import styled from "@emotion/styled";

export const ProductNameWrap = styled.td`
  text-align: left;
  span {
    vertical-align: middle;
    display: inline-block;
    width: 70%;
    @media (max-width: 1080px) {
      width: 60%;
    }
  }
`;

export const ProductImage = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: zoom-in;
  @media (max-width: 1080px) {
    margin-right: 2rem;
    width: 7rem;
    height: 7rem;
  }
`;

export const DefaultFileImgWrap = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: not-allowed;
  @media (max-width: 1080px) {
    margin-right: 2rem;
    width: 7rem;
    height: 7rem;
  }
`;

export const Diff = styled.div`
  color: ${(props) => (props.diff > 0 ? "#FF3B30" : "#0061FD")};
`;
