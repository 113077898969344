import React, { useEffect, useState } from "react";
//library
import { Link } from "react-router-dom";
//component
import Mheader from "../../../component/common/Mheader";
import swal from "sweetalert";

export default function CompletedContract() {
  const [isBlocking, setIsBlocking] = useState(false);

  //뒤로가기 확인
  // useEffect(() => {
  //   const unblock = history.block((loc, action) => {
  //     if (action === 'POP' && isBlocking) {
  //       swal('뒤로가기 할 수 없습니다. 확인버튼을 눌러주세요.','','warning');
  //       return false;
  //     }
  //     // return true;
  //   });

  //   return () => unblock();
  // }, [isBlocking]);

  // useEffect(() => {
  //   setIsBlocking(true);
  // }, [history]);

  return (
    <div className="completed_wrap">
      <Mheader title="공급계약 체결하기" />
      <div className="conclude_container completed_container">
        <div className="top_pagenation">
          <h2>공급계약 체결하기</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p className="active">2</p>
          </div>
        </div>
        <div className="completed_text">
          <h4>공급계약이 신청되었습니다.</h4>
          <p>공급사에서 계약 동의 시 계약이 체결됩니다.</p>
          <p>계약 진행 및 사항은 계약관리 내에서 조회하실 수 있습니다.</p>
        </div>
        <div className="completed_btn">
          <Link to="/management/contract/1">계약 내역 확인</Link>
        </div>
      </div>
    </div>
  );
}
