import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const CeoNameInput = ({ control, errors }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="ceo_name"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width={isDesktop ? 380 : "100%"}
          id="ceo_name"
          placeholder="대표자명을 입력해주세요."
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                대표자명{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={
            errors.ceo_name && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.ceo_name.message}
              </Typography>
            )
          }
          status={errors.ceo_name && "error"}
        />
      )}
    />
  );
};

export default CeoNameInput;
