import { QUERY_KEYS } from "../../constant/QueryKeys";
import { useQuery } from "@tanstack/react-query";
import { fetchSiteInfoApi } from "../../api/auth";

const useFetchSiteInfo = (orgNo) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_SITE_INFO],
    queryFn: () => fetchSiteInfoApi(orgNo),
    enabled: !!orgNo,
  });
};

export default useFetchSiteInfo;
