import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";
import { useSelector } from "react-redux";

const SwitchTypeButton = ({ setSwitchTypeModal }) => {
  const user = useSelector((state) => state.user);
  const isDemo = user?.isDemo;
  const isVerified = user?.identityVerification;
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const onClickSwitchTypeButton = () => {
    setSwitchTypeModal(true);
  };

  return (
    <Button
      type={isDemo ? "primary" : "secondary"}
      size="medium"
      style={{ width: isMobile && "46.8rem", minWidth: isMobile && "46.8rem" }}
      onClick={onClickSwitchTypeButton}
      disabled={!isVerified}
    >
      {isDemo ? "사용" : "테스트"} 환경으로 변환
    </Button>
  );
};

export default SwitchTypeButton;
