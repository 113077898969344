import { useState } from "react";
//library
import { useNavigate } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/request.css";

import i_contract from "../../asset/images/i_contract_based.png";
import i_contract_active from "../../asset/images/i_contract_based_active.png";
import i_reverse from "../../asset/images/i_quick_order.png";
import i_reverse_active from "../../asset/images/i_quick_order_active.png";

import i_company from "../../asset/images/i_choose_company.png";
import i_company_active from "../../asset/images/i_choose_company_active.png";
import i_pb from "../../asset/images/i_choose_pb.png";
import i_pb_active from "../../asset/images/i_choose_pb_active.png";
import i_pb_hover from "../../asset/images/i_choose_pb_hover.png";

import swal from "sweetalert";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

export default function Request() {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });

  const [orderType, setOrderType] = useState({
    contract: false,
    company: false,
    reverse: false,
    pb: false,
  });
  const [hoverOrderType, setHoverOrderType] = useState({
    contract: false,
    company: false,
    reverse: false,
    pb: false,
  });

  const userData = useSelector((state) => state.user);
  const isSubContractor = userData.constructionType === "SUBCONTRACTOR";

  const swalReverse = () => {
    swal({
      title: "주문서 역발행",
      text: `
        1. 고객사가 주문서 발행 요청 (전화, 문자, 메일 등)
        2. 공급사에서 "주문서 생성" 후 품목, 단가, 수량 등 입력
        3. 주문서 발행 > 고객사 확인 > 배송 진행
      `,
      icon: "info",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
            * 주문서 역발행</br>
              급한 건에 대하여 선제적으로 납품 행위를 먼저 하는 방법으로</br>
              빠른 배송을 원하는 상황에 적합합니다.
          `,
        },
      },
    });
  };

  const sendData = () => {
    let destination = "";

    if (orderType.contract) {
      destination = "/management/bid/request_reorder";
    } else if (orderType.company) {
      destination = "/management/quick_order/search";
    } else if (orderType.reverse) {
      swalReverse();
      return;
    } else if (orderType.pb) {
      destination = "/pb/choice";
    } else {
      swal("주문 방법을 선택해주세요.", "", "error");
      return;
    }

    navigate(destination);
  };

  const orderHoverDefaultFnc = () => {
    setHoverOrderType({
      company: false,
      reverse: false,
      contract: false,
      pb: false,
    });
  };

  return (
    <div className="request_wrap" style={{ paddingTop: 0 }}>
      <Mheader title="견적 / 계약 / 주문" />
      <div className="request_container">
        <div className="top_progress_bar" style={{ top: "140px" }}>
          <div
            className="active_menus color1"
            onClick={() => navigate("/management/request_order")}
          >
            주문방법선택
          </div>
          <div className="menus not_allowed">공급사 선택</div>
          <div className="menus not_allowed">입력 방법 선택</div>
          <div className="menus not_allowed">견적서 간편 입력</div>
          <div className="menus not_allowed">상세정보 입력</div>
          <div className="menus not_allowed">완료</div>
        </div>

        <div className="req_order_container">
          <div className="req_order_align">
            <div
              className={`req_order_company 
              ${(hoverOrderType.company || orderType.company) && "req_active"}
              
              `}
              onMouseOver={() =>
                setHoverOrderType({
                  company: true,
                  reverse: false,
                  contract: false,
                  pb: false,
                })
              }
              onMouseLeave={() => orderHoverDefaultFnc()}
              onClick={() => {
                setOrderType({
                  company: true,
                  reverse: false,
                  contract: false,
                  pb: false,
                });
              }}
            >
              {isDesktop && (
                <img
                  alt="company"
                  src={
                    orderType.company || hoverOrderType.company
                      ? i_company_active
                      : i_company
                  }
                />
              )}
              <div className="req_order_content">
                <div>업체지정 주문</div>
                <div>업체를 지정하여</div>
                <div>바로 주문합니다.</div>
                {isDesktop && <div>바로가기 →</div>}
              </div>
            </div>

            <div
              className={`req_order_reverse 
              ${(hoverOrderType.reverse || orderType.reverse) && "req_active"}`}
              onMouseOver={() =>
                setHoverOrderType({
                  company: false,
                  reverse: true,
                  contract: false,
                  pb: false,
                })
              }
              onMouseLeave={() => orderHoverDefaultFnc()}
              onClick={() => {
                setOrderType({
                  company: false,
                  reverse: true,
                  contract: false,
                  pb: false,
                });
                swalReverse();
              }}
            >
              {isDesktop && (
                <img
                  alt="reverse"
                  src={
                    orderType.reverse || hoverOrderType.reverse
                      ? i_reverse_active
                      : i_reverse
                  }
                />
              )}
              <div className="req_order_content">
                <div>주문서 역발행</div>
                <div>공급사에 주문서 발행을</div>
                <div>요청하는 방법입니다.</div>
                {isDesktop && <div>바로가기 →</div>}
              </div>
            </div>
          </div>

          <div className="req_order_align_flex">
            <div
              className={`req_order_contract 
              ${(hoverOrderType.contract || orderType.contract) && "req_active"}
              `}
              onMouseOver={() =>
                setHoverOrderType({
                  company: false,
                  reverse: false,
                  contract: true,
                  pb: false,
                })
              }
              onMouseLeave={() => orderHoverDefaultFnc()}
              onClick={() => {
                setOrderType({
                  company: false,
                  reverse: false,
                  contract: true,
                  pb: false,
                });
              }}
            >
              {isDesktop && (
                <img
                  alt="contract"
                  src={
                    orderType.contract || hoverOrderType.contract
                      ? i_contract_active
                      : i_contract
                  }
                />
              )}
              <div className="req_order_content">
                <div>계약서 기반 주문</div>
                <div>이전에 진행했던 계약서를</div>
                <div>선택하여 주문할 수 있습니다.</div>
                {isDesktop && <div>바로가기 →</div>}
              </div>
            </div>

            <div
              className={`${
                (hoverOrderType.pb || orderType.pb) &&
                "private_subcontractor_order req_active"
              } pb_special_layout req_order_pb`}
              onMouseOver={() =>
                setHoverOrderType({
                  company: false,
                  reverse: false,
                  contract: false,
                  pb: true,
                })
              }
              onMouseLeave={() => orderHoverDefaultFnc()}
              onClick={() =>
                setOrderType({
                  company: false,
                  reverse: false,
                  contract: false,
                  pb: true,
                })
              }
            >
              {isSubContractor ? (
                <div className="private_subcontractor_box">
                  <span className="private_subcontractor_badge">
                    협력사 전용
                  </span>
                  {isDesktop && (
                    <img
                      alt="pb"
                      className="pb_order_img"
                      src={
                        orderType.pb || hoverOrderType.pb
                          ? i_pb_hover
                          : i_pb_active
                      }
                    />
                  )}
                </div>
              ) : (
                isDesktop && (
                  <img
                    alt="pb"
                    src={
                      orderType.pb || hoverOrderType.pb
                        ? i_pb_hover
                        : i_pb_active
                    }
                    style={{ marginBottom: "20px" }}
                  />
                )
              )}
              <div className="req_order_content">
                <div>PB상품 주문</div>
                <div>공새로 PB상품을 선택하여</div>
                <div>주문할 수 있습니다.</div>
                {isDesktop && <div>바로가기 →</div>}
              </div>
            </div>
          </div>
        </div>

        <div className="blue_btn" onClick={sendData}>
          다음
        </div>
      </div>
    </div>
  );
}
