import { useState } from "react";
import Checkbox from "../../../../../../component/ui/checkbox/Checkbox";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const MaintainLoginCheckbox = () => {
  const [checked, setChecked] = useState(true);

  const onChangeCheckbox = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <Checkbox checked={checked} onChange={onChangeCheckbox}>
      <Typography component="p" type="c4" color={COLOR.GRAY.L09}>
        로그인 유지
      </Typography>
    </Checkbox>
  );
};

export default MaintainLoginCheckbox;
