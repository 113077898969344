import React, { useEffect, useState } from "react";

//library
import { useNavigate, useParams } from "react-router-dom";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";

// css
import s from "../../../asset/css/productChoicePop.module.css";

import { productDetailApi } from "../../../api/auth";

// alert
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//redux
import { actions } from "../../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import { excelDefault } from "../../../redux/reducer/default";

const emptyColumn = [
  { value: "" },
  { value: "" },
  { value: "" },
  { value: "" },
  { value: "무관" },
  { value: "" },
];

export default function ProductChoiceDetailPop() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redux_excel = useSelector((state) => state.excel);

  const { id } = useParams();

  const [choicePop, setChoicePop] = useState(false);
  const [productImg, setProductImg] = useState(""); //product img
  const [productInfo1, setProductInfo1] = useState([]); //product 정보1 - 이름과 브랜드만 쓰는 중

  // 문자열을 쉼표와 공백을 기준으로 분할하여 배열로 변환
  const isEmptyRedux = redux_excel.length === 0 || redux_excel === undefined;
  const parts = productInfo1?.standard?.split(", ");

  // toast
  const notifySuccess = (name) => toast.success(`제품을 담았습니다.`);

  // 배열 내의 각 요소를 객체로 변환
  const result = parts?.map((part) => {
    // 각 요소를 콜론을 기준으로 분할하여 키와 값을 추출
    const [key, value] = part.split(": ");
    // 객체로 변환하여 반환
    return { key: key?.trim(), value: value?.trim() };
  });

  const productDetailFnc = async () => {
    const response = await productDetailApi(id);

    if (response.status === "success") {
      // setCategory(response.data.categoryInfo);
      setProductImg(response.data.productImg);
      setProductInfo1(response.data.product);
      // setProductInfo2(response.data.productOptionList);
    }
  };

  useEffect(() => {
    productDetailFnc();
  }, []);

  const setDataFnc = async () => {
    // 컨버팅
    const pInfo = [
      { value: productInfo1.name },
      { value: productInfo1.standard },
      { value: productInfo1.unit },
      { value: 1 },
      { value: productInfo1.brand },
      { value: productInfo1.etc },
    ];

    function findMatchingIndex(emptyColumn, test) {
      for (let i = 0; i < test.length; i++) {
        const column = test[i];
        if (column.length === emptyColumn.length) {
          let isEqual = true;
          for (let j = 0; j < column.length; j++) {
            if (column[j].value !== emptyColumn[j].value) {
              isEqual = false;
              break;
            }
          }
          if (isEqual) {
            return i;
          }
        }
      }
      return -1; // 일치하는 배열을 찾지 못한 경우
    }

    // 테스트
    const resultIndex = findMatchingIndex(emptyColumn, redux_excel);

    if (resultIndex !== -1) {
      const copy = redux_excel;
      copy[resultIndex] = [...pInfo];
      dispatch(actions.setExcel(copy));
      notifySuccess();
      // setChoicePop(false);
      navigate("/management/bid/request_excel", {
        state: { getListPop: false },
      });
    } else {
      // redux_excel의 배열을 돌면서 값이 default값과 같다면 거기에 푸쉬해주기?
      dispatch(actions.setExcel([...redux_excel, pInfo]));
      notifySuccess(productInfo1.name);
      // setChoicePop(false);
      navigate("/management/bid/request_excel", {
        state: { getListPop: false },
      });
    }

    // if (isEmptyRedux) {
    //   dispatch(actions.setExcel([pInfo]));
    //   notifySuccess(productInfo1.name);
    //   navigate("/management/bid/request_excel", {
    //     state: { getListPop: false },
    //   });
    // } else {
    //   // redux_excel의 배열을 돌면서 값이 default값과 같다면 거기에 푸쉬해주기?
    //   dispatch(actions.setExcel([...redux_excel, pInfo]));
    //   notifySuccess(productInfo1.name);
    //   navigate("/management/bid/request_excel", {
    //     state: { getListPop: false },
    //   });
    // }
  };

  return (
    <div className={s.background} onClick={() => setChoicePop(false)}>
      <div className={s.detail_container} onClick={(e) => e.stopPropagation()}>
        <div className={s.detail_header}>
          <div className={s.title}>품목 보기</div>
          <div className={s.close} onClick={() => setChoicePop(false)}>
            <img
              src={i_close}
              alt="close_icon"
              onClick={() => {
                navigate("/management/bid/request_excel");
              }}
            />
          </div>
        </div>
        <div className={s.detil_contents}>
          <div className={s.detail_img_box}>
            <img alt="" src={productImg} />
          </div>
          {/* <div className={s.detail_title_box}>

          </div> */}
          <div className={s.detail_title_box}>
            <p className={s.detail_name1}>{productInfo1.brand}</p>
            <p className={s.detail_name2}>{productInfo1.name}</p>
          </div>

          {/* <p className={s.name3}>
                    {elem.product.standard.replace(/, /gi, " / ")}
                  </p>  */}
          <div className={s.detail_desc_box}>
            <p className={s.desc_title}>상세정보</p>
            <table className={s.desc_table}>
              {result?.map((elem, idx) => {
                return (
                  <tr>
                    <td>{elem.key}</td>
                    <td>{elem.value}</td>
                  </tr>
                );
              })}
              <tr>
                <td>단위</td>
                <td>{productInfo1.unit}</td>
              </tr>
              <tr>
                <td>비고</td>
                <td>{productInfo1.etc}</td>
              </tr>
            </table>
          </div>
          <div className={s.desc_btn_box}>
            <p onClick={setDataFnc} className={s.desc_confirm_btn}>
              품목 담기
            </p>
          </div>
        </div>
      </div>
      {/* {id && <ProductChoicePop setChoicePop={setChoicePop} />} */}
      <ToastContainer
        className="toastify"
        position="top-center"
        autoClose={3000}
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
