import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const EmailInput = ({ control, errors, handleCheckEmail }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="email"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width={isDesktop ? 380 : "100%"}
          id="email"
          placeholder="이메일 주소를 입력해주세요."
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                이메일 주소{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={
            errors.email ? (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.email.message}
              </Typography>
            ) : (
              <Typography component="p" type="c5" color={COLOR.GRAY.L05}>
                회사 이메일을 사용해주세요.
              </Typography>
            )
          }
          status={errors.email && "error"}
          onBlur={() => {
            field.onBlur();
            handleCheckEmail();
          }}
        />
      )}
    />
  );
};

export default EmailInput;
