import React,{useState,useEffect} from 'react';
//library
import { Link } from 'react-router-dom';
//component
import Mheader from '../../component/common/Mheader';
//css
import '../../asset/css/review.css';
//etc
import { useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router';
import swal from 'sweetalert';

export default function ReviewCompleted() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isBlocking, setIsBlocking] = useState(false);

  //뒤로가기 확인
  // useEffect(() => {
  //   const unblock = history.block((loc, action) => {
  //     if (action === 'POP' && isBlocking) {
  //       swal('뒤로가기 할 수 없습니다. 확인버튼을 눌러주세요.','','warning');
  //       return false;
  //     }
  //     // return true;
  //   });

  //   return () => unblock();
  // }, [isBlocking]);

  // useEffect(() => {
  //   setIsBlocking(true);
  // }, [history]);

  return (
    <div className='review_wrap'>
      <Mheader title='리뷰 작성하기' />
      <div className='review_container'>
        <div className='top_pagenation'>
          <h2>리뷰 작성하기</h2>
          <div className='top_pagenation_num'>
            <p>1</p>
            <p className='active'>2</p>
          </div>
        </div>
        <div className='review_completed_info'>
          <p>리뷰 작성이 완료되었습니다.</p>
          <div className='review_back_btn'>
            <Link
              to={
                location.state.type === 'quick'
                  ? '/management/quick_order'
                  : '/management/order/1'
              }
            >
              돌아가기
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
