import React from "react";
import Modal from "../../ui/modal/Modal";
import Typography from "../../ui/typography/Typography";
import { COLOR } from "../../../constant/Palette";
import Button from "../../ui/button/Button";

const VerifyAuthCodeErrorPop = ({
  verifyAuthCodeErrorPop,
  setVerifyAuthCodeErrorPop,
}) => {
  const onClickCancel = () => {
    setVerifyAuthCodeErrorPop(false);
  };

  return (
    <Modal
      size="xsmall"
      type="error"
      open={verifyAuthCodeErrorPop}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.RED.L10}>
          본인 인증 실패
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="primary" size="small" onClick={onClickCancel}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        다시 시도해주세요.
      </Typography>
    </Modal>
  );
};

export default VerifyAuthCodeErrorPop;
