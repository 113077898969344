import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const DeptInput = ({ control, errors }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="dept"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width={isDesktop ? 380 : "100%"}
          id="dept"
          placeholder="부서명을 입력해주세요."
          label={
            <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
              부서{" "}
            </Typography>
          }
          helpText={
            errors.dept && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.dept.message}
              </Typography>
            )
          }
          status={errors.dept && "error"}
        />
      )}
    />
  );
};

export default DeptInput;
