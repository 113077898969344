import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { withdrawSchema } from "../../pages/myPage/my-info/components/modal/withdraw/schema/schema";
import useWithdraw from "../../hooks/useMutation/useWithdraw";
import { useState } from "react";

const useWithdrawModalHandler = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(withdrawSchema),
    mode: "all",
    defaultValues: {
      password: "",
    },
  });
  const [withdrawSuccessModal, setWithdrawSuccessModal] = useState(false);
  const [withdrawFailModal, setWithdrawFailModal] = useState(false);

  const onWithdrawSuccessCb = ({ status }) => {
    if (status === "fail") {
      setWithdrawFailModal(true);
      return;
    }

    if (status === "success") {
      setWithdrawSuccessModal(true);
      return;
    }
  };

  const onWithdrawErrorCb = ({ error }) => {
    console.error("withdraw error", error);
  };

  const { mutate: withdraw } = useWithdraw(
    onWithdrawSuccessCb,
    onWithdrawErrorCb
  );

  const handleWithdraw = (data) => {
    withdraw({ password: data.password });
  };

  return {
    handleSubmit,
    control,
    errors,
    handleWithdraw,
    withdrawSuccessModal,
    setWithdrawSuccessModal,
    withdrawFailModal,
    setWithdrawFailModal,
  };
};

export default useWithdrawModalHandler;
