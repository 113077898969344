import { useNavigate, useLocation } from "react-router-dom";
import * as S from "../../asset/css/dashBoardTabs.styles.js";

export default function DashBoardTabs() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const pattern = /(estimate|contract|order|invoice|qna)/i;
  const now = pathname.match(pattern);

  const filter_now = now ? now[0] : null;

  return (
    <S.TabsWrapper filterUrl={filter_now}>
      <S.Tab onClick={() => navigate("/management/estimate/1")}>
        <p>입찰관리</p>
      </S.Tab>
      <S.Tab onClick={() => navigate("/management/contract/1")}>
        <p>계약관리</p>
      </S.Tab>
      <S.Tab onClick={() => navigate("/management/order/1")}>
        <p>주문관리</p>
      </S.Tab>
      <S.Tab onClick={() => navigate("/invoice")}>
        <p>정산관리</p>
      </S.Tab>
    </S.TabsWrapper>
  );
}
