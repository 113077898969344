import s from "./componentTest.module.css";
import ButtonTest from "./button/ButtonTest";
import ModalTest from "./modal/ModalTest";
import { Divider } from "antd";
import TypographyTest from "./typography/TypographyTest";
import ChipTest from "./chip/ChipTest";
import InputTest from "./input/InputTest";
import SelectTest from "./select/SelectTest";

export default function ComponentTest() {
  return (
    <div className={s.container}>
      <TypographyTest />
      <Divider />

      <ButtonTest />
      <Divider />

      <InputTest />
      <Divider />

      <SelectTest />
      <Divider />

      <ChipTest />
      <Divider />

      <ModalTest />
      <Divider />
    </div>
  );
}
