import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// css
import "../../asset/css/find.css";

export default function FindPw2() {
  const navigate = useNavigate();

  return (
    <div className="completed_wrap joincomplete_wrap">
      <div className="completed_container joincomplete_container">
        <div className="completed_info">
          <div className="join_top">
            <h2>비밀번호 재설정 완료</h2>
            <div className="join_pagenation">
              <p>1</p>
              <p>2</p>
              <p className="active">3</p>
            </div>
          </div>

          <p className="complete_text">
            비밀번호 변경이 완료되었습니다.
            <br />새 비밀번호로 로그인해주세요.
          </p>
        </div>
        <div className="btn_wrap">
          <div
            onClick={() => {
              navigate("/login");
            }}
            style={{ width: "100%" }}
          >
            로그인
          </div>
        </div>
      </div>
    </div>
  );
}
