import React, { useCallback, useEffect, useRef, useState } from "react";

//library
import { Route, Routes, useLocation } from "react-router-dom";
import { isIE } from "react-device-detect";

//reset.css
import "./asset/css/reset.css";
import "./component/styles/palette.module.css";
//datepicker.css
import "react-datepicker/dist/react-datepicker.css";
import "./asset/css/customDatepicker.css";
//common component
import Header from "./component/common/Header";
import SideBar from "./component/common/SideBar";
import Footer from "./component/common/Footer";
//page component
import Main from "./pages/main/Main";
import Login from "./pages/user/login/Login";
import Find from "./pages/find/Find";
import FindId from "./pages/find/FindId";
import FindPw from "./pages/find/FindPw";
import FindPw2 from "./pages/find/FindPw2";

// management - estimate
import Estimate from "./pages/management/estimate/Estimate";
import EstimateDetail from "./pages/management/estimate/EstimateDetail";
import ReEstimate from "./pages/management/estimate/ReEstimate";
import ConcludeContract from "./pages/management/estimate/ConcludeContract";
import CompletedContract from "./pages/management/estimate/CompletedContract";
// management - contract
import Contract from "./pages/management/contract/Contract";
import ContractDetail from "./pages/management/contract/ContractDetail";
// management - order
import Order from "./pages/management/order/Order";
import OrderIssuance from "./pages/management/order/OrderIssuance";
import OrderCompleted from "./pages/management/order/OrderCompleted";
import OrderDetail from "./pages/management/order/OrderDetail";
// policy
import Privacy from "./pages/policy/Privacy";
import PrivacyAgree from "./pages/policy/PrivacyAgree";
import Terms from "./pages/policy/Terms";
import Terms0811 from "./pages/policy/Terms~0811";
import Terms1211 from "./pages/policy/Terms~1211";
// look
import Look from "./pages/look/Look";
// payment
import Payment from "./pages/payment/Payment";
import PaymentFinished from "./pages/payment/PaymentFinished";

//request 견적요청
import RequestShort from "./pages/request/RequestShort";
import RequestShort2 from "./pages/request/RequestShort2";
import RequestShort3 from "./pages/request/RequestShort3";
import RequestOne2 from "./pages/request/RequestOne2";
import RequestSelfChoice from "./pages/request/RequestSelfChoice";
import RequestComplete from "./pages/request/RequestComplete";
import ReOrderComplete from "./pages/request/ReOrderComplete";
import Site from "./pages/site/Site";

// request new
import RequestFirstEstimate from "./pages/request/RequestFirstEstimate";
import RequestFirstOrder from "./pages/request/RequestFirstOrder";
import RequestExcel from "./pages/request/RequestExcel";
import RequestReorder from "./pages/request/RequestReorder";
import RequestReorder2 from "./pages/request/RequestReorder2";
import RequestInfoInput from "./pages/request/RequestInputInfo";

// review
import Review from "./pages/review/Review";
import ReviewCompleted from "./pages/review/ReviewCompleted";
import Notice from "./pages/myPage/notice/Notice";
import NoticeDetail from "./pages/myPage/notice/NoticeDetail";
import PaidService from "./pages/myPage/history/PaidService";
import History from "./pages/myPage/history/History";
// quick order
import QuickOrderDetail from "./pages/management/quickOrder/QuickOrderDetail";
import QuickOrder2 from "./pages/management/quickOrder/QuickOrder2";
import QuickEstimate from "./pages/management/quickOrder/QuickEstimate";
import QuickOrderComplete from "./pages/management/quickOrder/QuickOrderComplete";
import QuickRequestShort from "./pages/management/quickOrder/QuickRequestShort";
// qna
import QnA from "./pages/qna/QnA";
// all history
import AllHistory from "./pages/myPage/AllHistory";
//error
import NotFound from "./pages/error/404";

//redux
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./redux/action";

import { AnimatePresence } from "framer-motion";
import PrivateRoute from "./asset/js/PrivateRoute";
import Invoice from "./pages/calculate/Invoice";
import RouteChangeTracker from "./util/RouteChangeTracker";
import ReactGA4 from "react-ga4";
import PoscoLogin from "./pages/user/PoscoLogin";

// 협력사 - PB
import RequestFirstPb from "./pages/pb/RequestFirstPb";
import RequestOrderPb from "./pages/pb/RequestOrderPb";
import RequestCompletePb from "./pages/pb/RequestCompletePb";

// 협력사 - PB 주문하기
import PbOrderDetail from "./pages/management/pbOrder/pbOrderDetail";
import PbOrder from "./pages/management/pbOrder/pbOrder";

import circleIcon from "./asset/images/danger_circle_icon.png";
import SubcontractorAlertPop from "./component/common/Pop/SubcontractorAlertPop";
import CustomFloatButton from "./component/etc/CustomFloatButton";
import SearchList from "./pages/search/SearchList";
import ProductChoiceDetailPop from "./component/common/Pop/ProductChoiceDetailPop";
import ReleaseNote from "./pages/policy/ReleaseNote";
import RequestMethodChoice from "./pages/request/RequestMethodChoice";

import { version } from "../package.json";
import { notification } from "antd";
import axios from "axios";
import RequestConfirm from "./pages/request/RequestConfirm";
import ComponentTest from "./pages/componentTest";
import Signup from "./pages/user/signup/Signup";
import MyPage from "./pages/myPage/my-info/MyPage";
import CreateConstruction from "./pages/myPage/create-construction/CreateConstruction";
import CreateSite from "./pages/myPage/create-site/CreateSite";
import { useMediaQuery } from "react-responsive";

function App() {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });

  const location = useLocation();
  const dispatch = useDispatch();
  const signToken = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const _savePushToken = (e) => {
      if (e.data) {
        let pushTokenData = "";
        if (typeof e.data === "object") {
          pushTokenData = e.data;
        } else {
          pushTokenData = JSON.parse(e.data);
        }

        const pushToken = pushTokenData.value;
        const pushType = pushTokenData.type;

        const data = {
          value: pushToken,
          type: pushType,
        };
        if (pushToken && pushType) {
          dispatch(actions.setPushToken(data));
        }
      }
    };

    document.addEventListener("message", _savePushToken);
    window.addEventListener("message", _savePushToken);
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView) {
      if (signToken) {
        window.ReactNativeWebView.postMessage("setLoginState_true");
      } else {
        window.ReactNativeWebView.postMessage("setLoginState_false");
      }
    }
  }, [signToken]);

  const isSubcontractor =
    user.constructionType === "SUBCONTRACTOR" &&
    !location.pathname.includes("/pb/") &&
    location.pathname !== "/" &&
    !location.pathname.includes("/request_order") &&
    !location.pathname.includes("/my_page/my_info") &&
    !location.pathname.includes("/management/bid/request_choice") &&
    !location.pathname.includes("/management/bid/request_confirm") &&
    !location.pathname.includes("/management/bid/request_complete");

  const [subcontractorAlertPop, setSubcontractorAlertPop] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const notificationOpenRef = useRef(false);

  const openNotificationWith = useCallback(
    (type) => {
      if (notificationOpenRef.current) return;

      api.destroy();

      api[type]({
        message: "신규 업데이트 알림",
        description: (
          <div>
            <div>공새로 플랫폼의 업데이트가 존재합니다.</div>
            <div>
              F5를 눌러 새로고침하거나 이 알림을 클릭하여 새로고침 하세요.
            </div>
          </div>
        ),
        duration: 0,
        onClick: () => {
          window.location.reload(true);
          notificationOpenRef.current = false;
        },
        style: { width: 450, cursor: "pointer" },
        onClose: () => {
          notificationOpenRef.current = false;
        },
      });

      notificationOpenRef.current = true;
    },
    [api]
  );

  const checkVersion = useCallback(async () => {
    try {
      const frontVersion = version?.slice(0, 5);

      axios.interceptors.response.use((response) => {
        const backVersion = response.headers["version-customer"]?.slice(0, 5);

        if (backVersion && frontVersion !== backVersion) {
          openNotificationWith("info");
        }

        return response;
      });
    } catch (error) {
      console.error("Error checking version:", error);
    }
  }, [openNotificationWith]);

  useEffect(() => {
    checkVersion();
  }, [checkVersion]);

  const isDemo = user?.isDemo;

  return (
    <>
      {isIE ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                fontSize: "3rem",
                marginBottom: "4rem",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "#002863",
                }}
              >
                공새로 서비스
              </span>
              는 크롬 브라우저에 최적화되어 있습니다.
            </h2>
            <p
              style={{
                marginBottom: "1rem",
              }}
            >
              크롬 브라우저를 이용하여 서비스 이용을 부탁드립니다.
            </p>
            <a
              style={{ color: "#002863" }}
              href="https://www.google.com/chrome/"
            >
              크롬 다운로드 바로가기
            </a>
          </div>
        </>
      ) : (
        <div className="wrap">
          {/* true면 안 뜸 */}
          {isSubcontractor && signToken && (
            <div
              className="subcontractor_alert"
              onClick={() => setSubcontractorAlertPop(true)}
            >
              <img src={circleIcon} alt="circle icon" />
              <span>
                이 기능을 사용하기 위해서는 건설사 회원으로 전환이 필요합니다.
              </span>
            </div>
          )}
          <Header />
          <SideBar role={true} />
          <div
            className="container"
            style={{
              paddingTop: isDemo
                ? isDesktop
                  ? "200px"
                  : isMobile
                  ? "60rem"
                  : ""
                : "",
            }}
          >
            <AnimatePresence>
              <Routes>
                <Route
                  path="/ai_data_recommend"
                  element={
                    <PrivateRoute auth={signToken} component={<Main />} />
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/signup/:code" element={<Signup />} />
                {/* find */}
                <Route path="/find" element={<Find />} />
                <Route path="/find-id" element={<FindId />} />
                <Route path="/find-pw" element={<FindPw />} />
                <Route path="/find-pw2" element={<FindPw2 />} />
                {/* policy */}
                <Route path="/release_note" element={<ReleaseNote />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/privacyagree" element={<PrivacyAgree />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/terms0811" element={<Terms0811 />} />
                <Route path="/terms1211" element={<Terms1211 />} />
                <Route
                  path="/pb/choice"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestFirstPb />}
                    />
                  }
                />
                <Route
                  path="/pb/order"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestOrderPb />}
                    />
                  }
                >
                  <Route
                    path="request_confirm"
                    element={
                      <PrivateRoute
                        auth={signToken}
                        component={<RequestConfirm />}
                      />
                    }
                  />
                </Route>
                <Route
                  path="/pb/complete"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestCompletePb />}
                    />
                  }
                />
                <Route
                  path="/management/pb/order/:page"
                  element={
                    <PrivateRoute auth={signToken} component={<PbOrder />} />
                  }
                />
                <Route
                  path="/management/pb/order/document/:orderNo"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<PbOrderDetail />}
                    />
                  }
                />
                <Route
                  exact
                  path="/my_page/my_info"
                  element={
                    <PrivateRoute auth={signToken} component={<MyPage />} />
                  }
                />
                <Route
                  exact
                  path="/my_page/my_info/create_construction"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<CreateConstruction />}
                    />
                  }
                />
                <Route
                  exact
                  path="/my_page/my_info/create_site"
                  element={
                    <PrivateRoute auth={signToken} component={<CreateSite />} />
                  }
                />
                <Route
                  exact
                  path="/my_page/notice"
                  element={
                    <PrivateRoute auth={signToken} component={<Notice />} />
                  }
                />
                <Route
                  path="/my_page/notice/:id"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<NoticeDetail />}
                    />
                  }
                />
                <Route
                  path="/"
                  element={
                    <PrivateRoute auth={signToken} component={<Site />} />
                  }
                />
                <Route
                  path="/my_page/history"
                  element={
                    <PrivateRoute auth={signToken} component={<History />} />
                  }
                />
                {/* <Route
                  path="/management/bid/request_choice2"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestChoice2 />}
                    />
                  }
                /> */}
                {/* <Route
                  path="/management/request_bid"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestFirstEstimate />}
                    />
                  }
                /> */}
                <Route
                  path="/management/estimate/:page"
                  element={
                    <PrivateRoute auth={signToken} component={<Estimate />} />
                  }
                />
                <Route
                  path="/management/estimate/document/:id"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<EstimateDetail />}
                    />
                  }
                />
                <Route
                  path="/management/estimate/re_estimate"
                  element={
                    <PrivateRoute auth={signToken} component={<ReEstimate />} />
                  }
                />
                <Route
                  path="/management/estimate/conclude_contract/:id"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<ConcludeContract />}
                    />
                  }
                >
                  <Route
                    path="request_confirm"
                    element={
                      <PrivateRoute
                        auth={signToken}
                        component={<RequestConfirm />}
                      />
                    }
                  />
                </Route>
                <Route path="/poscoenc/:route/:id/*" element={<PoscoLogin />} />
                <Route
                  path="/completed_contract"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<CompletedContract />}
                    />
                  }
                />
                {/* management - estimate - request */}

                <Route
                  path="/management/estimate/request"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestFirstEstimate />}
                    />
                  }
                />
                <Route
                  path="/management/bid/request_choice"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestMethodChoice />}
                    />
                  }
                />
                <Route
                  path="/management/request_order"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestFirstOrder />}
                    />
                  }
                />

                <Route
                  path="/management/bid/request_excel"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestExcel />}
                    />
                  }
                >
                  <Route
                    path="detail/:id"
                    element={
                      <PrivateRoute
                        auth={signToken}
                        component={<ProductChoiceDetailPop />}
                      />
                    }
                  />
                </Route>
                <Route
                  path="/management/bid/request_reorder"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestReorder />}
                    />
                  }
                />
                <Route
                  path="/management/bid/request_reorder2"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestReorder2 />}
                    />
                  }
                />
                <Route
                  path="/management/estimate/request_short_term"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestShort2 />}
                    />
                  }
                />
                <Route
                  path="/management/estimate/request_one2"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestOne2 />}
                    />
                  }
                />
                <Route
                  path="/management/estimate/request_method"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestShort />}
                    />
                  }
                />
                <Route
                  path="/management/estimate/request_short3"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestShort3 />}
                    />
                  }
                />
                <Route
                  path="/management/estimate/request_self_choice"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestSelfChoice />}
                    />
                  }
                />

                <Route
                  path="/management/bid/request_input_info"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestInfoInput />}
                    />
                  }
                >
                  <Route
                    path="request_confirm"
                    element={
                      <PrivateRoute
                        auth={signToken}
                        component={<RequestConfirm />}
                      />
                    }
                  />
                </Route>

                <Route
                  path="/management/bid/request_complete"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<RequestComplete />}
                    />
                  }
                />
                <Route
                  path="/management/bid/re_order_complete"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<ReOrderComplete />}
                    />
                  }
                />
                {/* management - contract detail2,3,4는 정리필요 */}
                <Route
                  exact
                  path="/management/contract/:page"
                  element={
                    <PrivateRoute auth={signToken} component={<Contract />} />
                  }
                />
                <Route
                  path="/management/contract/document/:id"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<ContractDetail />}
                    />
                  }
                />
                <Route
                  path="/management/contract/order_issuance/:id"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<OrderIssuance />}
                    />
                  }
                />
                <Route
                  path="/management/contract/order_completed"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<OrderCompleted />}
                    />
                  }
                />
                {/* management - order */}
                <Route
                  exact
                  path="/management/order/:page"
                  element={
                    <PrivateRoute auth={signToken} component={<Order />} />
                  }
                />
                <Route
                  exact
                  path="/management/order/document/:orderNo"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<OrderDetail />}
                    />
                  }
                />
                {/* management - quick order */}
                <Route
                  path="/management/quick_order/document/:id"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<QuickOrderDetail />}
                    />
                  }
                />
                <Route
                  path="/management/quick_order2"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<QuickOrder2 />}
                    />
                  }
                />
                <Route
                  path="/management/quick_estimate"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<QuickEstimate />}
                    />
                  }
                />
                <Route
                  path="/management/quick_order_complete"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<QuickOrderComplete />}
                    />
                  }
                />
                <Route
                  path="/management/quick_order/request_short"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<QuickRequestShort />}
                    />
                  }
                />
                <Route
                  path="/management/quick_order/review/:id"
                  element={
                    <PrivateRoute auth={signToken} component={<Review />} />
                  }
                />
                <Route
                  path="/management/quick_order/review_completed"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<ReviewCompleted />}
                    />
                  }
                />
                {/* my page */}
                <Route
                  path="/my_page/paid_service"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<PaidService />}
                    />
                  }
                />
                <Route
                  path="/invoice"
                  element={
                    <PrivateRoute auth={signToken} component={<Invoice />} />
                  }
                />
                {/* look */}
                <Route
                  path="/look"
                  element={
                    <PrivateRoute auth={signToken} component={<Look />} />
                  }
                />
                <Route
                  path="/look/payment"
                  element={
                    <PrivateRoute auth={signToken} component={<Payment />} />
                  }
                />
                <Route
                  path="/look/payment-finished"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<PaymentFinished />}
                    />
                  }
                />
                <Route
                  path="/all_history"
                  element={
                    <PrivateRoute auth={signToken} component={<AllHistory />} />
                  }
                />
                {/* qna */}
                <Route
                  path="/qna"
                  element={
                    <PrivateRoute auth={signToken} component={<QnA />} />
                  }
                />
                <Route
                  path="/search_list"
                  element={
                    <PrivateRoute auth={signToken} component={<SearchList />} />
                  }
                />
                {/* component test */}
                <Route
                  path="/component_test"
                  element={
                    <PrivateRoute
                      auth={signToken}
                      component={<ComponentTest />}
                    />
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AnimatePresence>
          </div>
          <Footer />
          <CustomFloatButton />
        </div>
      )}
      {subcontractorAlertPop && (
        <SubcontractorAlertPop
          setSubcontractorAlertPop={setSubcontractorAlertPop}
        />
      )}
      {contextHolder}
      <RouteChangeTracker />
    </>
  );
}

export default App;
