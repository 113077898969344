import React, { useEffect } from "react";
import swal from "sweetalert";

//etc
import loadingImg from "../../asset/images/loading.gif";

export default function Loading({ msg }) {
  // const timerId = setTimeout(function(){
  //   swal({
  //     title: "통신오류",
  //     text: "데이터를 받아오지 못 했습니다.",
  //     icon: "error",
  //     closeOnClickOutside: false,
  //     closeOnEsc: false,
  //     buttons: {
  //       confirm: {
  //         text: "새로고침",
  //         value: true,
  //       },
  //     },
  //   }).then((result) => {
  //     if (result) {
  //       reloadingFnc();
  //     }
  //   });
  // },10000);

  // //10초 후 새로고침 출력
  // useEffect(()=>{
  //   return () => {
  //     clearTimeout(timerId);
  //   }
  // },[])

  // const reloadingFnc = () => {
  //   window.location.reload();
  // }

  return (
    <div
      style={{
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(32, 33, 33, 0.7);",
        position: "fixed",
        zIndex: 999999,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={loadingImg}
        style={{
          width: "150px",
          height: "150px",
        }}
      />

      <p
        style={{
          textAlign: "center",
          color: "#000",
          fontWeight: "bold",
          fontSize: "17px",
          lineHeight: "30px",
        }}
      >
        {msg}
        <br />
        잠시만 기다려주세요.
      </p>
    </div>
  );
}
