import { Divider } from "antd";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./title.module.css";

const Title = () => {
  return (
    <div className={s.container}>
      <div className={s.title_container}>
        <div className={s.title_text_container}>
          <Typography component="p" type="h1" color={COLOR.GRAY.L09}>
            회사 상세정보
          </Typography>
          <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
            회사 정보를 입력합니다. 정보는 확인 및 승인 후에 변경됩니다.
          </Typography>
        </div>
      </div>

      <Divider className={s.divider} />
    </div>
  );
};

export default Title;
