import React,{useEffect, useState} from 'react';
//library
import { Link } from 'react-router-dom';
//component
import Mheader from '../../../component/common/Mheader';

import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

export default function OrderCompleted() {
    const navigate = useNavigate();
    const [isBlocking, setIsBlocking] = useState(false);


  return (
    <div className="order_completed_wrap completed_wrap">
      <Mheader title="주문서 발행" />
      <div className="completed_container wd">
        <div className="top_pagenation">
          <h2>주문서 발행</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p className="active">2</p>
          </div>
        </div>
        <div className="completed_text">
          <h4>주문서 발행이 완료되었습니다.</h4>
          <p>공급사에 주문서 확인 후 배송이 시작됩니다.</p>
        </div>
        <div className="completed_btn">
          <Link to="/management/order/1">주문서 발행 내역</Link>
        </div>
      </div>
    </div>
  );
}
