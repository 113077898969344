import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createSiteSchema } from "../../pages/myPage/create-site/schema/schema";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useCreateSite from "../useMutation/useCreateSite";
import useUpdateSite from "../useMutation/useUpdateSite";
import { actions } from "../../redux/action";
import useFetchMyPageInfo from "../useQuery/useFetchMyPageInfo";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const getSelectTypeDefaultValue = (type) => {
  if (
    type !== "공통주택(아파트)" &&
    type !== "공통주택(주상복합)" &&
    type !== "오피스텔" &&
    type !== "물류 센터"
  ) {
    return "직접 입력";
  }

  return type;
};

const getInputTypeDefaultValue = (type) => {
  if (
    type === "공통주택(아파트)" ||
    type === "공통주택(주상복합)" ||
    type === "오피스텔" ||
    type === "물류 센터"
  ) {
    return "";
  }

  return type;
};

const useCreateSiteHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { state } = useLocation();
  const { data: myPage } = useFetchMyPageInfo();
  const site = myPage?.data.site;

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: zodResolver(createSiteSchema),
    mode: "all",
    defaultValues: {
      name: state?.isEdit ? site?.name : "",
      addr: state?.isEdit ? site?.addr1 : "",
      addr_detail: state?.isEdit ? site?.addr2 : "",
      zip: state?.isEdit ? String(site?.zip) : "",
      lat: state?.isEdit ? String(site?.lat) : "",
      lng: state?.isEdit ? String(site?.lng) : "",
      select_type: state?.isEdit ? getSelectTypeDefaultValue(site?.type) : "",
      input_type: state?.isEdit ? getInputTypeDefaultValue(site?.type) : "",
      scale: state?.isEdit ? site?.scale : "",
      process: state?.isEdit ? myPage?.data?.processName : "",
      phone: state?.isEdit ? site?.phone?.replaceAll("-", "") : "",
      s_date: state?.isEdit ? dayjs(site?.sdate) : "",
      e_date: state?.isEdit ? dayjs(site?.edate) : "",
    },
  });

  const [siteCreateModal, setSiteCreateModal] = useState(false);
  const [addrSearchModal, setAddrSearchModal] = useState(false);

  const onCreateSuccessCb = ({ status }) => {
    if (status === "success") {
      const userData = {
        ...user,
        organizationState: "PENDING_SUBORGANIZATION",
      };
      dispatch(actions.setUser(userData));
      navigate("/my_page/my_info", { state: { isFromCreateSitePage: true } });
    }
  };

  const onCreateErrorCb = (error) => {
    console.error("create error: ", error);
  };

  const { mutate: createSite } = useCreateSite(
    onCreateSuccessCb,
    onCreateErrorCb
  );

  const handleCreateSite = () => {
    createSite(params);
  };

  const onUpdateSuccessCb = () => {
    navigate("/my_page/my_info");
  };

  const onUpdateErrorCb = ({ error }) => {
    console.error("update error", error);
  };

  const { mutate: updateSite } = useUpdateSite(
    onUpdateSuccessCb,
    onUpdateErrorCb
  );

  const handleUpdateSite = () => {
    updateSite(params);
  };

  const [params, setParams] = useState({});

  const onClickSubmit = (data) => {
    setParams({
      ...params,
      siteNo: user.site_num || "",
      name: data.name,
      zip: data.zip,
      addr1: data.addr,
      addr2: data.addr_detail,
      type:
        data.select_type === "직접 입력" ? data.input_type : data.select_type,
      scale: data.scale,
      processName: data.process,
      phone: data.phone,
      sdate: dayjs(data.s_date).format("YYYY-MM-DD"),
      edate: dayjs(data.e_date).format("YYYY-MM-DD"),
      lat: data.lat,
      lng: data.lng,
    });

    setSiteCreateModal(true);
  };

  useEffect(() => {
    if (user.organizationState !== "REGIST_SUBORGANIZATION" && user.isDemo) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0];
      document.querySelector(`input[name="${firstErrorKey}"]`)?.focus();
    }
  }, [errors]);

  return {
    state,

    handleSubmit,
    control,
    errors,
    setValue,
    clearErrors,

    onClickSubmit,
    handleCreateSite,
    handleUpdateSite,

    addrSearchModal,
    setAddrSearchModal,
    siteCreateModal,
    setSiteCreateModal,
  };
};

export default useCreateSiteHandler;
