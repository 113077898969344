import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSearchSite from "../useQuery/useSearchSite";
import useAttendSite from "../useMutation/useAttendSite";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/action";

const useAttendSiteHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [siteAttendSuccessModal, setSiteAttendSuccessModal] = useState(false);

  const {
    data,
    isLoading,
    refetch: handleSearchSite,
  } = useSearchSite(false, keyword);
  let siteList = data?.data.siteList || [];

  const siteRegistrationData = {
    site: {
      no: "registration",
      name: "신규 현장 등록",
    },
  };

  siteList = [...siteList, siteRegistrationData];

  const attendSuccessCb = ({ status }) => {
    if (status === "success") {
      if (user.isDemo) {
        const userData = {
          ...user,
          organizationState: "PENDING_SUBORGANIZATION",
        };
        dispatch(actions.setUser(userData));
        setSiteAttendSuccessModal(true);
        return;
      }
      setSiteAttendSuccessModal(true);
      return;
    }
  };

  const attendErrorCb = (error) => {
    console.error("attend failed: ", error);
  };

  const { mutate: attendSite } = useAttendSite(attendSuccessCb, attendErrorCb);

  const handleAttendSite = () => {
    attendSite({ siteNo: selectedSite.no });
  };

  const handleClickSite = (site) => {
    setSelectedSite(site);
  };

  const handleSearch = () => {
    setSearchKeyword(keyword);
    handleSearchSite();
  };

  const moveToCreateSitePage = () => {
    navigate("/my_page/my_info/create_site");
  };

  return {
    keyword,
    setKeyword,
    searchKeyword,
    selectedSite,
    setSelectedSite,
    isLoading,
    siteList,
    handleAttendSite,
    handleClickSite,
    handleSearch,
    moveToCreateSitePage,
    siteAttendSuccessModal,
    setSiteAttendSuccessModal,
  };
};

export default useAttendSiteHandler;
