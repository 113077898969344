import { useCallback, useEffect, useState } from "react";
import { invoiceListItemApi } from "../../../api/auth";

export default function useInvoiceListItem(invoice) {
  const [invoiceListItem, setInvoiceListItem] = useState();

  const fetchInvoiceListItem = useCallback(async (invoiceNo) => {
    const response = await invoiceListItemApi(invoiceNo);
    if (response.status === "success") {
      setInvoiceListItem(response.data);
    }
  }, []);

  useEffect(() => {
    fetchInvoiceListItem(invoice?.customerInvoice.no);
  }, [invoice, fetchInvoiceListItem]);

  return { invoiceListItem, fetchInvoiceListItem };
}
