const types = {
  //user
  SET_USER: "SET_USER",

  //token
  SET_TOKEN: "SET_TOKEN",
  SET_PUSH_TOKEN: "SET_PUSH_TOKEN",

  //chat
  SET_SOCKET: "SET_SOCKET",

  //excel
  SET_EXCEL: "SET_EXCEL",

  //excel - 추가파일
  SET_ATTACH_EXCEL: "SET_ATTACH_EXCEL",

  //activity (keyword etc...)
  SET_ACTIVITY: "SET_ACTIVITY",
};

export default types;
