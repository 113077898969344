import React, { useState, useEffect } from "react";
//library
import { useNavigate } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/requestComplete.css";
import swal from "sweetalert";

// import usePreventBack from "../main/customHooks/usePreventBack";
// import usePreventBack from "../main/customHooks/usePreventBack";
import usePreventBack from "../main/customHooks/usePreventBack";
import { useSelector } from "react-redux";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";

export default function RequestComplete() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  //뒤로가기 방지
  usePreventBack();

  return (
    <div className="request_complete_wrap">
      <Mheader title="주문완료" />
      <div className="request_complete_container">
        <div className="top_box">
          <div className="title">PB상품</div>

          <OrderBreadCrumb
            step1="PB상품 선택"
            step2="상세정보 입력"
            step3="상세정보 확인"
            step4="주문완료"
            isActive={4}
          />
        </div>

        <div className="request_complete_text">
          <h2>PB상품 주문이 완료되었습니다.</h2>
        </div>
        <div className="btn_box pb_btn_box">
          <div className="view_estimate_info">
            <p
              onClick={() => {
                userData.constructionType === "SUBCONTRACTOR"
                  ? navigate("/management/pb/order/1")
                  : navigate("/management/order/1");
              }}
            >
              주문 관리 보기
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
