import { z } from "zod";

export const updatePwdSchema = z
  .object({
    old_password: z
      .string()
      .min(1, { message: "기존 비밀번호는 필수 항목입니다." }),
    new_password: z
      .string()
      .min(8, {
        message:
          "영문, 숫자, 특수문자를 혼합한 8-15자리의 비밀번호를 입력해주세요.",
      })
      .max(15, {
        message:
          "영문, 숫자, 특수문자를 혼합한 8-15자리의 비밀번호를 입력해주세요.",
      })
      .regex(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/, {
        message:
          "영문, 숫자, 특수문자를 혼합한 8-15자리의 비밀번호를 입력해주세요.",
      }),
    new_password_check: z
      .string()
      .min(1, { message: "비밀번호가 일치하지 않습니다." }),
  })
  .refine((data) => data.old_password !== data.new_password, {
    message: "기존 비밀번호와 동일합니다.",
    path: ["new_password"],
  })
  .refine((data) => data.new_password === data.new_password_check, {
    message: "비밀번호가 일치하지 않습니다.",
    path: ["new_password_check"],
  });
