import styled from "@emotion/styled";

export const Wrapper = styled.div``;

// =========
// DashBoard
// =========
export const DashboardContainer = styled.div`
  width: 1400px;
  margin: 0 auto;
  padding: 60px 30px 150px 230px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 5rem 5.5rem 0 5.5rem;
  }
`;

export const DashBoardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  margin-bottom: 90px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-bottom: 0;
  }
`;

// ==================
// DashBoard - header
// ==================
export const ContentHeader = styled.div`
  width: 100%;
  height: 100px;
  background: #f2f5f9;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  @media (max-width: 1080px) {
    height: 10rem;
    padding: 0 2.5rem;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  padding-right: 15px;
  @media (max-width: 1080px) {
    font-size: 4.5rem;
  }
`;

export const SelectedDate = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  padding-left: 15px;
`;

// ================
// Dashboard - body
// ================
export const ContentBody = styled.div`
  width: 100%;
  /* height: 175px; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 60px;

  @media (max-width: 1080px) {
    width: 100%;
    padding: 3rem 0;
    flex-wrap: wrap;
  }

  button {
    width: 130px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
    border: 2px solid rgba(0, 40, 99, 0.8);
    border-radius: 4px;
    font-weight: 700;
    font-size: 18px;
    color: #254574;
    transition: all 0.2s;
    &:hover {
      background: #254574;
      color: #ffffff;
    }
    @media (max-width: 1080px) {
      width: 82%;
      height: 10rem;
      font-size: 4.5rem;
    }
  }
`;

export const DivideLine = styled.div`
  opacity: 0.3;
  border: 1px solid #000000;
  height: 130px;
`;

export const OrderStateWrap = styled.div`
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1080px) {
    width: 30%;
  }
`;

export const OrderState = styled.div`
  height: 40px;
  font-weight: 700;
  font-size: 18px;
  @media (max-width: 1080px) {
    height: 7rem;
    font-size: 4rem;
  }
`;

export const OrderStartBtn = styled.button`
  background: ${(props) => {
    if (props.firstData?.searchData === "START") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.firstData?.searchData === "START") return "#ffffff";
  }} !important;
`;

export const OrderCheckBtn = styled.button`
  background: ${(props) => {
    if (props.firstData?.searchData === "CHECK") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.firstData?.searchData === "CHECK") return "#ffffff";
  }} !important;
`;

export const OrderDoneBtn = styled.button`
  background: ${(props) => {
    if (props.firstData?.searchData === "DONE") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.firstData?.searchData === "DONE") return "#ffffff";
  }} !important;
`;

export const OrderTestedBtn = styled.button`
  background: ${(props) => {
    if (props.firstData?.searchData === "TESTED") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.firstData?.searchData === "TESTED") return "#ffffff";
  }} !important;
`;

export const OrderEndBtn = styled.button`
  background: ${(props) => {
    if (props.firstData?.searchData === "END") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.firstData?.searchData === "END") return "#ffffff";
  }} !important;
`;

export const OrderAllBtn = styled.button`
  background: ${(props) => {
    if (props.firstData?.searchData === "ALL") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.firstData?.searchData === "ALL") return "#ffffff";
  }} !important;
`;

// ==================
// Dashboard - footer
// ==================
export const ContentFooter = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f2f5f9;
  border-top: 1px solid #dddddd;
  span {
    color: #ff6600;
  }
  @media (max-width: 1080px) {
    height: 14rem;
    padding: 0 2.4rem;
  }
`;

export const CancelNumBtn = styled.button`
  width: 130px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: #254574;
  margin-left: 30px;
  transition: all 0.2s;
  &:hover {
    background: #254574;
    color: #ffffff;
  }
  background: ${(props) => {
    if (props.firstData?.searchData === "CANCEL") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.firstData?.searchData === "CANCEL") return "#ffffff";
  }} !important;
  @media (max-width: 1080px) {
    width: 20rem;
    height: 8rem;
    font-size: 3.5rem;
    margin-left: 0;
  }
`;

export const RejectNumBtn = styled.button`
  width: 130px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: #254574;
  margin-left: 30px;
  transition: all 0.2s;
  &:hover {
    background: #254574;
    color: #ffffff;
  }
  background: ${(props) => {
    if (props.firstData?.searchData === "REJECT") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.firstData?.searchData === "REJECT") return "#ffffff";
  }} !important;
  @media (max-width: 1080px) {
    width: 20rem;
    height: 8rem;
    font-size: 3.5rem;
    margin-left: 3rem;
  }
`;

// ==============
// OrderCreateBtn
// ==============
export const OrderCreateBtnWrapper = styled.div`
  float: right;
  width: 210px;
  height: 50px;
  background: linear-gradient(
    89.81deg,
    #254574 46.33%,
    rgba(37, 69, 116, 0.5) 103.8%
  );
  border-radius: 6px;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`;

export const OrderCreateBtn = styled.button`
  background: linear-gradient(
    89.81deg,
    #254574 46.33%,
    rgba(37, 69, 116, 0.5) 103.8%
  );
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
`;

export const OrderCreateImg = styled.img`
  width: 24px;
  height: 24px;
`;

// =========
// OrderList
// =========
export const OrderListWrapper = styled.div`
  width: 100%;
  height: 215px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  margin-bottom: 40px;
`;

// ==================
// OrderList - header
// ==================
export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border-bottom: 1px solid #dddddd;
  background: #f2f5f9;
`;

export const OrderNo = styled.div`
  width: 25%;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
`;

export const SiteName = styled.div`
  width: 25%;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
`;

export const OrderStep = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  div {
    position: relative;
  }

  div:not(:first-of-type)::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -25px;
    border-bottom: 25px solid #ffffff;
    border-left: 25px solid transparent;
    z-index: 10;
  }

  div:not(:last-of-type)::before {
    content: "";
    position: absolute;
    right: -25px;
    border-top: 25px solid #ffffff;
    border-right: 25px solid transparent;
    z-index: 10;
  }

  div:nth-child(1) {
    border-radius: 5px 0 0 5px;
    border-bottom: ${(props) => {
      if (props.el.or_state === "START") return "25px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "START") return "#ffffff";
    }};
  }

  div:nth-child(1)::before {
    border-top: ${(props) => {
      if (props.el.or_state === "START") return "25px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "START") return "#ffffff";
    }};
  }

  div:nth-child(2) {
    background: ${(props) => {
      if (props.el.or_state === "CHECK") return "#254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "CHECK") return "#ffffff";
    }};
  }

  div:nth-child(2)::before {
    border-top: ${(props) => {
      if (props.el.or_state === "CHECK") return "25px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "CHECK") return "#ffffff";
    }};
  }

  div:nth-child(2)::after {
    border-bottom: ${(props) => {
      if (props.el.or_state === "CHECK") return "25px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "CHECK") return "#ffffff";
    }};
  }

  div:nth-child(3) {
    background: ${(props) => {
      if (props.el.or_state === "DONE") return "#254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "DONE") return "#ffffff";
    }};
  }

  div:nth-child(3)::before {
    border-top: ${(props) => {
      if (props.el.or_state === "DONE") return "25px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "DONE") return "#ffffff";
    }};
  }

  div:nth-child(3)::after {
    border-bottom: ${(props) => {
      if (props.el.or_state === "DONE") return "25px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "DONE") return "#ffffff";
    }};
  }

  div:nth-child(4) {
    background: ${(props) => {
      if (props.el.or_state === "TESTED") return "#254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "TESTED") return "#ffffff";
    }};
  }

  div:nth-child(4)::before {
    border-top: ${(props) => {
      if (props.el.or_state === "TESTED") return "25px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "TESTED") return "#ffffff";
    }};
  }

  div:nth-child(4)::after {
    border-bottom: ${(props) => {
      if (props.el.or_state === "TESTED") return "25px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "TESTED") return "#ffffff";
    }};
  }

  div:nth-child(5) {
    border-radius: 0 5px 5px 0;
    background: ${(props) => {
      if (props.el.or_state === "END") return "#254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "END") return "#ffffff";
    }};
  }

  div:nth-child(5)::after {
    border-bottom: ${(props) => {
      if (props.el.or_state === "END") return "25px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.or_state === "END") return "#ffffff";
    }};
  }
`;

export const Step = styled.div`
  width: 100%;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-weight: 600;
  font-size: 13px;
  margin-left: 28px;
  background: #ffffff;
  color: #333333;
  filter: drop-shadow(1.5px 1.5px 1.5px rgba(0, 0, 0, 0.25));
`;

// ================
// OrderList - body
// ================
export const ListBody = styled.div`
  width: 100%;
  height: 175px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
`;

export const ListInfo = styled.div`
  width: 60%;
`;

export const BadgeWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Badge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #254574;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-right: 10px;
  background: ${(props) =>
    (props.el.or_state === "START" && "#107C41") ||
    (props.el.or_state === "CHECK" && "#005BAA") ||
    (props.el.or_state === "DONE" && "#005BAA") ||
    (props.el.or_state === "TESTED" && "#005BAA") ||
    (props.el.or_state === "REJECT" && "#ff6600") ||
    (props.el.or_state === "CANCEL" && "#9C9EA1") ||
    (props.el.or_state === "END" && "#9C9EA1")};
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const TypeBadge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #fff;
  border: 1px solid
    ${(props) =>
      (props.el.bid_type === "A" && "#33C481") ||
      (props.el.bid_type === "S" && "#21A366") ||
      (props.el.bid_type === "T" && "#0064FF") ||
      (props.el.bid_type === "P" && "#005BAA") ||
      (props.el.bid_type === "Q" && "#0096D1") ||
      (props.el.bid_type === "B" && "#11366B") ||
      (props.el.bid_type === "R" && "#ff6600") ||
      (props.el.bid_type === "D" && "#11366B")};
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) =>
    (props.el.bid_type === "A" && "#33C481") ||
    (props.el.bid_type === "S" && "#21A366") ||
    (props.el.bid_type === "T" && "#0064FF") ||
    (props.el.bid_type === "P" && "#005BAA") ||
    (props.el.bid_type === "Q" && "#0096D1") ||
    (props.el.bid_type === "R" && "#ff6600") ||
    (props.el.bid_type === "B" && "#11366B") ||
    (props.el.bid_type === "D" && "#11366B")};
  margin-bottom: 10px;
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const CompanyName = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  margin-right: 20px;
`;

export const ProductName = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  margin-left: 20px;
`;

export const DetailInfo = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #999999;
`;

export const BidType = styled.span`
  display: inline-block;
  width: 250px;
  margin-right: 50px;
  margin-bottom: 10px;
`;

export const TotalAmount = styled.span`
  display: inline-block;
  width: 300px;
`;

export const OrderRegdate = styled.span`
  display: inline-block;
  width: 250px;
  margin-right: 50px;
`;

export const OrderEdate = styled.span`
  display: inline-block;
  width: 250px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const isModifiedText = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #ff6600;
  margin-bottom: 5px;
`;

export const OrderContentBtn = styled.button`
  width: 145px;
  height: 40px;
  border: 2px solid rgba(0, 40, 99, 0.8);
  filter: drop-shadow(0px 4px 3px rgba(67, 67, 87, 0.31));
  border-radius: 4px;
  background: #ffffff;
  color: #313e57;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
  transition: all 0.2s;
  color: ${(props) => props.modified && "#ff6600"};
  border: ${(props) => props.modified && "2px solid #ff6600"};
  &:hover {
    background: #313e57;
    background: ${(props) => props.modified && "#ff6600"};
    color: #ffffff;
  }
`;

export const TestedSheetBtn = styled.button`
  width: 145px;
  height: 40px;
  border: 2px solid rgba(0, 40, 99, 0.8);
  filter: drop-shadow(0px 4px 3px rgba(67, 67, 87, 0.31));
  border-radius: 4px;
  background: #ffffff;
  color: #313e57;
  font-weight: 700;
  font-size: 16px;
  transition: all 0.2s;
  &:hover {
    background: #313e57;
    color: #ffffff;
  }
`;

export const RequestPaymentBtn = styled.button`
  width: 145px;
  height: 40px;
  border: 2px solid rgba(0, 40, 99, 0.8);
  filter: drop-shadow(0px 4px 3px rgba(67, 67, 87, 0.31));
  border-radius: 4px;
  background: #ffffff;
  color: #313e57;
  font-weight: 700;
  font-size: 16px;
  transition: all 0.2s;
  &:hover {
    background: #313e57;
    color: #ffffff;
  }
`;

export const AlignContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AlignWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1080px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5rem 5.5rem 0 5.5rem;
  }
`;

export const SiteSelect = styled.select`
  width: 140px;
  height: 35px;
  line-height: 35px;
  background-color: #fff;
`;

export const DateRangeSelect = styled.select`
  width: 140px;
  height: 35px;
  line-height: 35px;
  margin-left: 10px;
  background-color: #fff;
`;

export const DateWrap = styled.div`
  width: 140px !important;
  margin-left: 10px;
  @media (max-width: 1080px) {
    width: 48% !important;
    margin-left: 0 !important;
  }
  .react-datepicker__input-container input {
    height: 35px !important;
    background: #fff !important;
    @media (max-width: 1080px) {
      height: 8rem !important;
    }
  }
  .react-datepicker__input-container {
    &::after {
      z-index: 1;
    }
  }
`;

export const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchAlignWrap = styled.div`
  position: relative;
  width: 91%;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #ccc;
`;

export const SearchInput = styled.input`
  width: 92%;
  height: 33px;
  line-height: 33px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 3px;
  border: none;
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 20px; // 아이콘을 원하는 위치로 조정하세요
  transform: translateY(-50%);
  img {
    width: 17px;
    height: 17px;
    cursor: pointer;
  }
`;

export const ResetIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8%;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
`;

export const SearchResultWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export const PreparationBtn = styled.button`
  margin-left: 20px;
`;

export const SearchResult = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  > span {
    color: #ff6600;
  }
`;

export const ExcelDownBtn = styled.button`
  /* width: 200px; */
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  border: 2px solid rgba(0, 40, 99, 0.8);
  filter: drop-shadow(0px 4px 3px rgba(67, 67, 87, 0.31));
  border-radius: 4px;
  background: #ffffff;
  color: #313e57;
  font-weight: 700;
  font-size: 16px;
  transition: all 0.2s;
  margin-left: 20px;
  opacity: ${(props) => props.disabled && "0.2"};
  cursor: ${(props) => props.disabled && "not-allowed"};
  &:not(:disabled):hover {
    background: #313e57;
    color: #ffffff;
  }
`;

export const ExcelIcon = styled.img`
  width: 18px;
  height: 18px;
`;
export const ExcelText = styled.span`
  margin-left: 10px;
`;
