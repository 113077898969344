import types from '../action/types';
import { userDefault } from './default';

export default (state = userDefault, action) => {
  switch (action.type) {
    case types.SET_USER:
      return action.payload;

    default:
      return state;
  }
};
