import { Divider } from "antd";
import Typography from "../../../../../../../component/ui/typography/Typography";

import { COLOR } from "../../../../../../../constant/Palette";
import s from "./orgInfo.module.css";

const OrgInfo = ({ org, logoUrl, isDemo }) => {
  return (
    <div className={s.container}>
      <div className={s.title}>
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          회사 정보
        </Typography>
      </div>

      <Divider className={s.divider} />

      <div className={s.logo}>
        <img src={logoUrl} alt="logo" />
      </div>

      <div className={s.info_container}>
        <div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              회사명
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {org?.name}
            </Typography>
          </div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              사업자 등록 번호
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {org?.num}
            </Typography>
          </div>
        </div>

        <div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              대표자명
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {org?.ceo}
            </Typography>
          </div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              업종 / 업태
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {org?.sec} / {org?.bc}
            </Typography>
          </div>
        </div>

        <div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              사업자 구분
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {org?.type === "C" ? "법인" : "개인"}
            </Typography>
          </div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              주소
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {org?.addr1}, {org?.addr2}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgInfo;
