import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSearchSite from "../useQuery/useSearchSite";
import useSwitchSite from "../useMutation/useSwitchSite";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/action";

const useSwitchSiteHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { mutate: switchSite } = useSwitchSite();
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedSite, setSelectedSite] = useState("");

  const {
    data,
    isLoading,
    refetch: handleSearchSite,
  } = useSearchSite(true, keyword);
  let siteList = data?.data?.siteList || [];
  const constructionNo = data?.data?.constructionNo;
  const constructionName = data?.data?.constructionName;

  const siteRegistrationData = {
    site: {
      no: "registration",
      name: "신규 현장 등록",
    },
  };

  siteList = [...siteList, siteRegistrationData];

  const handleClickSite = (site) => {
    setSelectedSite(site);
  };

  const handleSearch = () => {
    setSearchKeyword(keyword);
    handleSearchSite();
  };

  const onClickOk = () => {
    if (selectedSite.name === "신규 현장 등록") {
      moveToCreateSitePage();
      return;
    }

    switchSite({ siteNo: selectedSite.no });

    const userData = {
      ...user,
      con_num: constructionNo,
      rep_con: constructionName,
      site_num: selectedSite.no,
      rep_site: selectedSite.name,
      isDemo: selectedSite.isDemo,
    };

    dispatch(actions.setUser(userData));
    navigate("/");
  };

  const moveToCreateSitePage = () => {
    navigate("/my_page/my_info/create_site");
  };

  return {
    keyword,
    setKeyword,
    searchKeyword,
    selectedSite,
    setSelectedSite,
    isLoading,
    siteList,
    handleClickSite,
    handleSearch,
    onClickOk,
    moveToCreateSitePage,
  };
};

export default useSwitchSiteHandler;
