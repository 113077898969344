import { Divider } from "antd";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./title.module.css";
import SwitchTypeButton from "../button/SwitchTypeButton";

const Title = ({ setSwitchTypeModal }) => {
  return (
    <div className={s.container}>
      <div className={s.title_container}>
        <div className={s.title_text_container}>
          <Typography component="p" type="h1" color={COLOR.GRAY.L09}>
            마이페이지
          </Typography>
          <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
            내 정보를 확인하고 관리할 수 있습니다.
          </Typography>
        </div>

        <SwitchTypeButton setSwitchTypeModal={setSwitchTypeModal} />
      </div>

      <Divider className={s.divider} />
    </div>
  );
};

export default Title;
