import * as S from "../../asset/css/invoiceListItemBtn.styles";

export default function InvoiceListItemBtn(props) {
  const {
    state,
    invoice,
    excelDownload,
    downloadLinkRef,
    approve,
    reject,
    taxReject,
  } = props;

  return (
    <S.BtnWrap state={state}>
      <S.DownloadBtn onClick={excelDownload}>
        지급요청서 일괄 엑셀 다운
      </S.DownloadBtn>
      <a ref={downloadLinkRef} style={{ display: "none" }} />
      {invoice.customerInvoice.state === "REQUEST" && (
        <S.CompanionBtn onClick={reject}>정산취소</S.CompanionBtn>
      )}
      {invoice.customerInvoice.state === "REQUEST" && (
        <S.ApprovalBtn onClick={approve}>세금계산서 요청</S.ApprovalBtn>
      )}
      {invoice.customerInvoice.state === "ISSUED_INVOICE" && (
        <S.CompanionBtn onClick={taxReject}>세금계산서 반려</S.CompanionBtn>
      )}
    </S.BtnWrap>
  );
}
