import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";

const BizRegUploadButton = ({ setBizRegFile, clearErrors }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setBizRegFile(file);
    clearErrors("biz_reg");
  };

  const onClickUploadButton = () => {
    document.getElementById("file_upload_input").click();
  };

  return (
    <>
      <input
        type="file"
        id="file_upload_input"
        style={{ display: "none" }}
        onChange={handleChangeFile}
      />
      <Button
        type="primary"
        size="small"
        style={{ marginTop: isDesktop ? "22px" : "6.6rem" }}
        onClick={onClickUploadButton}
      >
        찾아보기
      </Button>
    </>
  );
};

export default BizRegUploadButton;
