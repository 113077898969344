import React, { useEffect, useState } from "react";

//css
import "../../asset/css/error.css";
//img
import errorImg from "../../asset/images/404.gif";
//lib
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Mheader from "../../component/common/Mheader";

//export를 꼭해줘야함!
export default function NotFound(props) {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  return (
    <>
      <Mheader title="페이지 에러" />
      <div className="error_wrap">
        <div className="error_code">{/* <img src={errorImg} /> */}</div>
        <div className="error_comment">
          <p>찾을 수 없는 페이지입니다.</p>
          <p>요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨습니다.</p>
        </div>
        <div
          className="error_btn"
          onClick={() => {
            navigate("/");
          }}
        >
          홈으로 이동
        </div>
      </div>
    </>
  );
}
