import useFetchMyPageInfo from "../../../../../../hooks/useQuery/useFetchMyPageInfo";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import Button from "../../../../../../component/ui/button/Button";
import { COLOR } from "../../../../../../constant/Palette";

const RankUpdateSuccessModal = ({
  rankUpdateSuccessModal,
  setRankUpdateSuccessModal,
  setRankUpdateModal,
}) => {
  const { refetch: fetchMyPageInfo } = useFetchMyPageInfo();

  const onClickCancel = () => {
    setRankUpdateSuccessModal(false);
    setRankUpdateModal(false);
    fetchMyPageInfo();
  };

  return (
    <Modal
      size="xsmall"
      type="success"
      open={rankUpdateSuccessModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.BLUE.L05}>
          직급 변경 완료
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="primary" size="small" onClick={onClickCancel}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        직급 변경이 정상적으로 완료되었습니다.
      </Typography>
    </Modal>
  );
};

export default RankUpdateSuccessModal;
