import { useMutation, useQueryClient } from "@tanstack/react-query";
import { switchSiteApi } from "../../api/auth";
import { QUERY_KEYS } from "../../constant/QueryKeys";

const useSwitchSite = (onSuccessCb, onErrorCb) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: switchSiteApi,
    onSuccess: (res) => {
      onSuccessCb(res);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SWITCH_SITE] });
    },
    onError: (error) => {
      onErrorCb(error);
    },
  });
};

export default useSwitchSite;
