import React, { useState, useEffect } from "react";
//library
import { useLocation, useNavigate } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
import Find2 from "./Find2";
import FindNone from "./FindNone";
import FindPw2 from "./FindPw2";
import FindPwNone from "./FindNone";
//css
import "../../asset/css/find.css";

//constant
import { IMP_CODE } from "../../constant/Constant";

//redux
import { useSelector } from "react-redux";

//api
import { findAccount, sendCodeApi, verifyCodeApi } from "../../api/auth";

//sweet alert
import swal from "sweetalert";
import useTimer from "../main/customHooks/useTimer";

export default function Find() {
  const navigate = useNavigate();
  const location = useLocation();
  const goPwTab = location?.state?.goPwTab;
  // const signToken = useSelector((state) => state.token);

  const [tabSwitch, setTabSwitch] = useState(goPwTab ? true : false);
  // const [certUid, setCertUid] = useState(false);
  // const [findInfo, setFindInfo] = useState(false);

  // //constant
  // const authData = {
  //   merchant_uid: `gong_auth_${new Date().getTime()}`,
  // };

  // const onClickCertification = () => {
  //   const { IMP } = window;
  //   IMP.init(IMP_CODE);
  //   IMP.certification(authData, onAuthCertificate);
  // };

  // const onAuthCertificate = async (response) => {
  //   const { success, merchant_uid, error_msg, imp_uid } = response;

  //   if (success) {
  //     if (merchant_uid !== authData.merchant_uid) {
  //       swal(`데이터가 변조되었습니다. 다시 시도해주세요.`, "", "warning");
  //       return;
  //     }
  //     //
  //     const reqData = {
  //       type: tabSwitch ? "password" : "email",
  //       imp_uid: imp_uid,
  //     };
  //     const apiResponse = await findAccount(reqData);

  //     if (apiResponse.status === "success") {
  //       if (!tabSwitch) {
  //         setFindInfo({
  //           email: apiResponse.data,
  //         });
  //       } else {
  //         setFindInfo(true);
  //       }
  //     } else {
  //       setFindInfo(false);
  //     }
  //     setCertUid(imp_uid);
  //   } else {
  //     swal(`본인인증에 실패하였습니다. / 사유 : ${error_msg}`);
  //     return;
  //   }
  // };

  //api

  // const FindFnc = () => {
  //   if (!tabSwitch) {
  //     //아이디 찾는거임
  //     if (findInfo) {
  //       //정보 있을때
  //       return <Find2 email={findInfo.email} />;
  //     } else {
  //       //정보 없을때
  //       return <FindNone />;
  //     }
  //   } else {
  //     //비밀번호 찾는거임
  //     if (findInfo) {
  //       //정보 있을때
  //       return <FindPw2 />;
  //     } else {
  //       //정보 없을때
  //       return <FindPwNone />;
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (signToken !== false) {
  //     navigate("/", { replace: true });
  //   }
  // }, []);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifyNumber, setVerifyNumber] = useState("");
  const [isSend, setIsSend] = useState(false);
  const { timer, running, startTimer, stopTimer } = useTimer();

  // 휴대폰 번호 입력할 때 마다 실행되는 함수
  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    // Ensure only numeric characters are allowed
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Limit the input to 11 characters
    const limitedValue = numericValue.slice(0, 11);

    setPhoneNumber(limitedValue);
  };

  // 인증하기 눌렀을 때 실행되는 함수
  const handleSendButtonClick = () => {
    if (phoneNumber.length === 11 && running === false) {
      sendCodeFnc();
    } else if (phoneNumber.length !== 11) {
      swal(
        "휴대폰 번호를 정확히 입력해주세요.",
        "휴대폰 번호는 하이픈(-) 제외, 11자리 숫자입니다.",
        "error"
      );
    } else if (running === true) {
      swal("이미 인증번호를 보냈습니다.");
    }
  };

  // 인증번호가 보내지고, 타이머 실행하는 함수
  const sendCodeFnc = async () => {
    const response = await sendCodeApi(phoneNumber);
    if (response.status === "success") {
      // swal("카카오톡으로 인증번호가 전송되었습니다.", "", "success");

      startTimer();
      setIsSend(true);
    } else if (response.status === "fail") {
      swal(response.message, "", "error");
    }
  };

  // 다음 눌렀을 때 실행되는 함수
  const handleNextButtonClick = () => {
    // 예외처리
    if (verifyNumber.length === 6) {
      sendVerifyCodeFnc();
    } else if (verifyNumber.length !== 6) {
      swal("올바른 인증번호를 입력해주세요.", "", "error");
    }
  };

  // 코드 맞는지 확인하는 함수
  const sendVerifyCodeFnc = async () => {
    const response = await verifyCodeApi(phoneNumber, verifyNumber);
    if (response.status === "success") {
      // tabswitch에 따라 navigation 분기
      navigate(tabSwitch ? "/find-pw" : "/find-id", {
        state: {
          userNo: response.data.userNo,
        },
      });
    } else if (response.status === "fail") {
      swal(response.message, "", "error");
    }
  };

  // 인증번호 입력할 때 마다 실행되는 함수
  const handleVerifyChange = async (event) => {
    const inputValue = event.target.value;

    setVerifyNumber(inputValue);
  };

  // 핸드폰 인증번호 전송 후 남은 시간 타이머 포맷 함수
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // 탭스위치 변경될 때 마다 초기화
  useEffect(() => {
    setPhoneNumber("");
    setVerifyNumber("");
    setIsSend(false);
    stopTimer();
  }, [tabSwitch]);

  return (
    <div className="find_wrap">
      <Mheader title="아이디·비밀번호 찾기" />
      <div className="find_container completed_container">
        <div className="join_top">
          <h3>아이디·비밀번호 찾기</h3>
          <div className="join_pagenation">
            <p className="active">1</p>
            <p>2</p>
            {tabSwitch && <p>3</p>}
          </div>
        </div>
        <div className="find_info">
          <div className="id_pw_tabs">
            <div
              className={tabSwitch ? null : "find_select"}
              onClick={() => {
                setTabSwitch(false);
              }}
            >
              아이디 찾기
            </div>
            <div
              className={tabSwitch ? "find_select" : null}
              onClick={() => {
                setTabSwitch(true);
              }}
            >
              {" "}
              비밀번호 찾기{" "}
            </div>
          </div>

          <div className="find_inner">
            <div className="find_box">
              <span>휴대폰 번호 입력</span>
              <div className="find_input">
                <input
                  type="text"
                  placeholder="휴대폰 번호를 입력해주세요. ( - 제외 )"
                  onChange={handleInputChange}
                  maxLength={11}
                  value={phoneNumber}
                />
                <div
                  className={`find_send_btn ${
                    phoneNumber.length === 11 && running === false
                      ? "find_active"
                      : ""
                  }`}
                  onClick={handleSendButtonClick}
                >
                  인증번호 발송
                </div>
              </div>
              {isSend && running && (
                <div className="find_timer">{formatTime(timer)}</div>
              )}
            </div>
            <div className="find_box" style={{ marginTop: "30px" }}>
              <span>인증번호</span>
              <div className="find_input">
                <input
                  type="text"
                  placeholder="인증번호를 입력해주세요."
                  onChange={handleVerifyChange}
                  maxLength={6}
                  value={verifyNumber}
                />
              </div>
            </div>
          </div>

          <div>
            <span
              className={`find_next_btn ${
                verifyNumber.length === 6 ? "find_active" : ""
              }`}
              onClick={handleNextButtonClick}
            >
              다음
            </span>
          </div>

          {/* {certUid ? (
            <FindFnc />
          ) : (
            <div className="find_id_1">
              <div className="certify_wrap">
                <h3>휴대폰 본인인증</h3>
                <div className="btn_certify" onClick={onClickCertification}>
                  본인인증
                </div>
                <p>* 휴대폰 본인인증을 완료하면 결과가 표시됩니다.</p>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}
