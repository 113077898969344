import React, { useEffect, useState } from "react";
//library
import { useNavigate, useLocation } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/request.css";

import i_excel from "../../asset/images/excel_icon.png";
import i_excel_active from "../../asset/images/excel_active_icon.png";
import i_choice from "../../asset/images/choice_icon.png";
import i_choice_active from "../../asset/images/choice_active_icon.png";

// redux
import { actions } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { estimateDefault } from "../../redux/reducer/default";

import swal from "sweetalert";
import { useMediaQuery } from "react-responsive";

export default function Request() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const againFirst = location.state?.againFirst;

  //companyInfo가 있는 경우 업체지정주문으로 들어옴.
  const companyInfo = location.state?.companyInfo;
  const isOrder = location.state?.isOrder;

  const [goal, setGoal] = useState({
    g1: false,
    g2: false,
    g3: false,
  });

  const {
    excel_list,
    choice_list,
    bid_period,
    bid_single,
    bid_state,
    all_data,
  } = useSelector((state) => ({
    excel_list: state.estimate.excel_list,
    choice_list: state.estimate.choice_list,
    bid_period: state.estimate.bid_period,
    bid_single: state.estimate.bid_single,
    bid_state: state.estimate.bid_state,
    all_data: state.estimate,
  }));

  const sendData = (step) => {
    let totalBid = {
      excel_list: excel_list,
      choice_list: choice_list,
      bid_period: bid_period,
      bid_single: bid_single,
      bid_state: goal.g1 ? "excel" : "choice",
    };

    if (goal.g1 && !goal.g2) {
      //엑셀선택일 때
      dispatch(actions.setEstimate(totalBid));

      if (step === "go_step2") {
        navigate("/management/bid/request_excel");
      } else if (step === "go_step3") {
        navigate("/management/bid/request_input_info");
      }
    } else if (goal.g2 && !goal.g1) {
      //품목선택일 때
      dispatch(actions.setEstimate(totalBid));

      if (step === "go_step2") {
        navigate("/management/bid/request_choice");
      } else if (step === "go_step3") {
        navigate("/management/bid/request_input_info");
      }
    } else if (!goal.g1 && !goal.g2) {
      //아무것도 선택하지 않았을 때
      swal("견적방법을 선택해주세요.");
    }
  };

  //지정품목주문으로 들어왔을 때
  const decideSendData = () => {
    //액셀 골랐을 때
    if (goal.g1 && !goal.g2) {
      navigate("/management/bid/request_excel", {
        state: {
          companyInfo: companyInfo,
          isOrder: true,
        },
      });
    }
    // 품목 선택 골랐을 때
    else if (goal.g2 && !goal.g1) {
      navigate("/management/bid/request_choice", {
        state: {
          companyInfo: companyInfo,
          isOrder: true,
        },
      });
    }
  };

  // useEffect(() => {
  //   if (
  //     againFirst === undefined &&
  //     (estimateDefault.excel_list !== all_data.excel_list ||
  //       estimateDefault.choice_list !== all_data.choice_list) &&
  //     !isOrder
  //   ) {
  //     swal({
  //       title: "작성중이던 견적이 있습니다.",
  //       text:
  //         excel_list && bid_state === "excel"
  //           ? `"${excel_list.bidProductList[0].name}" 등 ${excel_list.bidProductList.length}건
  //       견적을 이어 작성하시겠습니까?`
  //           : choice_list && bid_state === "choice"
  //           ? `"${choice_list[0].name}" 등 ${choice_list.length}건
  //       견적을 이어 작성하시겠습니까?`
  //           : `이어 작성하시겠습니까?`,
  //       icon: "info",
  //       buttons: ["삭제", "이어하기"],
  //       dangerMode: true,
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         //여기서 스탭에 따라 navigate하기
  //         //어디까지 작성되었느냐에 따라 push하기
  //         if (bid_period?.siteNo) {
  //           navigate("/management/bid/request_input_info");
  //         } else if (excel_list && bid_state === "excel") {
  //           navigate("/management/bid/request_excel");
  //         } else if (choice_list && bid_state === "choice") {
  //           navigate("/management/bid/request_choice");
  //         }
  //       } else {
  //         // swal('견적에 오류가 있습니다.');
  //         dispatch(actions.setEstimate(estimateDefault));
  //       }
  //     });
  //   }
  // }, []);

  return (
    <div className="request_wrap" style={{ paddingTop: 0 }}>
      <Mheader title="견적 / 계약 / 주문" />
      <div className="request_container">
        {isOrder ? (
          <div className="top_progress_bar" style={{ top: "140px" }}>
            <div
              className="menus"
              onClick={() => navigate("/management/request_order")}
            >
              주문방법선택
            </div>
            <div className="menus not_allowed">
              {isOrder ? companyInfo.name : "공급사 선택"}
            </div>
            <div className="active_menus color1">입력 방법 선택</div>
            <div className="menus not_allowed">
              엑셀입력 or
              <br /> 품목선택{" "}
            </div>
            <div className="menus not_allowed">상세정보 입력</div>
            <div className="menus not_allowed">완료</div>
          </div>
        ) : (
          <div className="top_progress_bar" style={{ top: "140px" }}>
            <div className="active_menus color1">견적방법선택</div>
            <div
              className="menus"
              onClick={() => {
                sendData("go_step2");
              }}
            >
              엑셀입력 or
              <br /> 품목선택
            </div>
            <div
              className="menus"
              onClick={() => {
                sendData("go_step3");
              }}
            >
              상세정보 입력
            </div>
            <div
              className="menus not_allowed"
              onClick={() => {
                swal("완료상태로는 이동할 수 없습니다.", "", "error");
              }}
            >
              완료
            </div>
          </div>
        )}

        <ul className="request_list">
          <li
            className={goal.g1 ? "select" : ""}
            onClick={() => setGoal({ g1: true, g2: false })}
          >
            <div className="goal_wrap">
              {isDesktop && (
                <div className="request_icon">
                  <img alt="" src={goal.g1 ? i_excel_active : i_excel} />
                  {/* <img alt='' src={i_excel} /> */}
                </div>
              )}
              <p>엑셀 간편 입력</p>
              <div className="checkbox_for_mo">
                <input
                  type="radio"
                  id="g1"
                  name="견적목적"
                  checked={goal.g1}
                  readOnly
                />
                <label
                  className={goal.g1 ? "check_sub_on" : "check_sub_off"}
                  htmlFor="g1"
                >
                  <span>엑셀 간편 입력</span>
                </label>
              </div>
              <p>
                <div>엑셀 파일을 이용하여</div>
                <div>간편주문이 가능합니다.</div>
                {isDesktop && <div>바로가기 →</div>}
              </p>
            </div>
          </li>
          <li
            className={goal.g2 ? "select" : ""}
            onClick={() => setGoal({ g1: false, g2: true })}
          >
            <div className="goal_wrap">
              {isDesktop && (
                <div className="request_icon">
                  <img alt="" src={goal.g2 ? i_choice_active : i_choice} />
                  {/* <img alt='' src={i_choice} /> */}
                </div>
              )}
              <p>품목 직접 선택</p>
              <div className="checkbox_for_mo">
                <input
                  type="checkbox"
                  id="g2"
                  name="견적목적"
                  checked={goal.g2}
                  readOnly
                />
                <label
                  className={goal.g2 ? "check_sub_on" : "check_sub_off"}
                  htmlFor="g2"
                >
                  <span>품목 직접 선택</span>
                </label>
              </div>
              <p>
                <div>원하는 품목을 선택하여</div>
                <div>주문이 가능합니다.</div>
                {isDesktop && <div>바로가기 →</div>}
              </p>
            </div>
          </li>
          {/* <li
            className={goal.g3 ? 'select' : ''}
            onClick={() => setGoal({ g1: false, g2: false, g3: true })}
          >
            <div className='goal_wrap'>
              <div className='request_icon'>
                
                <img alt='' src={goal.g3 ? i_choice_active : i_choice} />
              </div>
              <p>계약서 기반 주문</p>
              <div className='checkbox_for_mo'>
                <input
                  type='checkbox'
                  id='g3'
                  name='견적목적'
                  checked={goal.g3}
                  readOnly
                />
                <label
                  className={goal.g3 ? 'check_sub_on' : 'check_sub_off'}
                  htmlFor='g3'
                >
                  <span>계약서 기반 주문</span>
                </label>
              </div>
              <p>
                <span>이전에 진행했던 계약서를 선택하여</span>
                <span>주문할 수 있습니다.</span>
              </p>
            </div>
          </li> */}
        </ul>
        <div
          className="blue_btn"
          onClick={() => {
            isOrder ? decideSendData() : sendData("go_step2");
          }}
        >
          다음
        </div>
      </div>
    </div>
  );
}
