import React, { useState } from 'react';
import i_close from '../../../asset/images/w-cancel-icon.png';
import i_remove from '../../../asset/images/w-cancle-bt.png';

import { chatUploadFile, imgUpApi } from '../../../api/auth';

//sweet alert
import swal from 'sweetalert';


export default function ChatImgPop({ setImgPop, roomNo, afterUpload }) {
  // state
  const [selectedFile, setSelectedFile] = useState(false);
  

  //method
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const _chatUploadFile = async () => {
    if (!selectedFile) {
      swal( "이미지를 먼저 선택해주세요." ,  "" ,  "warning" )
      return;
    }

    let formData = new FormData();
    formData.append('file', selectedFile);

    // const response = await chatUploadFile(formData);
    const response = await imgUpApi(formData);
    if (response.status === 'success') {
      const path = response.data.f_path;
      afterUpload('image', path);
      setImgPop(false);
    } else {
      swal( "이미지 업로드 중 문제가 발생하였습니다." ,  "" ,  "warning" )
    }
  };

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setImgPop(false)}></div>
      <div className='chat_img_pop'>
        <div className='close' onClick={() => setImgPop(false)}>
          <img src={i_close} alt='' />
        </div>
        <h3 className='pop_tit'>이미지 첨부</h3>
        <div className='chat_img_pop_info'>
          <div className='chat_upload_img'>
            {selectedFile && <img src={URL.createObjectURL(selectedFile)} />}
          </div>
          <div className='img_upload'>
            <input
              type='file'
              id='upload'
              accept='image/*'
              onChange={handleFileChange}
            />
            <input
              type='text'
              value={
                selectedFile && selectedFile.name
                  ? selectedFile.name
                  : '파일을 선택하세요'
              }
              readOnly
            />
            <label htmlFor='upload'>찾아보기</label>
            {selectedFile && selectedFile.name && (
              <span
                className='file_remove'
                onClick={() => {
                  setSelectedFile(false);
                }}
              >
                <img src={i_remove} alt='' />
              </span>
            )}
          </div>
          <div className='chat_img_text'>
            <p>*최대 10MB까지 업로드가 가능합니다.</p>
            <p>*JPGE,BMP,PNG파일만 업로드 가능합니다.</p>
          </div>
          <div className='chat_img_btn_box btn_box'>
            <div className='cancel'>
              <p onClick={() => setImgPop(false)}>취소</p>
            </div>
            <div className='ok'>
              <p onClick={() => _chatUploadFile()}>확인</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
