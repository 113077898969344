import Typography from "../../../../../../component/ui/typography/Typography";
import Checkbox from "../../../../../../component/ui/checkbox/Checkbox";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./agreementCheckbox.module.css";
import ArrowRightSVG from "../../../../../../asset/images/svg/ArrowRightSVG";

const agreementOptions = [
  { text: "[필수] 이용 약관 동의", link: "terms" },
  { text: "[필수] 개인정보처리방침 약관동의", link: "privacy" },
  { text: "[필수] 개인정보수집 이용동의", link: "privacyAgree" },
  { text: "[필수] 만 14세 이상입니다.", link: null },
];

const AgreementCheckbox = ({ checkedList, setCheckedList }) => {
  const checkAll = agreementOptions.length === checkedList.length;

  const indeterminate =
    checkedList.length > 0 && checkedList.length < agreementOptions.length;

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked ? agreementOptions.map((option) => option.text) : []
    );
  };

  const onChange = (option) => {
    const updatedList = checkedList.includes(option)
      ? checkedList.filter((item) => item !== option)
      : [...checkedList, option];
    setCheckedList(updatedList);
  };

  const moveToAgreementDetailPage = (link) => {
    window.open(`https://customer.gongsaero.com/${link}`);
  };

  return (
    <>
      <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
        약관 동의
      </Typography>

      <div className={s.checkbox_container}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
            전체 약관 동의
          </Typography>
        </Checkbox>

        {agreementOptions.map((option) => (
          <div className={s.option_container} key={option.text}>
            <Checkbox
              checked={checkedList.includes(option.text)}
              onChange={() => onChange(option.text)}
            >
              <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                {option.text}
              </Typography>
            </Checkbox>
            {option.link && (
              <div
                className={s.svg_container}
                onClick={() => moveToAgreementDetailPage(option.link)}
              >
                <ArrowRightSVG size="medium" />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default AgreementCheckbox;
