import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useState } from "react";
import useUpdatePassword from "../useMutation/useUpdatePassword";
import { updatePwdSchema } from "../../pages/myPage/my-info/components/modal/pwd-update/schema/schema";

const useUpdatePwdModalHandler = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    resolver: zodResolver(updatePwdSchema),
    mode: "all",
    defaultValues: {
      old_password: "",
      new_password: "",
      new_password_check: "",
    },
  });

  const [pwdUpdateSuccessModal, setPwdUpdateSuccessModal] = useState(false);

  const onUpdatePwdSuccessCb = ({ status }) => {
    if (status === "fail") {
      setError("old_password", {
        type: "manual",
        message: "비밀번호가 올바르지 않습니다. 다시 한번 확인해 주세요.",
      });
    }

    if (status === "success") {
      setPwdUpdateSuccessModal(true);
      return;
    }
  };

  const onUpdatePwdErrorCb = ({ error }) => {
    console.error("pwd update error", error);
  };

  const { mutate: updatePwd } = useUpdatePassword(
    onUpdatePwdSuccessCb,
    onUpdatePwdErrorCb
  );

  const handleUpdatePwd = (data) => {
    updatePwd({
      oldPassword: data.old_password,
      newPassword: data.new_password,
    });
  };

  return {
    handleSubmit,
    control,
    errors,
    pwdUpdateSuccessModal,
    setPwdUpdateSuccessModal,
    handleUpdatePwd,
  };
};

export default useUpdatePwdModalHandler;
