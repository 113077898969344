import React, { useEffect, useState } from "react";
//library
import { Link, useNavigate } from "react-router-dom";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import fire_waste from "../../../asset/images/fire_waste.png";
import fire_waste_back from "../../../asset/images/no_fire_waste.png";
import no_fire_waste from "../../../asset/images/fire_waste_back.png";
import no_fire_waste_back from "../../../asset/images/no_fire_waste_back.png";
import { gunnyListApi } from "../../../api/auth";
import s from "../../../asset/css/requestPbPop.module.css";
import swal from "sweetalert";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";

const PB_VALUE = 210;
const wasteList = [
  fire_waste,
  no_fire_waste,
  fire_waste_back,
  no_fire_waste_back,
];

export default function PbOrderPop({ setPbPop }) {
  const navigate = useNavigate();
  const [pbList, setPbList] = useState([]);
  const userData = useSelector((state) => state.user);

  const [optionValue, setOptionValue] = useState([
    {
      pbProductNo: 1,
      pbName: "폐기물 분리 마대(40kg) / 가연성",
      count: 1000,
    },
    {
      pbProductNo: 2,
      pbName: "폐기물 분리 마대(40kg) / 불연성",
      count: 0,
    },
  ]);

  const handleIncrement = (e, pbProductNo) => {
    setOptionValue((prevOptionValue) => {
      return prevOptionValue.map((item) =>
        item.pbProductNo === pbProductNo
          ? // count값을 내리고 반환함 ( Math함수를 쓰지 않았을 땐 2501 -> 2601 되는 현상 발생)
            { ...item, count: Math.floor(item.count / 100) * 100 + 100 }
          : item
      );
    });
  };

  const handleDecrement = (e, pbProductNo) => {
    setOptionValue((prevOptionValue) => {
      return prevOptionValue.map((item) =>
        item.pbProductNo === pbProductNo
          ? // count값을 올리고 반환함 ( Math.floor를 썼을 땐 2501 -> 2400 되는 현상 발생)
            { ...item, count: Math.ceil(item.count / 100) * 100 - 100 }
          : item
      );
    });
  };

  const handleInputChange = (e, pbProductNo) => {
    setOptionValue((prevOptionValue) => {
      return prevOptionValue.map((item) =>
        item.pbProductNo === pbProductNo
          ? { ...item, count: parseInt(e.target.value, 10) }
          : item
      );
    });
  };

  const countFnc = () => {
    const result = optionValue
      .map((data) => data.count)
      .reduce((acc, current) => acc + current, 0);

    return result.toLocaleString("ko");
  };

  const sumFnc = () => {
    const result = optionValue
      .map((data) => data.count * PB_VALUE)
      .reduce((acc, current) => acc + current, 0);

    return result;
  };

  const nextValidation = () => {
    // filter는 True인 것들만 반환함
    const checkZero = optionValue.filter((value) => value.count < 0);
    const checkHundred = optionValue.filter((value) => value.count % 100 !== 0);
    const checkLimitThousand = optionValue
      .map((data) => data.count)
      .reduce((acc, current) => acc + current, 0);

    const filteredOptionValue = optionValue.filter(
      (elem, i) => elem.count !== 0
    );

    if (checkZero.length !== 0) {
      swal("", "수량을 확인해주세요.", "warning");
    } else if (checkHundred.length !== 0) {
      swal("", "백단위 미만을 포함한 주문은 불가능합니다.", "warning");
    }
    // else if (checkLimitThousand < 1000) {
    //   swal("", "합계 1000장 이상부터 주문 가능합니다.", "warning");
    // }
    else {
      navigate("/pb/order", { state: { optionValue: filteredOptionValue } });
    }
  };

  const gunnyListFnc = async () => {
    const response = await gunnyListApi();
    if (response.status === "success") {
      setPbList(response.data.pbProductList);
    }
  };
  useEffect(() => {
    gunnyListFnc();
  }, []);

  const settings = {
    customPaging: function (i) {
      return (
        <a className="pb_slider_nav_item">
          <img src={`${wasteList[i]}`} alt={`waste${i + 1}`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots pb-slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={s.pop}>
      <div className={s.pop_dim} onClick={() => setPbPop(false)}></div>
      <div className={s.pb_order_pop}>
        <div className={s.close} onClick={() => setPbPop(false)}>
          <img src={i_close} alt="" />
        </div>
        {/* <h3 className={s.pop_tit}>PB상품 주문하기</h3> */}
        <div className={s.container}>
          <div className={s.left_box}>
            <div className={s.img_box}>
              {/* <img src={waste1} />
              <img src={waste2} /> */}

              <Slider {...settings}>
                <div className={s.slick_elem}>
                  <img src={fire_waste} alt="asdf" />
                </div>
                <div className={s.slick_elem}>
                  <img src={no_fire_waste} alt="sadf" />
                </div>
                <div className={s.slick_elem}>
                  <img src={fire_waste_back} alt="fire_waste_back" />
                </div>
                <div className={s.slick_elem}>
                  <img src={no_fire_waste_back} alt="no_fire_waste_back" />
                </div>
              </Slider>
            </div>
          </div>
          <div className={s.right_box}>
            <span className={s.box_name}>폐기물 분리 마대 (40kg)</span>
            <span className={s.box_price}>210원</span>
            {/* <div className={s.pb_contents_container}>
              <div className={s.table_box}>
                <div className={s.table_inner}>
                  <span>규격</span>
                  <span>40kg</span>
                </div>
                <div className={s.table_inner}>
                  <span>크기</span>
                  <span>50 X 70 cm (오차범위 ±3cm)</span>
                </div>
                <div className={s.table_inner}>
                  <span>무게</span>
                  <span>-</span>
                </div>
                <div className={s.table_inner}>
                  <span>단위</span>
                  <span>장</span>
                </div>
              </div>
            </div> */}

            <div className={s.option_container}>
              {pbList.map((elem, idx) => {
                return (
                  <div className={s.option_box}>
                    <div className={s.option_title}>
                      <span>{elem.name}</span>
                    </div>
                    <div className={s.option_contents}>
                      {elem.name === "가연성 폐기물 마대 (40kg)" ? (
                        <div className={s.option_input_container}>
                          <input
                            className={s.option_input}
                            type="text"
                            value={optionValue[idx].count}
                            onChange={(e) => handleInputChange(e, elem.no)}
                          />
                          <div
                            onClick={(e) => handleIncrement(e, elem.no)}
                            className={s.plus}
                          >
                            +
                          </div>
                          <div
                            onClick={(e) => handleDecrement(e, elem.no)}
                            className={s.minus}
                          >
                            -
                          </div>
                          {optionValue[idx].count >= 0 ? (
                            <span className={s.count_elem}>
                              수량:{" "}
                              {optionValue[idx].count.toLocaleString("ko")}장
                            </span>
                          ) : (
                            <span className={s.count_not_allow_elem}>
                              수량을 확인해주세요.
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className={s.option_input_container} />
                      )}
                      {elem.name === "가연성 폐기물 마대 (40kg)" ? (
                        <span className={s.result_elem}>
                          {(optionValue[idx].count * 210).toLocaleString("ko")}
                          원
                        </span>
                      ) : (
                        <span className={s.result_elem}>품절</span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={s.total_calc_container}>
              <div className={s.goods_result_box}>
                <div className={s.desc_container}>
                  <span>
                    {/* * 합계 1,000장 이상부터 주문 가능합니다. */}
                    {/* <br /> */}* 주문수량단위 : 100장
                  </span>
                </div>

                <div className={s.goods_result_inner}>
                  <div className={s.goods_result_elem}>
                    <span className={s.goods_result_title}>공급가액</span>
                    <span className={s.goods_result_contents}>
                      {sumFnc().toLocaleString("ko")}원
                    </span>
                  </div>
                  <div className={s.goods_result_elem}>
                    <span className={s.goods_result_title}>부가세</span>
                    <span className={s.goods_result_contents}>
                      {(sumFnc() * 0.1).toLocaleString("ko")}원
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={s.total_container}>
              <span className={s.total_title}>총 상품 금액</span>
              <div className={s.total_sum_box}>
                <span className={s.count}>총 수량 {countFnc()}장</span>
                <div className={s.divider} />
                <span className={s.sum}>
                  {(sumFnc() + sumFnc() * 0.1).toLocaleString("ko")}원
                </span>
              </div>
            </div>

            <div className={s.btn_container}>
              <div
                className={s.next_btn}
                onClick={() => {
                  nextValidation();
                }}
              >
                다음
              </div>
            </div>
          </div>
        </div>
        <div className={s.info_container}>
          <span className={s.info_title}>제품정보</span>
          <span className={s.info_desc}>
            * 포스코 이앤씨 ESG 정책에 의거하여 추진 중인 사항입니다.
          </span>

          <div className={s.table_box}>
            <table>
              <tr>
                <td>가연성</td>
                <td>
                  소각 가능한 폐기물
                  <br />
                  <br />
                  해당 품목 : 폐합성수지 (플라스틱), 자재 포장 비닐 (천막),
                  폐스티로폼, 폐벽지, 폐목재, 부직포 등
                </td>
              </tr>
              <tr>
                <td>불연성</td>
                <td>
                  소각 불가능한 폐기물
                  <br />
                  <br />
                  해당 품목 : 재활용 폐콘 (아스콘), 폐타일, 폐벽돌 (블럭),
                  세대내부 청소 쓰레기 (불연성){" "}
                </td>
              </tr>
            </table>
          </div>

          <span className={s.info_title}>상세정보</span>

          <div className={s.table_box}>
            <table>
              <tr>
                <td>규격</td>
                <td>40kg</td>
              </tr>
              <tr>
                <td>무게</td>
                <td>-</td>
              </tr>
              <tr>
                <td>크기</td>
                <td>50 X 70 cm (오차범위 ±3cm)</td>
              </tr>
              <tr>
                <td>단위</td>
                <td>장</td>
              </tr>
            </table>
          </div>

          <span className={s.info_title}>배송정보</span>

          <div className={s.table_box}>
            <table>
              <tr>
                <td>배송방법</td>
                <td>직접배송 또는 택배</td>
              </tr>
              <tr>
                <td>배송기간</td>
                <td>
                  {userData.constructionType === "SUBCONTRACTOR"
                    ? "입금"
                    : "주문"}
                  &nbsp;확인 후 일주일 이내
                </td>
              </tr>
              <tr>
                <td>배송비</td>
                <td>포함</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
