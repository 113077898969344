import { useMediaQuery } from "react-responsive";
import DatePicker from "../../../../../../component/ui/date-picker/DatePicker";
import { Controller } from "react-hook-form";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const StartDatePicker = ({ control, errors }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="s_date"
      control={control}
      render={({ field }) => (
        <DatePicker
          {...field}
          id="s_date"
          size="medium"
          width={isDesktop ? 380 : "100%"}
          placeholder="년. 월. 일"
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                공사 시작일{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={
            errors.s_date && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                공사 시작일은 필수 항목입니다.
              </Typography>
            )
          }
          status={errors.s_date && "error"}
        />
      )}
    />
  );
};

export default StartDatePicker;
