import React, { useCallback, useEffect, useState } from "react";
//library
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
//component
//css
import "../../asset/css/site.css";
//etc
import look_icon from "../../asset/images/look_header_icon.png";

import user_icon from "../../asset/images/dashboard-user.png";
import bid_choose_icon from "../../asset/images/dashboard-bid-choose.png";
import order_check_icon from "../../asset/images/dashboard-order-check.png";
import order_done_icon from "../../asset/images/dashboard-order-done.png";
import order_reject_icon from "../../asset/images/dashboard-order-reject.png";
import chat_icon from "../../asset/images/dashboard-chat.png";
import coin_icon from "../../asset/images/dashboard-coin.png";
import pb_banner_img from "../../asset/images/dashboard-banner-pb.png";
import flood_banner_img from "../../asset/images/dashboard-banner-flood.jpg";

//sweet alert
import swal from "sweetalert";
import Mheader from "../../component/common/Mheader";
import { fetchDashboardInfoApi, searchListApi } from "../../api/auth";
import { useSelector } from "react-redux";
import { Pie, G2, measureTextWidth, Column } from "@ant-design/plots";
import { each, groupBy } from "@antv/util";
import ReactTooltip from "react-tooltip";
import ReactDatePicker from "react-datepicker";
import { Empty } from "antd";
import ko from "date-fns/locale/ko"; // 한국어적용

export default function Site() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isCustomer = user.constructionType === "CUSTOMER";

  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });

  // 배너 클릭
  const onClickBanner = () => {
    if (isCustomer) {
      navigate("management/bid/request_excel", {
        state: { fromDashboardFloodBanner: true },
      });
      return;
    }

    navigate("/pb/choice");
  };

  // 모바일 검색
  const [keyword, setKeyword] = useState("");
  const searchFnc = (headerKey) => {
    if (keyword === "" && headerKey === undefined) {
      return swal("검색어를 입력해주세요.", "", "warning");
    }

    let totalKeyword = headerKey ? headerKey : keyword;

    let data = {
      keyword: totalKeyword.replace(/\s/g, ""),
      siteNo: user.site_num,
    };
    searchListFnc(data);
  };

  const searchListFnc = async (data) => {
    // 키워드로 api 부르고, 결과값 가져가기
    const response = await searchListApi(data);
    if (response.status === "success") {
      navigate("/search_list", {
        state: {
          list: response.data,
          keyword: data.keyword,
        },
      });
    }
  };

  const onKeyPress = (e) => {
    if (e.key == "Enter") {
      searchFnc();
    }
  };

  // 대시보드
  const date = new Date();
  const curMonth = date.getMonth() + 1;
  const curYear = new Date().getFullYear();
  const dateString = `${curYear}-${curMonth}-01`;

  // 년월일이 제대로 구성된 날짜 문자열인지 확인
  const isValidDate = !isNaN(Date.parse(dateString));

  // 유효한 날짜인 경우에만 Date 객체 생성
  const chartDate = isValidDate ? new Date(dateString) : new Date();

  const [dashboard, setDashboard] = useState();
  const [orderCompanyChartDate, setOrderCompanyChartDate] = useState(chartDate);
  const [orderTypeQuarterChartDate, setOrderTypeQuarterChartDate] =
    useState(chartDate);
  // 1월이면 올해 데이터 null -> 작년 데이터 보여주기
  const [invoiceChartDate, setInvoiceChartDate] = useState(
    curMonth === 1 ? new Date(`${curYear - 1}-01-01`) : new Date()
  );
  const [orderTypeChartDate, setOrderTypeChartDate] = useState(
    curMonth === 1 ? new Date(`${curYear - 1}-01-01`) : new Date()
  );

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const fetchDashboardInfo = useCallback(async () => {
    try {
      const response = await fetchDashboardInfoApi({
        siteNo: user?.site_num,
        companyMonthDate: formatDate(orderCompanyChartDate),
        ordersTypeQuarterDate: formatDate(orderTypeQuarterChartDate),
        invoiceYearDate: formatDate(invoiceChartDate),
        ordersTypeDate: formatDate(orderTypeChartDate),
      });
      setDashboard(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [
    user.site_num,
    orderCompanyChartDate,
    orderTypeQuarterChartDate,
    invoiceChartDate,
    orderTypeChartDate,
  ]);

  // 공급사별 주문현황
  const pieChartTotalAmount = dashboard?.ordersMonthInfoList?.reduce(
    (acc, cur) => acc + +cur.price,
    0
  );
  const pieChartTotalCount = dashboard?.ordersMonthInfoList?.reduce(
    (acc, cur) => acc + +cur.count,
    0
  );
  const pieConfig = {
    appendPadding: 10,
    data: dashboard?.ordersMonthInfoList,
    angleField: "price",
    colorField: "companyName",
    legend: {
      position: isDesktop ? "right" : "bottom",
    },
    radius: 0.8,
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { name, value, color, data } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">
                        {Number(value)?.toLocaleString("ko")}원 ({data.count}건)
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 12,
        textAlign: "center",
      },
      autoRotate: false,
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  // 주문방식별 비율
  const curQuarter = (date) => {
    const month = date?.getMonth() + 1;

    if (month >= 1 && month <= 3) {
      return 1;
    } else if (month >= 4 && month <= 6) {
      return 2;
    } else if (month >= 7 && month <= 9) {
      return 3;
    } else {
      return 4;
    }
  };
  const donutChartTotalAmount = dashboard?.ordersTypeQuarterInfoList?.reduce(
    (acc, cur) => acc + +cur.price,
    0
  );
  const donutChartTotalCount = dashboard?.ordersTypeQuarterInfoList?.reduce(
    (acc, cur) => acc + +cur.count,
    0
  );
  const renderStatistic = (containerWidth, text, style) => {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2;

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  };
  const donutConfig = {
    appendPadding: 10,
    data: dashboard?.ordersTypeQuarterInfoList,
    angleField: "count",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.7,
    legend: {
      position: isDesktop ? "right" : "bottom",
    },
    meta: {
      value: {
        formatter: (v) => `${v} ¥`,
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
        fontWeight: 500,
      },
      autoRotate: false,
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    },
    statistic: {
      title: {
        offsetY: -8,
        style: {
          fontSize: isDesktop ? "26px" : "20px",
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "rgba(0, 0, 0, 0.65)",
          fontWeight: 500,
        },
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : "총 건수";
          return renderStatistic(d, text, {
            fontSize: 28,
          });
        },
      },
      content: {
        offsetY: 0,
        style: {
          fontSize: isDesktop ? "24px" : "18px",
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#11366B",
          fontWeight: 600,
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `${datum.count}건\n(${datum?.price?.toLocaleString("ko")}원)`
            : `${data?.reduce((r, d) => r + d.count, 0)}건\n(${data
                ?.reduce((r, d) => r + d.price, 0)
                ?.toLocaleString("ko")}원)`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  // 월별 정산금액
  const columnChartTotalAmount = dashboard?.invoiceYearInfoList?.reduce(
    (acc, cur) => acc + +cur.price,
    0
  );
  const annotations = [];
  each(groupBy(dashboard?.invoiceYearInfoList, "month"), (values, k) => {
    const value = values.reduce((a, b) => a + b.price, 0);
    annotations.push({
      type: "text",
      position: [k, value],
      content: `${value?.toLocaleString("ko")}원`,
      style: {
        textAlign: "center",
        fontSize: 12,
        fill: "rgba(0,0,0,0.85)",
      },
      offsetY: -8,
    });
  });

  const columnConfig = {
    data: dashboard?.invoiceYearInfoList,
    isStack: true,
    xField: "month",
    yField: "price",
    seriesField: "code",
    annotations: isDesktop && annotations,
    legend: isDesktop,
    xAxis: {
      label: {
        formatter: (v) => `${v}월`,
      },
    },
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    connectedArea: {
      style: (oldStyle, element) => {
        return {
          fill: "rgba(0,0,0,0.25)",
          stroke: oldStyle.fill,
          lineWidth: 0.5,
        };
      },
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>
              {title}월 (총:{" "}
              {items
                ?.reduce((acc, cur) => acc + +cur.value, 0)
                ?.toLocaleString("ko")}
              원)
            </h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { name, value, color } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">
                        {Number(value)?.toLocaleString("ko")}원
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
    scrollbar: {
      height: isDesktop ? 0 : 8,
    },
    minColumnWidth: 32,
    label: {
      style: {
        fontSize: 12,
      },
      position: "bottom",
      content: (item) => {
        return item.rate !== "0.00" ? `${Number(item.rate).toFixed(0)}%` : null;
      },
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };

  G2.registerInteraction("element-link", {
    start: [
      {
        trigger: "interval:mouseenter",
        action: "element-link-by-color:link",
      },
    ],
    end: [
      {
        trigger: "interval:mouseleave",
        action: "element-link-by-color:unlink",
      },
    ],
  });

  // 주문방식별 월별 추이
  const columnPercentChartTotalAmount =
    dashboard?.ordersTypeYearInfoList?.reduce(
      (acc, cur) => acc + +cur.originPrice,
      0
    );
  const columnPercentConfig = {
    data: dashboard?.ordersTypeYearInfoList,
    xField: "month",
    yField: "ratePrice",
    seriesField: "type",
    isPercent: true,
    isStack: true,
    legend: isDesktop,
    xAxis: {
      label: {
        formatter: (v) => `${v}월`,
      },
    },
    yAxis: {
      label: {
        formatter: (value) => {
          return `${value * 100}%`;
        },
      },
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}월</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { name, color, data } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">
                        {data?.originPrice?.toLocaleString("ko")}원
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
    scrollbar: {
      height: isDesktop ? 0 : 8,
    },
    minColumnWidth: 32,
    label: {
      position: "middle",
      content: (item) => {
        return item.ratePrice !== 0
          ? `${(item.ratePrice * 100).toFixed(0)}%`
          : null;
      },
      style: {
        fontSize: 12,
        fill: "#fff",
      },
    },
    interactions: [
      {
        type: "element-highlight-by-color",
      },
      {
        type: "element-link",
      },
    ],
  };

  useEffect(() => {
    if (user.site_num) {
      fetchDashboardInfo();
    }
  }, [fetchDashboardInfo, user.site_num]);

  return (
    <div className="site_wrap">
      <div className="site_container">
        <Mheader title="대시보드" />
        {isMobile && (
          <div className="search_input_container">
            <div
              className="m_look_icon_container"
              onClick={() => {
                navigate("/look");
              }}
            >
              <img
                src={look_icon}
                className="main_search_icon"
                alt="look_icon"
              />
            </div>

            <input
              type="text"
              className="main_search_input"
              placeholder="주문하실 품목을 입력해주세요."
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              onKeyPress={onKeyPress}
            />
            <span
              className="main_search_btn"
              onClick={() => {
                searchFnc();
              }}
            >
              검색
            </span>
          </div>
        )}
        <div className="dashboard_switch_tab">
          <button
            className="main_dashboard"
            onClick={() => {
              navigate("/");
            }}
          >
            통합 대시보드
          </button>
          <button
            className="ai_data_recommend"
            onClick={() => {
              navigate("/ai_data_recommend", { replace: true });
            }}
          >
            AI 데이터 추천
          </button>
        </div>

        <div className="dashboard_banner_container">
          <img
            src={isCustomer ? flood_banner_img : pb_banner_img}
            onClick={onClickBanner}
            alt="banner"
          />
        </div>

        <div className="dashboard_container">
          <div className="saving_price_container">
            {isDesktop && (
              <div className="coin_icon_container">
                <img src={coin_icon} alt="coin_icon" />
              </div>
            )}

            <div className="saving_price_inner_align">
              <div className="saving_price_title_container">
                <div className="saving_price_title">원가절감액</div>
              </div>

              <div className="saving_price">
                {isDesktop && <span>{`공새로`}</span>}
                {isDesktop && <span>{`를 통해 `}</span>}

                <a data-tip="React-tooltip" data-for="saving">
                  <span>
                    {dashboard?.avgSavingPrice?.toLocaleString("ko")}원
                  </span>
                </a>

                {isDesktop && <span>{`을 절감하였습니다.`}</span>}
              </div>
              <ReactTooltip
                id="saving"
                place="bottom"
                type="dark"
                effect="solid"
                backgroundColor="#E1E5F6"
                textColor="#222"
              >
                <p
                  style={{
                    margin: "10px 0 10px 0",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  <span style={{ marginRight: "30px" }}>
                    최고가 대비 절감액
                  </span>
                  <span>
                    {dashboard?.maxSavingPrice?.toLocaleString("ko")}원
                  </span>
                </p>
                <p
                  style={{
                    margin: "10px 0 10px 0",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  <span style={{ marginRight: "30px" }}>
                    평균가 대비 절감액
                  </span>
                  <span>
                    {dashboard?.avgSavingPrice?.toLocaleString("ko")}원
                  </span>
                </p>
              </ReactTooltip>
            </div>
          </div>

          <div className="dashboard_count_container">
            <div
              className="dashboard_count_inner_container"
              onClick={() => {
                navigate("/management/estimate/1", {
                  state: {
                    fromPage: "CHOOSE",
                  },
                });
              }}
            >
              <img src={bid_choose_icon} alt="bid_choose_icon" />
              <div>
                <div className="dashboard_count_title">투찰마감</div>
                <div className="dashboard_count">
                  {dashboard?.bidChooseCount}건
                </div>
              </div>
            </div>
            <div
              className="dashboard_count_inner_container"
              onClick={() => {
                navigate("/management/order/1", {
                  state: {
                    fromPage: "CHECK",
                  },
                });
              }}
            >
              <img src={order_check_icon} alt="bid_choose_icon" />
              <div>
                <div className="dashboard_count_title">주문확인</div>
                <div className="dashboard_count">
                  {dashboard?.ordersCheckCount}건
                </div>
              </div>
            </div>
            <div
              className="dashboard_count_inner_container"
              onClick={() => {
                navigate("/management/order/1", {
                  state: {
                    fromPage: "DONE",
                  },
                });
              }}
            >
              <img src={order_done_icon} alt="bid_choose_icon" />
              <div>
                <div className="dashboard_count_title">배송완료</div>
                <div className="dashboard_count">
                  {dashboard?.ordersDoneCount}건
                </div>
              </div>
            </div>
            <div
              className="dashboard_count_inner_container"
              onClick={() => {
                navigate("/management/order/1", {
                  state: {
                    fromPage: "REJECT",
                  },
                });
              }}
            >
              <img src={order_reject_icon} alt="bid_choose_icon" />
              <div>
                <div className="dashboard_count_title">검수반려</div>
                <div className="dashboard_count">
                  {dashboard?.ordersRejectCount}건
                </div>
              </div>
            </div>
            <div
              className="dashboard_count_inner_container"
              onClick={() => navigate("/qna")}
            >
              <img src={chat_icon} alt="bid_choose_icon" />
              <div>
                <div className="dashboard_count_title">질의응답</div>
                <div className="dashboard_count">{dashboard?.chatCount}건</div>
              </div>
            </div>
            {isDesktop && (
              <div className="dashboard_count_user_container">
                <img src={user_icon} alt="dashboard_user_icon" />
              </div>
            )}
          </div>

          <div className="dashboard_circle_chart_container">
            <div className="order_company_chart">
              <div className="dashboard_chart_title">
                <span>
                  <span>{`${orderCompanyChartDate?.getFullYear()}년 ${
                    orderCompanyChartDate?.getMonth() + 1
                  }월 `}</span>
                  <span>공급사별 주문현황</span>
                </span>
                <span>{`총 주문금액: ${pieChartTotalAmount?.toLocaleString(
                  "ko"
                )}원 (${pieChartTotalCount}건)`}</span>
              </div>
              <div className="month_picker_container">
                <ReactDatePicker
                  className="month_picker"
                  selected={orderCompanyChartDate}
                  onChange={(date) => setOrderCompanyChartDate(date)}
                  showMonthYearPicker
                  dateFormat="yyyy-MM"
                  locale={ko}
                />
              </div>
              {dashboard?.ordersMonthInfoList?.length >= 1 && (
                <Pie {...pieConfig} className="pie_chart" />
              )}
              {dashboard?.ordersMonthInfoList?.length === 0 && (
                <div className="dashboard_empty_container">
                  <Empty
                    description={<span>주문 내역이 존재하지 않습니다.</span>}
                  />
                </div>
              )}
              <div className="dashboard_chart_footer">
                <div>※ 비율기준: 주문금액</div>
                <div>※ 주문서 발행일 기준</div>
              </div>
            </div>

            <div className="order_type_quarter_chart">
              <div className="dashboard_chart_title">
                <span>
                  <span>{`${orderTypeQuarterChartDate?.getFullYear()}년 ${curQuarter(
                    orderTypeQuarterChartDate
                  )}분기 `}</span>
                  <span>주문방식별 비율</span>
                </span>
                <span>{`총 주문금액: ${donutChartTotalAmount?.toLocaleString(
                  "ko"
                )}원 (${donutChartTotalCount}건)`}</span>
              </div>
              <div className="month_picker_container">
                <ReactDatePicker
                  className="month_picker"
                  selected={orderTypeQuarterChartDate}
                  onChange={(date) => setOrderTypeQuarterChartDate(date)}
                  showQuarterYearPicker
                  dateFormat="yyyy-QQQ"
                  locale={ko}
                />
              </div>
              {dashboard && donutChartTotalAmount > 0 ? (
                <Pie {...donutConfig} className="pie_chart" />
              ) : (
                <div className="dashboard_empty_container">
                  <Empty description={<span>내역이 존재하지 않습니다.</span>} />
                </div>
              )}
              <div className="dashboard_chart_footer">
                <div>※ 비율기준: 주문건수</div>
                <div>※ 주문서 발행일 기준</div>
              </div>
            </div>
          </div>

          <div className="invoice_chart_container">
            <div className="dashboard_chart_title">
              <span>월별 정산금액</span>
              <span>{`${invoiceChartDate.getFullYear()}년 정산 총 금액: ${columnChartTotalAmount?.toLocaleString(
                "ko"
              )}원`}</span>
            </div>
            <div className="year_picker_container">
              <ReactDatePicker
                className="year_picker"
                selected={invoiceChartDate}
                onChange={(date) => setInvoiceChartDate(date)}
                showYearPicker
                dateFormat="yyyy"
              />
            </div>
            {dashboard && columnChartTotalAmount > 0 ? (
              <Column {...columnConfig} className="column_chart" />
            ) : (
              <div className="dashboard_empty_container">
                <Empty description={<span>내역이 존재하지 않습니다.</span>} />
              </div>
            )}
            <div className="dashboard_chart_footer">
              <div>※ 세금계산서 발행일 기준</div>
            </div>
          </div>

          <div className="order_type_chart_container">
            <div className="dashboard_chart_title">
              <span>주문방식별 월별 추이</span>
              <span>{`${orderTypeChartDate.getFullYear()}년 정산 총 금액: ${columnPercentChartTotalAmount?.toLocaleString(
                "ko"
              )}원`}</span>
            </div>
            <div className="year_picker_container">
              <ReactDatePicker
                className="year_picker"
                selected={orderTypeChartDate}
                onChange={(date) => setOrderTypeChartDate(date)}
                showYearPicker
                dateFormat="yyyy"
              />
            </div>
            {dashboard && columnPercentChartTotalAmount > 0 ? (
              <Column {...columnPercentConfig} className="column_chart" />
            ) : (
              <div className="dashboard_empty_container">
                <Empty description={<span>내역이 존재하지 않습니다.</span>} />
              </div>
            )}
            <div className="dashboard_chart_footer">
              <div>※ 세금계산서 발행일 기준</div>
            </div>
          </div>
        </div>
      </div>
      {/* <SiteSelect
        selectSwitch={selectSwitch}
        setSelectSwitch={setSelectSwitch}
        siteList={siteList}
        setSiteList={setSiteList}
        isPath={"main"}
      /> */}
    </div>
  );
}
