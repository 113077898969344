import { Checkbox as AntdCheckbox } from "antd";
import styles from "./checkbox.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export default function Checkbox({
  children,
  checked = false,
  onChange,
  defaultChecked = false,
  disabled = false,
  indeterminate = false,
  ...props
}) {
  const className = cx("common");

  return (
    <AntdCheckbox
      className={className}
      checked={checked}
      onChange={onChange}
      defaultChecked={defaultChecked}
      disabled={disabled}
      indeterminate={indeterminate}
      {...props}
    >
      {children}
    </AntdCheckbox>
  );
}
