import styled from "@emotion/styled";

export const Wrap = styled.div`
  width: 1400px;
  margin: 0 auto;
  padding: 60px 30px 150px 230px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 5rem 5.5rem 15rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  @media (max-width: 1080px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1080px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 26px;
  margin-right: 30px;
  span {
    margin-left: 10px;
    font-size: 15px;
    color: #787878;
  }
  @media (max-width: 1080px) {
    display: none;
  }
`;

export const DateRangeSelect = styled.select`
  width: 140px;
  height: 35px;
  line-height: 35px;
  margin-right: 10px;
  @media (max-width: 1080px) {
    width: 100%;
    height: 13rem;
    margin-bottom: 3rem;
    margin-right: 0;
    font-size: 4.6rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1080px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3rem;
  }
`;
export const DateWrap = styled.div`
  .react-datepicker__input-container input {
    height: 35px !important;
  }
  margin-right: 10px;
  @media (max-width: 1080px) {
    width: 48% !important;
    margin-right: 0;
    .react-datepicker__input-container input {
      height: 13rem !important;
      font-size: 4.6rem;
    }
  }
`;

export const SearchInput = styled.input`
  width: 300px;
  height: 35px;
  line-height: 35px;
  margin-left: 20px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 3rem;
    padding: 6.4rem 0;
    font-size: 4.6rem;
  }
`;

export const SearchBtn = styled.button`
  width: 100px;
  height: 35px;
  font-weight: 500;
  font-size: 18px;
  background: #dbe4f0;
  border-radius: 3px;
  color: #002863;
  font-weight: 500;
  font-size: 18px;
  margin-left: 10px;
  transition: all 0.2s;
  &:hover {
    background: #002863;
    color: #dbe4f0;
  }
  @media (max-width: 1080px) {
    width: 100%;
    margin-left: 0;
    height: 13rem;
    font-size: 4.6rem;
    transition: none;
  }
`;

export const SearchResult = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 10px;
  font-weight: 700;
  span {
    margin-left: 5px;
    color: #ff6600;
  }
  @media (max-width: 1080px) {
    font-size: 4rem;
  }
`;

export const HistoryListWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #f2f5f9;
  color: #020202;
  font-weight: 500;
  font-size: 16px;
  padding: 0 10px;
  border: 1px solid #ddd;
  position: sticky;
  top: 80px;
  span {
    border-right: 1px solid #ddd;
  }
  span:nth-of-type(1) {
    width: 15%;
  }
  span:nth-of-type(2) {
    width: 30%;
  }
  span:nth-of-type(3) {
    width: 20%;
  }
  span:nth-of-type(4) {
    width: 15%;
  }
  span:nth-of-type(5) {
    width: 20%;
    border-right: none;
  }
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  text-align: center;
  border: 1px solid #ddd;
  border-top: none;
  background: #fff;
  color: #222;
  font-size: 16px;
  padding: 0 10px;
  span {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 19px;
  }
  span:nth-of-type(1) {
    width: 15%;
  }
  span:nth-of-type(2) {
    width: 30%;
  }
  span:nth-of-type(3) {
    width: 20%;
  }
  span:nth-of-type(4) {
    width: 15%;
  }
  span:nth-of-type(5) {
    width: 20%;
  }
  button {
    width: 10%;
    height: 35px;
    background: #dbe4f0;
    border-radius: 3px;
    font-weight: 500;
    color: #002863;
    transition: all 0.2s;
    &:hover {
      background: #002863;
      color: #dbe4f0;
    }
  }
`;

export const M_HistoryListContainer = styled.div``;
export const M_HistoryListWrap = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 4rem;
`;

export const M_HistoryListHeader = styled.div`
  font-size: 3.5rem;
  font-weight: 700;
  background: #f2f5f9;
  padding: 4rem;
`;

export const M_HistoryListBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  div {
    margin-bottom: 3rem;
    font-size: 4rem;
  }
  div:nth-of-type(3) {
    display: flex;
    flex-wrap: wrap;
  }
  div:nth-of-type(1) {
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 4rem;
  }
`;
export const M_HistoryListPriceSection = styled.div`
  display: flex;
  gap: 3px;
  span:nth-child(1) {
    color: #787878;
    display: flex;
    align-items: flex-end;
    font-size: 2.5rem;
  }
`;

export const M_HistoryListEtcSection = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 6rem;
  white-space: normal;
  display: ${(props) => {
    if (props.selectTab === props.index) return "flex";
  }};
  img {
    width: 5rem;
    margin-bottom: -1rem;
    margin-right: 2rem;
    height: 5rem;
    cursor: pointer;
    transform: rotate(180deg);
    display: ${(props) => {
      if (props.selectTab === props.index) return "none";
    }};
  }
`;

export const M_HistoryListFooter = styled.div`
  width: 100%;
  padding: 0 4rem 4rem 4rem;
`;
export const M_HistoryAllListFooter = styled.div`
  width: 100%;
`;
export const M_HistoryDetailBtn = styled.button`
  width: 100%;
  height: 12rem;
  background: #dbe4f0;
  border-radius: 3px;
  font-size: 4rem;
  font-weight: 500;
  color: #002863;
  /* transition: all 0.2s; */
  cursor: not-allowed;
  /* &:hover {
      background: #002863;
      color: #dbe4f0;
    } */
`;
export const M_HistoryListNameSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 3rem;
  // p {
  //   font-size: 2rem;
  //   border: 1px solid #787878;
  //   padding: 1rem 3rem;
  //   border-radius: 10px;
  //   color: #787878;
  // }
`;

export const RegionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  span {
    width: 100% !important;
    text-align: left;
  }
  span:nth-child(1) {
    font-size: 16px;
  }
  span:nth-child(2) {
    font-size: 12px;
    color: #787878;
  }
`;
export const NameSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  div {
    text-align: left;
  }
  span {
    width: 100% !important;
    text-align: left;
  }
  span:nth-child(1) {
  }
  span:nth-child(2) {
    font-size: 12px;
    color: #787878;
  }
`;

export const ViewWithOrderBtn = styled.span`
  margin-left: 5px;
  cursor: pointer;
  border: 1px solid #787878;
  padding: 2px 10px;
  border-radius: 10px;
  font-size: 11px !important;
  letter-spacing: -0.3px;
  width: 120px;
  display: inline;
  transition: all 0.2s;
  &:hover {
    border: 1px solid #002863;
    color: #002863 !important;
  }
  p {
    margin-bottom: 10px;
  }
  dd {
    color: #fff;
    background: #002863;
    margin-bottom: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
  }
`;
export const ToolTipBadge = styled.span`
  background: #002863;
`;
export const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 17px;
`;
export const AllPriceSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  span {
    width: 100% !important;
  }
  span:nth-child(1) {
    font-size: 15px;
  }
  span:nth-child(2) {
    font-size: 12px;
    color: #787878;
  }
`;
export const EtcSection = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 13px;
  line-height: 16px;
  padding: 0 10px;
  color: #787878;
`;

export const GoTopBtn = styled.div`
  bottom: 30px;
  background: red;
  transition: 0.3s linear;
  right: 45%;
  transform: transparent(-50%, -50%);

  overflow: visible;
  position: fixed;

  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px;
  border: 0px;
  background: none;
  z-index: 999;
  cursor: pointer;
`;

export const GoTopInner = styled.div`
  display: flex;
  justify-content: center;
  align-center: center;
  margin: 0px;
  padding: 0px;
  border: none;
  background: none rgb(255, 255, 255);
  box-shadow: rgba(63, 71, 77, 0.25) 0px 2px 10px;
  height: 48px;
  line-height: 0;
  color: rgb(47, 52, 56);
  border-radius: 50%;
  width: 48px;
  cursor: pointer;
`;
