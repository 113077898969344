import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";
import { useNavigate } from "react-router-dom";

const NewSiteRegButton = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const navigate = useNavigate();
  const moveToCreateSitePage = () => {
    navigate("/my_page/my_info/create_site", { state: { isEdit: false } });
  };

  return (
    <Button
      type="secondary"
      size="medium"
      style={{ width: isMobile && "46.8rem", minWidth: isMobile && "46.8rem" }}
      onClick={moveToCreateSitePage}
    >
      신규 현장 등록
    </Button>
  );
};

export default NewSiteRegButton;
