import { useMediaQuery } from "react-responsive";
import s from "../../../asset/css/subcontractor.module.css";
import customer_icon from "../../../asset/images/join-type-customer.png";
import subcontractor_icon from "../../../asset/images/join-type-subcontractor.png";

export default function SubcontractorAlertPop({ setSubcontractorAlertPop }) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  return (
    <div
      className={s.background}
      onClick={() => setSubcontractorAlertPop(false)}
    >
      <div className={s.container} onClick={(e) => e.stopPropagation()}>
        <div className={s.customer_container}>
          {isDesktop && (
            <div className={s.icon_box}>
              <img src={customer_icon} alt="customer" />
              <div>건설사 임직원</div>
            </div>
          )}
          <div className={s.desc_box}>
            <div className={s.desc_type}>
              <div>건설사 임직원이란?</div>
              <div>공새로의 모든 기능이 사용 가능한 회원입니다.</div>
              <div>
                (건설면허를 가지고 있으며, 자체 계약 현장을 가지고 있는 회원)
              </div>
            </div>
            <div className={s.desc_function}>
              <div>주요 기능</div>
              <div className={s.desc_function_item}>
                <div>· 입찰</div>
                <div>· 단가계약</div>
                <div>· 통합정산(월 1회 정산)</div>
              </div>
              <div className={s.desc_function_item}>
                <div>· 주문(업체지정, 선주문)</div>
                <div>· 품목거래현황</div>
                <div>· PB상품 주문</div>
              </div>
            </div>
          </div>
        </div>

        <div className={s.divider} />

        <div className={s.subcontractor_container}>
          {isDesktop && (
            <div className={s.icon_box}>
              <img src={subcontractor_icon} alt="subcontractor" />
              <div>협력사 임직원</div>
            </div>
          )}
          <div className={s.desc_box}>
            <div className={s.desc_type}>
              <div>협력사 임직원이란?</div>
              <div>PB상품 주문 전용 회원입니다.</div>
              <div>(개설된 현장의 협력사로 단기간 시공하는 회원)</div>
            </div>
            <div className={s.desc_function}>
              <div>주요 기능</div>
              <div className={s.desc_function_item}>
                <div>· PB상품 주문</div>
              </div>
            </div>
          </div>
        </div>

        <div className={s.divider} />

        <div className={s.bottom_desc_container}>
          <div>
            * 협력사에서 건설사로 회원전환 시 공새로의 모든 기능(건설사전용)이
            사용 가능합니다.
          </div>
          <div>* 문의전화 : 032 - 205 - 0479</div>
        </div>

        <div className={s.button_container}>
          <button onClick={() => setSubcontractorAlertPop(false)}>확인</button>
        </div>
      </div>
    </div>
  );
}
