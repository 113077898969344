import { Controller } from "react-hook-form";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const addHyphenToBizNo = (bizNo) => {
  const number = bizNo.replace(/\D/g, "");

  let formattedValue = number;

  if (number.length > 3) {
    formattedValue = number.slice(0, 3) + "-" + number.slice(3);
  }
  if (number.length > 5) {
    formattedValue = formattedValue.slice(0, 6) + "-" + formattedValue.slice(6);
  }

  return formattedValue;
};

const BizNoInput = ({ control, errors }) => {
  return (
    <Controller
      name="biz_no"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          type="text"
          width="100%"
          id="biz_no"
          placeholder="사업자등록번호를 입력해주세요."
          maxLength={12}
          onChange={(e) => {
            const formattedBizNo = addHyphenToBizNo(e.target.value);
            field.onChange(formattedBizNo);
          }}
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                사업자등록번호{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={
            errors.biz_no && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.biz_no.message}
              </Typography>
            )
          }
          status={errors.biz_no && "error"}
        />
      )}
    />
  );
};

export default BizNoInput;
