import { Divider } from "antd";
import Typography from "../../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../../constant/Palette";
import SiteInviteButton from "../../button/SiteInviteButton";
import s from "./memberInfo.module.css";
import ApproveButton from "../../button/ApproveButton";
import RejectButton from "../../button/RejectButton";
import Chip from "../../../../../../../component/ui/chip/Chip";
import SiteAttendApproveModal from "../../../modal/site-attend-approve/SiteAttendApproveModal";
import SiteAttendRejectModal from "../../../modal/site-attend-reject/SiteAttendRejectModal";
import { useState } from "react";

const MemberInfo = ({
  memberList,
  siteAttendApproveModal,
  setSiteAttendApproveModal,
  siteAttendRejectModal,
  setSiteAttendRejectModal,
  setSiteInviteModal,
  isDemo,
}) => {
  const [selectedMember, setSelectedMember] = useState(null);

  const onClickApproveButton = (member) => {
    setSelectedMember(member);
    setSiteAttendApproveModal(true);
  };

  const onClickRejectButton = (member) => {
    setSelectedMember(member);
    setSiteAttendRejectModal(true);
  };

  return (
    <div className={s.container}>
      <div className={s.title}>
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          참여자
        </Typography>

        {!isDemo && (
          <SiteInviteButton setSiteInviteModal={setSiteInviteModal} />
        )}
      </div>

      <Divider className={s.divider} />

      <div className={s.info_container}>
        {memberList?.map((member) => (
          <div className={s.info_row_container} key={member.email}>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {member.name || "-"}
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {member.rank || "-"}
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {member.email || "-"}
            </Typography>
            {member.state === "PENDING" && (
              <>
                <div className={s.chip_container}>
                  <Chip type="default" size="medium" color="yellow">
                    승인대기중
                  </Chip>
                </div>
                <div className={s.button_group}>
                  <ApproveButton
                    onClickApproveButton={() => onClickApproveButton(member)}
                  />
                  <RejectButton
                    onClickRejectButton={() => onClickRejectButton(member)}
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      {siteAttendApproveModal && (
        <SiteAttendApproveModal
          memberNo={selectedMember.no}
          siteAttendApproveModal={siteAttendApproveModal}
          setSiteAttendApproveModal={setSiteAttendApproveModal}
        />
      )}
      {siteAttendRejectModal && (
        <SiteAttendRejectModal
          memberNo={selectedMember.no}
          siteAttendRejectModal={siteAttendRejectModal}
          setSiteAttendRejectModal={setSiteAttendRejectModal}
        />
      )}
    </div>
  );
};

export default MemberInfo;
