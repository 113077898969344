import React, { useEffect, useState } from "react";
//library

import {
  useNavigate,
  useLocation,
  useNavigation,
  Outlet,
} from "react-router-dom";

import DaumPostcodeEmbed from "react-daum-postcode";

//component
import Mheader from "../../component/common/Mheader";

import s from "../../asset/css/requestOrderPb.module.css";
import i_cancel from "../../asset/images/w-cancle-bt.png";
import fire_waste from "../../asset/images/fire_waste.png";
import no_fire_waste from "../../asset/images/no_fire_waste.png";

import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { createPbOrder, orderUserInfoApi } from "../../api/auth";
import Loading from "../../component/etc/Loading";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";
import { actions } from "../../redux/action";
import Button from "../../component/ui/button/Button";
import Typography from "../../component/ui/typography/Typography";
import Modal from "../../component/ui/modal/Modal";
import useScrollFixed from "../../hooks/useScrollFixed";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

const PB_VALUE = 210;

const PB_LIST = [
  { pbProductNo: 1, name: "폐기물 분리 마대(40kg) / 가연성" },
  { pbProductNo: 2, name: "폐기물 분리 마대(40kg) / 불연성" },
];

export default function RequestOrderPb() {
  const location = useLocation();
  const optionValue = location.state.optionValue;
  const [postOpen, setPostOpen] = useState(false);
  const openPostModal = () => setPostOpen(true);
  const closePostModal = () => setPostOpen(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const [generating, setGenerating] = useState(false);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const { isFixed } = useScrollFixed(isDesktop ? 200 : 300);

  const [orderInfo, setOrderInfo] = useState({
    siteNo: userData.site_num,
    siteUserNo: userData.u_no,
    recipient: "", // 받는사람
    phone: "", // 전화번호
    address: "", // 주소
    addressDetail: "", // 상세주소
    uniqueness: "", // 배송 요청사항
    productInfoList: optionValue, // 상품정보
    constructionType: userData.constructionType,
  });

  const sumFnc = () => {
    const result = optionValue
      .map((data) => data.count * PB_VALUE)
      .reduce((acc, current) => acc + current, 0);

    return result;
  };

  //주소 확인
  const onCompletePost = (data) => {
    setPostOpen(false);

    let copy = orderInfo;
    copy.address = data.address;
    setOrderInfo(copy);
    setOrderInfo({ ...orderInfo, address: data.address });
  };

  const nextValidation = () => {
    // 정규식을 사용하여 전화번호 유효성 검사
    const phoneRegex = /^\d{10,11}$/; // 10자리 또는 11자리 숫자
    const phoneValid = phoneRegex.test(orderInfo.phone);
    if (orderInfo.recipient === "") {
      swal("수령인을 입력해주세요.", "", "warning");
    } else if (orderInfo.phone === "") {
      swal("수령인 연락처를 입력해주세요.", "", "warning");
    } else if (orderInfo.address === "") {
      swal("주소찾기를 통해 주소를 선택해주세요.", "", "warning");
    } else if (orderInfo.addressDetail === "") {
      swal("상세주소를 입력해주세요.", "", "warning");
    } else {
      bidNextFnc();
    }
  };

  const prevStepFnc = () => {
    navigate(-1);
  };

  const bidNextFnc = async () => {
    navigate("/pb/order/request_confirm", {
      state: { data: orderInfo, type: "PB상품주문" },
    });
  };

  const pbOrderUserInfoFnc = async () => {
    const response = await orderUserInfoApi(userData.site_num);

    if (response.status === "success") {
      setOrderInfo({
        ...orderInfo,
        address: response.data.siteAddress,
        addressDetail: response.data.siteAddressDetail,
        phone: response.data.phone,
        recipient: response.data.name,
      });
      let newUserData = {
        ...userData,
        u_name: response.data.name,
        u_phone: response.data.phone,
      };
      dispatch(actions.setUser(newUserData));
      // handlePhoneNumberChange(response.data.phone);
    }
  };

  const [phone, setPhone] = useState("");

  const handlePhoneNumberChange = (value) => {
    // 기본값으로 빈 문자열을 사용하여 undefined 방지
    let input = value ? value.replace(/\D/g, "") : "";

    // Add hyphens based on the length of the input
    if (input.length > 3 && input.length <= 6) {
      input = `${input.slice(0, 3)}-${input.slice(3)}`;
    } else if (input.length > 6 && input.length <= 10) {
      input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6)}`;
    } else if (input.length > 10) {
      input = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7)}`;
    }
    setOrderInfo({
      ...orderInfo,
      phone: value || "",
    });
    setPhone(input);
  };

  useEffect(() => {
    pbOrderUserInfoFnc();
    if (userData.u_phone) {
      handlePhoneNumberChange(userData.u_phone);
    }
  }, []);

  // const location = useLocation();
  const currentPath = location.pathname; // 현재 경로
  const keyword = "request_confirm"; // 확인할 단어

  // URL에 특정 단어가 포함되어 있는지 확인
  const isConfirmPage = currentPath.includes(keyword);

  return (
    <>
      {!isConfirmPage ? (
        <div className="request_input_container">
          <div className="top_box">
            <div className="title">상세정보 입력</div>

            <OrderBreadCrumb
              step1="PB상품 선택"
              step2="상세정보 입력"
              step3="상세정보 확인"
              step4="주문완료"
              isActive={2}
            />
          </div>
          <div className="top_request_sub_header_wrap">
            <p>
              <span>
                정확한 납품을 위하여 정확한 정보를 입력해주시기 바랍니다.
              </span>
            </p>
          </div>
          <div className="order_issuance_contents">
            <div className="input_info_container">
              <span className="input_info_title">주문/입찰 방식</span>

              <div className="input_info_contents input_info_desc">
                PB상품 주문
              </div>
            </div>
            <div className="input_info_container">
              <span className="input_info_title">수령인</span>
              <div className="input_info_contents">
                <input
                  // defaultValue={userData.u_name}
                  onChange={(e) => {
                    setOrderInfo({
                      ...orderInfo,
                      recipient: e.target.value,
                    });
                  }}
                  defaultValue={userData.u_name}
                />
              </div>
            </div>
            <div className="input_info_container">
              <span className="input_info_title">수령인 연락처</span>
              <div className="input_info_contents">
                <input
                  value={phone}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\d]/g, "");
                    if (!/^\d{0,11}$/.test(e.target.value)) {
                      e.target.value = e.target.value.slice(0, -1);
                    }
                  }}
                  onChange={(e) => handlePhoneNumberChange(e.target.value)}
                />
              </div>
            </div>

            {/* <div className="input_info_container">
            <span className="input_info_title">납품 기한</span>

            <div className="input_info_contents input_info_desc">---</div>
          </div> */}

            <div className="input_info_container">
              <span className="input_info_title">납품 주소</span>
              <div className="input_info_contents my_addr_container ">
                <div className="input_info_btn_box">
                  <input type="text" value={orderInfo?.address} />
                  <div
                    className="input_info_search_btn"
                    onClick={openPostModal}
                  >
                    주소찾기
                  </div>
                </div>

                <Modal
                  size="xsmall"
                  open={postOpen}
                  title={
                    <Typography component="p" type="h4">
                      납품 주소 입력
                    </Typography>
                  }
                  closeIcon={true}
                  onOk={closePostModal}
                  onCancel={closePostModal}
                >
                  <DaumPostcodeEmbed
                    onComplete={(e) => onCompletePost(e, "일회성입찰공고")}
                  />
                </Modal>
                <input
                  className="direct_input"
                  placeholder="상세주소를 입력해주세요."
                  onChange={(e) => {
                    setOrderInfo({
                      ...orderInfo,
                      addressDetail: e.target.value,
                    });
                  }}
                  value={orderInfo.addressDetail}
                />
              </div>
            </div>

            <div className="input_info_container">
              <span className="input_info_title">배송비</span>
              <div className="input_info_contents input_info_desc">포함</div>
            </div>
            <div className="input_info_container">
              <span className="input_info_title">하차 역무</span>
              <div className="input_info_contents input_info_desc">
                하차 역무 있음
              </div>
            </div>
            <div className="input_info_container">
              <span className="input_info_title">비용 정산</span>
              <div className="input_info_contents input_info_desc">
                공새로 위임 정산
              </div>
            </div>
          </div>

          <section
            className={classNames("bid_bottom_button_section", {
              desktop_fixed: isDesktop && isFixed,
              mobile_fixed: isMobile && isFixed,
            })}
          >
            <div className="space_between_btn_group">
              <Button size="medium" type="secondary" onClick={prevStepFnc}>
                <Typography component="p" type="c1">
                  이전으로
                </Typography>
              </Button>
              <Button size="medium" type="primary" onClick={nextValidation}>
                <Typography component="p" type="c1" color="var(--white)">
                  입력 완료
                </Typography>
              </Button>
            </div>
          </section>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
}
