import React, { useEffect, useState } from "react";
//library
import { useLocation } from "react-router";
//component
import Mheader from "../../../component/common/Mheader";
//css
import "../../../asset/css/notice.css";
//etc
import { noticeDetailApi } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function NoticeDetail() {
  const location = useLocation();
  const [detailData, setDetailData] = useState({});

  const noticeDetailFnc = async () => {
    const response = await noticeDetailApi(location.state.bd_no);

    if (response.status === "success") {
      setDetailData(response.data);
    } else if (response.status === "fail") {
      swal("다시 시도해주세요.", "", "warning");
    }
  };

  useEffect(() => {
    noticeDetailFnc();
  }, []);

  return (
    <div className="noticeDetail_wrap">
      <Mheader title="공지사항" />
      <div className="noticeDetail_container " style={{ paddingTop: "30px" }}>
        <div className="top_pagenation notice_h2">
          <h2>공지사항</h2>
        </div>
        <div className="noticeDetail_content">
          <div className="no_content_top">
            <h3>{detailData.bd_title}</h3>
            <div className="no_content_right">
              <p>{detailData.bd_regdate}</p>
              <p>공새로</p>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: detailData.bd_contents }}
            className="notice_content_div"
          ></div>
        </div>
      </div>
    </div>
  );
}
