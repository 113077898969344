import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { siteInfoApi2 } from "../../../api/auth";

export default function useSiteInfo() {
  const [selectSwitch, setSelectSwitch] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const userData = useSelector((state) => state.user);

  const getSiteInfo = useCallback(async () => {
    const response = await siteInfoApi2();
    if (response.status === "success") {
      if (response.data.length >= 2 && userData.rep_site === false) {
        setSiteList(response.data);
        setSelectSwitch(true);
      }
    }
  }, [userData.rep_site]);

  useEffect(() => {
    getSiteInfo();
  }, [getSiteInfo]);

  return { selectSwitch, setSelectSwitch, siteList, setSiteList };
}
