import Chip from "../../../../../component/ui/chip/Chip";

const typeConfig = {
  true: { color: "blue", label: "세금계산서 발행완료" },
  false: { color: "yellow", label: "세금계산서 미발행" },
};

export default function TypeChip({ type }) {
  const chipConfig = typeConfig[type];

  return chipConfig ? (
    <Chip size="medium" type="border" color={chipConfig.color}>
      {chipConfig.label}
    </Chip>
  ) : null;
}
