import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import i_close from '../../../asset/images/w-cancel-icon.png';
import '../../../asset/css/pop.css';

export default function ServiceCancelPop({ setServiceCancel }) {
  // 기타 입력란
  const [selected, setSelected] = useState("");

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setServiceCancel(false)}></div>
      <div className="contract_pop">
        <div className="close" onClick={() => setServiceCancel(false)}>
          <img src={i_close} alt="" />
        </div>
        <div className="contract_pop_tit">
          <h3>유료 서비스 이용을 취소하시겠습니까?</h3>
          <p>서비스 이용 취소 사유를 선택해주세요</p>
        </div>
        <select onChange={handleSelect}>
          <option value="" hidden>취소사유 선택</option>
          <option value="회사와의 관계">회사와의 관계</option>
          <option value="파기 후 재계약">파기 후 재계약</option>
          <option value="변심">변심</option>
          <option value="기타">기타</option>
        </select>
        {selected === '기타' && <input placeholder="계약 취소 사유를 입력해주세요" />}
        <div className="confirm_btn"  onClick={() => setServiceCancel(false)}>
          <Link to="/management/contract/1">확인</Link>
        </div>
      </div>
    </div>
  );
}
