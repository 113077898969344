import { useState, useEffect } from "react";

const useTimer = () => {
  const [timer, setTimer] = useState(180);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    let interval = null;

    if (running && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setRunning(false);
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [running, timer]);

  const startTimer = () => {
    if (!running) {
      setTimer(180);
      setRunning(true);
    }
  };

  const stopTimer = () => setRunning(false);

  return {
    timer,
    running,
    startTimer,
    stopTimer,
  };
};

export default useTimer;
