import s from "../../../asset/css/reEstimate.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExcelComponent from "../../../component/etc/ExcelComponent";
import circle_plus from "../../../asset/images/circle-plus.png";
import question_img from "../../../asset/images/question-icon.png";
import ReactTooltip from "react-tooltip";
import ReactDatePicker from "react-datepicker";
import { motion } from "framer-motion";
import moment from "moment";
import { imgUpApi, reBidApi } from "../../../api/auth";
import swal from "sweetalert";
import Loading from "../../../component/etc/Loading";
import "../../../asset/css/requestsimple.css";
import Mheader from "../../../component/common/Mheader";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/action";
import { excelDefault } from "../../../redux/reducer/default";

export default function ReEstimate() {
  const redux_excel = useSelector((state) => state.excel);
  const notifyAdd = (count) => {
    toast.success(
      <span className="toast_title">{count}행이 추가되었습니다.</span>
    );
  };
  const notifyRemove = (count) => {
    toast.warn(
      <span className="toast_title">{count} 행이 삭제되었습니다.</span>
    );
  };
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const detailData = location.state.detailData;
  const bidProductList = location.state.detailData.bidProductList;
  const bidNo = location.state.bidNo;

  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState(bidProductList?.length);
  const [cols, setCols] = useState(6);
  const dispatch = useDispatch();

  // data가 바뀔 때마다 실행
  useEffect(() => {
    if (data) {
      dispatch(actions.setExcel(data));
    }
  }, [data]);

  const createGridData = (rows, cols) => {
    const data = [];

    for (let i = 0; i < rows; i++) {
      const rowData = [];

      for (let j = 0; j < cols; j++) {
        rowData.push({
          value:
            j === 0
              ? bidProductList[i]?.name
              : j === 1
              ? bidProductList[i]?.standard
              : j === 2
              ? bidProductList[i]?.unit
              : j === 3
              ? bidProductList[i]?.count
              : j === 4
              ? bidProductList[i]?.brand
              : j === 5
              ? bidProductList[i]?.etc
              : "",
        });
      }

      data.push(rowData);
    }

    return data;
  };

  const [data, setData] = useState(createGridData(rows, cols));

  const addRow = () => {
    const rowData = [];
    for (let j = 0; j < cols; j++) {
      rowData.push({
        // id: count++,
        value: j === 4 ? "무관" : "",
      });
    }
    setData([...data, rowData]);
    setRows(redux_excel.length);
    notifyAdd(redux_excel.length + 1);
    // setCols(20);
  };

  const [reEstParams, setReEstParams] = useState({
    bid: detailData.bid,
    bidProductList: data,
    bidImgList: detailData.bidImgInfoList,
    extraType: "ESTIMATE",
    previousBidNo: bidNo,
  });

  const [contractType, setContractType] = useState("SINGLE");

  const proMenu = [
    { processNo: 1, processName: "토목 공사" },
    { processNo: 2, processName: "기초 공사" },
    { processNo: 3, processName: "골조 공사" },
    { processNo: 4, processName: "설비 공사" },
    { processNo: 5, processName: "마감 공사" },
    { processNo: 6, processName: "부대 공사" },
    { processNo: 7, processName: "공통 공사" },
  ];

  const [reason, setReason] = useState("");
  const [selectDatePeriod, setSelectDatePeriod] = useState(false);
  const [selectDateSingle, setSelectDateSingle] = useState(false);
  const [selectDateSingle2, setSelectDateSingle2] = useState(false);

  const now = new Date();
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const getDiffDate = (selectDate) => {
    let diff = moment(selectDate).fromNow();
    let currentDate = moment();
    const timeDifference = moment(selectDate).diff(currentDate, "hours");
    const isOver6Hours = timeDifference >= 6;

    if (isOver6Hours) {
      return "약 " + diff;
    } else {
      return "날짜와 시간을 확인해주세요.";
    }
  };

  const getDiffDate2 = (selectDate) => {
    const date1 = moment(selectDate);
    const date2 = moment(selectDateSingle);
    let diff = moment(selectDate).fromNow();

    const filterDate2 = date2.add(6, "hours");

    if (date1.isBefore(filterDate2)) {
      return "최소 working hour 6시간 이후부터 선택 가능합니다.";
    } else {
      return "약 " + diff;
    }
  };

  // data가 바뀔 때마다 실행
  useEffect(() => {
    if (data) {
      dispatch(actions.setExcel(data));
    }
  }, [data]);

  const [uploadFile, setUploadFile] = useState([{ file: "", id: 0 }]);
  const [uploadImg, setUploadImg] = useState([]);
  const [fList, setFList] = useState([]);

  const onCreate = (i) => {
    const file = {
      file: "",
      id: i,
    };
    setUploadFile(uploadFile.concat(file));
  };

  const onRemove = (id) => {
    setUploadFile(uploadFile.filter((file) => file.id !== id));
    setFList(fList.filter((file) => file.fileIndex !== id));
  };

  const handleFileUpload = async (e, id) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const response = await imgUpApi(formData);
    if (response.status === "success") {
      let copy = fList;
      copy.push({
        fileNo: response.data.f_no,
      });

      setFList(copy);

      setUploadImg([...uploadImg, e.target.files[0]]);
      setReEstParams({
        ...reEstParams,
        bidImgList: fList,
      });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드 되지 않았습니다.", "", "warning");
    }
  };

  const isExcelEmpty = (array) => {
    for (const subArray of array) {
      const isEmpty = subArray.slice(0, 3).some((element) => !element.value);

      if (isEmpty) {
        return true;
      }
    }

    return false;
  };

  const isExcelCountValid = (array) => {
    for (const subArray of array) {
      const isCountValid =
        isNaN(Number(subArray[3].value)) || Number(subArray[3].value) < 1;

      if (isCountValid) {
        return false;
      }
    }

    return true;
  };

  const onClickReEst = async (method) => {
    // 데이터 유효성 검사
    if (isExcelEmpty(data)) {
      swal("엑셀에 빈 항목이 있습니다.", "", "error");
      return;
    }

    if (!isExcelCountValid(data)) {
      swal("올바른 수량을 입력해주세요.", "", "error");
      return;
    }

    // 일회성 입찰 공고 처리
    if (method === "일회성입찰공고") {
      if (!selectDateSingle) {
        swal("투찰기한을 입력해주세요.", "", "error");
        return;
      }

      if (moment(selectDateSingle).diff(moment(), "hours") < 6) {
        swal("투찰기한을 확인해주세요.", "", "error");
        return;
      }

      if (!selectDateSingle2) {
        swal("납품일시를 입력해주세요.", "", "error");
        return;
      }

      const isValidDeliveryTime =
        moment(selectDateSingle2).diff(moment(selectDateSingle), "hours") >=
          20 || new Date(selectDateSingle).getHours() <= 12;
      if (!isValidDeliveryTime) {
        swal(
          "납품일시는 최소 working hour 6시간 이후부터 선택 가능합니다.",
          "",
          "error"
        );
        return;
      }
    }

    // 단가 계약 입찰 공고 처리
    if (method === "단가계약입찰공고") {
      if (!selectDatePeriod) {
        swal("투찰기한을 입력해주세요.", "", "error");
        return;
      }

      if (reEstParams.bid.term === "") {
        swal("계약기간을 입력해주세요.", "", "error");
        return;
      }
    }

    // 입찰 사유 확인
    if (!reason) {
      swal("재공고 입찰 사유를 입력해주세요.", "", "error");
      return;
    }

    setLoading(true);

    // 입찰 상품 리스트 생성
    const pList = data.map((item) => ({
      name: item[0]?.value,
      standard: item[1]?.value || "",
      unit: item[2]?.value,
      count: Number(item[3]?.value),
      brand: item[4]?.value,
      etc: item[5]?.value,
    }));

    try {
      // API 요청
      const response = await reBidApi({
        ...reEstParams,
        bidProductList: pList,
      });

      // 응답 처리
      if (response.status === "success") {
        swal({
          title: "재공고 입찰 처리 되었습니다.",
          icon: "success",
        }).then((value) => {
          if (value) {
            dispatch(actions.setExcel(excelDefault));
            navigate("/management/estimate/1");
          }
        });
      } else {
        swal("재공고 입찰 처리에 실패하였습니다.", "", "error");
      }
    } catch (error) {
      console.error(error);
      swal("재공고 입찰 처리 중 오류가 발생하였습니다.", "", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={s.container}>
      <Mheader title="재공고 입찰" />
      {isDesktop && <div className={s.title}>재공고 입찰</div>}

      <div className={s.excel_add_container}>
        <div className={s.excel_add_btn} onClick={addRow}>
          <img src={circle_plus} alt="add_row" />
          <span>행 추가</span>
        </div>
      </div>

      <div className={s.spreadsheet_container}>
        {/* 
        
        재공고 입찰에서 들어온 경우엔 플래그 값을 하나 두고, 
        여기의 data를 핸들링한다.!.

        */}
        <ExcelComponent
          data={data}
          setData={setData}
          cols={cols}
          setCols={setCols}
          rows={rows}
          setRows={setRows}
          addRow={addRow}
          notifyRemove={notifyRemove}
        />
      </div>

      <ul className="many_input_list margin_adjust">
        <li>
          <p>계약 방식</p>
          <div>
            <div className="request_kind_select">
              <div
                onClick={() => {
                  setContractType("SINGLE");
                  setReEstParams({
                    ...reEstParams,
                    bid: { ...reEstParams.bid, type: "S" },
                  });
                }}
                className={
                  contractType === "SINGLE"
                    ? "kind_container active"
                    : "kind_container"
                }
              >
                <h2>일회성 입찰 공고</h2>
                <a data-tip="React-tooltip" data-for="single">
                  <img
                    className="question_img"
                    src={question_img}
                    style={{ marginTop: "4px" }}
                    alt="question"
                  />
                </a>
              </div>
              <ReactTooltip
                id="single"
                place="top"
                type="dark"
                effect="solid"
                backgroundColor="#002863"
              >
                <p>
                  지정한 품목에 대하여 최적의 공급사를 매칭하여 투찰받을 수
                  있습니다.
                </p>
              </ReactTooltip>

              <div
                onClick={() => {
                  // 단가계약입찰공고 or 지정기간
                  setContractType("PERIOD");
                  setReEstParams({
                    ...reEstParams,
                    bid: { ...reEstParams.bid, type: "T" },
                  });
                }}
                className={
                  contractType === "PERIOD"
                    ? "kind_container active"
                    : "kind_container"
                }
              >
                <h2>단가 계약 입찰 공고</h2>
                <a data-tip="React-tooltip" data-for="period">
                  <img
                    className="question_img"
                    src={question_img}
                    style={{ marginTop: "4px" }}
                    alt="question"
                  />
                </a>
              </div>
              <ReactTooltip
                id="period"
                place="top"
                type="dark"
                effect="solid"
                backgroundColor="#002863"
              >
                <p>
                  지정하신 기간 동안 고정적으로 공급받으실 업체 선정을 도와주는
                  서비스입니다.
                </p>
              </ReactTooltip>
            </div>
          </div>
        </li>
      </ul>

      {contractType === "SINGLE" && (
        <ul className="many_input_list">
          <div className="budget_info" style={{ marginTop: "10px" }}>
            <span className="request_short_info">
              * 공정단계는 마이페이지 - 현장정보 - 현장 정보수정에서 변경할 수
              있습니다.
            </span>
          </div>

          <li>
            <p>투찰 기한</p>
            <div>
              <ReactDatePicker
                locale="ko"
                selected={selectDateSingle ? selectDateSingle : null}
                minDate={
                  // 오전 12시(정오) 이후면 다음 날 부터 선택 가능
                  new Date().getHours() >= 12
                    ? new Date(now.getTime() + oneDayInMilliseconds)
                    : // 오늘 날짜라면 오늘이 최소 선택일자
                      new Date()
                }
                minTime={
                  formatDate(selectDateSingle) === formatDate(new Date())
                    ? new Date().setMinutes(new Date().getMinutes() + 360)
                    : new Date().getHours() >= 12 &&
                      moment(selectDateSingle).diff(moment(), "days") < 1
                    ? new Date().setHours(new Date().getHours() - 3, 0, 0)
                    : // : new Date().setMinutes(new Date().getMinutes() + 360)
                      now.setHours(7, 0, 0)
                }
                maxTime={now.setHours(18, 0, 0)}
                showTimeSelect // 시간 나오게 하기
                timeFormat="HH:mm" //시간 포맷
                timeIntervals={30} // 한시간 단위로 선택 가능한 box가 나옴
                timeCaption="time"
                dateFormat="yyyy/MM/dd HH:mm 까지"
                isClearable={true}
                peekNextMonth
                withPortal
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date) => {
                  if (date && date?.getHours() === 0) {
                    const plus18hours = date.setHours(date.getHours() + 18);
                    setSelectDateSingle(plus18hours);
                  }
                  setSelectDateSingle(date);
                  setReEstParams({
                    ...reEstParams,
                    bid: {
                      ...reEstParams.bid,
                      endate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                    },
                  });
                }}
              >
                <motion.span
                  className="select_time_alert"
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.8,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                >
                  {new Date(selectDateSingle).getFullYear() === 1970 ? (
                    <span>
                      투찰 마감 <span style={{ fontWeight: 700 }}>날짜</span>를
                      선택해주세요.
                    </span>
                  ) : (
                    <span>
                      투찰 마감 <span style={{ fontWeight: 700 }}>시간</span>을
                      선택해주세요.
                    </span>
                  )}
                </motion.span>
              </ReactDatePicker>
            </div>
          </li>

          <div
            className="budget_info"
            style={{ marginTop: "10px", flexDirection: "column" }}
          >
            <span className="request_short_info">
              * 긴급건의 경우{" "}
              <a href="/management/bid/request_choice" target="_blank">
                업체지정주문
              </a>{" "}
              또는{" "}
              <a href="/management/bid/request_choice" target="_blank">
                주문서 역발행
              </a>
              을 권장드립니다.
            </span>
            <span className="request_short_info" style={{ marginTop: "10px" }}>
              * 최소 working hour 6시간 이후부터 선택 가능합니다.
            </span>
          </div>

          {selectDateSingle && (
            <span className="bid_time_info">
              {getDiffDate(selectDateSingle)}
            </span>
          )}

          <li>
            <p>납품 일시</p>
            <div>
              <ReactDatePicker
                locale="ko"
                selected={selectDateSingle2 ? selectDateSingle2 : null}
                minDate={
                  new Date(selectDateSingle).getHours() >= 12 &&
                  selectDateSingle
                    ? new Date(
                        selectDateSingle.getTime() + oneDayInMilliseconds
                      )
                    : new Date(selectDateSingle)
                }
                minTime={
                  formatDate(selectDateSingle2) === formatDate(selectDateSingle)
                    ? new Date(selectDateSingle).setHours(
                        new Date(selectDateSingle).getHours() + 6
                      )
                    : moment(selectDateSingle2).diff(
                        moment(selectDateSingle),
                        "days"
                      ) < 1
                    ? new Date(selectDateSingle2).setHours(
                        new Date(selectDateSingle).getHours() - 3,
                        0,
                        0
                      )
                    : now.setHours(7, 0, 0)
                }
                maxTime={now.setHours(18, 0, 0)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                timeCaption="time"
                dateFormat="yyyy/MM/dd HH:mm 까지"
                isClearable={true}
                peekNextMonth
                withPortal
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date) => {
                  if (date && date?.getHours() === 0) {
                    const plus18hours = date.setHours(date.getHours() + 18);
                    setSelectDateSingle2(plus18hours);
                  }
                  setSelectDateSingle2(date);
                  setReEstParams({
                    ...reEstParams,
                    bid: {
                      ...reEstParams.bid,
                      deldate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                    },
                  });
                }}
              >
                <motion.span
                  className="select_time_alert"
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.8,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                >
                  {new Date(selectDateSingle2).getFullYear() === 1970 ? (
                    <span>
                      원하는 납품 <span style={{ fontWeight: 700 }}>날짜</span>
                      를 선택해주세요.
                    </span>
                  ) : (
                    <span>
                      원하는 납품 <span style={{ fontWeight: 700 }}>시간</span>
                      을 선택해주세요.
                    </span>
                  )}
                </motion.span>
              </ReactDatePicker>
            </div>
          </li>

          {selectDateSingle2 && (
            <span className="bid_time_info">
              {getDiffDate2(selectDateSingle2)}
            </span>
          )}

          <li className="contract_period_box">
            <p>계약 기간</p>
            <div>
              <input
                type="text"
                key={1}
                readOnly
                placeholder="계약기간을 입력해주세요.( 단가 계약 입찰 공고 선택시 작성 )"
                className="write_the_budget not_allowed"
                style={{ textAlign: "left", paddingLeft: "0px" }}
              />
            </div>
          </li>

          <li>
            <p>공고 관련 추가 내용</p>
            <div>
              <textarea
                style={{
                  width: "100%",
                  height: "80px",
                  resize: "none",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
                placeholder="공고 관련 추가 내용이 있으시다면 작성해주세요."
                onChange={(e) => {
                  setReEstParams({
                    ...reEstParams,
                    bid: { ...reEstParams.bid, comment: e.target.value },
                  });
                }}
              />
            </div>
          </li>

          <li>
            <p>재공고 입찰 사유</p>
            <div>
              <textarea
                style={{
                  width: "100%",
                  height: "80px",
                  resize: "none",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
                placeholder="사유를 입력해주세요."
                onChange={(e) => {
                  setReason(e.target.value);
                  setReEstParams({
                    ...reEstParams,
                    bid: { ...reEstParams.bid, selectReason: e.target.value },
                  });
                }}
              />
            </div>
          </li>

          <li>
            <p>이미지 첨부</p>
            <div>
              <div>
                {uploadFile.map((data, i) => (
                  <div className="img_add" key={data.id}>
                    <input
                      type="file"
                      id={data.id}
                      accept="image/*"
                      onChange={(e) => {
                        const arr = uploadFile.map((f_name, index) =>
                          f_name.id === data.id
                            ? { ...f_name, file: e.target.files[0].name }
                            : { ...f_name }
                        );
                        setUploadFile(arr);
                        handleFileUpload(e, data.id);
                        // setUploadFile([...uploadFile, {...data,  file : e.target.files[0].name}])
                      }}
                    />
                    <p>{data.file}</p>
                    <label htmlFor={data.id}>찾아보기</label>
                    {data.id === 0 ? (
                      <div
                        className="btn_add"
                        onClick={() => {
                          if (uploadFile.length < 5) {
                            onCreate(uploadFile.length);
                          }
                        }}
                      >
                        추가　+
                      </div>
                    ) : (
                      <div
                        className="btn_add"
                        onClick={() => {
                          if (!uploadFile.length < 2) {
                            onRemove(uploadFile[i].id);
                          }
                        }}
                      >
                        삭제　-
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </li>

          <li>
            <p></p>
            <div>
              <div className="btn_box">
                <div
                  className="prev"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  이전
                </div>
                <div
                  className="next"
                  onClick={() => onClickReEst("일회성입찰공고")}
                >
                  완료
                </div>
              </div>
            </div>
          </li>
        </ul>
      )}

      {contractType === "PERIOD" && (
        <ul className="many_input_list">
          <li>
            <p>투찰 기한</p>
            <ReactDatePicker
              style={{ transform: "scale(1.3)" }}
              locale="ko"
              selected={selectDatePeriod ? selectDatePeriod : null}
              minDate={
                new Date().getHours() >= 12
                  ? new Date(now.getTime() + oneDayInMilliseconds)
                  : new Date()
              }
              minTime={
                formatDate(selectDatePeriod) === formatDate(new Date())
                  ? new Date().setMinutes(new Date().getMinutes() + 360)
                  : new Date().getHours() >= 12 &&
                    moment(selectDatePeriod).diff(moment(), "days") < 1
                  ? new Date().setHours(new Date().getHours() - 3, 0, 0)
                  : now.setHours(7, 0, 0)
              }
              maxTime={now.setHours(18, 0, 0)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              dateFormat="yyyy/MM/dd HH:mm 까지"
              isClearable={true}
              peekNextMonth
              withPortal
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={(date) => {
                if (date && date?.getHours() === 0) {
                  const plus18hours = date.setHours(date.getHours() + 18);
                  setSelectDatePeriod(plus18hours);
                }
                setSelectDatePeriod(date);
                setReEstParams({
                  ...reEstParams,
                  bid: {
                    ...reEstParams.bid,
                    endate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                  },
                });
              }}
            >
              <motion.span
                className="select_time_alert"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.2,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                {new Date(selectDatePeriod).getFullYear() === 1970 ? (
                  <span>
                    투찰 마감 <span style={{ fontWeight: 700 }}>날짜</span>를
                    선택해주세요.
                  </span>
                ) : (
                  <span>
                    투찰 마감 <span style={{ fontWeight: 700 }}>시간</span>을
                    선택해주세요.
                  </span>
                )}
              </motion.span>
            </ReactDatePicker>
          </li>

          <div
            className="budget_info"
            style={{ marginTop: "10px", flexDirection: "column" }}
          >
            <span className="request_short_info">
              * 긴급건의 경우{" "}
              <a href="/management/bid/request_choice" target="_blank">
                업체지정주문
              </a>{" "}
              또는{" "}
              <a href="/management/bid/request_choice" target="_blank">
                주문서 역발행
              </a>
              을 권장드립니다.
            </span>
            <span className="request_short_info" style={{ marginTop: "10px" }}>
              * 최소 working hour 6시간 이후부터 선택 가능합니다.
            </span>
          </div>

          {selectDatePeriod && (
            <span className="bid_time_info">
              {getDiffDate(selectDatePeriod)}
            </span>
          )}

          <li>
            <p>납품 일시</p>
            <div className="bidding_date_box">
              <input
                disabled
                readOnly
                placeholder="(일회성 입찰 공고 선택시 작성)"
                className="not_allowed"
              />
            </div>
          </li>

          <li className="contract_period_box">
            <p>계약 기간</p>
            <div>
              <input
                type="text"
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");
                }}
                placeholder="계약기간을 입력해주세요."
                className="write_the_budget"
                onChange={(e) => {
                  setReEstParams({
                    ...reEstParams,
                    bid: {
                      ...reEstParams.bid,
                      term: e.target.value,
                    },
                  });
                }}
                defaultValue={reEstParams.bid.term}
              />
              <span className="ten_thousand">개월</span>
            </div>
          </li>

          <li>
            <p>공고 관련 추가 내용</p>
            <div>
              <textarea
                style={{
                  width: "100%",
                  height: "80px",
                  resize: "none",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
                placeholder="공고 관련 추가 내용이 있으시다면 작성해주세요."
                onChange={(e) => {
                  setReEstParams({
                    ...reEstParams,
                    bid: { ...reEstParams.bid, comment: e.target.value },
                  });
                }}
              />
            </div>
          </li>

          <li>
            <p>재공고 입찰 사유</p>
            <div>
              <textarea
                style={{
                  width: "100%",
                  height: "80px",
                  resize: "none",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
                placeholder="사유를 입력해주세요."
                onChange={(e) => {
                  setReason(e.target.value);
                  setReEstParams({
                    ...reEstParams,
                    bid: { ...reEstParams.bid, selectReason: e.target.value },
                  });
                }}
              />
            </div>
          </li>

          <li>
            <p>이미지 첨부</p>
            <div>
              <div>
                {uploadFile.map((data, i) => (
                  <div className="img_add" key={data.id}>
                    <input
                      type="file"
                      id={data.id}
                      accept="image/*"
                      onChange={(e) => {
                        const arr = uploadFile.map((f_name, index) =>
                          f_name.id === data.id
                            ? { ...f_name, file: e.target.files[0].name }
                            : { ...f_name }
                        );
                        setUploadFile(arr);
                        handleFileUpload(e, data.id);
                        // setUploadFile([...uploadFile, {...data,  file : e.target.files[0].name}])
                      }}
                    />
                    <p>{data.file}</p>
                    <label htmlFor={data.id}>찾아보기</label>
                    {data.id === 0 ? (
                      <div
                        className="btn_add"
                        onClick={() => {
                          if (uploadFile.length < 5) {
                            onCreate(uploadFile.length);
                          }
                        }}
                      >
                        추가　+
                      </div>
                    ) : (
                      <div
                        className="btn_add"
                        onClick={() => {
                          if (!uploadFile.length < 2) {
                            onRemove(uploadFile[i].id);
                          }
                        }}
                      >
                        삭제　-
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </li>

          <li>
            <p></p>
            <div>
              <div className="btn_box">
                <div
                  className="prev"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  이전
                </div>
                <div
                  className="next"
                  onClick={() => onClickReEst("단가계약입찰공고")}
                >
                  완료
                </div>
              </div>
            </div>
          </li>
        </ul>
      )}
      {loading && <Loading msg={"로딩중 입니다."} />}
    </div>
  );
}
