import useUpdateRankModalHandler from "../../../../../../hooks/handler/useUpdateRankModalHandler";
import Button from "../../../../../../component/ui/button/Button";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import RankSelect from "./components/elements/select/RankSelect";
import RankUpdateSuccessModal from "../rank-update-success/RankUpdateSuccessModal";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./rankUpdateModal.module.css";

const RankUpdateModal = ({ rankUpdateModal, setRankUpdateModal }) => {
  const {
    handleSubmit,
    control,
    errors,
    rankUpdateSuccessModal,
    setRankUpdateSuccessModal,
    handleUpdateRank,
  } = useUpdateRankModalHandler();
  const onClickCancel = () => {
    setRankUpdateModal(false);
  };

  return (
    <Modal
      size="xsmall"
      open={rankUpdateModal}
      title={
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          직급 변경
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={null}
    >
      <form onSubmit={handleSubmit(handleUpdateRank)}>
        <RankSelect control={control} errors={errors} />

        <div className={s.footer_container}>
          <Button type="danger" size="small" onClick={onClickCancel}>
            취소
          </Button>
          <Button type="primary" size="small" htmlType="submit">
            확인
          </Button>
        </div>
      </form>
      {rankUpdateSuccessModal && (
        <RankUpdateSuccessModal
          rankUpdateSuccessModal={rankUpdateSuccessModal}
          setRankUpdateSuccessModal={setRankUpdateSuccessModal}
          setRankUpdateModal={setRankUpdateModal}
        />
      )}
    </Modal>
  );
};

export default RankUpdateModal;
