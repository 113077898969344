import useSwitchTypeModalHandler from "../../hooks/handler/useSwitchTypeModalHandler";
import useFetchOrgState from "../../hooks/useQuery/useFetchOrgState";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { actions } from "../../redux/action";
import { Steps } from "antd";
import { IMP_CODE } from "../../constant/Constant";
import { verifyAuthCode } from "../../api/auth";
import Typography from "../ui/typography/Typography";
import DataTamperingWarningPop from "./Pop/DataTamperingWarningPop";
import VerifyAuthCodeErrorPop from "./Pop/VerifyAuthCodeErrorPop";
import { COLOR } from "../../constant/Palette";
import { useMediaQuery } from "react-responsive";
import s from "../../asset/css/demoBanner.module.css";
import useMyPageHandler from "../../hooks/handler/useMyPageHandler";
import useSearchSite from "../../hooks/useQuery/useSearchSite";
import SiteAttendModal from "../../pages/myPage/my-info/components/modal/site-attend/SiteAttendModal";

const DemoBanner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const user = useSelector((state) => state.user);

  const noRegOrg = user.organizationState === "REGIST_ORGANIZATION";
  const isPendingOrg = user.organizationState === "PENDING_ORGANIZATION";
  const noRegSubOrg = user.organizationState === "REGIST_SUBORGANIZATION";
  const isPendingSubOrg = user.organizationState === "PENDING_SUBORGANIZATION";
  const isAllApproved = user.organizationState === "OK";
  const isVerified = user.identityVerification;

  const { siteAttendModal, setSiteAttendModal } = useMyPageHandler();
  const { data } = useSearchSite(false, "", noRegSubOrg);
  const siteList = data?.data?.siteList;

  const constructionDesc = () => {
    if (noRegOrg) return "미입력";
    if (isPendingOrg) return "승인대기";
    return "승인완료";
  };

  const constructionStatus = () => {
    if (noRegOrg) return "process";
    if (isPendingOrg) return "wait";
    return "finish";
  };

  const siteDesc = () => {
    if (noRegOrg || isPendingOrg || noRegSubOrg) return "미입력";
    if (isPendingSubOrg) return "승인대기";
    return "승인완료";
  };

  const siteStatus = () => {
    if (noRegOrg || isPendingOrg) return "wait";
    if (noRegSubOrg) return "process";
    if (isPendingSubOrg) return "wait";
    return "finish";
  };

  const verificationDesc = () => {
    if (isVerified) return "인증완료";
    return "미인증";
  };

  const verificationStatus = () => {
    if (!isAllApproved) return "wait";
    if (isVerified) return "finish";
    return "process";
  };

  const stepList = [
    {
      title: "회사 정보 입력",
      description: constructionDesc(),
      disabled: !noRegOrg,
      status: constructionStatus(),
      onClick: () => {
        if (noRegOrg) onChange(0);
      },
    },
    {
      title: "현장 정보 입력",
      description: siteDesc(),
      disabled: !noRegSubOrg,
      status: siteStatus(),
      onClick: () => {
        if (noRegSubOrg) onChange(1);
      },
    },
    {
      title: "본인 인증",
      description: verificationDesc(),
      disabled: !isAllApproved,
      status: verificationStatus(),
      onClick: () => {
        if (isAllApproved && !isVerified) onChange(2);
      },
    },
  ];

  const [current, setCurrent] = useState(null);

  const onChange = (step) => {
    setCurrent(step);

    switch (step) {
      case 0:
        navigate("/my_page/my_info/create_construction", {
          state: { isEdit: false },
        });
        break;
      case 1:
        if (siteList?.length >= 1) {
          setSiteAttendModal(true);
        } else {
          navigate("/my_page/my_info/create_site", {
            state: { isEdit: false },
          });
        }
        break;
      case 2:
        onClickCertification();
        break;
      default:
        break;
    }
  };

  const {
    data: orgState,
    refetch: fetchOrgState,
    isSuccess,
  } = useFetchOrgState();

  useEffect(() => {
    if (
      location.state?.isFromCreateConstructionPage ||
      location.state?.isFromCreateSitePage ||
      location.state?.isFromSignupPage ||
      location.state?.isFromLoginPage
    ) {
      return;
    }

    setCurrent(null);
    fetchOrgState();

    if (isSuccess) {
      const userData = {
        ...user,
        identityVerification: orgState?.data?.identityVerification,
        organizationState: orgState?.data?.organizationState,
      };

      dispatch(actions.setUser(userData));
    }
  }, [location.pathname]);

  const [dataTamperingWarningPop, setDataTamperingWarningPop] = useState(false);
  const [verifyAuthCodeErrorPop, setVerifyAuthCodeErrorPop] = useState(false);

  const authData = {
    merchant_uid: `gong_auth_${new Date().getTime()}`,
  };

  const onAuthCertificate = async (response) => {
    const { success, merchant_uid, error_msg, imp_uid } = response;

    if (success) {
      if (merchant_uid !== authData.merchant_uid) {
        setDataTamperingWarningPop(true);
        return;
      }

      try {
        const response = await verifyAuthCode({ authCode: imp_uid });

        if (response.status === "success") {
          const userData = {
            ...user,
            u_name: response.data.name,
            identityVerification: true,
          };

          dispatch(actions.setUser(userData));
          navigate(0);
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (error_msg) {
      setVerifyAuthCodeErrorPop(true);
    }
  };

  const onClickCertification = () => {
    const { IMP } = window;
    IMP.init(IMP_CODE);
    IMP.certification(authData, onAuthCertificate);
  };

  const isDemo = user?.isDemo;
  const { handleSwitchType } = useSwitchTypeModalHandler();

  return (
    <div
      className={isVerified ? s.demo_container_isVerified : s.demo_container}
      onClick={() => {
        if (isVerified) handleSwitchType(isDemo ? false : true);
      }}
    >
      {!isVerified && (
        <>
          <Typography
            component="p"
            type={isDesktop ? "h4" : "h5"}
            color={COLOR.GRAY.L09}
          >
            서비스 사용을 위해 아래 단계를 클릭하여 진행해주세요.
          </Typography>

          <div className={s.step_container}>
            <Steps
              current={current}
              onChange={setCurrent}
              size={isDesktop ? "default" : "small"}
              direction="horizontal"
            >
              {stepList.map((step) => (
                <div
                  key={step.title}
                  title={step.title}
                  disabled={step.disabled}
                  description={step.description}
                  status={step.status}
                  onClick={step.onClick}
                />
              ))}
            </Steps>
          </div>
        </>
      )}

      {isVerified && (
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          현재 데모 진행중입니다. 테스트 발주를 자유롭게 해볼 수 있습니다. 실제
          업무에서 활용하려면 이곳을 눌러 가장 최근 현장으로 전환하세요.
        </Typography>
      )}

      <DataTamperingWarningPop
        dataTamperingWarningPop={dataTamperingWarningPop}
        setDataTamperingWarningPop={setDataTamperingWarningPop}
      />
      <VerifyAuthCodeErrorPop
        verifyAuthCodeErrorPop={verifyAuthCodeErrorPop}
        setVerifyAuthCodeErrorPop={setVerifyAuthCodeErrorPop}
      />
      {siteAttendModal && (
        <SiteAttendModal
          siteAttendModal={siteAttendModal}
          setSiteAttendModal={setSiteAttendModal}
        />
      )}
    </div>
  );
};

export default DemoBanner;
