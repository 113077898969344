import Button from "../../../../../../component/ui/button/Button";
import { useMediaQuery } from "react-responsive";

const SiteInviteButton = ({ setSiteInviteModal }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const onClickSiteInviteButton = () => {
    setSiteInviteModal(true);
  };

  return (
    <Button
      type="primary"
      size="small"
      style={{ width: isMobile && "21rem", minWidth: isMobile && "21rem" }}
      onClick={onClickSiteInviteButton}
    >
      현장 초대
    </Button>
  );
};

export default SiteInviteButton;
