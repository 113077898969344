import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { invoiceListApi } from "../../../api/auth";

export default function useInvoiceList(fromPage) {
  const userData = useSelector((state) => state.user);
  const [invoiceList, setInvoiceList] = useState();
  const [state, setState] = useState("all");

  const fetchInvoiceList = useCallback(
    async (state) => {
      try {
        const response = await invoiceListApi(userData.site_num, state);
        setInvoiceList(response.data);
        setState(state);
      } catch (error) {
        console.error(error);
      }
    },
    [userData.site_num]
  );

  useEffect(() => {
    const fetchType = fromPage ? "request" : "all";

    if (userData.site_num) fetchInvoiceList(fetchType);
  }, [fetchInvoiceList, fromPage, userData.site_num]);

  return { invoiceList, state, fetchInvoiceList };
}
