import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const BizTypeInput = ({ control }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="biz_type"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width={isDesktop ? 180 : "100%"}
          id="biz_type"
          placeholder="업태를 입력해주세요."
          label={
            <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
              업태
            </Typography>
          }
        />
      )}
    />
  );
};

export default BizTypeInput;
