import React, { useState } from 'react';
//library
import { useLocation, useNavigate } from 'react-router-dom';
//component
import Mheader from '../../component/common/Mheader';
//css
import '../../asset/css/review.css';
//etc
import i_star from '../../asset/images/m-big-star-icon-t.svg';
import i_star_n from '../../asset/images/m-big-star-icon-n.svg';
import { reviewApi } from '../../api/auth';

//sweet alert
import swal from 'sweetalert';
export default function Review() {
  const navigate = useNavigate();
  const location = useLocation();

  

  let RatingArray = new Array();
  for (let i = 0; i < 5; i++) {
    RatingArray[i] = i;
  }

  const [reviewData, setReviewData] = useState({
    or_no: location.state.or_no,
    c_no: location.state.or_c_no,
    dScore: 0,
    pScore: 0,
    qScore: 0,
    rv_review: '',
  });

  const reviewWriteFnc = async () => {
    const response = await reviewApi(reviewData);
    if (response.status === 'success' && response.message === 'ExistReview') {
      swal('이미 리뷰가 작성된 주문입니다.','','warning');
      navigate(-1);
    } else if (response.status === 'success' && !response.message) {
      navigate('/management/quick_order/review_completed',{
        state: {
          type: location.state.type,
        }
      })
    } else if (response.status === 'fail') {
      swal( "다시 시도해주세요." ,  "" ,  "warning" )
    }
  };

  const reviewValidation = () => {
    if (reviewData.or_no === 0 || reviewData.c_no === 0) {
      swal('다시 시도해주세요','','warning');
    } else if (
      reviewData.dScore === 0 ||
      reviewData.pScore === 0 ||
      reviewData.qScore === 0
    ) {
      swal('평점을 선택해주세요','','warning');
    } else if (reviewData.rv_review === '') {
      swal('후기를 작성해주세요.','','warning');
    } else {
      reviewWriteFnc();
    }
  };

  return (
    <div className='review_wrap'>
      <Mheader title='리뷰 작성하기' />
      <div className='review_container'>
        <div className='top_pagenation'>
          <h2>리뷰 작성하기</h2>
          <div className='top_pagenation_num'>
            <p className='active'>1</p>
            <p>2</p>
          </div>
        </div>
        <div className='review_contents'>
          <ul className='review_list'>
            <li>
              <p>납품 일정을 잘 지켰나요?</p>
              <div className='review_star'>
                {RatingArray.map((index) => (
                  <div
                    onClick={() => {
                      setReviewData({ ...reviewData, dScore: index + 1 });
                    }}
                    key={index}
                  >
                    <img
                      src={index < reviewData.dScore ? i_star : i_star_n}
                      alt=''
                    />
                  </div>
                ))}
              </div>
            </li>
            <li>
              <p>주문한 상품이 제대로 왔나요?</p>
              <div className='review_star'>
                {RatingArray.map((index) => (
                  <div
                    onClick={() =>
                      setReviewData({ ...reviewData, pScore: index + 1 })
                    }
                    key={index}
                  >
                    <img
                      src={index < reviewData.pScore ? i_star : i_star_n}
                      alt=''
                    />
                  </div>
                ))}
              </div>
            </li>
            <li>
              <p>질의 대응이 빨랐나요?</p>
              <div className='review_star'>
                {RatingArray.map((index) => (
                  <div
                    onClick={() =>
                      setReviewData({ ...reviewData, qScore: index + 1 })
                    }
                    key={index}
                  >
                    <img
                      src={index < reviewData.qScore ? i_star : i_star_n}
                      alt=''
                    />
                  </div>
                ))}
              </div>
            </li>
            <li>
              <p>후기를 남겨주세요.</p>
              <textarea
                placeholder='후기 내용을 입력해 주세요.'
                onChange={(e) => {
                  setReviewData({ ...reviewData, rv_review: e.target.value });
                }}
              ></textarea>
            </li>
          </ul>
        </div>
        <div className='review_btn_box btn_box'>
          <div className='prev'>
            <p>이전</p>
          </div>
          <div className='ok'>
            <p
              onClick={() => {
                reviewValidation();
              }}
            >
              작성
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
