import React from "react";
import { Controller } from "react-hook-form";
import Input from "../../../../../../../../../component/ui/input/Input";
import Typography from "../../../../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../../../../constant/Palette";

const UserEmailInput = ({ control, errors }) => {
  return (
    <Controller
      name="email"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width="100%"
          id="email"
          placeholder="이메일 주소를 입력해주세요."
          helpText={
            errors.email && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.email.message}
              </Typography>
            )
          }
          status={errors.email && "error"}
        />
      )}
    />
  );
};

export default UserEmailInput;
