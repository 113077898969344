import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import Button from "../../../../../../component/ui/button/Button";
import { COLOR } from "../../../../../../constant/Palette";

const PwdUpdateSuccessModal = ({
  pwdUpdateSuccessModal,
  setPwdUpdateSuccessModal,
  setPwdUpdateModal,
}) => {
  const onClickCancel = () => {
    setPwdUpdateSuccessModal(false);
    setPwdUpdateModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="success"
      open={pwdUpdateSuccessModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.BLUE.L05}>
          비밀번호 변경 완료
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="primary" size="small" onClick={onClickCancel}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        비밀번호 변경이 정상적으로 완료되었습니다.
      </Typography>
    </Modal>
  );
};

export default PwdUpdateSuccessModal;
