import useSwitchTypeModalHandler from "../../../../../../hooks/handler/useSwitchTypeModalHandler";
import { useSelector } from "react-redux";
import Button from "../../../../../../component/ui/button/Button";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const SwitchTypeModal = ({ switchTypeModal, setSwitchTypeModal }) => {
  const user = useSelector((state) => state.user);
  const isDemo = user?.isDemo;
  const { handleSwitchType } = useSwitchTypeModalHandler();
  const onClickCancel = () => {
    setSwitchTypeModal(false);
  };
  const onClickOk = () => {
    handleSwitchType(isDemo ? false : true);
    setSwitchTypeModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="success"
      open={switchTypeModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.BLUE.L05}>
          {isDemo ? "서비스 사용" : "테스트"} 환경으로 전환하시겠습니까?
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button type="primary" size="small" onClick={onClickOk}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        확인을 누르면 작업 환경을 전환합니다.
      </Typography>
    </Modal>
  );
};

export default SwitchTypeModal;
