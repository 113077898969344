import React, { useState } from "react";
//library
import { useNavigate } from "react-router-dom";

//css
import "../../../asset/css/pop.css";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import { orderCancelApi } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function OrderFormCancelPop({
  setOrderFormPop,
  orderNum,
  move2Success,
  bidType,
}) {
  const navigate = useNavigate();

  const [cancelData, setCancelData] = useState({
    or_no: orderNum,
    or_cancel: "",
  });

  const orderCancelFnc = async () => {
    if (cancelData.or_no === 0) {
      swal("다시 시도해주세요.", "", "warning");
    } else if (cancelData.or_cancel === "") {
      swal("취소사유를 입력해주세요.", "", "warning");
    } else if (bidType === "B" || bidType === "D") {
      swal(
        "PB주문서 취소는 고객센터를 통해서 가능합니다.",
        "고객센터: 032-205-0479",
        "warning"
      );
    } else {
      const response = await orderCancelApi(
        cancelData.or_no,
        cancelData.or_cancel
      );
      if (response.status === "success") {
        swal("주문이 취소되었습니다.", "", "success");
        // move2Success();
        navigate("/management/order/1");
        setOrderFormPop(false);
      } else if (response.status === "fail") {
        swal("다시 시도해주세요.", "", "warning");
      }
    }
  };

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setOrderFormPop(false)}></div>
      <div className="contract_pop">
        <div className="close" onClick={() => setOrderFormPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <div className="contract_pop_tit">
          <h3>주문서 발행 취소를 요청하시겠습니까?</h3>
          <p>주문서 발행 취소 사유를 입력해주세요</p>
        </div>
        <input
          placeholder="주문 취소 사유를 입력해주세요"
          maxLength={255}
          onChange={(e) => {
            setCancelData({ ...cancelData, or_cancel: e.target.value });
          }}
        />
        <div className="confirm_btn">
          <p
            onClick={() => {
              orderCancelFnc();
            }}
          >
            확인
          </p>
        </div>
      </div>
    </div>
  );
}
