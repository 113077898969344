import React, { useState, useEffect } from "react";
//library
import { useNavigate, useParams } from "react-router-dom";
//component
import Mheader from "../../../component/common/Mheader";

//css
import s from "../../../asset/css/pbOrder.module.css";
import * as S from "../../../asset/css/orderDetail.styles.js";
import { pbOrdersDetailApi, pbOrdersPdfApi } from "../../../api/auth";
import fire_waste from "../../../asset/images/fire_waste.png";
import no_fire_waste from "../../../asset/images/no_fire_waste.png";
import { numberWithCommas } from "../../../util/Util";

//sweet alert
import swal from "sweetalert";
import { useMediaQuery } from "react-responsive";
import Button from "../../../component/ui/button/Button.js";
import DownloadSVG from "../../../asset/images/svg/DownloadSVG.js";
import { Divider } from "antd";
import TypeChip from "./components/chip/TypeChip.js";
import StateChip from "./components/chip/StateChip.js";
import ReactTooltip from "react-tooltip";

const getPBOrderState = (state) => {
  if (state === "PENDING") return "입금대기";
  if (state === "READY") return "배송대기";
  if (state === "DONE") return "배송완료";
  if (state === "CANCEL") return "취소";

  return "";
};

export default function OrderDetail() {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const { orderNo } = useParams();

  const [detailInfo, setDetailInfo] = useState({});
  const [pbProductList, setPbProductList] = useState([]);
  const [ordersGroupList, setOrdersGroupList] = useState();

  const pbOrderDetailFnc = async () => {
    const res = await pbOrdersDetailApi(orderNo);
    if (res.status === "success") {
      setDetailInfo(res.data);
      setPbProductList(res.data.pbProductDetailInfoList);
    }
  };

  useEffect(() => {
    pbOrderDetailFnc();
  }, []);

  const [selectedTab, setSelectedTab] = useState(0);
  const [hoveredTab, setHoveredTab] = useState(null);

  const selectTabHandler = (index) => {
    setSelectedTab(index);
  };

  const hoverTabHandler = (index) => {
    setHoveredTab(index);
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return "";
    }

    phoneNumber = phoneNumber.replace(/\D/g, "");

    if (phoneNumber.length === 10) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else if (phoneNumber.length === 11) {
      return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      return phoneNumber;
    }
  };

  const pbOrdersPdfFnc = async () => {
    const response = await pbOrdersPdfApi(orderNo);
    if (response.status === "success") {
      window.open(response.data.fileRoot, "_blank");
    } else {
      swal(
        "",
        "거래명세서 다운로드에 실패했습니다. 다시 시도해주세요.",
        "error"
      );
    }
  };

  const calcDownloadFnc = async (url) => {
    window.open(url, "_blank");
  };

  const isTaxFileIssued = !!detailInfo?.taxFileUrl;

  const moveToPBOrderListPage = () => {
    navigate("/management/pb/order/1");
  };

  return (
    <div className={s.page_container}>
      <Mheader title="주문서 상세 내역" />
      <section className={s.header_section}>
        <div className={s.header_desc}>
          <p>상세정보</p>
          <p>주문 내역과 상태를 확인할 수 있습니다.</p>
        </div>

        <div className={s.header_button}>
          {isTaxFileIssued && (
            <Button
              size="medium"
              type="secondary"
              withIcon
              icon={<DownloadSVG size="small" stroke="var(--primary-10)" />}
              iconPosition="end"
              onClick={() => {
                detailInfo?.taxFileUrl !== null &&
                  calcDownloadFnc(detailInfo?.taxFileUrl);
              }}
            >
              세금계산서
            </Button>
          )}
          <Button
            size="medium"
            type="secondary"
            withIcon
            icon={<DownloadSVG size="small" stroke="var(--primary-10)" />}
            iconPosition="end"
            onClick={pbOrdersPdfFnc}
          >
            거래명세서
          </Button>
        </div>
      </section>

      <Divider className={s.header_section_divider} />

      <section className={s.card_section}>
        <div className={s.card_order_no_container}>
          <span>주문번호 : {detailInfo?.pbOrders?.code || "-"}</span>
          <span>
            발행시간 : {detailInfo.pbOrders?.createdDate.slice(0, 16) || "-"}
          </span>
        </div>

        <div className={s.card_order_contents_container}>
          <div className={s.card_order_contents_chip_container}>
            <TypeChip type={isTaxFileIssued} />
            <StateChip state={detailInfo.pbOrders?.state} />
          </div>

          <div className={s.card_order_contents_title_container}>
            <span className={s.card_order_contents_title}>
              {detailInfo?.pbOrders?.title || "-"}
            </span>
            {isDesktop && (
              <Divider
                className={s.card_order_contents_title_divider}
                type="vertical"
              />
            )}
            <span className={s.card_order_contents_site_name}>
              {detailInfo?.siteName || "-"}
            </span>
          </div>
        </div>
      </section>

      <section className={s.order_section}>
        <div className={s.order_info_container}>
          <p className={s.order_info_title}>주문자</p>
          <Divider className={s.order_section_divider} />
          <div className={s.order_info_detail}>
            <div>
              <div>
                <p>고객사명</p>
                <p>{detailInfo?.constructionName || "-"}</p>
              </div>
              <div>
                <p>현장명</p>
                <p>{detailInfo?.siteName || "-"}</p>
              </div>
            </div>
            <div>
              <div>
                <p>주문자명</p>
                <p>{detailInfo?.orderUserName || "-"}</p>
              </div>
              <div>
                <p>주문자 연락처</p>
                <p>{formatPhoneNumber(detailInfo?.orderUserPhone) || "-"}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={s.order_info_container}>
          <p className={s.order_info_title}>주문 정보</p>
          <Divider className={s.order_section_divider} />

          <div className={s.order_info_detail}>
            <div>
              <div>
                <p>주문번호</p>
                <p>{detailInfo?.pbOrders?.code || "-"}</p>
              </div>
              <div>
                <p>주문상태</p>
                <p>
                  {getPBOrderState(detailInfo?.pbOrders?.state)}{" "}
                  {!isTaxFileIssued && "(세금계산서 미발행)"}
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>주문 / 입찰 방식</p>
                <p>PB상품 주문</p>
              </div>
              <div>
                <p>배송업체</p>
                <p>{detailInfo?.deliveryCompanyName || "-"}</p>
              </div>
            </div>
            <div>
              <div>
                <p>배송 담당자</p>
                <p>{detailInfo?.deliveryUserName || "-"}</p>
              </div>
              <div>
                <p>배송 담당 연락처</p>
                <p>{detailInfo?.deliveryUserPhone || "-"}</p>
              </div>
            </div>

            <div />

            <div>
              <div>
                <p>수령인</p>
                <p>{detailInfo?.pbOrders?.recipient || "-"}</p>
              </div>
              <div>
                <p>수령인 연락처</p>
                <p>{formatPhoneNumber(detailInfo?.pbOrders?.phone) || "-"}</p>
              </div>
            </div>
            <div>
              <div>
                <p>납품 주소</p>
                <p>
                  {detailInfo?.pbOrders?.address}{" "}
                  {detailInfo?.pbOrders?.addressDetail}
                </p>
              </div>
              <div>
                <p>배송비</p>
                <p>배송비 포함</p>
              </div>
            </div>
            <div>
              <div>
                <p>배송 일정</p>
                <p>입금 확인 후 일주일 이내</p>
              </div>
              <div>
                <p>하차 역무</p>
                <p>하차 역무 있음</p>
              </div>
            </div>
          </div>
        </div>

        <div className={s.order_info_container}>
          <p className={s.order_info_title}>결제 정보</p>
          <Divider className={s.order_section_divider} />
          <div className={s.order_info_detail}>
            <div>
              <div>
                <p>비용 정산 방법</p>
                <p>무통장 입금</p>
              </div>
              <div>
                <p>예금주명</p>
                <p>(주) 공새로</p>
              </div>
            </div>
            <div>
              <div>
                <p>입금은행</p>
                <p>우리은행</p>
              </div>
              <div>
                <p>계좌번호</p>
                <p>1005-004-232090</p>
              </div>
            </div>
            <div>
              <div>
                <p>세금계산서 발행</p>
                <p>contact@gongsaero.com</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={s.help_text_section}>
        <p>* 최초 주문시 "사업자등록증" 첨부 부탁드립니다.</p>
        <p>
          * 입금이 완료되어야 주문이 진행되며, 2주 내에 입금되지 않으면 주문이
          자동 취소됩니다.
        </p>
      </section>

      <section className={s.product_section}>
        <p className={s.product_section_title}>품목 내역</p>
        <Divider className={s.product_section_divider} />

        <S.Wrap>
          {ordersGroupList?.map((_, index) => (
            <S.TabWrap key={index}>
              {selectedTab === index ? (
                <a data-tip="React-tooltip">
                  <S.SelectedTabBtn
                    onClick={() => {
                      selectTabHandler(index);
                    }}
                    onMouseEnter={() => {
                      hoverTabHandler(index);
                    }}
                  >
                    {index === 0
                      ? `${ordersGroupList.length}(최종)`
                      : ordersGroupList.length - index}
                  </S.SelectedTabBtn>
                  {isDesktop && (
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      backgroundColor="#002863"
                    >
                      <span>
                        수정일시 :{" "}
                        {ordersGroupList[hoveredTab]?.createdDate
                          .replace("T", " ")
                          .slice(0, 16)}
                      </span>
                    </ReactTooltip>
                  )}
                </a>
              ) : (
                <a data-tip="React-tooltip">
                  <S.TabBtn
                    onClick={() => {
                      selectTabHandler(index);
                    }}
                    onMouseEnter={() => {
                      hoverTabHandler(index);
                    }}
                  >
                    {index === 0
                      ? `${ordersGroupList.length}(최종)`
                      : ordersGroupList.length - index}
                  </S.TabBtn>
                  {isDesktop && (
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      backgroundColor="#002863"
                    >
                      <span>
                        수정일시 :{" "}
                        {ordersGroupList[hoveredTab]?.createdDate
                          .replace("T", " ")
                          .slice(0, 16)}
                      </span>
                    </ReactTooltip>
                  )}
                </a>
              )}
            </S.TabWrap>
          ))}
        </S.Wrap>

        {!selectedTab && (
          <div className="subject_ok_table">
            <table>
              <tbody>
                <tr>
                  <th>
                    <p>품목명</p>
                  </th>
                  <th>
                    <p>규격</p>
                  </th>
                  <th>
                    <p>단위</p>
                  </th>
                  <th>
                    <p>수량</p>
                  </th>
                  <th>
                    <p>단가</p>
                  </th>
                  <th>
                    <p>금액</p>
                  </th>
                </tr>
                {pbProductList?.map((data, i) => {
                  return (
                    <tr
                      className={data.state === "ADD" ? "subject_t_add" : null}
                    >
                      <S.ProductNameWrap>
                        <S.ProductImage
                          src={
                            data.name === "가연성 폐기물 마대 (40kg)"
                              ? fire_waste
                              : no_fire_waste
                          }
                          alt=""
                          onClick={() =>
                            window.open(
                              data.name === "가연성 폐기물 마대 (40kg)"
                                ? fire_waste
                                : no_fire_waste,
                              "_blank"
                            )
                          }
                        />
                        <span>{data?.name}</span>
                      </S.ProductNameWrap>
                      <td>
                        <p className="p_list_standard limit_max_height">
                          {data?.standard?.replace(/, /gi, "\n")}
                        </p>
                      </td>
                      <td>{data?.unit}</td>
                      <td>{numberWithCommas(data?.count)}</td>
                      <td>{numberWithCommas(data.price)}원</td>
                      <td>{numberWithCommas(data.price * data?.count)}원</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan="5">공급가액 (A)</td>
                  <td>
                    <strong>
                      {detailInfo?.pbOrders?.totalAmount?.toLocaleString("ko")}
                      원
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">부가세 (B)</td>
                  <td>
                    <strong>
                      {(
                        detailInfo?.pbOrders?.totalVatAmount -
                        detailInfo?.pbOrders?.totalAmount
                      )?.toLocaleString("ko")}
                      원
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">합계 (A+B)</td>
                  <td>
                    <strong>
                      {(
                        detailInfo?.pbOrders?.totalVatAmount || 0
                      )?.toLocaleString("ko")}
                      원
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </section>

      <section className={s.additional_section}>
        <p className={s.additional_section_title}>추가 정보</p>
        <Divider className={s.additional_section_divider} />
        <div className={s.additional_detail}>
          <div>
            <p>고객사(주문) 비고</p>
            <p>{detailInfo.pbOrders?.uniqueness || "-"}</p>
          </div>
        </div>
      </section>

      <section className={s.bottom_button_section}>
        {isDesktop && (
          <Button
            size="medium"
            type="secondary"
            onClick={moveToPBOrderListPage}
          >
            목록으로
          </Button>
        )}
      </section>
    </div>
  );
}
