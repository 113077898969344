//library
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

//css
import "../../asset/css/requestsimple.css";

//api
import { imgUpApi, productRecommendListApi } from "../../api/auth";

// image
import circle_plus from "../../asset/images/circle-plus.png";
import search_icon from "../../asset/images/search-icon.png";
import x_icon from "../../asset/images/icon_close_x.svg";
import question_icon from "../../asset/images/excel_question_icon.png";

import company_icon from "../../asset/images/excel_company_icon.png";
import pencil_icon from "../../asset/images/excel_pencil_icon.png";
import note_icon from "../../asset/images/excel_note_icon.png";
import active_company_icon from "../../asset/images/excel_company_gray_icon.png";
import active_pencil_icon from "../../asset/images/excel_pencil_gray_icon.png";
import active_note_icon from "../../asset/images/excel_note_gray_icon.png";

//alert
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { useMediaQuery } from "react-responsive";

// component
import Mheader from "../../component/common/Mheader";
import ExcelComponent from "../../component/etc/ExcelComponent";
import ProductChoicePop from "../../component/common/Pop/ProductChoicePop";
import MaterialTablePop from "../../component/common/Pop/MaterialTablePop";
import ExcelDownloadPop from "../../component/common/Pop/ExcelDownloadPop";
import Loading from "../../component/etc/Loading";
import { checkValidationFile } from "../../util/uploadValidation";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";
import Button from "../../component/ui/button/Button";
import Typography from "../../component/ui/typography/Typography";
import useScrollFixed from "../../hooks/useScrollFixed.js";

export default function Request() {
  // Hook 초기화
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [emptyAlert, setEmptyAlert] = useState(false);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });

  // 상태 초기화
  const defaultMethod = location.state?.choiceOrderMethod;
  const bidProductList = location.state?.bidProductList;
  const bidImgList = location.state?.bidImgList;
  const bidComment = location.state?.comment;
  const reBidComment = location.state?.reBidComment;
  const previousBidNo = location.state?.previousBidNo;
  const extraType = location.state?.extraType;
  const isFromDashboardFloodBanner = location.state?.fromDashboardFloodBanner;
  const isFromSearchList = location.state?.fromPage;

  const [choiceOrderMethod, setChoiceOrderMethod] = useState(defaultMethod);
  const [uploadFile, setUploadFile] = useState([{ file: "", id: 0 }]);
  const [uploadImg, setUploadImg] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [preSelectData, setPreSelectData] = useState([]);
  const [comment, setComment] = useState("");
  const [fList, setFList] = useState([]);
  const [excelPop, setExcelPop] = useState(false);
  const [generating, setGenerating] = useState(false); // loading
  const [choicePop, setChoicePop] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedName, setSelectedName] = useState("자재패키지 목록");
  const [materialTablePop, setMaterialTablePop] = useState(false);

  const redux_excel = useSelector((state) => state.excel);
  const redux_attach_excel = useSelector((state) => state.attachExcel);

  const isEmptyRedux = redux_excel.length === 0 || redux_excel === undefined;
  const getListPop = location.state?.getListPop;
  const elemNo = location.state?.elemNo;
  const { id } = useParams();
  const { isFixed } = useScrollFixed(isDesktop ? 200 : 300);

  // 토스트 알림
  const notifySuccess = () => {
    toast.success(<span className="toast_title">제품을 담았습니다.</span>);
  };

  const notifyAdd = (count) => {
    toast.success(
      <span className="toast_title">{count}행이 추가되었습니다.</span>
    );
  };

  const notifyRemove = (count) => {
    toast.warn(
      <span className="toast_title">{count} 행이 삭제되었습니다.</span>
    );
  };

  const notifyTextOverflow = () => {
    toast.warn(<span className="toast_title">300자 이내로 작성해주세요.</span>);
  };

  const notifySample = (sample) => {
    toast.success(
      <div className="toast_wrap">
        <span className="toast_title">
          {sample} 자재패키지가 적용되었습니다.
        </span>
        <span className="toast_contents">
          상세 규격을 확인 후 주문바랍니다.
        </span>
      </div>
    );
  };

  const sampleList = [
    { no: 1, name: "추천 50 품목" },
    { no: 2, name: "비산먼지대비" },
    { no: 3, name: "방한대비" },
    { no: 4, name: "수방대비" },
  ];

  const [generating1, setGenerating1] = useState(false);
  const [rows, setRows] = useState(3);
  const [cols, setCols] = useState(6);
  const [data, setData] = useState();

  // 함수들
  const createGridData = (rows, cols) => {
    const data = [];
    for (let i = 0; i < rows; i++) {
      const rowData = [];
      for (let j = 0; j < cols; j++) {
        rowData.push({
          value: j === 4 ? "무관" : "",
        });
      }
      data.push(rowData);
    }
    return data;
  };

  const addRow = () => {
    const rowData = [];
    for (let j = 0; j < cols; j++) {
      rowData.push({
        value: j === 4 ? "무관" : "",
      });
    }

    setData([...redux_excel, rowData]);
    setRows(redux_excel.length);
    notifyAdd(redux_excel.length + 1);
  };

  const handleFileUpload = async (file, id) => {
    const checkFile = checkValidationFile(file);
    if (!checkFile) return false;

    const formData = new FormData();
    formData.append("file", file);
    setGenerating(true);

    const response = await imgUpApi(formData);
    if (response.status === "success") {
      const newFile = {
        fileNo: response.data.f_no,
        fileSize: convertFileSize(file.size),
        fileName: response.data.f_name,
        filePath: response.data.f_path,
        fileIndex: response.data.f_no,
      };

      setFList((prevFList) => {
        const updatedFList = [...prevFList, newFile];
        dispatch(
          actions.setAttachExcel({
            ...redux_attach_excel,
            comment: comment,
            file: updatedFList,
          })
        );
        return updatedFList;
      });
    } else if (response.status === "NoData" || response.status === "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
    setGenerating(false);
  };

  const deleteAll = () => {
    swal({
      title: "",
      text: `작성하신 엑셀 품목이 삭제됩니다.\n 정말 삭제하시겠습니까?`,
      icon: "warning",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        setData(createGridData(3, 6));
        notifyRemove("전체");
      }
    });
  };

  const onClickSampleMapping = async (no) => {
    setGenerating1(true);
    const response = await productRecommendListApi(no);
    if (response.status === "success") {
      const pList = response.data.recommendProductList?.map((item) => [
        { value: item.name },
        { value: item.standard },
        { value: item.unit },
        { value: item.count },
        { value: "무관" },
        { value: item.etc },
      ]);

      setData(pList);
      setRows(response.data.recommendProductList.length);
      setGenerating1(false);
    }
  };

  const validateExcelFnc = async () => {
    const removeEmptyRows = (data) => {
      return data.filter((item) => {
        const allEmpty = item.every(
          (field, index) =>
            field.value === "" || (index === 4 && field.value === "무관")
        );
        return !allEmpty;
      });
    };

    const cleanedData = removeEmptyRows(data);
    checkAndAlert(cleanedData);
  };

  const checkAndAlert = (data) => {
    let hasEmptyFields = false;

    for (const item of data) {
      if (
        !item[0].value ||
        !item[2].value ||
        !item[3].value ||
        item[3].value <= 0
      ) {
        hasEmptyFields = true;
        break;
      }
    }

    if (hasEmptyFields) {
      swal({
        title: "필수 정보가 누락된 항목이 존재합니다.",
        text: `품목명, 단위, 수량은 필수 입력 정보입니다.\n 누락된 항목을 확인하시어 입력해주시기 바랍니다.`,
        icon: "error",
        buttons: true,
      }).then(() => {
        setEmptyAlert(true);
        setData(data);
        dispatch(actions.setExcel(data));
      });
    } else {
      goEstimateFnc(data);
      dispatch(actions.setExcel(data));
    }
  };

  const goEstimateFnc = async (data) => {
    if (data.length === 0) {
      return swal("오류", "한줄 이상 입력해주세요.", "error");
    }
    if (!choiceOrderMethod) {
      return swal("오류", "진행 방식을 선택해주세요.", "error");
    }
    const pList = data.map((item) => ({
      name: item[0]?.value,
      standard: item[1]?.value || "",
      unit: item[2]?.value,
      count: Number(item[3]?.value),
      brand: item[4]?.value || "",
      etc: item[5]?.value,
    }));

    navigate("/management/bid/request_input_info", {
      state: {
        comment: comment,
        reBidComment: reBidComment,
        bidImgList: fList,
        bidProductList: pList,
        choiceOrderMethod: choiceOrderMethod,
        ...(previousBidNo && { previousBidNo: previousBidNo }),
        ...(previousBidNo && { extraType: extraType }),
      },
    });
  };

  const handleCommentChange = (e) => {
    const value = e.target.value;

    if (value.length <= 300) {
      setComment(value);
      dispatch(
        actions.setAttachExcel({
          ...redux_attach_excel,
          file: fList,
          comment: value,
        })
      );
    } else {
      const truncatedText = value.substring(0, 300);
      setComment(truncatedText);
      dispatch(
        actions.setAttachExcel({
          ...redux_attach_excel,
          file: fList,
          comment: truncatedText,
        })
      );
      notifyTextOverflow();
    }
  };

  const handleDelete = (idx) => {
    const updatedItems = fList.filter((item) => item.fileIndex !== idx);
    setFList(updatedItems);
    saveReduxFile(updatedItems);
  };

  const saveReduxFile = (updatedItems) => {
    dispatch(
      actions.setAttachExcel({
        ...redux_attach_excel,
        file: updatedItems,
      })
    );
  };

  const handleMethodChange = (method) => {
    setChoiceOrderMethod(method);
    dispatch(
      actions.setAttachExcel({
        ...redux_attach_excel,
        method: method,
      })
    );
  };

  const convertFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + "KB";
    } else {
      return (bytes / (1024 * 1024)).toFixed(2) + "MB";
    }
  };

  const onChangeSelectValue = (e) => {
    const no = e.currentTarget.value;

    onClickSampleMapping(no);
    const item = sampleList.find((item) => item.no == no);
    notifySample(item.name);
    setSelectedName(item.name);
    setSelectedValue("");
  };

  // useEffect들
  useEffect(() => {
    if (isFromDashboardFloodBanner) {
      onClickSampleMapping(4);
      setSelectedName("수방대비");
      notifySample("수방대비");
    }
  }, [isFromDashboardFloodBanner]);

  useEffect(() => {
    if (getListPop) {
      setChoicePop(true);
      if (elemNo) {
        navigate(`/management/bid/request_excel/detail/${elemNo}`);
      } else {
        navigate("/management/bid/request_excel");
      }
    }
    if (id) {
      setChoicePop(true);
    }
    if (getListPop === false) {
      setChoicePop(false);
    }
  }, [getListPop, elemNo, id, navigate]);

  useEffect(() => {
    if (selectData.length !== 0) {
      setPreSelectData(selectData);
    }
  }, [selectData]);

  useEffect(() => {
    if (previousBidNo) {
      const reBidData = bidProductList.map((item) => [
        { value: item.name },
        { value: item.standard || "" },
        { value: item.unit },
        { value: item.count },
        { value: item.brand || "" },
        { value: item.etc },
      ]);

      setData(reBidData);
      dispatch(
        actions.setAttachExcel({
          ...redux_attach_excel,
          method: defaultMethod,
          file: bidImgList,
          comment: bidComment,
        })
      );
    } else {
      setData(redux_excel);
    }
  }, []);

  useEffect(() => {
    if (data) dispatch(actions.setExcel(data));
  }, [data]);

  useEffect(() => {
    if (isEmptyRedux && !previousBidNo) {
      setData(createGridData(rows, cols));
    }
    if (previousBidNo) {
      setChoiceOrderMethod(defaultMethod);
      setComment(bidComment || "");
      setFList(bidImgList || []);
    } else if (redux_attach_excel) {
      setChoiceOrderMethod(redux_attach_excel?.method);
      setComment(redux_attach_excel?.comment || "");
      setFList(redux_attach_excel?.file || []);
    }
  }, []);

  const prevStepFnc = () => {
    navigate(-1);
  };

  return (
    <div className="request_container">
      <Mheader title="내역 작성하기" />
      <div className="request_container_wrap">
        <div className="top_box">
          <div className="title">내역 작성하기</div>
          <OrderBreadCrumb
            step1="내역 작성"
            step2="상세정보 입력"
            step3="상세정보 확인"
            // step4="주문/입찰 완료"
            step4={previousBidNo ? "재공고 완료" : "주문/입찰 완료"}
            isActive={1}
          />
        </div>

        <div className="top_request_sub_header">
          <div className="top_request_sub_header_wrap">
            <p>
              <span>엑셀란은 빈행없이 작성해주세요. (비고 제외)</span>
              <br />
              가지고 계신 엑셀 파일에서 복사/붙여넣기가 가능합니다.
              <br />
              <span
                className="sub_header_link"
                onClick={() => setMaterialTablePop(true)}
              >
                잡자재 기준 안내
              </span>
            </p>
          </div>

          <div className="excel_choice_form">
            <div>
              <span className="excel_elem_title input_choice_title">
                진행 방식
              </span>

              <div className="request_switch_box">
                {/* 업체지정주문 */}
                {!previousBidNo && (
                  <div
                    className={
                      choiceOrderMethod === "업체지정주문"
                        ? "request_switch_active"
                        : "request_switch"
                    }
                    onClick={() => {
                      // setOrderCompanyPop(true);
                      handleMethodChange("업체지정주문");
                      // setChoiceOrderMethod("업체지정주문");
                    }}
                  >
                    <div className="request_switch_inner">
                      <div className="request_switch_img">
                        <img
                          src={
                            choiceOrderMethod === "업체지정주문"
                              ? company_icon
                              : active_company_icon
                          }
                        />
                      </div>
                      <div className="request_switch_desc">
                        <span
                          className={
                            choiceOrderMethod === "업체지정주문"
                              ? "switch_active_title"
                              : "switch_title"
                          }
                        >
                          업체지정주문
                        </span>
                      </div>
                    </div>
                    <div>
                      <a
                        data-tip="React-tooltip"
                        data-for="업체지정주문"
                        className="material_table_anchor"
                        href={() => false}
                      >
                        <img
                          src={question_icon}
                          className="request_switch_question_img"
                          alt="?"
                        />
                      </a>
                      <ReactTooltip
                        id="업체지정주문"
                        place="bottom"
                        // type="dark"
                        effect="solid"
                        backgroundColor="#d9d9d9"
                        textColor="#1b1d1f"
                        className="material_table_tooltip"
                        offset="{'bottom': 10}"
                      >
                        구매 품목을 작성 후, 원하는 업체를 지정하여 주문합니다.
                      </ReactTooltip>
                    </div>
                  </div>
                )}
                {/* 일회성 입찰 공고 */}
                <div
                  className={
                    choiceOrderMethod === "일회성입찰공고"
                      ? "request_switch_active"
                      : "request_switch"
                  }
                  // onClick={() => onClickSwitch(SECOND)}
                  onClick={() => handleMethodChange("일회성입찰공고")}
                >
                  <div className="request_switch_inner">
                    <div className="request_switch_img">
                      <img
                        src={
                          choiceOrderMethod === "일회성입찰공고"
                            ? pencil_icon
                            : active_pencil_icon
                        }
                      />
                    </div>
                    <div className="request_switch_desc">
                      <span
                        className={
                          choiceOrderMethod === "일회성입찰공고"
                            ? "switch_active_title"
                            : "switch_title"
                        }
                      >
                        일회성 입찰 공고
                      </span>
                    </div>
                  </div>
                  <div>
                    <a
                      data-tip="React-tooltip"
                      data-for="aaa"
                      className="material_table_anchor"
                      href={() => false}
                    >
                      <img
                        src={question_icon}
                        className="request_switch_question_img"
                        alt="?"
                      />
                    </a>
                    <ReactTooltip
                      id="aaa"
                      place="bottom"
                      // type="dark"
                      effect="solid"
                      backgroundColor="#d9d9d9"
                      textColor="#1b1d1f"
                      className="material_table_tooltip"
                      offset="{'bottom': 10}"
                    >
                      일회성 입찰을 통해 최저가 공급사를 선정합니다.
                    </ReactTooltip>
                  </div>
                </div>
                {/* 단가 계약 입찰 공고 */}
                <div
                  className={
                    choiceOrderMethod === "단가계약입찰공고"
                      ? "request_switch_active"
                      : "request_switch"
                  }
                  // onClick={() => onClickSwitch(THIRD)}
                  onClick={() => handleMethodChange("단가계약입찰공고")}
                >
                  <div className="request_switch_inner">
                    <div className="request_switch_img">
                      <img
                        src={
                          choiceOrderMethod === "단가계약입찰공고"
                            ? note_icon
                            : active_note_icon
                        }
                      />
                    </div>
                    <div className="request_switch_desc">
                      <span
                        className={
                          choiceOrderMethod === "단가계약입찰공고"
                            ? "switch_active_title"
                            : "switch_title"
                        }
                      >
                        단가 계약 입찰 공고
                      </span>
                    </div>
                  </div>

                  <div>
                    <a
                      data-tip="React-tooltip3"
                      data-for="단가계약입찰공고"
                      className="material_table_anchor"
                      href={() => false}
                    >
                      <img
                        src={question_icon}
                        className="request_switch_question_img"
                        alt="?"
                      />
                    </a>
                    <ReactTooltip
                      id="단가계약입찰공고"
                      place="bottom"
                      // type="dark"
                      effect="solid"
                      backgroundColor="#d9d9d9"
                      textColor="#1b1d1f"
                      className="material_table_tooltip"
                      offset="{'bottom': 10}"
                    >
                      입찰을 통해 최저가 공급사와 원하는 기간동안 단가 계약을
                      체결합니다.
                    </ReactTooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="excel_sample_wrap">
            {/* <span className="excel_sample_desc">자재패키지 양식 자동입력</span> */}
            <div className="excel_sample_container">
              <div className="excel_sample_btns">
                <select onChange={onChangeSelectValue} value={selectedValue}>
                  <option selected>{selectedName}</option>
                  {sampleList.map((elem, idx) => {
                    return <option value={elem.no}>{elem.name}</option>;
                  })}
                </select>
              </div>

              <div className="top_excel_btn_inner">
                <div
                  className="excel_search_btn"
                  onClick={() => {
                    setChoicePop(true);
                  }}
                >
                  <span>품목 검색</span>
                  <img src={search_icon} />
                </div>
                <div className="excel_search_btn" onClick={addRow}>
                  <span>행 추가</span>
                  <img src={circle_plus} />
                </div>
              </div>
            </div>
            {/* {isPosco && (
                <div
                  className="excel_sample_btn"
                  onClick={() => setMaterialTablePop(true)}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: isResponsive ? "99%" : "140px",
                    marginTop: isResponsive ? "3px" : 0,
                  }}
                >
                  <span>잡자재 기준 안내</span>
                  <a
                    data-tip="React-tooltip"
                    data-for="warning"
                    className="material_table_anchor"
                    href={() => false}
                  >
                    <img src={warning_icon} alt="warning_icon" />
                  </a>
                  <ReactTooltip
                    id="warning"
                    place="bottom"
                    type="dark"
                    effect="solid"
                    backgroundColor="#E1E5F6"
                    className="material_table_tooltip"
                    offset="{'bottom': 10}"
                  >
                    ※본사 구매 규정을 확인 후 진행하시기 바랍니다.
                  </ReactTooltip>
                </div>
              )} */}
          </div>
        </div>

        <ExcelComponent
          data={data}
          setData={setData}
          cols={cols}
          setCols={setCols}
          rows={rows}
          setRows={setRows}
          addRow={addRow}
          notifyRemove={notifyRemove}
          emptyAlert={emptyAlert}
          setEmptyAlert={setEmptyAlert}
        />
        <div className="excel_delete_controls">
          <span className="delete_control" onClick={deleteAll}>
            전체삭제
          </span>
        </div>

        <div className="excel_attach_info">
          <div className="excel_attach_elem">
            <div className="excel_elem_title">추가 설명</div>
            <div className="textarea_div" spellCheck="false">
              <textarea
                placeholder="추가 설명이 있으시다면 작성해주세요."
                onChange={handleCommentChange}
                value={comment}
              ></textarea>
              <div className="add_text_alert_container">
                <span className="add_alert_desc">
                  * 300자 이내로 작성해 주세요.
                </span>
                <span className="add_alert_typing">{comment.length} / 300</span>
              </div>
            </div>
          </div>
          <div className="excel_attach_elem">
            <div className="excel_elem_title">파일 첨부</div>
            <div className="img_add_div">
              <div className="img_add">
                <input
                  type="file"
                  id="fileUpload"
                  // accept="image/*"
                  onChange={(e) => {
                    if (fList?.length >= 5) {
                      return false;
                    } else {
                      handleFileUpload(e.target.files[0]);
                    }
                  }}
                />
                <p></p>
                <label
                  className={fList?.length >= 5 && "no_upload"}
                  htmlFor={fList?.length < 5 ? "fileUpload" : "noUpload"}
                >
                  찾아보기
                </label>
              </div>
              <div className="add_text_alert_container">
                <span className="add_alert_desc">
                  * 10MB 이하의 이미지 파일을 첨부해 주세요.
                </span>
                <span className="add_alert_typing">
                  {fList?.length || "0"} / 5
                </span>
              </div>
            </div>
          </div>
          <div className="img_added_list">
            {fList?.map((elem, idx) => {
              return (
                <div className="img_added_elem">
                  <div className="img_added_inner">
                    <span
                      className="img_added_name"
                      title={elem?.fileName}
                      onClick={() => {
                        window.open(
                          elem.filePath,
                          "_blank",
                          "noopener,noreferrer"
                        );

                        // alert(elem.filePath);
                      }}
                    >
                      {elem?.fileName}
                    </span>
                    <span className="img_added_size">{elem?.fileSize}</span>
                  </div>
                  <div
                    className="img_added_close_box"
                    onClick={() => {
                      handleDelete(elem.fileIndex);
                    }}
                  >
                    <img src={x_icon} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <section
          className={classNames("bid_bottom_button_section", {
            desktop_fixed: isDesktop && isFixed,
            mobile_fixed: isMobile && isFixed,
          })}
        >
          <div className="space_between_btn_group">
            <Button size="medium" type="secondary" onClick={prevStepFnc}>
              <Typography component="p" type="c1">
                이전으로
              </Typography>
            </Button>
            <Button size="medium" type="primary" onClick={validateExcelFnc}>
              <Typography component="p" type="c1" color="var(--white)">
                입력 완료
              </Typography>
            </Button>
          </div>
        </section>
      </div>
      {excelPop ? <ExcelDownloadPop setExcelPop={setExcelPop} /> : null}
      {materialTablePop && (
        <MaterialTablePop setMaterialTablePop={setMaterialTablePop} />
      )}
      {generating1 && <Loading msg={"샘플 매핑중입니다."} />}
      {choicePop && (
        <ProductChoicePop
          setData={setData}
          setChoicePop={setChoicePop}
          notifySuccess={notifySuccess}
        />
      )}
      {/* {orderCompanyPop && (
        <OrderCompanySelectPop
          setOrderCompanyPop={setOrderCompanyPop}
          comment={comment}
          uploadFile={uploadFile}
        />
      )} */}
      <ToastContainer
        className="toastify"
        position="top-center"
        autoClose={3000}
        pauseOnHover
        theme="light"
      />

      {generating && <Loading msg={"이미지 업로드 중입니다."} />}
    </div>
  );
}
