const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";

  const digits = phoneNumber.replace(/\D/g, "");

  switch (digits.length) {
    case 10:
      return digits.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    case 11:
      return digits.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    default:
      return digits;
  }
};

export default formatPhoneNumber;
