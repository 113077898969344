import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constant/QueryKeys";
import { searchSiteApi } from "../../api/auth";

const useSearchSite = (isEntered, keyword, enabled = true) => {
  return useQuery({
    queryKey: [QUERY_KEYS.SEARCH_SITE],
    queryFn: () => searchSiteApi(isEntered, keyword),
    enabled: enabled,
  });
};

export default useSearchSite;
