//chat
import * as chatAction from "./chatActions";

//token
import * as tokenAction from "./tokenActions";

//user
import * as userAction from "./userActions";

//push
import * as pushAction from "./pushActions";

//estimate
import * as excelAction from "./excelActions";

//estimate
import * as attachExcelAction from "./attachExcelActions";

//activity
import * as activityAction from "./activityActions";

export const actions = Object.assign(
  {},
  chatAction,
  tokenAction,
  userAction,
  pushAction,
  excelAction,
  attachExcelAction,
  activityAction
);
