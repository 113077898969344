import { React, useState, useEffect } from 'react';
//library
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
//component
import Mheader from '../../../component/common/Mheader';
import ServiceCancelPop from '../../../component/common/Pop/ServiceCancelPop';
//css
import '../../../asset/css/history.css';
//etc
import { payHistortApi, findUserRoleApi } from '../../../api/auth';
import { numberWithCommas } from '../../../util/Util';
import { useSelector } from 'react-redux';

//sweet alert
import swal from 'sweetalert';

export default function PaidService() {
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isPay, setIsPay] = useState(true);
  const [roleData, setRoleData] = useState('');
  const [payInfo, setPayInfo] = useState({});
  const isResponsive = useMediaQuery({
    query: '(max-width : 1080px)',
  });
  const [payList, setPayList] = useState([]);

  //유료 서비스 이용 취소 팝업
  const [serviceCancel, setServiceCancel] = useState(false);

  //role이 user면 인근업체 찾기 불가능, silver나 gold 일떼만 가능
  const roleCheckFnc = async () => {
    const response = await findUserRoleApi();
    if (response.status === 'success') {
      setPayInfo(response.data);
      /*         if (response.data.r_auth === "ROLE_GOLD" || response.data.r_auth === "ROLE_SILVER") {
            setIsPayment(true);
        } */
    } else {
      swal('유저 정보를 불러오는데 실패했습니다.','','error');
    }
  };

  const payHistoryFnc = async () => {
    const siteNum = userData.u_no;
    const response = await payHistortApi(siteNum);
    if (response.status === 'success') {
      setPayList(response.data.hList);
      setRoleData(response.data.r_auth);
    } else if (response.status === 'fail') {
    }
  };

  const payTypeFnc2 = (data) => {
    if (data === 'GOLD') {
      return '골드 등급';
    } else if (data === 'PLATINUM') {
      return '플래티넘 등급';
    }
  };

  const payTypeFnc = (data) => {
    if (data === 'ROLE_GOLD') {
      return '골드 등급';
    } else if (data === 'ROLE_PLATINUM') {
      return '플래티넘 등급';
    } else if (data === 'ROLE_USER') {
      setIsPay(false);
      return '일반 등급';
    }
  };

  useEffect(() => {
    roleCheckFnc();
    payHistoryFnc();
  }, []);

  return (
    <div className='history_wrap'>
      <Mheader title='유료 서비스 이용내역' />
      <div className='history_container'>
        <div className='history_pagenation'>
          <h2>유료 서비스 이용내역</h2>
          {isPay ? (
            isResponsive ? (
              <div className='m_history_now'>
                <div className='history_now_left'>
                  <p>
                    현재 오픈 이벤트 진행중으로, <span>한시적 무료</span>로
                    제공됩니다.
                  </p>
                  <p>* 추후 무료 이용 종료시 결제 후 이용 가능합니다.</p>
                </div>
                {/* <p onClick={() => setServiceCancel(true)}>이용취소</p> */}
              </div>
            ) : (
              <div className='history_now'>
                <p>
                  현재 오픈 이벤트 진행중으로, <span>한시적 무료</span>로
                  제공됩니다.
                </p>
                <p>* 추후 무료 이용 종료시 결제 후 이용 가능합니다.</p>
                <div className='history_now_right'>
                  {/* <p></p>
                    <p onClick={() => setServiceCancel(true)}>이용취소</p> */}
                </div>
              </div>
            )
          ) : (
            <p className='not_pay'>
              현재 회원님은 이용중인 유료 서비스가 없습니다.
            </p>
          )}
        </div>

        {isPay ? (
          isResponsive ? (
            payList.map((data, i) => {
              return (
                <div className='m_payment_detail'>
                  <h3>
                    {payTypeFnc2(data.pay_type)} /{' '}
                    {numberWithCommas(data.pay_amount) + '원'}
                  </h3>
                  <p>결제금액 : {numberWithCommas(data.pay_amount)}</p>
                  <p>결제일 : {data.pay_regdate}</p>
                  <p>결제수단 : {data.pay_root}</p>
                </div>
              );
            })
          ) : (
            <table>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}></th>
                  <th style={{ width: '55%' }}>상품정보</th>
                  <th style={{ width: '10%' }}>결제금액</th>
                  <th style={{ width: '10%' }}>결제일</th>
                  <th style={{ width: '20%' }}>결제수단</th>
                </tr>
              </thead>
              <tbody>
                {payList.map((data, i) => {
                  return (
                    <tr key={data.pay_regdate}>
                      <td style={{ width: '5%' }}>{i + 1}</td>
                      <td style={{ width: '55%' }}>
                        {payTypeFnc2(data.pay_type)} /{' '}
                        {numberWithCommas(data.pay_amount) + '원'}
                      </td>
                      <td style={{ width: '10%' }}>
                        {numberWithCommas(data.pay_amount)}
                      </td>
                      <td style={{ width: '10%', padding: '0' }}>
                        {data.pay_regdate}
                      </td>
                      <td style={{ width: '20%' }}>{data.pay_root}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )
        ) : (
          <div className='not_pay_wrap'>
            <p className='not_pay_state'>이용내역이 없습니다.</p>
            <p
              className='lets_pay'
              onClick={() => {
                navigate( '/look/payment',{
                  state: { 
                    payInfo: payInfo
                  }
                })
              }}
            >
              서비스 결제하기
            </p>
          </div>
        )}
      </div>
      {serviceCancel && (
        <ServiceCancelPop setServiceCancel={setServiceCancel} />
      )}
    </div>
  );
}
