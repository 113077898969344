const { kakao } = window;

const getCoordinates = (address, callback) => {
  const geocoder = new kakao.maps.services.Geocoder();

  geocoder.addressSearch(address, (result, status) => {
    if (status === kakao.maps.services.Status.OK) {
      const { x: lng, y: lat } = result[0];
      callback(lat, lng);
    } else {
      console.error("주소 변환 실패", status);
    }
  });
};

export default getCoordinates;
