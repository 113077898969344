import { Controller } from "react-hook-form";
import { Radio as AntdRadio } from "antd";
import Radio from "../../../../../../component/ui/radio/Radio";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./userTypeRadio.module.css";

const { Group } = AntdRadio;

const UserTypeRadio = ({ control }) => {
  return (
    <div className={s.container}>
      <label htmlFor="user_type">
        <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
          회원종류{" "}
        </Typography>
        <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
          *
        </Typography>
      </label>

      <Controller
        name="user_type"
        control={control}
        defaultValue="CUSTOMER"
        render={({ field }) => (
          <Group {...field}>
            <div className={s.radio_container}>
              <Radio value="CUSTOMER">
                <div className={s.typo_container}>
                  <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                    건설사
                  </Typography>
                  <Typography component="p" type="c3" color={COLOR.GRAY.L07}>
                    공새로의 서비스를 모두 이용하는 고객
                  </Typography>
                </div>
              </Radio>
              <Radio value="SUBCONTRACTOR">
                <div className={s.typo_container}>
                  <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                    협력사
                  </Typography>
                  <Typography component="p" type="c3" color={COLOR.GRAY.L07}>
                    건설사의 협력사로 공새로 PB상품의 주문만을 이용하는 고객
                  </Typography>
                </div>
              </Radio>
            </div>
          </Group>
        )}
      />
    </div>
  );
};

export default UserTypeRadio;
