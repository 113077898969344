import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";

const PhoneCheckButton = ({
  errors,
  phone,
  handleCheckPhone,
  phoneBtnDisable,
}) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Button
      type="primary"
      size="small"
      style={{ marginTop: isDesktop ? "22px" : "6.6rem" }}
      disabled={!phone || errors.phone || phoneBtnDisable}
      onClick={handleCheckPhone}
    >
      인증 요청
    </Button>
  );
};

export default PhoneCheckButton;
