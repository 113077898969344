import Button from "../../../../../../component/ui/button/Button";

const ApproveButton = ({ onClickApproveButton }) => {
  return (
    <Button type="secondary" size="small" onClick={onClickApproveButton}>
      승인
    </Button>
  );
};

export default ApproveButton;
