import React, { useState, useEffect } from "react";
//library
import { useLocation, useNavigate } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/requestComplete.css";
import swal from "sweetalert";

// import usePreventBack from "../main/customHooks/usePreventBack";
// import usePreventBack from "../main/customHooks/usePreventBack";
import usePreventBack from "../main/customHooks/usePreventBack";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";
import Button from "../../component/ui/button/Button";
import Typography from "../../component/ui/typography/Typography";
import { useSelector } from "react-redux";

export default function RequestComplete() {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.user);
  const type = location.state.type;
  const no = location.state.no;
  const isRebid = location.state.isRebid;
  //뒤로가기 방지
  usePreventBack();

  return (
    <div className="request_complete_wrap">
      <Mheader title="주문/입찰 완료" />
      <div
        className="request_complete_container"
        style={{ paddingTop: "40px" }}
      >
        <div className="top_box">
          <div className="title">
            {isRebid ? "재공고 완료" : "주문/입찰 완료"}
          </div>
          <OrderBreadCrumb
            step1={
              type === "단가계약기반주문"
                ? "계약서 선택"
                : type === "PB상품주문"
                ? "PB상품선택"
                : "내역 작성"
            }
            step2="상세정보 입력"
            step3="상세정보 확인"
            step4={
              isRebid
                ? "재공고 완료"
                : type === "PB상품주문" || type === "단가계약기반주문"
                ? "주문완료"
                : "주문/입찰 완료"
            }
            isActive={4}
          />
        </div>
        {type === "업체지정주문" && (
          <div className="request_complete_text">
            <h2>업체지정 주문 요청이 완료되었습니다.</h2>
            <p>
              <span> 업체지정 주문은 투찰이 진행되지 않습니다. </span>
              <br />
              <span>지정하신 공급사에 주문요청이 완료되었습니다.</span>
            </p>
          </div>
        )}
        {type === "단가계약기반주문" && (
          <div className="request_complete_text">
            <h2>단가 계약기반 주문 요청이 완료되었습니다.</h2>
            <p>
              <span> 단가 계약기반 주문은 투찰이 진행되지 않습니다. </span>
              <br />
              <span>계약된 공급사에 주문요청이 완료되었습니다.</span>
            </p>
          </div>
        )}
        {type === "PB상품주문" && (
          <div className="request_complete_text">
            <h2>PB상품 주문 요청이 완료되었습니다.</h2>
            <p>
              <span> PB상품 주문은 투찰이 진행되지 않습니다. </span>
              <br />
              <span>(주)공새로에 주문요청이 완료되었습니다.</span>
            </p>
          </div>
        )}
        {type === "일회성입찰공고" && (
          <div className="request_complete_text">
            <h2>일회성 입찰 공고 게시가 완료되었습니다.</h2>
            <p>
              <span>
                입찰 공고 게시에 따라 질의응답 메뉴에 새로운 단체 채팅방이
                생성됩니다.
                <br />
                공급사가 투찰 시 자동으로 채팅방에 초대되며 <br />
                입찰 공고에 대하여 공급사와 자유롭게 질의응답을 진행할 수
                있습니다.
              </span>
            </p>
          </div>
        )}
        {type === "일회성입찰주문" && (
          <div className="request_complete_text">
            <h2>일회성 입찰 주문 요청이 완료되었습니다.</h2>
            <p>
              <span>
                일회성 입찰 주문은 투찰이 진행되지 않습니다.
                <br />
                계약된 공급사에 주문요청이 완료되었습니다.
              </span>
            </p>
          </div>
        )}
        {type === "단가계약입찰공고" && (
          <div className="request_complete_text">
            <h2>단가 계약 입찰 공고 게시가 완료되었습니다.</h2>
            <p>
              <span>
                입찰 공고 게시에 따라
                <br />
                질의응답 메뉴에 새로운 단체 채팅방이 생성됩니다.
                <br />
                공급사가 투찰 시 자동으로 채팅방에 초대되며 <br />
                입찰 공고에 대하여 공급사와 자유롭게
                <br />
                질의응답을 진행할 수 있습니다.
              </span>
            </p>
          </div>
        )}
        <div className="btn_center_box">
          {(type === "업체지정주문" ||
            type === "단가계약기반주문" ||
            type === "PB상품주문" ||
            type === "일회성입찰주문") && (
            <Button
              size="medium"
              type="primary"
              onClick={() => {
                if (userData?.constructionType === "SUBCONTRACTOR") {
                  // 상세
                  navigate("/management/pb/order/document/" + no);
                } else {
                  // 상세
                  navigate("/management/order/document/" + no);
                }
              }}
            >
              <Typography component="p" type="c1" color="var(--white)">
                주문 정보 보기
              </Typography>
            </Button>
          )}

          {(type === "일회성입찰공고" || type === "단가계약입찰공고") && (
            <Button
              size="medium"
              type="primary"
              onClick={() => {
                // 상세
                navigate("/management/estimate/document/" + no);
              }}
            >
              <Typography component="p" type="c1" color="var(--white)">
                입찰 공고 보기
              </Typography>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
