import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// css
import "../../asset/css/find.css";

import eyeIcon from "../../asset/images/eye-icon.png";
import eyeCrossedIcon from "../../asset/images/eye-crossed-icon.png";
import { changePwApi } from "../../api/auth";
import swal from "sweetalert";

export default function FindPw() {
  const navigate = useNavigate();
  const location = useLocation();
  const userNo = location?.state?.userNo;

  //비밀번호 영문+숫자+특수문자 8자리 이상
  const patternPwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;
  const [pw, setPw] = useState("");
  const [pwRight, setPwRight] = useState("");
  const pwdResult = patternPwd.test(pw);
  const [pwdStatus, setPwdStatus] = useState(true);

  //유저 비밀번호 형식 구분
  const userPwdValidation = () => {
    if (!pwdResult) {
      setPwdStatus(false);
    } else {
      setPwdStatus(true);
    }
  };

  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const handlePwChange = (e) => {
    setPw(e.target.value);
  };

  const handlePwRightChange = (e) => {
    setPwRight(e.target.value);
  };

  const handleSuccessClick = () => {
    if (
      !pwdResult ||
      pw === "" ||
      pwRight === "" ||
      pw !== pwRight ||
      !pwdResult ||
      pw.length < 8 ||
      pw.length > 16
    ) {
      swal("비밀번호를 확인해주세요.", "", "error");
    } else if (userNo === undefined) {
      swal("올바르지 않은 접근입니다.", "", "error");
    } else {
      changePwFnc();
    }
  };

  const changePwFnc = async () => {
    const response = await changePwApi(userNo, pw);
    if (response.status === "success") {
      navigate("/find-pw2");
    } else if (response.status === "fail") {
      swal("비밀번호 변경에 실패했습니다.", "", "error");
    }
  };

  return (
    <div className="completed_wrap joincomplete_wrap">
      <div className="completed_container joincomplete_container">
        <div className="completed_info">
          <div className="join_top find_top">
            <h2>새 비밀번호 설정</h2>
            <div className="join_pagenation">
              <p>1</p>
              <p className="active">2</p>
              <p>3</p>
            </div>
          </div>

          <>
            <dt className={pwdStatus ? "info_title" : "info_title_false"}>
              새 비밀번호
            </dt>
            <dd className={pwdStatus ? "info_input" : "info_input_false "}>
              <div className="pwd_input_box">
                <input
                  type={visible ? "text" : "password"}
                  placeholder="새 비밀번호를 입력해주세요."
                  onChange={handlePwChange}
                  style={{ marginBottom: "0" }}
                  onBlur={userPwdValidation}
                />
                <img
                  onClick={() => {
                    setVisible(!visible);
                  }}
                  className="visible_icon"
                  src={!visible ? eyeIcon : eyeCrossedIcon}
                />
              </div>
              <dd className={pwdStatus ? "info_alert pwd_desc" : "info_alert"}>
                영문, 숫자, 특수문자를 혼합한 8~15자리의 비밀번호를
                입력해주세요.
              </dd>
            </dd>
            {/* {pwdStatus ? (
                ''
              ) : (
                <dd className='info_alert'>
                  영문, 숫자, 특수문자를 혼합한 8~15자리의 비밀번호를
                  입력해주세요.
                </dd>
              )} */}
            <dt className={pw !== pwRight ? "info_title_false" : "info_title"}>
              비밀번호 확인
            </dt>
            <dd className={pw !== pwRight ? "info_input_false" : "info_input"}>
              <div className="pwd_input_box">
                <input
                  type={visible2 ? "text" : "password"}
                  placeholder="비밀번호를 한번 더 입력해주세요."
                  onChange={handlePwRightChange}
                  style={{ marginBottom: "0" }}
                />
                <img
                  onClick={() => {
                    setVisible2(!visible2);
                  }}
                  className="visible_icon"
                  src={!visible2 ? eyeIcon : eyeCrossedIcon}
                />
              </div>
            </dd>
            {pw !== pwRight ? (
              <dd className="info_alert">비밀번호가 일치하지 않습니다.</dd>
            ) : (
              ""
            )}
          </>
        </div>
        <div className="btn_wrap find_btn_wrap">
          <div onClick={handleSuccessClick} style={{ width: "100%" }}>
            변경하기
          </div>
        </div>
      </div>
    </div>
  );
}
