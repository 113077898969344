import React, { useState, useEffect } from 'react';
//library

//etc
import { businessInfoApi } from '../../../api/auth';
import i_close from '../../../asset/images/w-cancel-icon.png';

import { useSelector } from 'react-redux';

export default function BusinessInfoPop({ setBizPop }) {
  const userData = useSelector((state) => state.user);
  const [comInfo, setComInfo] = useState([]);

  const businessFnc = async () => {
    const response = await businessInfoApi(userData.site_num);
    if (response.status === 'success') {
      setComInfo(response.data);
    }
  };

  //사업자 구분
  const businessType = (data) => {
    if (data === 'P') {
      return '개인';
    } else if (data === 'C') {
      return '법인';
    }
  };

  useEffect(() => {
    businessFnc();
  }, []);

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setBizPop(false)}>
        <div className='business_info_pop'>
          <div className='close' onClick={() => setBizPop(false)}>
            <img src={i_close} alt='' />
          </div>
          <h3 className='pop_tit'>사업자정보 보기</h3>
          <div className='business_info'>
            <div className='business_img'>
              <img src={comInfo.f_root} />
            </div>
          </div>
          <ul className='business_list'>
            <li>
              <p>건설사명</p>
              <span>{comInfo.con_name}</span>
            </li>
            <li>
              <p>사업자구분</p>
              <span>{businessType(comInfo.con_type)}</span>
            </li>
            <li>
              <p>사업자등록번호</p>
              <span>{comInfo.con_num}</span>
            </li>
            <li>
              <p>대표자명</p>
              <span>{comInfo.con_ceo}</span>
            </li>
            <li>
              <p>업종</p>
              <span>{comInfo.con_sec}</span>
            </li>
            <li>
              <p>업태</p>
              <span>{comInfo.con_bc}</span>
            </li>
            <li>
              <p>주소</p>
              <span>{comInfo.con_addr1 + ', ' + comInfo.con_addr2}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
