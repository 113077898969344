import Button from "../../../../../../component/ui/button/Button";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";

const AddProductPop = ({ navigate, addProductPop, setAddProductPop }) => {
  const moveToRequestExcelPage = () => {
    navigate("/management/bid/request_excel");
  };
  const onClickCancel = () => {
    setAddProductPop(false);
  };

  return (
    <Modal
      size="xsmall"
      type="success"
      open={addProductPop}
      title={
        <Typography component="p" type="h4" color="var(--sub-blue-05)">
          주문 품목 내역 담기 완료
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button type="primary" size="small" onClick={moveToRequestExcelPage}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        현재 작성 중인 내역에 품목을 추가하였습니다.
      </Typography>
      <Typography component="p" type="c2">
        내역 작성 화면으로 이동하시겠습니까?
      </Typography>
    </Modal>
  );
};

export default AddProductPop;
