import { useState } from "react";
import Button from "../../../component/ui/button/Button";
import s from "./modalTest.module.css";
import Modal from "../../../component/ui/modal/Modal";
import Typography from "../../../component/ui/typography/Typography";

export default function ModalTest() {
  const [xsOpen, setXsOpen] = useState(false);
  const [smOpen, setSmOpen] = useState(false);
  const [mdOpen, setMdOpen] = useState(false);
  const [lgOpen, setLgOpen] = useState(false);

  const [successOpen, setSuccessOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const openXsModal = () => setXsOpen(true);
  const openSmModal = () => setSmOpen(true);
  const openMdModal = () => setMdOpen(true);
  const openLgModal = () => setLgOpen(true);

  const openSuccessModal = () => setSuccessOpen(true);
  const openInfoModal = () => setInfoOpen(true);
  const openWarningModal = () => setWarningOpen(true);
  const openErrorModal = () => setErrorOpen(true);

  const closeXsModal = () => setXsOpen(false);
  const closeSmModal = () => setSmOpen(false);
  const closeMdModal = () => setMdOpen(false);
  const closeLgModal = () => setLgOpen(false);

  const closeSuccessModal = () => setSuccessOpen(false);
  const closeInfoModal = () => setInfoOpen(false);
  const closeWarningModal = () => setWarningOpen(false);
  const closeErrorModal = () => setErrorOpen(false);

  return (
    <div className={s.modal_group_container}>
      <Typography component="p" type="h1">
        MODAL SIZE TEST
      </Typography>
      <div>
        <Button onClick={openXsModal} size="medium">
          xs modal
        </Button>
        <Modal
          size="xsmall"
          open={xsOpen}
          title={
            <Typography component="p" type="h4">
              xs modal title
            </Typography>
          }
          closeIcon={false}
          onOk={closeXsModal}
          onCancel={closeXsModal}
          footer={[
            <Button type="danger" onClick={closeXsModal}>
              취소
            </Button>,
            <Button type="primary" onClick={closeXsModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c1">
            xs modal content
          </Typography>
        </Modal>

        <Button onClick={openSmModal} size="medium">
          sm modal
        </Button>
        <Modal
          size="small"
          open={smOpen}
          title={
            <Typography component="p" type="h4">
              sm modal title
            </Typography>
          }
          closeIcon={false}
          onOk={closeSmModal}
          onCancel={closeSmModal}
          footer={[
            <Button type="danger" onClick={closeSmModal}>
              취소
            </Button>,
            <Button type="primary" onClick={closeSmModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c1">
            sm modal content
          </Typography>
        </Modal>

        <Button onClick={openMdModal} size="medium">
          md modal
        </Button>
        <Modal
          size="medium"
          open={mdOpen}
          title={
            <Typography component="p" type="h4">
              md modal title
            </Typography>
          }
          closeIcon={false}
          onOk={closeMdModal}
          onCancel={closeMdModal}
          footer={[
            <Button type="danger" onClick={closeMdModal}>
              취소
            </Button>,
            <Button type="primary" onClick={closeMdModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c1">
            md modal content
          </Typography>
        </Modal>

        <Button onClick={openLgModal} size="medium">
          lg modal
        </Button>
        <Modal
          size="large"
          open={lgOpen}
          title={
            <Typography component="p" type="h4">
              lg modal title
            </Typography>
          }
          closeIcon={false}
          onOk={closeLgModal}
          onCancel={closeLgModal}
          footer={[
            <Button type="danger" onClick={closeLgModal}>
              취소
            </Button>,
            <Button type="primary" onClick={closeLgModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c1">
            lg modal content
          </Typography>
        </Modal>
      </div>

      <Typography component="p" type="h1">
        MODAL TYPE TEST
      </Typography>
      <div>
        <Button onClick={openSuccessModal} size="medium">
          success modal
        </Button>
        <Modal
          size="xsmall"
          type="success"
          open={successOpen}
          title={
            <Typography component="p" type="h4" color="var(--sub-blue-05)">
              success modal title
            </Typography>
          }
          closeIcon={false}
          onOk={closeSuccessModal}
          onCancel={closeSuccessModal}
          footer={[
            <Button type="danger" onClick={closeSuccessModal}>
              취소
            </Button>,
            <Button type="primary" onClick={closeSuccessModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c1">
            success modal content
          </Typography>
        </Modal>

        <Button onClick={openInfoModal} size="medium">
          info modal
        </Button>
        <Modal
          size="xsmall"
          type="info"
          open={infoOpen}
          title={
            <Typography component="p" type="h4" color="var(--sub-green-10)">
              info modal title
            </Typography>
          }
          closeIcon={false}
          onOk={closeInfoModal}
          onCancel={closeInfoModal}
          footer={[
            <Button type="danger" onClick={closeInfoModal}>
              취소
            </Button>,
            <Button type="primary" onClick={closeInfoModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c1">
            info modal content
          </Typography>
        </Modal>

        <Button onClick={openWarningModal} size="medium">
          warning modal
        </Button>
        <Modal
          size="xsmall"
          type="warning"
          open={warningOpen}
          title={
            <Typography component="p" type="h4" color="var(--sub-yellow-10)">
              warning modal title
            </Typography>
          }
          closeIcon={false}
          onOk={closeWarningModal}
          onCancel={closeWarningModal}
          footer={[
            <Button type="danger" onClick={closeWarningModal}>
              취소
            </Button>,
            <Button type="primary" onClick={closeWarningModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c1">
            warning modal content
          </Typography>
        </Modal>

        <Button onClick={openErrorModal} size="medium">
          error modal
        </Button>
        <Modal
          size="xsmall"
          type="error"
          open={errorOpen}
          title={
            <Typography component="p" type="h4" color="var(--sub-red-10)">
              error modal title
            </Typography>
          }
          closeIcon={false}
          onOk={closeErrorModal}
          onCancel={closeErrorModal}
          footer={[
            <Button type="danger" onClick={closeErrorModal}>
              취소
            </Button>,
            <Button type="primary" onClick={closeErrorModal}>
              확인
            </Button>,
          ]}
        >
          <Typography component="p" type="c1">
            error modal content
          </Typography>
        </Modal>
      </div>
    </div>
  );
}
