import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import i_close from '../../../asset/images/w-cancel-icon.png';
import '../../../asset/css/pop.css';

import { changeContractAgreeApi, contractDetailApi } from '../../../api/auth';
import { numberWithCommas } from '../../../util/Util';
import swal from 'sweetalert';
import Loading from '../../etc/Loading';
import ContractChangeTermsPop from '../Pop/ContractChangeTermsPop';

export default function ServiceCancelPop({ setContractReasonPop, currentList, move2Success, isPause }) {
  // 기타 입력란
  const [reasonInput, setReasonInput] = useState("");

  //loading
  const [generating, setGenerating] = useState(false);

  //계약서 미리보기
  // const [conInfo2, setConInfo2] = useState();
  const [pList2, setPlist2] = useState(currentList.productList);
  

  const [contractChangePop, setContractChangePop] = useState(false);
  const [contractChangeNo, setContractChangeNo]=useState('');
  const [contractChangePath, setContractChangePath]=useState('');

  //계약 세부내용
  //
  // const contractDetailFnc = async () => {
  //   setGenerating(true);
  //   const response = await contractDetailApi(
  //     conValue.ct_no,
  //     conValue.ct_parent,
  //     conValue.ct_count,
  //   );
  //   
  //   if (response.status === 'success') {
  //     setConInfo2(response.data.bInfo);
  //     setPlist2(response.data.pList);
  //     // setCountList(response.data.countList);
  //     // setSiteInfo(response.data.bInfo?.s_u_info);
  //     // setComInfo(response.data.bInfo?.c_u_info);
  //   }
  //   setGenerating(false);
  // };

  const changeContractAgreeFnc = async() =>{

    if(reasonInput === ''){
      swal('변경 사유를 입력해주세요','','warning');
    }else{
      setGenerating(true);

      const response = await changeContractAgreeApi(currentList.ct_no,reasonInput);
      

      if(response.status === 'success'){
        setContractChangeNo(response.data.ct_no);
        setContractChangePath(response.data.file_path);
        setContractChangePop(true);
      }else{
        swal('변경계약에 실패했습니다.','다시 시도해주세요.','error')
      }
      setGenerating(false);
    }

  }

  // useEffect(()=>{
  //   contractDetailFnc();
  // },[]);


  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setContractReasonPop(false)}></div>
      <div className="contract_pop">
        <div className="close" onClick={() => setContractReasonPop(false)}>
          <img src={i_close} alt="" />
        </div>
        {isPause !== 'PAUSE' ?
          <div className="contract_pop_tit">
            <h3>계약 변경에 동의하시겠습니까?</h3>
            <p>변경된 품목과 계약일시가 표기됩니다.</p>
          </div>
          :
          <h1 className='contract_pop_tit2'>계약내용</h1>
        }



        <div className="compare_table">

          {/* <h3>아래 내용은 변경된계약내용입니다.</h3> */}
          <div className="compare_materials">
              <table>
              <tbody>
              <tr>
                  <th>
                    <p>품목명</p>
                  </th>
                  <th>
                    <p>단가 / 수량</p>
                  </th>
                  <th>
                    <p>금액</p>
                  </th>
                </tr>

                {pList2.map((a,i)=>{
                  
                  if(a.od_count !== "0" && a.od_price !== "0"){
                    return(
                      <tr>
                        <td>
                          <p>{a.bp_name}</p>
                        </td>
                        <td>
                          <p>
                            {numberWithCommas(a.od_price)}원 &nbsp;/&nbsp;&nbsp;
                            {a.od_count}(개)
                          </p>
                        </td>
                        <td>
                          <p>{numberWithCommas(a.od_count * a.od_price)}원</p>
                        </td>
                      </tr>
                    )
                  }
                })}
                
                <tr>
                  <td>합계</td>
                  <td colSpan='2'>
                  <strong>
                      {numberWithCommas(
                        pList2
                          .map((data) => data.od_count * data.od_price)
                          .reduce((sum, current) => sum + current, 0)
                      )}원
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>


              <div class="compare_date">
                <div class="compare_date_tit">
                  <p>계약일시</p>
                </div>
                <div class="compare_date_content">
                  <p>
                    {currentList.ct_sdate}&nbsp;~&nbsp;
                    {currentList.ct_edate}
                  </p>
                </div>
              </div>
          </div>



{/* take line */}
        {isPause !== 'PAUSE' && 
        <>
          <div className="line"></div>

          <input placeholder="계약 변경 사유를 입력해주세요" onChange={(e)=>{
            setReasonInput(e.target.value);
          }}/>
          <div className="confirm_btn"  onClick={changeContractAgreeFnc}>
            동의
          </div>
        </>
      }
      </div>
      {generating && <Loading/>}
      {contractChangePop && 
      <ContractChangeTermsPop
          setContractChangePop = {setContractChangePop}
          setContractReasonPop = {setContractReasonPop}
          move2Success={move2Success}
          ctNo={contractChangeNo}
          filePath={contractChangePath}
      />}
    </div>
  );
}
