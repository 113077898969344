import Button from "../../../../../../component/ui/button/Button";

const CancelButton = ({ navigate }) => {
  const moveToBackPage = () => {
    navigate(-1);
  };
  return (
    <Button type="secondary" size="medium" onClick={moveToBackPage}>
      취소
    </Button>
  );
};

export default CancelButton;
