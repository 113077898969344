import React, { useState } from 'react';
import i_close from '../../../asset/images/w-cancel-icon.png';
import i_download from '../../../asset/images/download_icon.png';

import { changeContractApi} from '../../../api/auth';
import Loading from '../../etc/Loading';
import { useMediaQuery } from 'react-responsive';

import { isMobile, isIOS } from 'react-device-detect';

//sweet alert
import swal from 'sweetalert';

export default function ContractTermsPop({
  setContractChangePop,
  ctNo,
  filePath,
  move2Success,
  setContractReasonPop
}) {
  const [generating, setGenerating] = useState(false);
  const PageName = '변경 계약 약관';
  const isResponsive = useMediaQuery({
    query: '(max-width : 1080px)',
  });

  //계약 체결하기
  const contractClearApi = async () => {
    setGenerating(true);

    const response = await changeContractApi(ctNo, filePath);

    if(response.status === 'success'){
      swal('변경 계약이 완료되었습니다.','','success');
      setContractChangePop(false);
      setContractReasonPop(false);
      move2Success();
    } else{
      swal('변경 계약에 실패했습니다.','다시 시도해주세요.','error');
    }
    
    setGenerating(false);
  };

  const downloadPdf = async () => {
    const link = document.createElement('a');
    link.href = filePath;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className='pop'>
        <div className='pop_dim' onClick={() => setContractChangePop(false)}></div>
        <div className='contract_terms_pop'>
          <div className='close' onClick={() => setContractChangePop(false)}>
            <img src={i_close} alt='' />
          </div>
          <h3 className='pop_tit'>{PageName}</h3>
          <div>
            {!isMobile?
            <div>
            <iframe
              style={{ width: '100%', height: '500px' }}
              src={filePath}
            ></iframe>
            <div className="pop_download_container">
              <span>보안 프로그램의 문제로 인해 미리보기가 표시되지 않는 경우, 다운로드하여 {PageName}를 확인해주세요.</span>
              <div className="pop_download_box" onClick={()=>{downloadPdf()}}>
                  <span>다운로드</span>
                  <img src={i_download} alt=""/>
              </div>
            </div>
            {/* <span className="contract_terms_info">
                모바일 환경에선 미리보기가 불가합니다.<br/>
                다운로드 받은 계약서를 보고 계약해주세요!
              </span> */}
            </div>
            :
            isMobile && isIOS ? //아이폰 웹에서 계약서보기로 띄워줌
            <div>
              <span className="contract_terms_info">
                모바일 환경에선 미리보기가 불가합니다.<br/>
                아래 계약서를 확인한 후 동의를 눌러주세요.
              </span>
              <a className="go_contract_terms" 
              href={filePath}
              >계약서 보기</a>
            </div>
            :
            <div>
              <iframe
              style={{ width: '100%', height: '200px' }}
              src={filePath}
              ></iframe>
              <div className="pop_download_container">
              <span>보안 프로그램의 문제로 인해 미리보기가 표시되지 않는 경우, 다운로드하여 {PageName}를 확인해주세요.</span>
              <div className="pop_download_box" onClick={()=>{downloadPdf()}}>
                  <span>다운로드</span>
                  <img src={i_download} alt=""/>
              </div>
            </div>
              <span className="contract_terms_info">
                모바일 환경에선 미리보기가 불가합니다.<br/>
                다운로드 받은 계약서를 보고 계약해주세요!
              </span>
            </div>
            }
          </div>
          <div className='terms_pop_btn btn_box'>
            <div className='cancel'>
              <p onClick={() => setContractChangePop(false)}>취소</p>
            </div>
            <div className='agree'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  contractClearApi();
                }}
              >
                동의
              </a>
            </div>
          </div>
        </div>
      </div>

      {generating && <Loading msg={'계약 날인중입니다.'} />}
    </>
  );
}
