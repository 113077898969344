import useAttendSiteHandler from "../../../../../../hooks/handler/useAttendSiteHandler";
import classNames from "classnames/bind";

import Modal from "../../../../../../component/ui/modal/Modal";
import Button from "../../../../../../component/ui/button/Button";
import Typography from "../../../../../../component/ui/typography/Typography";
import SiteSearchInput from "./components/elements/input/SiteSearchInput";
import Spin from "../../../../../../component/ui/spin/Spin";
import Chip from "../../../../../../component/ui/chip/Chip";
import SiteAttendSuccessModal from "../site-attend-success/ExistSiteAttendSuccessModal";

import { COLOR } from "../../../../../../constant/Palette";
import s from "./siteAttendModal.module.css";
import CirclePlusSVG from "../../../../../../asset/images/svg/CirclePlusSVG";

const SiteAttendModal = ({ siteAttendModal, setSiteAttendModal }) => {
  const {
    keyword,
    setKeyword,
    searchKeyword,
    selectedSite,
    setSelectedSite,
    isLoading,
    siteList,
    handleAttendSite,
    handleClickSite,
    handleSearch,
    moveToCreateSitePage,
    siteAttendSuccessModal,
    setSiteAttendSuccessModal,
  } = useAttendSiteHandler();

  const onClickCancel = () => {
    setSelectedSite(null);
    setSiteAttendModal(false);
  };

  const onClickOk = () => {
    if (selectedSite.name === "신규 현장 등록") {
      moveToCreateSitePage();
      return;
    }

    handleAttendSite();
  };

  return (
    <Modal
      size="xsmall"
      open={siteAttendModal}
      title={
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          현장 참여
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button
          type="primary"
          size="small"
          onClick={onClickOk}
          disabled={!selectedSite}
        >
          확인
        </Button>,
      ]}
    >
      <SiteSearchInput
        keyword={keyword}
        setKeyword={setKeyword}
        handleSearch={handleSearch}
        setSelectedSite={setSelectedSite}
      />

      <div className={s.site_container}>
        {isLoading && <Spin />}
        {siteList?.length !== 1 &&
          siteList?.map(({ site, isUserPending }) => (
            <div
              className={classNames(s.site, {
                [s.selected]: selectedSite?.no === site.no,
                [s.disabled]: site.state === "PENDING" || isUserPending,
              })}
              key={site.no}
              onClick={() => handleClickSite(site)}
              title={site.name}
            >
              <div className={s.typo_container}>
                {site.no !== "registration" && (
                  <>
                    <Typography
                      component="p"
                      type={selectedSite?.no === site.no ? "h5" : "c1"}
                      color={COLOR.GRAY.L09}
                    >
                      {site.name}
                    </Typography>
                    <Typography
                      component="p"
                      type={selectedSite?.no === site.no ? "c3" : "c4"}
                      color={COLOR.GRAY.L05}
                    >
                      {site.addr1}, {site.addr2}
                    </Typography>
                  </>
                )}
                {site.no === "registration" && (
                  <div className={s.registration_container}>
                    <div className={s.svg_container}>
                      <CirclePlusSVG />
                    </div>
                    <Typography
                      component="p"
                      type={selectedSite?.no === site.no ? "h5" : "c1"}
                      color={COLOR.GRAY.L09}
                    >
                      {site.name}
                    </Typography>
                  </div>
                )}
              </div>
              {(site.state === "PENDING" || isUserPending) && (
                <div className={s.chip_container}>
                  <Chip size="medium" type="default" color="yellow">
                    승인대기
                  </Chip>
                </div>
              )}
            </div>
          ))}
        {siteList?.length === 1 && (
          <div className={s.empty_container}>
            <div>
              <Typography component="span" type="c1" color={COLOR.GRAY.L09}>
                <Typography
                  component="span"
                  type="c2"
                  color={COLOR.SUB.BLUE.L05}
                >
                  "{searchKeyword}"
                </Typography>
                가 존재하지 않습니다.
              </Typography>
              <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                해당 현장 사용자에게 정확한 현장명을 확인해주세요.
              </Typography>
            </div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
              아직 등록되지 않은 현장이라면, 신규 등록을 진행해주세요.
            </Typography>
            <Button type="primary" size="small" onClick={moveToCreateSitePage}>
              신규 현장 등록
            </Button>
          </div>
        )}
      </div>
      {siteAttendSuccessModal && (
        <SiteAttendSuccessModal
          siteAttendSuccessModal={siteAttendSuccessModal}
          setSiteAttendSuccessModal={setSiteAttendSuccessModal}
          setSiteAttendModal={setSiteAttendModal}
        />
      )}
    </Modal>
  );
};

export default SiteAttendModal;
