import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constant/QueryKeys";
import { checkCodeApi } from "../../api/auth";

const useCheckCode = (userType, phone, code) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CHECK_CODE],
    queryFn: () => checkCodeApi(userType, phone, code),
    enabled: false,
    retry: false,
  });
};

export default useCheckCode;
