import Typography from "../../../component/ui/typography/Typography";
import Select from "../../../component/ui/select/Select";
import s from "./selectTest.module.css";

const options = [
  { value: 1, label: "label 1" },
  { value: 2, label: "label 2" },
  { value: 3, label: "label 3" },
  { value: 4, label: "label 4" },
  { value: 5, label: "label 5" },
  { value: 6, label: "label 6" },
  { value: 7, label: "label 7" },
  { value: 8, label: "label 8" },
  { value: 9, label: "label 9" },
  { value: 10, label: "label 10" },
];

export default function SelectTest() {
  return (
    <div className={s.select_group_container}>
      <Typography component="p" type="h1">
        SELECT SIZE TEST
      </Typography>
      <div>
        <Select size="small" width={120} defaultValue={1} options={options} />
        <Select size="medium" width={140} defaultValue={2} options={options} />
        <Select size="large" width={160} defaultValue={3} options={options} />
      </div>
    </div>
  );
}
