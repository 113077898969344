import s from "../../asset/css/releaseNote.module.css";
import Mheader from "../../component/common/Mheader";
import { useEffect, useState } from "react";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import axios from "axios";
import { NotionRenderer } from "react-notion";

const NOTION_PAGE_ID = "9551758919284d9d9b36410ae67de3b0";

export default function ReleaseNote() {
  const [response, setResponse] = useState({});

  const fetchReleaseNote = async () => {
    try {
      const response = await axios.get(
        `https://notion-api.splitbee.io/v1/page/${NOTION_PAGE_ID}`
      );
      setResponse(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchReleaseNote();
  }, []);

  return (
    <div className={s.container}>
      <Mheader title="제품정보" />
      <h2 className={s.title}>제품정보</h2>
      <div className={s.contents}>
        <NotionRenderer blockMap={response} />
      </div>
    </div>
  );
}
