import React, { useState, useEffect } from "react";
//library
import { useLocation, useNavigate } from "react-router-dom";
//component
import Mheader from "../../../component/common/Mheader";
import QuickCancelPop from "../../../component/common/Pop/QuickCancelPop";
//etc
import {
  quickOrderDetailApi,
  orderCheckApi,
  generateCheckApi,
} from "../../../api/auth";
import { numberWithCommas } from "../../../util/Util";
import Loading from "../../../component/etc/Loading";
import GenerateCheckPop from "../../../component/common/Pop/generateCheckPop";

//sweet alert
import swal from "sweetalert";

export default function QuickOrderDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [quickPop, setQuickPop] = useState(false); // 주문서 발행 취소를 요청하시겠습니까? 팝업
  const [detailData, setDetailData] = useState({});
  const [pList, setpList] = useState([]);
  const [orderNumber, setOrderNumber] = useState(0);
  const [quickNumber, setQuickNumber] = useState(0);
  const [fileList, setFileList] = useState([]);
  const ascendData = location.state.estimateData;
  const [cNo, setCNo] = useState("");
  const [generatePop, setGeneratePop] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [orNo, setOrNo] = useState();
  const [filePath, setFilePath] = useState();

  const quickDetailFnc = async () => {
    const response = await quickOrderDetailApi(ascendData.or_no);
    if (response.status === "success") {
      setFileList(response.data.fileList);
      setDetailData(response.data.qInfo);
      setpList(response.data.pList);
    } else if (response.status === "fail") {
      swal("오류", "", "error");
    }
  };

  //검수완료
  const orderCheckFnc = async (data) => {
    const response = await orderCheckApi(data);
    if (response.status === "success") {
      swal("검수완료 상태로 변경되었습니다.", "", "success");
      window.location.reload();
    } else {
      swal("다시 시도해주세요.", "", "warning");
    }
  };

  const generateCheckFnc = async () => {
    setGenerating(true); //loading ui on
    const response = await generateCheckApi(ascendData.or_no);

    if (response.status === "success") {
      setOrNo(response.data.or_no);
      setFilePath(response.data.file_path);
      setGenerating(false); //case success : loading ui off
      setGeneratePop(true);
    }
  };

  const quickDrop = (data) => {
    if (data === "L") {
      return "하차역무 있음 ⭕️";
    } else if (data === "U") {
      return "하차역무 없음 ❌";
    }
  };

  const quickState = (data) => {
    if (data === "START") {
      return "주문접수";
    } else if (data === "CHECK") {
      return "주문확인";
    } else if (data === "ING") {
      return "배송중";
    } else if (data === "DONE") {
      return "배송완료";
    } else if (data === "TESTED") {
      return "검수완료";
    } else if (data === "CANCEL") {
      return "취소";
    } else if (data === "BEFORE") {
      return "정산대기";
    }
  };

  //금액
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (ascendData.or_no !== 0) {
      quickDetailFnc();
    }
  }, []);

  useEffect(() => {
    quickDetailFnc();
  }, []);

  //임시
  // const [isBlocking, setIsBlocking] = useState(false);
  // useEffect(() => {
  //   const unblock = history.block((loc, action) => {
  //     if (action === 'POP' && isBlocking) {
  //       return history.push('/management/quick_order');
  //     }
  //     return true;
  //   });
  //   return () => unblock();
  // }, [isBlocking]);
  // useEffect(() => {
  //   detailData && setIsBlocking(true);
  // }, [detailData]);

  return (
    <div className="quick_wrap order_wrap">
      <Mheader title="퀵주문 발행 내역" />
      <div className="order_ok_container wd">
        <div className="top_pagenation">
          <h2>주문서 발행 내역</h2>
          {/* 상태가 아닐경우 버튼 지우기 */}
          {/* 주문접수시 */}
          <ul className="top_btn_box top_btn2_box">
            {/* {detailData?.or_state === 'CHECK' && (
              <li>
                <p
                  onClick={() => {
                    setQuickPop(true);
                    setOrderNumber(ascendData.or_no);
                  }}
                >
                  취소
                </p>
              </li>
            )} */}

            {detailData?.or_state === "DONE" ? (
              <li>
                <p
                  onClick={() => {
                    // if (window.confirm('검수완료 상태로 변경하시겠습니까?')) {
                    //   orderCheckFnc(ascendData.or_no);
                    // }
                    generateCheckFnc();
                  }}
                >
                  검수 완료
                </p>
              </li>
            ) : null}
            {/* <li>
              <p onClick={() => setEdit(true)}>수정</p>
            </li> */}
          </ul>
          {/* 배송완료시 */}
          {/* <ul className="top_btn_box">
            <li>
              <p>검수확인</p>
            </li>
          </ul> */}
          {/* 검수완료시 */}
          {/* <ul className="top_btn_box">
            <li>
              <Link to="/review">리뷰 작성하기</Link>
            </li>
          </ul> */}
        </div>
        <div className="quick_info">
          <div className="contract_content">
            <div className="content_top">
              <span>퀵주문번호 :&nbsp;</span>
              <span className="public_code">{ascendData?.or_code}</span>
              {/*               <span className="num_line">|</span>
              <span>2021.07.20</span> */}
            </div>
            <div className="content_box">
              <div className="content_btm">
                <div className="content_box">
                  <p className="company_img">
                    <img src={ascendData.f_root} alt={ascendData.c_name} />
                  </p>
                  <div className="contract_info">
                    <div className="icon_box">
                      <p className={detailData?.or_state + " icon"}>
                        {quickState(detailData?.or_state)}
                      </p>
                    </div>
                    <p className="contractor">
                      <span>
                        {"[" + ascendData?.c_name + "]"}&nbsp;
                        {ascendData?.s_name}
                      </span>
                      <span>퀵주문</span>
                    </p>
                    <div className="contract_text">
                      <div>
                        <p>납품기한 : {ascendData?.or_edate}</p>
                        <p>
                          납품주소 :&nbsp;<span>{ascendData?.or_addr}</span>
                        </p>
                        {/* 취소 상태일 경우에만 나옴 */}
                        {/* <p>취소사유 :&nbsp;<span>납품 일자 변경</span></p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quote_content">
            <h3 className="s_tit">
              <p>주문서 상세</p>
            </h3>
            <div className="quote_list_box">
              <ul className="quote_list">
                <li>
                  <p>배송비 포함</p>
                  <span>포함</span>
                </li>
                <li>
                  <p>하차 역무</p>
                  <span>하차역무 있음 ⭕️</span>
                </li>
                <li>
                  <p>비용 정산 방법</p>
                  <span>{detailData?.q_refund}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="conclude_table">
            <table>
              <tbody>
                <tr>
                  <th>
                    <p>품목명</p>
                  </th>
                  <th>
                    <p>규격</p>
                  </th>
                  <th>
                    <p>단위</p>
                  </th>
                  <th>
                    <p>수량</p>
                  </th>
                  <th>
                    <p>단가</p>
                  </th>
                  <th>
                    <p>금액</p>
                  </th>
                </tr>
                {pList.map((data, i) => {
                  return (
                    <tr>
                      <td>{data.ol_p_name}</td>
                      <td>{data.ol_standard}</td>
                      <td>{data.ol_unit}</td>
                      <td>{data.ol_count}</td>
                      <td>{numberWithCommas(data.ol_price)}</td>
                      <td>{numberWithCommas(data.ol_price * data.ol_count)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan="5">합계</td>
                  <td>
                    <strong>
                      {numberWithCommas(
                        pList
                          .map((data) => data.ol_count * data.ol_price)
                          .reduce((sum, current) => sum + current, 0)
                      )}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="uniqueness_box">
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>
                  <span>퀵주문</span>특이사항
                </p>
              </div>
              <div className="uniqueness_content">
                <p>{detailData.or_uniqueness}</p>
              </div>
            </div>
            {/*             <div className="uniqueness">
              <div className="uniqueness_tit">
                <p><span>C상사</span>특이사항</p>
              </div>
              <div className="uniqueness_content">
                <p>주문서 발행시 10%할인 가능</p>
              </div>
            </div> */}
          </div>
          {fileList.length === 0 ? null : (
            <div className="look_info payment_account">
              <h3 className="s_tit">
                <p>첨부파일 정보</p>
              </h3>
              {fileList.map((a, i) => {
                return (
                  <div>
                    <p
                      onClick={() => {
                        window.open(fileList[i].f_root);
                      }}
                    >
                      첨부파일 {i + 1} 보기
                    </p>
                    <span>{fileList[i].f_origname}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {detailData?.or_state === "CHECK" ||
        detailData?.or_state === "START" ? (
          <p className="caution_txt">
            *고객사 및 공급사의 일방적인 계약중지에 따른 패널티가 발생할 수
            있습니다. 취소 시 상호간의 협의 후 진행해주세요.
            <span
              onClick={() => {
                setQuickPop(true);
                setOrderNumber(ascendData.or_no);
                setQuickNumber(ascendData.c_no);
              }}
              className="contract_pause_request"
            >
              퀵주문 취소
            </span>
          </p>
        ) : null}
      </div>
      {quickPop && (
        <QuickCancelPop
          setQuickPop={setQuickPop}
          orderNumber={orderNumber}
          quickNumber={quickNumber}
        />
      )}
      {generatePop && (
        // <ContractTermsPop
        //   ctNo={ctNo}
        //   filePath={filePath}
        //   setTermsPop={setTermsPop}
        //   contractData={contractData}
        //   move2Success={_move2Success}
        // />
        <GenerateCheckPop
          //여기에 담아서 팝업에 보내기
          setGeneratePop={setGeneratePop}
          filePath={filePath}
          orNo={orNo}
          // detailData={detailData}
          reload="quick"
          detailData={ascendData}
          detailData2={detailData}
          quick={quickDetailFnc}
        />
      )}
      {generating && <Loading msg={"검수확인서 생성중입니다."} />}
    </div>
  );
}
