import Button from "../../../../../../component/ui/button/Button";

const SignupButton = ({ navigate }) => {
  const moveToSignupPage = () => {
    navigate("/signup");
  };

  return (
    <Button size="large" type="secondary" onClick={moveToSignupPage}>
      회원가입
    </Button>
  );
};

export default SignupButton;
