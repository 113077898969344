import React, { useEffect, useRef, useState } from "react";
//library
import { useNavigate, useLocation, Outlet } from "react-router-dom";
// etc
import i_close from "../../../asset/images/w-cancel-icon.png";
// css
import s from "../../../asset/css/productChoicePop.module.css";
// api
import { productFindApi } from "../../../api/auth";
// redux
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/action";
// hook
import useScrollLockedBehindModal from "../../../hooks/useScrollLockedBehindModal";
import Pagination from "../../ui/pagination/Pagination";
import Loading from "../../etc/Loading";

const emptyColumn = [
  { value: "" },
  { value: "" },
  { value: "" },
  { value: "" },
  { value: "무관" },
  { value: "" },
];

export default function ProductChoicePop({
  setData,
  setChoicePop,
  notifySuccess,
}) {
  useScrollLockedBehindModal();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [itemList, setItemList] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [enteredKeyword, setEnteredKeyword] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);

  //품목 직접 선택 검색
  const searchProduct = async () => {
    setLoading(true);
    try {
      const searchData = {
        processNo: null,
        lcNo: null,
        mcNo: null,
        scNo: null,
        keyword: enteredKeyword,
        pageNum: pageNum,
        pageCount: 30,
      };
      const response = await productFindApi(searchData);
      setItemList(response.data);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const scrollRef = useRef(null);

  const enterFnc = (keyword) => {
    setPageNum(1);
    setEnteredKeyword(keyword);
  };

  useEffect(() => {
    searchProduct();
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pageNum, enteredKeyword]);

  const redux_excel = useSelector((state) => state.excel);

  const setDataFnc = async (product) => {
    // 컨버팅
    const pInfo = [
      { value: product.name },
      { value: product.standard },
      { value: product.unit },
      { value: 1 },
      { value: product.brand },
      { value: product.etc },
    ];

    function findMatchingIndex(emptyColumn, test) {
      for (let i = 0; i < test.length; i++) {
        const column = test[i];
        if (column.length === emptyColumn.length) {
          let isEqual = true;
          for (let j = 0; j < column.length; j++) {
            if (column[j].value !== emptyColumn[j].value) {
              isEqual = false;
              break;
            }
          }
          if (isEqual) {
            return i;
          }
        }
      }
      return -1; // 일치하는 배열을 찾지 못한 경우
    }

    const resultIndex = findMatchingIndex(emptyColumn, redux_excel);

    if (resultIndex !== -1) {
      const copy = redux_excel;
      copy[resultIndex] = [...pInfo];
      dispatch(actions.setExcel(copy));
      setData(copy);
      notifySuccess(product.name);
      setChoicePop(false);
    } else {
      setData([...redux_excel, pInfo]);
      dispatch(actions.setExcel([...redux_excel, pInfo]));
      notifySuccess(product.name);
      setChoicePop(false);
    }
  };

  const handlePageChange = (page) => {
    setPageNum(page);
  };

  return (
    <div className={s.background} onClick={() => setChoicePop(false)}>
      <div className={s.container} onClick={(e) => e.stopPropagation()}>
        <div ref={scrollRef} />

        <div className={s.header}>
          <div className={s.title}>품목 검색</div>
          <div className={s.close} onClick={() => setChoicePop(false)}>
            <img src={i_close} alt="close_icon" />
          </div>
        </div>
        <div className={s.contents}>
          <div className={s.input_box}>
            <input
              className={s.input}
              type="text"
              value={keyword}
              placeholder="품목명을 입력해주세요."
              spellCheck="false"
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  enterFnc(e.target.value);
                }
              }}
            />
            <div
              className={s.input_search}
              onClick={() => {
                enterFnc(keyword);
              }}
            >
              검색
            </div>
          </div>
          <div className={s.search_count_box}>
            <p>
              검색결과 <span> '{enteredKeyword || "전체"}' </span>{" "}
              <span>{totalCount ? totalCount?.toLocaleString("ko") : "0"}</span>
              건
            </p>
            <div className={s.search_alert_box}>
              <span>
                * 공급사의 재고 보유 상태에 따라 실제 제품과 사진이 상이할 수
                있습니다.
              </span>
              <span>
                * 정확한 투찰 및 납품을 위해 품목 선택 후, 규격 및 비고에 정확한
                내용을 작성 부탁드립니다.
              </span>
            </div>
          </div>
          {totalCount === 0 && <span>검색결과가 없습니다.</span>}
          <ul className={s.search_result_box}>
            {itemList?.productList?.map((elem, idx) => {
              return (
                <li
                  onClick={() => {
                    navigate(
                      `/management/bid/request_excel/detail/${elem.product.no}`,
                      {
                        state: {
                          background: location,
                        },
                      }
                    );
                  }}
                >
                  <div className={s.img_box}>
                    <img
                      src={elem.file}
                      alt={elem.product.name}
                      className={s.product_img}
                    />
                  </div>
                  <div className={s.desc_box}>
                    <p className={s.name1}>{elem.product.brand}</p>
                    <p className={s.name2}>{elem.product.name}</p>
                  </div>
                  <div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setDataFnc(elem.product);
                      }}
                      className={s.confirm_btn}
                    >
                      품목 담기
                    </div>
                  </div>
                </li>
              );
            })}
            <Outlet
              context={
                {
                  // setCartItem: setCartItem,
                  // cartItem: cartItem,
                }
              }
            />
          </ul>
          <div className={s.pagination_container}>
            <Pagination
              current={pageNum}
              total={totalCount}
              pageSize={30}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}
