import React, { useState, useEffect } from "react";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import i_download from "../../../asset/images/download_icon.png";

//redux
import { useSelector } from "react-redux";

import { myPageInfoApi } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function ExcelDownloadPop({ setExcelPop }) {
  // const [excelPop, setExcelPop] = useState(false);

  const userData = useSelector((state) => state.user);

  const [myInfoData, setMyInfoData] = useState({});

  const myInfoComeFnc = async () => {
    const response = await myPageInfoApi(userData.site_num);

    if (response.status === "success") {
      setMyInfoData(response.data.uInfo);
      //   setSiteData(response.data.sList);
    } else if (response.status === "fail") {
      swal("오류", "", "error");
    }
  };

  useEffect(() => {
    myInfoComeFnc();
  }, []);

  const downloadExcel = async (excelLink) => {
    const link = document.createElement("a");
    link.href = excelLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const excels = [
    {
      fileName: "견적서 엑셀 샘플 파일",
      url: "https://gongsaero.s3.ap-northeast-2.amazonaws.com/system/gongsaero_template.xlsx",
    },
    // {fileName:"포스코건설 추천 잡자재 파일",url:""}
  ];
  const testUrlPosco =
    "https://gongsaero.s3.ap-northeast-2.amazonaws.com/system/%5B%ED%8F%AC%EC%8A%A4%EC%BD%94%EA%B1%B4%EC%84%A4%5D+%EC%B6%94%EC%B2%9C+%EC%9E%A1%EC%9E%90%EC%9E%AC+%ED%8C%8C%EC%9D%BC.xlsx";

  return (
    <div className="pop">
      <div
        className="pop_dim"
        //   onClick={() => setExcelPop(false)}
      >
        <div className="excel_download_pop">
          <div className="close" onClick={() => setExcelPop(false)}>
            <img src={i_close} alt="" />
          </div>
          <h3 className="pop_tit">엑셀파일 다운로드</h3>
          <div className="excel_info">
            {excels.map((data, index) => {
              return (
                <div className="excels">
                  <span>{data.fileName}</span>
                  <div
                    className="excels_download_box"
                    onClick={() => {
                      downloadExcel(data.url);
                    }}
                  >
                    <span>다운로드</span>
                    <img src={i_download} alt="" />
                  </div>
                </div>
              );
            })}
            {/* 포스코 회원만 보일 코드 (임시) */}
            {myInfoData?.con_name?.includes("포스코") ||
            myInfoData?.con_name?.includes("posco") ||
            myInfoData?.con_name?.includes("Posco") ||
            myInfoData?.con_name?.includes("POSCO") ||
            myInfoData?.con_name?.includes("공새로") ? (
              <div className="excels">
                <span>포스코건설 추천 잡자재 50 샘플</span>
                <div
                  className="excels_download_box"
                  onClick={() => {
                    downloadExcel(testUrlPosco);
                  }}
                >
                  <span>다운로드</span>
                  <img src={i_download} alt="" />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
