import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createConstructionSchema } from "../../pages/myPage/create-construction/schema/schema";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/action";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFileUpload from "../useMutation/useFileUpload";
import useCreateConstruction from "../useMutation/useCreateConstruction";

const useCreateConstructionHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: zodResolver(createConstructionSchema),
    mode: "all",
    defaultValues: {
      biz_logo: "",
      biz_name: "",
      biz_reg: "",
      biz_category: "",
      biz_no: "",
      ceo_name: "",
      biz_item: "",
      biz_type: "",
      addr: "",
      addr_detail: "",
      zip: "",
      user_type: "CUSTOMER",
    },
  });

  const [constructionCreateModal, setConstructionCreateModal] = useState(false);
  const [addrSearchModal, setAddrSearchModal] = useState(false);

  const onCreateSuccessCb = ({ status }) => {
    if (status === "success") {
      const userData = {
        ...user,
        organizationState: "PENDING_ORGANIZATION",
      };
      dispatch(actions.setUser(userData));
      navigate("/", { state: { isFromCreateConstructionPage: true } });
    }
  };

  const onCreateErrorCb = (error) => {
    console.error("create error: ", error);
  };

  const { mutate: createConstruction } = useCreateConstruction(
    onCreateSuccessCb,
    onCreateErrorCb
  );

  const handleCreateConstruction = () => {
    createConstruction(params);
  };

  const [bizRegFile, setBizRegFile] = useState(null);

  const onBizRegFileUploadSuccessCb = ({ data }) => {
    setValue("biz_reg", data.name);
    setParams({ ...params, biz_reg: data.no });
  };

  const onBizRegFileUploadErrorCb = (error) => {
    console.error("biz reg file upload error", error);
  };

  const { mutate: bizRegFileUpload } = useFileUpload(
    onBizRegFileUploadSuccessCb,
    onBizRegFileUploadErrorCb
  );

  const handleBizRegFileUpload = useCallback(async () => {
    const formData = new FormData();
    formData.append("file", bizRegFile);

    bizRegFileUpload(formData);
  }, [bizRegFile, bizRegFileUpload]);

  useEffect(() => {
    if (bizRegFile) {
      handleBizRegFileUpload();
    }
  }, [bizRegFile, handleBizRegFileUpload]);

  const [bizLogoFile, setBizLogoFile] = useState(null);

  const onBizLogoFileUploadSuccessCb = ({ data }) => {
    setValue("biz_logo", data.path);
    setParams({ ...params, biz_logo: data.no });
  };

  const onBizLogoFileUploadErrorCb = (error) => {
    console.error("biz logo file upload error", error);
  };

  const { mutate: bizLogoFileUpload } = useFileUpload(
    onBizLogoFileUploadSuccessCb,
    onBizLogoFileUploadErrorCb
  );

  const handleBizLogoFileUpload = useCallback(async () => {
    const formData = new FormData();
    formData.append("file", bizLogoFile);

    bizLogoFileUpload(formData);
  }, [bizLogoFileUpload, bizLogoFile]);

  useEffect(() => {
    if (bizLogoFile) {
      handleBizLogoFileUpload();
    }
  }, [bizLogoFile, handleBizLogoFileUpload]);

  const [params, setParams] = useState({});

  const onClickSubmit = (data) => {
    setParams({
      ...params,
      constructionNo: user.con_num || "",
      name: data.biz_name,
      type: data.biz_category,
      num: data.biz_no,
      ceo: data.ceo_name,
      sec: data.biz_item,
      bc: data.biz_type,
      addr1: data.addr,
      addr2: data.addr_detail,
      zip: data.zip,
      constructionType: data.user_type,
    });

    setConstructionCreateModal(true);
  };

  useEffect(() => {
    if (user.organizationState !== "REGIST_ORGANIZATION" && user.isDemo) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0];
      document.querySelector(`input[name="${firstErrorKey}"]`)?.focus();
    }
  }, [errors]);

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    clearErrors,

    setBizLogoFile,
    setBizRegFile,
    onClickSubmit,
    handleCreateConstruction,

    addrSearchModal,
    setAddrSearchModal,
    constructionCreateModal,
    setConstructionCreateModal,
  };
};

export default useCreateConstructionHandler;
