import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";

const AddrSearchButton = ({ setAddrSearchModal }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const onClickAddrSearchButton = () => {
    setAddrSearchModal(true);
  };

  return (
    <Button
      type="primary"
      size="small"
      style={{ marginTop: isDesktop ? "22px" : "6.6rem" }}
      onClick={onClickAddrSearchButton}
    >
      주소검색
    </Button>
  );
};

export default AddrSearchButton;
