import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoginApi } from "../../api/auth";
import { QUERY_KEYS } from "../../constant/QueryKeys";

const useLogin = (onSuccessCb, onErrorCb) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: LoginApi,
    onSuccess: (res) => {
      onSuccessCb(res);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.LOGIN] });
    },
    onError: (error) => {
      onErrorCb(error);
    },
  });
};

export default useLogin;
