import React from "react";
import Modal from "../../../../../component/ui/modal/Modal";
import Typography from "../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../constant/Palette";
import Button from "../../../../../component/ui/button/Button";

const AgreementCheckModal = ({
  agreementCheckModal,
  setAgreementCheckModal,
}) => {
  const onClickCancel = () => {
    setAgreementCheckModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="error"
      open={agreementCheckModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.RED.L10}>
          약관 미동의
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="primary" size="small" onClick={onClickCancel}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        서비스 가입을 위하여 필수 약관에 동의해주시기 바랍니다.
      </Typography>
    </Modal>
  );
};

export default AgreementCheckModal;
