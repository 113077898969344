import styled from "@emotion/styled";

export const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 15px;
  div {
    position: relative;
  }
  div:nth-child(1) {
    clip-path: polygon(10% 0, 85% 0, 100% 100%, 10% 100%) !important;
    left: -26px;
    color: ${(props) => {
      if (props.filterUrl === "estimate") return "#ffffff";
    }};
    background: ${(props) => {
      if (props.filterUrl === "estimate") return "#254574";
    }};
  }
  div:nth-child(2) {
    left: -63px;
    color: ${(props) => {
      if (props.filterUrl === "contract") return "#ffffff";
    }};
    background: ${(props) => {
      if (props.filterUrl === "contract") return "#254574";
    }};
  }
  div:nth-child(3) {
    left: -100px;
    color: ${(props) => {
      if (props.filterUrl === "order") return "#ffffff";
    }};
    background: ${(props) => {
      if (props.filterUrl === "order") return "#254574";
    }};
  }
  div:nth-child(4) {
    left: -137px;
    color: ${(props) => {
      if (props.filterUrl === "invoice") return "#ffffff";
    }};
    background: ${(props) => {
      if (props.filterUrl === "invoice") return "#254574";
    }};
  }
`;

export const Tab = styled.div`
  width: 260px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #b1b1b1;
  background: #dedede;
  cursor: pointer;
  clip-path: polygon(0 0, 85% 0, 100% 100%, 15% 100%);
  transition: all 0.2s;
  :hover {
    color: #ffffff;
    background: #254574;
  }
`;
