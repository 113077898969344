import React from 'react';
import i_close from '../../../asset/images/w-cancel-icon.png';
import FileViewer from 'react-file-viewer';

export default function ExcelPreviewPop({ url, setExcelPop }) {
  const onError = (e) => {};

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setExcelPop(false)}></div>
      <div className='contract_terms_pop'>
        <div className='close' onClick={() => setExcelPop(false)}>
          <img src={i_close} alt='' />
        </div>
        <div>
          <FileViewer fileType={'xlsx'} filePath={url} onError={onError} />
        </div>
      </div>
    </div>
  );
}
