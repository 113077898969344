import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const AddrDetailInput = ({ control, errors }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="addr_detail"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width={isDesktop ? 380 : "100%"}
          id="addr_detail"
          placeholder="상세주소를 입력해주세요."
          helpText={
            (errors.addr || errors.addr_detail) && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                현장 주소는 필수 항목입니다.
              </Typography>
            )
          }
          status={errors.addr_detail && "error"}
        />
      )}
    />
  );
};

export default AddrDetailInput;
