import React, { useEffect, useState } from 'react';
//library
import { Link } from 'react-router-dom';
//css
import '../../../asset/css/pop.css';
//etc
import i_close from '../../../asset/images/w-cancel-icon.png';
import { quickOrderCancelApi } from '../../../api/auth';
//sweet alert
import swal from 'sweetalert';

export default function QuickCancelPop({
  setQuickPop,
  orderNumber,
  quickNumber,
}) {
  const [cancelData, setCancelData] = useState({
    or_no: orderNumber,
    q_no: quickNumber, // 큐넘이 없음;
    or_cancel: '',
  });
  const [selected, setSelected] = useState('');

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  const quickOrderCancelFnc = async () => {
    if (cancelData.or_no === 0 && cancelData.q_no === 0) {
      swal( "다시 시도해주세요." ,  "" ,  "warning" )
    } else if (cancelData.or_cancel === '') {
      swal( "취소사유를 선택해주세요." ,  "" ,  "warning" )
    } else {
      const response = await quickOrderCancelApi(cancelData);
      if (response.status === 'success') {
        swal( "퀵주문이 취소되었습니다." ,  "" ,  "success" )
        window.location.reload();
        setQuickPop(false);
      } else if (response.status === 'fail') {
        swal( "다시 시도해주세요." ,  "" ,  "warning" )
      }
    }
  };

  useEffect(() => {
    /* setCancelData({...cancelData, or_no : orderNumber}); */
  }, []);

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setQuickPop(false)}></div>
      <div className='contract_pop'>
        <div className='close' onClick={() => setQuickPop(false)}>
          <img src={i_close} alt='' />
        </div>
        <div className='contract_pop_tit'>
          <h3>퀵주문 취소를 요청하시겠습니까?</h3>
          <p>주문 취소 사유를 선택해주세요.</p>
        </div>
        <select
          onChange={(e) => {
            if (e === '기타') {
            } else {
              setCancelData({ ...cancelData, or_cancel: e.target.value });
            }
          }}
        >
          <option value='' hidden>
            취소사유 선택
          </option>
          <option value='회사와의 관계'>회사와의 관계</option>
          <option value='파기 후 재계약'>파기 후 재계약</option>
          <option value='변심'>변심</option>
          <option value='기타'>기타</option>
        </select>
        {selected === '기타' && (
          <input placeholder='계약 취소 사유를 입력해주세요' />
        )}
        <div className='confirm_btn'>
          <p
            onClick={() => {
              quickOrderCancelFnc();
            }}
          >
            확인
          </p>
        </div>
      </div>
    </div>
  );
}
