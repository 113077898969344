import { useState } from "react";
import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import EyeOpenedSVG from "../../../../../../asset/images/svg/EyeOpenedSVG";
import EyeClosedSVG from "../../../../../../asset/images/svg/EyeClosedSVG";
import { COLOR } from "../../../../../../constant/Palette";

const PwdCheckInput = ({ control, errors }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  const [pwdInVisible, setPwdInVisible] = useState(true);

  const onClickSuffix = () => {
    setPwdInVisible((prev) => !prev);
  };

  return (
    <Controller
      name="password_check"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width={isDesktop ? 380 : "100%"}
          id="password_check"
          type={pwdInVisible ? "password" : "text"}
          placeholder="비밀번호를 다시 한번 입력해주세요."
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                비밀번호 확인{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={
            errors?.password_check && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.password_check.message}
              </Typography>
            )
          }
          status={errors.password_check && "error"}
          suffix={pwdInVisible ? <EyeOpenedSVG /> : <EyeClosedSVG />}
          onClickSuffix={onClickSuffix}
        />
      )}
    />
  );
};

export default PwdCheckInput;
