// PrivateRoute.js

import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ auth, component: Component, type }) {
  // 건설사 CUSTOMER
  // 협력사 SUBCONTRACTOR
  // {type === 'subcontractor' ? '가능' : type === 'customer' ? '가능' : '불가능' }
  // 협력사(subcontractor) Type을 갖고온다면?

  // 건설사인 경우 다 보여주고
  // 협력사인 경우 몇가지는 제재해애하는 상황
  // 협력사인경우
  // type === 협력사이면 보여줄 페이지 component를 지정해놔야할 듯?

  return auth ? Component : <Navigate to="/login" />;
}

export default PrivateRoute;
