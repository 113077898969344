import React from "react";
//library
import { Link } from "react-router-dom";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import pb_order_desc from "../../../asset/images/pb_order_desc.jpg";

export default function PbDescPop({ setPbDescPop }) {
  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setPbDescPop(false)}></div>
      <div className="pb_desc_pop">
        <div className="close" onClick={() => setPbDescPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3 className="pop_tit">
          <img
            src={pb_order_desc}
            style={{ width: "100%", marginTop: "20px" }}
          />
        </h3>
      </div>
    </div>
  );
}
