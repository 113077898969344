import styled from "@emotion/styled";

export const Wrapper = styled.div`
  @media (max-width: 1080px) {
    width: 970.02px;
  }
`;

export const InvoiceItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border: 1px solid #ddd;
  border-top: none;
  background: #f2f5f9;
  @media (max-width: 1080px) {
    height: 10rem;
  }
`;

export const InvoiceItemCodeWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InvoiceItemCode = styled.div`
  font-size: 16px;
  font-weight: 700;
  @media (max-width: 1080px) {
    font-size: 2.8rem;
    white-space: nowrap;
  }
`;

export const OrderDetailBtn = styled.button`
  width: 80px !important;
  height: 30px !important;
  line-height: 30px !important;
  background: #dbe4f0 !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #002863 !important;
  transition: all 0.2s !important;
  margin-left: 20px !important;
  &:hover {
    background: #002863 !important;
    color: #dbe4f0 !important;
  }
  @media (max-width: 1080px) {
    font-size: 2.2rem !important;
    width: 12rem !important;
    height: 6rem !important;
    line-height: 6rem !important;
    margin-left: 1rem !important;
  }
`;

export const InvoiceItemDate = styled.div`
  font-size: 16px;
  font-weight: 700;
  @media (max-width: 1080px) {
    font-size: 2.8rem;
  }
`;

export const InvoiceItemTotalAmount = styled.div`
  font-size: 16px;
  font-weight: 700;
  @media (max-width: 1080px) {
    font-size: 2.8rem;
  }
`;

export const InvoiceItemTitle = styled.div`
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: 40px;
  div {
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    border-right: 1px solid #ddd;
  }
  div:nth-of-type(1) {
    width: 14%;
  }
  div:nth-of-type(2) {
    width: 22%;
  }
  div:nth-of-type(3) {
    width: 13%;
  }
  div:nth-of-type(4) {
    width: 16%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
  div:nth-of-type(5) {
    width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
  div:nth-of-type(6) {
    width: 17%;
    border-right: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1080px) {
    height: 7rem;
    line-height: 7rem;
    div {
      font-size: 2.8rem;
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
`;
