import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const CodeInput = ({
  control,
  errors,
  time,
  isActive,
  codeBtnDisable,
  codeInputDisable,
}) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  const helpText = () => {
    if (codeBtnDisable && codeInputDisable) {
      return (
        <Typography component="p" type="c5" color={COLOR.SUB.BLUE.L05}>
          확인 완료
        </Typography>
      );
    }

    if (errors.code) {
      return (
        <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
          {time} {errors.code.message}
        </Typography>
      );
    }

    return (
      <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
        {isActive && time}
      </Typography>
    );
  };

  return (
    <Controller
      name="code"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width={isDesktop ? 280 : "70rem"}
          id="code"
          placeholder="인증번호를 입력해주세요."
          disabled={codeInputDisable}
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                인증번호 확인{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={helpText()}
          status={errors.code && "error"}
        />
      )}
    />
  );
};

export default CodeInput;
