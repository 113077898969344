import React, { useState } from "react";
import { getYear, getMonth } from "date-fns"; // getYear, getMonth 
import DatePicker, { registerLocale } from "react-datepicker";  // 한국어적용
import ko from 'date-fns/locale/ko'; // 한국어적용
import arrowLeftIcon from '../../asset/images/arrow-left-icon.png';
import arrowRightIcon from '../../asset/images/arrow-right-icon.png';


registerLocale("ko", ko) // 한국어적용
const _ = require('lodash'); //lodash 라이브러리 사용


const PickDate = ({
  estimateInfo, //회원가입 모든 폼
  setEstimateInfo, //회원가입 모든 폼 set함수
  estimateInfoPeriod, //회원가입 모든 폼
  setEstimateInfoPeriod, //회원가입 모든 폼 set함수
  sort, //change date 바꿀값 string 구분용
  selectDate, //첫 셀렉된 날짜
  setSelectDate, //셀렉하고 날짜 변경 set함수
  time, //시간 가져가기
  alertMsg, //투찰기한 혹은 납품일시가 minValueTime에 충족하지 않을경우 alert true
  minValueTime, //최소시간 납품일시는 1시간, 투찰가능시간은 10분
  formatDate //date type format
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const years = _.range(2010, getYear(new Date()) + 2, 1)
  const months = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월']; 
  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div className="pick_calendar">
          <a onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <img src={arrowLeftIcon} alt=""/>
          </a>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option+"년"}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <a onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <img src={arrowRightIcon} alt=""/>
          </a>
        </div>
      )}
      // className={
      //   alertMsg.endate2 ? "select_alert" : null
      // }
      selected={selectDate}
      dateFormat={"yyyy-MM-dd"}
      locale={ko}
      minDate={new Date()}
      onChange={(date) => {
        setStartDate(date);
        setSelectDate(date);
        
        {sort === 'enDate' &&
        setEstimateInfo({
          ...estimateInfo,
          bid_endate: formatDate(date) + 'T' + time,
        })}
        {sort === 'delDate' &&
        setEstimateInfo({
          ...estimateInfo,
          bid_deldate: formatDate(date) + 'T' + time,
        })}
        {sort === 'enDate2' &&
        setEstimateInfoPeriod({
          ...estimateInfoPeriod,
          bid_endate: formatDate(date) + 'T' + time,
        })
        }
        minValueTime(date, time, sort);
      }}
    />
  );
};

export default PickDate