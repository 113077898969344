import React, { useState } from "react";
//library
import { useNavigate } from "react-router-dom";
//component
import Mheader from "../../../component/common/Mheader";

export default function Request() {
  const navigate = useNavigate();
  const [uploadFile, setUploadFile] = useState([{ file: "파일명", id: 0 }]);

  return (
    <div className="quick_wrap request_step4_wrap">
      <Mheader title="견적 간편 입력" />
      <div className="quick_estimate_container request_step4_container wd">
        <div className="top_pagenation">
          <h2>견적서 간편 입력</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p>2</p>
            <p>3</p>
            <p className="active">4</p>
            <p>5</p>
          </div>
        </div>
        <div className="top_request_step4">
          <p>
            * 비고란에 텍스트 및 링크 또는 이미지를 업로드하여 정확한 품목을
            요청해보세요
          </p>
          <div className="excel_btn_wrap">
            <div className="excel_download">엑셀 샘플 다운로드</div>
            <div className="excel_upload">엑셀파일 업로드</div>
          </div>
        </div>
        <div className="excel_place"></div>
        <ul className="request_step4_list">
          <li>
            <p>공고 관련 추가 내용</p>
            <div className="textarea_div" spellcheck="false">
              <textarea placeholder="공고 관련 추가 내용이 있으시다면 작성해주세요."></textarea>
            </div>
          </li>
          <li>
            <p>이미지 첨부</p>
            <div>
              {uploadFile.map((data, i) => {
                if (data.id === 0) {
                  return (
                    <div className="img_add" key="data.file">
                      <input
                        type="file"
                        id={data.id}
                        accept="image/*"
                        onChange={(e) => {
                          let copy = uploadFile;
                          copy[i].file = e.target.files[0].name;
                          setUploadFile(copy);
                        }}
                      />
                      <p>{data.file}</p>
                      <label htmlFor={data.id}>찾아보기</label>
                      <div
                        className="btn_add"
                        onClick={() => {
                          if (uploadFile.length < 5) {
                            setUploadFile([
                              ...uploadFile,
                              { file: "", id: uploadFile.length },
                            ]);
                          }
                        }}
                      >
                        추가{""}
                        {""}
                        {""}+
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="img_add" key="data.file">
                      <input
                        type="file"
                        id={data.id}
                        accept="image/*"
                        onChange={(e) => {
                          let copy = uploadFile;
                          copy[data.id].file = e.target.files[0].name;
                          setUploadFile(copy);
                        }}
                      />
                      <p>{data.file}</p>
                      <label htmlFor={data.id}>찾아보기</label>
                      <div
                        className="btn_add"
                        onClick={() => {
                          if (!uploadFile.length < 2) {
                            let copy = uploadFile;
                            copy.splice(-1, 1);
                            setUploadFile(copy);
                          }
                        }}
                      >
                        삭제{""}
                        {""}
                        {""}-
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </li>
          <li>
            <div className="page_btn">
              <div
                className="btn_prev"
                onClick={() => {
                  navigate(-1);
                }}
              >
                이전
              </div>
              <div
                className="next"
                onClick={() => {
                  navigate("/management/bid/request_complete");
                }}
              >
                다음
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
