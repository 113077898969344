import React from "react";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./pwdUpdateModal.module.css";
import Button from "../../../../../../component/ui/button/Button";
import useUpdatePwdModalHandler from "../../../../../../hooks/handler/useUpdatePwdModalHandler";
import PwdUpdateSuccessModal from "../pwd-update-success/PwdUpdateSuccessModal";
import OldPwdInput from "./components/elements/input/OldPwdInput";
import NewPwdInput from "./components/elements/input/NewPwdInput";
import NewPwdCheckInput from "./components/elements/input/NewPwdCheckInput";

const PwdUpdateModal = ({ pwdUpdateModal, setPwdUpdateModal }) => {
  const {
    handleSubmit,
    control,
    errors,
    handleUpdatePwd,
    pwdUpdateSuccessModal,
    setPwdUpdateSuccessModal,
  } = useUpdatePwdModalHandler();

  const onClickCancel = () => {
    setPwdUpdateModal(false);
  };

  return (
    <Modal
      size="xsmall"
      open={pwdUpdateModal}
      title={
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          비밀번호 변경
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={null}
    >
      <form
        className={s.body_container}
        onSubmit={handleSubmit(handleUpdatePwd)}
      >
        <div className={s.body_container}>
          <OldPwdInput control={control} errors={errors} />
          <NewPwdInput control={control} errors={errors} />
          <NewPwdCheckInput control={control} errors={errors} />
        </div>

        <div className={s.footer_container}>
          <Button type="danger" size="small" onClick={onClickCancel}>
            취소
          </Button>
          <Button type="primary" size="small" htmlType="submit">
            확인
          </Button>
        </div>
      </form>
      {pwdUpdateSuccessModal && (
        <PwdUpdateSuccessModal
          pwdUpdateSuccessModal={pwdUpdateSuccessModal}
          setPwdUpdateSuccessModal={setPwdUpdateSuccessModal}
          setPwdUpdateModal={setPwdUpdateModal}
        />
      )}
    </Modal>
  );
};

export default PwdUpdateModal;
