import React from "react";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/policy.css";

export default function Terms() {
  return (
    <div className="terms_wrap">
      <Mheader title="이용약관" />
      <div className="terms_container">
        <h2 className="policy_tit">이용약관</h2>
        <div className="terms_contents">
          <h1 className="terms_title">물품구매계약 일반약관</h1>
          <div className="terms_box">
            <h4>제 1조 &#40;목 적&#41;</h4>
            <p>
              이 약관은 이용자가 주식회사 공새로(이하 “회사”)가 운영하는 인터넷
              서비스 사이트(이하 “사이트” 또는 “공새로”)를 통해 제공하는 인터넷
              전자상거래 관련 서비스(이하 “서비스”)와 관련하여 회사와 이용자의
              권리, 의무, 책임사항을 규정함을 목적으로 합니다. 또한 본 약관은
              유무선 PC통신, 태블릿 PC, 스마트폰(아이폰, 안드로이드폰 등)
              어플리케이션 및 모바일웹 등을 이용하는 전자상거래 등에 대해서도 그
              성질에 반하지 않는 한 준용됩니다.
              <br />본 약관이 규정한 내용 이외의 회사와 이용자 간의 권리, 의무
              및 책임사항에 관하여서는 전기통신사업법 기타 대한민국의 관련
              법령과 상관습에 의합니다.
            </p>
          </div>
          <div className="terms_box">
            <h4>제 2조 &#40;정 의&#41;</h4>
            <p>
              이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
              <br />
              1. 회사가 운영하는 사이트는 아래와 같습니다.www.gongsaero.com 을
              비롯하여 추후 회사에서 공지하고 제공하는 기타 웹사이트, 스마트폰
              및 이동통신기기를 통해 제공되는 모바일 어플리케이션 포함
              <br />
              2. 이용자: 공새로에 접속하여 이 약관에 따라 서비스를 받는 회원 및
              비회원을 말합니다.
              <br />
              3. 회원(고객): 본 약관에 따라 회사와 서비스 이용계약을 체결하고
              회원 아이디(ID)를 부여받아 회사가 제공하는 서비스를 이용하는
              개인을 말합니다.
              <br />
              4. 회원(공급업체): 회사에 개인정보를 제공하여 회원등록을 한 자
              혹은 회사로서, 회사와 서비스 이용계약을 체결하고 회원 아이디(ID)를
              부여 받아 사이트 내에 매칭하기 서비스와 비교견적 서비스를 이용하는
              구매자이자 판매자, 작업자이자 업체를 말합니다.
              <br />
              5. 매칭요청: 요청자가 원하는 견적(자재조달, 인력매칭, 장비매칭
              등)에 대한 구체적인 정보를 담고 있는 서비스 요청서입니다.
              <br />
              6. 콘텐츠(Contents): 회사가 공새로에서 제공하는 정보, 요청서 작성,
              견적 작성, 요청서 수신, 견적 수신, 프로그램 등
              부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지ㆍ영상 및 복합체의
              정보나 자료를 의미합니다.
              <br />
              7. 회원 아이디(이하 “ID”): 회원의 식별과 회원의 서비스 이용을
              위하여 회원이 선정하고 회사가 승인하는 문자 또는 숫자의 조합을
              말합니다.
              <br />
              8. 비밀번호(Password): 이용자가 등록회원과 동일인인지 신원을
              확인하고, 회원의 통신상 개인정보를 보호하기 위하여 회원이 정한
              문자와 숫자의 조합을 말합니다.
              <br />
              9. 비회원: 회원에 가입하지 않아 사이트에 모든 기능을 사용할 수
              없는 자를 말합니다.
              <br />
              10. 회원 탈퇴: 회원이 이용계약을 종료시키는 행위를 말합니다.
              <br />
              11. 안전거래서비스: 서비스로서, 회원 상호 간의 금전 거래를
              안전하고 용이하게 하기 위하여 회사가 제공하는 서비스를 의미합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 3조 &#40;약관의 명시, 효력 및 개정&#41;</h4>
            <p>
              1. 회사는 이 약관의 내용과 주소지, 관리자의 성명, 개인정보보호
              담당자의 성명, 연락처(전화, 전자우편 주소 등) 등을 이용자가 알 수
              있도록 공새로의 초기 서비스 화면에 게시합니다. 다만, 약관의 구체적
              내용은 이용자가 연결화면을 통하여 볼 수 있습니다.
              <br />
              2. 이 약관은 그 내용을 공새로에 게시하거나 이메일 등 기타의
              방법으로 회원에게 공지함으로써 효력이 발생합니다.
              <br />
              3. 서비스 이용 시 화면에서 제시되는 경고 팝업의 효력은 본 약관의
              효력과 동일합니다.
              <br />
              4. 회사는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법,
              정보통신망 이용촉진 및 정보 보호 등에 관한 법률 기타 관련 법령을
              위배하지 않는 범위 내에서 약관을 변경할 수 있으며, 변경된 약관은
              2항과 같은 방법으로 효력을 발생합니다.
              <br />
              5. 제4항에 따라 공지된 적용일자 이후에 회원이 회사의 서비스를 계속
              이용하는 경우에는 변경된 약관의 효력 발생일로부터 7일 이내에
              거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 개정된
              약관에 동의하는 것으로 간주합니다. 개정된 약관에 동의하지 아니하는
              회원은 언제든지 자유롭게 서비스 이용계약을 해지할 수 있습니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 4조 &#40;약관 외 준칙&#41;</h4>
            <p>
              1. 이 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하
              ‘서비스 별 안내’라 합니다)와 함께 적용합니다.
              <br />
              2. 이 약관에 명시되지 않은 사항에 대해서는 전기통신사업법,
              전자거래기본법, 정보통신망법, 기타 관련법령 및 서비스 별 안내의
              규정에 의합니다.
              <br />
            </p>
          </div>

          <h1 className="terms_title">제 2장 서비스 이용 계약</h1>
          <div className="terms_box">
            <h4>제 5조 &#40;이용 계약의 성립&#41;</h4>
            <p>
              1. 이용 계약은 서비스 이용 희망자의 이 약관에 대해 동의한다는
              의사표시와 이용 신청에 대한 회사의 승낙으로 성립됩니다. <br />
              2. 이 약관에 대한 동의는 이용 신청 당시 링크를 확인함으로써 그
              의사표시를 합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 6조 &#40;회원가입 및 승낙&#41;</h4>
            <p>
              1. 회원가입은 회원이 되고자 하는 이용자(이하 “가입신청자”)가
              약관의 내용에 동의를 하고, 회사가 정한 가입 양식에 따라
              회원정보(이용자ID), 비밀번호, 주소, 연락처 등을 기입하여
              회원가입신청을 온라인상 혹은 서면 제출하고 회사가 이러한 신청에
              대하여 승인함으로써 체결됩니다.
              <br />
              2. 이용자의 회원가입신청에 대하여 회사가 승낙한 경우, 회사는 회원
              ID/Password와 기타 회사가 필요하다고 인정하는 내용을 이용자에게
              통지합니다.
              <br />
              3. 회사는 이용자의 신청에 대해서 회원가입을 승낙하는 것을 원칙으로
              합니다. 다만, 다음 각 호에 해당하는 신청에 대하여는 승인을 하지
              아니할 수 있습니다.
              <br />
              &nbsp;1) 본인 실명이 아니거나 다른 사람의 명의를 사용하여
              신청하였을 때<br />
              &nbsp;2) 서비스 이용 계약 신청서의 내용을 허위로 기재하였을 때
              <br />
              &nbsp;3) 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로
              신청하였을 때<br />
              &nbsp;4) 부정한 용도로 본 서비스를 이용하고자 하는 경우
              <br />
              &nbsp;5) 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우
              <br />
              &nbsp;6) 본 서비스와 경쟁관계에 있는 이용자가 신청하는 경우
              <br />
              &nbsp;7) 기타 이용신청자의 귀책사유로 이용승낙이 곤란한 경우
              <br />
              4. 회사는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그
              신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수
              있습니다.
              <br />
              &nbsp;1) 회사가 설비의 여유가 없는 경우
              <br />
              &nbsp;2) 회사의 기술상 지장이 있는 경우
              <br />
              &nbsp;3) 기타 회사의 귀책사유로 이용승낙이 곤란한 경우
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 7조 &#40;안전거래서비스&#41;</h4>
            <p>
              1. 회원은 사이트를 통한 회원가입 시 발급된 ID 하나로 제1장 제2조
              1항에 명시된 모든 사이트에 자동 가입되며, 해당 서비스를 이용할 수
              있습니다. 단, 일부 사이트의 특정 서비스는 별도의 가입절차를 통하여
              이용할 수 있으며, 이 경우 해당 사이트의 이용 시에는 해당 사이트의
              특정서비스에 대한 이용약관이 이 약관보다 우선 적용됩니다. <br />
              2. 회원의 최초 서비스 이용신청 이후 사이트가 늘어난 경우에도 신규
              사이트의 이용약관에서 달리 명시되지 않는 한 제1항의 내용이
              마찬가지로 적용됩니다. 이 경우 회사는 신규 사이트의 서비스 개시
              일자, 회원자동가입 사실 기타 관련 정보를 그 개시일 7일 이전부터
              개시 일까지 각 사이트에 공지하거나, 또는 그 개시일 7일 이전까지
              이메일 통지 등으로 각 회원에게 개별 통지합니다.
              <br />
              3. 공새로 서비스 이용은 사이트의 업무상 또는 기술상 특별한 지장이
              없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
              <br />
              4. 회사가 공새로에서 제공하는 매칭 서비스는 거래금액에 대한 일정
              요율을 수수료로 책정합니다. 단, 회사가 지정하는 테스트 기간은
              무료이며, 유료 서비스 (매칭 수수료, 프리미엄서비스, 안전 결제
              수수료, 배너이용, 우대등록 등 광고이용)은 향후 당사 정책에 따라
              변경 가능합니다.
              <br />
              5. 유료서비스 이용 요금 및 결제 방식은 해당 서비스에 명시되어 있는
              규정에 따릅니다.
              <br />
              6. 회사는 통신판매중개업자로서 서비스 요금이 무료인 서비스와 일부
              유료서비스 이용과 관련하여 이용자들 사이에서 발생한 거래와 관련된
              손해에 대해서는 회사에 고의 또는 중대한 과실이 있는 경우를
              제외하고는 책임을 지지 않습니다.
              <br />
              7. 회원(공급업체)는 당 서비스를 통해 체결된 서비스 및 재화에 대해
              고객이 요청한 납품기한 내에 공급해야 합니다.
              <br />
              8. 회원(공급업체)는 고객에게 플랫폼 외 거래 유도, 납품 기한
              마준수, 그 외 기타 부적절한 행위에 대하여 당사의 부정당업체
              제재기준에 의거하여 당 플랫폼 이용 관련 제재를 받습니다.
              <br />
            </p>
          </div>
          <div className="terms_title">제 3장 서비스 제공 및 변경</div>
          <div className="terms_box">
            <h4>제 8조 &#40;서비스 내용&#41;</h4>
            <p>
              회사가 제공하는 서비스의 내용은 다음과 같습니다.
              <br />
              1. 건설사(현장)와 건자재 공급업체를 매칭하기 위한 정보와 매칭
              절차에 관련한 정보을 제공합니다.
              <br />
              2. 건자재 비교견적을 위한 역경매 방식의 견적 요청 절차와
              업체로부터 작성되는 견적서에 대한 정보를 제공합니다.
              <br />
              3. 업체 홍보 및 특가판매, 공동 구매 등의 판매 채널을 제공합니다.
              <br />
              4. 일정 관리 및 업무에 필요한 기타 정보를 제공합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 9조 &#40;정보의 제공 및 광고의 게재&#41;</h4>
            <p>
              1. 회사는 회원에게 서비스 이용에 필요하다고 인정되는 각종 정보에
              대해서 사이트 및 이메일, SMS 발송 등 각종 매체에 게재하는 방법
              등으로 회원에게 제공할 수 있습니다. <br />
              2. 회사는 서비스 개선 및 소개 등을 목적으로 회원의 동의 하에
              추가적인 개인정보를 요청할 수 있습니다.
              <br />
              3. 회사는 서비스의 운용과 관련하여 사이트, 이메일, SMS, DM 등에
              광고 등을 게재할 수 있습니다.
              <br />
              4. 회사는 서비스 이용 중 발생하는 이벤트, 알람, 공지 내용 등에
              대해서 이메일, SMS, 카카오톡 등을 통해서 메시지를 전송할 수
              있습니다.
              <br />
              5. 회사는4.를 위해 회원가입시 본인인증을 위해 사용된 휴대전화 번호
              등 개인정보를 활용할 수 있습니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 10조 &#40;서비스 제공의 제한 및 중단&#41;</h4>
            <p>
              1. 회사는 정기점검, 보수, 교체 등 회사가 필요한 경우 및 부득이한
              사유로 인하여 서비스 이용에 지장이 있는 경우에는 서비스의 이용의
              전부 또는 일부를 제한하거나 일시 중단할 수 있습니다. <br />
              2. 전시, 사변, 천재지변 또는 이에 준하는 국가 비상사태가
              발생하거나 발생할 우려가 있는 경우와 정전, 서비스 이용 폭주 등으로
              정상적인 서비스가 불가능한 경우 등 기타 불가항력적인 사유가 있는
              경우에는 서비스 이용의 전부 또는 일부를 제한하거나 중지할 수
              있습니다.
              <br />
              3. 제1항에 의한 서비스 중단의 경우에는 사이트는 회원에게 제11조의
              방법으로 통지를 합니다. 단, 사이트가 통제할 수 없는 사유로 인한
              서비스 중단으로 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
              <br />
              4. 회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로
              인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단,
              회사의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
              아니합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 11조 &#40;회원에 대한 통지&#41;</h4>
            <p>
              1. 회사가 회원에 대한 통지를 하는 경우에는 회원이 서비스 이용 신청
              시 사이트 화면 또는 회원가입 시 제출한 이메일 주소로 할 수
              있습니다. <br />
              2. 회사는 불특정 다수 회원에 대한 통지의 경우 7일 이상 사이트 상에
              게시함으로써 개별 통지에 갈음할 수 있습니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 12조 &#40;회원탈퇴 및 자격상실&#41;</h4>
            <p>
              1. 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 공새로
              내의 메뉴 또는 전화, 이메일 등의 방법을 이용하여 가입 해지를
              신청할 수 있으며, 회사는 즉시 회원 탈퇴 처리를 합니다.
              <br />
              2. 회원 탈퇴가 이루어짐과 동시에 서비스 이용과 관련된 모든 정보는
              비식별 처리됩니다. 이로 인해 발생하는 불이익에 대한 책임은 회원
              본인에게 있습니다.
              <br />
              3. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원의
              회원자격을 적절한 방법으로 제한 및 정지, 상실 시킬 수 있습니다.
              <br />
              1) 가입 신청 시에 허위 내용을 등록한 경우
              <br />
              2) 다른 사람의 ‘서비스’이용을 방해하거나 그 정보를 도용하는 등
              전자거래질서를 위협하는 경우
              <br />
              3) 서비스를 이용하여 법령과 본 약관이 금지하거나 공서양속에 반하는
              행위를 하는 경우
              <br />
              4) 회사가 회원 자격을 제한ㆍ정지 시킨 후, 동일한 행위가 2회 이상
              반복되거나 10일 이내에 그 사유가 시정되지 아니하는 경우
              <br />
              5) 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이
              경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를
              부여합니다.
              <br />
              6) 기타 서비스 이용에 있어 다른 회원의 이용에 불편을 끼치거나
              거래상의 신뢰를 상실 시키는 행위를 하는 경우 사용 제한 등의
              불이익을 제공할 수 있습니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 13조 &#40;과오금&#41;</h4>
            <p>
              1. 회사는 과오금이 발생한 경우 대금의 결제와 동일한 방법으로
              과오금 전액을 환불합니다. 다만, 동일한 방법으로 환불이 불가능할
              때는 이를 사전에 고지합니다.
              <br />
              2. 회원의 책임 있는 사유로 과오금이 발생한 경우, 회사가 과오금을
              환불하는 데 소요되는 비용은 합리적인 범위 내에서 회원이 부담하여야
              합니다.
              <br />
              3. 회사는 과오금의 환불절차를 디지털콘텐츠이용자보호지침에 따라
              처리합니다.
              <br />
            </p>
          </div>
          <div className="terms_title">
            제 4장 서비스 이용 계약의 해제, 해지
          </div>
          <div className="terms_box">
            <h4>
              제 14조 &#40;회사의 서비스 이용 계약해제, 해지 및 이용제한&#41;
            </h4>
            <p>
              1. 회사는 거래의 안정성을 위해 해당 약관에서 정한 바에 따라 회원
              자격을 정지하거나, 서비스 이용 제한 등의 조치를 취할 수 있습니다.
              <br />
              2. 회사는 다른 이용자의 약관 위반 행위에 가공하거나 공모한 이용자
              또는 약관 위반 행위를 돕거나 그로 인해 부당한 이득을 취한 이용자에
              대해서도 해당 약관 위반 행위에 대한 제재를 적용할 수 있습니다.
              <br />
              3. 회사가 회원자격을 정지하거나 이용제한 등 기타 필요한 조치를
              취할 경우 이 약관에 특별한 규정이 없는 한 사전에 회원에게 유선
              또는 메일 등 알림을 통해 통보하며 회원이 연락 두절 되거나 긴급을
              요하는 부득이한 경우 선조치 후통보 할 수 있습니다.
              <br />
              4. 계정 공유 중 제 3자가 이용자 본인의 계정을 이용하여 약관 위반
              행위가 발생한다고 하더라도 이용자 본인 및 해당 계정에 제재가
              적용될 수 있습니다.
              <br />
              1) 상업적 광고 행위“상업적 광고 행위” 란 채팅, 프로필, 게시글 등을
              이용하여 이루어지는 아래 각 호의 행위를 의미합니다.- 음란사이트
              광고 행위- 피라미드, 금융 서비스를 광고하는 행위- 위법, 위조 등이
              가능한 서비스를 광고하는 행위
              <br />
              2) 사행 행위“사행행위” 란 아래 각 호의 행위를 의미합니다.- 불법
              도박 사이트 서비스를 광고하는 행위- 사회통념상 납득되지 않는
              서비스를 광고하는 행위
              <br />
              3) 버그 및 제한사항 악용“버그 및 제한사항 악용” 이란 아래 각 호의
              행위를 의미합니다.- 고의로 서비스 오류를 이용하여 이득을 얻고,
              서비스 내 경제 시스템에 영향을 주는 행위예) 고용건수 오류를
              이용하여 반복적으로 이를 획득하는 행위- 고의로 서비스 오류를
              이용하여 다른 이용자에게 피해를 주는 행위예) 리뷰 작성을 이용하여
              다른 이용자에게 고의적 악성 리뷰를 작성하여 피해를 주는 행위-
              고의로 서비스 오류를 이용하여 시스템에 영향을 주는 행위예)
              프로그램 오류를 이용하여 서버를 다운 시키는 행위
              <br />
              4) 어뷰징“어뷰징” 이란 아래 각 호의 행위를 의미합니다.- 고의로
              리뷰 및 고용 수 등을 조작하는 행위- 일반적으로 서비스에 통용되지
              아니하는 방법으로 재화를 늘리는 행위- 비정상적으로 시스템 설계상
              제한을 회피하여 이익을 취하는 행위- 시스템상 허용되지 않는
              방법으로 다른 이용자의 평판 이익을 늘리는 행위
              <br />
              5) 불건전 언어 사용“불건전 언어 사용” 이란 아래 각 호의 행위를
              의미합니다.- 욕설, 비/속어 등을 사용하는 행위- 음란한 단어나
              노골적인 성 묘사 등을 통해 성적 수치심을 느끼게 하는 표현이나
              행동을 하는 행위- 다른 이용자에게 불쾌감이나 성적 수치심을 줄 수
              있는 명칭을 사용하는 행위- 현실에 대한 위협이나 상대방에게
              공포심을 느끼게 하는 표현이나 행동을 하는 행위- 기타 약관에서
              금지하는 표현이나 통신을 하여 상대방에게 불쾌감이나 혐오감을 주는
              행위- 제3자의 명예, 초상권, 개인정보를 포함한 제반 권리를 침해,
              훼손할 목적의 내용이 포함된 대화 등 이에 준하는 행위
              <br />
              6) 불건전 이름(닉네임) 사용“불건전 이름 사용” 이란 온라인 문화를
              저해하거나 관계 법령 및 사회질서와 미풍 양속을 저해하는 내용과
              욕설 또는 음란한 내용으로 만든 이름, 비즈니스 네임 등을 포함하여
              명명 된 아래 각 호의 행위를 의미합니다.- 공새로 직원 및 관리자
              또는 제3자를 사칭하는 행위- 욕설, 비/속어 등을 사용하는 행위- 다른
              이용자에게 불쾌감이나 성적 수치심을 줄 수 있는 명칭을 사용하는
              행위- 기타 약관에서 금지하는 명칭을 사용하는 행위관련 계정의
              닉네임 등은 임의로 변경될 수 있습니다.
              <br />
              7) 명의도용“명의도용” 이란 아래 각 호의 행위를 의미합니다.- 타인의
              개인정보를 이용하여 계정을 생성하는 행위
              <br />
              8) 결제도용“결제도용” 이란 아래 각 호의 행위를 의미합니다.- 타인의
              결제수단을 무단으로 이용하여 서비스 이용요금 등을 결제하는 행위
              <br />
              9) 불건전 게시물 게시“불건전 게시물 게시” 란 아래 각 호의 행위를
              의미합니다.- 회사 또는 제3자의 권리(저작권, 특허 등)를 침해하는
              게시물- 본인/타인의 개인정보(신상정보, 위치, 연락처, 이메일 등)이
              포함된 게시물- 다른 사람에게 성적 수치심을 주거나 혐오감, 불쾌감을
              유발하는 게시물- 다른 사람을 비방하거나 음해하는 목적의
              게시물(인신공격, 루머 포함)- 영리 목적의 광고 게시물(경품,
              상품광고, 사이트 홍보 등)- 악성코드가 포함되어 있거나 시스템
              장애를 유도하는 게시물- 특정 사상/종교적 색채가 짙은 게시물- 특정
              개인 및 회사에 대한 허위 사실을 악의적으로 유포하는 게시물- 인종,
              성, 특정 지역을 비하하거나 차별하는 게시물- 폭력, 비행, 사행심을
              조장하는 게시물- 약관 위반 행위와 관련된 게시물- 기타 약관, 관련
              법령에서 금지하는 게시물- 짧은 시간에 많은 양의 게시물을
              등록하거나 동일한 내용을 반복 게시하여 운영을 방해하는 행위
              <br />
              10) 회원은 회사의 해제․해지 및 이용제한에 대하여 이의신청을 할 수
              있습니다. 회원의 이의신청이 정당한 경우 회사는 즉시 회원이
              서비스를 이용할 수 있도록 필요한 조치를 취합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 15조 &#40;회사의 계약해제, 해지의 효과&#41;</h4>
            <p>
              회사가 제13조 제2항에 따라 서비스 이용계약을 해제 또는 해지한 경우
              회사는 회 원에게 손해배상을 청구할 수 있습니다. 다만, 회원이 고의
              또는 과실 없음을 증명 한 경우에는 그러하지 않습니다.
            </p>
          </div>
          <div className="terms_title">제 5장 안전거래서비스</div>
          <div className="terms_box">
            <h4>제 16조 &#40;안전거래서비스&#41;</h4>
            <p>
              1. 회사는 공새로 회원이 서비스를 이용함에 있어 안전하고 편리한
              금전 거래를 위하여 대금의 수령, 보관 및 송금업무로 이루어지는
              에스크로 방식의 안전거래서비스를 제공합니다. <br />
              2. 안전거래 진행 여부에 대한 동의를 하고, 안전거래를 위한 계좌를
              등록한 회원에 한하여 안전거래 서비스를 이용할 수 있습니다.
              <br />
              3. 각 거래에 대한 안전거래서비스는 공새로 고객 요구에 따라
              개시됩니다. 회원은 거래금액과 예상 서비스 이행 완료일을 포함한
              최종 안전거래 내용을 발송하며, 고객은 서비스에 대한 수수료 안내를
              받은 뒤 수수료와 거래금액을 합한 대금을 안전거래서비스에
              입금합니다.
              <br />
              4. 회사는 안전거래서비스를 통해 고객이 입금한 대금을 보관합니다.
              공새로 회원이 서비스를 이행한 이후 고객이 서비스 상 거래 확정
              버튼을 클릭하는 방식으로 거래 확정을 하면 회사는 보관하는 대금 중
              수수료를 제외한 거래금액을 회원에게 지급합니다.
              <br />
              5. 회사는 안전 결제를 위한 통로로써 역할을 하며 당사자간 분쟁으로
              인한 문제는 책임이 없습니다.
              <br />
              6. 대금 지급 지연이 발생한 경우 거래 당사자간 협의 통하여 일방
              고객이 거래 확정을 하여 회사에 알려야 합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 17조 &#40;안전거래서비스 계좌 등록&#41;</h4>
            <p>
              1. 회원은 안전거래서비스를 위하여 자신의 용역 및 판매 대금을
              수령할 계좌를 지정하여 올바른 정보를 입력하여야 합니다. <br />
              2. 회원이 입력한 계좌의 예금주는 회원이 실명확인을 하여 가입할 때
              동일인임을 원칙으로 합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 18조 &#40;안전거래서비스 이용료 등&#41;</h4>
            <p>
              1. 안전거래서비스를 이용하기 위한 이용료는 유료가 원칙이며 회사는
              안전거래서비스 이용료를 안전거래서비스 안내페이지에 안내합니다.
              안전거래서비스에 대한 이용료는 고객이 부담합니다. <br />
              2. 대금 송금 또는 결제로 인하여 별도의 수수료가 발생할 경우 해당
              수수료는 대금을 송금하는 회원(고객)이 부담하여야 합니다.
              <br />
              3. 안전거래서비스에 이미 고객이 이용료를 포함한 대금을
              지급하였으나 계약이 파기된 경우 회사는 고객에게 이용료를 제한
              거래금액만을 반환합니다. 자세한 반환에 대한 규정은 회사의 별도
              규정에 따릅니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 19조 &#40;예치금의 지급&#41;</h4>
            <p>
              1. 회사는 고객이 거래 확정을 하면 바로 대금을 회원에게 지급하는
              것을 원칙으로 합니다. 다만 금융기관 또는 전기통신사업자의 귀책 등
              회사의 귀책사유가 없는 사유로 지급이 늦어질 수 있습니다. <br />
              2. 고객은 용역 및 납품 이행이 완료하였을 때 지체 없이 거래 확정을
              하여 상대방에게 대금이 원활히 지급될 수 있도록 하여야 합니다.
              <br />
              3. 회사는 영업일 오전 9시부터 오후 5시까지 예치금 지급을
              실행합니다. 거래 완료가 휴일 및 업무 시간 외에 진행된 경우 다음
              영업일에 지급이 실행됩니다.
              <br />
              4. 고객이 다음과 같이 정당한 사유 없이 거래 확정 의무를 다하지
              않는 경우 회사는 고객이 거래 확정하였다고 간주하고 대금을 지급하고
              관련 절차를 진행할 수 있습니다.
              <br />
              &nbsp;1) 상대방이 용역 및 납품 이행을 완료하였음을 고지하였음에도
              10일 이상 상대방이나 회사의 연락에 응하지 아니하는 경우
              <br />
              &nbsp;2) 상대방이 용역 및 납품 이행을 완료하여 이를 고지하였고
              고객이 이를 확인하였음에도 고객이 해당 확인 일자로부터 10일 이상
              거래 확정을 하지 아니하는 경우
              <br />
              &nbsp;3) 기타 고객이 정당한 사유 없이 거래확정을 아니하여 회사가
              거래 확정처리를 하는 경우
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 20조 &#40;안전거래서비스에 대한 회사의 면책&#41;</h4>
            <p>
              1. 안전거래서비스는 회원과 고객 간의 거래의 안전과 신뢰성 향상을
              위한 목적에서 회사가 제공하는 서비스의 일환이므로 회사는
              안전거래서비스를 이용하는 양 당사자 간의 거래에 어떠한 책임도 지지
              않습니다. <br />
              2. 회사는 안전거래서비스를 이용하는 회원과 고객 중 어느 일방을
              대리, 대행하거나 그 이행을 보조하는 위치에 있지 아니합니다.
              <br />
              3. 안전거래를 위하여 잘못된 정보를 입력하여 잘못된 대금지급이
              이루어졌을 경우 회사는 이에 대하여 책임지지 않습니다.
              <br />
              4. 회사는 양 당사자 간의 거래에 대하여 책임을 부담하지 아니하며
              특히 고객의 거래 확정으로 거래가 승인된 이후 발생한 분쟁에 대하여
              책임지지 않습니다. 거래 확정 후 대금의 지급이 완료된 이후 발생한
              분쟁 또는 불만 사항은 양 당사자가 협의를 통해 해결해야 합니다.
              <br />
            </p>
          </div>
          <div className="terms_title">제 6장 개인 정보의 처리</div>
          <div className="terms_box">
            <h4>제 21조 &#40;회원의 개인정보 보호&#41;</h4>
            <p>
              1. 회사는 회원의 개인정보를 보호하기 위하여 정보통신망법 및
              개인정보 보호법 등 관계 법령에서 정하는 바를 준수하며 이용자의
              개인 식별이 가능한 개인정보(휴대전화번호, 이메일 주소 등)를
              수집하는 때에는 반드시 당해 이용자의 동의를 받습니다. <br />
              2. 회사의 개인정보 보호는 공새로 개인정보처리방침에 따릅니다.
              <br />
              3. 개인정보 수집에 동의하는 경우 회사는 회원의 개인정보를 수집,
              보관, 이용할 수 있습니다.
              <br />
              4. 회사는 수집된 개인정보 보호를 위해서 최선을 다하여야 합니다.
              <br />
            </p>
          </div>
          <div className="terms_title">제 7장 당사자의 의무</div>
          <div className="terms_box">
            <h4>제 22조 &#40;회사의 의무&#41;</h4>
            <p>
              1. 회사는 회원이 희망한 서비스 제공 개시 일에 특별한 사정이 없는
              한 서비스를 이용할 수 있도록 하여야 합니다. <br />
              2. 회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가
              생기거나 기계의 결함이 있는 때에는 부득이한 사유가 없는 한 지체
              없이 이를 수리 또는 복구합니다. <br />
              3. 회사는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보
              보호정책을 공시하고 준수합니다.
              <br />
              4. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고
              객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야
              합니다. 다만, 즉시 처리가 곤란한 경우는 이용자에게 그 사유와
              처리일정을 통보하여야 합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 23조 &#40;회원의 의무&#41;</h4>
            <p>
              1. 회원은 다음 각 호의 행위를 하여서는 안 됩니다.
              <br />
              2. 회원가입신청 또는 변경 시 허위내용을 등록하는 행위
              <br />
              3. 공새로에 게시된 정보를 허위로 변경하는 행위
              <br />
              4. 회사 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를
              방해하는 행위
              <br />
              5. 다른 회원의 ID를 도용하는 행위
              <br />
              6. 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터
              프로그램 등)의 전송 또는 게시하는 행위
              <br />
              7. 회사의 직원이나 관리자를 가장하거나 사칭하여 또는 타인의 명의를
              오용하여 글을 게시하거나 메일을 발송하는 행위
              <br />
              8. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을
              방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타의 다른
              컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나
              전자우편으로 발송하는 행위
              <br />
              9. 스토킹(stalking) 등 다른 회원을 괴롭히는 행위
              <br />
              10. 다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는
              행위
              <br />
              11. 회사가 제공하는 서비스에 정한 약관 기타 서비스 이용에 관한
              규정을 위반하는 행위
              <br />
              12. 외설 또는 폭력적인 메시지ㆍ화상ㆍ음성 기타 공서양속에 반하는
              정보를 공개 또는 게시하는 행위
              <br />
              13. 사실관계를 왜곡하는 정보제공 등 기타 회사가 부적절하다고
              판단하는 행위
              <br />
              14. 기타 관계 법령이나 회사에서 정한 규정에 위배되는 행위
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 24조 &#40;책임의 귀속&#41;</h4>
            <p>
              1. 회원이 개인의 정보를 허위 또는 잘못 기재하여 생기는 불이익에
              대한 책임은 회원 본인에게 있으며, 이에 대해 회사는 아무런 책임이
              없습니다. <br />
              2. 회사가 사이트 운영상 부적절하다고 판단한 정보가 게시된 경우,
              회사는 게시를 행한 자의 승낙 없이 게재된 당해 정보를 삭제할 수
              있습니다. 단, 회사는 이러한 정보의 삭제 등을 할 의무를 지지
              않습니다.
              <br />
              3. 회사는 회원이 본 약관 또는 당사 정책에 위반되는 내용의 메시지를
              프로필 영역에 작성하거나 타 회원에게 해당 메시지 내용을 발송하는
              경우 별도의 통지 없이 작성 또는 발송된 내용을 임의로 수정 또는
              삭제할 수 있습니다.
              <br />
              4. 제1항에 해당하는 행위를 한 회원이 있을 경우 회사는 본 약관
              제12조, 제21조에서 정한 바에 따라 회원의 회원자격을 적절한
              방법으로 제한 및 정지, 상실시킬 수 있으며, 특히 아래 각 호에
              해당하는 행위를 할 경우에는 각각에 대응되는 조치를 취할 수
              있습니다. 다만, 회사가 취할 수 있는 조치는 아래 각 호에 한정되지
              아니하며, 회사가 조치를 취할 수 있는 회원의 불법적인 행위 역시
              아래 각 호의 경우에 한정되지 아니합니다.
              <br />
              5. 타 회원들에 대한 무단 마케팅(요청서 및 견적에 제공 용역에 대한
              내용 외 본인의 영리적 목적을 위한 내용을 무단으로 작성하는 것을
              의미하며 광고 등을 포함): 1년간 모든 기능 사용 정지 등<br />
              6. 타 회원들에 대한 부적절한 대화 시도 및 메시지 발송 등(욕설,
              성희롱, 기타 부적절한 표현을 포함함): 1년간 모든 기능 사용 정지 등
              <br />
              7. 타 회원들에게 허위사실을 유포하거나 표시광고를 위반하는
              경우(견적에 회사에 상당한 수수료를 지불하고 있어, 절대 채팅으로
              답변하지 말라는 내용을 기재하거나, 명함에 공새로 로고를 무단으로
              표시하고 자신을 공새로 협력사로 소개하는 등을 포함함): 1회 경고 후
              재 적발시 영구 기간 동안 모든 기능 사용 정지(영구 제재) 등<br />
              8. 회원의 의무 및 서비스 정책 위반(프로필에 계속하여 휴대폰 번호
              등 개인정보를 노출, 요청서 또는 견적에 본인 연락처를 작성하여 직접
              연락을 유도하는 등의 경우를 포함함): 1년간 모든 기능 사용 정지 등
              <br />
              9. 회원은 그 귀책사유로 인하여 회사나 다른 회원이 입은 손해를
              배상할 책임이 있습니다.
              <br />
              10. 회원은 서비스의 이용과 관련하여 관계법령, 약관, 세부이용지침,
              서비스 이용안내 및 회사가 통지한 공지사항 등을 준수하여야 하며,
              이를 수시로 확인하여야 합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 25조 &#40;고객의 의무&#41;</h4>
            <p>
              1. 고객은 제공받기 원하는 용역에 대한 내용으로 요청서를 작성하여
              회사에 제공하고 회사가 이를 다수의 회원에게 전달하는 것에 대하여
              동의합니다. <br />
              2. 고객은 허위나 과장된 내용으로 요청서를 작성하거나 또는 용역을
              제공받고자 하는 의사가 없음에도 요청서를 작성할 수 없으며 허위 및
              과장된 내용으로 요청서를 작성함에 따라 용역을 제공받지 못하거나
              법적인 문제가 발생할 경우 이에 대한 책임은 고객에게 있습니다.
              <br />
              3. 고객은 용역을 구매하기 전에 반드시 회원이 견적에 작성한 용역의
              상세 내용과 거래의 조건을 정확하게 확인해야 합니다. 구매하려는
              용역의 내용과 거래의 조건을 확인하지 않고 구매하여 발생한 모든
              손실과 손해는 고객 본인이 부담합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 26조 &#40;회원의 서비스 관련 의무&#41;</h4>
            <p>
              1. 회원은 서비스 및 재화의 제공, 계약내용의 변경, 서비스 및 재화
              제공에 대한 대금 결제 및 환불 등 관련한 일체의 업무를 처리하며,
              이를 신의성실 하게 이행하여야 할 의무가 있습니다. 또한 용역 제공과
              관련하여 발생하는 모든 책임과 의무를 부담합니다. <br />
              2. 회원이 작성한 견적, 프로필 등에 허위 또는 과장, 기재누락,
              오기가 있어서는 아니되며 이를 준수하지 아니하여 발생하는 모든
              법적문제에 대하여는 회원이 모든 책임을 부담합니다. 회원은 해당
              견적 및 제공 용역에 대한 진실성과 적법성에 대해 보증합니다.
              <br />
              3. 회원은 작성한 견적, 용역의 내용, 용역 제공 시기 등 내용의 변경
              사항이 있을 경우 이를 바로 갱신하여야 할 의무를 가지며 잘못
              입력함으로 인해 발생하게 된 계약 불이행에 대해서는 규정에 의해
              제재를 받습니다.
              <br />
              4. 회원은 관련법령 등을 위반하는 불법적인 용역을 제공하지 않는
              다른 점에 대해 보증하며 불법적이거나 본 이용약관을 위반하는
              내용으로 견적 및 허위 내용을 작성하여 고객에게 송부할 수 없습니다.
              <br />
              5. 회원은 견적을 수령한 고객과의 공새로 서비스에서 제공하는 메시지
              기능 혹은 질의답변이나 전화 등 별도의 연결수단을 통하여 세부사항을
              조정할 수 있습니다. 회사는 견적 발송 이후 회원과 고객 간에 발생한
              일체의 문제에 대하여 어떠한 책임도 지지 않습니다.
              <br />
              6. 회원은 잘못된 서비스나 재화 제공으로 인해 발생하는 고객의
              컴플레인에 대하여 신의성실을 다해 대응하며, 이에 대한 대응이
              제대로 이루어지지 않을 경우 플랫폼 사용에 대한 불이익을 받을 수
              있습니다.
              <br />
              7. 회원은 고객이 요청한 견적건에 대하여 조달해야할 품목의 품질과
              규격 특성 등에 대하여 명확하게 인지하고 투찰한다. 모호한 견적
              요청건에 대해서는 요청 고객과의 질의 응답을 통해 확실한 답변을
              받은 뒤 투찰한다.
              <br />
              8. 회사는 회원이 투찰한 단가를 분석하여 적정단가 여부를 분석할 수
              있으며, 특정 품목이 과거 견적가에 비해 일정 수준 이상으로
              상이하거나 지나친 저가 투찰 시 이를 제지할 수 있다. <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 27조 &#40;회원의 ID 및 비밀번호에 대한 의무&#41;</h4>
            <p>
              1. 원칙적으로 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.{" "}
              <br />
              2. 회원은 자신의 ID 및 비밀번호를 제 3자에게 이용하게 해서는 안
              되며, 그로 인한 모든 책임은 회원에게 있습니다. <br />
              3. 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
              있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는
              경우 그에 따라야 합니다. 만약 회원이 위 사실을 인지하고도 회사에
              대한 통보를 지체함으로써 발생한 손해에 대해서 회사는 배상할 의무가
              없습니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 28조 &#40;게시물 또는 내용물의 삭제&#41;</h4>
            <p>
              1. 회원이 회사에 등록하는 게시물 및 타인 게시물의 활용 등으로
              인하여 본인 또는 타인에게 손해나 기타 문제가 발생하는 경우 회원은
              이에 대한 책임을 지게 되며, 회사는 이에 대하여 책임을 지지
              않습니다. <br />
              2. 회사는 회원이 게시하거나 등록한 내용이 제27조 제 1항의 규정에
              위반되거나 회사 소정의 게시기간을 초과하는 경우 사전 통지나 동의
              없이 이를 삭제할 수 있습니다. 그러나 회사가 이러한 정보의 삭제
              등을 할 의무를 지는 것은 아닙니다. <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 29조 &#40;저작권의 귀속 및 권리, 의무&#41;</h4>
            <p>
              1. 회사가 작성한 저작물에 대한 저작권 기타 지식재산권은 회사에
              귀속합니다. <br />
              2. 회원은 회사의 서비스를 이용함으로써 얻은 정보를 회사의 사전승낙
              없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로
              이용하거나 제3자에게 이용하게 하여서는 안 됩니다. <br />
              3. 회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한
              회원에게 있습니다. <br />
              4. 회사는 회원 또는 요청자가 사이트에 게시한 다음 각 호의 게시물을
              회사의 서비스 제공과 관련한 마케팅 용도로
              사용ㆍ복제ㆍ수정ㆍ출판ㆍ배포 할 수 있습니다. 단, 회사는 게시물
              이용 시 회원의 개인정보가 포함되지 않는 범위에서 해당 정보를
              처리하고, 최대한 작성자의 권리를 보호할 수 있도록 노력할 것입니다.{" "}
              <br />
              가. 프로필 정보
              <br />
              나. 요청자 및 작업자가 작성한 후기(이에 포함된 글 및 사진)
              <br />
              다. 요청서 및 견적의 내용
              <br />
              라. 회사와 요청자 또는 작업자 사이의 연락 내용
              <br />
              마. 기타 요청자 및 작업자가 서비스 이용과정에서 작성·게시한 콘텐츠
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 30조 &#40;광고주 및 연결 사이트와의 관계&#41;</h4>
            <p>
              1. 회사의 공식 사이트 이외의 웹사이트 및 이메일에서 링크된
              사이트에서는 회사의 개인정보 보호정책 및 본 약관이 적용되지
              않습니다. <br />
              2. 회사는 공새로 사이트 또는 e-mail 상에 게재되어 있거나 본
              서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는
              거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.{" "}
              <br />
            </p>
          </div>
          <div className="terms_title">제 8장 기타</div>
          <div className="terms_box">
            <h4>제 31조 &#40;양도의 금지&#41;</h4>
            <p>
              회원은 서비스 이용 권리를 타인에게 대여, 양도 또는 증여 등을 할 수
              없으며, 또 한 질권의 목적으로도 사용할 수 없습니다.
            </p>
          </div>
          <div className="terms_box">
            <h4>제 32조 &#40;면책&#41;</h4>
            <p>
              1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
              제공할 수 없는 경우에는 고의 또는 과실이 없는 한 구매자에게 발생한
              손해에 대하여 서비스 제공에 관한 책임이 면제됩니다. <br />
              2. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여
              책임을 지지 않습니다.
              <br />
              3. 회사는 회원이 서비스의 이용을 통해 예상했던 금전적인 수익을
              얻지 못하게 되거나, 서비스를 통하여 얻은 자료로 인해 손해를 보게
              되더라도 이에 관하여 책임을 지지 않습니다. <br />
              4. 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성
              등의 내용에 관하여는, 회사가 해당 정보의 허위성을 명백히
              인지하였다는 특별한 사정이 없는 한, 책임을 지지 않습니다. 회사가
              본 약관과 관련 법령에 따른 조치를 취한 경우에도 같습니다. <br />
              5. 회사는 서비스 이용과 관련하여 회사의 고의, 과실에 의하지 아니한
              손해에 대하여 책임을 지지 않습니다.
              <br />
              6. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나
              정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
              <br />
              7. 회사는 회원들에게 요청서와 견적을 전달하거나 광고플랫폼 또는
              안전거래서비스를 제공하고 이에 대한 부수적인 서비스를 제공할
              뿐이므로 회원 상호 간 서비스를 매개로 하여 거래를 한 경우 이용자
              각자의 의무이행에 대한 책임은 각 이용자에게 있으며 회사는 이에
              대한 책임이 없습니다.
              <br />
              8. 회사는 회원들에게 요청서와 견적을 전달하거나 광고플랫폼 또는
              안전거래서비스를 제공하고 이에 대한 부수적인 서비스를 제공할
              뿐이므로 회원과 요청자 간 거래와 관련하여 발생한 의무의 불완전
              이행, 이행 지체 등 용역 이행 미비, 사후처리, 대금 정산, 완성품의
              하자, 청약철회, 물품의 반품 등 어떠한 법적 분쟁 및 사후처리에
              대해서도 개입하거나 책임지지 않습니다.
              <br />
              9. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한
              규정이 없는 한 책임을 지지 않습니다.
              <br />
              10. 회사는 회원 간에 분쟁이 발생하였을 경우 고의 또는 중과실이
              없는 한 회원 간 법적 문제에 대하여 책임을 지지 않습니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 33조 &#40;손해배상&#41;</h4>
            <p>
              당사자 일방이 본 계약상 의무를 위반함으로 인하여 상대방에게 손해가
              발생한 경 우, 귀책사유 있는 당사자는 상대방이 입은 손해를
              배상합니다.
            </p>
          </div>
          <div className="terms_box">
            <h4>제 34조 &#40;비밀유지&#41;</h4>
            <p>
              1. 당사자는 서비스를 이용하는 과정에서 알게 된 상대방의 정보 또는
              이용자의 정보를 제3자에게 누설, 공개하지 아니합니다.
              <br />
              2. 본 조는 이용계약이 해지되거나 서비스 제공이 중단된 이후에도
              유효합니다.
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 35조 &#40;고객센터&#41;</h4>
            <p>
              1. 회사는 이용자에게 생긴 문제를 해결하기 위해 최선을 다하며 이를
              위하여 이용자로부터 고객센터에 제출되는 불만사항 및 의견은
              우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한
              경우에는 이용자에게 그 사유와 처리일정을 즉시 통보합니다. <br />
              2. 회사와 이용자 간 분쟁이 발생할 경우 양자 간 합의에 의하여
              처리하는 것을 원칙으로 하며 회사는 이용자 간 발생한 법적 문제에
              대하여 고의 또는 중과실이 없는 한 책임이 없습니다. 그러나 회사는
              고객센터를 통하여 피해 사항을 적극적으로 청취하여 이용자들 간
              발생한 문제가 해결하는 데에 도움이 될 수 있도록 최선을 다합니다.{" "}
              <br />
            </p>
          </div>
          <div className="terms_box">
            <h4>제 36조 &#40;재판권 및 준거법&#41;</h4>
            <p>
              1. 회사와 이용자간 발생한 분쟁에 관한 소송의 관할은 민사소송법에
              따라 정합니다. <br />
              2. 회사와 이용자 간에 제기된 소송에는 대한민국법을 적용합니다.{" "}
              <br />
            </p>
          </div>
          <div>
            <h4>부칙</h4>
            <br />
            <br />
            <h4>본 약관은 2022년 5월 1일부터 시행합니다.</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
