import { useState } from "react";
import useFetchMyPageInfo from "../useQuery/useFetchMyPageInfo";
import { useSelector } from "react-redux";

const useMyPageHandler = () => {
  const user = useSelector((state) => state.user);
  const isDemo = user?.isDemo;
  const { data: myPage } = useFetchMyPageInfo();
  const myPageInfo = myPage?.data;

  const [switchTypeModal, setSwitchTypeModal] = useState(false);

  const [myInfoChangeModal, setMyInfoChangeModal] = useState(false);
  const [rankUpdateModal, setRankUpdateModal] = useState(false);
  const [pwdUpdateModal, setPwdUpdateModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);

  const [siteAttendModal, setSiteAttendModal] = useState(false);
  const [siteSwitchModal, setSiteSwitchModal] = useState(false);

  const [siteAttendApproveModal, setSiteAttendApproveModal] = useState(false);
  const [siteAttendRejectModal, setSiteAttendRejectModal] = useState(false);

  const [siteInviteModal, setSiteInviteModal] = useState(false);

  return {
    isDemo,
    myPageInfo,
    switchTypeModal,
    setSwitchTypeModal,
    myInfoChangeModal,
    setMyInfoChangeModal,
    rankUpdateModal,
    setRankUpdateModal,
    pwdUpdateModal,
    setPwdUpdateModal,
    withdrawModal,
    setWithdrawModal,
    siteAttendModal,
    setSiteAttendModal,
    siteSwitchModal,
    setSiteSwitchModal,
    siteAttendApproveModal,
    setSiteAttendApproveModal,
    siteAttendRejectModal,
    setSiteAttendRejectModal,
    siteInviteModal,
    setSiteInviteModal,
  };
};

export default useMyPageHandler;
