import React, { useState, useEffect, useCallback } from "react";
//library
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
//component
import CompanyPop from "../../../component/common/Pop/CompanyPop";
import ContractPop from "../../../component/common/Pop/ContractPop";
import Mheader from "../../../component/common/Mheader";
import EstimateCancelPop from "../../../component/common/Pop/EstimateCancelPop";
import ExcelPreviewPop from "../../../component/common/Pop/ExcelPreviewPop";
import Loading from "../../../component/etc/Loading";
//css
import "../../../asset/css/estimatedetail.css";
import * as S from "../../../asset/css/estimateDetail.styles.js";
//etc
import i_refresh from "../../../asset/images/w-refresh-icon.png";
import i_cahat from "../../../asset/images/m-chat-icon.png";

//sweet alert
import swal from "sweetalert";

//redux
import { useSelector } from "react-redux";

import {
  estimateBidExtendApi,
  estimateComInfoApi,
  generateCompareExcel,
  attendChat,
  estimateDetail2Api,
  fetchBidStatusListApi,
  sendBidRequestMessageApi,
  bidFailApi,
} from "../../../api/auth";
import moment from "moment";
import BidStatusPop from "../../../component/common/Pop/bidStatusPop.js";
import ReBidPop from "../../../component/common/Pop/ReBidPop.js";
import Button from "../../../component/ui/button/Button";
import Typography from "../../../component/ui/typography/Typography";
import Modal from "../../../component/ui/modal/Modal";
import useScrollFixed from "../../../hooks/useScrollFixed.js";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

export default function EstimateDetail() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const pushToken = useSelector((state) => state.push);

  const [reBidOpen, setReBidOpen] = useState(false);
  const openReBidModal = () => setReBidOpen(true);
  const closeReBidModal = () => setReBidOpen(false);

  const [alertReBidOpen, setAlertReBidOpen] = useState(false);
  const openAlertReBidModal = () => setAlertReBidOpen(true);
  const closeAlertReBidModal = () => setAlertReBidOpen(false);

  const [bidFailOpen, setBidFailOpen] = useState(false);
  const openBidFailModal = () => setBidFailOpen(true);
  const closeBidFailModal = () => setBidFailOpen(false);

  const params = useParams();
  const navigate = useNavigate();

  const [detailPop, setDetailPop] = useState(false);
  const [contractPop, setContractPop] = useState(false);
  const [comList, setComList] = useState([]);
  const [lowestCompany, setLowestCompany] = useState(true);
  const [reason, setReason] = useState({
    re1: "",
    re2: "",
  });
  const [pList, setpList] = useState([]);
  const location = useLocation();
  // const [bidNo, setBidNo] = useState(location.state?.bidNo);
  const bidNo = params.id;
  const [detailData, setDetailData] = useState({});
  const [extendTime, setExtendTime] = useState();
  const [endDate, setEndDate] = useState();
  const [prsCompany, setPrsCompany] = useState(0);
  const [comDetail, setComDetail] = useState({});
  const [index, setIndex] = useState(0);
  const [lowestState, setLowestState] = useState([]);
  const [lowestIndex, setLowestIndex] = useState(0);
  const [cancelPop, setCancelPop] = useState(false);
  const [estimateNum, setEstimateNum] = useState(0);

  //doc
  const [generating, setGenerating] = useState(false);
  const [excelPop, setExcelPop] = useState(false);
  const [excelUrl, setExcelUrl] = useState(false);
  const [imgList, setImgList] = useState([]);

  //estimate company info api
  const estimateComFnc = async (data) => {
    const response = await estimateComInfoApi(data);
    if (response.status === "success") {
      setComDetail(response.data.companyInfo);
    } else {
      swal("오류", "", "error");
    }
  };

  //estimate bid api
  const bidExtendFnc = async () => {
    const response = await estimateBidExtendApi(bidNo, endDate);

    if (response.status === "success") {
      setExtendTime(response.data.bidTime);
      setEndDate(response.data.bid_endate);
    }
  };

  //estimate bidDrop
  const bidDrop = (data) => {
    if (data === "L") {
      return "하차역무 있음 ⭕️";
    } else if (data === "U") {
      return "하차역무 없음 ❌";
    }
  };

  //금액
  const numberWithCommas = (x) => {
    if (x != undefined) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const estimateDetailFnc2 = async () => {
    const response = await estimateDetail2Api(bidNo);

    if (response.status === "success") {
      const endate = moment(response.data.bid?.endate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      setDetailData(response.data);
      // setpList(response.data.bidProductList);
      setpList(
        response.data?.bidProductList.map((item, index) => ({
          ...item,
          arr: response.data.offerDetailInfoList
            ? response.data?.offerDetailInfoList?.map(
                (oItem, subIndex) => oItem?.companyPriceInfoList[index]
              )
            : [],
        }))
      );
      setComList(response.data.offerDetailInfoList);
      const arr = response.data?.offerDetailInfoList?.filter(
        (item) => item.companyName === response.data?.lowestCompanyName
      );

      setLowestState(arr);
      const arr2 = response.data?.offerDetailInfoList?.map((item, index) =>
        item.companyName === response.data?.lowestCompanyName ? index : null
      );

      setLowestIndex(arr2);
      setExtendTime(response.data.bidTime);
      setEndDate(endate);
      setImgList(response.data?.bidImgInfoList);
    }
  };

  useEffect(() => {
    estimateDetailFnc2();
  }, []);

  const _generateCompareExcel = async (type) => {
    setGenerating(true);

    const data = {
      bid_no: bidNo,
    };

    const response = await generateCompareExcel(data);
    if (response.status === "success") {
      if (type === "download") {
        const link = document.createElement("a");
        link.href = response.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // window.open(response.data);
      } else {
        setExcelUrl(response.data);
        setExcelPop(true);
      }
    }
    setGenerating(false);
  };

  const _attendChat = async () => {
    const reqData = {
      bidNo: bidNo,
    };

    const response = await attendChat(reqData);

    if (response.status === "success") {
      navigate("/qna", {
        state: {
          bidRoomNo: response.data.chatRoom.no,
          selectedChatItem: response.data,
        },
      });
    }
  };

  // 투찰 현황 확인
  const [bidStatusPop, setBidStatusPop] = useState(false);
  const [bidStatusList, setBidStatusList] = useState();
  const openBidStatusPop = () => {
    setBidStatusPop(true);
  };

  const closeBidStatusPop = () => {
    setBidStatusPop(false);
  };

  const fetchBidStatusList = useCallback(async () => {
    try {
      const response = await fetchBidStatusListApi(detailData?.bid?.no);
      if (response.status === "success") {
        setBidStatusList(response.data.bidViewInfoList);
      }
    } catch (error) {
      console.error(error);
    }
  }, [detailData?.bid?.no]);

  useEffect(() => {
    if (bidStatusPop) {
      fetchBidStatusList();
    }
  }, [bidStatusPop, fetchBidStatusList]);

  const onClickSendBidRequestMessage = async (companyNoList) => {
    try {
      const response = await sendBidRequestMessageApi({
        bidNo: detailData?.bid?.no,
        companyNoList: companyNoList,
      });
      if (response.status === "success") {
        swal({
          title: "투찰 요청 알림이 발송되었습니다.",
          icon: "success",
        }).then((value) => {
          if (value) {
            fetchBidStatusList();
          }
        });
      } else if (response.status === "fail") {
        swal("알림 전송이 실패하였습니다.", "", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 입찰 유찰
  const bidFail = async (bidNo, reason) => {
    try {
      await bidFailApi(bidNo, reason);
      // 얼럿띄우기
      openBidFailModal();
      // 다시 api 실행
      estimateDetailFnc2();
      // 모달 끄기
      closeReBidModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOkClick = () => {
    const value = document.querySelector(".swal-content__textarea").value;

    if (!value) {
      swal("유찰 사유를 입력해주세요.", "", "warning");
    } else {
      bidFail(detailData?.bid?.no, value);
      swal({
        title: "해당 입찰 건이 유찰되었습니다.",
        text: `${value}`,
        icon: "success",
      }).then((value) => {
        if (value) estimateDetailFnc2();
      });
    }
  };

  const handleCancelClick = () => {
    swal("입력이 취소되었습니다.", "", "info");
  };

  const onClickOpenBidFailModal = () => {
    try {
      swal({
        title: "유찰 사유 입력",
        icon: "info",
        buttons: {
          cancel: {
            text: "취소",
            value: null,
            visible: true,
          },
          confirm: {
            text: "확인",
            value: true,
            visible: true,
          },
        },
        closeOnClickOutside: false,
        content: {
          element: "textarea",
          attributes: {
            placeholder: "사유를 입력해주세요.",
            rows: 4,
            style: "resize:none",
          },
        },
      }).then((value) => {
        if (value) handleOkClick();
        if (!value) handleCancelClick();
      });
    } catch (error) {
      console.error(error);
    }
  };

  // 재입찰(re bid)
  const [reBidPop, setReBidPop] = useState(false);

  // 재공고(re est)
  const reEst = async () => {};

  const [reBidComment, setReBidComment] = useState("");
  const handleCommentChange = (e) => {
    const value = e.target.value;

    if (value.length <= 300) {
      setReBidComment(value);
    } else {
      const truncatedText = value.substring(0, 300);
      setReBidComment(truncatedText);
    }
  };

  const [selectedRadio, setSelectedRadio] = useState(-1);

  const options = [
    {
      id: 0,
      title: "유찰",
      description:
        "본 입찰 공고를 유찰 시킵니다.\n특정 사유로 구매 의사가 없어진 경우 진행할 수 있습니다.",
    },
    {
      id: 1,
      title: "재입찰",
      description:
        "기존 공고는 유찰되며, 품목의 변동 없이 새로운 입찰을 다시 진행합니다.\n납품 기한의 변동으로 동일한 조건에서 다시 입찰을 받고 싶은 경우 선택합니다.",
    },
    {
      id: 2,
      title: "재공고 입찰",
      description:
        "품목 및 조건을 변경하여 입찰을 다시 진행하는 방식입니다.\n품목 상의 변동나 납품 조건 등 모든 내용의 변동이 필요한 경우 선택합니다.",
    },
  ];

  const handleRadioChange = (index) => {
    setSelectedRadio(index);
  };

  const navigateToBid = (path, state) => {
    navigate(path, { state });
  };

  const getBidImgList = (bidImgInfoList) => {
    return bidImgInfoList?.map((item) => ({
      fileIndex: item.fileNo,
      fileNo: item.fileNo,
      fileName: item.fileName,
      filePath: item.fileRoot,
    }));
  };

  const validateReBidModal = () => {
    if (selectedRadio === -1 || reBidComment === "") {
      return openAlertReBidModal();
    }

    const commonState = {
      comment: detailData.bid?.comment, // 투찰시 사용자가 입력한 코멘트
      reBidComment: reBidComment, // 재견적할 때 쓴 코멘트
      bidProductList: pList,
      bidImgList: getBidImgList(detailData.bidImgInfoList),
      choiceOrderMethod:
        detailData.bid.type === "S" ? "일회성입찰공고" : "단가계약입찰공고",
      previousBidNo: bidNo, // rebid
    };

    switch (selectedRadio) {
      case 0: // 유찰
        bidFail(detailData?.bid?.no, reBidComment);
        break;
      case 1: // 재입찰
        navigateToBid("/management/bid/request_input_info", {
          ...commonState,
          extraType: "BID", // rebid
        });
        break;
      case 2: // 재공고입찰
        navigateToBid("/management/bid/request_excel", {
          ...commonState,
          extraType: "ESTIMATE", // rebid
        });
        break;
      default:
        break;
    }
  };

  const comparativeDownload = () => {
    if (detailData?.offerCount === 0) {
      swal("현재 입찰에 참여한 업체가 없습니다.", "", "info");
    } else {
      //TODO
      if (pushToken && pushToken.type && pushToken.value) {
        swal(
          "비교견적서 다운로드 기능은 현재 모바일/PC 웹에서 가능합니다. 곧 업데이트 예정입니다.",
          "",
          "info"
        );
      } else {
        _generateCompareExcel("download");
      }
    }
  };

  const { isFixed } = useScrollFixed(isDesktop ? 200 : 300);

  return (
    <>
      <div className="estimate_ok_wrap" style={{ paddingTop: 0 }}>
        <Mheader title="입찰 내역 확인" />
        <div className="estimate_ok_container" style={{ paddingTop: "30px" }}>
          <div className="est_ok_tit">
            <h2>입찰 내역 확인</h2>
            {/* {bidBtn(detailData?.bid?.state)} */}

            <div className="quote_qna_btn" onClick={_attendChat}>
              <span>
                <img src={i_cahat} alt="" />
              </span>
              <p>해당 입찰 공고 질의응답 바로가기</p>
            </div>
          </div>

          <div className="contract_content">
            <div className="content_top">
              <span>입찰번호 :&nbsp;</span>
              <span className="public_code">{detailData.bid?.code}</span>
              <span className="num_line">|</span>
              <span>{detailData.bid?.createdDate}</span>
            </div>
            <div className="content_box">
              <div className="content_btm">
                <p className="company_img">
                  <img src={detailData?.constructionLogo} alt="" />
                </p>
                <div className="contract_info">
                  <div className="icon_box">
                    <S.Badge el={detailData}>
                      {detailData?.bid?.state === "ALIVE" && "입찰진행중"}
                      {detailData?.bid?.state === "CHOOSE" && "투찰마감"}
                      {detailData?.bid?.state === "END" && "선정완료"}
                      {detailData?.bid?.state === "CANCEL" && "입찰취소"}
                      {detailData?.bid?.state === "FAIL" && "유찰"}
                    </S.Badge>
                    {detailData?.bid?.extraType !== null && (
                      <S.ReBadge>
                        {detailData?.bid?.extraType === "REBID" && "재입찰"}
                        {detailData?.bid?.extraType === "REESTIMATE" &&
                          "재공고입찰"}
                      </S.ReBadge>
                    )}
                  </div>
                  <p className="contractor">
                    <span>
                      {"[" +
                        detailData?.constructionName +
                        "] " +
                        detailData?.siteName}
                    </span>
                    <span>
                      {detailData.bid?.type === "C"
                        ? "단가 계약 입찰 (" + detailData.bid?.term + "개월)"
                        : "일회성 입찰"}
                    </span>
                  </p>
                  <div className="contract_text">
                    <p>
                      투찰 참여업체 : {detailData?.offerDetailInfoList?.length}
                      개사
                    </p>

                    {detailData.bid?.state === "ALIVE" && (
                      <>
                        <p className="bidding_time">
                          남은 투찰시간 : {extendTime}{" "}
                          <div className="extension" onClick={bidExtendFnc}>
                            <span>1시간 연장</span>
                            <span className="i_refresh">
                              <img src={i_refresh} alt="" />
                            </span>
                          </div>
                        </p>
                        <p className="bidding_time">
                          투찰 마감일 : {endDate}까지
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quote_content">
            <h3 className="s_tit">
              <p>상세정보</p>
            </h3>
            <div className="quote_list_box">
              <ul className="quote_list">
                <li>
                  <p>고객사</p>
                  <span>
                    {"[" +
                      detailData?.constructionName +
                      "] " +
                      detailData?.siteName}
                  </span>
                </li>
                <li>
                  <p>현장주소</p>
                  <span>
                    {detailData.bid?.addr1}, {detailData.bid?.addr2}
                  </span>
                </li>
                <li>
                  <p>공급사</p>
                  {detailData.bid?.state === "END" ? (
                    <span>
                      {/* idk */}
                      {detailData?.lowestCompanyName}
                    </span>
                  ) : (
                    <span>미정</span>
                  )}
                </li>

                {detailData.bid?.type === "C" ? (
                  <li>
                    <p>계약기간</p>
                    <span>계약 체결일로부터 {detailData.bid?.term}개월</span>
                  </li>
                ) : null}
              </ul>
              <ul className="quote_list">
                {detailData.bid?.type !== "C" ? (
                  <li>
                    <p>납품기한</p>
                    <span>
                      {moment(detailData.bid?.deldate).format(
                        "YYYY-MM-DD HH:mm까지"
                      )}
                    </span>
                  </li>
                ) : null}
                <li>
                  <p>배송방법</p>
                  <span>{bidDrop(detailData.bid?.drop)}</span>
                </li>
                <li>
                  <p>결제방법</p>
                  <span>{detailData.bid?.refund}</span>
                </li>
                {detailData.bid?.type === "C" ? (
                  <li>
                    <p>결제주기</p>
                    <span>
                      납품완료일(세금계산서 발행일)로부터{" "}
                      {detailData.bid?.payday}일
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          <div className="bidding_content">
            <h3 className="s_tit flex">
              <p>
                투찰현황 <span>{detailData.bid?.offerCount}</span>
              </p>

              {detailData.bid?.state === "ALIVE" && (
                <Button size="medium" onClick={openBidStatusPop}>
                  투찰현황확인
                </Button>
              )}
              {detailData.bid?.state !== "ALIVE" && (
                <Button
                  size="medium"
                  type="secondary"
                  onClick={comparativeDownload}
                >
                  비교견적서 다운로드
                </Button>
              )}
            </h3>
            <div className="bid_zero_dn">
              <div className="bidding_table">
                <table className="bid_prd">
                  <tbody>
                    <tr>
                      <th>
                        <p className="name">품목명</p>
                      </th>
                      <th>
                        <p className="standard">규격</p>
                      </th>
                      <th>
                        <p className="unit" style={{ lineHeight: "1.3" }}>
                          제조국
                          <br />
                          (브랜드)
                        </p>
                      </th>
                      <th>
                        <p className="unit">단위</p>
                      </th>
                      <th>
                        <p className="cnt">수량</p>
                      </th>
                      {comList?.map((item) => (
                        <th>
                          <p className="cnt">{item.companyName}</p>
                        </th>
                      ))}
                    </tr>
                    <tr>
                      <td colSpan="5"></td>
                      {comList?.map((item, i) => (
                        <td>
                          <div className="flex gap4 flex-col">
                            {detailData.bid?.state === "CHOOSE" &&
                              item.isLowPrice && (
                                <Button
                                  size="medium"
                                  type="primary"
                                  onClick={() => {
                                    navigate(
                                      "/management/estimate/conclude_contract/" +
                                        item.offerNo,
                                      {
                                        state: {
                                          detailData: detailData,
                                          prsCompany: item.offerNo,
                                          index: i,
                                          selectCompany: item,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  {detailData.bid?.type === "C"
                                    ? "계약하기"
                                    : "주문하기"}
                                </Button>
                              )}
                            <Button
                              size="medium"
                              type="secondary"
                              onClick={() => {
                                setDetailPop(true);
                                estimateComFnc(item.companyNo);
                              }}
                            >
                              업체정보
                            </Button>
                          </div>
                        </td>
                      ))}
                    </tr>
                    {pList.map((data, i) => (
                      <tr key={data.no}>
                        <td>{data.name}</td>
                        <td>
                          <pre className="p_list_standard">
                            {data.standard?.replace(/, /gi, "\n")}
                          </pre>
                        </td>
                        <td>{data.brand}</td>
                        <td>{data.unit}</td>
                        <td>{data.count.toLocaleString("ko")}</td>
                        {data?.arr?.map((item) =>
                          detailData.bid?.state !== "ALIVE" ? (
                            <td>{numberWithCommas(item?.price)}</td>
                          ) : (
                            <td>투찰 마감 후 공개</td>
                          )
                        )}
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="5" className="total">
                        합계
                      </td>
                      {comList?.map((item, idx) =>
                        detailData.bid?.state !== "ALIVE" &&
                        detailData.bid?.state !== "CANCEL" ? (
                          <td className="total">
                            {numberWithCommas(item?.totalPrice)}
                            {idx === comList.length - 1 && !item.isLowPrice && (
                              <Typography
                                component="p"
                                type="c1"
                                color="var(--sub-red-10)"
                              >
                                최고가
                              </Typography>
                            )}
                            {item.isLowPrice && (
                              <Typography
                                component="p"
                                type="c1"
                                color="var(--sub-blue-10)"
                              >
                                최저가
                              </Typography>
                            )}
                            {/* <div
                              style={{ color: "#ff6600", fontWeight: "500" }}
                            >
                             
                            </div> */}

                            <Typography
                              component="p"
                              type="c1"
                              color="var(--sub-red-10)"
                            >
                              {`(최고가 대비 ${(
                                (item.totalPrice /
                                  comList[comList.length - 1].totalPrice) *
                                100
                              ).toFixed()}%)`}
                            </Typography>
                          </td>
                        ) : detailData.bid?.state === "CANCEL" ? (
                          <td>취소된 공고</td>
                        ) : (
                          <td>투찰 마감 후 공개</td>
                        )
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="uniqueness_box">
                <h3 className="uniqueness_constructor">
                  <p>건설사</p>
                </h3>
                <div className="uniqueness">
                  <div className="uniqueness_tit">
                    <p>
                      <span>공고 관련</span>
                      <span>추가 내용</span>
                    </p>
                  </div>
                  <div className="uniqueness_content contract_additions">
                    <p>{detailData.bid?.comment}</p>
                  </div>
                </div>
                {imgList && (
                  <div className="uniqueness_attachments">
                    {imgList.map((item) => (
                      <ul className="uniqueness_attachments_list">
                        <li>
                          <div className="uniqueness_attachments_file_down">
                            <p>
                              <a
                                href={item.fileRoot}
                                target="_blank"
                                rel="noreferrer"
                              >
                                내가 첨부한 파일 보기
                              </a>
                            </p>
                          </div>
                          <p>{item.fileName}</p>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </div>

              {detailData?.offerDetailInfoList?.length > 0 && (
                <div className="uniqueness_box">
                  <h3 className="uniqueness_company">
                    <p>공급사</p>
                  </h3>
                  {detailData?.offerDetailInfoList?.map((data) => (
                    <>
                      <div className="uniqueness">
                        <div className="uniqueness_tit">
                          <p>
                            <span>{data.companyName}</span>특이사항
                          </p>
                        </div>
                        <div className="uniqueness_content">
                          <p>{data.offerComment}</p>
                        </div>
                      </div>
                      {data?.offerFileUrl && (
                        <div className="uniqueness_attachments">
                          <ul className="uniqueness_attachments_list">
                            <li>
                              <div className="uniqueness_attachments_file_down">
                                <p>
                                  <a
                                    href={data?.offerFileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {data.companyName} 첨부 파일
                                  </a>
                                </p>
                              </div>
                              <p>{data?.offerFileName}</p>
                            </li>
                          </ul>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}

              {detailData.bid?.state === "FAIL" && (
                <div className="fail_container">
                  <h3 className="fail_title">
                    <p>유찰 사유</p>
                    {detailData.nextBidCode && (
                      <a
                        href={`/management/estimate/document/${detailData.nextBidNo}`}
                      >
                        ( {detailData.nextBidCode} {detailData.nextBidType} )
                      </a>
                    )}
                  </h3>

                  <div className="fail_box">
                    <div className="fail_left_box">
                      <p>
                        <span>사유</span>
                      </p>
                    </div>
                    <div className="fail_content">
                      <p>{detailData?.bid?.selectReason}</p>
                    </div>
                  </div>
                </div>
              )}

              {detailData.bid?.state === "ALIVE" ? (
                <p className="caution_txt">
                  *고객사 및 공급사의 일방적인 공고 취소 및 중단에 따른 패널티가
                  발생할 수 있습니다. 공고 취소 및 중단 시 상호간의 협의 후
                  진행해주세요.
                  <span
                    className="contract_pause_request"
                    onClick={() => {
                      setCancelPop(true);
                      setEstimateNum(detailData.bid?.no);
                    }}
                  >
                    공고 취소하기
                  </span>
                </p>
              ) : null}
            </div>
          </div>

          {detailData.bid?.state === "CHOOSE" && (
            <div
              className={classNames("re_estimate_btn_group", {
                desktop_fixed: isDesktop && isFixed,
                mobile_fixed: isMobile && isFixed,
              })}
            >
              <Button
                size="medium"
                type="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                목록으로
              </Button>
              <Button size="medium" type="primary" onClick={openReBidModal}>
                유찰 및 재입찰
              </Button>
            </div>
          )}
        </div>
        {detailPop && (
          <CompanyPop setDetailPop={setDetailPop} comDetail={comDetail} />
        )}
        {contractPop && (
          <ContractPop
            setContractPop={setContractPop}
            setReason={setReason}
            reason={reason}
            detailData={detailData}
            detailData2={detailData}
            prsCompany={prsCompany}
            index={index}
          />
        )}
        {cancelPop && (
          <EstimateCancelPop
            setCancelPop={setCancelPop}
            estimateNum={estimateNum}
          />
        )}
        {excelPop && (
          <ExcelPreviewPop setExcelPop={setExcelPop} url={excelUrl} />
        )}
        {bidStatusPop && (
          <BidStatusPop
            bidStatusList={bidStatusList}
            onClickSendBidRequestMessage={onClickSendBidRequestMessage}
            closeBidStatusPop={closeBidStatusPop}
          />
        )}
        {reBidPop && (
          <ReBidPop
            detailData={detailData}
            bidNo={bidNo}
            setReBidPop={setReBidPop}
            estimateDetailFnc2={estimateDetailFnc2}
          />
        )}
      </div>

      {generating && <Loading msg={"비교견적서 생성중입니다."} />}
      <Modal
        size="small"
        type="success"
        open={reBidOpen}
        title={
          <Typography component="p" type="h4" color="var(--sub-blue-05)">
            유찰 및 재입찰
          </Typography>
        }
        closeIcon={false}
        onOk={validateReBidModal}
        onCancel={openReBidModal}
        footer={[
          <Button type="danger" onClick={closeReBidModal}>
            취소
          </Button>,
          <Button type="primary" onClick={validateReBidModal}>
            확인
          </Button>,
        ]}
      >
        <div className="re_bid_modal_container">
          <div className="re_bid_modal_sub_title">
            <Typography component="p" type="h4">
              유찰 및 재입찰 방법을 선택 후, 사유를 입력해주세요.
            </Typography>
          </div>

          <div className="re_bid_modal_contents">
            <div>
              {options.map((option, i) => (
                <div className="re_bid_modal_elem" key={i}>
                  <input
                    type="radio"
                    id={`radio${i}`}
                    checked={selectedRadio === i}
                    onChange={() => handleRadioChange(i)}
                  />
                  <label
                    htmlFor={`radio${i}`}
                    className={selectedRadio === i ? "active_elem" : ""}
                  >
                    <div className="re_bid_modal_elem_title">
                      <Typography component="p" type="h4">
                        {option.title}
                      </Typography>
                    </div>
                    <Typography component="p" type="c2">
                      {option.description.split("\n").map((line, idx) => (
                        <div key={idx}>
                          {line}
                          <br />
                        </div>
                      ))}
                    </Typography>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="re_bid_modal_text_container">
          <Typography component="p" type="h5">
            사유입력
          </Typography>

          <div
            className="re_bid_modal_textarea_container textarea_div"
            spellCheck="false"
          >
            <textarea
              placeholder="추가 설명이 있으시다면 작성해주세요."
              onChange={handleCommentChange}
              value={reBidComment}
            ></textarea>
            <div className="add_text_alert_container">
              <span className="add_alert_desc">
                * 300자 이내로 작성해 주세요.
              </span>
              <span className="add_alert_typing">
                {reBidComment.length} / 300
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        size="xsmall"
        type="warning"
        open={alertReBidOpen}
        closeIcon={false}
        title={
          <Typography component="p" type="h4" color="var(--sub-yellow-10)">
            유찰 및 재입찰 방법과 사유를 입력해주세요.
          </Typography>
        }
        onOk={closeAlertReBidModal}
        onCancel={closeAlertReBidModal}
        footer={[
          <Button type="primary" onClick={closeAlertReBidModal}>
            확인
          </Button>,
        ]}
      >
        <Typography component="p" type="c2" color="var(--sub-gray-09)">
          유찰, 재입찰, 재공고 입찰 중 한가지를 선택하신 후, 사유를 입력해주시기
          바랍니다.
        </Typography>
      </Modal>
      <Modal
        size="xsmall"
        type="success"
        open={bidFailOpen}
        closeIcon={false}
        title={
          <Typography component="p" type="h2" color="var(--sub-blue-05)">
            유찰 완료
          </Typography>
        }
        onOk={closeBidFailModal}
        onCancel={closeBidFailModal}
        footer={[
          <Button type="primary" onClick={closeBidFailModal}>
            확인
          </Button>,
        ]}
      >
        <Typography component="p" type="h4" color="var(--sub-gray-90)">
          본 입찰 건이 아래 사유로 유찰되었습니다.
        </Typography>
        <br />
        <Typography component="p" type="c2" color="var(--sub-gray-09)">
          {reBidComment}
        </Typography>
      </Modal>
    </>
  );
}
