import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const addHyphenToPhone = (phoneNumber) => {
  return phoneNumber
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

const PhoneInput = ({ control, errors, phoneInputDisable }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="phone"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          type="text"
          width={isDesktop ? 280 : "70rem"}
          id="phone"
          placeholder="휴대폰 번호를 입력해주세요."
          onChange={(e) => {
            const formattedPhoneNumber = addHyphenToPhone(e.target.value);
            field.onChange(formattedPhoneNumber);
          }}
          disabled={phoneInputDisable}
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                휴대폰 번호 입력{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={
            errors.phone && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.phone.message}
              </Typography>
            )
          }
          status={errors.phone && "error"}
        />
      )}
    />
  );
};

export default PhoneInput;
