import { Divider } from "antd";
import Typography from "../../../../../../../component/ui/typography/Typography";
import MyInfoChangeButton from "../../button/MyInfoChangeButton";

import s from "./myInfo.module.css";
import { COLOR } from "../../../../../../../constant/Palette";

import formatPhoneNumber from "../../../../../../../util/formatPhoneNumber";

const MyInfo = ({ my, setMyInfoChangeModal, isDemo }) => {
  return (
    <div className={s.container}>
      <div className={s.title}>
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          나의 정보
        </Typography>
        {!isDemo && (
          <MyInfoChangeButton setMyInfoChangeModal={setMyInfoChangeModal} />
        )}
      </div>

      <Divider className={s.divider} />

      <div className={s.info_container}>
        <div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              이름
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {my?.name || "-"}
            </Typography>
          </div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              회사
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {my?.organizationName || "-"}
            </Typography>
          </div>
        </div>

        <div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              부서
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {my?.department || "-"}
            </Typography>
          </div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              직급
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {my?.rank || "-"}
            </Typography>
          </div>
        </div>

        <div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              이메일
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {my?.id || "-"}
            </Typography>
          </div>
          <div>
            <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
              연락처
            </Typography>
            <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
              {formatPhoneNumber(my?.phone) || "-"}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInfo;
