import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const Title = () => {
  return (
    <Typography component="p" type="h1" color={COLOR.GRAY.L09}>
      회원가입
    </Typography>
  );
};

export default Title;
