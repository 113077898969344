import { useMutation, useQueryClient } from "@tanstack/react-query";
import { withdrawApi } from "../../api/auth";
import { QUERY_KEYS } from "../../constant/QueryKeys";

const useWithdraw = (onSuccessCb, onErrorCb) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: withdrawApi,
    onSuccess: (res) => {
      onSuccessCb(res);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WITHDRAW] });
    },
    onError: (error) => {
      onErrorCb(error);
    },
  });
};

export default useWithdraw;
