import Button from "../../../../../component/ui/button/Button";
import Modal from "../../../../../component/ui/modal/Modal";
import Typography from "../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../constant/Palette";

const LoginPartnerModal = ({ loginPartnerModal, setLoginPartnerModal }) => {
  const moveToPartnerPage = () => {
    window.open("https://partner.gongsaero.com/login");
    setLoginPartnerModal(false);
  };

  const onClickCancel = () => {
    setLoginPartnerModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="warning"
      open={loginPartnerModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.YELLOW.L10}>
          다른 서비스의 계정이 입력되었습니다.
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button type="primary" size="small" onClick={moveToPartnerPage}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        공급사 회원으로 고객사 서비스 로그인을 시도하셨습니다.
      </Typography>
      <Typography component="p" type="c2">
        확인을 누르면 공급사 로그인 화면으로 이동합니다.
      </Typography>
    </Modal>
  );
};

export default LoginPartnerModal;
