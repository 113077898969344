import React from "react";
import Button from "../../../../../../component/ui/button/Button";
import { useMediaQuery } from "react-responsive";

const OrgSearchButton = ({ setOrgSearchModal, orgNameFromInviteCode }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  const onClickOrgSearchButton = () => {
    setOrgSearchModal(true);
  };

  return (
    <Button
      type="primary"
      size="small"
      onClick={onClickOrgSearchButton}
      disabled={orgNameFromInviteCode}
      style={{ marginTop: isDesktop ? "22px" : "6.6rem" }}
    >
      회사 검색
    </Button>
  );
};

export default OrgSearchButton;
