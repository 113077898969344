import React, { useState, useEffect } from "react";
//library
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import moment from "moment";
//component
import Mheader from "../../../component/common/Mheader";
//css
import "../../../asset/css/orderIssuance.css";
//etc
import i_cancel from "../../../asset/images/w-cancle-bt.png";
import {
  // contractAddProductDelApi,
  contractOrderApi,
  contractOrderListApi,
} from "../../../api/auth";

import { numberWithCommas } from "../../../util/Util";
import Loading from "../../../component/etc/Loading";

import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";

registerLocale("ko", ko);

export default function OrderIssuance() {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();

  const conInfo = location.state.conInfo;
  const ct_no = location.state.contractNo;
  const firstClist = location.state.firstClist;

  const now = new Date();

  //checkbox
  const [allCheck, setAllCheck] = useState(false);
  const [blink, setBlink] = useState(false);

  const [pList, setpList] = useState([]);
  const [addList, setAddList] = useState([]);
  const [deliveryTime, setDeliveryTime] = useState();
  const [alertMsg, setAlertMsg] = useState(false);
  const [ascendData, setAscendDate] = useState({
    or_val_no: 0, //오더넘버
    or_uniqueness: "", //특이사항
    or_edate: 0, //납품기한
    or_addr: firstClist.bid_addr1, //현장주소
    or_detail: firstClist.bid_addr2, //상세주소
    s_no: userData.site_num,
  });

  const [basicInfo, setBasicInfo] = useState({});
  //loading ui
  const [generating, setGenerating] = useState(false);

  // 날짜 - 시작
  const [startDate, setStartDate] = useState(new Date());
  //선택된 합계
  const [chooseSum, setChooseSum] = useState(0);

  //해당 계약의 품목 불러오기
  const orderInfoFnc = async () => {
    const response = await contractOrderListApi(ct_no, firstClist.bid_order);

    if (response.status === "success") {
      setBasicInfo(response.data.basic);
      if (response.data.basic.bid_type === "S") {
        const delDate = new Date(response.data.basic.bid_deldate);

        // const delDate = new Date(
        //   response.data.basic.bid_deldate.date.year,
        //   response.data.basic.bid_deldate.date.month - 1,
        //   response.data.basic.bid_deldate.date.day
        // );

        setStartDate(delDate);
        // setDeliveryTime(
        //   response.data.basic.bid_deldate.time.hour +
        //     ':' +
        //     response.data.basic.bid_deldate.time.minute
        // );
        setDeliveryTime(delDate.getHours() + ":" + delDate.getMinutes());
        setAscendDate({
          ...ascendData,
          or_edate:
            formatDate(delDate) +
            " " +
            // response.data.basic.bid_deldate.time.hour +
            // ':' +
            // response.data.basic.bid_deldate.time.minute,
            delDate.getHours() +
            ":" +
            delDate.getMinutes(),
        });
      }

      let tempPList = response.data.pList.filter(
        (item) => item.bp_state === "ALIVE" || item.bp_state === "ADD"
      );

      tempPList.map((item) => {
        item.selected = false;
      });

      setpList(tempPList);
      // const arr = response.data.pList.filter((item) => item.bp_state === 'ADD');
      // const filterArray = arr.filter(
      //   (item, index) =>
      //     arr.findIndex((item2) => item2.bp_name === item.bp_name) === index
      // );
      // const getoutArray = arr.filter((item,index)=> arr.findIndex((item2)=> item2.bp_name === item.bp_name) !== index)
      // const totalAddArray = filterArray.map((item)=>
      // setAddList(filterArray);
    } else {
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  //주문서 추가된 품목 삭제하기
  // const addProductDel = async (data) => {
  //   const response = await contractAddProductDelApi(data);
  //   if (response.status === 'success') {
  //     const arr = addList.filter((item) => item.bp_no !== data);
  //     setAddList(arr);
  //   }
  // };

  //주문서 발행 완료하기
  const orderCompleteApi = async () => {
    setGenerating(true);
    if (ascendData.or_edate === 0 || ascendData.or_edate === "not_available") {
      swal("납품기한을 설정해주세요", "", "warning");
    } else if (ascendData.or_detail === "") {
      swal("상세 주소를 입력해주세요", "", "warning");
    } else {
      let completeList = pList.filter((item) => item.selected === true);
      completeList = completeList.map((item) => ({
        bp_bid_no: firstClist.bid_no,
        bp_no: item.bp_no,
        bp_etc: item.bp_etc,
        od_count: item.od_count,
        bp_state: item.bp_state,
        od_price: item.od_price,
        bp_name: item.bp_name,
        bp_standard: item.bp_standard,
        bp_unit: item.bp_unit,
      }));

      const completeList2 = addList.map((item) => ({
        bp_bid_no: firstClist.bid_no,
        bp_no: item.bp_no,
        od_count: item.od_count,
        bp_state: item.bp_state,
        od_price: item.od_price,
        bp_etc: item.bp_etc,
        bp_name: item.bp_name,
        bp_standard: item.bp_standard,
        bp_unit: item.bp_unit,
      }));
      const finalList = completeList.concat(completeList2);

      if (finalList.length === 0) {
        swal("주문할 품목을 선택해주세요.", "", "warning");
        setGenerating(false);
        return;
      }

      if (finalList.reduce((total, val) => total * val.od_count, 1) <= 0) {
        //선택된 배열을 돌려서 수량에 -1인 것이 있으면 filter
        swal(
          "수량이 잘못 입력된 품목이 있습니다. \n주문한 품목을 살펴보시고, 주문을 원치 않는 품목은 선택해제해주시기 바랍니다.",
          "",
          "warning"
        );
        setGenerating(false);
        return;
      }

      const response = await contractOrderApi(
        finalList,
        ascendData,
        firstClist.bid_no,
        ct_no
      );
      if (response.status === "success") {
        navigate("/management/contract/order_completed");
      } else if (response.message === "overTimeSet") {
        swal("납품기한을 확인해주세요.", "", "warning");
      } else {
        swal("다시 시도해주세요", "", "warning");
      }
    }
    setGenerating(false);
  };

  //시간설정
  const minValueTime = (chooseDate, chooseTime) => {
    if (
      formatDate(chooseDate) === formatDate(new Date()) &&
      getTime(60) >= chooseTime
    ) {
      //현재날짜와 비딩날짜가 같고, 선택한 날짜가 현재시간보다 작을 때 --수정예정
      setAscendDate({ ...ascendData, or_edate: "not_available" });
      setAlertMsg(true);
    } else setAlertMsg(false);

    // setAscendDate({
    //   ...ascendData,
    //   or_edate: formatDate(startDate) + ' ' + e.target.value,
    // });
  };
  const getTime = (min) => {
    //몇시몇분인지 리턴하는 함수
    const offset = (new Date().getTimezoneOffset() - min) * 60000; //isoString을 사용하면 국제시간 기준(+9시간)으로 되기 때문에 미리 빼줌
    const today = new Date(Date.now() - offset);
    let defaultTime = today.toISOString().slice(11, 16); //현재시간의 시와 분 리턴

    return defaultTime;
  };

  useEffect(() => {
    if (conInfo && ct_no) {
      orderInfoFnc();
      setAscendDate({ ...ascendData, or_val_no: conInfo.ct_no });
      // selectedListFnc();
    }
  }, []);

  const selectedListFnc = async (selectedList) => {
    let resultList = selectedList?.reduce(
      (total, val) => Number(total) + Number(val.od_price * val.od_count),
      0
    );
    setChooseSum(resultList);
  };

  const getDiffDate = (selectDate) => {
    let diff = moment(selectDate).fromNow();
    if (diff.includes("전")) {
      return "날짜와 시간을 확인해주세요.";
    } else {
      return "약 " + diff;
    }
  };

  return (
    <div className="order_issuance_wrap">
      <Mheader title="주문서 발행" />
      <div className="order_issuance_container wd">
        <div className="top_pagenation">
          <h2>주문서 발행</h2>
          <div className="top_pagenation_num">
            <p className="active">1</p>
            <p>2</p>
          </div>
        </div>
        <div className="order_issuance_contents">
          <div className="issuance_subject">
            <h3 className="s_tit">
              <p>품목 작성</p>
            </h3>
            <div className="subject_box">
              {/* {basicInfo.bid_type === 'T' && (
                <p>
                  *계약 기준 외 품목 추가 시 사전에 공급사와 단가 상의 후
                  발행해주세요. 미 상의 후 진행 시 주문서 발행이 거부될 수
                  있습니다.
                </p>
              )} */}

              <div className="subject_btn">
                {/* 품목검색추가 잠깐 삭제 [대표님 지시] */}
              </div>
            </div>
            <div className="subject_table">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <input
                        name="item"
                        id="all"
                        checked={allCheck}
                        type="checkbox"
                        onChange={() => {
                          if (!allCheck) {
                            let tempList = pList;
                            tempList.map((item) => {
                              if (
                                item.od_price !== "0" &&
                                item.od_count !== "0"
                              ) {
                                item.selected = true;
                              }
                            });
                            setpList(tempList);
                            setBlink(!blink);
                            setAllCheck(true);
                          } else {
                            let tempList = pList;
                            tempList.map((item) => {
                              if (
                                item.od_price !== "0" &&
                                item.od_count !== "0"
                              ) {
                                item.selected = false;
                              }
                            });
                            setpList(tempList);
                            setBlink(!blink);
                            setAllCheck(false);
                            setChooseSum(0);
                          }
                        }}
                      />
                      <label htmlFor="all"></label>
                    </th>
                    <th>
                      <p>품목명</p>
                    </th>
                    <th>
                      <p>규격</p>
                    </th>
                    <th>
                      <p>단위</p>
                    </th>
                    <th>
                      <p>수량</p>
                    </th>
                    <th>
                      <p>단가</p>
                    </th>
                    <th>
                      <p>금액</p>
                    </th>
                    <th>
                      <p>비고</p>
                    </th>
                  </tr>
                  {pList?.map((data, i) => {
                    if (data?.od_price !== "0" && data?.od_count !== "0")
                      return (
                        <tr
                          className={
                            data.bp_state === "ADD" ? "subject_t_add" : ""
                          }
                        >
                          <td>
                            <input
                              name="item"
                              id={`item_${i}`}
                              type="checkbox"
                              checked={(blink || !blink) && data.selected}
                              onChange={() => {
                                let tempList = pList;
                                tempList[i].selected = !tempList[i].selected;

                                let isAllChecked = true;
                                tempList.map((item) => {
                                  if (item.selected === false) {
                                    isAllChecked = false;
                                    return;
                                  }
                                });
                                if (isAllChecked === true) {
                                  setAllCheck(true);
                                } else {
                                  setAllCheck(false);
                                }

                                // var select = async() =>{

                                // .
                                // reduce((total, val)=> Number(total)+Number(val.od_price * val.od_count), 0);
                                // setChooseSum(selectedList);
                                // }
                                // select();

                                //reduce total = 더 해줄 값
                                //val은 배열을 돌면서 차례차례 요소를 뽑아줌
                                //콤마 뒤의 숫자는 초기값

                                // for( let i in selectedList){
                                //   let a = 0;
                                //   let b = a + (selectedList[i].od_price*selectedList[i].od_count);
                                //
                                //
                                // }

                                setpList(tempList);
                                setBlink(!blink);

                                let selectedList = pList.filter(
                                  (item) => item.selected === true
                                );
                                selectedListFnc(selectedList);
                              }}
                            />
                            <label htmlFor={`item_${i}`}></label>
                          </td>
                          <td>{data?.bp_name}</td>
                          <td>
                            <p className="p_list_standard">
                              {data.bp_standard.replace(/, /gi, "\n")}
                            </p>
                          </td>
                          <td>{data?.bp_unit}</td>
                          <td>
                            <input
                              style={{ borderBottom: "1px solid #d2d2d2" }}
                              type="number"
                              min={0}
                              defaultValue={data?.od_count}
                              onChange={(e) => {
                                let tempList = pList;
                                tempList[i].od_count = e.target.value;
                                setpList(tempList);
                                setBlink(!blink);

                                let selectedList = pList.filter(
                                  (item) => item.selected === true
                                );
                                selectedListFnc(selectedList);
                              }}
                            />
                          </td>
                          <td>
                            {(blink || !blink) &&
                              numberWithCommas(data?.od_price)}
                          </td>
                          <td>
                            {(blink || !blink) &&
                              numberWithCommas(data?.od_count * data?.od_price)}
                          </td>
                          <td>
                            <input
                              type="text"
                              style={{ borderBottom: "1px solid #d2d2d2" }}
                              onChange={(e) => {
                                let tempList = pList;
                                tempList[i].bp_etc = e.target.value;
                                setpList(tempList);
                                setBlink(!blink);
                              }}
                            />
                          </td>
                        </tr>
                      );
                  })}
                  {/* {addList.map((data, i) => {
                    return (
                      <tr className='subject_t_add'>
                        <td>
                          <img
                            src={i_cancel}
                            alt=''
                            className='t_cancel'
                            onClick={() => {
                              // addProductDel(data.bp_no);
                            }}
                          />
                        </td>
                        <td>{data.bp_name}</td>
                        <td>{data.bp_standard}</td>
                        <td>{data.bp_unit}</td>
                        <td>
                          <input
                            type='number'
                            min={1}
                            defaultValue={data.od_count}
                            onChange={(e) => {
                              const arr = addList.map((item) =>
                                item.bp_no === data.bp_no
                                  ? { ...item, od_count: e.target.value }
                                  : { ...item }
                              );
                              setAddList(arr);
                            }}
                          />
                        </td>
                        <td>{numberWithCommas(data?.od_price)}</td>
                        <td>
                          {numberWithCommas(data?.od_count * data?.od_price)}
                        </td>
                        <td>
                          <input
                            type='text'
                            onChange={(e) => {
                              let tempList = addList;
                              tempList[i].bp_etc = e.target.value;
                              setAddList(addList);
                              setBlink(!blink);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })} */}
                  <tr>
                    <td colSpan="6">합계</td>
                    <td>
                      <strong>
                        {numberWithCommas(
                          pList
                            .map((data) => data.od_count * data.od_price)
                            .reduce((sum, current) => sum + current, 0) +
                            addList
                              .map((data) => data.od_count * data.od_price)
                              .reduce((sum, current) => sum + current, 0)
                        )}
                      </strong>
                    </td>
                    <td>
                      <input type="text" />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6">선택된 합계</td>
                    <td>
                      {chooseSum !== 0 ? (
                        <strong>{numberWithCommas(chooseSum)}</strong>
                      ) : allCheck ? (
                        <strong>
                          {numberWithCommas(
                            pList
                              .map((data) => data.od_count * data.od_price)
                              .reduce((sum, current) => sum + current, 0) +
                              addList
                                .map((data) => data.od_count * data.od_price)
                                .reduce((sum, current) => sum + current, 0)
                          )}
                        </strong>
                      ) : (
                        0
                      )}
                      {/* 
                      {allCheck?
                      <strong>
                      {numberWithCommas(
                        pList
                          .map((data) => data.od_count * data.od_price)
                          .reduce((sum, current) => sum + current, 0) +
                          addList
                            .map((data) => data.od_count * data.od_price)
                            .reduce((sum, current) => sum + current, 0)
                      )}
                      </strong>
                      :
                      <strong>
                        {numberWithCommas(chooseSum)}
                      </strong>
                      } */}
                    </td>
                    <td>선택된 품목의 합계금액입니다.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="uniqueness_box">
              <div className="uniqueness">
                <div className="uniqueness_tit">
                  <p>특이사항</p>
                </div>
                <div className="uniqueness_content">
                  <p>
                    <textarea
                      onChange={(e) => {
                        setAscendDate({
                          ...ascendData,
                          or_uniqueness: e.target.value,
                        });
                      }}
                      placeholder="특이사항을 입력해주세요."
                    ></textarea>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="delivery_date">
            <h3 className="s_tit">
              <p>납품 기한</p>
            </h3>
            <ul className="delivery_date_ul">
              <li>
                {/* <DatePicker
                  locale='ko'
                  selected={startDate}
                  startDate={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setAscendDate({
                      ...ascendData,
                      or_edate: formatDate(date) + ' ' + deliveryTime,
                    });
                    minValueTime(formatDate(date), deliveryTime);
                  }}
                  minDate={new Date()}
                  dateFormat='yyyy-MM-dd'
                  className={
                    alertMsg
                      ? 'bidding_time_select select_alert'
                      : 'bidding_time_select'
                  }
                /> */}
                <DatePicker
                  locale="ko"
                  selected={startDate ? startDate : null}
                  // closeOnScroll={true} //when scroll close
                  minDate={new Date()}
                  minTime={
                    //formatDate가 같다면 10분전까지만 select
                    formatDate(startDate) === formatDate(new Date())
                      ? new Date().setHours(new Date().getHours() + 1)
                      : now.setHours(0, 0, 0)
                  }
                  maxTime={now.setHours(23, 59, 59)}
                  showTimeSelect // 시간 나오게 하기
                  timeFormat="HH:mm" //시간 포맷
                  timeIntervals={30} // 10분 단위로 선택 가능한 box가 나옴
                  timeCaption="time"
                  dateFormat="yyyy/MM/dd HH:mm 까지"
                  isClearable={true}
                  peekNextMonth
                  withPortal
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(date) => {
                    setStartDate(date);
                    setAscendDate({
                      ...ascendData,
                      or_edate: moment(date).format("yyyy-MM-DD HH:mm"),
                    });
                    // minValueTime(formatDate(date), deliveryTime);
                  }}
                  // 날짜만 선택하고 닫으면
                  // 시간이 오전 12시로 pick되는 issue 발생
                  // 마지막에 validation으로 formatdate이용해서 체크하는거 추천
                />
              </li>
            </ul>
            {startDate && (
              <span className="bid_time_info2">{getDiffDate(startDate)}</span>
            )}
            {basicInfo.bid_type === "T" ? (
              <p
                className={alertMsg ? "time_false" : null}
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                *당일 납품 기한 시간은 주문서발행 시간 기준 1시간 이후 부터 선택
                가능합니다.
              </p>
            ) : (
              <p className={alertMsg ? "time_false" : null}>
                *기존에 지정된 납품일시에서 변경하실 경우, 사전에 공급사와 먼저
                협의해주세요.
              </p>
            )}
          </div>
          <div className="issuance_delivery_address">
            <h3 className="s_tit">
              <p>납품 주소</p>
            </h3>
            <ul>
              <li>
                <input type="text" disabled value={firstClist?.bid_addr1} />
              </li>
              <li>
                <input
                  type="text"
                  placeholder="납품을 원하는 상세 주소를 설명해주세요."
                  defaultValue={firstClist?.bid_addr2}
                  onChange={(e) => {
                    setAscendDate({ ...ascendData, or_detail: e.target.value });
                  }}
                />
              </li>
            </ul>
          </div>
          <p className="caution_txt">
            *주문서 수정은 공급사에서 주문확인 전 까지 가능합니다.
          </p>
        </div>
        <div className="order_btn_box btn_box">
          <div className="prev">
            <p>이전</p>
          </div>
          <div className="ok">
            <p
              onClick={() => {
                orderCompleteApi();
              }}
            >
              작성
            </p>
          </div>
        </div>
      </div>
      {generating && <Loading msg={"로딩 중입니다."} />}
    </div>
  );
}
