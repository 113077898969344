import { Controller } from "react-hook-form";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./bizLogoImg.module.css";

const BizLogoImg = ({ control, setBizLogoFile }) => {
  const handleChangeFile = (e, onChange) => {
    const file = e.target.files[0];
    if (file) {
      setBizLogoFile(file);
      onChange(file);
    }
  };

  const onClickUploadImg = () => {
    document.getElementById("file_upload_img").click();
  };

  return (
    <Controller
      name="biz_logo"
      control={control}
      render={({ field }) => (
        <div className={s.container}>
          <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
            로고
          </Typography>

          <input
            type="file"
            id="file_upload_img"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => handleChangeFile(e, field.onChange)}
          />
          <div className={s.logo_container} onClick={onClickUploadImg}>
            {field.value && (
              <img className={s.logo_image} src={field.value} alt="" />
            )}
          </div>
        </div>
      )}
    />
  );
};

export default BizLogoImg;
