import React from "react";
import { useNavigate } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/policy.css";

export default function Privacy() {
  const navigate = useNavigate();

  return (
    <div className="privacy_wrap">
      <Mheader title="개인정보처리방침" />
      <div className="privacy_container">
        <h2 className="policy_tit">개인정보처리방침</h2>
        <div className="privacy_contents">
          {/* <div className='privacy_box'>
          <a href="/privacyAgree" className="privacyAgree_link">개인정보수집이용동의 약관 보기</a>
        </div> */}
          <div className="privacy_box">
            <h4>제 1조 &#40;총칙&#41;</h4>
            <p>
              이 약관은 “주식회사 공새로”(이하 "회사"라 합니다)에서 운영하는
              “웹사이트 및 스마트기기 앱”(이하 "사이트"라 합니다)에서 제공하는
              “중개 및 정보제공 서비스”(이하"서비스"라 합니다)를 이용함에 있어
              회사와 이용자의 권리, 의무 및 책임 사항을 규정함을 목적으로
              합니다.
              <br />
              "회사"는 이용자들의 개인정보보호를 매우 중요시하며, 이용자가
              회사의 서비스를 이용함과 동시에 온라인상에서 회사에 제공한
              개인정보가 보호받을 수 있도록 최선을 다하고 있습니다. 이에
              "회사"는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및
              정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할
              관련 법규상의 개인정보보호 규정 및 정보통신부가 제정한
              개인정보보호지침을 준수하고 있습니다.
              <br />
              "사이트"는 개인정보 처리방침을 통하여 이용자들이 제공하는
              개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를
              위해 어떠한 조치가 취해지고 있는지 알려 드립니다.
              <br />
              "회사"의 개인정보 처리방침은 정부의 법률 및 지침 변경이나 "회사"의
              내부 방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른
              개인정보 처리방침의 지속적인 개선을 위하여 필요한 절차를 정하고
              있습니다. 그리고 개인정보 처리방침을 개정하거나 변경하는 경우
              “사이트”에 게시하고 있습니다. “사이트” 이용자는 정기적으로
              “사이트”에 접속하여 변경된 개인정보 처리방침을 확인하시기
              바랍니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 2조 &#40;개인정보 수집에 대한 동의&#41;</h4>
            <p>
              "회사"는 귀하께서 "사이트"의 개인정보보호방침과 이용약관에
              동의하면 개인정보 수집에 대해 동의한 것으로 봅니다. 동의하지
              아니한 경우엔 해당 서비스 이용이 불가합니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 3조 &#40;개인정보의 수집목적 및 이용목적&#41;</h4>
            <p>
              "개인정보"라 함은 개인과 법인에 관한 정보로서 당해 정보에 포함되어
              있는 성명, 휴대폰번호 등의 사항에 의해 당해 개인을 식별할 수 있는
              정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와
              용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.일부
              서비스는 별도의 사용자 등록이 없이 언제든지 사용할 수 있습니다.
              그러나 "회사"는 회원서비스를 통하여 이용자들에게 맞춤식 서비스를
              비롯한 보다 더 향상된 양질의 서비스를 제공하기 위하여 다음 (제 4
              조)과 같은 목적으로 이용자 개인의 정보를 수집 · 이용하고 있습니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 4조 &#40;수집하는 개인정보 항목 및 수집방법&#41;</h4>
            <p>
              "회사"는 이용자들이 회원서비스를 이용하기 위해 회원으로 가입하실
              때 서비스 제공을 위해 필요한 정보들을 온라인상에서 입력 받고
              있습니다. 수집되는 개인정보 항목과 수집방법은 아래와 같습니다.
              <br />
              <br />
              &#40;고객사 - 회원가입&#41;
              <br />
              아이디, 비밀번호, 이름, 소속, 직급, 직책, 연락처, 이메일,
              사업자명, 직원 수, 사업자구분, 사업자등록번호, 대표자명, 업종,
              업태, 주소, 로고 이미지, 사업자등록증 사본, 사업자 도장 이미지,
              현장명, 현장분류, 현장규모, 공정, 공사시작일, 준공예정일,
              현장주소, 거래정보, 입찰예산, 투찰기한, 납품일시, 배송비 포함
              여부, 하차 역무 포함 여부, 우선사항, 계약기간, 결제주기, 납품주소,
              주문 품목 등
              <br />
              <br />
              &#40;공급사 - 회원가입&#41;
              <br />
              아이디, 비밀번호, 이름, 소속, 직급, 직책, 연락처, 이메일,
              사업자명, 직원 수, 사업자구분, 사업자등록번호, 대표자명, 업종,
              업태, 주소, 로고 이미지, 사업자등록증 사본, 사업자 도장 이미지,
              정산 받을 통장사본, 신용평가서 사본, 납품 가능한 지역, 제휴업체
              분류, 사업장 사진, 보유 차량, 보유 장비, 거래정보 등
            </p>
            <br />
            <br />
            <p>
              이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보&#40;인종
              및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록,
              건강상태 및 성생활 등&#41;는 수집하지 않으며 부득이하게 수집해야
              할 경우 이용자들의 사전 동의를 반드시 구할 것입니다. 그리고 어떤
              경우에라도 입력하신 정보를 이용자들에게 사전에 밝힌 목적 이외에
              다른 목적으로는 사용하지 않으며 이용자의 사전 동의 없이는 이용자의
              개인 정보를 외부로 공개 · 유출하지 않습니다.
            </p>
          </div>

          <div className="privacy_box">
            <h4>
              제 5조 &#40;인터넷 접속정보파일 등 개인정보를 자동으로 수집하는
              장치의 설치·운영 및 그 거부에 관한 사항&#41;
            </h4>
            <p>
              "사이트"는 장바구니 처리 및 최근 본 상품 처리, 로그인 판별 시
              브라우저 쿠키를 이용합니다. <br />
              <br /> 쿠키(cookie)란?
              <br />
              "사이트"는 귀하에 대한 정보를 저장하고 수시로 찾아내는
              쿠키(cookie)를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터
              브라우저(Microsoft Edge, Chrome 등)로 전송하는 소량의 정보입니다.
              귀하께서 "사이트"에 접속을 하면 "사이트"는 귀하의 브라우저에 있는
              쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아
              접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.
              쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는
              않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다.
              <br />
              <br />
              쿠키 설정방법
              <br />
              &nbsp;&nbsp;&nbsp;1) &nbsp;&nbsp;&nbsp;Microsoft Edge의 경우: 웹
              브라우저 우측 상단의 ‘설정 및 기타’ - ‘설정’ - ‘쿠키 및 사이트
              권한’
              <br />
              &nbsp;&nbsp;&nbsp;2) &nbsp;&nbsp;&nbsp;Chrome의 경우: 웹 브라우저
              우측 상단의 ‘설정 및 제어’ - ‘설정’ - ‘개인 정보 보호 및 보안’ -
              ‘쿠키 및 기타 사이트 데이터‘
              <br />
              &nbsp;&nbsp;&nbsp;3) &nbsp;&nbsp;&nbsp;쿠키 설정방법은 브라우저
              종류 및 버전에 따라 변경될 수 있으니, 상세한 방법은 인터넷에
              검색하시기 바랍니다.
              <br />
              다만 쿠키를 차단할 경우 “사이트” 사용이 불편해지고 로그인이 필요한
              일부 서비스 이용에 어려움이 있을 수 있습니다. 쿠키는 브라우저의
              종료 시나 로그아웃 시 만료됩니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 6조 &#40;비밀번호 관리&#41;</h4>
            <p>
              "회사"는 이용자들의 민감한 개인정보인 비밀번호에 대한 내용을
              아래와 같이 취급합니다.
              <br />
              사용자 아이디와 동일한 비밀번호를 설정하지 못하도록 설정
              <br />
              비밀번호는 다음의 규칙을 충족해야 합니다.
              <br />
              1) 8~15 글자로 영문, 숫자, 특수문자 3종류 혼합
              <br />
              비밀번호의 노출 방지를 위하여, 입력된 비밀번호는 별표(*)
              처리되거나 음영 처리(Shadow) 되도록 설계하여, 단말기 화면에서 읽을
              수 없도록 구현
              <br />
              비밀번호 분실시를 대비하여 비밀번호 초기화 절차를 적용
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 7조 &#40;수집한 개인정보 공유 및 제공&#41;</h4>
            <p>
              "회사”는 이용자의 개인정보를 “제 3조 개인정보의 수집목적 및
              이용목적”에서 고지한 범위 내에서만 사용하며, 이용자의 사전 동의
              없이는 해당 범위를 초과하여 이용자의 개인정보를 이용하거나 외부에
              공개하지 않습니다.
              <br />
              <br /> 다만, 아래의 경우에는 예외로 합니다.
              <br />
              • &nbsp;&nbsp;이용자들이 사전에 공개에 동의한 경우
              <br />
              • &nbsp;&nbsp;“사이트”에서 이용자에게 “서비스”를 제공하기 위해
              필요한 경우
              <br />
              • &nbsp;&nbsp;이용자가 속한 기업의 시스템과 연동이 필요한 경우
              <br />
              • &nbsp;&nbsp;서비스 제공에 관한 계약을 이행하기 위하여 필요한
              개인정보로서 경제적 · 기술적인 사유로 통상적인 동의를 받는 것이
              뚜렷하게 곤란한 경우
              <br />
              • &nbsp;&nbsp;서비스 이용에 따른 금액정산을 위하여 필요한 경우
              <br />
              • &nbsp;&nbsp;“회사” 서비스를 이용하여 타인에게 정신적, 물질적
              피해를 줌으로써 그에 대한 법적인 조치를 취하기 위하여 개인정보를
              공개해야 한다고 판단되는 충분한 근거가 있는 경우
              <br />
              • &nbsp;&nbsp;기타 법에 의해 요구된다고 판단되는 경우 (ex. 관련
              법에 의거 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우 등)
              <br />
              • &nbsp;&nbsp;통계 작성, 학술연구나 시장조사를 위하여 특정 개인을
              식별할 수 없는 형태로 광고주, 협력업체나 연구단체 등에 제공하는
              경우
              <br />
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 8조 &#40;개인정보의 열람, 정정, 삭제&#41;</h4>
            <p>
              이용자는 언제든지 "사이트"에서 개인정보를 열람하고 보관된 필수
              정보를 수정할 수 있습니다. 또한, 회원 가입 시 요구된 필수 정보
              이외의 추가 정보도 언제든지 열람, 수정, 삭제할 수 있습니다.
              개인정보의 변경 및 삭제, 회원 탈퇴는 로그인 후 "사이트"의
              마이페이지에서 이용할 수 있습니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 9조 &#40;개인정보의 보유기간 및 이용기간&#41;</h4>
            <p>
              이용자가 A급 회원으로서 회사에 제공하는 서비스를 이용하는 동안
              "회사"는 이용자들의 개인정보를 계속적으로 보유하며 서비스 제공
              등을 위해 이용합니다. 다만, 위 “7. 개인정보의 열람, 정정, 삭제”
              에서 설명한 절차와 방법에 따라 이용자 본인이 직접 삭제하거나
              수정한 정보, 가입해지를 요청한 경우에는 재생할 수 없는 방법에
              의하여 디스크에서 완전히 삭제하며 추후 열람이나 이용이 불가능한
              상태로 처리됩니다.
              <br />
              이용자의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은
              목적이 달성되면 파기하는 것을 원칙으로 합니다.
              <br />
              그리고 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등
              관계법령의 규정에 의하여 보존할 필요가 있는 경우 "회사"는
              관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우
              "회사"는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은
              아래와 같습니다.
              <br />
              <br />
              • &nbsp;&nbsp;계약 또는 청약철회 등에 관한 기록 : 5년
              <br />
              • &nbsp;&nbsp;대금결제 및 재화 등의 공급에 관한 기록 : 5년
              <br />
              • &nbsp;&nbsp;소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
              <br />
              <br />
              "회사"는 귀중한 이용자의 개인정보를 안전하게 처리하며, 유출의
              방지를 위하여 다음과 같은 방법을 통하여 개인정보를
              파기합니다.종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
              통하여 파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을
              재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 10조 &#40;게시물 보호&#41;</h4>
            <p>
              "회사"는 이용자의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지
              않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는 그렇지
              아니합니다.
              <br />
              • &nbsp;&nbsp;스팸(spam)성 게시물 (예 : 행운의 편지, 8억 메일,
              특정 사이트 광고 등)
              <br />
              • &nbsp;&nbsp;타인을 비방할 목적으로 허위 사실을 유포하여 타인의
              명예를 훼손하는 글<br />
              • &nbsp;&nbsp;타인의 저작권 등 권리를 침해하는 내용, 기타 게시판
              주제와 다른 내용의 게시물
              <br />
              • &nbsp;&nbsp;"회사"는 바람직한 게시판 문화를 활성화하기 위하여
              동의 없는 타인의 신상 공개시 특정 부분을 삭제하거나 기호 등으로
              수정하여 게시할 수 있습니다.
              <br />
              • &nbsp;&nbsp;다른 주제의 게시판으로 이동 가능한 내용일 경우 해당
              게시물에 이동 경로를 밝혀 오해가 없도록 하고 있습니다.
              <br />
              • &nbsp;&nbsp;그 외의 경우 명시적 또는 개별적인 경고 후 삭제
              조치할 수 있습니다.
              <br />
              근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게
              있습니다. 또 게시물을 통해 자발적으로 공개된 정보는 보호받기
              어려우므로 정보 공개 전에 심사숙고하시기 바랍니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 11조 &#40;수집한 개인정보의 위탁&#41;</h4>
            <p>
              "회사"는 서비스 향상을 위해서 이용자의 개인정보를 필요한 경우 동의
              등 법률상의 요건을 구비하여 외부에 수집 · 처리 · 관리 등을
              위탁하여 처리할 있으며, 제 3자에게 제공할 수 있습니다. <br />
              "회사"는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고
              있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수
              있도록 필요한 사항을 규정하고 있습니다. 또한 공유하는 정보는 당해
              목적을 달성하기 위하여 필요한 최소한의 정보에 국한됩니다.
              <br />
              <br />
              "회사"는 이용자에게 편리하고 혜택이 있는 다양한 서비스를 제공하기
              위하여 아래의 업체와 제휴합니다. 제공되는 개인정보의 항목은
              회원가입 시 "회사"에 제공한 개인정보 중 아래와 같이 제공됩니다.
              <br />
              <br />
              • &nbsp;&nbsp;개인정보를 제공 받는 자 :
              <br />
              • &nbsp;&nbsp;수집 방법 :
              <br />
              • &nbsp;&nbsp;수집 항목 :
              <br />
              • &nbsp;&nbsp;수집 및 이용목적 :
              <br />
              • &nbsp;&nbsp;보유 및 이용기간 :
              <br />
              <br />
              다만, 아래의 경우에는 예외로 합니다. • &nbsp;&nbsp;이용자들이
              사전에 동의한 경우
              <br />
              • &nbsp;&nbsp;법령의 규정에 의거하거나, 수사 목적으로 법령에
              정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
              <br />
              • &nbsp;&nbsp;개인정보의 처리를 위탁하거나 제공하는 경우에는
              수탁자, 수탁범위, 공유 정보의 범위 등에 관한 사항을 서면,
              전자우편, 전화 또는 “사이트”를 통해 미리 이용자에게 고지합니다.
              <br />
            </p>
          </div>

          <div className="privacy_box">
            <h4>
              제 12조 &#40;정보주체와 법정대리인의 권리,의무 및 그 행사방법에
              관한 사항&#41;
            </h4>
            <p>
              1. 정보주체&#40;만 14세 미만인 경우에는 법정대리인을 말함&#41;는
              언제든지 개인정보보호위원회가 보유하고 있는 개인정보에 대하여
              개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수
              있습니다.
              <br />
              <br />
              ※ 만 14세 미만 아동에 관한 개인정보의 열람 등 요구는 법정대리인이
              직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의
              개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을
              통하여 권리를 행사할 수도 있습니다.
              <br />
              2. 제1항에 따른 권리 행사는 개인정보위에 대해 「개인정보 보호법」
              시행령 제41조, 제43조, 제44조에 따라 서면, 전자우편, 모사전송(FAX)
              등을 통하여 하실 수 있으며 이에 대해 지체 없이 조치하겠습니다.
              <br />
              3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
              자 등 대리인을 통하여 하실 수 있습니다. 이 경우 「개인정보 처리
              방법에 관한 고시(제2020-7호)」별지 제11호 서식에 따른 위임장을
              제출하셔야 합니다.
              <br />
              4. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항,
              제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
              <br />
              5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
              대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
              <br />
              6. 개인정보보호위원회는 정보주체 권리에 따른 열람의 요구,
              정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
              본인이거나 정당한 대리인인지를 확인합니다.
              <br />
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 13조 &#40;개인정보의 안전성 확보 조치에 관한 사항&#41;</h4>
            <p>
              개인정보처리자는 고유식별정보, 비밀번호 등 개인정보를 정보통신망을
              통하여 송신하거나 보조저장매체 등을 통하여 전달하는 경우에는 이를
              복호화되지 아니하도록 일방향 암호화하여 저장합니다. “회사”의
              “사이트”에서 진행하는 개인정보보안은 다음과 같습니다.
              <br />
              • &nbsp;&nbsp;개인정보 접근 권한 최소화
              <br />
              • &nbsp;&nbsp;개인정보 접근 시 불필요 서비스 제거, 보안패치 진행
              <br />
              • &nbsp;&nbsp;OWASP(Open Web Application Security Project)에 의거,
              10대 웹 애플리케이션의 취약점에 대한 방어
              <br />
              • &nbsp;&nbsp;개인정보 저장 시 복호화되지 않도록 양방향 암호화
              <br />
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 14조 &#40;이용자의 권리와 의무&#41;</h4>
            <p>
              이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를
              예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해
              발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등
              허위정보를 입력할 경우 회원자격이 상실될 수 있습니다. 이용자는
              개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를
              침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 이용자의
              개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의
              개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을
              다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는
              『정보통신망이용촉진 및 정보보호 등에 관한 법률』 등에 의해
              처벌받을 수 있습니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>
              제 15조 &#40;개인정보 관련 의겸수렴 및 불만처리에 관한 사항&#41;
            </h4>
            <p>
              "회사"는 개인정보보호와 관련하여 이용자 여러분들의 의견을 수렴하고
              있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고
              있습니다.이용자들은 아래에 명시한 “제 16조 개인정보 보호책임자 및
              담당자” 항을 참고하여 전화나 메일을 통하여 불만사항을 신고할 수
              있고, "회사"는 이용자들의 신고사항에 대하여 신속하고도 충분한
              답변을 해 드릴 것입니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 16조 &#40;개인정보 보호책임자 및 담당자&#41;</h4>
            <p>
              "회사"는 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을
              다하고 있습니다. 개인정보를 보호하는데 있어 이용자에게 고지한
              사항들에 반하는 사고가 발생할 경우 개인정보 보호책임자가 책임을
              집니다.그러나 기술적 · 관리적 보완조치를 했음에도 불구하고, 해킹
              등 기본적인 네트워크 상의 위험성에 의해 발생하는 예기치 못한
              사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종
              분쟁에 관해서는 "회사"의 책임이 없습니다. 또한 이용자 개인정보와
              관련한 아이디(ID)의 비밀번호에 대한 보안유지책임은 해당 이용자
              자신에게 있습니다."회사"는 비밀번호에 대해 어떠한 방법으로도
              이용자에게 직접적으로 질문하는 경우는 없으므로 타인에게 비밀번호가
              유출되지 않도록 각별히 주의하시기 바랍니다.특히 공공장소의
              온라인상에서 접속해 있을 경우에는 더욱 유의하셔야 합니다."회사"는
              개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보
              보호책임자 및 담당자를 지정하고 있고, 연락처는 아래와 같습니다.
              <br />
              <br />
              개인정보 보호 책임자 :  남가람
              <br />
              소속 / 직위　: 공새로 / 대표
              <br />
              E - M A I L 　: ceo@gongsaero.com <br />
              전 화 번 호　: 032-205-0479
              <br />
              <br />
              개인정보 보호 담당자 :  문제선
              <br />
              소속 / 직위　: 공새로 / 매니저
              <br />
              E - M A I L 　: js.moon@gongsaero.com <br />
            </p>
          </div>
          <div className="privacy_box">
            <h4>
              제 17조 &#40;정보주체의 권리, 의무 및 그 행사방법에 관한 사항
              탈퇴했을 때&#41;
            </h4>
            <p>
              귀하는 회원가입 및 학술연구를 위해 개인정보의 수집ㆍ이용 및 제공에
              대해 동의하신 내용을 언제든지 철회할 수 있습니다. 귀하는 본인
              개인정보의 열람, 정정, 처리정지, 삭제 요청을 할 수 있으며,
              개인정보 보호책임자에게 서면, 전화 또는 Fax 등으로 연락하시면 지체
              없이 파기하는 등 필요한 조치를 하겠습니다. 단, 법에서 정한
              의무사항이 있으면 권리 행사가 제한 될 수 있습니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 18조 &#40;고지의 의무&#41;</h4>
            <p>
              현 개인정보처리방침의 내용은 정부의 정책 또는 보안기술의 변경에
              따라 내용의 추가 삭제 및 수정이 있을 시에는 시행일의 7일전부터
              “사이트”를 통해 고지할 것입니다.
              <br />
            </p>
          </div>
          <div className="privacy_box">
            <h4>제 19조 &#40;개인정보에 관한 민원서비스&#41;</h4>
            <p>
              개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
              문의하시기 바랍니다.
              <br />
              <br />
              개인정보침해신고센터 (privacy.kisa.or.kr / 국번 없이 118)
              <br />
              대검찰청 사이버범죄수사단 (www.spo.go.kr / 02-3480-2000)
              <br />
              경찰청 사이버안전국 (www.ctrc.go.kr/ 국번 없이 182)
              <br />
              <br />- 공고일자 : 2023년 12월 08일
              <br />- 시행일자 : 2024년 01월 08일
            </p>
          </div>

          <h2 className="policy_tit">개인정보수집이용동의</h2>
          <div
            className="privacy_container"
            style={{ width: "1100px", padding: "20px 0" }}
          >
            <div className="privacy_box">
              <h4>
                수집하는 개인정보 항목 / 수집 및 이용목적 / 보유 및 이용기간
              </h4>
              <table>
                <tr>
                  <td>수집방법</td>
                  <td>수집항목</td>
                  <td>수집 및 이용목적</td>
                  <td>보유 및 이용기간</td>
                </tr>
                <tr>
                  <td>회원가입</td>
                  <td>
                    회원) ID, 비밀번호, 이름, 부서, 직급 <br />
                    건설사) 건설사명, 사업자구분, 사업자등록번호, 대표자명,
                    업종, 업태, 주소, 로고이미지
                    <br />
                    현장) 현장명, 분류, 현장규모, 현재공종단계, 대표번호,
                    공사시작일, 준공예정일, 현장주소
                  </td>
                  <td>
                    사이트의 전반적인 기능 이용 <br />( 입찰, 계약, 주문, 정산,
                    채팅, 인근공급사찾기 등 )
                  </td>
                  <td>회원탈퇴 후 탈퇴한 정보는 비식별처리</td>
                </tr>
                <tr>
                  <td>입찰/계약/주문</td>
                  <td>
                    입찰) 진행 공종, 입찰예산, 투찰기한,
                    <br /> 배송비 포함 업무, 하차역무 포함여부, 우선사항,
                    계약기간, 결제주기, 납품주소, 납품일시
                  </td>
                  <td>자재 입찰 후 공급사 투찰 서비스 제공</td>
                  <td rowSpan="3">
                    {" "}
                    부정이용 방지를 위하여 회원탈퇴 후에도
                    입찰/계약/주문/정산/채팅 정보는 보관합니다.
                  </td>
                </tr>
                <tr>
                  <td>질의응답</td>
                  <td>
                    채팅내용, 첨부된 이미지, 첨부된 파일
                    <br />
                  </td>
                  <td>입찰건에 대한 고객사, 공급사간의 질의응답 서비스 제공</td>
                </tr>
                <tr>
                  <td>오류</td>
                  <td>
                    단말기정보, 화면사이즈, 디바이스 아이디, IP주소, 오류일시 등
                    <br />
                  </td>
                  <td>사이트 이용 중 오류 및 불편사항 추적</td>
                </tr>
              </table>
            </div>
            <div className="privacy_box">
              <p>
                단, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의
                규정에 따라 거래 관계 확인을 위해 개인정보를 일정기간 보유 할 수
                있습니다.
              </p>
            </div>
            <div className="privacy_box">
              <h4>관계법령에 의한 개인정보 보존기간</h4>
              <p>
                <ul style={{ marginLeft: "10px" }}>
                  <li>서비스이용기록, 접속로그 (통신비밀보호법:3개월)</li>
                  <li>계약 또는 청약철회 등에 관한 기록 (전자상거래법: 5년)</li>
                  <li>
                    대금결제 및 대금납부 등 재화 공급에 관한 기록 (전자상거래법:
                    5년)
                  </li>
                  <li>
                    소비자의 불만 또는 분쟁처리에 관한 기록 (전자상거래법: 3년)
                  </li>
                </ul>
              </p>
            </div>
            <div className="privacy_box">
              <p>
                <h4>동의를 거부할 권리 및 거부 경우의 불이익</h4>
                다만, 수집 및 이용을 거부하신 정보와 연계된 서비스(입찰, 계약,
                주문, 정산관리 등)는 동의 전까지 일부 또는 전체를 이용하실 수
                없습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
