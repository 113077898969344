import Button from "../../../../../../component/ui/button/Button";

const CancelButton = ({ navigate }) => {
  const moveToLoginPage = () => {
    navigate("/login");
  };

  return (
    <Button type="secondary" size="medium" onClick={moveToLoginPage}>
      취소
    </Button>
  );
};

export default CancelButton;
