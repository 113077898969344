import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import i_close from "../../../asset/images/w-cancel-icon.png";
import "../../../asset/css/pop.css";
import { contractCancelApi } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function ContractCancelPop({
  setCancelPop,
  contractList,
  contractNum,
}) {
  const navigate = useNavigate();

  const [cancelData, setCancelData] = useState({
    ct_no: contractNum,
    ct_cancel: "",
  });
  // 기타 입력란
  const [selected, setSelected] = useState("");

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  const contractCancelFnc = async () => {
    if (cancelData.ct_no === 0) {
      swal("다시 시도해주세요.", "", "warning");
    } else if (cancelData.ct_cancel === "") {
      swal("취소사유를 선택해주세요.", "", "warning");
    } else {
      const response = await contractCancelApi(
        cancelData.ct_no,
        cancelData.ct_cancel
      );
      if (response.status === "success") {
        swal("계약이 취소 요청되었습니다.", "", "warning");
        window.location.reload();
        setCancelPop(false);
      } else if (response.status === "fail") {
        swal("다시 시도해주세요.", "", "warning");
      }
    }
  };

  return (
    <div className="pop">
      <div
        className="pop_dim"
        onClick={() => {
          setCancelPop(false);
        }}
      ></div>
      <div className="contract_pop">
        <div
          className="close"
          onClick={() => {
            setCancelPop(false);
          }}
        >
          <img src={i_close} alt="" />
        </div>
        <div className="contract_pop_tit">
          <h3>계약을 취소 하시겠습니까?</h3>
          <p>계약취소 사유를 선택해주세요</p>
        </div>
        <select
          onChange={(e) => {
            if (e.target.value === "기타") {
              setSelected("기타");
            } else {
              setCancelData({ ...cancelData, ct_cancel: e.target.value });
              setSelected(e.target.value);
            }
          }}
        >
          <option value="" hidden>
            취소사유 선택
          </option>
          <option value="회사와의 관계">회사와의 관계</option>
          <option value="파기 후 재계약">파기 후 재계약</option>
          <option value="변심">변심</option>
          <option value="기타">기타</option>
        </select>
        {selected === "기타" && (
          <input
            placeholder="계약 취소 사유를 입력해주세요"
            maxLength={255}
            onChange={(e) => {
              setCancelData({ ...cancelData, ct_cancel: e.target.value });
            }}
          />
        )}
        <div
          className="confirm_btn"
          onClick={() => {
            setCancelPop(false);
          }}
        >
          <p
            onClick={() => {
              if (selected === "") {
                swal("취소 사유를 선택해주세요.", "", "warning");
              } else if (selected !== "") {
                contractCancelFnc();
              }
            }}
          >
            확인
          </p>
        </div>
      </div>
    </div>
  );
}
