import Typography from "../../../../../../component/ui/typography/Typography";
import s from "./findAccount.module.css";
import { COLOR } from "../../../../../../constant/Palette";

const FindAccount = ({ navigate }) => {
  const moveToFindAccountPage = () => {
    navigate("/find");
  };

  return (
    <div className={s.container}>
      <Typography
        component="p"
        type="c4"
        color={COLOR.GRAY.L09}
        onClick={moveToFindAccountPage}
      >
        아이디 • 비밀번호 찾기
      </Typography>
    </div>
  );
};

export default FindAccount;
