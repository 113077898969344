import React, { useState, useEffect } from "react";
//library
import { useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
//component
import Mheader from "../../../component/common/Mheader";
import OrderFormCancelPop from "../../../component/common/Pop/OrderFormCancelPop";
import Loading from "../../../component/etc/Loading";

//css
import "../../../asset/css/order.css";
import * as S from "../../../asset/css/orderDetail.styles.js";
import {
  orderCheckApi,
  generateCheckApi,
  ordersDetailApi,
  orderRejectApi,
  showOrderSheetApi,
  checkReorderStateApi,
} from "../../../api/auth";

//sweet alert
import swal from "sweetalert";
import OrdersModifyHistoryTabs from "./OrdersModifyHistoryTabs";
import { useMediaQuery } from "react-responsive";
import DeliveryDonePop from "../../../component/common/Pop/DeliveryDonePop.js";
import DownloadSVG from "../../../asset/images/svg/DownloadSVG.js";
import Button from "../../../component/ui/button/Button";
import { Divider } from "antd";
import StateChip from "./components/chip/StateChip.js";
import TypeChip from "./components/chip/TypeChip.js";
import ReorderUnitPriceFailPop from "./components/pop/reorder/ReorderUnitPriceFailPop.js";
import ReorderUnitPriceSuccessPop from "./components/pop/reorder/ReorderUnitPriceSuccessPop.js";
import ReorderPBPop from "./components/pop/reorder/ReorderPBPop.js";
import AddProductPop from "./components/pop/addProduct/AddProductPop.js";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/action/index.js";
import useScrollFixed from "../../../hooks/useScrollFixed.js";
import classNames from "classnames";

const formatPhoneNumber = (phoneNumber) => {
  // Check if phoneNumber is defined and not null
  if (!phoneNumber) {
    return "";
  }

  // Remove all non-numeric characters
  phoneNumber = phoneNumber.replace(/\D/g, "");

  // Determine the format  on the length of the number
  if (phoneNumber.length === 10) {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if (phoneNumber.length === 11) {
    return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } else {
    // Return the original input if it doesn't match the expected lengths
    return phoneNumber;
  }
};

const getOrderState = (state) => {
  if (state === "START") return "발행완료";
  if (state === "CHECK") return "주문확인";
  if (state === "DONE") return "배송완료";
  if (state === "TESTED") return "검수완료";
  if (state === "END") return "정산";
  if (state === "CANCEL") return "취소";
  if (state === "REJECT") return "반려";

  return "";
};

const getOrderType = (type) => {
  if (type === "A") return "앱주문";
  if (type === "S") return "일회성 입찰 주문";
  if (type === "T") return "단가 계약기반 주문";
  if (type === "P") return "주문서 역발행";
  if (type === "Q") return "업체 지정 주문";
  if (type === "R") return "반품";
  if (type === "B") return "PB상품 주문";
  if (type === "D") return "PB상품 주문";

  return "";
};

const convertByte = (byte) => {
  if (!byte) return;

  const kb = 1024;
  const fraction = 2;
  const notations = ["Byte", "KB", "MB", "GB"];

  const exponent = Math.floor(Math.log(byte) / Math.log(kb));
  const size = parseFloat((byte / Math.pow(kb, exponent)).toFixed(fraction));
  const notation = notations[exponent];

  return `${size} ${notation}`;
};

export default function OrderDetail(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderNo } = useParams();

  const [generating, setGenerating] = useState(false);
  const [detailInfo, setDetailInfo] = useState({});
  const [imgList, setImgList] = useState([]);
  const [ordersGroupList, setOrdersGroupList] = useState();

  // 주문서 발행 취소를 요청하시겠습니까? 팝업
  const [orderFormPop, setOrderFormPop] = useState(false);

  // 주문확인 시 배송완료
  const [deliveryDonePop, setDeliveryDonePop] = useState(false);

  //검수완료
  const [loading, setLoading] = useState(false);

  const orderCheckFnc = async () => {
    setLoading(true);
    try {
      const response = await orderCheckApi(orderNo);

      if (response.status === "success") {
        swal("검수완료 되었습니다.", "", "success");
        orderDetailFnc();
      }
    } catch (error) {
      swal("다시 시도해주세요.", "", "warning");
    } finally {
      setLoading(false);
    }
  };

  //검수확인서 다운
  const generateSubmitFnc = async () => {
    swal({
      title: "검수완료 처리하시겠습니까?",
      icon: "info",
      buttons: true,
      content: {
        element: "span",
        attributes: {
          innerHTML: `※ 검수완료 단계로 이동합니다.
                    <dd>검수된 내역 기준으로 정산이 진행됩니다.</dd>
                    `,
        },
      },
    }).then((value) => {
      if (value) {
        orderCheckFnc();
      } else {
        swal("취소하셨습니다", "", "info");
      }
    });
  };

  //검수확인서 다운
  const generateCheckFnc = async () => {
    const response = await generateCheckApi(orderNo);

    if (response.status === "success") {
      // setOrNo(response.data.or_no);
      // setGenerating(false); //case success : loading ui off
      // setGeneratePop(true);

      const link = document.createElement("a");
      link.href = response.data.file_path;
      link.target = "_blank"; // 새 창에서 열기
      link.download = "파일이름.pdf"; // 다운로드 파일 이름 설정
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  //거래명세서 다운
  const showOrderSheetFnc = async () => {
    // setGenerating(true); //loading ui on
    const response = await showOrderSheetApi(orderNo);

    if (response.status === "success") {
      const link = document.createElement("a");
      link.href = response.data.fileRoot;
      link.target = "_blank"; // 새 창에서 열기
      link.download = "파일이름.pdf"; // 다운로드 파일 이름 설정
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const orderDetailFnc = async () => {
    const res = await ordersDetailApi(orderNo);
    if (res.status === "success") {
      setDetailInfo(res.data);
      setImgList(res.data.bidImgInfoList);
      setOrdersGroupList(res.data.ordersGroupList);
    }
  };

  const _move2Success = () => {
    orderDetailFnc();
  };

  useEffect(() => {
    orderDetailFnc();
  }, []);

  useEffect(() => {
    orderDetailFnc();
  }, [orderNo]);

  const onClickReject = async (invoiceNo, rejectReason) => {
    await orderRejectApi(invoiceNo, rejectReason);
  };

  const handleSendClick = () => {
    const value = document.querySelector(".swal-content__textarea").value;

    if (!value) {
      swal("반려사유를 입력해주세요.", "", "warning");
    } else {
      onClickReject(detailInfo?.orders?.no, value);
      swal({
        title: "주문서가 반려되었습니다.",
        text: `${value}`,
        icon: "success",
      }).then((value) => {
        if (value) orderDetailFnc();
      });
    }
  };

  const handleCancelClick = () => {
    swal("입력이 취소되었습니다.", "", "info");
  };

  const Reject = () => {
    swal({
      title: "반려사유 입력",
      icon: "info",
      buttons: {
        cancel: {
          text: "취소",
          value: null,
          visible: true,
        },
        confirm: {
          text: "전송",
          value: true,
          visible: true,
        },
      },
      closeOnClickOutside: false,
      content: {
        element: "textarea",
        attributes: {
          placeholder: "사유를 입력해주세요.",
          rows: 4,
          style: "resize:none",
        },
      },
    }).then((value) => {
      if (value) handleSendClick();
      if (!value) handleCancelClick();
    });
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const [hoveredTab, setHoveredTab] = useState(null);

  const selectTabHandler = (index) => {
    setSelectedTab(index);
  };

  const hoverTabHandler = (index) => {
    setHoveredTab(index);
  };

  const renderDiff = (diff) =>
    ordersGroupList?.length !== 1 &&
    diff !== null &&
    diff !== 0 && (
      <S.Diff diff={diff}>
        ({diff > 0 ? "+" : ""}
        {diff?.toLocaleString("ko")})
      </S.Diff>
    );

  const totalAmountDiff =
    detailInfo.orders?.totalAmount -
    detailInfo?.ordersGroupList?.[0]?.beforeTotalAmount;

  const moveToReviewPage = () => {
    navigate(`/management/quick_order/review/${orderNo}`, {
      state: {
        or_c_no: detailInfo.orders?.companyNo,
        or_no: orderNo,
        type: "normal",
      },
    });
  };

  const moveToOrderListPage = () => {
    navigate("/management/order/1");
  };

  const onClickDeliveryDone = () => {
    setDeliveryDonePop(true);
  };

  const onClickOrderCancel = () => {
    setOrderFormPop(true);
  };

  const [reorderUnitPriceSuccessPop, setReorderUnitPriceSuccessPop] =
    useState(false);
  const [reorderUnitPriceFailPop, setReorderUnitPriceFailPop] = useState(false);
  const [reorderPBPop, setReorderPBPop] = useState(false);
  const [addProductPop, setAddProductPop] = useState(false);

  const excel = useSelector((state) => state.excel);

  const convertedProductList = detailInfo?.productInfoList?.map((product) => [
    { value: product.bidProduct.name },
    { value: product.bidProduct.standard },
    { value: product.bidProduct.unit },
    { value: product.bidProduct.count },
    { value: product.bidProduct.brand },
    { value: product.bidProduct.etc },
  ]);

  const checkReduxExcelRowEmpty = (row) => {
    return row.every(
      (cell, i) =>
        cell.value === "" ||
        (i === 4 && (cell.value === "" || cell.value === "무관"))
    );
  };

  const checkReduxExcelAllEmpty = (data) => {
    return data.every((row) => checkReduxExcelRowEmpty(row));
  };

  const onClickAddProduct = () => {
    if (checkReduxExcelAllEmpty(excel)) {
      dispatch(actions.setExcel(convertedProductList));
      return;
    }

    if (!checkReduxExcelAllEmpty(excel)) {
      dispatch(actions.setExcel([...excel, ...convertedProductList]));
      return;
    }
  };

  const checkReorderState = async () => {
    if (isPB) {
      setReorderPBPop(true);
      return;
    }

    try {
      const response = await checkReorderStateApi({
        type: isUnitPrice ? "contract" : "company",
        no: isUnitPrice ? detailInfo.contractNo : detailInfo?.orders?.companyNo,
      });

      if (response.data.resultCode === "ok" && isUnitPrice) {
        setReorderUnitPriceSuccessPop(true);
        return;
      }

      if (response.data.resultCode === "contract_not_alive") {
        setReorderUnitPriceFailPop(true);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickReorder = () => {
    checkReorderState();
  };

  const { isFixed } = useScrollFixed(isDesktop ? 200 : 300);

  const isStart = detailInfo?.orders?.state === "START";
  const isCheck = detailInfo?.orders?.state === "CHECK";
  const isDone = detailInfo?.orders?.state === "DONE";
  const isTested = detailInfo?.orders?.state === "TESTED";
  const isEnd = detailInfo?.orders?.state === "END";
  const isCancel = detailInfo?.orders?.state === "CANCEL";
  const isReject = detailInfo?.orders?.state === "REJECT";

  const isSingle = detailInfo.bidType === "S";
  const isUnitPrice = detailInfo.bidType === "T";
  const isReverse = detailInfo.bidType === "P";
  const isPartnerAssigned = detailInfo.bidType === "Q";
  const isPB = detailInfo.bidType === "B" || detailInfo.bidType === "D";
  const isReturn = detailInfo.bidType === "R";

  return (
    <div className="page_container">
      <Mheader title="주문서 상세 내역" />
      <section className="header_section">
        <div className="header_desc">
          <p>상세정보</p>
          <p>주문 내역과 상태를 확인할 수 있습니다.</p>
        </div>

        <div className="header_button">
          {(isTested || isEnd) && (
            <Button
              size="medium"
              type="secondary"
              withIcon
              icon={<DownloadSVG size="small" stroke="#11366B" />}
              iconPosition="end"
              onClick={generateCheckFnc}
            >
              검수확인서
            </Button>
          )}
          {(isDone || isTested || isEnd) && (
            <Button
              size="medium"
              type="secondary"
              withIcon
              icon={<DownloadSVG size="small" stroke="#11366B" />}
              iconPosition="end"
              onClick={showOrderSheetFnc}
            >
              거래명세서
            </Button>
          )}
          {isTested && (
            <Button
              size={isDesktop ? "medium" : "small"}
              type="secondary"
              onClick={moveToReviewPage}
            >
              리뷰 작성
            </Button>
          )}
        </div>
      </section>

      <Divider className="header_section_divider" />

      <section className="card_section">
        <div className="card_order_no_container">
          <span>주문번호 : {detailInfo?.orders?.code || "-"}</span>
          <span>
            발행시간 : {detailInfo.orders?.createdDate.slice(0, 16) || "-"}
          </span>
        </div>

        <div className="card_order_contents_container">
          <div className="card_order_contents_chip_container">
            <TypeChip type={detailInfo.bidType} />
            <StateChip state={detailInfo.orders?.state} />
          </div>

          <div className="card_order_contents_title_container">
            <span className="card_order_contents_title">
              {detailInfo?.orders?.title || "-"}
            </span>
            {isDesktop && (
              <Divider
                className="card_order_contents_title_divider"
                type="vertical"
              />
            )}
            <span className="card_order_contents_site_name">
              {detailInfo?.siteName || "-"}
            </span>
          </div>
        </div>
      </section>

      <section className="order_section">
        <div className="order_info_container">
          <p className="order_info_title">주문자</p>
          <Divider className="order_section_divider" />
          <div className="order_info_detail">
            <div>
              <div>
                <p>고객사명</p>
                <p>{detailInfo?.constructionName || "-"}</p>
              </div>
              <div>
                <p>현장명</p>
                <p>{detailInfo?.siteName || "-"}</p>
              </div>
            </div>
            <div>
              <div>
                <p>주문자명</p>
                <p>
                  {detailInfo?.siteUser?.name
                    ? `${detailInfo?.siteUser?.name} ${detailInfo?.siteUser?.rank}`
                    : "-"}
                </p>
              </div>
              <div>
                <p>주문자 연락처</p>
                <p>{formatPhoneNumber(detailInfo?.siteUser?.phone) || "-"}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="order_info_container">
          <p className="order_info_title">주문 정보</p>
          <Divider className="order_section_divider" />
          <div className="order_info_detail">
            <div>
              <div>
                <p>주문번호</p>
                <p>{detailInfo?.orders?.code || "-"}</p>
              </div>
              <div>
                <p>주문상태</p>
                <p>{getOrderState(detailInfo?.orders?.state)}</p>
              </div>
            </div>
            {isPartnerAssigned && (
              <div>
                <div>
                  <p>주문 / 입찰 방식</p>
                  <p>{getOrderType(detailInfo.bidType)}</p>
                </div>
                <div>
                  <p>지정 업체</p>
                  <p>{detailInfo?.companyName || "-"}</p>
                </div>
              </div>
            )}
            {isPB && (
              <div>
                <div>
                  <p>주문 / 입찰 방식</p>
                  <p>{getOrderType(detailInfo.bidType)}</p>
                </div>
                <div>
                  <p></p>
                  <p></p>
                </div>
              </div>
            )}
            {isSingle && (
              <div>
                <div>
                  <p>주문 / 입찰 방식</p>
                  <p>{getOrderType(detailInfo.bidType)}</p>
                </div>
                <div>
                  <p>지정 업체</p>
                  <p>{detailInfo?.companyName || "-"}</p>
                </div>
              </div>
            )}
            {isUnitPrice && (
              <>
                <div>
                  <div>
                    <p>주문 / 입찰 방식</p>
                    <p>{getOrderType(detailInfo.bidType)}</p>
                  </div>
                  <div>
                    <p>계약번호</p>
                    <p>
                      <a
                        className="order_info_detail_origin_code"
                        href={`/management/contract/document/${detailInfo?.contractNo}`}
                      >
                        {detailInfo?.contractCode || "-"}
                      </a>
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <p>계약 공급사</p>
                    <p>{detailInfo?.companyName || "-"}</p>
                  </div>
                  <div>
                    <p>계약기간</p>
                    <p>{detailInfo?.bidTerm || "-"}개월</p>
                  </div>
                </div>
              </>
            )}
            {isReverse && (
              <div>
                <div>
                  <p>주문 / 입찰 방식</p>
                  <p>{getOrderType(detailInfo.bidType)}</p>
                </div>
                <div>
                  <p>공급사명</p>
                  <p>{detailInfo?.companyName || "-"}</p>
                </div>
              </div>
            )}
            {isReturn && (
              <div>
                <div>
                  <p>주문 / 입찰 방식</p>
                  <p>
                    주문 건 반품 (기존 주문번호{" "}
                    <span>
                      <a
                        className="order_info_detail_origin_code"
                        href={`/management/order/document/${detailInfo?.orders?.originOrdersNo}`}
                      >
                        {detailInfo?.originOrdersCode || "-"}
                      </a>
                    </span>
                    )
                  </p>
                </div>
                <div>
                  <p>공급사명</p>
                  <p>{detailInfo?.companyName || "-"}</p>
                </div>
              </div>
            )}
            <div>
              <div>
                <p>공급사 담당자</p>
                <p>
                  {detailInfo?.companyUser?.name
                    ? `${detailInfo?.companyUser?.name} ${detailInfo?.companyUser?.rank}`
                    : "-"}
                </p>
              </div>
              <div>
                <p>공급사 담당 연락처</p>
                <p>
                  {formatPhoneNumber(detailInfo?.companyUser?.phone) || "-"}
                </p>
              </div>
            </div>

            <div />

            <div>
              <div>
                <p>수령인</p>
                <p>{detailInfo?.orders?.recipient || "-"}</p>
              </div>
              <div>
                <p>수령인 연락처</p>
                <p>{formatPhoneNumber(detailInfo?.orders?.phone) || "-"}</p>
              </div>
            </div>
            <div>
              <div>
                <p>납품 기한</p>
                <p>{detailInfo?.orders?.delDate?.slice(0, 16) || "-"} 까지</p>
              </div>
              <div>
                <p>납품 주소</p>
                <p>
                  {detailInfo?.orders?.addr} {detailInfo?.orders?.detail}
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>배송비</p>
                <p>배송비 포함</p>
              </div>
              <div>
                <p>하차 역무</p>
                <p>하차 역무 있음</p>
              </div>
            </div>
            <div>
              <div>
                <p>비용 정산</p>
                <p>공새로 위임 정산</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product_section">
        <p className="product_section_title">품목 내역</p>
        <Divider className="product_section_divider" />
        <S.Wrap>
          {ordersGroupList?.map((_, index) => (
            <S.TabWrap key={index}>
              {selectedTab === index ? (
                <a data-tip="React-tooltip">
                  <S.SelectedTabBtn
                    onClick={() => {
                      selectTabHandler(index);
                    }}
                    onMouseEnter={() => {
                      hoverTabHandler(index);
                    }}
                  >
                    {index === 0
                      ? `${ordersGroupList.length}(최종)`
                      : ordersGroupList.length - index}
                  </S.SelectedTabBtn>
                  {isDesktop && (
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      backgroundColor="#002863"
                    >
                      <span>
                        수정일시 :{" "}
                        {ordersGroupList[hoveredTab]?.createdDate
                          .replace("T", " ")
                          .slice(0, 16)}
                      </span>
                    </ReactTooltip>
                  )}
                </a>
              ) : (
                <a data-tip="React-tooltip">
                  <S.TabBtn
                    onClick={() => {
                      selectTabHandler(index);
                    }}
                    onMouseEnter={() => {
                      hoverTabHandler(index);
                    }}
                  >
                    {index === 0
                      ? `${ordersGroupList.length}(최종)`
                      : ordersGroupList.length - index}
                  </S.TabBtn>
                  {isDesktop && (
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      backgroundColor="#002863"
                    >
                      <span>
                        수정일시 :{" "}
                        {ordersGroupList[hoveredTab]?.createdDate
                          .replace("T", " ")
                          .slice(0, 16)}
                      </span>
                    </ReactTooltip>
                  )}
                </a>
              )}
            </S.TabWrap>
          ))}
        </S.Wrap>

        {ordersGroupList?.map((ordersGroup, index, array) => (
          <OrdersModifyHistoryTabs
            ordersGroup={ordersGroup}
            ordersGroupNo={ordersGroup.no}
            key={ordersGroup.no}
            index={index}
            arrayLength={array.length - 1}
            selectedTab={selectedTab}
          />
        ))}

        <div style={{ position: "relative" }}>
          {!selectedTab && (
            <div className="subject_ok_table">
              {ordersGroupList?.length !== 1 && (
                <div
                  style={{
                    position: "absolute",
                    top: "-30px",
                    right: 0,
                    zIndex: 10,
                    height: "30px",
                    lineHeight: "30px",
                  }}
                >
                  <span>품목 추가 </span>
                  <span style={{ color: "#FF3B30" }}>
                    +{ordersGroupList?.[0]?.modifyAdd?.toLocaleString("ko")}
                  </span>
                  <span> 품목 삭제 </span>
                  <span style={{ color: "#0061FD" }}>
                    -{ordersGroupList?.[0]?.modifyDelete?.toLocaleString("ko")}
                  </span>
                </div>
              )}

              <table>
                <tbody>
                  <tr>
                    <th>
                      <p>품목명</p>
                    </th>
                    <th>
                      <p>규격</p>
                    </th>
                    <th>
                      <p style={{ lineHeight: "1.3" }}>
                        제조국
                        <br />
                        (브랜드)
                      </p>
                    </th>
                    <th>
                      <p>단위</p>
                    </th>
                    <th>
                      <p>수량</p>
                    </th>
                    <th>
                      <p>단가</p>
                    </th>
                    <th>
                      <p>금액</p>
                    </th>
                    <th>
                      <p>비고</p>
                    </th>
                  </tr>
                  {detailInfo.productInfoList?.map((data) => {
                    const curCount = data?.bidProduct?.count;
                    const beforeCount = data?.beforeCount;
                    const countDiff = curCount - beforeCount;

                    const curPrice = data?.price;
                    const beforePrice = data?.beforePrice;
                    const priceDiff = curPrice - beforePrice;

                    const curTotal = curCount * curPrice;
                    const totalDiff = curTotal - beforeCount * beforePrice;

                    return (
                      <tr
                        key={data?.bidProduct?.no}
                        className={
                          data.state === "ADD" ? "subject_t_add" : null
                        }
                      >
                        <td>
                          <p className="p_list_standard limit_max_height">
                            {data.bidProduct?.name}
                          </p>
                        </td>
                        <td>
                          <p className="p_list_standard limit_max_height">
                            {data.bidProduct?.standard?.replace(/, /gi, "\n")}
                          </p>
                        </td>
                        <td>{data.bidProduct?.brand}</td>
                        <td>{data.bidProduct?.unit}</td>
                        <td>
                          <div>{curCount?.toLocaleString("ko")}</div>
                          {renderDiff(countDiff)}
                        </td>
                        <td>
                          <div>{curPrice?.toLocaleString("ko")}</div>
                          {renderDiff(priceDiff)}
                        </td>
                        <td>
                          <div>{curTotal?.toLocaleString("ko")}</div>
                          {renderDiff(totalDiff)}
                        </td>
                        <td>
                          <span className="limit_max_height">
                            {data.bidProduct?.etc || "-"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan="6">총 합계</td>
                    <td>
                      <div>
                        <strong>
                          {detailInfo.orders?.totalAmount?.toLocaleString("ko")}
                        </strong>
                      </div>
                      <strong>{renderDiff(totalAmountDiff)}</strong>
                    </td>
                    <td>
                      <input type="text" readOnly />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </section>

      <section className="additional_section">
        <p className="additional_section_title">추가 정보</p>
        <Divider className="additional_section_divider" />
        <div className="additional_detail">
          <div>
            <p>고객사(주문) 비고</p>
            <p>{detailInfo.orders?.uniqueness || "-"}</p>
          </div>
          <div>
            <p>공급사 비고</p>
            <p>{detailInfo.orders?.companyComment || "-"}</p>
          </div>
          {isCancel && (
            <div>
              <p>취소 사유</p>
              <p>{detailInfo.orders?.cancelReason || "-"}</p>
            </div>
          )}
          {isReject && (
            <div>
              <p>반려 사유</p>
              <p>{detailInfo.orders?.rejectReason || "-"}</p>
            </div>
          )}
          <div>
            <p>첨부파일</p>
            <div className="additional_file_container">
              {imgList.length !== 0 ? (
                imgList.map((img) => (
                  <a
                    className="additional_file_download_button"
                    key={img.fileName}
                    href={img.fileRoot}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="additional_file_name_container">
                      <p>{img.fileName}</p>
                      <p>{convertByte(img.fileLength)}</p>
                    </div>
                    <DownloadSVG size="small" stroke="#1B1D1F" />
                  </a>
                ))
              ) : (
                <p className="additional_file_name_default">-</p>
              )}
            </div>
          </div>
        </div>
      </section>

      {isStart && (
        <section className="warning_section">
          <span>
            *고객사 및 공급사의 일방적인 취소에 따른 패널티가 발생할 수
            있습니다. 취소 및 중단 시 상호간의 협의 후 진행해주세요.
          </span>
          <span onClick={onClickOrderCancel}>주문 취소</span>
        </section>
      )}

      <section
        className={classNames("bottom_button_section", {
          desktop_fixed: isDesktop && isFixed,
          mobile_fixed: isMobile && isFixed,
        })}
      >
        {isDesktop && (
          <Button size="medium" type="secondary" onClick={moveToOrderListPage}>
            목록으로
          </Button>
        )}
        {isCheck && (
          <Button size="medium" type="primary" onClick={onClickDeliveryDone}>
            배송 완료
          </Button>
        )}
        {isDone && (
          <div className="bottom_button_container">
            <Button size="medium" type="danger" onClick={Reject}>
              검수 반려
            </Button>
            <Button
              size="medium"
              type="primary"
              onClick={() => generateSubmitFnc(detailInfo.orders?.state)}
            >
              검수 완료
            </Button>
          </div>
        )}
        {!isReturn && (isTested || isEnd) && (isUnitPrice || isPB) && (
          <Button size="medium" type="primary" onClick={onClickReorder}>
            재주문 하기
          </Button>
        )}
        {!isReturn && (isTested || isEnd) && !(isUnitPrice || isPB) && (
          <Button
            size="medium"
            type="primary"
            onClick={() => {
              onClickAddProduct();
              setAddProductPop(true);
            }}
          >
            내역 담기
          </Button>
        )}
        {!isReturn && isCancel && (
          <Button
            size="medium"
            type="primary"
            onClick={() => {
              onClickAddProduct();
              setAddProductPop(true);
            }}
          >
            내역 담기
          </Button>
        )}
      </section>

      {orderFormPop && (
        <OrderFormCancelPop
          setOrderFormPop={setOrderFormPop}
          orderNum={detailInfo.orders?.no}
          move2Success={_move2Success}
          bidType={detailInfo.bidType}
        />
      )}
      {deliveryDonePop && (
        <DeliveryDonePop
          orderNo={orderNo}
          setDeliveryDonePop={setDeliveryDonePop}
          orderDetailFnc={orderDetailFnc}
        />
      )}
      {reorderUnitPriceSuccessPop && (
        <ReorderUnitPriceSuccessPop
          navigate={navigate}
          contractNo={detailInfo?.contractNo}
          reorderUnitPriceSuccessPop={reorderUnitPriceSuccessPop}
          setReorderUnitPriceSuccessPop={setReorderUnitPriceSuccessPop}
        />
      )}
      {reorderUnitPriceFailPop && (
        <ReorderUnitPriceFailPop
          navigate={navigate}
          contractNo={detailInfo?.contractNo}
          onClickAddProduct={onClickAddProduct}
          reorderUnitPriceFailPop={reorderUnitPriceFailPop}
          setReorderUnitPriceFailPop={setReorderUnitPriceFailPop}
        />
      )}
      {reorderPBPop && (
        <ReorderPBPop
          navigate={navigate}
          reorderPBPop={reorderPBPop}
          setReorderPBPop={setReorderPBPop}
        />
      )}
      {addProductPop && (
        <AddProductPop
          navigate={navigate}
          addProductPop={addProductPop}
          setAddProductPop={setAddProductPop}
        />
      )}
      {generating && <Loading msg={"검수확인서 생성중입니다."} />}
      {loading && <Loading />}
    </div>
  );
}
