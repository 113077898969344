import { Divider } from "antd";
import { useNavigate } from "react-router-dom";
import useSignupHandler from "../../../hooks/handler/useSignupHandler";

import Title from "./components/elements/title/Title";
import EmailInput from "./components/elements/input/EmailInput";
import PwdInput from "./components/elements/input/PwdInput";
import PwdCheckInput from "./components/elements/input/PwdCheckInput";
import NameInput from "./components/elements/input/NameInput";
import OrgNameInput from "./components/elements/input/OrgNameInput";
import OrgSearchButton from "./components/elements/button/OrgSearchButton";
import DeptInput from "./components/elements/input/DeptInput";
import RankSelect from "./components/elements/select/RankSelect";
import PhoneInput from "./components/elements/input/PhoneInput";
import PhoneCheckButton from "./components/elements/button/PhoneCheckButton";
import CodeInput from "./components/elements/input/CodeInput";
import CodeCheckButton from "./components/elements/button/CodeCheckButton";
import AgreementCheckbox from "./components/elements/checkbox/AgreementCheckbox";
import CancelButton from "./components/elements/button/CancelButton";
import SignupButton from "./components/elements/button/SignupButton";
import OrgSearchModal from "./components/modal/OrgSearchModal";
import AgreementCheckModal from "./components/modal/AgreementCheckModal";

import s from "./signup.module.css";

const Signup = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    errors,
    setValue,
    setError,
    clearErrors,
    phone,
    code,
    phoneInputDisable,
    phoneBtnDisable,
    codeInputDisable,
    codeBtnDisable,
    checkedList,
    setCheckedList,
    agreementCheckModal,
    setAgreementCheckModal,
    orgSearchModal,
    setOrgSearchModal,
    handleCheckEmail,
    handleCheckPhone,
    handleCheckCode,
    handleSignup,
    time,
    isActive,
    orgNameFromInviteCode,
  } = useSignupHandler();

  return (
    <div className={s.page_container}>
      <section className={s.title_container}>
        <Title />
      </section>

      <Divider className={s.divider} />

      <form className={s.form_container} onSubmit={handleSubmit(handleSignup)}>
        <section className={s.input_container}>
          <EmailInput
            control={control}
            errors={errors}
            handleCheckEmail={handleCheckEmail}
          />
          <PwdInput control={control} errors={errors} />
          <PwdCheckInput control={control} errors={errors} />
          <NameInput control={control} errors={errors} />
          <div className={s.input_with_button_container}>
            <OrgNameInput control={control} errors={errors} />
            <OrgSearchButton
              setOrgSearchModal={setOrgSearchModal}
              orgNameFromInviteCode={orgNameFromInviteCode}
            />
          </div>
          <DeptInput control={control} errors={errors} />
          <RankSelect control={control} setValue={setValue} errors={errors} />
          <div className={s.input_with_button_container}>
            <PhoneInput
              control={control}
              errors={errors}
              phoneInputDisable={phoneInputDisable}
            />
            <PhoneCheckButton
              errors={errors}
              phone={phone}
              handleCheckPhone={handleCheckPhone}
              phoneBtnDisable={phoneBtnDisable}
            />
          </div>
          <div className={s.input_with_button_container}>
            <CodeInput
              control={control}
              errors={errors}
              time={time}
              isActive={isActive}
              codeBtnDisable={codeBtnDisable}
              codeInputDisable={codeInputDisable}
            />
            <CodeCheckButton
              errors={errors}
              code={code}
              handleCheckCode={handleCheckCode}
              codeBtnDisable={codeBtnDisable}
            />
          </div>
        </section>

        <section className={s.agreement_container}>
          <AgreementCheckbox
            checkedList={checkedList}
            setCheckedList={setCheckedList}
          />
        </section>

        <section className={s.button_container}>
          <CancelButton navigate={navigate} />
          <SignupButton />
        </section>
      </form>

      {agreementCheckModal && (
        <AgreementCheckModal
          agreementCheckModal={agreementCheckModal}
          setAgreementCheckModal={setAgreementCheckModal}
        />
      )}
      {orgSearchModal && (
        <OrgSearchModal
          orgSearchModal={orgSearchModal}
          setOrgSearchModal={setOrgSearchModal}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
        />
      )}
    </div>
  );
};

export default Signup;
