import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import Button from "../../../../../../component/ui/button/Button";
import { COLOR } from "../../../../../../constant/Palette";

const WithdrawFailModal = ({ withdrawFailModal, setWithdrawFailModal }) => {
  const onClickCancel = () => {
    setWithdrawFailModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="error"
      open={withdrawFailModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.RED.L10}>
          회원 탈퇴 오류
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button type="primary" size="small" onClick={onClickCancel}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        비밀번호가 일치하지 않습니다.
      </Typography>
    </Modal>
  );
};

export default WithdrawFailModal;
