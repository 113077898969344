import Chip from "../../../../../component/ui/chip/Chip";

const stateConfig = {
  PENDING: { color: "yellow", label: "입금대기" },
  READY: { color: "yellow", label: "배송대기" },
  DONE: { color: "blue", label: "배송완료" },
  CANCEL: { color: "red", label: "취소" },
};

export default function StateChip({ state }) {
  const chipConfig = stateConfig[state];

  return chipConfig ? (
    <Chip size="medium" type="default" color={chipConfig.color}>
      {chipConfig.label}
    </Chip>
  ) : null;
}
