import { useNavigate, useParams } from "react-router-dom";
import * as S from "../../../asset/css/pbOrder.styles.js";
import "../../../asset/css/estimate.css";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import Mheader from "../../../component/common/Mheader.js";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchPbOrderListApi } from "../../../api/auth.js";
import Pagination from "../../../component/ui/pagination/Pagination.js";

export default function PbOrder() {
  const navigate = useNavigate();
  const { page } = useParams();
  const user = useSelector((state) => state.user);

  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });

  const url = "/management/pb/order/";

  const [firstData, setFirstData] = useState({
    page: page,
    siteNo: user?.site_num,
    startDate: "2021-01-01",
    endDate: moment().format("yyyy-MM-DD"),
    state: "ALL",
  });
  const [pbOrderList, setPbOrderList] = useState([]);
  const [maxNum, setMaxNum] = useState(0);
  const [stateCount, setStateCount] = useState("");
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);

  const fetchPbOrderList = async () => {
    try {
      const response = await fetchPbOrderListApi({
        page: currentPage,
        siteNo: firstData.siteNo,
        startDate: firstData.startDate,
        endDate: firstData.endDate,
        state: firstData.state,
      });
      setPbOrderList(response.data.pbOrdersInfoList);
      setMaxNum(response.data.maxNum);
      setStateCount(response.data.stateCount);
    } catch (error) {
      console.error(error);
    }
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("전체");

  const onChangeSelectValue = (e) => {
    setSelectValue(e.currentTarget.value);
    setCurrentPage(1);
  };

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setFirstData({
      ...firstData,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    });
  };

  useEffect(() => {
    if (selectValue === "최근 1개월") setDate(1, "M");
    if (selectValue === "최근 3개월") setDate(3, "M");
    if (selectValue === "최근 6개월") setDate(6, "M");
    if (selectValue === "최근 1년") setDate(1, "y");
    if (selectValue === "전체") {
      setStartDate(new Date("2021-01-01"));
      setEndDate(new Date(moment().format("yyyy-MM-DD")));
      setFirstData({
        ...firstData,
        startDate: "2021-01-01",
        endDate: moment().format("yyyy-MM-DD"),
      });
    }
  }, [selectValue]);

  useEffect(() => {
    fetchPbOrderList();
  }, [firstData, page]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`${url}${page}`);
  };

  return (
    <S.Wrapper>
      <Mheader title="PB상품 주문관리" />
      <S.DashboardContainer>
        <S.BankContainer>
          <div>
            <S.Holder>예금주명 (주)공새로</S.Holder>
            {isDesktop && (
              <>
                <div>|</div>
                <S.BankName>우리은행</S.BankName>
                <S.Account>1005-004-232090</S.Account>
              </>
            )}
            {isMobile && <S.BankName>우리은행 1005-004-232090</S.BankName>}
          </div>
          <S.Instruction>*입금확인 이후 배송이 진행됩니다.</S.Instruction>
        </S.BankContainer>

        <S.DashBoardContent>
          <S.ContentHeader>
            <S.TitleWrap>
              <S.Title>PB 주문관리 현황</S.Title>
              {isDesktop && (
                <>
                  <div>|</div>
                  <S.SelectedDate>
                    {firstData?.startDate} ~ {firstData?.endDate}
                  </S.SelectedDate>
                </>
              )}
            </S.TitleWrap>

            {isDesktop && (
              <S.AlignWrap>
                <S.DateRangeSelect
                  onChange={onChangeSelectValue}
                  value={selectValue}
                >
                  <option>최근 1개월</option>
                  <option>최근 3개월</option>
                  <option>최근 6개월</option>
                  <option>최근 1년</option>
                  <option>전체</option>
                </S.DateRangeSelect>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setFirstData({
                        ...firstData,
                        startDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setFirstData({
                        ...firstData,
                        endDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>
              </S.AlignWrap>
            )}
          </S.ContentHeader>

          <S.ContentBody>
            <S.EstimateStateWrap>
              <S.EstimateState>입금대기</S.EstimateState>
              <S.EstimatePendingBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    state: "PENDING",
                  });
                  navigate(url + "1");
                }}
              >
                {stateCount?.pending}건
              </S.EstimatePendingBtn>
            </S.EstimateStateWrap>

            <S.EstimateStateWrap>
              <S.EstimateState>배송대기</S.EstimateState>
              <S.EstimateReadyBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    state: "READY",
                  });
                  navigate(url + "1");
                }}
              >
                {stateCount?.ready}건
              </S.EstimateReadyBtn>
            </S.EstimateStateWrap>

            <S.EstimateStateWrap>
              <S.EstimateState>배송완료</S.EstimateState>
              <S.EstimateDoneBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    state: "DONE",
                  });
                  navigate(url + "1");
                }}
              >
                {stateCount?.done}건
              </S.EstimateDoneBtn>
            </S.EstimateStateWrap>

            {isDesktop && <S.DivideLine></S.DivideLine>}

            <S.EstimateStateWrap>
              <S.EstimateState>전체보기</S.EstimateState>
              <S.EstimateAllBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    state: "ALL",
                  });
                  navigate(url + "1");
                }}
              >
                {Object.values(stateCount)?.reduce(
                  (acc, cur) => +acc + +cur,
                  0
                )}
                건
              </S.EstimateAllBtn>
            </S.EstimateStateWrap>
          </S.ContentBody>
        </S.DashBoardContent>

        {isDesktop && (
          <>
            <S.SearchResultWrap>
              <S.SearchResult>
                총 <span>{maxNum}</span>건이 검색되었습니다.
              </S.SearchResult>
            </S.SearchResultWrap>

            {pbOrderList?.map((el, idx) => (
              <S.EstimateListWrapper key={idx}>
                <S.ListHeader>
                  <S.EstimateNo>주문번호 : {el.pbOrders.code}</S.EstimateNo>
                  <S.SiteName>현장명 : {el.siteName}</S.SiteName>
                  <S.EstimateStep el={el}>
                    <S.Step>
                      <p>입금대기</p>
                    </S.Step>
                    <S.Step>
                      <p>배송대기</p>
                    </S.Step>
                    <S.Step>
                      <p>배송완료</p>
                    </S.Step>
                  </S.EstimateStep>
                </S.ListHeader>

                <S.ListBody>
                  <S.ListInfo>
                    <S.Badge el={el}>
                      {el.pbOrders.state === "PENDING" && "입금대기"}
                      {el.pbOrders.state === "READY" && "배송대기"}
                      {el.pbOrders.state === "DONE" && "배송완료"}
                      {el.pbOrders.state === "CANCEL" && "취소"}
                    </S.Badge>
                    <S.ReBadge el={el}>
                      {el.pbOrders.pbTaxInvoiceNo && "세금계산서 발행완료"}
                      {!el.pbOrders.pbTaxInvoiceNo && "세금계산서 미발행"}
                    </S.ReBadge>
                    <S.NameWrapper>
                      <S.CompanyName>(주)공새로</S.CompanyName>
                      <div>|</div>
                      <S.ProductName>{el.pbOrders.title}</S.ProductName>
                    </S.NameWrapper>
                    <S.DetailInfo>
                      <S.PbCreatedDate>{`발행시간 : ${el.pbOrders.createdDate}`}</S.PbCreatedDate>
                      <S.PbAmount>{`금액 : ${el.pbOrders.totalVatAmount?.toLocaleString(
                        "ko"
                      )}원 (부가세 포함)`}</S.PbAmount>
                      <S.PbDepositDate>{`입금확인일 : ${
                        el.pbOrders.depositDate || "미확인"
                      }`}</S.PbDepositDate>
                    </S.DetailInfo>
                  </S.ListInfo>
                  <S.BtnWrapper>
                    <S.EstimateContentBtn
                      onClick={() => {
                        navigate(
                          `/management/pb/order/document/${el.pbOrders.no}`
                        );
                      }}
                    >
                      주문서 상세 내역
                    </S.EstimateContentBtn>
                  </S.BtnWrapper>
                </S.ListBody>
              </S.EstimateListWrapper>
            ))}
            <div className="estimate_pagination_container">
              <Pagination
                current={currentPage}
                total={maxNum}
                onChange={handlePageChange}
                pageSize={10}
              />
            </div>
          </>
        )}
      </S.DashboardContainer>

      {isMobile && (
        <div className="estimate_wrap">
          <S.AlignWrap>
            <S.DateWrap>
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setFirstData({
                    ...firstData,
                    startDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>

            <S.DateWrap>
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setFirstData({
                    ...firstData,
                    endDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>
          </S.AlignWrap>

          <S.SearchResultWrap>
            <S.SearchResult>
              검색결과 : <span>{maxNum}</span>건
            </S.SearchResult>
          </S.SearchResultWrap>

          <div className="estimate_container">
            <div className="contract_list">
              <div className="contract_list_btm">
                {pbOrderList.map((el, i) => {
                  return (
                    <div className="contract_content" key={el.pbOrders.code}>
                      <div className="content_top">
                        <span>주문번호 :&nbsp;</span>
                        <span className="public_code">{el.pbOrders.code}</span>
                      </div>
                      <div className="content_btm">
                        <div className="content_box">
                          <div className="contract_info">
                            <div className="icon_box">
                              <S.Badge el={el}>
                                {el.pbOrders.state === "PENDING" && "입금대기"}
                                {el.pbOrders.state === "READY" && "배송대기"}
                                {el.pbOrders.state === "DONE" && "배송완료"}
                                {el.pbOrders.state === "CANCEL" && "취소"}
                              </S.Badge>
                              <S.ReBadge el={el}>
                                {el.pbOrders.pbTaxInvoiceNo &&
                                  "세금계산서 발행완료"}
                                {!el.pbOrders.pbTaxInvoiceNo &&
                                  "세금계산서 미발행"}
                              </S.ReBadge>
                            </div>
                            <p className="contractor">
                              <span>{`[(주)공새로] ${el.siteName}`}</span>
                            </p>
                            <div className="contract_text">
                              <p>{`발행시간 : ${el.pbOrders.createdDate}`}</p>
                              <p className="bidding_time">
                                {`금액 : ${el.pbOrders.totalVatAmount?.toLocaleString(
                                  "ko"
                                )}원 (부가세 포함)`}
                              </p>
                              <p>{`입금확인일 : ${
                                el.pbOrders.depositDate || "미확인"
                              }`}</p>
                            </div>
                          </div>
                        </div>
                        <div className="contract_more">
                          <p
                            onClick={() => {
                              navigate(
                                `/management/pb/order/document/${el.pbOrders.no}`
                              );
                            }}
                          >
                            주문서 상세 내역
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="estimate_pagination_container">
                <Pagination
                  current={currentPage}
                  total={maxNum}
                  onChange={handlePageChange}
                  pageSize={10}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </S.Wrapper>
  );
}
