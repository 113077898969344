import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { poscoLoginApi } from "../../api/auth";

//redux
import { useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default";

export default function PoscoLogin(props) {
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  // uri 안에 ' / ' 가 있는 경우 Param이 잘리기 때문에 분기해줘야함.
  // Route = main or invoice
  const { route, id, "*": asteriskValue } = useParams();

  const poscoLoginFnc = async () => {
    // 포스코 enc에서 주는 값을 받아주면 됨
    const token = asteriskValue ? id + "/" + asteriskValue : id;

    const response = await poscoLoginApi(token);
    if (response.status === "success") {
      if (response.message === "없는 유저") {
        // 없는 유저일 시
        swal({
          title: "회원가입",
          icon: "info",
          buttons: true,
          content: {
            element: "span",
            attributes: {
              innerHTML: `공새로에 등록되지 않은 유저입니다. 회원가입을 진행해주세요.
               <dd>※ 확인버튼을 누르면 회원가입 단계로 이동합니다.</dd>
              `,
            },
          },
        }).then((value) => {
          if (value) {
            navigate("/join_type");
          } else {
            swal("취소하셨습니다", "", "info");
          }
        });
      } else if (response.message === "posco 아님") {
        // 없는 유저일 시
        swal(
          "잘못된 접근입니다.",
          "@poscoenc.com 아이디로 로그인해주세요.",
          "error"
        );
      } else {
        // 성공 시
        setDispatchFnc(response.data);
        if (route === "invoice") {
          navigate("/");
          navigate("/invoice");
        } else {
          navigate("/");
        }
      }
    }
    if (response.status === "fail") {
      dispatch(actions.setToken(false));
      dispatch(actions.setUser(userDefault));
      if (response.message === "없는 유저") {
        swal(
          "",
          "공새로에 가입이 되지 않은 유저입니다. \n 회원가입 페이지로 이동합니다.",
          "error"
        );
        navigate("/join_type");
      } else if (response.message === "posco 아님") {
        swal("", "올바르지 않은 접근입니다.", "error");
        navigate("/join_type");
      } else {
        swal("", "접속에 실패했습니다.\n 다시 시도해주세요.", "error");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    //이미 로그인이 되어있을 경우 한번 물어보기 ((잠시 주석
    // signToken ? alreadyLogin() :
    poscoLoginFnc();
  }, []);

  const setDispatchFnc = async (userInfo) => {
    let poscoUserData = {
      u_no: userInfo.userNo,
      u_name: userInfo.userName,
      u_phone: userInfo?.u_phone,
      constructionType: "CUSTOMER",

      site_num: false,
      rep_site: false,
      rep_con: false,
    };

    dispatch(actions.setUser(poscoUserData));
    dispatch(actions.setToken(userInfo.userToken));
  };

  //   const alreadyLogin = async () => {
  //     swal({
  //       title: "이미 로그인되어있습니다.",
  //       text: "기존 로그인을 끊고,\n 새로운 계정으로 로그인 하시겠습니까?",
  //       icon: "info",
  //       buttons: true,
  //       // dangerMode: false,
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         poscoLoginFnc();
  //       } else {
  //         // swal("취소하셨습니다.");
  //         navigate("/");
  //       }
  //     });
  //   };

  // 화면 설계
  // Case 1 : 이미 로그인 되어있다면? 기존 로그인을 끊고 새로 로그인하시겠습니까?
  //
  // 페이지 흐름 , 해당 아이디로 로그인 되었습니다. [홈으로 이동]
  // 이미 로그인이 되어있다면? '기존 로그인을 끊고 새로 로그인하시겠습니까?' Y = 디스패치 하기 N =
  // 회원가입이 안되어있다면? 회원가입 후 사용가능합니다.
  // 실패했다면(fail) ? 로그인에 실패했습니다. 다시 시도해주세요.

  return <div className="login_wrap">잠시만 기다려주십시오..</div>;
}
