import React, { useState, useEffect } from "react";
//library
import { useNavigate } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/requestComplete.css";
import swal from "sweetalert";
import usePreventBack from "../main/customHooks/usePreventBack";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";
export default function ReOrderComplete() {
  const navigate = useNavigate();

  //뒤로가기 방지
  usePreventBack();

  return (
    <div className="request_complete_wrap">
      <Mheader title="입찰 / 계약 / 주문" />
      <div
        className="request_complete_container"
        style={{ paddingTop: "40px" }}
      >
        <div className="top_box">
          <div className="title">단가 계약기반 주문</div>

          <OrderBreadCrumb
            step1="계약서 선택"
            step2="상세정보 입력"
            step3="상세정보 확인"
            step4="주문완료"
            isActive={4}
          />
        </div>
        <div className="request_complete_text">
          <h2>단가 계약기반 주문 요청이 완료되었습니다.</h2>
          <p>
            <span>
              계약서 기반 주문은 투찰이 진행되지 않으며,
              <br />{" "}
            </span>
            {/* <br /> */}
            <span>공급사에 주문 요청이 진행되었습니다.</span>
          </p>
        </div>
        <div className="btn_box">
          <div className="view_estimate_info">
            <p
              onClick={() => {
                navigate("/management/order/1");
              }}
            >
              주문 정보 보기
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
