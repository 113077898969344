import { useNavigate } from "react-router-dom";
import * as S from "../../asset/css/invoiceListItem.styles";
import InvoiceListItemList from "./InvoiceListItemList";
import { useMediaQuery } from "react-responsive";

export default function InvoiceListItem(props) {
  const { invoiceItem, invoice, fetchInvoiceListItem, codeGroupList } = props;
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });

  const onClickMoveToDetail = () => {
    navigate(`/management/order/document/${invoiceItem.orders.no}`);
  };

  return (
    <S.Wrapper>
      <S.InvoiceItemWrap>
        <S.InvoiceItemCodeWrap>
          <S.InvoiceItemCode>
            {isDesktop && `주문번호 : ${invoiceItem.orders.code}`}
            {isMobile && invoiceItem.orders.code}
          </S.InvoiceItemCode>
          <S.OrderDetailBtn onClick={onClickMoveToDetail}>
            상세보기
          </S.OrderDetailBtn>
        </S.InvoiceItemCodeWrap>
        <S.InvoiceItemDate>
          {isDesktop && `주문 일자 : ${invoiceItem.orders.createdDate}`}
          {isMobile && invoiceItem.orders.createdDate}
        </S.InvoiceItemDate>
        <S.InvoiceItemTotalAmount>
          소계 : {invoiceItem.orders.totalAmount.toLocaleString("ko")}원
        </S.InvoiceItemTotalAmount>
      </S.InvoiceItemWrap>
      <S.InvoiceItemTitle>
        <div>품목번호</div>
        <div>품목명</div>
        <div>수량</div>
        <div>단가</div>
        <div>합계</div>
        <div>정산코드</div>
      </S.InvoiceItemTitle>
      {invoiceItem?.ordersDetailItemList?.map((ordersDetailItem) => (
        <InvoiceListItemList
          key={ordersDetailItem.bidProduct.no}
          ordersDetailItem={ordersDetailItem}
          invoice={invoice}
          fetchInvoiceListItem={fetchInvoiceListItem}
          codeGroupList={codeGroupList}
        />
      ))}
    </S.Wrapper>
  );
}
