import React,{useEffect, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import i_close from '../../../asset/images/w-cancel-icon.png';
//css
import '../../../asset/css/alarmHeader.css';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../../redux/action';
import { userDefault, activityDefault } from '../../../redux/reducer/default';

// api
import { alarmListApi } from '../../../api/auth';

//sweet alert
import swal from 'sweetalert';

export default function AlarmHeaderPop({ setAlarmPop,alarmPop }) {
    const navigate = useNavigate();

    const userData = useSelector((state) => state.user);
    
    // maxwidth 구분
    const isResponsive = useMediaQuery({
      query: '(max-width : 1080px)',
    });
    const [alarmList, setAlarmList] = useState([]);


//   const dispatch = useDispatch();
//   const { u_name, rep_site, rep_con } = useSelector((state) => ({
//     u_name: state.user.u_name,
//     rep_site: state.user.rep_site,
//     rep_con: state.user.rep_con,
//   }));
//   const [boardInfo, setBoardInfo] = useState([]);
//   const [isSelected, setIsSelected] = useState(false);

//   const [siteList, setSiteList] = useState([]);
//   const [selectSwitch, setSelectSwitch] = useState([]);


  const alarmListFnc = async() =>{
    const response = await alarmListApi(userData.site_num);
    if(response.status === 'success'){
        setAlarmList(response.data);
        
    }
  }

  useEffect(()=>{
    alarmListFnc();
  },[])

  const dateCalFnc = async(alarmDate) =>{
    const dueTime = moment(alarmDate, 'YYYY-MM-DD HH:mm:ss');
    const currentTime = moment();

    const duePeriod = {
      day: moment.duration(currentTime.diff(dueTime)).days(), //cuttenttime=현재, dueTime=생성시간 일/시/분/초 비교
      hour: moment.duration(currentTime.diff(dueTime)).hours(),
      minute: moment.duration(currentTime.diff(dueTime)).minutes(),
      second: moment.duration(currentTime.diff(dueTime)).seconds(),
    };


    let dueText = '';
    if (duePeriod.day === 0) {
      if (duePeriod.hour === 0) {
        if (duePeriod.minute === 0) {
          if (duePeriod.second > 0) {
            dueText = duePeriod.second + '초 지남';
          } else {
            dueText = -1 * duePeriod.second + '초 남음';
          }
        } else {
          if (duePeriod.minute > 0) {
            dueText = duePeriod.minute + '분 지남';
          } else {
            dueText = -1 * duePeriod.minute + '분 남음';
          }
        }
      } else {
        if (duePeriod.hour > 0) {
          dueText =
            duePeriod.hour +
            '시간' +
            -1 * duePeriod.minute +
            ' 분 지남';
        } else {
          dueText =
            -1 * duePeriod.hour +
            '시간' +
            -1 * duePeriod.minute +
            '분 남음';
        }
      }
    } else {
      if (duePeriod.day > 0) {
        dueText = duePeriod.day + '일 지남';
      } else {
        dueText = -1 * duePeriod.day + '일 남음';
      }
    }

    return dueText;
  }


  return (
    <>
      {isResponsive ? (
        <div className='pop'>
          <div className='change_site_pop_dim' 
          onClick={() => {setAlarmPop(!alarmPop)}}
          ></div>
          <div className='change_site_container_mobile_pop'>
            <div className='close' onClick={() => {setAlarmPop(!alarmPop)}}>
              <img src={i_close} alt='' />
            </div>
            <h3 className='pop_tit'>알림</h3>
            <div className=''>
              
              

            <ul className='change_site_container_list'>
            
            {alarmList?.map((a, i)=>{
                const updateTime = moment(a.createdDate, 'YYYY-MM-DD HH:mm:ss');
                const currentTime = moment();

                const updatePeriod = {
                    day: moment.duration(currentTime.diff(updateTime)).days(),
                    hour: moment.duration(currentTime.diff(updateTime)).hours(),
                    minute: moment.duration(currentTime.diff(updateTime)).minutes(),
                    second: moment.duration(currentTime.diff(updateTime)).seconds(),
                };
                let updateText = '';
                if (updatePeriod.day == 0) {
                    if (updatePeriod.hour == 0) {
                    if (updatePeriod.minute == 0) {
                        if (updatePeriod.second < 30) {
                        updateText = '방금 전';
                        } else {
                        updateText = updatePeriod.second + '초 전';
                        }
                    } else {
                        updateText = updatePeriod.minute + '분 전';
                    }
                    } else {
                    updateText = updatePeriod.hour + '시간 전';
                    }
                } else {
                    updateText = updatePeriod.day + '일 전';
                }


                    return(
                        <li
                        className=
                            // 'header_site_select_true'
                            'header_site_select_false'
                            onClick={()=>{
                                navigate('/management/order/document/'+a.typeNo,
                                {
                                  state: {orderNo : a.typeNo}
                                })
                                //리스트에 있던 정보를 못 가져옴
                            }}
                        >
                        <div className='header_site_img'>
                            {/* <span>ddd</span> */}
                            <img src={a.logo} alt={a.logo}/>
                        </div>
                        <p className='header_site_info'>
                            {/* &#91;<span className='site_company'>{data.constructionName}</span>&#93;  */}
                            <span className='header_site_title'>{a.title}</span>
                            <span className='header_site_c_name'>{a.name}</span>
                            <span className='header_site_desc'>{a.code} &nbsp;{a.message} </span>
                            <span className='header_site_date'>{a.createdDate? updateText : 'time error'}</span>
                        </p>
                        </li>
                    )
                })}






                {alarmList.length === 0 && <span className="empty_alarm">주문알림 내역이 없습니다.</span>}
          </ul>








            </div>
          </div>
        </div>
      ) : (
        <>
        <div className='change_site_pop_dim' onClick={() => {setAlarmPop(!alarmPop)}}></div>
        <div className='change_site_container_pop' onClick={()=>{
        }}>

          <h3>알림</h3>
          <ul className='change_site_container_list'>
            {alarmList?.map((a, i)=>{
                const updateTime = moment(a.createdDate, 'YYYY-MM-DD HH:mm:ss');
                const currentTime = moment();

                const updatePeriod = {
                    day: moment.duration(currentTime.diff(updateTime)).days(),
                    hour: moment.duration(currentTime.diff(updateTime)).hours(),
                    minute: moment.duration(currentTime.diff(updateTime)).minutes(),
                    second: moment.duration(currentTime.diff(updateTime)).seconds(),
                };
                let updateText = '';
                if (updatePeriod.day == 0) {
                    if (updatePeriod.hour == 0) {
                    if (updatePeriod.minute == 0) {
                        if (updatePeriod.second < 30) {
                        updateText = '방금 전';
                        } else {
                        updateText = updatePeriod.second + '초 전';
                        }
                    } else {
                        updateText = updatePeriod.minute + '분 전';
                    }
                    } else {
                    updateText = updatePeriod.hour + '시간 전';
                    }
                } else {
                    updateText = updatePeriod.day + '일 전';
                }


                    return(
                        <li
                        className=
                            // 'header_site_select_true'
                            'header_site_select_false'
                            onClick={()=>{
                                navigate('/management/order/document/'+a.typeNo,
                                {
                                  state: {orderNo : a.typeNo}
                                })
                                //리스트에 있던 정보를 못 가져옴
                            }}
                        >
                        <div className='header_site_img'>
                            {/* <span>ddd</span> */}
                            <img src={a.logo} alt={a.logo}/>
                        </div>
                        <p className='header_site_info'>
                            {/* &#91;<span className='site_company'>{data.constructionName}</span>&#93;  */}
                            <span className='header_site_title'>{a.title}</span>
                            <span className='header_site_c_name'>{a.name}</span>
                            <span className='header_site_desc'>{a.code} &nbsp;{a.message} </span>
                            <span className='header_site_date'>{a.createdDate? updateText : 'time error'}</span>
                        </p>
                        </li>
                    )
                })}






                {alarmList.length === 0 && <span className="empty_alarm">주문알림 내역이 없습니다.</span>}
          </ul>
        </div>
        </>
      )}
    </>
  );
}
