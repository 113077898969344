import React, { useState, useEffect } from "react";
//library
import DatePicker, { registerLocale } from "react-datepicker";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ko from "date-fns/locale/ko";

//component
import Mheader from "../../../component/common/Mheader";
import ContractTermsPop from "../../../component/common/Pop/ContractTermsPop";
import Loading from "../../../component/etc/Loading";
//css
import "../../../asset/css/concludeContract.css";
//etc
import {
  choiceCompanyApi,
  generateContract,
  orderUserInfoApi,
} from "../../../api/auth";
import { numberWithCommas } from "../../../util/Util";

//sweet alert
import swal from "sweetalert";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import OrderBreadCrumb from "../../../component/common/OrderBreadCrumb";
import { actions } from "../../../redux/action";
registerLocale("ko", ko);

export default function ConcludeContract() {
  const userData = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [comList, setComList] = useState([]);
  const [detailData] = useState(location.state.detailData);
  const [selectCompany] = useState(location.state.selectCompany);
  const [reason] = useState(location.state.reason);
  const [prsCompany] = useState(location.state.prsCompany);

  const [ctNo, setCtNo] = useState(false);
  const [filePath, setFilePath] = useState(false);
  const [generating, setGenerating] = useState(false);

  const [contractData, setContractData] = useState({
    ct_select: "", //
    ct_sdate: moment(detailData.bid.createdDate).format("YYYY-MM-DD"), //detailData.bid.createdDate
    // ct_sdate: moment(detailData.bid.createdDate).format('YYYY-MM-DD HH:mm:ss'), //detailData.bid.createdDate
    ct_o_no: selectCompany.offerNo, //투찰번호 selectComapny.offerNo
    ct_comment: selectCompany.offercomment, //selectCompany.offercomment
    bid_no: detailData.bid.no, //detailData.bid.no
  });

  const [orderData, setOrderData] = useState({
    offerNo: selectCompany.offerNo,
    uniqueness: "",
    selectReason: "",
    address: detailData.bid?.addr1,
    addressDetail: detailData.bid?.addr2,
    recipient: userData.u_name,
    phone: userData.u_phone,
    // no submit data
  });

  // 날짜 - 시작, 끝
  const [startDate, setStartDate] = useState(); // 계약 시작일

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  // 약관 팝업
  const [termsPop, setTermsPop] = useState(false);

  const bidDrop = (data) => {
    if (data === "L") {
      return "하차역무 있음 ⭕️";
    } else if (data === "U") {
      return "하차역무 없음 ❌";
    }
  };

  //계약 종료일
  const endDateFnc = (databid, bidTerm) => {
    return formatDate(databid.setMonth(databid.getMonth() + Number(bidTerm)));
  };

  useEffect(() => {
    if (prsCompany) {
      if (reason) {
        let copy = contractData;
        copy.ct_select = reason;
        copy.bid_no = detailData.bid.no;
        copy.ct_o_no = prsCompany;
        setContractData(copy);
      } else {
        let copy = contractData;
        copy.bid_no = detailData.bid.no;
        copy.ct_o_no = prsCompany;
        setContractData(copy);
      }
    }
  }, [comList]);

  const _move2Success = () => {
    navigate("/completed_contract", { replace: true });
  };

  const _generateContract = async () => {
    //
    setGenerating(true); //loading ui on
    const response = await generateContract(
      contractData.ct_select,
      contractData.ct_sdate,
      contractData.ct_o_no,
      contractData.ct_comment,
      contractData.bid_no
    );

    if (response.status === "success") {
      setCtNo(response.data.ct_no);
      setFilePath(response.data.file_path);
      setGenerating(false); //case success : loading ui off
      setTermsPop(true);
    } else {
    }
  };

  const onNextValidation = async () => {
    if (orderData.recipient === "") {
      swal("수령인을 입력해주세요.,", "", "warning");
    } else if (orderData.phone === "") {
      swal("수령인 연락처를 입력해주세요.", "", "warning");
    } else if (orderData.detail === "") {
      swal("납품주소를 입력해주세요.", "", "warning");
    } else {
      goConfirmFnc("일회성입찰주문");
    }
  };
  const goConfirmFnc = async (type) => {
    const filterData = {
      submitData: { ...orderData, deldate: detailData.bid.deldate },
      bidImgList: detailData.bidImgInfoList,
      bidProductList: detailData.bidProductList,
    };
    navigate(
      "/management/estimate/conclude_contract/" +
        prsCompany +
        "/request_confirm",
      {
        state: {
          data: filterData,
          type: type,
          selectCompany: selectCompany,
        },
      }
    );
  };

  const [phone, setPhone] = useState("");

  const handlePhoneNumberChange = (value) => {
    // 기본값으로 빈 문자열을 사용하여 undefined 방지
    let input = value ? value.replace(/\D/g, "") : "";

    // Add hyphens based on the length of the input
    if (input.length > 3 && input.length <= 6) {
      input = `${input.slice(0, 3)}-${input.slice(3)}`;
    } else if (input.length > 6 && input.length <= 10) {
      input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6)}`;
    } else if (input.length > 10) {
      input = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7)}`;
    }

    // value를 저장 ( - 제외한 )
    setOrderData({ ...orderData, phone: value || "" });
    setPhone(input);
  };

  // api를 통해 유저휴대폰 번호 받아옴.
  const getUserData = async () => {
    const response = await orderUserInfoApi(userData.site_num);
    if (response.status === "success") {
      // Create a new user data object, preserving existing values and updating u_name and u_phone
      let newUserData = {
        ...userData,
        u_name: response.data.name,
        u_phone: response.data.phone,
      }; // Dispatch the action to update the user data
      dispatch(actions.setUser(newUserData));
      handlePhoneNumberChange(response.data.phone);
    }
  };

  useEffect(() => {
    if (userData.u_phone) {
      handlePhoneNumberChange(userData.u_phone);
    } else {
      getUserData();
    }
  }, []);

  // const location = useLocation();
  const currentPath = location.pathname; // 현재 경로
  const keyword = "request_confirm"; // 확인할 단어

  // URL에 특정 단어가 포함되어 있는지 확인
  const isConfirmPage = currentPath.includes(keyword);

  return (
    <>
      {!isConfirmPage ? (
        <div className="conclude_wrap">
          <Mheader title="상세정보 입력" />
          <div className="request_input_container">
            <div className="input_top_box">
              <div className="title">상세정보 입력</div>

              <OrderBreadCrumb
                step1="입찰 내역 확인"
                step2="상세정보 입력"
                step3={
                  detailData.bid.type === "C" ? "계약서 확인" : "상세정보 확인"
                }
                step4={detailData.bid.type === "C" ? "계약 완료" : "주문 완료"}
                isActive={2}
              />
            </div>
            <div className="top_request_sub_header">
              <div className="top_request_sub_header_wrap">
                <p>정확한 납품을 위하여 정확한 정보를 입력해주시기 바랍니다.</p>
              </div>
            </div>
            <div className="input_info_direction">
              {detailData.bid.type === "S" ? (
                <div>
                  <div className="input_info_container">
                    <span className="input_info_title">주문 / 입찰 방식</span>
                    <div className="input_info_contents input_info_desc">
                      {detailData.bid.type === "S"
                        ? "일회성 입찰 주문"
                        : "단가 계약 주문"}
                    </div>
                  </div>

                  <div style={{ marginTop: "40px" }}>
                    {/* <div className="input_info_contents"> */}
                    <span>단가 계약 내역</span>
                    <div className="contract_content">
                      <div className="content_top">
                        <div className="content_inner">
                          <div>
                            <span>입찰번호 :&nbsp;</span>
                            <span>{detailData.bid.code}</span>
                          </div>
                          <div>
                            발행일자 :&nbsp;
                            {detailData.bid.createdDate}
                          </div>
                        </div>
                      </div>
                      <div className="content_box">
                        <div className="content_btm">
                          <p className="contractor">
                            <span>
                              [{detailData.constructionName}]{" "}
                              {detailData.siteName} /{" "}
                              {selectCompany?.companyName}
                            </span>
                            {/* <span>
                        {detailData.bid.type === "S"
                          ? "일회성 입찰"
                          : "단가계약 (" + detailData.bid.term + "개월)"}
                      </span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>

                  {/* 수령인 . 수령인 연락처 . 납품기한 . 납품주소 만들기 ~ 
            // 주문정보, 확정견적 지우깅 취소주문하기 버튼도 변경하기 모바일한번보기
            */}

                  <div className="input_info_container">
                    <span className="input_info_title">수령인</span>
                    <div className="input_info_contents input_info_desc">
                      <input
                        defaultValue={userData.u_name}
                        onChange={(e) =>
                          setOrderData({
                            ...orderData,
                            recipient: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="input_info_container">
                    <span className="input_info_title">수령인 연락처</span>
                    <div className="input_info_contents input_info_desc">
                      <input
                        value={phone}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^\d]/g, "");
                          if (!/^\d{0,11}$/.test(e.target.value)) {
                            e.target.value = e.target.value.slice(0, -1);
                          }
                        }}
                        onChange={(e) =>
                          handlePhoneNumberChange(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="input_info_container">
                    <span className="input_info_title">납품 기한</span>
                    <div className="input_info_contents input_info_desc">
                      {moment(detailData.bid?.deldate).format(
                        "YYYY-MM-DD HH:mm까지"
                      )}
                    </div>
                  </div>
                  <div className="input_info_container">
                    <span className="input_info_title">납품 주소</span>
                    <div className="input_info_contents my_addr_container ">
                      <div className="input_info_btn_box">
                        <input type="text" disabled value={orderData.address} />
                      </div>
                      <input
                        className="direct_input"
                        placeholder="상세주소를 입력해주세요."
                        defaultValue={orderData.addressDetail}
                        onChange={(e) => {
                          setOrderData({
                            ...orderData,
                            detail: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="input_info_container">
                    <span className="input_info_title">배송비</span>
                    <div className="input_info_contents input_info_desc">
                      포함
                    </div>
                  </div>
                  <div className="input_info_container">
                    <span className="input_info_title">하차 역무</span>
                    <div className="input_info_contents input_info_desc">
                      하차 역무 있음
                    </div>
                  </div>
                  <div className="input_info_container">
                    <span className="input_info_title">비용 정산</span>
                    <div className="input_info_contents input_info_desc">
                      공새로 위임 정산
                    </div>
                  </div>
                </div>
              ) : (
                <div className="quote_content">
                  <h3 className="s_tit">
                    <p>주문 정보</p>
                  </h3>
                  <div className="quote_list_box">
                    <ul className="quote_list">
                      <li>
                        <p>현장주소</p>
                        <span>
                          {detailData.bid.addr1}, {detailData.bid.addr2}
                        </span>
                      </li>
                      <li>
                        <p>공급사</p>
                        <span>{selectCompany?.companyName}</span>
                      </li>
                      {detailData.bid?.type === "S" ? (
                        <li>
                          <p>납품일시</p>
                          <span>
                            {moment(detailData.bid?.deldate).format(
                              "YYYY-MM-DD HH:mm까지"
                            )}
                          </span>
                        </li>
                      ) : (
                        <li>
                          <p>계약기간</p>
                          <span>
                            계약 체결일로부터 {detailData?.bid.term}개월
                          </span>
                        </li>
                      )}
                    </ul>
                    <ul className="quote_list">
                      <li>
                        <p>배송비포함여부</p>
                        <span>
                          {detailData.bid.fee === "Y" ? "포함" : "비포함"}
                        </span>
                      </li>
                      <li>
                        <p>하차역무포함여부</p>
                        <span>{bidDrop(detailData.bid.drop)}</span>
                      </li>
                      <li>
                        <p>비용지급방법</p>
                        <span>{detailData.bid.refund}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {detailData.bid.type !== "S" && (
                <div className="contract_date">
                  <h3 className="s_tit">
                    <p>계약 시작일</p>
                  </h3>
                  <ul className="contract_date_ul">
                    <li>
                      <DatePicker
                        locale="ko"
                        selected={startDate ? startDate : new Date()}
                        onChange={(date) => {
                          setStartDate(date);
                          setContractData({
                            ...contractData,
                            ct_sdate: formatDate(date),
                          });
                        }}
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                      />
                    </li>
                    {detailData.bid.type === "C" ? (
                      <li>
                        <input
                          disabled
                          value={endDateFnc(
                            new Date(startDate ? startDate : new Date()),
                            detailData.bid.term
                          )}
                          // value={contractData.ct_edate}
                        />
                      </li>
                    ) : null}
                  </ul>
                </div>
              )}

              <div className="conclude_est">
                {detailData.bid.type === "C" ? (
                  <h3 className="s_tit">
                    <p>품목 내역</p>
                  </h3>
                ) : (
                  <h3 className="s_tit">
                    <p>확정 견적</p>
                  </h3>
                )}
                <div className="conclude_table">
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <p>품목명</p>
                        </th>
                        <th>
                          <p>규격</p>
                        </th>
                        <th>
                          <p style={{ lineHeight: "1.3" }}>
                            제조국
                            <br />
                            (브랜드)
                          </p>
                        </th>
                        <th>
                          <p>단위</p>
                        </th>
                        <th>
                          <p>수량</p>
                        </th>
                        <th>
                          <p>단가</p>
                        </th>
                        <th>
                          <p>금액</p>
                        </th>
                      </tr>
                      {detailData.bidProductList.map((data, i) => (
                        <tr key={data.no}>
                          <td>{data.name}</td>
                          <td>
                            <pre className="p_list_standard">
                              {data.standard.replace(/, /gi, "\n")}
                            </pre>
                          </td>
                          <td>{data.brand}</td>
                          <td>{data.unit}</td>
                          <td>{data.count}</td>
                          <td>
                            {numberWithCommas(
                              selectCompany?.companyPriceInfoList[i]?.price
                            )}
                          </td>
                          <td>
                            {numberWithCommas(
                              // comList[item]?.odList[i]?.od_price * data.bp_count
                              selectCompany?.companyPriceInfoList[i]?.price *
                                data.count
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="6">합계(VAT 미포함)</td>
                        <td>
                          <strong>
                            {/* {numberWithCommas(item.spList?.map((data) => data.ol_count * data.ol_price).reduce((sum, current) => sum + current, 0))} */}
                            {numberWithCommas(selectCompany?.totalPrice)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="uniqueness_box">
                {detailData.bid.type === "C" ? (
                  <>
                    <div className="uniqueness">
                      <div className="uniqueness_tit">
                        <p>
                          <span>{selectCompany?.companyName}</span>특이사항
                        </p>
                      </div>
                      <div className="uniqueness_content">
                        <p>{selectCompany.offerComment}</p>
                      </div>
                    </div>
                    <div className="uniqueness">
                      <div className="uniqueness_tit">
                        <p>
                          <span>주문 관련</span>
                          <span>추가 내용</span>
                        </p>
                      </div>
                      <div className="uniqueness_content contract_additions">
                        <textarea
                          placeholder="질의응답을 통해 협의된 내용을 작성해주세요"
                          onChange={(e) => {
                            setContractData({
                              ...contractData,
                              ct_comment: e.target.value,
                            });
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </>
                ) : (
                  <ul className="request_step4_list">
                    <li>
                      <p>비고</p>
                      <div className="textarea_div" spellCheck="false">
                        <textarea
                          placeholder="주문 관련 참고 사항이 있으시면 작성해주시기 바랍니다."
                          onChange={(e) => {
                            setOrderData({
                              ...orderData,
                              uniqueness: e.target.value,
                            });
                          }}
                          value={orderData.uniqueness}
                        ></textarea>
                        <div className="add_text_alert_container">
                          <span className="add_alert_desc">
                            * 300자 이내로 작성해 주세요.
                          </span>
                          <span className="add_alert_typing">
                            {orderData.uniqueness?.length || "0"} / 300
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
              <div className="btn_box">
                <div className="cancel">
                  <p
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    취소
                  </p>
                </div>
                <div className="save">
                  <p
                    onClick={() => {
                      if (
                        contractData.bid_no === "" ||
                        contractData.ct_o_no === ""
                      ) {
                        swal("다시 시도해주세요.", "", "warning");
                      } else if (contractData.ct_sdate.indexOf("NaN") !== -1) {
                        swal("계약 시작일을 선택해주세요.", "", "warning");
                      } else {
                        if (detailData.bid.type === "S") {
                          //단일건
                          // goConfirmFnc("일회성입찰주문");
                          onNextValidation();
                        } else if (detailData.bid.type === "C") {
                          //지정기간
                          _generateContract();
                        }
                      }
                    }}
                  >
                    {detailData.bid.type === "S" ? "주문하기" : "계약하기"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {termsPop && (
            <ContractTermsPop
              ctNo={ctNo}
              filePath={filePath}
              setTermsPop={setTermsPop}
              contractData={contractData}
              move2Success={_move2Success}
            />
          )}
          {generating && (
            <Loading
              msg={
                detailData.bid.type === "S"
                  ? "주문 생성중입니다."
                  : "계약 생성중입니다."
              }
            />
          )}
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
}
