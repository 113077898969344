import React, { useEffect, useState } from "react";
//library
import { useNavigate, useLocation } from "react-router-dom";
import { Spreadsheet } from "react-spreadsheet";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/requestsimple.css";
import plus_icon from "../../asset/images/excel-plus-icon.png";
import minus_icon from "../../asset/images/excel-minus-icon.png";
//etc
import {
  estimateEasyApi,
  imgUpApi,
  uploadExcel,
  estimateTimeOverApi,
} from "../../api/auth";
import ExcelDownloadPop from "../../component/common/Pop/ExcelDownloadPop";
// import Loading from '../../../component/etc/Loading';
import Loading from "../../component/etc/Loading";

//sweet alert
import swal from "sweetalert";

import circle_minus from "../../asset/images/circle-minus.png";
import circle_plus from "../../asset/images/circle-plus.png";

import { useMediaQuery } from "react-responsive";

//redux
import { useSelector } from "react-redux";

export default function Request() {
  const navigate = useNavigate();
  const location = useLocation();
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  const userData = useSelector((state) => state.user);
  const pushToken = useSelector((state) => state.push);

  const bidNo = location.state.bid_no;
  const bidEndate = location.state.bid_endate;

  const [uploadFile, setUploadFile] = useState([{ file: "", id: 0 }]);
  const [uploadImg, setUploadImg] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [preSelectData, setPreSelectData] = useState([]);
  const [comment, setComment] = useState("");
  const [fList, setFList] = useState([]);
  const [excelPop, setExcelPop] = useState(false);

  const [generating, setGenerating] = useState(false); //loading

  //파일 추가
  const onCreate = (i) => {
    const file = {
      file: "",
      id: i,
    };
    setUploadFile(uploadFile.concat(file));
  };

  //파일 삭제
  const onRemove = (id) => {
    setUploadFile(uploadFile.filter((file) => file.id !== id));
  };

  const downloadExcel = async () => {
    const link = document.createElement("a");
    link.href = `https://gongsaero.s3.ap-northeast-2.amazonaws.com/system/gongsaero_template.xlsx`;
    // link.href = `https://gongsaero.s3.ap-northeast-2.amazonaws.com/1648528042788_gongsaero_template.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [data, setData] = useState([
    [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
  ]);

  const columeLabel = ["품목명", "규격", "단위", "수량", "비고"];

  const handleFileUpload = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const response = await imgUpApi(formData);
    if (response.status === "success") {
      let copy = fList;
      copy.push({ f_no: response.data.f_no, bid_no: bidNo });
      setFList(copy);
      setUploadImg([...uploadImg, e.target.files[0]]);
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };

  // 엑셀 추가
  const addArray = () => {
    const arr = [
      [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
    ];
    const plusLine = data.concat(arr);
    setData(plusLine);
  };

  //엑셀 삭제
  const deleteArray = () => {
    if (preSelectData.length === 0) {
      swal("삭제할 행을 선택해주세요.", "", "warning");
      return;
    }

    let targetList = Array();
    preSelectData.map((item) => {
      targetList.push(item.row);
    });

    const delArr = data.map((item, index) => {
      if (!targetList.includes(index)) {
        return item;
      }
    });

    const filtered = delArr.filter(function (x) {
      return x !== undefined;
    });

    setData(filtered);
  };

  //시간 포맷 시간까지!
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = "" + d.getHours(),
      min = "" + d.getMinutes();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (min.length < 2) min = "0" + min;

    return [year, month, day].join("-") + " " + [hour, min].join(":");
  };

  //견적요청 완료하기
  const sendData = async () => {
    setGenerating(true);
    const res = await estimateTimeOverApi(bidNo);
    if (res.status === "fail") {
      swal("마감시간이 지났습니다. 다시 투찰해 주세요!", "", "warning");
      navigate("/management/request");

      return false;
    }

    if (bidEndate < formatDate(new Date())) {
      swal("마감시간이 지났습니다. 다시 투찰해 주세요!", "", "warning");
      navigate("/management/request");

      return false;
    }

    const pList = data.map((item) => ({
      bid_no: bidNo,
      bp_name: item[0]?.value,
      bp_standard: item[1]?.value,
      bp_unit: item[2]?.value,
      bp_count: item[3]?.value,
      bp_etc: item[4]?.value,
    }));
    const response = await estimateEasyApi(
      pList,
      fList,
      comment,
      userData.site_num,
      bidNo
    );

    if (response.status === "success") {
      navigate("/management/bid/request_complete");
    } else {
      if (response.message === "Invalid") {
        swal(response.data);
      } else {
        swal("견적에 오류가 있습니다. 다시 시도해주세요", "", "error");
        navigate("/management/request");
      }
    }
    setGenerating(false);
  };

  var isDisabled = false;

  const alreadyPerform = async () => {
    if (isDisabled) {
      //<-( 1 ) 수행가능여부 검사
      swal("이미 작업이 수행중입니다.", "", "warning");
      return false;
    } else {
      isDisabled = true; //<-( 2 ) 실행 불가능하도록 flag 변경
      sendData();
      isDisabled = false; //(3)수행가능하도록 열어준다. settimeout을통해 X초 뒤에 풀어주는것도 방법이다.
    }
  };

  useEffect(() => {
    if (selectData.length !== 0) {
      setPreSelectData(selectData);
    }
  }, [selectData]);

  const deleteSelectRow = (row) => {
    if (data.length === 1) {
      return;
    }
    let targetList = Array(); //array 생성
    targetList.push(row); //[3]

    const delArr = data.map((item, index) => {
      //선택한 row가 없다면, 값 제대로 return
      //선택한 row는 Undefined return
      if (!targetList.includes(index)) {
        return item;
      }
    });
    //undefined row 제거
    const filtered = delArr.filter(function (x) {
      return x !== undefined;
    });

    setData(filtered);
  };

  return (
    <div className="request_step4_wrap">
      <Mheader title="견적서 간편 입력" />
      <div className="request_step4_container wd">
        <div className="top_pagenation">
          <h2>견적서 간편 입력</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p>2</p>
            <p>3</p>
            <p className="active">4</p>
            <p>5</p>
          </div>
        </div>
        <div className="top_request_step4">
          <p>
            {/* * 비고란에 텍스트 및 링크 또는 이미지를 업로드하여 정확한 품목을
            요청해보세요 */}
            * 엑셀란은 빈행없이 작성해주세요.
            <br />
            * 엑셀 다운로드를 통해 작성예시를 다운받아 볼 수 있습니다.
            <br />* 가지고 계신 엑셀 파일에서 복사/붙여넣기가 가능합니다.
          </p>
          <div className="excel_btn_wrap">
            <div
              className="excel_download"
              onClick={() => {
                if (pushToken && pushToken.type && pushToken.value) {
                  swal(
                    "엑셀 다운로드 기능은 현재 모바일/PC 웹에서 가능합니다. 곧 업데이트 예정입니다.",
                    "",
                    "info"
                  );
                } else {
                  setExcelPop(true);
                  // downloadExcel();
                }
              }}
            >
              샘플 다운로드
            </div>
            <div className="excel_download">
              <label
                for={"excel-form"}
                onClick={(e) => {
                  if (pushToken && pushToken.type && pushToken.value) {
                    e.preventDefault();
                    swal(
                      "엑셀 업로드 기능은 현재 모바일/PC 웹에서 가능합니다. 곧 업데이트 예정입니다.",
                      "",
                      "info"
                    );
                  }
                }}
              >
                엑셀 업로드
              </label>
            </div>
            <input
              type={"file"}
              name={"excel-form"}
              id={"excel-form"}
              onChange={async (e) => {
                const formData = new FormData();
                formData.append("file", e.target.files[0]);
                const response = await uploadExcel(formData);
                if (response.status === "success") {
                  if (response.data.length > 0) {
                    setData(response.data);
                    e.target.value = null;
                  } else {
                    swal("엑셀파일을 다시 확인해주세요.", "", "warning");
                    e.target.value = null;
                  }
                } else {
                  swal(response.message);
                  e.target.value = null;
                }
              }}
              style={{ display: "none" }}
            />
            <div className="excel_controls" onClick={addArray}>
              행 추가
              <img src={plus_icon} alt="" />
            </div>
            <div className="excel_controls" onClick={deleteArray}>
              행 삭제
              <img src={minus_icon} alt="" />
            </div>
          </div>
        </div>
        <div className="excel_place">
          <Spreadsheet
            className="sheet"
            data={data}
            hideRowIndicators={false}
            columnLabels={columeLabel}
            onChange={setData}
            onSelect={setSelectData}
          />
          {!isResponsive && (
            <div className="excel_delete_container">
              <div className="excel_empty_place">행 삭제</div>
              {data.map((a, i) => {
                return (
                  <div
                    onClick={() => deleteSelectRow(i)}
                    className="excel_delete_btn"
                    title={i + 1 + "번 행 삭제하기"}
                  >
                    {/* {i+1}번째 */}
                    <img src={circle_minus} />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {!isResponsive && (
          <div className="excel_add_container" onClick={addArray}>
            <img src={circle_plus} />
            <span>행 추가</span>
          </div>
        )}

        <ul className="request_step4_list">
          <li>
            <p>견적 관련 추가 설명</p>
            <div className="textarea_div" spellCheck="false">
              <textarea
                placeholder="견적 관련 추가 설명이 있으시다면 작성해주세요."
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              ></textarea>
            </div>
          </li>
          <li>
            <p>이미지 첨부</p>
            <div>
              {uploadFile.map((data, i) => (
                <div className="img_add" key={data.id}>
                  <input
                    type="file"
                    id={data.id}
                    accept="image/*"
                    onChange={(e) => {
                      const arr = uploadFile.map((f_name, index) =>
                        f_name.id === data.id
                          ? { ...f_name, file: e.target.files[0].name }
                          : { ...f_name }
                      );
                      setUploadFile(arr);
                      handleFileUpload(e);
                      // setUploadFile([...uploadFile, {...data,  file : e.target.files[0].name}])
                    }}
                  />
                  <p>{data.file}</p>
                  <label htmlFor={data.id}>찾아보기</label>
                  {data.id === 0 ? (
                    <div
                      className="btn_add"
                      onClick={() => {
                        if (uploadFile.length < 5) {
                          onCreate(uploadFile.length);
                        }
                      }}
                    >
                      추가　+
                    </div>
                  ) : (
                    <div
                      className="btn_add"
                      onClick={() => {
                        if (!uploadFile.length < 2) {
                          onRemove(uploadFile[i].id);
                        }
                      }}
                    >
                      삭제　-
                    </div>
                  )}
                </div>
              ))}
            </div>
          </li>
          <li>
            <div className="page_btn">
              <div
                className="btn_prev"
                onClick={() => {
                  navigate(-1);
                }}
              >
                이전
              </div>
              <div
                className="next"
                onClick={() => {
                  // sendData();
                  alreadyPerform();
                }}
              >
                다음
              </div>
            </div>
          </li>
        </ul>
      </div>
      {excelPop ? <ExcelDownloadPop setExcelPop={setExcelPop} /> : null}
      {generating && <Loading msg={"견적 요청중입니다."} />}
    </div>
  );
}
