import React, { useState } from "react";
//library
import { useLocation, useNavigate } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/search.css";

//sweet alert
import swal from "sweetalert";
import { useMediaQuery } from "react-responsive";
export default function SearchList() {
  const navigate = useNavigate();
  const location = useLocation();

  const searchList = location?.state?.list;
  const keyword = location?.state?.keyword;
  // searchList가 undefined일 때
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  const [showAll, setShowAll] = useState(false);

  const handleToggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  // estimate state
  const estimateState = (data) => {
    if (data === "ALIVE") {
      return "입찰진행중";
    } else if (data === "CHOOSE") {
      return "투찰마감";
    } else if (data === "END") {
      return "선정완료";
    } else if (data === "CANCEL") {
      return "입찰취소";
    } else if (data === "FAIL") {
      return "유찰";
    }
  };

  //contract state
  const contractState = (data) => {
    if (data === "START") {
      return "계약 전";
    } else if (data === "ALIVE") {
      return "계약완료";
    } else if (data === "CANCEL") {
      return "계약취소";
    } else if (data === "HOLD") {
      return "중지요청";
    } else if (data === "STOP") {
      return "계약중지";
    } else if (data === "GRACE") {
      return "연장가능계약";
    } else if (data === "END") {
      return "계약종료";
    }
  };
  //order state
  const orderState = (data) => {
    if (data === "START") {
      return "발행완료";
    } else if (data === "CHECK") {
      return "주문확인";
    } else if (data === "DONE") {
      return "배송완료";
    } else if (data === "TESTED") {
      return "검수완료";
    } else if (data === "CANCEL") {
      return "취소";
    } else if (data === "BEFORE") {
      return "정산대기";
    } else if (data === "END") {
      return "정산완료";
    } else if (data === "REJECT") {
      return "반려";
    }
  };

  return (
    <div className="menu_search_wrap">
      <Mheader title="전체 검색" />
      <div className="menu_search_container">
        {/* {searchList
          ? "히스토리와 비슷하게 만들기 리스트들 뷰 만들기"
          : "X 잘못된 접근"} */}

        <span className="menu_search_header_title">
          <span className="bold">"{keyword}"</span>결과 총{" "}
          <span className="alert">
            {(searchList?.totalCount).toLocaleString("ko")}
          </span>
          건의 검색결과가 있습니다.
          {isResponsive && (
            <p className="menu_re_search" onClick={() => navigate("/")}>
              다시검색하기 &gt;
            </p>
          )}
        </span>

        <div className="menu_search_list_container">
          <div className="menu_list_header">
            <div className="menu_header_left">
              <span className="bold">메뉴</span>
              <span className="alert">{searchList?.searchMenuCount}건</span>
            </div>
            {searchList.searchMenuList.length > 5 && (
              <div className="menu_header_right" onClick={handleToggleShowAll}>
                <div className="show_more">{showAll ? "접기" : "펼치기"}</div>
              </div>
            )}
          </div>
          <div className="menu_list_contents">
            {searchList?.searchMenuList.length > 0 ? (
              <div>
                <div>
                  {searchList?.searchMenuList.length > 0 ? (
                    <div>
                      {searchList.searchMenuList
                        .slice(0, showAll ? undefined : 5)
                        .map((elem, idx) => (
                          <div className="menu_list_elem" key={idx}>
                            <div className="menu_list_left">
                              <span>
                                {elem.depth1}
                                {elem.depth2 && ` > ${elem.depth2}`}
                                {elem.depth3 && ` > ${elem.depth3}`}
                              </span>
                            </div>
                            <div
                              className="menu_list_right"
                              onClick={() => {
                                elem.state
                                  ? navigate(elem.url, {
                                      state: { fromPage: elem.state },
                                    })
                                  : navigate(elem.url);
                              }}
                            >
                              <span>바로가기</span>
                            </div>
                          </div>
                        ))}
                      {/* {searchList.searchMenuList.length > 5 && (
                        <div
                          className="show_more"
                          onClick={handleToggleShowAll}
                        >
                          {showAll ? "간략히 보기" : "더 보기"}
                        </div>
                      )} */}
                    </div>
                  ) : (
                    <div className="no_search_result">
                      검색 결과가 없습니다.
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="no_search_result">검색 결과가 없습니다.</div>
            )}
          </div>
        </div>

        <div className="etc_search_list_container">
          <div className="etc_list_header">
            <div className="etc_header_left">
              <span className="bold">PB상품</span>
              <span className="alert">{searchList?.pbProductListCount}건</span>
            </div>
            <div
              className="etc_header_right"
              onClick={() => navigate("/pb/choice")}
            >
              <span>전체보기</span>
            </div>
          </div>
          <div className="etc_list_contents">
            <div className="etc_list_elem_box">
              {searchList?.pbProductList.length > 0 ? (
                searchList?.pbProductList.map((elem, idx) => (
                  <div className="etc_list_elem">
                    <div className="etc_list_left">
                      <div className="etc_list_img_box">
                        <img alt="dd" src={elem.imageUrl} />
                      </div>
                      <div className="etc_list_desc">
                        <div className="pb_list_desc_inner">
                          <span className="etc_list_title">{elem.name}</span>
                          <div className="etc_list_hashtag_container">
                            {elem.pbTagList.map((elem, i) => {
                              return (
                                <span className="etc_list_hashtag">{elem}</span>
                              );
                            })}
                          </div>
                        </div>
                        <span className="etc_list_standard">
                          {elem.standard}
                        </span>
                      </div>
                    </div>
                    <div
                      className="etc_list_right"
                      onClick={() => navigate("/pb/choice")}
                    >
                      <span>바로가기</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no_search_result">검색 결과가 없습니다.</div>
              )}
            </div>
          </div>
        </div>

        <div className="etc_search_list_container">
          <div className="etc_list_header">
            <div className="etc_header_left">
              <span className="bold">품목</span>
              <span className="alert">
                {(searchList?.productListCount).toLocaleString("ko")}건
              </span>
            </div>
            <div
              className="etc_header_right"
              onClick={() =>
                navigate("/management/bid/request_excel", {
                  state: { getListPop: true },
                })
              }
            >
              <span>
                {/* <span className="bold">{keyword}</span>  */}
                전체보기
              </span>
            </div>
          </div>
          <div className="etc_list_contents">
            <div className="etc_list_elem_box">
              {searchList?.productList.length > 0 ? (
                searchList?.productList.map((elem, idx) => (
                  <div className="etc_list_elem">
                    <div className="etc_list_left">
                      <div className="etc_list_img_box">
                        <img alt="dd" src={elem.imageUrl} />
                      </div>
                      <div className="etc_list_desc">
                        <span className="etc_list_title">{elem.name}</span>
                        <span className="etc_list_standard">
                          {elem.standard}
                        </span>
                      </div>
                    </div>
                    <div
                      className="etc_list_right"
                      onClick={
                        () =>
                          navigate(
                            "/management/bid/request_excel/detail/" + elem.no,
                            {
                              state: { getListPop: true, elemNo: elem.no },
                            }
                          )
                        // navigate("/management/order/1", {
                        //   state: { keyword: keyword },
                        // })
                      }
                    >
                      <span>바로가기</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no_search_result">검색 결과가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
        <div className="etc_search_list_container">
          <div className="etc_list_header">
            <div className="etc_header_left">
              <span className="bold">견적</span>
              <span className="alert">{searchList?.bidListCount}건</span>
            </div>
            <div className="etc_header_right">
              <span onClick={() => navigate("/management/estimate/1")}>
                전체보기
              </span>
            </div>
          </div>
          <div className="etc_list_contents">
            <div className="etc_list_elem_box">
              {searchList?.bidList.length > 0 ? (
                searchList?.bidList.map((elem, idx) => (
                  <div className="etc_list_elem">
                    <div className="etc_list_left">
                      <div className="etc_list_desc">
                        <div className="etc_list_desc_inner">
                          <span className={`estimate_badge ${elem.state}`}>
                            {estimateState(elem.state)}
                          </span>
                          <span className="etc_list_title">{elem.code}</span>
                        </div>
                        <div className="etc_list_desc_inner direction_column">
                          {elem.productName && (
                            <span className="etc_list_product_name">
                              {elem.productName}
                            </span>
                          )}
                          {elem.createdDate && (
                            <span className="etc_list_standard">
                              {elem.createdDate} 발행
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="etc_list_right"
                      onClick={() =>
                        navigate("/management/estimate/document/" + elem.no)
                      }
                    >
                      <span>바로가기</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no_search_result">검색 결과가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
        <div className="etc_search_list_container">
          <div className="etc_list_header">
            <div className="etc_header_left">
              <span className="bold">계약</span>
              <span className="alert">{searchList?.contractListCount}건</span>
            </div>
            <div className="etc_header_right">
              <span onClick={() => navigate("/management/contract/1")}>
                전체보기
              </span>
            </div>
          </div>
          <div className="etc_list_contents">
            <div className="etc_list_elem_box">
              {searchList?.contractList.length > 0 ? (
                searchList?.contractList.map((elem, idx) => (
                  <div className="etc_list_elem">
                    <div className="etc_list_left">
                      <div className="etc_list_desc">
                        <div className="etc_list_desc_inner">
                          <span className={`contract_badge ${elem.state}`}>
                            {contractState(elem.state)}
                          </span>
                          <span className="etc_list_title">{elem.code}</span>
                        </div>
                        <div className="etc_list_desc_inner direction_column">
                          {elem.productName && (
                            <span className="etc_list_product_name">
                              {elem.productName}
                            </span>
                          )}
                          {elem.createdDate && (
                            <span className="etc_list_standard">
                              {elem.createdDate} 발행
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="etc_list_right"
                      onClick={() =>
                        navigate("/management/contract/document/" + elem.no)
                      }
                    >
                      <span>바로가기</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no_search_result">검색 결과가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
        <div className="etc_search_list_container">
          <div className="etc_list_header">
            <div className="etc_header_left">
              <span className="bold">주문</span>
              <span className="alert">{searchList?.ordersListCount}건</span>
            </div>
            <div className="etc_header_right">
              <span
                onClick={() =>
                  navigate("/management/order/1", {
                    state: { keyword: keyword },
                  })
                }
              >
                전체보기
              </span>
            </div>
          </div>
          <div className="etc_list_contents">
            <div className="etc_list_elem_box">
              {searchList?.ordersList.length > 0 ? (
                searchList?.ordersList.map((elem, idx) => (
                  <div className="etc_list_elem">
                    <div className="etc_list_left">
                      <div className="etc_list_desc">
                        <div className="etc_list_desc_inner">
                          <span className={`order_badge ${elem.state}`}>
                            {orderState(elem.state)}
                          </span>
                          <span className="etc_list_title">{elem.code}</span>
                        </div>
                        <div className="etc_list_desc_inner  direction_column">
                          {elem.productName && (
                            <span className="etc_list_product_name">
                              {elem.productName}
                            </span>
                          )}
                          {elem.createdDate && (
                            <span className="etc_list_standard">
                              {elem.createdDate} 발행
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="etc_list_right"
                      onClick={() =>
                        navigate("/management/order/document/" + elem.no)
                      }
                    >
                      <span>바로가기</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no_search_result">검색 결과가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
