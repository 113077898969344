import Button from "../../../../../../component/ui/button/Button";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";

const ReorderUnitPriceFailPop = ({
  navigate,
  contractNo,
  onClickAddProduct,
  reorderUnitPriceFailPop,
  setReorderUnitPriceFailPop,
}) => {
  const moveToContractDetailPage = () => {
    navigate(`/management/contract/document/${contractNo}`);
  };

  const moveToRequestExcelPage = () => {
    navigate("/management/bid/request_excel");
  };

  const onClickCancel = () => {
    setReorderUnitPriceFailPop(false);
  };

  return (
    <Modal
      size="xsmall"
      type="error"
      open={reorderUnitPriceFailPop}
      title={
        <Typography component="p" type="h4" color="var(--sub-red-10)">
          계약이 유효하지 않습니다.
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button
          type="primary"
          size="small"
          onClick={() => {
            onClickAddProduct();
            moveToRequestExcelPage();
          }}
        >
          내역 담기
        </Button>,
        <Button type="primary" size="small" onClick={moveToContractDetailPage}>
          계약 확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        계약이 만료되었거나 일시 중지되었습니다.
      </Typography>
      <Typography component="p" type="c2">
        재주문을 위하여 “내역 담기"를 통해 주문 내역을 주문서에
      </Typography>
      <Typography component="p" type="c2">
        담고 주문 또는 입찰을 진행할 수 있습니다.
      </Typography>
    </Modal>
  );
};

export default ReorderUnitPriceFailPop;
