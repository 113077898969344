export default function SearchSVG({ size = "small", fill = "#11366B" }) {
  if (size === "xsmall") size = "16";
  if (size === "small") size = "20";
  if (size === "medium") size = "24";
  if (size === "large") size = "32";
  if (size === "xlarge") size = "40";

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9422 17.0577L14.0305 13.1468C15.1643 11.7856 15.7297 10.0398 15.609 8.27238C15.4883 6.50499 14.6909 4.85217 13.3827 3.65772C12.0744 2.46328 10.356 1.8192 8.58498 1.85944C6.81394 1.89969 5.12659 2.62118 3.87395 3.87383C2.62131 5.12647 1.89982 6.81382 1.85957 8.58486C1.81932 10.3559 2.46341 12.0743 3.65785 13.3825C4.85229 14.6908 6.50512 15.4882 8.2725 15.6089C10.0399 15.7295 11.7858 15.1642 13.1469 14.0304L17.0579 17.9421C17.1159 18.0002 17.1849 18.0463 17.2607 18.0777C17.3366 18.1091 17.4179 18.1253 17.5001 18.1253C17.5822 18.1253 17.6635 18.1091 17.7394 18.0777C17.8152 18.0463 17.8842 18.0002 17.9422 17.9421C18.0003 17.8841 18.0464 17.8151 18.0778 17.7392C18.1092 17.6634 18.1254 17.5821 18.1254 17.4999C18.1254 17.4178 18.1092 17.3365 18.0778 17.2606C18.0464 17.1848 18.0003 17.1158 17.9422 17.0577ZM3.12506 8.74993C3.12506 7.63741 3.45496 6.54988 4.07304 5.62485C4.69112 4.69982 5.56963 3.97885 6.59746 3.55311C7.6253 3.12737 8.7563 3.01598 9.84744 3.23302C10.9386 3.45006 11.9409 3.98579 12.7275 4.77246C13.5142 5.55913 14.0499 6.56141 14.267 7.65255C14.484 8.74369 14.3726 9.87469 13.9469 10.9025C13.5211 11.9304 12.8002 12.8089 11.8751 13.427C10.9501 14.045 9.86258 14.3749 8.75006 14.3749C7.25872 14.3733 5.82894 13.7801 4.77441 12.7256C3.71987 11.6711 3.12671 10.2413 3.12506 8.74993Z"
        fill={fill}
      />
    </svg>
  );
}
