import { z } from "zod";

export const createConstructionSchema = z.object({
  biz_logo: z.string().optional(),
  biz_name: z.string().min(1, { message: "회사명은 필수 항목입니다." }),
  biz_reg: z.string().min(1, { message: "사업자등록증은 필수 항목입니다." }),
  biz_category: z
    .string()
    .min(1, { message: "사업자 구분은 필수 항목입니다." }),
  biz_no: z.string().min(1, { message: "사업자등록번호는 필수 항목입니다." }),
  ceo_name: z.string().min(1, { message: "대표자명은 필수 항목입니다." }),
  biz_item: z.string().optional(),
  biz_type: z.string().optional(),
  addr: z.string().min(1),
  addr_detail: z.string().min(1),
  zip: z.string().optional(),
  user_type: z.string().min(1, { message: "회원종류는 필수 항목입니다." }),
});
