import styles from "./chip.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export default function Chip({
  size = "medium",
  type = "default",
  color,
  children,
  ...props
}) {
  const className = cx({
    [size]: true,
    [type]: true,
    [color]: true,
  });

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
}
