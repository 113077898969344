import { Divider } from "antd";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./title.module.css";

const Title = ({ state }) => {
  return (
    <div className={s.container}>
      <div className={s.title_container}>
        <div className={s.title_text_container}>
          <Typography component="p" type="h1" color={COLOR.GRAY.L09}>
            {state?.isEdit ? "현장 수정하기" : "신규 현장 등록하기"}
          </Typography>
          <Typography component="p" type="c1" color={COLOR.GRAY.L07}>
            현장 정보를 입력하여 등록을 요청합니다. 정보는 확인 및 승인 후에
            등록됩니다.
          </Typography>
        </div>
      </div>

      <Divider className={s.divider} />
    </div>
  );
};

export default Title;
