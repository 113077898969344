import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Select from "../../../../../../component/ui/select/Select";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import s from "./bizCategorySelect.module.css";

const options = [
  { value: "C", label: "개인" },
  { value: "P", label: "법인" },
];

const BizCategorySelect = ({ errors, control }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="biz_category"
      control={control}
      render={({ field }) => {
        return (
          <div className={s.select_container}>
            <label htmlFor="biz_category">
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                사업자 구분{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </label>

            <Select
              {...field}
              id="biz_category"
              value={field.value || undefined}
              size="medium"
              width={isDesktop ? 380 : "100%"}
              placeholder="사업자 구분을 선택해주세요."
              options={options}
              status={errors.biz_category && "error"}
            />

            {errors.biz_category && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.biz_category.message}
              </Typography>
            )}
          </div>
        );
      }}
    />
  );
};

export default BizCategorySelect;
