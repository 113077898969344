import React, { useEffect, useState } from "react";
//library
import { useNavigate, useLocation } from "react-router-dom";
import { Spreadsheet, DataCell } from "react-spreadsheet";
//component
import Mheader from "../../../component/common/Mheader";
//css
import "../../../asset/css/requestsimple.css";
//etc
import {
  imgUpApi,
  quickOrderEasyApi,
  uploadQuickExcel,
} from "../../../api/auth";

//redux
import { useSelector } from "react-redux";

import circle_minus from "../../../asset/images/circle-minus.png";
import circle_plus from "../../../asset/images/circle-plus.png";

//sweet alert
import swal from "sweetalert";

import { useMediaQuery } from "react-responsive";

export default function Request() {
  const pushToken = useSelector((state) => state.push);

  const navigate = useNavigate();
  const location = useLocation();
  const c_no = location.state.c_no;
  const [uploadFile, setUploadFile] = useState([{ file: "", id: 0 }]);
  const [uploadImg, setUploadImg] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [preSelectData, setPreSelectData] = useState([]);
  const [comment, setComment] = useState("");
  const [fList, setFList] = useState([]);

  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  const or_no = location.state.or_no;

  //파일 추가
  const onCreate = (i) => {
    const file = {
      file: "",
      id: i,
    };
    setUploadFile(uploadFile.concat(file));
  };

  //파일 삭제
  const onRemove = (id) => {
    setUploadFile(uploadFile.filter((file) => file.id !== id));
  };

  const downloadExcel = async () => {
    const link = document.createElement("a");
    link.href = `https://gongsaero.s3.ap-northeast-2.amazonaws.com/system/gongsaero_template_quick.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [data, setData] = useState([
    [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
  ]);

  const columeLabel = ["품목명", "규격", "단위", "수량", "비고"];

  const handleFileUpload = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const response = await imgUpApi(formData);
    if (response.status === "success") {
      let copy = fList;
      copy.push({ fileNo: response.data.f_no, qi_q_no: location.state.q_no });
      setFList(copy);
      setUploadImg([...uploadImg, e.target.files[0]]);
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };

  //퀵주문 완료하기
  const sendData = async () => {
    const pList = data.map((item) => ({
      bidProduct: {
        productNo: null,
        name: item[0]?.value,
        standard: item[1]?.value,
        unit: item[2]?.value,
        count: item[3]?.value != 0 ? Number(item[3]?.value) : "",
        etc: item[4]?.value,
      },
      price: item[5]?.value != 0 ? Number(item[5]?.value) : "", //밖에 빼서 달라는 요구사항
    }));
    const filterPlist = { bidProductList: pList }; //converting
    const step2Data = {
      orderInfoList: pList,
      bidImgList: fList,
      uniqueness: comment,
      companyNo: c_no,
    };

    if (pList?.find((el) => String(el?.price)?.slice(-1) !== "0")) {
      swal("단가는 10원 단위 이상으로 입력해주세요.", "", "error");
      return;
    }

    const response = await quickOrderEasyApi(filterPlist);

    if (response.status === "success") {
      navigate("/management/quick_order2", {
        state: {
          step2Data: step2Data,
        },
      });
    } else {
      if (response.message === "Invalid") {
        swal(response.data);
      } else {
        swal("다시 시도해주세요", "", "warning");
      }
    }
  };

  // 엑셀 추가
  const addArray = () => {
    const arr = [
      [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
    ];
    const plusLine = data.concat(arr);
    setData(plusLine);
  };

  //엑셀 삭제
  const deleteArray = () => {
    if (preSelectData.length === 0) {
      swal("삭제할 행을 선택해주세요.", "", "warning");
      return;
    }

    let targetList = Array();
    preSelectData.map((item) => {
      targetList.push(item.row);
    });

    const delArr = data.map((item, index) => {
      if (!targetList.includes(index)) {
        return item;
      }
    });

    const filtered = delArr.filter(function (x) {
      return x !== undefined;
    });

    setData(filtered);
  };

  useEffect(() => {
    if (selectData.length !== 0) {
      setPreSelectData(selectData);
    }
  }, [selectData]);

  const deleteSelectRow = (row) => {
    if (data.length === 1) {
      return;
    }
    let targetList = Array(); //array 생성
    targetList.push(row); //[3]

    const delArr = data.map((item, index) => {
      //선택한 row가 없다면, 값 제대로 return
      //선택한 row는 Undefined return
      if (!targetList.includes(index)) {
        return item;
      }
    });
    //undefined row 제거
    const filtered = delArr.filter(function (x) {
      return x !== undefined;
    });

    setData(filtered);
  };

  return (
    <div className="request_step4_wrap">
      <Mheader title="견적서 간편 입력" />
      <div className="request_step4_container wd">
        <div className="quick_bar_container">
          <div className="top_progress_bar">
            <div
              className="menus"
              onClick={() => navigate("/management/request_order")}
            >
              주문방법선택
            </div>
            <div
              className="menus"
              onClick={() => navigate("/management/quick_order/search")}
            >
              공급사 선택
            </div>
            <div className="active_menus color2">견적서 간편 입력</div>
            <div className="menus not_allowed">상세정보 입력</div>
            <div className="menus not_allowed">완료</div>
          </div>
        </div>

        <div className="top_request_step4">
          <p>
            {/* * 비고란에 텍스트 및 링크 또는 이미지를 업로드하여 정확한 품목을
            요청해보세요 */}
            * 엑셀란은 빈행없이 작성해주세요.
            <br />
            * 엑셀 다운로드를 통해 작성예시를 다운받아 볼 수 있습니다.
            <br />* 가지고 계신 엑셀 파일에서 복사/붙여넣기가 가능합니다.
          </p>
          <div className="excel_btn_wrap">
            <div
              className="excel_download"
              onClick={(e) => {
                if (pushToken && pushToken.type && pushToken.value) {
                  e.preventDefault();
                  swal(
                    "엑셀 다운로드 기능은 현재 모바일/PC 웹에서 가능합니다. 곧 업데이트 예정입니다.",
                    "",
                    "info"
                  );
                } else {
                  downloadExcel();
                }
              }}
            >
              엑셀 다운로드
            </div>
            <div className="excel_download">
              <label for={"excel-form"}>엑셀 업로드</label>
            </div>
            <input
              onClick={(e) => {
                if (pushToken && pushToken.type && pushToken.value) {
                  e.preventDefault();
                  swal(
                    "엑셀 업로드 기능은 현재 모바일/PC 웹에서 가능합니다. 곧 업데이트 예정입니다."
                  );
                }
              }}
              type={"file"}
              name={"excel-form"}
              id={"excel-form"}
              onChange={async (e) => {
                const formData = new FormData();
                formData.append("file", e.target.files[0]);
                const response = await uploadQuickExcel(formData);
                if (response.status === "success") {
                  if (response.data.length > 0) {
                    setData(response.data);
                    e.target.value = null;
                  } else {
                    swal("엑셀파일을 다시 확인해주세요.", "", "warning");
                    e.target.value = null;
                  }
                } else {
                  swal(response.message);
                  e.target.value = null;
                }
              }}
              style={{ display: "none" }}
            />
            <div className="excel_controls" onClick={addArray}>
              줄 추가
            </div>
            <div className="excel_controls" onClick={deleteArray}>
              줄 삭제
            </div>
          </div>
        </div>
        <div className="excel_place">
          <Spreadsheet
            className="sheet"
            data={data}
            hideRowIndicators={false}
            columnLabels={columeLabel}
            onChange={setData}
            onSelect={setSelectData}
            evaluatedData={columeLabel}
          />
          {!isResponsive && (
            <div className="excel_delete_container">
              <div className="excel_empty_place">행 삭제</div>
              {data.map((a, i) => {
                return (
                  <div
                    onClick={() => deleteSelectRow(i)}
                    className="excel_delete_btn"
                    title={i + 1 + "번 행 삭제하기"}
                  >
                    {/* {i+1}번째 */}
                    <img src={circle_minus} />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {!isResponsive && (
          <div className="excel_add_container" onClick={addArray}>
            <img src={circle_plus} />
            <span>행 추가</span>
          </div>
        )}

        <ul className="request_step4_list">
          <li>
            <p>견적 관련 추가 설명</p>
            <div className="textarea_div" spellcheck="false">
              <textarea
                placeholder="견적 관련 추가 설명이 있으시다면 작성해주세요."
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              ></textarea>
            </div>
          </li>
          <li>
            <p>이미지 첨부</p>
            <div>
              {uploadFile.map((data, i) => (
                <div className="img_add" key={data.id}>
                  <input
                    type="file"
                    id={data.id}
                    accept="image/*"
                    onChange={(e) => {
                      const arr = uploadFile.map((f_name, index) =>
                        f_name.id === data.id
                          ? { ...f_name, file: e.target.files[0].name }
                          : { ...f_name }
                      );
                      setUploadFile(arr);
                      handleFileUpload(e);
                      // setUploadFile([...uploadFile, {...data,  file : e.target.files[0].name}])
                    }}
                  />
                  <p>{data.file}</p>
                  <label htmlFor={data.id}>찾아보기</label>
                  {data.id === 0 ? (
                    <div
                      className="btn_add"
                      onClick={() => {
                        if (uploadFile.length < 5) {
                          onCreate(uploadFile.length);
                        }
                      }}
                    >
                      추가　+
                    </div>
                  ) : (
                    <div
                      className="btn_add"
                      onClick={() => {
                        if (!uploadFile.length < 2) {
                          onRemove(uploadFile[i].id);
                        }
                      }}
                    >
                      삭제　-
                    </div>
                  )}
                </div>
              ))}
            </div>
          </li>
          <li>
            <div className="page_btn">
              <div
                className="btn_prev"
                onClick={() => {
                  navigate("/management/quick_order/search");
                }}
              >
                이전
              </div>
              <div
                className="next"
                onClick={() => {
                  sendData();
                }}
              >
                다음
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
