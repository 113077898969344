import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constant/QueryKeys";
import { searchUserApi } from "../../api/auth";

const useSearchUser = (keyword) => {
  return useQuery({
    queryKey: [QUERY_KEYS.SEARCH_USER],
    queryFn: () => searchUserApi(keyword),
  });
};

export default useSearchUser;
