import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updatePasswordApi } from "../../api/auth";
import { QUERY_KEYS } from "../../constant/QueryKeys";

const useUpdatePassword = (onSuccessCb, onErrorCb) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePasswordApi,
    onSuccess: (res) => {
      onSuccessCb(res);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.UPDATE_PASSWORD] });
    },
    onError: (error) => {
      onErrorCb(error);
    },
  });
};

export default useUpdatePassword;
