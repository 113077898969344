import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { inviteMailSchema } from "../../pages/myPage/my-info/components/modal/site-invite/schema/schema";
import useSearchUser from "../useQuery/useSearchUser";
import useInviteSite from "../useMutation/useInviteSite";
import useInviteMail from "../useMutation/useInviteMail";
import useFetchMyPageInfo from "../useQuery/useFetchMyPageInfo";

const useInviteSiteHandler = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: zodResolver(inviteMailSchema),
    mode: "all",
    defaultValues: {
      email: "",
    },
  });
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserList, setSelectedUserList] = useState([]);

  const { data, isLoading, refetch: handleSearchUser } = useSearchUser(keyword);
  const userList = data?.data;
  const { refetch: fetchMyPageInfo } = useFetchMyPageInfo();

  const inviteSuccessCb = ({ status }) => {
    if (status === "success") {
      handleSearchUser();
      fetchMyPageInfo();
      setSelectedUserList([]);
    }
  };

  const inviteErrorCb = (error) => {
    console.error("invite failed: ", error);
  };

  const { mutate: inviteSite } = useInviteSite(inviteSuccessCb, inviteErrorCb);

  const handleInviteSite = (inviteList) => {
    const userNoList = inviteList?.map((user) => user.no);

    inviteSite({ inviteList: userNoList });
  };

  const [siteInviteMailSuccess, setSiteInviteMailSuccess] = useState(false);
  const [siteInviteMailFail, setSiteInviteMailFail] = useState(false);

  const inviteMailSuccessCb = ({ status }) => {
    if (status === "success") {
      setSiteInviteMailSuccess(true);
      return;
    }

    if (status === "fail") {
      setSiteInviteMailFail(true);
      return;
    }
  };

  const inviteMailErrorCb = (error) => {
    console.error("invite mail failed: ", error);
  };

  const { mutate: inviteMail } = useInviteMail(
    inviteMailSuccessCb,
    inviteMailErrorCb
  );

  const handleInviteMail = (email) => {
    inviteMail(email);
  };

  const handleClickUser = (user) => {
    if (selectedUserList.some((u) => u.no === user.no)) {
      setSelectedUserList(selectedUserList.filter((u) => u.no !== user.no));
    } else {
      setSelectedUserList([...selectedUserList, user]);
    }
  };

  const isChecked = (user) => selectedUserList.some((u) => u.no === user.no);

  const handleSearch = () => {
    setSearchKeyword(keyword);
    handleSearchUser();
  };

  const segmentedOptions = ["기존 회원 초대", "미가입 회원 초대"];
  const [segmentedValue, setSegmentedValue] = useState("기존 회원 초대");

  useEffect(() => {
    setValue("email", "");
    clearErrors();
    setKeyword("");
    setSearchKeyword("");
    setSelectedUserList([]);
    handleSearchUser();
  }, [segmentedValue, setValue, clearErrors, handleSearchUser]);

  return {
    handleSubmit,
    control,
    errors,
    keyword,
    setKeyword,
    searchKeyword,
    selectedUser,
    setSelectedUser,
    selectedUserList,
    setSelectedUserList,
    isLoading,
    userList,
    handleInviteSite,
    handleClickUser,
    isChecked,
    handleSearch,
    handleInviteMail,
    segmentedOptions,
    segmentedValue,
    setSegmentedValue,
    siteInviteMailSuccess,
    setSiteInviteMailSuccess,
    siteInviteMailFail,
    setSiteInviteMailFail,
  };
};

export default useInviteSiteHandler;
