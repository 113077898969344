import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import EyeOpenedSVG from "../../../../../../asset/images/svg/EyeOpenedSVG";
import EyeClosedSVG from "../../../../../../asset/images/svg/EyeClosedSVG";

const PwdInput = ({ loginParams, setLoginParams, onKeyDown }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  const [pwdInVisible, setPwdInVisible] = useState(true);

  const onChangePassword = (e) => {
    setLoginParams({ ...loginParams, password: e.target.value });
  };

  const onClickSuffix = () => {
    setPwdInVisible((prev) => !prev);
  };

  return (
    <Input
      id="password"
      type={pwdInVisible ? "password" : "text"}
      size="large"
      width={isDesktop ? 280 : "100%"}
      placeholder="비밀번호를 입력해주세요."
      onChange={onChangePassword}
      suffix={pwdInVisible ? <EyeOpenedSVG /> : <EyeClosedSVG />}
      onClickSuffix={onClickSuffix}
      onKeyDown={onKeyDown}
    />
  );
};

export default PwdInput;
