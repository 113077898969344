import React from "react";
import classNames from "classnames/bind";
import styles from "./select.module.css";
import { Select as AntdSelect } from "antd";
import SelectSVG from "../../../asset/images/svg/SelectSVG";

const cx = classNames.bind(styles);

const Select = React.forwardRef(
  (
    {
      size = "small",
      width,
      placeholder = "",
      options = {},
      status = "",
      helpText = "",
      onChange,
      ...props
    },
    ref
  ) => {
    const className = cx("common", {
      [size]: true,
    });
    const popupClassName = cx("popup", {
      [`popup-${size}`]: true,
    });

    return (
      <div className={cx("container")}>
        <AntdSelect
          ref={ref}
          className={className}
          popupClassName={popupClassName}
          placeholder={placeholder}
          options={options}
          status={status}
          onChange={onChange}
          suffixIcon={<SelectSVG size={size === "small" ? "16" : "20"} />}
          listHeight={250}
          style={{ width: width }}
          {...props}
        />
        {helpText && <div>{helpText}</div>}
      </div>
    );
  }
);

export default Select;
