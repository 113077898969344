import React, { useEffect, useState } from "react";
//library
import { useNavigate } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/requestsimple.css";
//etc
// import { estimateEasyApi, imgUpApi, uploadExcel, estimateTimeOverApi } from '../../api/auth';

//lib
import swal from "sweetalert";
import moment from "moment";

//etc
import {
  contractListApi,
  contractPauseCancelApi,
  siteRegDateApi,
  aliveContractApi,
} from "../../api/auth";

import arrow_icon from "../../asset/images/excel_arrow_icon.png";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default";
import { differenceInMonths } from "date-fns";
import MaterialTablePop from "../../component/common/Pop/MaterialTablePop";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";

export default function Request() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isPosco = user?.rep_con === "(주)포스코이앤씨";

  // const { excel_list, choice_list, bid_period, bid_single, bid_state } =
  //   useSelector((state) => ({
  //     excel_list: state.estimate.excel_list,
  //     choice_list: state.estimate.choice_list,
  //     bid_period: state.estimate.bid_period,
  //     bid_single: state.estimate.bid_single,
  //     bid_state: state.estimate.bid_state,
  //   }));

  const userData = useSelector((state) => state.user);
  // const pushToken = useSelector((state) => state.push);

  // const bidNo = location.state.bid_no;
  // const savedInfo = location.state?.estimateInfo;
  // const savedInfoPeriod = location.state?.estimateInfoPeriod;

  const [uploadFile, setUploadFile] = useState([{ file: "", id: 0 }]);
  // [{...},{...}];
  // {file:asdfadsf,id:0} 이런식으로 나옴

  const [selectData, setSelectData] = useState([]);
  const [preSelectData, setPreSelectData] = useState([]);

  const [generating, setGenerating] = useState(false); //loading
  // 잡자재 기준표 모달
  const [materialTablePop, setMaterialTablePop] = useState(false);

  //시간 포맷 시간까지!
  // const formatDate = (date) => {
  //   var d = new Date(date),
  //     month = '' + (d.getMonth() + 1),
  //     day = '' + d.getDate(),
  //     year = d.getFullYear(),
  //     hour = '' + d.getHours(),
  //     min = '' + d.getMinutes();

  //   if (month.length < 2) month = '0' + month;
  //   if (day.length < 2) day = '0' + day;
  //   if (hour.length <2) hour = '0' + hour;
  //   if (min.length <2) min = '0' + min;

  //   return [year, month, day].join('-')+' '+[hour, min].join(':');
  // };
  //날짜
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  //견적요청 완료하기
  const sendData = async (step) => {
    // let totalBid = {
    //   excel_list : excel_list,
    //   choice_list : choice_list,
    //   bid_period : {...bid_period, comment : comment},
    //   bid_single : {...bid_single, comment : comment},
    //   bid_state : 'excel'
    // }

    // dispatch(actions.setEstimate(totalBid));

    if (step === "go_step3") {
      navigate("/management/bid/request_input_info");
    } else if (step === "go_step1") {
      navigate("/management/request_order", {
        state: {
          againFirst: true,
        },
      });
    }

    setGenerating(false);
  };

  var isDisabled = false;

  useEffect(() => {
    if (selectData.length !== 0) {
      setPreSelectData(selectData);
    }
  }, [selectData]);

  // const [isBlocking, setIsBlocking] = useState(false);

  //뒤로가기 확인
  // useEffect(() => {
  //   const unblock = history.block((loc, action) => {
  //     if (action === 'POP' && isBlocking) {
  //       swal('뒤로가기 할 수 없습니다. 확인버튼을 눌러주세요.','','warning');
  //       return false;
  //     }
  //     // return true;
  //   });

  //   return () => unblock();
  // }, [isBlocking]);

  // useEffect(() => {
  //   setIsBlocking(true);
  // }, [history]);

  const [cancelPop, setCancelPop] = useState(false);
  const [pauseCancelPop, setPauseCancelPop] = useState(false);
  const [maxNum, setMaxNum] = useState(0);
  const [firstData, setFirstData] = useState({
    s_no: 0,
    nowPage: 1,
    searchData: "ALL",
    startDate: "",
    lastDate: "",
  });
  const [contractNum, setContractNum] = useState(0);
  const [startDate, setStartDate] = useState(new Date(1970, 1, 1));
  const [lastDate, setLastDate] = useState("");
  const [contractList, setContractList] = useState([]);
  const [conStateData, setConStateData] = useState({});

  const siteRegDate = async () => {
    const s_no = firstData.s_no;
    const response = await siteRegDateApi(s_no);

    if (response.status === "success") {
      //2022-03-14 17:22:07"
      const originalDate = response.data.site_regdate;
      const date1 = originalDate.split(" ");
      const date2 = date1[0].split("-");

      const getDate = new Date(
        parseInt(date2[0], "10"),
        parseInt(date2[1] - 1, "10"),
        parseInt(date2[2], "10")
      );
      setStartDate(getDate);
    }
  };

  const swalReverse = () => {
    swal({
      title: "주문서 역발행",
      text: `
        1. 고객사가 주문서 발행 요청 (전화, 문자, 메일 등)
        2. 공급사에서 "주문서 생성" 후 품목, 단가, 수량 등 입력
        3. 주문서 발행 > 고객사 확인 > 배송 진행
      `,
      icon: "info",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
            * 주문서 역발행</br>
              급한 건에 대하여 선제적으로 납품 행위를 먼저 하는 방법으로</br>
              빠른 배송을 원하는 상황에 적합합니다.
          `,
        },
      },
    });
  };

  //조회
  // const lookUpFnc = async () => {
  //   const response = await contractListApi(
  //     firstData.s_no,
  //     firstData.nowPage,
  //     firstData.searchData,
  //     firstData.startDate,
  //     firstData.lastDate
  //   );

  //   if (response.status === 'success') {
  //     if(response.data.cList.length === 0){
  //       swal({
  //         title: "계약오류",
  //         text: "진행했던 지정기간 계약이 없습니다.",
  //         icon: "warning",
  //         buttons: true,
  //         // dangerMode: false,
  //       })
  //       .then((willDelete) => {
  //         if (willDelete) {
  //           history.push('/management/request');
  //         } else {
  //           history.push('/management/request');
  //         }
  //       });
  //     }
  //
  //     setContractList(response.data.cList);
  //     setMaxNum(response.data.maxNum);
  //     setConStateData(response.data.state);
  //   } else {
  //   }
  // };

  const lookUpFnc2 = async () => {
    const response = await aliveContractApi(firstData.s_no, 1);

    if (response.status === "success") {
      setContractList(response.data);
    }
  };

  //계약 타입
  const contractType = (data) => {
    if (data === "T" || data === "C") {
      return "단가계약";
    } else if (data === "S") {
      return "단일 건 계약";
    }
  };

  //계약 상태
  const contractState = (data, check) => {
    if (data === "START") {
      return "체결대기";
    } else if (data === "CANCEL") {
      return "취소";
    } else if (data === "ALIVE" || data === "PAUSE" || data === "DISAGREE") {
      if (check === "Y") {
        return "체결중(변경)";
      } else {
        return "진행중";
      }
    } else if (data === "HOLD") {
      return "중지요청";
    } else if (data === "STOP") {
      return "계약중지";
    } else if (data === "END" || data === "GRACE") {
      return "만료";
    } else if (data === "REQ") {
      return "계약대기";
    } else if (data === "COMPLETE") {
      return "진행중";
    }
  };

  useEffect(() => {
    let copy = firstData;
    copy.s_no = userData.site_num;
    copy.lastDate = formatDate(new Date());
    copy.startDate = formatDate(startDate);
    setFirstData(copy);
  }, []);

  useEffect(() => {
    if (firstData.s_no !== 0) {
      lookUpFnc2();
    }
  }, [firstData.nowPage, firstData.searchData]); //필터랑, 페이지 바뀔 때만 리스트새로받아오기

  useEffect(() => {
    siteRegDate();
  }, []);
  const _move2Success = () => {
    lookUpFnc2();
  };

  useEffect(() => {
    lookUpFnc2();
  }, []);

  //계약 종료일
  const endDateFnc = (databid, bidTerm) => {
    return formatDate(databid.setMonth(databid.getMonth() + Number(bidTerm)));
  };

  const diffMonths = (sdate, edate) => {
    const m_sdate = moment(sdate);
    const m_edate = moment(edate);

    return `(${m_edate.diff(m_sdate, "months")}개월)`;
  };

  return (
    <div className="request_container">
      <Mheader title="단가 계약서 선택하기" />
      <div className="request_container_wrap">
        <div className="top_box">
          <div className="title">단가 계약서 선택하기</div>

          <OrderBreadCrumb
            step1="계약서 선택"
            step2="상세정보 입력"
            step3="상세정보 확인"
            step4="주문완료"
            isActive={1}
          />
        </div>

        <div className="top_request_sub_header">
          <div className="top_request_sub_header_wrap">
            <p>
              <span>단가 계약을 선택하여 주문할 수 있습니다.</span>
            </p>
          </div>
        </div>

        <div className="reorder_list">
          {contractList.length > 0 ? (
            contractList.map((data, i) => {
              // if(data.ct_state === 'ALIVE')
              return (
                <div className="contract_content" key={data.contract.no}>
                  <div className="content_top">
                    <span>계약번호 :&nbsp;</span>
                    <span className="public_code">{data.contract.code}</span>
                  </div>
                  <div className="content_btm">
                    <div className="content_box">
                      <p className="company_img">
                        <img src={data.companyLogo} />
                      </p>
                      <div className="contract_info">
                        <div className="icon_box">
                          <p className={data.contract.state + " icon"}>
                            {/* {contractState(data.contract.state, data.contract.check)} */}
                            선택가능
                          </p>
                        </div>
                        <p className="contractor">
                          <span>
                            {/* {'[' + data.con_name + ']'}&nbsp;
                            {'/ ' + data.c_name}&nbsp;{data.s_name} */}
                            {data.company.name} 공급사
                          </span>
                          <span>
                            {contractType("T")}{" "}
                            {diffMonths(
                              data.contract.sdate,
                              data.contract.edate
                            )}
                          </span>
                        </p>
                        <div className="contract_text">
                          {/* <p className='company_info'>
                              계약기간 : {data.ct_sdate}&nbsp;-&nbsp;
                              {endDateFnc(
                                new Date(data.ct_sdate),
                                data.bid_term
                              )}
                            </p> */}
                          <p className="company_info">
                            주문품목 : {data.productName} 포함{" "}
                            {data.productCount}건
                          </p>
                          <p className="company_info">
                            계약일시 : {data.contract.createdDate.substr(0, 10)}{" "}
                            ~ {data.contract.edate.substr(0, 10)}
                          </p>
                          {/* <p>
                              계약기간 : 202020220
                            </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="contract_more">
                      {data.ct_state === "HOLD" && <p>중지 요청 취소</p>}
                      <p
                        onClick={() => {
                          navigate("/management/bid/request_reorder2", {
                            state: {
                              detailInfo: data,
                            },
                          });
                        }}
                      >
                        선택
                      </p>
                      {/* {data.ct_state === 'START' && (
                        <p
                          onClick={() => {
                            setCancelPop(true);
                            setContractNum(data.ct_no);
                          }}
                        >
                          계약 취소
                        </p>
                      )} */}
                      {/* {data.ct_state === 'ALIVE' && data.orderAvailable && (
                        <>
                          <p
                            onClick={() => {
                              const today = moment();
                              const startAt = moment(
                                data.ct_sdate,
                                'YYYY-MM-DD'
                              );
                              const endAt = moment(
                                endDateFnc(
                                  new Date(data.ct_sdate),
                                  data.bid_term
                                ),
                                'YYYY-MM-DD'
                              );

                              if (today.diff(startAt) < 0) {
                                swal(
                                  '계약기간이 되지 않아 주문서 작성이 불가능합니다.','','error'
                                );
                                return;
                              } else if (endAt.diff(today) < 0) {
                                swal(
                                  '계약기간이 지나 주문서 작성이 불가능합니다.','','error'
                                );
                                return;
                              }


                              history.push({
                                pathname:
                                  '/management/contract/order_issuance/' +
                                  data.ct_no,
                                state: {
                                  conInfo: data,
                                  contractNo: data.ct_no,
                                  // contractNo: 556,
                                },
                              });
                            }}
                          >
                            주문서 발행
                          </p>
                        </>
                      )} */}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="request_complete_container">
              <div className="request_complete_text">
                <h2>진행중인 계약이 없습니다.</h2>
              </div>
            </div>
          )}
        </div>

        <ul className="request_step4_list">
          <li></li>
        </ul>
      </div>
      {materialTablePop && (
        <MaterialTablePop setMaterialTablePop={setMaterialTablePop} />
      )}
    </div>
  );
}
