import React from "react";
import { DatePicker as AntdDatePicker } from "antd";
import styles from "./datePicker.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const DatePicker = React.forwardRef(
  (
    {
      size = "small",
      width,
      id = "",
      label = "",
      placeholder = "",
      helpText = "",
      status = "",
      onChange,
      ...props
    },
    ref
  ) => {
    const className = cx("common", {
      [size]: true,
    });

    return (
      <div className={cx("container")}>
        {label && <label htmlFor={id}>{label}</label>}
        <div>
          <AntdDatePicker
            ref={ref}
            className={className}
            popupClassName={cx("popup_common")}
            style={{ width: width }}
            id={id}
            placeholder={placeholder}
            status={status}
            onChange={onChange}
            {...props}
          />
        </div>
        {helpText && <div>{helpText}</div>}
      </div>
    );
  }
);

export default DatePicker;
