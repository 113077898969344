import React, { useState, useEffect } from "react";
//library
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import moment from "moment";
//component
import Mheader from "../../../component/common/Mheader";
//css
import "../../../asset/css/quickOrder.css";
import {
  contractAddrApi,
  quickOrderRequestApi,
  quickBidCreateApi,
  quickCreateApi,
} from "../../../api/auth";

import question_img from "../../../asset/images/question-icon.png";

//redux
import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";

import Loading from "../../../component/etc/Loading";

registerLocale("ko", ko);

export default function QuickOrder2() {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();
  // const step2Data = location.state.step2Data;
  const isOrder = location.state?.isOrder;
  const companyInfo = location.state?.companyInfo;
  const productData = location.state?.productData;
  const isExcel = location.state?.isExcel;
  const kind = location.state?.kind;

  // alert(isExcel);

  const [quickData, setQuickData] = useState({
    siteNo: userData.site_num, //고객사 번호
    companyNo: companyInfo.no, //공급사 번호
    fee: "Y", //배송비 포함 여부
    drop: "L", //하차 역무 포함 여부
    order: kind,
    refund: "공새로 위임 정산", //비용 정산 방법 --당분간 공새로 위임 정산으로
    edate: "", //납품일시
    addr: "", //납품 주소
    detail: "", //상세 주소
    uniqueness: productData.bid_comment,
    bidImgList: [],
    bidProductList: "",
  });

  //loading ui
  const [generating, setGenerating] = useState(false);

  // 날짜
  const [biddingDate, setbiddingDate] = useState();

  const [refundInfoPopup, setRefundInfoPopup] = useState(false);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const getTime = (min) => {
    //몇시몇분인지 리턴하는 함수
    const offset = (new Date().getTimezoneOffset() - min) * 60000; //isoString을 사용하면 국제시간 기준(+9시간)으로 되기 때문에 미리 빼줌
    const today = new Date(Date.now() - offset);
    let defaultTime = today.toISOString().slice(11, 16); //현재시간의 시와 분 리턴

    return defaultTime;
  };

  //퀵주문 기본정보
  const quickInfoFnc = async () => {
    setGenerating(true);
    if (quickData.edate === "" || quickData.edate === "Invalid date") {
      swal("납품일시를 선택해주세요", "", "warning");
    } else if (moment(quickData.edate).fromNow().includes("전")) {
      swal("납품일시를 확인해주세요", "", "warning");
    } else if (quickData.or_c_no === 0 || quickData.q_s_no === 0) {
      swal("다시 진행해주세요.", "", "warning");
    } else if (quickData.detail === "") {
      swal("상세주소를 입력해주세요.", "", "warning");
    } else {
      // if(isExcel){

      // } else{
      const productFinalData = productData.pList.map((item) => ({
        productNo: !isExcel ? item.no : "",
        name: item.name,
        standard: item.standard,
        unit: item.unit,
        count: !isExcel ? item.cartAmount : item.count,
        etc: item.etc,
        productImage: !isExcel ? item.productImage : "",
      }));

      //setstate로 넣어주니 에러가 발생하기에 변수에 할당함.
      let finalData = {
        ...quickData,
        bidProductList: productFinalData,
        bidImgList: isExcel ? productData.bidImgList : [],
      };

      const response = await quickCreateApi(finalData);

      if (response.status === "success") {
        navigate("/management/quick_order_complete");
      } else {
      }
      // }

      setGenerating(false);
    }
    setGenerating(false);
  };

  const siteNumSendFnc = async () => {
    const response = await contractAddrApi(userData.site_num);

    if (response.status === "success") {
      setQuickData({
        ...quickData,
        addr: response.data.addr1,
        detail: response.data.addr2,
      });
    } else {
      swal("오류", "", "error");
    }
  };

  useEffect(() => {
    //처음에 현장 기본주소 불러와야함.
    siteNumSendFnc();
  }, []);

  const now = new Date();

  const getDiffDate = (selectDate) => {
    let diff = moment(selectDate).fromNow();
    if (diff.includes("전")) {
      // setQuickData({
      //   ...quickData,
      //   edate: 'Invalid date',
      // });
      return "날짜와 시간을 확인해주세요.";
    } else {
      return "약 " + diff;
    }
  };

  return (
    <div className="quick_order2_wrap">
      <Mheader title="퀵주문" />
      <div className="quick_order2_container" style={{ paddingTop: "0" }}>
        <div className="quick_bar_container">
          {isOrder ? (
            <div className="top_progress_bar" style={{ top: "140px" }}>
              <div
                className="menus"
                onClick={() => navigate("/management/request_order")}
              >
                주문방법선택
              </div>
              <div className="menus not_allowed">
                {isOrder ? companyInfo.name : "공급사 선택"}
              </div>
              <div className="menus not_allowed">입력 방법 선택</div>
              <div className="menus not_allowed">
                {isExcel ? "엑셀 간편 입력" : "품목 직접 선택"}
              </div>
              <div className="active_menus color1">상세정보 입력</div>
              <div className="menus not_allowed">완료</div>
            </div>
          ) : (
            <div className="top_progress_bar">
              <div
                className="menus"
                onClick={() => navigate("/management/request_order")}
              >
                주문방법선택
              </div>
              <div
                className="menus"
                onClick={() => navigate("/management/quick_order/search")}
              >
                공급사 선택
              </div>
              <div className="menus not_allowed">견적서 간편 입력</div>
              <div className="active_menus color3">상세정보 입력</div>
              <div className="menus not_allowed">완료</div>
            </div>
          )}
        </div>

        <ul className="many_input_list">
          <li>
            <p>납품일시</p>
            <div className="bidding_date_box">
              {/* <div className='date_picker_wrap'>
                <DatePicker
                  locale='ko'
                  selected={biddingDate}
                  onChange={(date) => {
                    setbiddingDate(date);
                    setQuickData({
                      ...quickData,
                      or_edate: formatDate(date) + ' ' + biddingTime,
                    });
                    minValueTime(date, biddingTime);
                  }}
                  selectsStart
                  minDate={new Date()}
                  biddingDate={biddingDate}
                  dateFormat='yyyy-MM-dd'
                  className={alertMsg ? 'select_alert' : null}
                />
              </div>
              <input
                type='time'
                name=''
                className={
                  alertMsg
                    ? 'quick_order2_time_selector select_alert'
                    : 'quick_order2_time_selector'
                }
                value={biddingTime}
                required
                onChange={(e) => {
                  setbiddingTime(e.target.value);
                  setQuickData({
                    ...quickData,
                    or_edate: formatDate(biddingDate) + ' ' + e.target.value,
                  });
                  minValueTime(biddingDate, e.target.value);
                }}
              /> */}
              <DatePicker
                locale="ko"
                selected={biddingDate ? biddingDate : null}
                // closeOnScroll={true} //when scroll close
                minDate={new Date()}
                minTime={
                  //formatDate가 같다면 10분전까지만 select
                  formatDate(biddingDate) === formatDate(new Date())
                    ? new Date().setHours(new Date().getHours() + 1)
                    : now.setHours(0, 0, 0)
                }
                maxTime={now.setHours(23, 59, 59)}
                showTimeSelect // 시간 나오게 하기
                timeFormat="HH:mm" //시간 포맷
                timeIntervals={30} // 10분 단위로 선택 가능한 box가 나옴
                timeCaption="time"
                dateFormat="yyyy/MM/dd HH:mm 까지"
                isClearable={true}
                peekNextMonth
                withPortal
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date) => {
                  setbiddingDate(date);
                  setQuickData({
                    ...quickData,
                    edate: moment(date).format("yyyy-MM-DD HH:mm:00"),
                  });
                  // minValueTime(formatDate(date), deliveryTime);
                }}
                // 날짜만 선택하고 닫으면
                // 시간이 오전 12시로 pick되는 issue 발생
                // 마지막에 validation으로 formatdate이용해서 체크하는거 추천
              />
            </div>
          </li>
          {biddingDate && (
            <span className="bid_time_info">{getDiffDate(biddingDate)}</span>
          )}

          {/* {alertMsg ? (
            <span className='bid_time_info'>
              납품일시는 현재시간보다 이후만 가능합니다.
            </span>
          ) : null} */}
          <li>
            <p>납품 주소</p>
            <div>
              <input
                type="text"
                value={quickData?.addr}
                readOnly="readonly"
                className="quick_order_address"
                disabled
              />
              <input
                type="text"
                placeholder="납품을 원하는 상세 주소를 설명해주세요."
                className="quick_order2_address_detail"
                defaultValue={quickData.detail}
                onChange={(e) => {
                  setQuickData({ ...quickData, detail: e.target.value });
                }}
              />
            </div>
          </li>
          <li className="sort_line"></li>
          <li>
            <p>배송비 포함 여부</p>
            <div>
              <select
                onChange={(e) => {
                  setQuickData({
                    ...quickData,
                    fee: e.target.value,
                  });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="" hidden></option>
                <option value="Y" selected>
                  포함
                </option>
                <option value="N">포함 안함</option>
              </select>
            </div>
          </li>
          <li>
            <p>하차 역무 포함 여부</p>
            <div>
              <select
                onChange={(e) => {
                  setQuickData({
                    ...quickData,
                    drop: e.target.value,
                  });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="" hidden></option>
                <option value="U">하차역무 없음 ❌</option>
                <option value="L" selected>
                  하차역무 있음 ⭕️
                </option>
              </select>
            </div>
          </li>
          <li>
            <p>
              비용 정산 방법
              <img
                className="question_img"
                src={question_img}
                onMouseOver={() => {
                  setRefundInfoPopup(!refundInfoPopup);
                }}
                onMouseLeave={() => {
                  setRefundInfoPopup(false);
                }}
              ></img>
              {refundInfoPopup ? (
                <p className="question_box_refund">
                  "공새로 위임정산건"입니다. <br></br>
                  아래 번호로 문의 바랍니다.<br></br>
                  고객센터 (T.032-205-0479)
                </p>
              ) : null}
            </p>
            <div>
              <select
                onChange={(e) => {
                  setQuickData({
                    ...quickData,
                    refund: e.target.value,
                  });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="공새로 위임 정산" selected>
                  공새로 위임 정산
                </option>
              </select>
            </div>
          </li>
          <li>
            <p></p>
            <div>
              <div className="btn_box">
                <div className="prev">
                  <Link to="/management/quick_order/search">이전</Link>
                </div>
                <div className="next">
                  <p
                    onClick={() => {
                      userData.constructionType !== "SUBCONTRACTOR"
                        ? quickInfoFnc()
                        : swal(
                            "건설사 전용 기능입니다.",
                            "이 기능을 사용하기 위해서는 건설사 회원으로 전환이 필요합니다.",
                            "warning"
                          );
                    }}
                    style={{ color: "#fff", width: "auto" }}
                  >
                    다음
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      {generating && <Loading msg={"로딩 중입니다."} />}
    </div>
  );
}
