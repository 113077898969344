import useCreateConstructionHandler from "../../../hooks/handler/useCreateConstructionHandler";
import { useNavigate } from "react-router-dom";

import Title from "./components/elements/title/Title";
import Mheader from "../../../component/common/Mheader";
import BizLogoImg from "./components/elements/logo/BizLogoImg";
import BizNameInput from "./components/elements/input/BizNameInput";
import BizRegInput from "./components/elements/input/BizRegInput";
import BizRegUploadButton from "./components/elements/button/BizRegUploadButton";
import BizCategorySelect from "./components/elements/select/BizCategorySelect";
import BizNoInput from "./components/elements/input/BizNoInput";
import CeoNameInput from "./components/elements/input/CeoNameInput";
import BizItemInput from "./components/elements/input/BizItemInput";
import BizTypeInput from "./components/elements/input/BizTypeInput";
import AddrInput from "./components/elements/input/AddrInput";
import AddrDetailInput from "./components/elements/input/AddrDetailInput";
import AddrSearchButton from "./components/elements/button/AddrSearchButton";
import UserTypeRadio from "./components/elements/radio/UserTypeRadio";
import CreateButton from "./components/elements/button/CreateButton";
import CancelButton from "./components/elements/button/CancelButton";
import AddrSearchModal from "./components/modal/addr-search/AddrSearchModal";
import ConstructionCreateModal from "./components/modal/construction-create/ConstructionCreateModal";

import s from "./createConstruction.module.css";

const CreateConstruction = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    errors,
    setValue,
    clearErrors,

    setBizLogoFile,
    setBizRegFile,
    onClickSubmit,
    handleCreateConstruction,

    addrSearchModal,
    setAddrSearchModal,
    constructionCreateModal,
    setConstructionCreateModal,
  } = useCreateConstructionHandler();

  return (
    <div className={s.page_container}>
      <Mheader title="회사 상세정보" />

      <section className={s.title_section}>
        <Title />
      </section>

      <form className={s.form_container} onSubmit={handleSubmit(onClickSubmit)}>
        <section className={s.input_section}>
          <BizLogoImg control={control} setBizLogoFile={setBizLogoFile} />
          <BizNameInput control={control} errors={errors} />

          <div className={s.input_with_button_container}>
            <BizRegInput control={control} errors={errors} />
            <BizRegUploadButton
              setBizRegFile={setBizRegFile}
              clearErrors={clearErrors}
            />
          </div>

          <BizCategorySelect control={control} errors={errors} />
          <BizNoInput control={control} errors={errors} />
          <CeoNameInput control={control} errors={errors} />

          <div className={s.biz_item_type_container}>
            <BizItemInput control={control} />
            <BizTypeInput control={control} />
          </div>

          <div className={s.addr_container}>
            <div className={s.input_with_button_container}>
              <AddrInput control={control} errors={errors} />
              <AddrSearchButton
                setAddrSearchModal={setAddrSearchModal}
                clearErrors={clearErrors}
              />
            </div>
            <AddrDetailInput control={control} errors={errors} />
          </div>

          <UserTypeRadio control={control} />
        </section>

        <section className={s.button_container}>
          <CancelButton navigate={navigate} />
          <CreateButton />
        </section>
      </form>

      {addrSearchModal && (
        <AddrSearchModal
          addrSearchModal={addrSearchModal}
          setAddrSearchModal={setAddrSearchModal}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      )}
      {constructionCreateModal && (
        <ConstructionCreateModal
          constructionCreateModal={constructionCreateModal}
          setConstructionCreateModal={setConstructionCreateModal}
          handleCreateConstruction={handleCreateConstruction}
        />
      )}
    </div>
  );
};

export default CreateConstruction;
