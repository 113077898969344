import Typography from "../../../component/ui/typography/Typography";
import s from "./typographyTest.module.css";

export default function TypographyTest() {
  return (
    <div className={s.typography_group_container}>
      <Typography component="p" type="h1">
        TYPOGRAPHY COMPONENT TEST
      </Typography>
      <div>
        <Typography component="p" type="c1">
          P
        </Typography>
        <Typography component="span" type="c1">
          SPAN
        </Typography>
        <Typography component="div" type="c1">
          DIV
        </Typography>
      </div>

      <Typography component="p" type="h1">
        TYPOGRAPHY TYPE TEST
      </Typography>
      <div>
        <Typography component="p" type="h1">
          H1
        </Typography>
        <Typography component="p" type="h2">
          H2
        </Typography>
        <Typography component="p" type="h3">
          H3
        </Typography>
        <Typography component="p" type="h4">
          H4
        </Typography>
        <Typography component="p" type="h5">
          H5
        </Typography>
        <Typography component="p" type="c1">
          C1
        </Typography>
        <Typography component="p" type="c2">
          C2
        </Typography>
        <Typography component="p" type="c3">
          C3
        </Typography>
        <Typography component="p" type="c4">
          C4
        </Typography>
        <Typography component="p" type="c5">
          C5
        </Typography>
        <Typography component="p" type="c6">
          C6
        </Typography>
      </div>

      <Typography component="p" type="h1">
        TYPOGRAPHY COLOR TEST
      </Typography>
      <div>
        <Typography component="p" type="c1" color="var(--sub-red-10)">
          RED
        </Typography>
        <Typography component="p" type="c1" color="var(--sub-blue-10)">
          BLUE
        </Typography>
        <Typography component="p" type="c1" color="var(--sub-green-10)">
          GREEN
        </Typography>
      </div>
    </div>
  );
}
