import { useMediaQuery } from "react-responsive";
import "../../../asset/css/bidStatusPop.css";
import close_icon from "../../../asset/images/cancel-btn.png";

export function BidStatusPopItem(props) {
  const { bidStatus, onClickSendBidRequestMessage } = props;
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });

  const chipBgByBidStatus = () => {
    if (bidStatus.isBiding && bidStatus.isRead) {
      return "bid_status_modal_chip_isBid";
    }
    if (!bidStatus.isBiding && bidStatus.isRead) {
      return "bid_status_modal_chip_isRead";
    }
    if (!bidStatus.isBiding && !bidStatus.isRead) {
      return "bid_status_modal_chip_none";
    }
  };

  const isDisabledButtonBySendStatus = () => {
    if (bidStatus.isSend) {
      return "bid_status_modal_send_btn_disabled";
    }
    if (!bidStatus.isSend) {
      return "bid_status_modal_send_btn";
    }
  };

  return (
    <div className="bid_status_modal_list_item">
      <div className="bid_status_modal_list_inner_container">
        <div className="bid_status_modal_chip_container">
          <div className={chipBgByBidStatus()}>
            {bidStatus.isBiding && bidStatus.isRead && "투찰완료"}
            {!bidStatus.isBiding && bidStatus.isRead && "확인완료"}
            {!bidStatus.isBiding && !bidStatus.isRead && "미확인"}
          </div>
        </div>

        <div className="bid_status_modal_company_container">
          {isDesktop && (
            <div className="bid_status_modal_company_logo">
              <img src={bidStatus.companyLogoImage} alt="close_icon" />
            </div>
          )}
          <div className="bid_status_modal_company_description">
            <div>{bidStatus.company.name}</div>
            <div>공급사 분류 : {bidStatus.company.type}</div>
            <div title={bidStatus.company.addr1}>{bidStatus.company.addr1}</div>
            <div>대표번호 : {bidStatus.company.phone}</div>
          </div>
        </div>
      </div>

      <button
        className={isDisabledButtonBySendStatus()}
        onClick={() => {
          if (!bidStatus.isSend) {
            onClickSendBidRequestMessage([bidStatus.company.no]);
          }
        }}
      >
        {bidStatus.isSend && "발송완료"}
        {!bidStatus.isSend && "투찰요청"}
      </button>
    </div>
  );
}

export default function BidStatusPop(props) {
  const { bidStatusList, closeBidStatusPop, onClickSendBidRequestMessage } =
    props;

  return (
    <div className="bid_status_modal_background">
      <div className="bid_status_modal_container">
        <div className="bid_status_modal_header">
          <div className="bid_status_modal_title">투찰현황확인</div>
          <div className="bid_status_modal_close" onClick={closeBidStatusPop}>
            <img src={close_icon} alt="close_icon" />
          </div>
        </div>

        <div className="bid_status_modal_divider" />

        <div className="bid_status_modal_list_container">
          {bidStatusList?.map((bidStatus) => (
            <BidStatusPopItem
              bidStatus={bidStatus}
              onClickSendBidRequestMessage={onClickSendBidRequestMessage}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
