import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import * as S from "../../asset/css/invoice.styles";
import Mheader from "../../component/common/Mheader";
import DashBoardTabs from "../../component/etc/DashBoardTabs";
import SiteSelect from "../../component/etc/SiteSelect";
import useInvoiceCount from "./customHooks/useInvoiceCount";
import useInvoiceList from "./customHooks/useInvoiceList";
import InvoiceList from "./InvoiceList";
import useSiteInfo from "./customHooks/useSiteInfo";

export default function Invoice() {
  const location = useLocation();
  const fromPage = location?.state?.fromPage;
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  const { countByState, fetchInvoiceCountByState } = useInvoiceCount();
  const { invoiceList, state, fetchInvoiceList } = useInvoiceList(fromPage);
  const { selectSwitch, setSelectSwitch, siteList, setSiteList } =
    useSiteInfo();

  const calculateEntireTotalAmount = () => {
    const totalAmounts =
      invoiceList?.map((invoice) => invoice?.customerInvoice?.totalAmount) ||
      [];
    const entireTotalAmount = totalAmounts.reduce((acc, cur) => acc + cur, 0);

    return entireTotalAmount ? entireTotalAmount.toLocaleString("ko") : "-";
  };

  return (
    <S.Wrapper>
      <Mheader title="정산관리" />
      <S.DashboardContainer>
        {isDesktop && <DashBoardTabs />}
        <S.DashBoardContent>
          <S.ContentHeader>
            <S.TitleWrap>
              <S.Title>나의 정산관리 현황</S.Title>
            </S.TitleWrap>
          </S.ContentHeader>

          <S.ContentBody>
            <S.PaymentStateWrap>
              <S.RequestState state={state}>정산요청</S.RequestState>
              <S.CheckPaymentRequestListBtn
                onClick={() => fetchInvoiceList("request")}
                state={state}
              >
                {countByState?.request}건
              </S.CheckPaymentRequestListBtn>
            </S.PaymentStateWrap>

            <S.PaymentStateWrap>
              <S.ProcessState state={state}>세금계산서 발행</S.ProcessState>
              <S.PaymentProcessingBtn
                onClick={() => fetchInvoiceList("process")}
                state={state}
              >
                {countByState?.process}건
              </S.PaymentProcessingBtn>
            </S.PaymentStateWrap>

            <S.PaymentStateWrap>
              <S.CompleteState state={state}>정산완료</S.CompleteState>
              <S.PaymentCompletedBtn
                onClick={() => fetchInvoiceList("complete")}
                state={state}
              >
                {countByState?.complete}건
              </S.PaymentCompletedBtn>
            </S.PaymentStateWrap>

            {isDesktop && <S.DivideLine />}

            <S.PaymentStateWrap>
              <S.AllState state={state}>전체보기</S.AllState>
              <S.PaymentAllBtn
                onClick={() => fetchInvoiceList("all")}
                state={state}
              >
                {countByState?.all}건
              </S.PaymentAllBtn>
            </S.PaymentStateWrap>
          </S.ContentBody>

          <S.ContentFooter>
            <S.EntireTotalAmount>
              당월 총 금액 : {calculateEntireTotalAmount()} 원 (VAT 제외)
            </S.EntireTotalAmount>
          </S.ContentFooter>
        </S.DashBoardContent>
      </S.DashboardContainer>

      {invoiceList?.length &&
        invoiceList?.map((invoice, index) => (
          <InvoiceList
            invoice={invoice}
            index={index}
            state={state}
            key={invoice.customerInvoice.no}
            fetchInvoiceList={fetchInvoiceList}
            fetchInvoiceCountByState={fetchInvoiceCountByState}
          />
        ))}

      {invoiceList?.length === 0 && (
        <S.NoInvoiceAlert>
          <S.NoInvoiceAlertText>내역이 존재하지 않습니다</S.NoInvoiceAlertText>
        </S.NoInvoiceAlert>
      )}

      {selectSwitch && (
        <SiteSelect
          selectSwitch={selectSwitch}
          setSelectSwitch={setSelectSwitch}
          siteList={siteList}
          setSiteList={setSiteList}
          isPath={"invoice"}
        />
      )}
    </S.Wrapper>
  );
}
