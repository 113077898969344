import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import DaumPostcodeEmbed from "react-daum-postcode";

const AddrSearchModal = ({
  addrSearchModal,
  setAddrSearchModal,
  setValue,
  clearErrors,
}) => {
  const onClickCancel = () => {
    setAddrSearchModal(false);
  };

  const onCompletePost = (data) => {
    setValue("addr", data.address);
    setValue("zip", data.zonecode);
    clearErrors("addr");
    setAddrSearchModal(false);
  };

  return (
    <Modal
      size="xsmall"
      open={addrSearchModal}
      title={
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          주소 검색
        </Typography>
      }
      onCancel={onClickCancel}
      footer={null}
    >
      <DaumPostcodeEmbed onComplete={onCompletePost} />
    </Modal>
  );
};

export default AddrSearchModal;
