import { checkSocialRegistered, socialLogin, logError } from "../api/auth";
import { actions } from "../redux/action";

//sweet alert
import swal from "sweetalert";

export const wonComma = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const errorLog = (key, value) => {
  let userAgent = navigator.userAgent;
  userAgent = userAgent.replace(";", "");
  const reqData = {
    userAgent: userAgent,
    key: key,
    value: value,
  };
  logError(reqData);
};

export const generateRandomKey = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const _checkSocialRegistered = async (data) => {
  const response = await checkSocialRegistered(data);

  if (response.status === "success") {
    return false;
  } else if (response.message === "AlreadyRegistered") {
    return true;
  } else {
    //error
    return -1;
  }
};

export const processSocialLogin = async (
  type,
  value,
  navigate,
  dispatch,
  pushToken
) => {
  const reqData = {
    type: type,
    value: value,
  };

  const response = await _checkSocialRegistered(reqData);
  if (response === false) {
    //가입처리
    swal(
      "가입되지 않은 소셜 계정입니다.\n가입 후 공새로만의 특별한 서비스를 이용해보세요.",
      "",
      "warning"
    );
    navigate(`/join_type`, {
      state: {
        socialType: type,
        socialValue: value,
      },
    });
    // navigate replace
  } else if (response === true) {
    //로그인처리
    const response = await socialLogin(type, value, pushToken);

    if (response.status === "success") {
      if (response.data.u_type !== "S") {
        swal(
          "현장 관리자만 로그인이 가능합니다. 공급사 관계자께서는 공급사 페이지를 이용해주세요.",
          "",
          "info"
        );
        return;
      }

      let newUserData = {
        u_no: response.data.u_no,
        u_name: response.data.u_name,
        u_phone: response.data?.u_phone,

        site_num: false,
        rep_site: false,
        rep_con: false,
      };

      dispatch(actions.setUser(newUserData));
      dispatch(actions.setToken(response.data.signToken));
    } else {
      swal("회원 정보가 일치하지 않습니다.", "", "warning");
    }
  } else {
    swal("알 수 없는 오류가 발생하였습니다.(2)", "", "error");
    navigate("/", { replace: true });
  }
};

//금액
export const numberWithCommas = (x) => {
  if (x != undefined) {
    //로딩이 되기 전에 return을 받아버리면 undefined때문에 toString에서 에러가남
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
