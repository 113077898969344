//아임포트
export const IMP_CODE = 'imp44131202';
export const PG = 'html5_inicis';

//social login
export const KAKAO_ID = '3955a4ed1ff8736d39417b115f143831'; //javascript key
export const GOOGLE_ID =
  '522618540862-5m42jo4fg6j7e7fu7p7u6kitrp16ks7l.apps.googleusercontent.com';
//522618540862-pacmscgd3q2p0vd23t3isk5sthclj35t.apps.googleusercontent.com

export const NAVER_CLIENT_ID = '1v4e8wyxDIfhkni7_sSL';
export const NAVER_CALLBACK_URL = 'https://customer.gongsaero.com/naver-login';

/**
 * naver
 * http://localhost:3000/#access_token=AAAAOZhFvYbvSaIwoZhGYRrntak_rXdwSB_MokBhfPIEJ0jh3vZva7l_yjRdbEI2ivwuAHXkPzo49-fIMVk23TzYLD8&state=2a5e1cd8-d503-4f49-b5c4-d179dfaceba3&token_type=bearer&expires_in=3600
 *
 *
 */
