import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import Button from "../../../../../../component/ui/button/Button";

const SiteAttendSuccessModal = ({
  siteAttendSuccessModal,
  setSiteAttendSuccessModal,
  setSiteAttendModal,
}) => {
  const onClickCancel = () => {
    setSiteAttendSuccessModal(false);
    setSiteAttendModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="success"
      open={siteAttendSuccessModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.BLUE.L05}>
          현장이 추가되었습니다.
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="primary" size="small" onClick={onClickCancel}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        현장 추가 요청이 완료되었습니다.
      </Typography>
      <Typography component="p" type="c2">
        현장 관계자의 승인을 통해 현장에 추가가 완료됩니다.
      </Typography>
      <Typography component="p" type="c2">
        현장 관계자는 마이페이지에서 요청을 승인할 수 있습니다.
      </Typography>
    </Modal>
  );
};

export default SiteAttendSuccessModal;
