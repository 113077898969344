import styled from "@emotion/styled";

export const ProductNameWrap = styled.td`
  text-align: left;
  span {
    vertical-align: middle;
    display: inline-block;
    width: 70%;
    @media (max-width: 1080px) {
      width: 60%;
    }
  }
`;

export const ProductImage = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: zoom-in;
  @media (max-width: 1080px) {
    margin-right: 2rem;
    width: 7rem;
    height: 7rem;
  }
`;

export const DefaultFileImgWrap = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: not-allowed;
  @media (max-width: 1080px) {
    margin-right: 2rem;
    width: 7rem;
    height: 7rem;
  }
`;

export const Diff = styled.div`
  color: ${(props) => (props.diff > 0 ? "#FF3B30" : "#0061FD")};
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`;

export const TabWrap = styled.div``;

export const TabBtn = styled.button`
  width: 90px;
  height: 30px;
  font-size: 12px;
  padding: 0px 10px;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #c6c6c8;
  border-bottom: none;
  color: #c6c6c8;
  &:hover {
    font-weight: 600;
    border-bottom: none;
    color: #000;
    background: #f7f7f7;
  }
  @media (max-width: 1080px) {
    width: 20rem;
    height: 8rem;
    font-size: 3.5rem;
  }
`;

export const SelectedTabBtn = styled(TabBtn)`
  font-weight: 600;
  border-bottom: none;
  color: #000;
  background: #f7f7f7;
`;

export const BadgeWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Badge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #254574;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-right: 10px;
  background: ${(props) =>
    (props?.el?.orders?.state === "START" && "#107C41") ||
    (props?.el?.orders?.state === "CHECK" && "#005BAA") ||
    (props?.el?.orders?.state === "DONE" && "#005BAA") ||
    (props?.el?.orders?.state === "TESTED" && "#005BAA") ||
    (props?.el?.orders?.state === "REJECT" && "#ff6600") ||
    (props?.el?.orders?.state === "CANCEL" && "#9C9EA1") ||
    (props?.el?.orders?.state === "END" && "#9C9EA1")};
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const PbBadge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #254574;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-right: 10px;
  background: ${(props) =>
    (props?.el?.pbOrders?.state === "PENDING" && "#005baa") ||
    (props?.el?.pbOrders?.state === "READY" && "#005baa") ||
    (props?.el?.pbOrders?.state === "DONE" && "#005baa")};
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const TypeBadge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #fff;
  border: 1px solid
    ${(props) =>
      (props?.el?.bidType === "A" && "#33C481") ||
      (props?.el?.bidType === "S" && "#21A366") ||
      (props?.el?.bidType === "T" && "#0064FF") ||
      (props?.el?.bidType === "P" && "#005BAA") ||
      (props?.el?.bidType === "Q" && "#0096D1") ||
      (props?.el?.bidType === "R" && "#ff6600") ||
      (props?.el?.bidType === "B" && "#11366B") ||
      (props?.el?.bidType === "D" && "#11366B")};
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) =>
    (props?.el?.bidType === "A" && "#33C481") ||
    (props?.el?.bidType === "S" && "#21A366") ||
    (props?.el?.bidType === "T" && "#0064FF") ||
    (props?.el?.bidType === "P" && "#005BAA") ||
    (props?.el?.bidType === "Q" && "#0096D1") ||
    (props?.el?.bidType === "R" && "#ff6600") ||
    (props?.el?.bidType === "B" && "#11366B") ||
    (props?.el?.bidType === "D" && "#11366B")};
  margin-bottom: 10px;
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
`;

export const PbCalcBadge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-right: 10px;
  background: ${(props) =>
    (props.el?.taxFileUrl !== null && "#83b576") ||
    (props.el?.taxFileUrl === null && "#005BAA")};
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;
