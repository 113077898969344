import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import Select from "../../../../../../component/ui/select/Select";
import s from "./processSelect.module.css";

const options = [
  { value: "토목 공사", label: "토목 공사" },
  { value: "기초 공사", label: "기초 공사" },
  { value: "골조 공사", label: "골조 공사" },
  { value: "설비 공사", label: "설비 공사" },
  { value: "마감 공사", label: "마감 공사" },
  { value: "부대 공사", label: "부대 공사" },
  { value: "공통 공사", label: "공통 공사" },
];

const ProcessSelect = ({ errors, control }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="process"
      control={control}
      render={({ field }) => {
        return (
          <div className={s.select_container}>
            <label htmlFor="process">
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                현재 공종 단계{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </label>

            <Select
              {...field}
              id="process"
              value={field.value || undefined}
              size="medium"
              width={isDesktop ? 380 : "100%"}
              placeholder="공종 단계를 선택해주세요."
              options={options}
              status={errors.process && "error"}
            />

            {errors.process && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.process.message}
              </Typography>
            )}
          </div>
        );
      }}
    />
  );
};

export default ProcessSelect;
