import classNames from "classnames/bind";
import styles from "./segmented.module.css";
import { Segmented as AntdSegmented } from "antd";

const cx = classNames.bind(styles);

const Segmented = ({ options, size, value, onChange, ...props }) => {
  const className = cx("common", {
    [size]: true,
  });

  return (
    <AntdSegmented
      className={className}
      options={options}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default Segmented;
