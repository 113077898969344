import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constant/QueryKeys";
import { fetchMyPageInfoApi } from "../../api/auth";
import { useSelector } from "react-redux";

const useFetchMyPageInfo = () => {
  const token = useSelector((state) => state.token);

  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_MY_PAGE_INFO],
    queryFn: fetchMyPageInfoApi,
    enabled: !!token,
  });
};

export default useFetchMyPageInfo;
