import React, { useState } from 'react';
import i_close from '../../../asset/images/w-cancel-icon.png';
import i_img from '../../../asset/images/w-img-ul-icon.png';
import i_file from '../../../asset/images/w-file-upld-icon.png';
import i_user from '../../../asset/images/w-plus-user-icon.png';

export default function ChatPop({ setChatPop, setImgPop, setFilePop, setUserPop }) {
  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setChatPop(false)}></div>
      <div className="chat_pop">
        <div className="close" onClick={() => setChatPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <div className="chat_pop_info">
          <ul className="chat_pop_list">
            <li onClick={() => { setImgPop(true); setChatPop(false); }}>
              <div className="chat_icon">
                <img src={i_img} alt="" />
              </div>
              <p>이미지 첨부</p>
            </li>
            <li onClick={() => { setFilePop(true); setChatPop(false); }}>
              <div className="chat_icon">
                <img src={i_file} alt="" />
              </div>
              <p>파일 첨부</p>
            </li>
            <li onClick={() => { setUserPop(true); setChatPop(false); }}>
              <div className="chat_icon">
                <img src={i_user} alt="" />
              </div>
              <p>유저 초대</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
