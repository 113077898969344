import { useCallback, useEffect, useState } from "react";
import { updateInvoiceCodeApi } from "../../../api/auth";

export default function useInvoiceListItemList(
  ordersDetailItem,
  fetchInvoiceListItem,
  invoice
) {
  const [selectedOption, setSelectedOption] = useState("");
  const [updatedInvoiceCode, setUpdatedInvoiceCode] = useState("");

  const updateInvoiceCode = useCallback(
    async (code) => {
      const response = await updateInvoiceCodeApi(
        ordersDetailItem?.orderlist.no,
        code
      );
      if (response.status === "success") {
        setUpdatedInvoiceCode(response);
      }
    },
    [ordersDetailItem?.orderlist.no]
  );

  useEffect(() => {
    if (selectedOption) updateInvoiceCode(selectedOption);
  }, [selectedOption, updateInvoiceCode]);

  useEffect(() => {
    if (updatedInvoiceCode?.status === "success")
      fetchInvoiceListItem(invoice?.customerInvoice?.no);
  }, [updatedInvoiceCode, fetchInvoiceListItem, invoice]);

  return { selectedOption, setSelectedOption };
}
