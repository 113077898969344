import styled from "@emotion/styled";

export const Wrapper = styled.div`
  @media (max-width: 1080px) {
    width: 970.02px;
  }
`;

export const InvoiceItemList = styled.div`
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: 40px;
  &:hover {
    background: #f2f5f9;
  }
  div {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    border-right: 1px solid #ddd;
  }
  div:nth-of-type(1) {
    width: 14%;
  }
  div:nth-of-type(2) {
    width: 22%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
  div:nth-of-type(3) {
    width: 13%;
  }
  div:nth-of-type(4) {
    width: 16%;
  }
  div:nth-of-type(5) {
    width: 18%;
  }
  div:nth-of-type(6) {
    width: 17%;
    border-right: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    select {
      width: 80% !important;
      height: 30px !important;
      line-height: 30px !important;
      font-size: 15px;
    }
  }
  @media (max-width: 1080px) {
    height: 7rem;
    line-height: 7rem;
    div {
      font-size: 2.6rem;
      overflow-x: scroll;
      overflow-y: hidden;
    }
    div:nth-of-type(6) {
      button {
        font-size: 1.7rem;
        width: 13rem;
        height: 4.5rem;
        line-height: 4.5rem;
      }
      select {
        font-size: 2.6rem;
        height: 5rem !important;
        line-height: 5rem !important;
      }
    }
  }
`;
