import React, { useState } from 'react';
import i_close from '../../../asset/images/w-cancel-icon.png';
import '../../../asset/css/pop.css';
import { contractPauseApi } from '../../../api/auth';

//sweet alert
import swal from 'sweetalert';

export default function ContractStopPop({
  setContractPop,
  state,
  contractNum,
  move2Success,
}) {
  const [cancelData, setCancelData] = useState({
    ct_no: contractNum,
    ct_cancel: '',
  });

  //계약 중지
  const contractPauseFnc = async () => {
    if (cancelData.ct_no === 0) {
      swal( "다시 시도해주세요." ,  "" ,  "warning" )
    } else if (cancelData.ct_cancel === '') {
      swal( "취소사유를 선택해주세요." ,  "" ,  "warning" )
    } else {
      const response = await contractPauseApi(
        cancelData.ct_no,
        cancelData.ct_cancel
        );
        if (response.status === 'success') {
        swal( "계약중지 요청이 완료되었습니다." ,  "" ,  "success" )
        move2Success();
        setContractPop(false);
      } else if (response.status === 'fail') {
        swal( "다시 시도해주세요." ,  "" ,  "warning" )
      }
    }
  };

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setContractPop(false)}></div>
      <div className='contract_pop'>
        <div className='close' onClick={() => setContractPop(false)}>
          <img src={i_close} alt='' />
        </div>
        <div className='contract_pop_tit'>
          <h3>계약 {state} 요청을 하시겠습니까?</h3>
          <p>공급사 동의 시 계약이 {state} 됩니다.</p>
        </div>
        <select
          onChange={(e) => {
            if (e === '기타') {
            } else {
              setCancelData({ ...cancelData, ct_cancel: e.target.value });
            }
          }}
        >
          <option value='' hidden>
            {state}사유 선택
          </option>
          <option value='회사와의 관계'>회사와의 관계</option>
          <option value='파기 후 재계약'>파기 후 재계약</option>
          <option value='변심'>변심</option>
          <option value='기타'>기타</option>
        </select>
        <div className='confirm_btn'>
          <p
            onClick={() => {
              contractPauseFnc();
            }}
          >
            확인
          </p>
        </div>
      </div>
    </div>
  );
}
