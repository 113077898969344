import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";

const EmailInput = ({ loginParams, setLoginParams, onKeyDown }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  const onChangeEmail = (e) => {
    setLoginParams({ ...loginParams, email: e.target.value });
  };

  return (
    <Input
      id="email"
      type="text"
      size="large"
      width={isDesktop ? 280 : "100%"}
      placeholder="이메일 주소를 입력해주세요."
      onChange={onChangeEmail}
      onKeyDown={onKeyDown}
    />
  );
};

export default EmailInput;
