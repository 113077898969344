import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";
import { useNavigate } from "react-router-dom";

const SiteInfoChangeRequestButton = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const navigate = useNavigate();
  const moveToCreateSitePage = () => {
    navigate("/my_page/my_info/create_site", { state: { isEdit: true } });
  };

  return (
    <Button
      type="secondary"
      size="small"
      style={{ width: isMobile && "40.8rem", minWidth: isMobile && "40.8rem" }}
      onClick={moveToCreateSitePage}
    >
      정보 변경 요청
    </Button>
  );
};

export default SiteInfoChangeRequestButton;
