import Button from "../../../../../../component/ui/button/Button";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";

const ReorderPBPop = ({ navigate, reorderPBPop, setReorderPBPop }) => {
  const onClickReorder = () => {
    navigate("/pb/choice");
  };
  const onClickCancel = () => {
    setReorderPBPop(false);
  };

  return (
    <Modal
      size="xsmall"
      type="info"
      open={reorderPBPop}
      title={
        <Typography component="p" type="h4" color="var(--sub-green-10)">
          PB상품 재주문 하기
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button type="primary" size="small" onClick={onClickReorder}>
          재주문
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        PB상품 재주문을 위하여 PB상품 주문 화면으로 이동합니다.
      </Typography>
    </Modal>
  );
};

export default ReorderPBPop;
