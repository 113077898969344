import React, { useState } from "react";
//library
import { useNavigate, useLocation } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/request.css";
//etc
import i_rq1 from "../../asset/images/w-repeat-icon.png";
import i_rq2 from "../../asset/images/w-check-icon.png";

import s from "../../asset/css/requestConfirm.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  bidCreateApi,
  createPbOrder,
  quickCreateApi,
  termBidCompleteApi,
  choiceCompanyApi,
  reBidApi,
} from "../../api/auth";
import { actions } from "../../redux/action";
import { excelDefault } from "../../redux/reducer/default";
import swal from "sweetalert";
import Loading from "../../component/etc/Loading";
import moment from "moment";
import DownloadSVG from "../../asset/images/svg/DownloadSVG";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";
import Button from "../../component/ui/button/Button";
import Typography from "../../component/ui/typography/Typography";
import Modal from "../../component/ui/modal/Modal";
import useScrollFixed from "../../hooks/useScrollFixed.js";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import PreventRefresh from "../main/customHooks/usePreventRefresh";

export default function RequestConfirm() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.data;
  const type = location.state.type;
  const selectCompany = location.state.selectCompany;
  const extraType = location.state?.extraType;
  const previousBidNo = location.state?.previousBidNo;
  const dispatch = useDispatch();
  //loading ui
  const [generating, setGenerating] = useState(false);
  const userData = useSelector((state) => state.user);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const openConfirmModal = () => setConfirmModalOpen(true);
  const closeConfirmModal = () => setConfirmModalOpen(false);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const { isFixed } = useScrollFixed(isDesktop ? 200 : 300);

  const handleResponse = (response) => {
    if (response.status === "success") {
      dispatch(actions.setExcel(excelDefault));
      dispatch(actions.setAttachExcel(false));
      handleNavigate(response);
    } else {
      swal("통신에 실패했습니다", "다시 실행해주세요.", "error");
    }
    setGenerating(false);
  };

  const handleNavigate = (response) => {
    navigate("/management/bid/request_complete", {
      state: {
        type: type,
        no: response?.data?.no,
        ...(data.extraType && { isRebid: true }),
      },
    });
  };

  const nextStepFnc = async () => {
    let response;
    setGenerating(true);

    try {
      if (type === "업체지정주문") {
        response = await quickCreateApi(data);
        handleResponse(response);
      } else if (type === "일회성입찰공고" || type === "단가계약입찰공고") {
        // 재입찰, 재공고입찰로 들어올 경우 - 전 견적 유찰시키는 api 구문 실행
        if (data?.extraType === "BID" || data?.extraType === "ESTIMATE") {
          response = await reBidApi(data);
          handleNavigate(response);
        } else {
          response = await bidCreateApi(data);
          handleResponse(response);
        }
      } else if (type === "단가계약기반주문") {
        response = await termBidCompleteApi(data);
        handleNavigate(response);
      } else if (type === "일회성입찰주문") {
        response = await choiceCompanyApi(data.submitData);
        handleNavigate(response);
      } else if (type === "PB상품주문") {
        response = await createPbOrder(data);
        if (response.status === "success") {
          handleNavigate(response);
        }
      } else {
        console.error("Unknown type:", type);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setGenerating(false);
    }
  };

  const contractDueFnc = (startDate, endDate) => {
    // 포맷된 날짜
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    // 두 날짜 사이의 개월 수 계산
    const due = moment(endDate).diff(moment(startDate), "months");

    // 결과 문자열 반환
    return `${start} - ${end} (${due}개월)`;
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Check if phoneNumber is defined and not null
    if (!phoneNumber) {
      return "";
    }

    // Remove all non-numeric characters
    phoneNumber = phoneNumber.replace(/\D/g, "");

    // Determine the format based on the length of the number
    if (phoneNumber.length === 10) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else if (phoneNumber.length === 11) {
      return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      // Return the original input if it doesn't match the expected lengths
      return phoneNumber;
    }
  };

  const sumFnc = () => {
    const result = data.productInfoList
      .map((data) => data.count * 210)
      .reduce((acc, current) => acc + current, 0);

    return result;
  };

  const prevStepFnc = () => {
    navigate(-1);
  };

  return (
    <div className="request_wrap">
      <PreventRefresh />
      <Mheader title="상세정보 확인" />
      <div className="request_input_container">
        <div className="input_top_box">
          <div className="title">상세정보 확인</div>

          {/* 

업체지정주문, 일회성입찰공고 단가계약입찰공고 재공고입찰  /  step1이 내역 작성
재입찰  /  step1이 없음
단가계약기반주문  /  step1이 계약서 선택
PB상품주문  /  step1이 PB상품 선택

업체지정주문, 일회성입찰공고 단가계약입찰공고  /  step4가 주문/입찰 완료
재입찰, 재공고입찰  /  step4가 재공고 완료
*/}

          <OrderBreadCrumb
            step1={
              type === "단가계약기반주문"
                ? "계약서 선택"
                : type === "PB상품주문"
                ? "PB상품선택"
                : data.extraType === "BID"
                ? null
                : "내역 작성"
            }
            step2="상세정보 입력"
            step3="상세정보 확인"
            step4={
              data?.previousBidNo
                ? "재공고 완료"
                : type === "PB상품주문" || type === "단가계약기반주문"
                ? "주문완료"
                : "주문/입찰 완료"
            }
            isActive={3}
          />
        </div>
        <div className="top_request_sub_header">
          <div className="top_request_sub_header_wrap">
            <p>작성하신 정보가 맞는지 다시한번 확인해주시기 바랍니다.</p>
          </div>
        </div>
        <div className={s.container}>
          <div className={s.section}>
            <div className={s.title_section}>
              <span className={s.title}>주문자</span>
            </div>
            {/* 주문자 */}
            <div className={s.contents_section}>
              <div className={s.contents_elem}>
                <span className={s.contents_title}>고객사명</span>
                <span className={s.contents_desc}>{userData.rep_con}</span>
              </div>
              <div className={s.contents_elem}>
                <span className={s.contents_title}>현장명</span>
                <span className={s.contents_desc}>{userData.rep_site}</span>
              </div>
              <div className={s.contents_elem}>
                <span className={s.contents_title}>주문자명</span>
                <span className={s.contents_desc}>{userData.u_name}</span>
              </div>
              <div className={s.contents_elem}>
                <span className={s.contents_title}>주문자 연락처</span>
                <span className={s.contents_desc}>
                  {formatPhoneNumber(userData.u_phone)}
                </span>
              </div>
            </div>
            {/* 주문 정보 */}
            <div className={s.title_section}>
              <span className={s.title}>주문정보</span>
            </div>
            <div className={s.contents_section}>
              <div className={s.contents_elem}>
                <span className={s.contents_title}>주문 / 입찰 방식</span>
                <span className={s.contents_desc}>{type}</span>
              </div>
              {type === "일회성입찰주문" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>계약 공급사</span>
                  <span className={s.contents_desc}>
                    {selectCompany.companyName}
                  </span>
                </div>
              )}
              {type === "단가계약기반주문" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>계약번호</span>
                  <span className={s.contents_desc}>{data.code}</span>
                </div>
              )}
              {type === "단가계약기반주문" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>계약 공급사</span>
                  <span className={s.contents_desc}>{selectCompany}</span>
                </div>
              )}
              {type === "단가계약기반주문" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>계약기간</span>
                  <span className={s.contents_desc}>
                    {contractDueFnc(data.sdate, data.edate)}
                  </span>
                </div>
              )}
              {type === "업체지정주문" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>지정 업체</span>
                  <span className={s.contents_desc}>{selectCompany || ""}</span>
                </div>
              )}
              {type === "단가계약입찰공고" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>계약 기간</span>
                  <span className={s.contents_desc}>{data.bid.term}개월</span>
                </div>
              )}
              {(type === "업체지정주문" ||
                type === "단가계약기반주문" ||
                type === "PB상품주문") && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>수령인</span>
                  <span className={s.contents_desc}>{data.recipient}</span>
                </div>
              )}
              {type === "일회성입찰주문" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>수령인</span>
                  <span className={s.contents_desc}>
                    {data.submitData.recipient}
                  </span>
                </div>
              )}
              {type === "일회성입찰주문" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>수령인 연락처</span>
                  <span className={s.contents_desc}>
                    {formatPhoneNumber(data.submitData.phone)}
                  </span>
                </div>
              )}
              {(type === "업체지정주문" ||
                type === "단가계약기반주문" ||
                type === "PB상품주문") && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>수령인 연락처</span>
                  <span className={s.contents_desc}>
                    {formatPhoneNumber(data.phone)}
                  </span>
                </div>
              )}
              {(type === "일회성입찰공고" || type === "단가계약입찰공고") && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>투찰 기한</span>
                  <span className={s.contents_desc}>{data.bid.endate}</span>
                </div>
              )}
              {(type === "일회성입찰공고" ||
                type === "업체지정주문" ||
                type === "단가계약기반주문") && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>납품 기한</span>
                  <span className={s.contents_desc}>
                    {type === "일회성입찰공고" && data.bid.deldate}
                    {type === "업체지정주문" && data.edate}
                    {type === "단가계약기반주문" && data.deliveryDate}
                    까지
                  </span>
                </div>
              )}{" "}
              {type === "일회성입찰주문" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>납품 기한</span>
                  <span className={s.contents_desc}>
                    {data.submitData.deldate}
                  </span>
                </div>
              )}
              <div className={s.contents_elem}>
                <span className={s.contents_title}>납품 주소</span>
                {/* 주소 addr - 여기 타입에 따라 다르게 */}
                <span className={s.contents_desc}>
                  {(type === "일회성입찰공고" ||
                    type === "단가계약입찰공고") && (
                    <>
                      {data.bid.addr1}, {data.bid.addr2}{" "}
                    </>
                  )}
                  {type === "업체지정주문" && (
                    <>
                      {data.addr}, {data.detail}{" "}
                    </>
                  )}
                  {type === "단가계약기반주문" && (
                    <>
                      {data.address}, {data.detail}{" "}
                    </>
                  )}
                  {type === "PB상품주문" && (
                    <>
                      {data.address}, {data.addressDetail}{" "}
                    </>
                  )}
                  {type === "일회성입찰주문" && (
                    <>
                      {data.submitData.address}, {data.submitData.addressDetail}{" "}
                    </>
                  )}
                </span>
              </div>
              {type === "단가계약입찰공고" && (
                <div className={s.contents_elem}>
                  <span className={s.contents_title}>선정방식</span>
                  <span className={s.contents_desc}>최저단가</span>
                </div>
              )}
              <div className={s.contents_elem}>
                <span className={s.contents_title}>배송비</span>
                <span className={s.contents_desc}>배송비 포함</span>
              </div>
              <div className={s.contents_elem}>
                <span className={s.contents_title}>하차 역무</span>
                <span className={s.contents_desc}>하차 역무 있음</span>
              </div>
              <div className={s.contents_elem}>
                <span className={s.contents_title}>비용 정산</span>
                <span className={s.contents_desc}>공새로 위임 정산</span>
              </div>
            </div>
            {/* 품목 내역 */}
            <div className={s.title_section}>
              <span className={s.title}>품목 내역</span>
            </div>
            <div className={s.contents_section}>
              {(type === "일회성입찰공고" ||
                type === "단가계약입찰공고" ||
                type === "업체지정주문") && (
                <table className={s.table}>
                  <tbody>
                    <tr>
                      <th className={s.table_number}></th>
                      <th>
                        <p className="name">품목명</p>
                      </th>
                      <th>
                        <p className="standard">규격</p>
                      </th>
                      <th>
                        <p style={{ lineHeight: "1.3" }}>
                          제조국
                          <br />
                          (브랜드)
                        </p>
                      </th>
                      <th>
                        <p className="unit">단위</p>
                      </th>
                      <th>
                        <p className="cnt">수량</p>
                      </th>
                      <th>
                        <p className="etc">비고</p>
                      </th>
                    </tr>
                    {data.bidProductList?.map((data, i) => (
                      <tr key={data.no}>
                        <th className={s.table_number}>{i + 1}</th>
                        <td>{data.name}</td>
                        <td>
                          <pre className="p_list_standard">
                            {data.standard?.replace(/, /gi, "\n")}
                          </pre>
                        </td>
                        <td>{data.brand}</td>
                        <td>{data.unit}</td>
                        <td>{data?.count.toLocaleString("ko")}</td>
                        <td>{data.etc}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {type === "단가계약기반주문" && (
                <table className={s.subject_table}>
                  <tbody>
                    <tr>
                      <th className={s.table_number}></th>
                      <th>
                        <p className="name">품목명</p>
                      </th>
                      <th>
                        <p className="standard">규격</p>
                      </th>
                      <th>
                        <p style={{ lineHeight: "1.3" }}>
                          제조국
                          <br />
                          (브랜드)
                        </p>
                      </th>
                      <th>
                        <p className="unit">단위</p>
                      </th>
                      <th>
                        <p className="cnt">수량</p>
                      </th>
                      <th>계약 단가(원)</th>
                      <th>금액 (원)</th>
                      <th>
                        <p className="etc">비고</p>
                      </th>
                    </tr>
                    {data.productInfoList?.map((data, i) => (
                      <tr key={data.no}>
                        <th className={s.table_number}>{i + 1}</th>
                        <td>{data.bidProduct.name}</td>
                        <td>
                          <pre className="p_list_standard">
                            {data.bidProduct.standard?.replace(/, /gi, "\n")}
                          </pre>
                        </td>
                        <td>{data.bidProduct.brand}</td>
                        <td>{data.bidProduct.unit}</td>
                        <td>
                          {Number(data.bidProduct.count).toLocaleString("ko")}
                        </td>
                        <td>{Number(data.price).toLocaleString("ko")}원</td>
                        <td>
                          {(data.price * data.bidProduct.count).toLocaleString(
                            "ko"
                          )}
                          원
                        </td>
                        <td>{data.etc}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="7">합계</td>
                      <td>
                        <strong>
                          {data.productInfoList
                            .map((data) => data.bidProduct.count * data.price)
                            .reduce((sum, current) => sum + current, 0)
                            .toLocaleString("ko")}
                          원
                        </strong>
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              {type === "일회성입찰주문" && (
                <table className={s.subject_table}>
                  <tbody>
                    <tr>
                      <th className={s.table_number}></th>
                      <th>
                        <p className="name">품목명</p>
                      </th>
                      <th>
                        <p className="standard">규격</p>
                      </th>
                      <th>
                        <p style={{ lineHeight: "1.3" }}>
                          제조국
                          <br />
                          (브랜드)
                        </p>
                      </th>
                      <th>
                        <p>단위</p>
                      </th>
                      <th>
                        <p>수량</p>
                      </th>
                      <th>
                        <p>단가</p>
                      </th>
                      <th>
                        <p>금액</p>
                      </th>
                    </tr>
                    {data.bidProductList.map((data, i) => (
                      <tr key={data.no}>
                        <td>{i + 1}</td>
                        <td>{data.name}</td>
                        <td>
                          <pre className="p_list_standard">
                            {data.standard.replace(/, /gi, "\n")}
                          </pre>
                        </td>
                        <td>{data.brand}</td>
                        <td>{data.unit}</td>
                        <td>{data.count}</td>
                        <td>
                          {selectCompany?.companyPriceInfoList[
                            i
                          ]?.price.toLocaleString("ko")}
                          원
                        </td>
                        <td>
                          {
                            // comList[item]?.odList[i]?.od_price * data.bp_count
                            (
                              selectCompany?.companyPriceInfoList[i]?.price *
                              data.count
                            ).toLocaleString("ko")
                          }
                          원
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="7">합계(VAT 미포함)</td>
                      <td colSpan="1">
                        <strong>
                          {/* {numberWithCommas(item.spList?.map((data) => data.ol_count * data.ol_price).reduce((sum, current) => sum + current, 0))} */}
                          {(selectCompany?.totalPrice).toLocaleString("ko")}원
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              {type === "PB상품주문" && (
                <table className={s.pb_table}>
                  <tr>
                    <th></th>
                    <th>
                      <p className="standard">품목명</p>
                    </th>
                    <th>단위</th>
                    <th>수량</th>
                    <th>단가 (원)</th>
                    <th>금액 (원)</th>
                  </tr>
                  {data.productInfoList?.map((data, i) => (
                    <tr key={data.no}>
                      <th className={s.table_number}>{i + 1}</th>
                      <td>{data.pbName}</td>
                      <td>-</td>
                      <td>{data.count.toLocaleString("ko")}개</td>
                      <td>{210}원</td>
                      <td>{(data.count * 210).toLocaleString("ko")}원</td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      rowSpan="4"
                      colSpan="2"
                      style={{ background: "#f7f7f7" }}
                    ></td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ background: "#f7f7f7" }}>
                      공급가액
                    </td>
                    <td colSpan="1">
                      <strong>{sumFnc().toLocaleString("ko")}원</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ background: "#f7f7f7" }}>
                      부가세
                    </td>
                    <td colSpan="1">
                      <strong>{(sumFnc() * 0.1).toLocaleString("ko")}원</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ background: "#f7f7f7" }}>
                      <strong>총 결제 금액</strong>
                    </td>
                    <td colSpan="1">
                      <strong>
                        {(sumFnc() + sumFnc() * 0.1).toLocaleString("ko")}원
                      </strong>
                    </td>
                  </tr>
                </table>
              )}
            </div>
            {/* 추가 정보 */}
            {
              <>
                <div className={s.title_section}>
                  <span className={s.title}>추가 정보</span>
                </div>
                <div className={s.file_section}>
                  {type === "일회성입찰주문" && (
                    <div className={s.contents_elem}>
                      <span className={s.contents_title}>
                        {selectCompany.companyName} 비고
                      </span>
                      <span className={s.contents_desc}>
                        {selectCompany.offerComment}
                      </span>
                    </div>
                  )}
                  <div className={s.contents_elem}>
                    <span className={s.contents_title}>비고</span>

                    {(type === "일회성입찰공고" ||
                      type === "단가계약입찰공고") && (
                      <span className={s.contents_desc}>
                        {data.bid.comment || "-"}
                      </span>
                    )}
                    {(type === "업체지정주문" ||
                      type === "단가계약기반주문") && (
                      <span className={s.contents_desc}>
                        {data.uniqueness || "-"}
                      </span>
                    )}
                    {type === "PB상품주문" && (
                      <span className={s.contents_desc}>-</span>
                    )}
                    {type === "일회성입찰주문" && (
                      <span className={s.contents_desc}>
                        {data.submitData.uniqueness || "-"}
                      </span>
                    )}
                  </div>
                  {(type === "일회성입찰공고" ||
                    type === "단가계약입찰공고" ||
                    type === "업체지정주문" ||
                    type === "일회성입찰주문") &&
                    data.bidImgList.length !== 0 && (
                      <div className={s.contents_elem}>
                        <span className={s.contents_title}>첨부파일</span>
                        <span className={s.contents_desc}>
                          <div className={s.contents_file}>
                            {data.bidImgList?.map((elem, idx) => {
                              return (
                                <div
                                  className="img_added_elem"
                                  onClick={() => {
                                    window.open(
                                      elem.filePath,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                >
                                  <div className="img_added_inner">
                                    <span
                                      className="img_added_name"
                                      title={elem?.fileName}
                                    >
                                      {elem?.fileName}
                                    </span>
                                    <span className="img_added_size">
                                      {elem?.fileSize}
                                    </span>
                                  </div>

                                  <div className="img_added_close_box">
                                    <DownloadSVG
                                      size="medium"
                                      stroke="var(--gray-90)"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </span>
                      </div>
                    )}
                </div>
              </>
            }
            <section
              className={classNames("bid_bottom_button_section", {
                desktop_fixed: isDesktop && isFixed,
                mobile_fixed: isMobile && isFixed,
              })}
            >
              <div className="space_between_btn_group">
                <Button size="medium" type="secondary" onClick={prevStepFnc}>
                  <Typography component="p" type="c1">
                    이전으로
                  </Typography>
                </Button>
                <Button size="medium" type="primary" onClick={openConfirmModal}>
                  <Typography component="p" type="c1" color="var(--white)">
                    {type === "일회성입찰공고" || type === "단가계약입찰공고"
                      ? "입찰 공고 게시"
                      : "주문하기"}
                  </Typography>
                </Button>
              </div>
            </section>
          </div>
        </div>
      </div>
      {generating && <Loading msg={"로딩 중입니다."} />}
      <Modal
        size="xsmall"
        type="success"
        open={confirmModalOpen}
        title={
          <Typography component="p" type="h2" color="var(--sub-blue-05)">
            {type === "일회성입찰공고" || type === "단가계약입찰공고"
              ? "공고 내용 확인"
              : "최종 주문 확인"}
          </Typography>
        }
        closeIcon={false}
        onOk={openConfirmModal}
        onCancel={closeConfirmModal}
        footer={[
          <Button type="danger" onClick={closeConfirmModal}>
            취소
          </Button>,
          <Button type="primary" onClick={nextStepFnc}>
            확인
          </Button>,
        ]}
      >
        <Typography component="p" type="c1">
          {type === "일회성입찰공고" || type === "단가계약입찰공고"
            ? "확인을 누르면 공고가 게시됩니다."
            : "확인을 누르면 주문이 진행됩니다."}
        </Typography>
      </Modal>
    </div>
  );
}
