import { Controller } from "react-hook-form";
import Input from "../../../../../../../../../component/ui/input/Input";
import Typography from "../../../../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../../../../constant/Palette";

const RankInput = ({ control, errors }) => {
  return (
    <Controller
      name="input_rank"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width="100%"
          id="rank"
          placeholder="직급을 입력해주세요."
          helpText={
            errors.input_rank && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.input_rank.message}
              </Typography>
            )
          }
          status={errors.input_rank && "error"}
        />
      )}
    />
  );
};

export default RankInput;
