// default 값 setting
export const userDefault = {
  // 로그인 시 저장
  u_no: false,
  u_name: false,
  u_phone: false,
  u_rank: false,
  u_type: false, // 건설사 or 공급사
  identityVerification: false,
  lastOrganizationNo: false,
  organizationState: false,

  // 로그인 후 현장선택시 추가 저장
  constructionType: false, // 건설사 or 협력사
  site_num: false, // 현장번호
  rep_site: false, // 현장이름
  con_num: false, // 건설사번호
  rep_con: false, // 건설사이름
  isDemo: false,

  // 위 두 가지 정상저장 됐을 때 상태
  isAuthenticated: false,
};

export const pushDefault = {
  type: false,
  value: false,
};

export const excelDefault = [];

export const attachExcelDefault = {
  comment: false,
  file: [],
};

export const activityDefault = {
  activity_list: [],
};
