import { Modal as AntdModal } from "antd";
import styles from "./modal.module.css";
import classNames from "classnames/bind";
import SuccessSVG from "../../../asset/images/svg/SuccessSVG";
import InfoSVG from "../../../asset/images/svg/InfoSVG";
import WarningSVG from "../../../asset/images/svg/WarningSVG";
import ErrorSVG from "../../../asset/images/svg/ErrorSVG";

const cx = classNames.bind(styles);

const TITLE_ICON_LIST = {
  success: <SuccessSVG size="xlarge" />,
  info: <InfoSVG size="xlarge" />,
  warning: <WarningSVG size="xlarge" />,
  error: <ErrorSVG size="xlarge" />,
};

export default function Modal({
  size = "xsmall",
  type,
  title,
  children,
  footer,
  open,
  onOk,
  onCancel,
  ...props
}) {
  const className = cx({
    [size]: true,
    [type]: true,
  });
  const titleIcon = TITLE_ICON_LIST[type] || null;

  return (
    <AntdModal
      className={className}
      title={
        <>
          {titleIcon}
          {title}
        </>
      }
      centered
      open={open}
      footer={footer}
      onOk={onOk}
      onCancel={onCancel}
      {...props}
    >
      {children}
    </AntdModal>
  );
}
