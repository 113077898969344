import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";

const SiteSwitchButton = ({ setSiteSwitchModal }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const onClickSiteSwitchButton = () => {
    setSiteSwitchModal(true);
  };

  return (
    <Button
      type="primary"
      size="medium"
      style={{ width: isMobile && "100%", minWidth: isMobile && "100%" }}
      onClick={onClickSiteSwitchButton}
    >
      현장 전환
    </Button>
  );
};

export default SiteSwitchButton;
