import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";

const CodeCheckButton = ({ errors, code, handleCheckCode, codeBtnDisable }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Button
      type="primary"
      size="small"
      style={{ marginTop: isDesktop ? "22px" : "6.6rem" }}
      disabled={!code || errors.code || codeBtnDisable}
      onClick={handleCheckCode}
    >
      인증 확인
    </Button>
  );
};

export default CodeCheckButton;
