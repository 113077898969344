/*global kakao*/
//library
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
// css
import s from "../../../asset/css/orderCompanySelectPop.module.css";
// api
import { quickOrderComDetailApi, quickOrderSearchApi } from "../../../api/auth";

//library
import { useMediaQuery } from "react-responsive";
import { Map, MapMarker, Circle, CustomOverlayMap } from "react-kakao-maps-sdk";

//component
import Mheader from "../../../component/common/Mheader";
import CompanyPop from "../../../component/common/Pop/CompanyPop";
//css
import "../../../asset/css/quickSearch.css";

//img
import nowLocation from "../../../asset/images/marker-now-location.png";

import store from "../../../asset/images/icon_map_store.png";
import pin from "../../../asset/images/icon_map_pin.png";
import phone from "../../../asset/images/icon_map_phone.png";

//sweet alert
import swal from "sweetalert";
import ReactTooltip from "react-tooltip";

const THIRTY = "30";
const FIFTY = "50";
const HUNDRED = "100";
const ALL = "500";
const DISTANCE = "distance";
const ORDERS = "orders";

export default function OrderCompanySelectPop({
  setOrderCompanyPop,
  setSelectCompany,
  setChoiceOrderMethod,
}) {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const [detailPop, setDetailPop] = useState(false);
  const [firstData, setFirstData] = useState({
    siteNo: userData.site_num,
    nowPage: 1,
    c_name: "",
  });
  const [siteLocation, setSiteLocation] = useState({ lat: 0, lng: 0 });
  const [clickMarker, setClickMarker] = useState(0);
  const [nowPage, setNowPage] = useState(1);
  const [cList, setcList] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [isSelected, setIsSelected] = useState({});
  const [activeSwitch, setActiveSwitch] = useState(THIRTY);
  const [checked, setChecked] = useState(true);

  const scrollRef = useRef(null);

  const onClickSwitch = (switchName) => {
    setActiveSwitch(switchName);
    changeRadiusFnc(switchName);
  };

  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  //퀵주문 공급사 검색하기
  const quickSearchFnc = async () => {
    setIsSelected({});
    try {
      const response = await quickOrderSearchApi({
        siteNo: firstData.siteNo,
        keyword: firstData.c_name,
        km: km,
        sort: sort,
      });

      setcList(response.data.matchingDistanceList);
      setSiteLocation({
        lat: response.data.siteLatitude,
        lng: response.data.siteLongitude,
      });
    } catch (error) {
      swal("다시 시도해주세요.", "", "warning");
    }
  };

  const onKeyPressQuickSearch = (e) => {
    if (e.key === "Enter") quickSearchFnc();
  };

  useEffect(() => {
    quickSearchFnc();
  }, [km, sort, activeSwitch]);

  const handleCheckBoxChange = (event) => {
    const isCheck = event.target.checked;
    setSort(isCheck ? ORDERS : DISTANCE);
    setChecked(!checked);
  };

  useEffect(() => {
    quickSearchFnc();
  }, [sort, checked]);

  //퀵주문 공급사 상세보기
  const quickOrderDetailFnc = async (c_no) => {
    const response = await quickOrderComDetailApi(c_no);

    if (response.status === "success") {
      setDetailData(response.data.cInfo);
    } else if (response.status === "fail") {
      swal("다시 시도해주세요.", "", "warning");
    }
  };

  const doublePopFnc = () => {
    if (detailPop) {
      setOrderCompanyPop(true);
      setDetailPop(false);
    } else {
      setOrderCompanyPop(false);
    }
  };

  const mapStyle = {
    width: "100%",
    height: "350px",
  };
  const [map, setMap] = useState();
  const [km, setKm] = useState("30");
  const [sort, setSort] = useState(ORDERS);

  // const handleCheckBoxChange = (event) => {
  //   const isCheck = event.target.checked;
  //   setSort(isCheck ? "orders" : "distance");
  //   quickSearchFnc();
  // };

  const zoomIn = () => {
    if (!map) return;
    map.setLevel(map.getLevel() - 1);
  };
  const zoomOut = () => {
    if (!map) return;
    map.setLevel(map.getLevel() + 1);
  };
  const setMapType = (maptype) => {
    if (!map) return;
    var roadmapControl = document.getElementById("btnRoadmap");
    var skyviewControl = document.getElementById("btnSkyview");
    if (maptype === "roadmap") {
      map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
      roadmapControl.className = "selected_btn";
      skyviewControl.className = "btn";
    } else {
      map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
      skyviewControl.className = "selected_btn";
      roadmapControl.className = "btn";
    }
  };

  const changeRadiusFnc = async (selectKm) => {
    setKm(selectKm);

    switch (selectKm) {
      case "30":
        map.setLevel("10");
        break;
      case "50":
        map.setLevel("11");
        break;
      case "100":
        map.setLevel("12");
        break;
      case "500":
        map.setLevel("16");
        break;
      default:
        break;
    }
  };

  return (
    <div className={s.background} onClick={doublePopFnc}>
      <div className={s.container} onClick={(e) => e.stopPropagation()}>
        <div ref={scrollRef} />
        <div className={s.header}>
          <div className={s.title}>공급사 선택</div>
          <div className={s.close} onClick={doublePopFnc}>
            <img src={i_close} alt="close_icon" />
          </div>
        </div>

        <div className={s.contents}>
          <div className={s.input_box}>
            {/* <div className={s.input_title}>품목명</div> */}
            <input
              type="text"
              placeholder="공급사명을 입력하세요."
              onChange={(e) => {
                setFirstData({
                  ...firstData,
                  c_name: e.target.value,
                  nowPage: 1,
                });
                // setNowPage(1);
              }}
              className={s.input}
              onKeyPress={onKeyPressQuickSearch}
            />
            <div
              className={s.input_search}
              onClick={() => {
                quickSearchFnc();
              }}
            >
              검색
            </div>
          </div>

          <div className={s.switch_box}>
            <div
              className={activeSwitch === THIRTY ? s.switch_active : s.switch}
              onClick={() => onClickSwitch(THIRTY)}
            >
              30km
            </div>
            <div
              className={activeSwitch === FIFTY ? s.switch_active : s.switch}
              onClick={() => onClickSwitch(FIFTY)}
            >
              50km
            </div>
            <div
              className={activeSwitch === HUNDRED ? s.switch_active : s.switch}
              onClick={() => onClickSwitch(HUNDRED)}
            >
              100km
            </div>
            <div
              className={activeSwitch === ALL ? s.switch_active : s.switch}
              onClick={() => onClickSwitch(ALL)}
            >
              전체
            </div>
          </div>

          <div className={s.choice_container}>
            <div className={s.map_box}>
              <Map
                id={"map"}
                center={{ lat: siteLocation?.lat, lng: siteLocation?.lng }}
                className={s.map}
                level={9}
                onCreate={setMap}
                zoomable={!isResponsive ? true : false} // 모바일에선 에러때문에 손가락줌 안 되게 변경
              >
                <Circle
                  center={{
                    lat: siteLocation?.lat,
                    lng: siteLocation?.lng,
                  }}
                  radius={Number.parseInt(km) * 1000}
                  strokeWeight={1}
                  strokeColor={"#95b9ed"}
                  strokeOpacity={2}
                  strokeStyle={"solid"}
                  fillColor={"#c4e1f9"}
                  fillOpacity={0.4}
                />

                <MapMarker
                  position={{
                    lat: siteLocation.lat,
                    lng: siteLocation.lng,
                  }}
                  center={{
                    // 지도의 중심좌표 추가
                    lat: siteLocation.lat,
                    lng: siteLocation.lng,
                  }}
                  image={{
                    // src: 'https://gongsaero.s3.ap-northeast-2.amazonaws.com/system/marker_gongsaero.png', // 마커이미지의 주소입니다
                    src: nowLocation, // 마커이미지의 주소입니다
                    size: {
                      width: 26,
                      height: 42,
                    },
                  }}
                />
                {cList.map((data, i) => {
                  //
                  return (
                    <>
                      <MapMarker
                        key={i}
                        position={{
                          lat: data.company.lat,
                          lng: data.company.lng,
                        }}
                        onMouseOver={() => setClickMarker(data.company.no)}
                        onMouseOut={() => setClickMarker(0)}
                      />
                      {clickMarker === data.company.no && (
                        <CustomOverlayMap
                          position={{
                            lat: data.company.lat,
                            lng: data.company.lng,
                          }}
                          yAnchor={-0.1}
                        >
                          <div className={s.hover_popover}>
                            {data.company.name}
                          </div>
                        </CustomOverlayMap>
                      )}
                    </>
                  );
                })}
              </Map>
              <div className="custom_zoomcontrol radius_border">
                <span onClick={zoomIn}>
                  <img
                    src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png"
                    alt="확대"
                  />
                </span>
                <span onClick={zoomOut}>
                  <img
                    src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png"
                    alt="축소"
                  />
                </span>
              </div>
              <div className="custom_typecontrol radius_border">
                <span
                  id="btnRoadmap"
                  className="selected_btn"
                  onClick={() => setMapType("roadmap")}
                >
                  지도
                </span>
                <span
                  id="btnSkyview"
                  className="btn"
                  onClick={() => {
                    setMapType("skyview");
                  }}
                >
                  스카이뷰
                </span>
              </div>
            </div>
            <ul className={s.list_box}>
              <div className={s.sort_box}>
                <div className={s.sort_radio}>
                  <input
                    type="checkbox"
                    id="order"
                    name="sort"
                    checked={checked}
                    onChange={handleCheckBoxChange}
                  />
                  <label htmlFor="order" className={s.sort_desc}>
                    주문 순으로 정렬
                  </label>
                </div>
                <span className={s.sort_desc}>
                  반경
                  <span className={s.sort_alert}>{km}</span>
                  km 내 검색된 공급사는
                  <span className={s.sort_alert}>{cList.length}</span>개 입니다.
                </span>
              </div>
              {cList.length > 0 ? (
                cList?.map((data, i) => {
                  return (
                    <li
                      key={i}
                      onClick={() => {
                        setIsSelected({
                          idx: i,
                          no: data.company.no,
                          name: data.company.name,
                        });
                      }}
                    >
                      <input
                        type="radio"
                        id={`p${i}`}
                        name="company"
                        checked={isSelected.idx === i}
                        // className={isSelected.idx === i ? "hello" : "bye"}
                      />
                      <label
                        htmlFor={`p${i}`}
                        className={isSelected.idx === i && s.active_elem}
                      >
                        <div className={s.desc_container}>
                          <div className={s.img_box}>
                            <img src={data.companyLogo} className={s.img} />
                          </div>
                          <div className={s.desc_box}>
                            <div className={s.title_inner}>
                              <span className={s.desc_title}>
                                {data.company.name}
                              </span>
                              <span className={s.desc_sub_title}>
                                총 평점: {data.totalGrade}/5 &nbsp;최근 3개월
                                주문 횟수 : {data.ordersCount}{" "}
                              </span>
                            </div>
                            <div className={s.desc_inner}>
                              <div className={s.desc_elem}>
                                <img src={store} className={s.desc_icon} />
                                <span>{data.company.type}</span>
                              </div>
                              <div className={s.desc_elem}>
                                <img src={pin} className={s.desc_icon} />
                                <span style={{ textWrap: "pretty" }}>
                                  {data.company.addr1 + ","}&nbsp;
                                  {data.company.addr2}
                                </span>
                              </div>
                              <div className={s.desc_elem}>
                                <img src={phone} className={s.desc_icon} />
                                <span>{data.company.phone}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={s.btn_box}>
                          <div
                            className={s.btn}
                            onClick={() => {
                              setDetailPop(true);
                              quickOrderDetailFnc(data.company.no);
                            }}
                          >
                            상세보기
                          </div>
                        </div>
                      </label>
                    </li>
                  );
                })
              ) : (
                <span className={s.no_result}>
                  "{firstData.c_name}"에 대한 검색결과가 없습니다.
                </span>
              )}
            </ul>
          </div>
          <div class={s.next_btn_box}>
            <div class={s.next_btn}>
              <p
                onClick={() => {
                  if (Object.keys(isSelected).length !== 0) {
                    setOrderCompanyPop(false);
                    setSelectCompany(isSelected);
                    setChoiceOrderMethod("업체지정주문");
                  } else {
                    swal("공급사를 선택해주세요.", "", "warning");
                  }
                }}
              >
                다음
              </p>
            </div>
          </div>
        </div>
      </div>
      {detailPop && (
        <CompanyPop setDetailPop={setDetailPop} comDetail={detailData} />
      )}
    </div>
  );
}
