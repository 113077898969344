import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Input from "../../../../../../../../../component/ui/input/Input";
import Typography from "../../../../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../../../../constant/Palette";
import EyeOpenedSVG from "../../../../../../../../../asset/images/svg/EyeOpenedSVG";
import EyeClosedSVG from "../../../../../../../../../asset/images/svg/EyeClosedSVG";

const OldPwdInput = ({ control, errors }) => {
  const [pwdInVisible, setPwdInVisible] = useState(true);

  const onClickSuffix = () => {
    setPwdInVisible((prev) => !prev);
  };

  return (
    <Controller
      name="old_password"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width="100%"
          id="old_password"
          type={pwdInVisible ? "password" : "text"}
          placeholder="기존 비밀번호를 입력해주세요."
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                기존 비밀번호{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={
            errors?.old_password && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.old_password.message}
              </Typography>
            )
          }
          status={errors.old_password && "error"}
          suffix={pwdInVisible ? <EyeOpenedSVG /> : <EyeClosedSVG />}
          onClickSuffix={onClickSuffix}
        />
      )}
    />
  );
};

export default OldPwdInput;
