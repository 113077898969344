import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { updateRankSchema } from "../../pages/myPage/my-info/components/modal/rank-update/schema/schema";
import useUpdateRank from "../useMutation/useUpdateRank";
import { useState } from "react";

const useUpdateRankModalHandler = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(updateRankSchema),
    mode: "all",
    defaultValues: {
      select_rank: "",
      input_rank: "",
    },
  });

  const [rankUpdateSuccessModal, setRankUpdateSuccessModal] = useState(false);

  const onUpdateRankSuccessCb = ({ status }) => {
    if (status === "success") {
      setRankUpdateSuccessModal(true);
      return;
    }
  };

  const onUpdateRankErrorCb = ({ error }) => {
    console.error("rank update error", error);
  };

  const { mutate: updateRank } = useUpdateRank(
    onUpdateRankSuccessCb,
    onUpdateRankErrorCb
  );

  const handleUpdateRank = (data) => {
    updateRank({
      rank:
        data.select_rank === "직접 입력" ? data.input_rank : data.select_rank,
    });
  };

  return {
    handleSubmit,
    control,
    errors,
    rankUpdateSuccessModal,
    setRankUpdateSuccessModal,
    handleUpdateRank,
  };
};

export default useUpdateRankModalHandler;
