import React, { useState, useEffect } from "react";

//library
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import { useNavigate, useLocation } from "react-router";
import ko from "date-fns/locale/ko";

//component
import Mheader from "../../component/common/Mheader";

//css
import "../../asset/css/request.css";

import question_img from "../../asset/images/question-icon.png";

//etc
import {
  estimateRequestApi,
  estimateSelectApi,
  estimateShort2Api,
  getProNameApi,
} from "../../api/auth";

//redux
import { useSelector } from "react-redux";

import PickDate from "../../component/common/PickDate";

//sweet alert
import swal from "sweetalert";

registerLocale("ko", ko);

export default function RequestShort2() {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();

  const [selMenu, setSelMenu] = useState([]);
  const [alertMsg, setAlertMsg] = useState({ endate: false, deldate: false });
  const [isBlocking, setIsBlocking] = useState(false);
  const [estimateInfo, setEstimateInfo] = useState({
    bid_pro_no: "", //진행 공정
    bid_budget: 0, //견적 예산
    bid_endate: "", //투찰 기한
    bid_deldate: "", //납품 일시
    bid_fee: "Y", //배송비 포함 여부
    bid_drop: "L", //하자 역무 여부
    bid_priority: "최저 단가", //우선 사항
    bid_refund: "공새로 위임 정산", //정산 방법
    bid_addr: "", //현장 기본 주소
    bid_addr_detail: "", //현장 상세 주소
    bid_s_no: userData.site_num, // 대표현장 번호
    bid_past_no: "", //견적요청 다시하기 구분
  });

  const [isSelect, setIsSelect] = useState();
  const [nowPro, setNowPro] = useState();

  const onPushEstimateInfo = async () => {
    const response = await estimateShort2Api(
      estimateInfo.bid_pro_no,
      estimateInfo.bid_budget,
      estimateInfo.bid_endate,
      estimateInfo.bid_deldate,
      estimateInfo.bid_fee,
      estimateInfo.bid_drop,
      estimateInfo.bid_priority,
      estimateInfo.bid_refund,
      estimateInfo.bid_addr,
      estimateInfo.bid_addr_detail,
      estimateInfo.bid_s_no,
      estimateInfo.bid_past_no
    );
    if (response.status === "success") {
      return response.data.bid_no;
    } else {
      return -1;
    }
  };

  const selectMenuFnc = async () => {
    const response = await estimateSelectApi();
    if (response.status === "success") {
      setSelMenu(response.data.pList);
    }
  };

  const siteNumSendFnc = async () => {
    const response = await estimateRequestApi(userData.site_num);
    if (response.status === "success") {
      setEstimateInfo({ ...estimateInfo, bid_addr: response.data });
    }
  };

  // 날짜
  const [biddingDate, setbiddingDate] = useState(""); //투찰기한
  const [biddingTime, setbiddingTime] = useState(""); //투찰시간
  const [delTime, setdelTime] = useState(""); //납품시간
  const [delDate, setDelDate] = useState(""); //납품 일시

  const [refundInfoPopup, setRefundInfoPopup] = useState(false);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onNextValidation = async () => {
    if (estimateInfo.bid_budget === 0 || estimateInfo.es_budget === null) {
      swal("견적 예산을 입력해주세요.", "", "warning");
    } else if (estimateInfo.bid_endate === "") {
      swal("투찰기한을 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_endate === "not_available") {
      swal("투찰기한을 확인해주세요.", "", "warning");
    } else if (estimateInfo.bid_deldate === "") {
      swal("납품일시를 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_deldate === "not_available") {
      swal("납품일시를 확인해주세요.", "", "warning");
    } else if (estimateInfo.bid_fee === "") {
      swal("배송비 포함 여부를 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_drop === "") {
      swal("하차역무 포함 여부를 선택해주세요", "", "warning");
    } else if (estimateInfo.bid_priority === "") {
      swal("우선사항을 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_refund === "") {
      swal("비용 정산 방법을 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_term === "") {
      swal("결제주기를 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_payday === "") {
      swal("계약기간을 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_addr_detail === "") {
      swal("상세주소를 입력해주세요.", "", "warning");
    } else {
      const enDate = moment(estimateInfo.bid_endate);
      const delDate = moment(estimateInfo.bid_deldate);
      if (enDate.diff(delDate) >= 0) {
        swal("납품일시가 투찰마감보다 빠를 수 없습니다.", "", "error");
        return;
      }

      const bidNo = await onPushEstimateInfo();
      navigate("/management/estimate/request_method", {
        state: {
          bid_no: bidNo,
          bid_endate: estimateInfo.bid_endate,
        },
      });
    }
  };
  const numberWithCommasThousand = (x) => {
    if (x != "") {
      let tenThousand = x.toString() + "000";
      return tenThousand.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const minValueTime = (chooseDate, chooseTime, sort) => {
    //투찰기한과 납품일시 최소시간 정해주는 함수
    if (sort === "enDate") {
      //endate의 경우 10분 후부터 설정가능
      if (
        formatDate(chooseDate) === formatDate(new Date()) &&
        getTime(10) >= chooseTime
      ) {
        setEstimateInfo({ ...estimateInfo, bid_endate: "not_available" });
        setAlertMsg({ ...alertMsg, endate: true });
      } else setAlertMsg({ ...alertMsg, endate: false });
    } else if (sort === "delDate") {
      //deldate의 경우 60분 후부터 설정가능
      if (
        formatDate(chooseDate) === formatDate(new Date()) &&
        getTime(60) >= chooseTime
      ) {
        setEstimateInfo({ ...estimateInfo, bid_deldate: "not_available" });
        setAlertMsg({ ...alertMsg, deldate: true });
      } else setAlertMsg({ ...alertMsg, deldate: false });
    }
  };
  const getTime = (num) => {
    const offsetTime = (new Date().getTimezoneOffset() - num) * 60000;
    const wantTime = new Date(Date.now() - offsetTime);
    let afterWantTime = wantTime.toISOString().slice(11, 16); //현재시간의 시와 분 리턴

    return afterWantTime;
  };

  const getProNameFnc = async () => {
    //현재 진행공정 가져오기
    const res = await getProNameApi(userData.site_num);
    if (res.status === "success") {
      setNowPro(res.data);
      setEstimateInfo({
        ...estimateInfo,
        bid_pro_no: res.data.pro_no,
      });
      //
    }
  };

  useEffect(() => {
    selectMenuFnc();
    setEstimateInfo({ ...estimateInfo, bid_past_no: location.state?.againBid });
  }, []);

  useEffect(() => {
    getProNameFnc();
  }, [estimateInfo.bid_pro_no]);
  useEffect(() => {
    siteNumSendFnc();
  }, [estimateInfo.bid_addr]);

  //뒤로가기 확인
  // useEffect(() => {
  //   const unblock = history.block((loc, action) => {
  //     if (action === 'POP' && isBlocking) {
  //       return window.confirm('뒤로 가시겠습니까? (데이터가 삭제됩니다.)');
  //     }
  //     return true;
  //   });

  //   return () => unblock();
  // }, [isBlocking]);

  // useEffect(() => {
  //   estimateInfo && setIsBlocking(true);
  // }, [estimateInfo]);

  return (
    <div className="request_short_wrap">
      <Mheader title="견적 / 계약 / 주문" />
      <div className="request_short_container">
        <div className="top_pagenation">
          <h2>견적 기본 정보 입력</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p className="active">2</p>
            <p>3</p>
            <p>4</p>
            <p>5</p>
          </div>
        </div>
        <ul className="many_input_list">
          <div className="budget_info" style={{ marginTop: "10px" }}>
            <span className="request_short_info">
              * 공정단계는 마이페이지 - 현장정보 - 현장 정보수정에서 변경할 수
              있습니다.
            </span>
          </div>
          <li>
            <p>견적 예산</p>
            <div>
              <input
                type="text"
                placeholder="입찰 예산을 입력해주세요."
                className="write_the_budget"
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_budget: e.target.value,
                  });
                }}
              ></input>
              <span className="ten_thousand">천원</span>
            </div>
          </li>
          <div className="budget_info" style={{ marginTop: "10px" }}>
            <span className="request_short_info">
              * 견적예산을 모를 경우 0이라고 입력해주세요.
            </span>
            <span>
              {estimateInfo.bid_budget != 0
                ? numberWithCommasThousand(estimateInfo.bid_budget) + "원"
                : ""}
            </span>
          </div>
          <li>
            <p>투찰 기한</p>
            <div className="bidding_date_box">
              <div className="date_picker_wrap">
                <PickDate
                  estimateInfo={estimateInfo} //회원가입 모든 폼
                  setEstimateInfo={setEstimateInfo} //회원가입 모든 폼 set함수
                  sort="enDate" //change date string 구분용
                  selectDate={biddingDate} //처음에 셀렉되어있는 날짜
                  setSelectDate={setbiddingDate} //셀렉되어있는 날짜 변경
                  time={biddingTime} //시간 가져가기
                  alertMsg={alertMsg.endate} //투찰기한 혹은 납품일시가 minValueTime에 충족하지 않을경우
                  minValueTime={minValueTime} //최소시간 납품일시는 1시간, 투찰가능시간은 10분
                  formatDate={formatDate} //date type format
                />
                {/* <DatePicker
                  locale='ko'
                  selected={biddingDate}
                  onChange={(date) => {
                    setbiddingDate(date);
                    setEstimateInfo({
                      ...estimateInfo,
                      bid_endate: formatDate(date) + ' ' + biddingTime,
                    });
                    minValueTime(date, biddingTime, "bid_endate");
                  }}
                  minDate={new Date()}
                  biddingDate={biddingDate}
                  dateFormat='yyyy-MM-dd'
                  className={ alertMsg.endate ? "select_alert" : null }
                /> */}
              </div>
              <input
                type="time"
                className={
                  alertMsg.endate
                    ? "bidding_time_select select_alert"
                    : "bidding_time_select"
                }
                value={biddingTime}
                // defaultValue={defaultValueTime()}
                required
                onChange={(e) => {
                  setbiddingTime(e.target.value);
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_endate: formatDate(biddingDate) + " " + e.target.value,
                  });
                  minValueTime(biddingDate, e.target.value, "enDate");
                }}
              />
            </div>
            <p
              onClick={() => {
                navigate("/management/quick_order/search");
              }}
              className="btn_quick"
            >
              퀵주문 바로가기
            </p>
          </li>
          {alertMsg.endate ? (
            <span className="bid_time_info">
              투찰 기한은 현재시간에서 10분 이후부터 가능합니다.
            </span>
          ) : null}
          <li>
            <p>납품 일시</p>
            <div className="bidding_date_box">
              <div className="date_picker_wrap">
                <PickDate
                  estimateInfo={estimateInfo} //회원가입 모든 폼
                  setEstimateInfo={setEstimateInfo} //회원가입 모든 폼 set함수
                  sort="delDate" //change date 바꿀인자
                  selectDate={delDate} //처음에 셀렉되어있는 날짜
                  setSelectDate={setDelDate} //셀렉되어있는 날짜 변경
                  time={delTime} //시간 가져가기
                  alertMsg={alertMsg.deldate} //투찰기한 혹은 납품일시가 minValueTime에 충족하지 않을경우
                  minValueTime={minValueTime} //최소시간 납품일시는 1시간, 투찰가능시간은 10분
                  formatDate={formatDate} //date type format
                />
                {/* <DatePicker
                  locale='ko'
                  selected={delDate}
                  onChange={(date) => {
                    setDelDate(date);
                    setEstimateInfo({
                      ...estimateInfo,
                      bid_deldate: formatDate(date) + ' ' + delTime,
                    });
                    minValueTime(date, delTime, "bid_deldate");
                  }}
                  selectsStart
                  minDate={biddingDate}
                  biddingDate={setDelDate}
                  dateFormat='yyyy-MM-dd'
                  className={ alertMsg.deldate ? "select_alert" : null }
                /> */}
              </div>
              <input
                type="time"
                className={
                  alertMsg.deldate
                    ? "bidding_time_select select_alert"
                    : "bidding_time_select"
                }
                value={delTime}
                required
                onChange={(e) => {
                  setdelTime(e.target.value);
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_deldate: formatDate(delDate) + " " + e.target.value,
                  });
                  minValueTime(delDate, e.target.value, "delDate");
                }}
              />
            </div>
          </li>
          {alertMsg.deldate ? (
            <span className="bid_time_info">
              납품 일시는 현재시간에서 1시간 이후부터 가능합니다.
            </span>
          ) : null}
          <li>
            <p>납품 주소</p>
            <div>
              <input
                type="text"
                value={estimateInfo.bid_addr}
                readOnly="readonly"
                className="request_delivery_address"
                disabled
              />
              <input
                className="direct_input"
                placeholder="상세주소를 입력해주세요."
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_addr_detail: e.target.value,
                  });
                }}
              />
            </div>
          </li>
          <li className="sort_line"></li>
          <li>
            <p>배송비 포함 여부</p>
            <div>
              <select
                onChange={(e) => {
                  setEstimateInfo({ ...estimateInfo, bid_fee: e.target.value });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="" hidden></option>
                <option value="Y" selected>
                  포함
                </option>
                <option value="N">포함 안함</option>
              </select>
            </div>
          </li>
          <li>
            <p>하차 역무 포함 여부</p>
            <div>
              <select
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_drop: e.target.value,
                  });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="" hidden></option>
                <option value="U">하차역무 없음 ❌</option>
                <option value="L" selected>
                  하차역무 있음 ⭕️
                </option>
              </select>
            </div>
          </li>
          <li>
            <p>우선사항</p>
            <div>
              <select
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_priority: e.target.value,
                  });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="" hidden></option>
                <option value="품질 우선">품질 우선</option>
                <option value="최저 단가" selected>
                  최저 단가
                </option>
                <option value="적시 배송">적시 배송</option>
                <option value="우선사항 없음">우선사항 없음</option>
              </select>
            </div>
          </li>
          <li>
            <p>
              비용 정산 방법
              <img
                className="question_img"
                src={question_img}
                onMouseOver={() => {
                  setRefundInfoPopup(!refundInfoPopup);
                }}
                onMouseLeave={() => {
                  setRefundInfoPopup(false);
                }}
              ></img>
              {refundInfoPopup ? (
                <p className="question_box_refund">
                  "공새로 위임정산건"입니다. <br></br>
                  아래 번호로 문의 바랍니다.<br></br>
                  고객센터 (T.032-205-0479)
                </p>
              ) : null}
            </p>
            <div>
              <select
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_refund: e.target.value,
                  });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="" hidden></option>
                <option value="공새로 위임 정산" selected>
                  공새로 위임 정산
                </option>
              </select>
            </div>
          </li>

          <li>
            <p></p>
            <div>
              <div className="btn_box">
                <div
                  className="prev"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  이전
                </div>
                <div className="next" onClick={onNextValidation}>
                  다음
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
