export const COLOR = {
  PRIMARY: {
    L10: "#21273D",
    L09: "#0C284E",
    L08: "#11366B",
    L07: "#415E89",
    L06: "#587297",
    L05: "#7086A6",
    L04: "#889AB5",
    L03: "#A0AFC4",
    L02: "#B8C3D3",
    L01: "#E7EBF0",
  },
  SECONDARY: {
    L10: "#FF6600",
    L09: "#FF751A",
    L08: "#FF8533",
    L07: "#FF944D",
    L06: "#FFA366",
    L05: "#FFB280",
    L04: "#FFC299",
    L03: "#FFD1B2",
    L02: "#FFE0CC",
    L01: "#FFF0E5",
  },
  GRAY: {
    L10: "#000000",
    L09: "#1B1D1F",
    L08: "#26282B",
    L07: "#454C53",
    L06: "#72787F",
    L05: "#9EA4AA",
    L04: "#C9CDD2",
    L03: "#E8EBED",
    L02: "#F7F8F9",
    L01: "#FFFFFF",
  },
  SUB: {
    RED: {
      L10: "#E22400",
      L01: "#FFDAD8",
    },
    YELLOW: {
      L10: "#C97900",
      L01: "#FEF1D5",
    },
    GREEN: {
      L10: "#5B8C30",
      L01: "#DEEED4",
    },
    BLUE: {
      L10: "#003FA5",
      L05: "#0061FD",
      L01: "#D2E2FE",
    },
  },
};

export const SPACE = {
  L10: "40px",
  L09: "36px",
  L08: "32px",
  L07: "24px",
  L06: "20px",
  L05: "16px",
  L04: "12px",
  L03: "8px",
  L02: "4px",
  L01: "2px",
};

export const RADIUS = {
  FULL: "50%",
  L04: "18px",
  L03: "12px",
  L02: "6px",
  L01: "3px",
};
