import * as S from "../../asset/css/allHistory.styles.js";
import { PLACEHOLDER } from "../../constant/Placeholder.js";
import { allHistoryOrderApi, historyPackageApi } from "../../api/auth.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Mheader from "../../component/common/Mheader.js";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../util/Util.js";
import arrowClose from "../../asset/images/arrow-close.png";

import HistoryPackagePop from "../../component/common/Pop/HistoryPackagePop.js";
import Modal from "../../component/ui/modal/Modal.js";
import Button from "../../component/ui/button/Button.js";
import Typography from "../../component/ui/typography/Typography.js";
import { actions } from "../../redux/action/index.js";

export default function AllHistory() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redux_excel = useSelector((state) => state.excel);
  // const userData = useSelector((state) => state.user);
  const [defaultHistoryList, setDefaultHistoryList] = useState({
    region: "",
    keyword: "",
  });
  const [packagePop, setPackagePop] = useState(false);
  const [fetchedHistoryList, setFetchedHistoryList] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("전체");
  const [enterSearchKeyword, setEnterSearchKeyword] = useState("");
  const [maxNum, setMaxNum] = useState(0);
  const [tempVar, setTempVar] = useState(0);
  const [selectTab, setSelectTab] = useState(false);
  const [withProductList, setWithProductList] = useState([]);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [excelNavOpen, setExcelNavOpen] = useState(false);
  const openExcelNavModal = () => setExcelNavOpen(true);
  const closeExcelNavModal = () => setExcelNavOpen(false);

  const [openModalIndex, setOpenModalIndex] = useState(null);
  const openPackageModal = (name, idx) => {
    setOpenModalIndex(idx);
    setPackageFnc(name);
  };
  const closePackageModal = () => setOpenModalIndex(null);

  const fetchHistoryList = async () => {
    const response = await allHistoryOrderApi(defaultHistoryList);

    if (response.status === "success") {
      setEnterSearchKeyword(defaultHistoryList.keyword);
      setFetchedHistoryList(response.data);
      setMaxNum(response.data.length);
    }
  };

  const onKeyPressFetchHistoryList = (e) => {
    if (e.key === "Enter") {
      fetchHistoryList();
    }
  };

  const onChangeSearchKeyword = (e) => {
    setDefaultHistoryList({ keyword: e.target.value });
  };

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setDefaultHistoryList({
      ...defaultHistoryList,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
      nowPage: 1,
    });
  };

  useEffect(() => {
    fetchHistoryList();
  }, [tempVar]);

  const navigateFnc = async (type, typeNo) => {
    let path;

    if (type === "BID") {
      path = `/management/estimate/document/${typeNo}`;
    } else if (type === "CONTRACT") {
      path = `/management/contract/document/${typeNo}`;
    } else if (type === "ORDERS") {
      path = `/management/order/document/${typeNo}`;
    }

    navigate(path);
  };

  useEffect(() => {
    if (selectValue === "최근 1개월") setDate(1, "M");
    if (selectValue === "최근 3개월") setDate(3, "M");
    if (selectValue === "최근 6개월") setDate(6, "M");
    if (selectValue === "최근 1년") setDate(1, "y");
    if (selectValue === "전체") {
      setStartDate(new Date("2021-01-01"));
      setEndDate(new Date(moment().format("yyyy-MM-DD")));
      setDefaultHistoryList({
        ...defaultHistoryList,
        startDate: "2021-01-01",
        endDate: moment().format("yyyy-MM-DD"),
        nowPage: 1,
      });
    }
  }, [selectValue]);

  // useEffect(() => {
  //   setDefaultHistoryList({
  //     ...defaultHistoryList,
  //     key: searchKeyword,
  //     nowPage: 1,
  //   });
  // }, [searchKeyword]);

  const [isVisible, setIsVisible] = useState(false);

  // 스크롤 이벤트 핸들러
  const handleScroll = () => {
    if (window.scrollY > 100) {
      // 스크롤이 일정 값 이상인 경우
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // 페이지 로딩 시와 스크롤 이벤트 등록
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // 컴포넌트 언마운트 시 이벤트 제거
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 페이지 상단으로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [packageList, setPackageList] = useState([]);
  const setPackageFnc = async (name) => {
    // 담고 배열을 redux에 저장하고, navigate해주기

    const response = await historyPackageApi(name);
    if (response.status === "success") {
      const data = response.data.map((item) => [
        { value: item.name },
        { value: item.standard },
        { value: item.unit },
        { value: item.count },
        { value: "무관" },
        { value: "" },
      ]);
      setPackageList(data);
    }
  };

  const removeEmptyReduxRows = (data) => {
    return data?.filter((item) => {
      const allEmpty = item.every(
        (field, index) =>
          field.value === "" || (index === 4 && field.value === "무관")
      );
      return !allEmpty;
    });
  };

  const setReduxPackageFnc = async () => {
    // 기존에 있던 redux에 붙혀넣기
    const cleanedData = removeEmptyReduxRows(redux_excel);
    const attachList = [...cleanedData, ...packageList];
    dispatch(actions.setExcel(attachList));
    // 이동할지 물어보는 alert 호출
    openExcelNavModal();
    closePackageModal();
  };

  const navExcelFnc = () => {
    navigate("/management/bid/request_excel", {
      state: { choiceOrderMethod: "업체지정주문" },
    });
  };

  const CustomModal = ({ data, idx }) => {
    return (
      <Modal
        size="xsmall"
        type="success"
        open={openModalIndex === idx}
        title={
          <Typography component="p" type="h2" color="var(--sub-blue-05)">
            {data.name}
          </Typography>
        }
        onOk={setReduxPackageFnc}
        onCancel={closePackageModal}
        footer={[
          <Button type="danger" size="small" onClick={closePackageModal}>
            취소
          </Button>,
          <Button type="primary" size="small" onClick={setReduxPackageFnc}>
            내역 담기
          </Button>,
        ]}
      >
        <div className="history_modal_inner">
          <div>
            <span className="history_modal_no_list_title">규격</span>
            <span className="history_modal_contents">{data.standard}</span>
          </div>
          <div className="history_modal_flex">
            <span className="history_modal_title">연관 상품</span>
            <ul className="history_modal_contents">
              {packageList?.map((elem, idx) => (
                <li>{elem[0].value}</li>
              ))}{" "}
            </ul>
          </div>
          <div>주문서 내역에 {data.name}과 연관상품을 담으시겠습니까?</div>
        </div>
      </Modal>
    );
  };

  return (
    <S.Wrap>
      <Mheader title="품목별 거래현황" />
      <S.Header>
        <S.Title>품목별 거래현황</S.Title>
        <S.SearchBox>
          <S.SearchInput
            placeholder={PLACEHOLDER.ALL_HISTORY_SEARCH_INPUT}
            onChange={onChangeSearchKeyword}
            onKeyPress={onKeyPressFetchHistoryList}
          />

          <S.SearchBtn onClick={fetchHistoryList}>검색</S.SearchBtn>
        </S.SearchBox>
      </S.Header>

      <S.SearchResult>
        <span>{enterSearchKeyword || "전체"}</span> &nbsp;검색결과 :
        <span>{maxNum}</span>건
      </S.SearchResult>

      {isDesktop && (
        <S.HistoryListWrap>
          <S.ListTitle>
            <span>지역∙주문일</span>
            <span>품목명</span>
            <span>단가</span>
            <span>주문금액</span>
            <span>기타</span>
          </S.ListTitle>

          {fetchedHistoryList?.map((data, idx) => (
            <S.ListContent key={idx}>
              <span title={data.region}>
                <S.RegionSection>
                  <span>{data.region || "-"}</span>
                  <span>{data.ordersDate}</span>
                </S.RegionSection>
              </span>

              <span>
                <S.NameSection>
                  <div>
                    <span title={data.name || "-"}>{data.name || "-"}</span>
                    <S.ViewWithOrderBtn
                      data-tip="React-tooltip"
                      data-for="testTip"
                      onClick={() => openPackageModal(data.name, idx)}
                    >
                      연관 상품 보기
                    </S.ViewWithOrderBtn>
                  </div>
                  <span title={data.standard || "-"}>
                    {data.standard || "-"}
                  </span>
                </S.NameSection>
              </span>
              <span title={numberWithCommas(data.price) + "원"}>
                <S.PriceSection>
                  {numberWithCommas(data.price)}원
                </S.PriceSection>
              </span>
              <span>
                <S.AllPriceSection>
                  <span>총 {numberWithCommas(data.totalPrice)}원</span>
                  <span
                    title={
                      data.price.toLocaleString("ko") +
                      "원 * " +
                      data.count +
                      "개 [단위 :" +
                      data.unit +
                      "]"
                    }
                  >
                    ({data.price.toLocaleString("ko") + "원"} *{" "}
                    {data.count + "개"}&nbsp;[단위 : {data.unit}])
                  </span>
                </S.AllPriceSection>
              </span>
              <span>
                <S.EtcSection title={data.etc || "-"}>
                  {data.etc || "-"}
                </S.EtcSection>
              </span>
              <CustomModal data={data} idx={idx} />
            </S.ListContent>
          ))}
          {isVisible && (
            <S.GoTopBtn onClick={scrollToTop}>
              <S.GoTopInner>
                <img src={arrowClose} />
              </S.GoTopInner>
            </S.GoTopBtn>
          )}
        </S.HistoryListWrap>
      )}

      {isMobile && (
        <S.M_HistoryListContainer>
          {fetchedHistoryList?.map((data, idx) => (
            <S.M_HistoryListWrap key={idx}>
              <S.M_HistoryListHeader>
                {data.region || "-"}&nbsp; | &nbsp;{data.ordersDate}
              </S.M_HistoryListHeader>
              <S.M_HistoryListBody>
                <S.M_HistoryListNameSection>
                  <span>품목명 : {data.name || "-"}</span>
                </S.M_HistoryListNameSection>
                <div>
                  단가 : {data.price.toLocaleString("ko") + "원" || "-"}
                </div>
                <S.M_HistoryListPriceSection>
                  주문금액 :
                  {"총 " + data.totalPrice.toLocaleString("ko") + "원" || "-"}
                  <span>
                    ({data.price.toLocaleString("ko") + "원"} *{" "}
                    {data.count + "개"}&nbsp;[단위 : {data.unit}])
                  </span>
                </S.M_HistoryListPriceSection>
                <S.M_HistoryListEtcSection
                  // data-tip="React-tooltip"
                  onClick={() => setSelectTab(idx)}
                  selectTab={selectTab}
                  index={idx}
                >
                  <img src={arrowClose} />
                  기타 : {data.etc || "-"}
                </S.M_HistoryListEtcSection>
                <S.M_HistoryAllListFooter>
                  <S.M_HistoryDetailBtn
                    onClick={() => {
                      openPackageModal(data.name, idx);
                    }}
                  >
                    연관 상품 보기
                  </S.M_HistoryDetailBtn>
                </S.M_HistoryAllListFooter>
              </S.M_HistoryListBody>
              <CustomModal data={data} idx={idx} />
            </S.M_HistoryListWrap>
          ))}
          {packagePop && (
            <HistoryPackagePop
              setPackagePop={setPackagePop}
              withProductList={withProductList}
            />
          )}
        </S.M_HistoryListContainer>
      )}
      <Modal
        size="xsmall"
        type="success"
        open={excelNavOpen}
        title={
          <Typography component="p" type="h2" color="var(--sub-blue-05)">
            주문 품목 내역 담기 완료
          </Typography>
        }
        closeIcon={false}
        onOk={navExcelFnc}
        onCancel={closeExcelNavModal}
        footer={[
          <Button type="danger" onClick={closeExcelNavModal}>
            취소
          </Button>,
          <Button type="primary" onClick={navExcelFnc}>
            확인
          </Button>,
        ]}
      >
        <Typography component="p" type="c1">
          현재 작성 중인 내역에 품목을 추가하였습니다.
        </Typography>
        <Typography component="p" type="c1">
          내역 작성 화면으로 이동하시겠습니까?
        </Typography>
      </Modal>
    </S.Wrap>
  );
}
