import Button from "../../../../../../component/ui/button/Button";

const CreateButton = () => {
  return (
    <Button type="primary" size="medium" htmlType="submit">
      등록하기
    </Button>
  );
};

export default CreateButton;
