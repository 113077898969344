import React, { useState, useEffect } from 'react';
import i_close from '../../../asset/images/w-cancel-icon.png';

import { chatInviteList, chatInvite } from '../../../api/auth';

//redux
import { useSelector } from 'react-redux';

//loading
import Loading from '../../etc/Loading';

//sweet alert
import swal from 'sweetalert';

export default function ChatInvitaionPop({
  setUserPop,
  roomNo,
  updatePartInfo,
}) {
  const userData = useSelector((state) => state.user);

  //state
  const [inviteList, setInviteList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [generating, setGenerating] = useState(false);


  //method
  const _chatInvite = async () => {
    // setGenerating(true);
    
    const reqData = {
      roomNo: roomNo,
      inviteUserNoList:selectedList
    };

    const response = await chatInvite(reqData);
    if (response.status === 'success') {
      //invitation finished
      updatePartInfo(roomNo);
      setUserPop(false);
      setGenerating(false);
    } else {
      swal( "에러가 발생하였습니다. (2)" ,  "" ,  "error" )
      setGenerating(false);
      return;
    }
    setGenerating(false);
  };

  const _chatInviteList = async () => {
    const reqData = {
      room_no: roomNo,
      site_no: userData.site_num,
    };
    const response = await chatInviteList(roomNo);
    if (response.status === 'success') {
      setInviteList(response.data);
    } else {
      if (response.message === 'NotAvailable') {
        swal( "초대 가능한 인원이 없습니다." ,  "" ,  "warning" )
        setUserPop(false);
        return;
      } else {
        swal( "에러가 발생하였습니다." ,  "" ,  "error" )
        setUserPop(false);
        return;
      }
    }
  };

  //lifecycle
  useEffect(() => {
    _chatInviteList();
  }, []);

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setUserPop(false)}></div>
      <div className='chat_user_pop'>
        <div className='close' onClick={() => setUserPop(false)}>
          <img src={i_close} alt='' />
        </div>
        <h3 className='pop_tit'>유저초대</h3>
        <div className='chat_user_info'>
          <ul className='chat_user_list'>

            {inviteList.map((item, index) => {
              return (
                <li>
                  <input
                    type='checkbox'
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        //add
                        if (selectedList.indexOf(item.user.no) === -1) {
                          //not exist
                          let newSelectedList = selectedList;
                          newSelectedList.push(item.user.no);

                          setSelectedList(newSelectedList);
                        }
                      } else {
                        //delete
                        if (selectedList.indexOf(item.user.no) !== -1) {
                          //exist
                          let newSelectedList = selectedList;
                          const idx = newSelectedList.indexOf(item.user.no);
                          if (idx > -1) {
                            newSelectedList.splice(idx, 1);
                          }
                          setSelectedList(newSelectedList);
                        }
                      }
                    }}
                    id={item.user.no}
                  />
                  <label for={item.user.no}>
                    <div className='chat_text'>
                      <h4>{item.organizationName}</h4>
                      <p>
                        {item.user.name} &nbsp;&nbsp;| &nbsp;&nbsp;{item.user.rank}
                      </p>
                    </div>
                  </label>
                </li>
              );
            })}
            {inviteList.length === 0 && <li style={{textAlign:'center'}}>초대 가능한 유저가 없습니다.</li> }
          </ul>
          <div className='chat_img_btn_box btn_box'>
            <div className='cancel'>
              <p onClick={() => setUserPop(false)}>취소</p>
            </div>
            <div className='ok'>
              <p
                onClick={() => {
                  if (selectedList.length === 0) {
                    swal( "초대할 인원을 선택해주세요." ,  "" ,  "warning" )
                    return;
                  } else {
                    _chatInvite();
                  }
                }}
              >
                확인
              </p>
            </div>
          </div>
        </div>
      </div>
      {generating && <Loading msg={'초대 중입니다.'} />}

    </div>
  );
}
