import { useState } from "react";
import close_icon from "../../../asset/images/cancel-btn.png";
import s from "../../../asset/css/reBidPop.module.css";
import swal from "sweetalert";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import moment from "moment";
import { reBidApi } from "../../../api/auth";
import Loading from "../../etc/Loading";
import { motion } from "framer-motion";

export default function ReBidPop(props) {
  const { detailData, bidNo, setReBidPop, estimateDetailFnc2 } = props;
  const [loading, setLoading] = useState(false);

  const [reason, setReason] = useState("");
  const [bidTime, setBidTime] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState(false);

  const [reBidParams, setReBidParams] = useState({
    bid: detailData.bid,
    bidProductList: detailData.bidProductList,
    bidImgList: detailData.bidImgInfoList,
    extraType: "BID",
    previousBidNo: bidNo,
  });

  const onClickReBid = async () => {
    if (!bidTime) {
      swal("투찰기한을 입력해주세요.", "", "error");
      return;
    }
    if (detailData.bid.type !== "C" && !deliveryTime) {
      swal("납품일시를 입력해주세요.", "", "error");
      return;
    }
    if (!reason) {
      swal("재입찰 사유를 입력해주세요.", "", "error");
      return;
    }

    setLoading(true);

    try {
      const response = await reBidApi(reBidParams);
      if (response.status === "success") {
        swal({
          title: "재입찰 처리 되었습니다.",
          icon: "success",
        }).then((value) => {
          if (value) {
            estimateDetailFnc2();
            setReBidPop(false);
          }
        });
      } else {
        swal("재입찰 처리에 실패하였습니다.", "", "error");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeReason = (e) => {
    setReason(e.target.value);
    setReBidParams({
      ...reBidParams,
      bid: { ...reBidParams.bid, selectReason: e.target.value },
    });
  };

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  return (
    <div className={s.background}>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.title}>재입찰</div>
          <div
            className="bid_status_modal_close"
            onClick={() => setReBidPop(false)}
          >
            <img src={close_icon} alt="close_icon" />
          </div>
        </div>

        <div className={s.body}>
          <div>품목 조건에 변동없이 입찰을 다시 진행합니다.</div>
          <div>
            기존 입찰번호 : <span>{detailData.bid?.code}</span>
          </div>
          <div>기존 입찰은 유찰 상태로 변경됩니다.</div>
        </div>

        <div className={s.date_container}>
          <div className={s.date_title}>투찰기한</div>
          <ReactDatePicker
            selected={bidTime}
            onChange={(date) => {
              setBidTime(date);
              setReBidParams({
                ...reBidParams,
                bid: {
                  ...reBidParams.bid,
                  endate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                },
              });
            }}
            isClearable={true}
            peekNextMonth
            withPortal
            selectsStart
            showTimeSelect
            dateFormat="yyyy-MM-dd h:mm aa"
            locale={ko}
            showMonthDropdown
            showYearDropdown
            placeholderText="투찰기한"
            dropdownMode="select"
            minDate={
              new Date().getHours() >= 12
                ? new Date(new Date().getTime() + oneDayInMilliseconds)
                : new Date()
            }
            minTime={
              formatDate(bidTime) === formatDate(new Date())
                ? new Date().setMinutes(new Date().getMinutes() + 360)
                : new Date().getHours() >= 12 &&
                  moment(bidTime).diff(moment(), "days") < 1
                ? new Date().setHours(new Date().getHours() - 3, 0, 0)
                : new Date().setHours(7, 0, 0)
            }
            maxTime={new Date().setHours(18, 0, 0)}
          >
            <motion.span
              className="select_time_alert"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.2,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <span>
                투찰 마감 <span style={{ fontWeight: 700 }}>시간</span>을
                선택해주세요.
              </span>
            </motion.span>
          </ReactDatePicker>

          <div className={s.date_title}>납품일시</div>
          <ReactDatePicker
            selected={deliveryTime}
            onChange={(date) => {
              setDeliveryTime(date);
              setReBidParams({
                ...reBidParams,
                bid: {
                  ...reBidParams.bid,
                  deldate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                },
              });
            }}
            isClearable={detailData.bid.type !== "C"}
            peekNextMonth
            disabled={detailData.bid.type === "C"}
            withPortal
            selectsStart
            showTimeSelect
            dateFormat="yyyy-MM-dd h:mm aa"
            locale={ko}
            showMonthDropdown
            showYearDropdown
            placeholderText="납품일시"
            dropdownMode="select"
            minDate={
              new Date(bidTime).getHours() >= 12 && bidTime
                ? new Date(bidTime.getTime() + oneDayInMilliseconds)
                : new Date(bidTime)
            }
            minTime={
              formatDate(deliveryTime) === formatDate(bidTime)
                ? new Date(bidTime).setHours(new Date(bidTime).getHours() + 6)
                : moment(deliveryTime).diff(moment(bidTime), "days") < 1
                ? new Date(deliveryTime).setHours(
                    new Date(bidTime).getHours() - 3,
                    0,
                    0
                  )
                : new Date().setHours(7, 0, 0)
            }
            maxTime={new Date().setHours(18, 0, 0)}
          >
            <motion.span
              className="select_time_alert"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.2,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <span>
                원하시는 납품 <span style={{ fontWeight: 700 }}>날짜</span>를
                선택해주세요.
              </span>
            </motion.span>
          </ReactDatePicker>
        </div>

        <div className={s.textarea_container}>
          <div>재입찰 사유 입력</div>
          <textarea
            placeholder="사유를 입력해주세요."
            onChange={onChangeReason}
          />
        </div>

        <div className={s.footer}>
          <button onClick={() => setReBidPop(false)}>취소</button>
          <button onClick={onClickReBid}>확인</button>
        </div>
      </div>
      {loading && <Loading msg={"로딩중 입니다."} />}
    </div>
  );
}
