import React, { useState, useEffect } from 'react';
//library
import { useNavigate, useLocation } from 'react-router-dom';
//component
import Mheader from '../../component/common/Mheader';
//css
import '../../asset/css/payment.css';
//etc
import {
  paySendingApi,
  getServiceData,
  peyCompleteApi,
  paymentFailed,
} from '../../api/auth';
import { IMP_CODE, PG } from '../../constant/Constant';
import { generateRandomKey } from '../../util/Util';

//sweet alert
import swal from 'sweetalert';

export default function Payment({}) {
  const navigate = useNavigate();
  const location = useLocation();

  const buyerData = location.state;

  //service Data
  const [serviceNo, setServiceNo] = useState(1);
  const [serviceData, setServiceData] = useState({});
  const [serviceLoading, setServiceLoading] = useState(false);

  //payment Method
  const [payMethod, setPayMethod] = useState('card');

  const { IMP } = window;
  IMP.init(IMP_CODE);

  const [payData, setPayData] = useState({
    pg: PG,
    pay_method: 'card',
    merchant_uid: `gong_${new Date().getTime()}_${generateRandomKey(5)}`,
    buyer_name: buyerData.u_name,
    buyer_tel: buyerData.u_phone,
    buyer_email: buyerData.u_id,
    pay_text: '',
    pay_state: '',
  });

  const onPaymentFinished = async (response) => {
    const { success, error_msg, pay_method } = response;
    /**
     *  success,
      error_msg,
      imp_uid,
      merchant_uid,
      pay_method,
      paid_amount,
     */

    let resultData = {
      merchant_uid: response.merchant_uid,
      imp_uid: response.imp_uid,
      amount: response.paid_amount,
      pay_method: response.pay_method,
    };
    if (success) {
      const response = await peyCompleteApi(resultData);
      if (response.status === 'success') {
        //결제완료, 새로고침
        swal('결제가 완료되었습니다. 감사합니다.','','success');
        navigate('/look',{replace:true});
      }
    } else {
      //fail
      resultData = { ...resultData, reason: error_msg };
      const response = await paymentFailed(resultData);
      if (response.status === 'success') {
        //결제실패 기록완료
        swal('결제에 실패하였습니다. 결제수단을 다시 확인해주세요.','','error');
        navigate('/look',{replace:true});
      }
    }
  };

  //결제상품정보 조회
  const _getServiceData = async (service_no) => {
    setServiceLoading(true);
    const response = await getServiceData({ service_no: service_no });
    if (response.status === 'success') {
      setServiceData(response.data);
    } else {
      swal('상품 정보 조회에 실패하였습니다.','','error');
      return;
    }
  };

  //결제 정보검증 api
  const cashCheckApi = async () => {
    const response = await paySendingApi(
      payData.merchant_uid,
      payData.amount,
      serviceNo
    );
    if (response.status === 'success') {
      IMP.request_pay(payData, onPaymentFinished);
    } else {
      swal('다시 시도해주세요','','warning');
      navigate('/look/payment',{replace:true});
    }
  };

  useEffect(() => {
    _getServiceData(serviceNo);
  }, [serviceNo]);

  useEffect(() => {
    if (serviceData.service_no !== undefined) {
      setPayData({
        ...payData,
        amount: serviceData.price,
        name: serviceData.name,
      });
      setServiceLoading(false);
    }
  }, [serviceData]);

  return (
    <div className='payment_wrap'>
      <Mheader title='유료 서비스 결제' />
      <div className='payment_container'>
        <div className='payment_part1'>
          <div className='payment_top'>
            <h2>유료 서비스 결제하기</h2>
            <div className='payment_pagenation'>
              <p className='active'>1</p>
              <p>2</p>
            </div>
          </div>
          <div className='payment_box'>
            <p className='payment_title_for_pc'>구매 서비스를 선택해주세요.</p>
            <p className='payment_title_for_mo'>구매 서비스 선택</p>
            <ul>
              <li
                className={serviceNo === 1 ? 'service_select' : ''}
                onClick={() => {
                  setServiceNo(1);
                }}
              >
                <div className='tier_title'>
                  <input
                    type='radio'
                    id='p1'
                    name='등급'
                    checked={serviceNo === 1 ? true : false}
                    value={1}
                    onChange={() => {
                      setServiceNo(1);
                    }}
                  />
                  <label
                    className={
                      serviceNo === 1 ? 'check_sub_on' : 'check_sub_off'
                    }
                    htmlFor='p1'
                  >
                    <span>골드등급</span>
                  </label>
                </div>
                <p>인근 업체 찾기 이용 가능</p>
                <strong>
                  6,600 <span>₩</span>
                </strong>
              </li>
              <li
                className={serviceNo === 2 ? 'service_select' : ''}
                onClick={() => {
                  setServiceNo(2);
                }}
              >
                <div className='tier_title'>
                  <input
                    type='radio'
                    id='p2'
                    name='등급'
                    value={2}
                    onChange={() => {
                      setServiceNo(2);
                    }}
                    checked={serviceNo === 2 ? true : false}
                  />
                  <label
                    className={
                      serviceNo === 2 ? 'check_sub_on' : 'check_sub_off'
                    }
                    htmlFor='p2'
                  >
                    <span>플래티넘 등급</span>
                  </label>
                </div>
                <p>인근공급사찾기 이용 + 월 매거진 이메일 수신 가능</p>
                <strong>
                  11,000 <span>₩</span>
                </strong>
              </li>
            </ul>
          </div>
        </div>
        <div className='payment_part2'>
          <p>결제 수단을 선택해주세요.</p>
          <ul>
            <li
              className={payMethod === 'card' ? 'pay_select' : ''}
              onClick={() => {
                setPayMethod('card');
                setPayData({ ...payData, pay_method: 'card' });
              }}
            >
              신용카드
            </li>
            <li
              className={payMethod === 'vbank' ? 'pay_select' : ''}
              onClick={() => {
                setPayMethod('vbank');
                setPayData({ ...payData, pay_method: 'vbank' });
              }}
            >
              가상계좌
            </li>
            <li
              className={payMethod === 'trans' ? 'pay_select' : ''}
              onClick={() => {
                setPayMethod('trans');
                setPayData({ ...payData, pay_method: 'trans' });
              }}
            >
              실시간계좌이체
            </li>
          </ul>
        </div>
        <div className='btn_wrap'>
          <div
            onClick={() => {
              navigate(-1);
            }}
          >
            취소
          </div>
          <div
            onClick={() => {
              if (serviceLoading) {
                swal('정보 조회중입니다. 잠시 후 다시 시도해주세요.','','warning');
                return;
              }
              cashCheckApi();
            }}
          >
            결제하기
          </div>
        </div>
      </div>
    </div>
  );
}
