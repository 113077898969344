import Button from "../../../../../../component/ui/button/Button";

const SignupButton = () => {
  return (
    <Button type="primary" size="medium" htmlType="submit">
      가입하기
    </Button>
  );
};

export default SignupButton;
