import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attendSiteApproveApi } from "../../api/auth";
import { QUERY_KEYS } from "../../constant/QueryKeys";

const useAttendSiteApprove = (onSuccessCb, onErrorCb) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: attendSiteApproveApi,
    onSuccess: (res) => {
      onSuccessCb(res);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ATTEND_SITE_APPROVE],
      });
    },
    onError: (error) => {
      onErrorCb(error);
    },
  });
};

export default useAttendSiteApprove;
