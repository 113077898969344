import { useState, useEffect, useCallback, useRef } from "react";

const formatTime = (sec) => {
  const mins = Math.floor(sec / 60);
  const secs = sec % 60;
  return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
};

const useTimer = (sec) => {
  const [time, setTime] = useState(formatTime(sec));
  const [isActive, setIsActive] = useState(false);
  const intervalRef = useRef(null);

  const updateTimer = useCallback(() => {
    setTime((prevTime) => {
      const [mins, secs] = prevTime.split(":").map(Number);
      const currentSeconds = mins * 60 + secs;

      if (currentSeconds <= 0) {
        clearInterval(intervalRef.current);
        setIsActive(false);
        return formatTime(0);
      }

      const newSeconds = currentSeconds - 1;
      return formatTime(newSeconds);
    });
  }, []);

  const start = useCallback(() => {
    setTime(formatTime(sec));
    setIsActive(true);
    intervalRef.current = setInterval(updateTimer, 1000);
  }, [sec, updateTimer]);

  const end = useCallback(() => {
    clearInterval(intervalRef.current);
    setIsActive(false);
  }, []);

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return {
    time,
    start,
    end,
    isActive,
  };
};

export default useTimer;
