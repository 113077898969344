import React, { useEffect, useState } from "react";
//library
import { useNavigate, useLocation } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/request.css";
import s from "../../asset/css/requestPb.module.css";

import pb_banner1 from "../../asset/images/pb_banner1.jpg";
import pb_banner2 from "../../asset/images/pb_banner2.jpg";
import pb_empty_card from "../../asset/images/pb_empty_cart.png";
import fire_waste from "../../asset/images/fire_waste.png";
import no_fire_waste from "../../asset/images/no_fire_waste.png";
import i_quick_order_active from "../../asset/images/i_quick_order_active.png";

import i_choose_company from "../../asset/images/i_choose_company.png";
import i_choose_company_active from "../../asset/images/i_choose_company_active.png";
import i_choose_product from "../../asset/images/i_choose_product.png";
import i_choose_product_active from "../../asset/images/i_choose_product_active.png";
import arrow_icon from "../../asset/images/excel_arrow_icon.png";

// redux
import { actions } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";

import swal from "sweetalert";
import { useMediaQuery } from "react-responsive";
import PbOrderPop from "../../component/common/Pop/PbOrderPop";
import PbDescPop from "../../component/common/Pop/PbDescPop";
import Slider from "react-slick";
import MaterialTablePop from "../../component/common/Pop/MaterialTablePop";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";

export default function Request() {
  const [pbPop, setPbPop] = useState(false);
  const [pbDescPop, setPbDescPop] = useState(false);
  // const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isPosco = user?.rep_con === "(주)포스코이앤씨";

  // 잡자재 기준표 모달
  const [materialTablePop, setMaterialTablePop] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  // const settings = {
  //   customPaging: function (i) {
  //     return (
  //       <a className="pb_slider_nav_item">
  //         asdf
  //         {/* <img src={`${wasteList[i]}`} alt={`waste${i + 1}`} /> */}
  //       </a>
  //     );
  //   },
  //   dots: true,
  //   dotsClass: "slick-dots pb-slick-thumb",
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  const swalReverse = () => {
    swal({
      title: "주문서 역발행",
      text: `
        1. 고객사가 주문서 발행 요청 (전화, 문자, 메일 등)
        2. 공급사에서 "주문서 생성" 후 품목, 단가, 수량 등 입력
        3. 주문서 발행 > 고객사 확인 > 배송 진행
      `,
      icon: "info",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
            * 주문서 역발행</br>
              급한 건에 대하여 선제적으로 납품 행위를 먼저 하는 방법으로</br>
              빠른 배송을 원하는 상황에 적합합니다.
          `,
        },
      },
    });
  };

  return (
    <div className={s.wrap}>
      <Mheader title="PB상품 주문하기" />

      <div className={s.container}>
        <div className={s.contents_wrap}>
          {/* <div className={s.title}>PB상품</div>
          <div className={s.title_desc}>
            건설 현장 생산성 향상에 도움을 드리는 품목입니다
          </div> */}
          <div className="top_box">
            <div className="title">PB상품</div>

            <OrderBreadCrumb
              step1="PB상품 선택"
              step2="상세정보 입력"
              step3="상세정보 확인"
              step4="주문완료"
              isActive={1}
            />
          </div>

          <div className="top_request_sub_header">
            <div className="top_request_sub_header_wrap">
              <p>
                <span>공새로 PB상품을 선택하여 주문할 수 있습니다.</span>
                <br />
                <span>
                  포스코 이앤씨 ESG 정책에 의거하여 추진 중인 사항입니다.
                </span>
                <br />
                {/* {isPosco && (
                  <>
                    *&nbsp;
                    <span
                      className="sub_header_link"
                      onClick={() => setMaterialTablePop(true)}
                    >
                      잡자재 기준 안내
                    </span>
                  </>
                )} */}
              </p>
            </div>
          </div>

          <div className={s.contents_box}>
            <div className={s.contents_inner}>
              <div className={s.pb_contents} onClick={() => setPbPop(true)}>
                <div className={s.contents_img_box}>
                  <img src={fire_waste} />
                  <img src={no_fire_waste} />
                </div>
                <div className={s.pb_desc}>
                  <span className={s.pb_desc_title}>
                    폐기물 분리 마대 (40kg)
                  </span>
                  <span className={s.pb_desc_contents}>가연성 / 불연성</span>
                  <span className={s.pb_desc_contents}>210원</span>
                </div>
              </div>
              <div className={s.empty_contents}>
                <img src={pb_empty_card} />
                <span>상품 준비 중입니다.</span>
              </div>
              <div className={s.empty_contents}>
                <img src={pb_empty_card} />
                <span>상품 준비 중입니다.</span>
              </div>
              <div className={s.empty_contents}>
                <img src={pb_empty_card} />
                <span>상품 준비 중입니다.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.img_container}>
        <Slider {...settings} style={{ height: "300px;", width: "90%" }}>
          <img
            src={pb_banner2}
            className={s.slide_img}
            onClick={() => {
              setPbDescPop(true);
            }}
          />
          <img src={pb_banner1} className={s.slide_img} />
        </Slider>
      </div>

      {materialTablePop && (
        <MaterialTablePop setMaterialTablePop={setMaterialTablePop} />
      )}
      {pbPop && <PbOrderPop setPbPop={setPbPop} />}
      {pbDescPop && <PbDescPop setPbDescPop={setPbDescPop} />}
    </div>
  );
}
