import { COLOR } from "../../../constant/Palette";
import styles from "./typography.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export default function Typography({
  component: Component,
  type = "c1",
  color = COLOR.GRAY.L09,
  children,
  ...props
}) {
  const className = cx({
    [type]: true,
  });

  return (
    <Component className={className} style={{ color: color }} {...props}>
      {children}
    </Component>
  );
}
