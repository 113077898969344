import React from "react";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import Button from "../../../../../../component/ui/button/Button";

const SiteInviteMailSuccess = ({
  siteInviteMailSuccess,
  setSiteInviteMailSuccess,
}) => {
  const onClickCancel = () => {
    setSiteInviteMailSuccess(false);
  };

  return (
    <Modal
      size="xsmall"
      type="success"
      open={siteInviteMailSuccess}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.BLUE.L05}>
          전송이 완료되었습니다.
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="primary" size="small" onClick={onClickCancel}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        회원 가입을 위한 초대 메일이 전송되었습니다.
      </Typography>
    </Modal>
  );
};

export default SiteInviteMailSuccess;
