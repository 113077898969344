import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import Select from "../../../../../../component/ui/select/Select";
import TypeInput from "../input/TypeInput";
import s from "./typeSelect.module.css";

const options = [
  { value: "공통주택(아파트)", label: "공통주택(아파트)" },
  { value: "공통주택(주상복합)", label: "공통주택(주상복합)" },
  { value: "오피스텔", label: "오피스텔" },
  { value: "물류센터", label: "물류센터" },
  { value: "직접 입력", label: "직접 입력" },
];

const TypeSelect = ({ control, errors }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="select_type"
      control={control}
      render={({ field }) => {
        const isUserInput = field.value === "직접 입력";

        return (
          <div className={s.select_container}>
            <label htmlFor="select_type">
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                분류{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </label>

            <Select
              {...field}
              value={field.value || undefined}
              size="medium"
              width={isDesktop ? 380 : "100%"}
              placeholder="현장 분류를 선택해주세요."
              options={options}
              helpText={
                errors.select_type && (
                  <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                    {errors.select_type.message}
                  </Typography>
                )
              }
              status={errors.select_type && "error"}
            />

            {isUserInput && <TypeInput control={control} errors={errors} />}
          </div>
        );
      }}
    />
  );
};

export default TypeSelect;
