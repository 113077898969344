import React from "react";
//library
import { Link } from "react-router-dom";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import i_calc from "../../../asset/images/calc.png";

export default function HistoryPackagePop({ setPackagePop, withProductList }) {
  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setPackagePop(false)}></div>
      <div className="calculate_info_pop">
        <div className="close" onClick={() => setPackagePop(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3 className="pop_tit">연관 상품</h3>
        <div className="package_info">
          {withProductList?.map((elem, idx) => {
            return <div className="package_elem">{elem.name}</div>;
          })}
          <span>{withProductList.length}개의 연관 상품입니다.</span>
          {/* <img src={taxData?.f_root} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
