import DownloadSVG from "../../../asset/images/svg/DownloadSVG";
import Button from "../../../component/ui/button/Button";
import Typography from "../../../component/ui/typography/Typography";
import { COLOR } from "../../../constant/Palette";
import s from "./buttonTest.module.css";

export default function ButtonTest() {
  return (
    <div className={s.button_group_container}>
      <Typography component="p" type="h1">
        BUTTON SIZE TEST
      </Typography>
      <div>
        <Button size="xsmall">xs</Button>
        <Button size="small">small</Button>
        <Button size="medium">medium</Button>
        <Button size="large">large</Button>
      </div>

      <Typography component="p" type="h1">
        BUTTON TYPE TEST
      </Typography>
      <div>
        <Button size="xsmall" type="primary">
          primary
        </Button>
        <Button size="small" type="secondary">
          secondary
        </Button>
        <Button size="medium" type="danger">
          danger
        </Button>
        <Button
          size="large"
          withIcon
          icon={<DownloadSVG size="medium" stroke="var(--white)" />}
          iconPosition="end"
        >
          with icon
        </Button>
        <Button type="primary" onlyIcon icon={<DownloadSVG size="medium" />} />
      </div>

      <Typography component="p" type="h1">
        BUTTON DISABLE TEST
      </Typography>
      <div>
        <Button size="xsmall" type="primary" disabled>
          disabled
        </Button>
        <Button size="small" type="secondary" disabled>
          disabled
        </Button>
        <Button size="medium" type="danger" disabled>
          disabled
        </Button>
        <Button
          size="large"
          type="secondary"
          withIcon
          icon={<DownloadSVG size="medium" stroke={COLOR.GRAY.L04} />}
          iconPosition="end"
          disabled
        >
          disabled
        </Button>
        <Button
          type="primary"
          onlyIcon
          icon={<DownloadSVG size="medium" />}
          disabled
        />
      </div>
    </div>
  );
}
