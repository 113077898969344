import Chip from "../../../../../component/ui/chip/Chip";

const typeConfig = {
  A: { color: "gray", label: "앱주문" },
  S: { color: "green", label: "일회성입찰" },
  T: { color: "yellow", label: "단가계약" },
  P: { color: "red", label: "역발행" },
  Q: { color: "blue", label: "업체지정" },
  R: { color: "gray", label: "반품" },
  B: { color: "secondary", label: "PB주문" },
  D: { color: "secondary", label: "PB주문" },
};

export default function TypeChip({ type }) {
  const chipConfig = typeConfig[type];

  return chipConfig ? (
    <Chip size="medium" type="border" color={chipConfig.color}>
      {chipConfig.label}
    </Chip>
  ) : null;
}
