import { useState } from "react";
import useSearchOrg from "../../../../../hooks/useQuery/useSearchOrg";

import Button from "../../../../../component/ui/button/Button";
import Modal from "../../../../../component/ui/modal/Modal";
import Typography from "../../../../../component/ui/typography/Typography";
import Spin from "../../../../../component/ui/spin/Spin";
import OrgSearchInput from "../elements/input/OrgSearchInput";

import { COLOR } from "../../../../../constant/Palette";
import s from "./orgSearchModal.module.css";
import classNames from "classnames/bind";
import CirclePlusSVG from "../../../../../asset/images/svg/CirclePlusSVG";

const OrgSearchModal = ({
  orgSearchModal,
  setOrgSearchModal,
  setValue,
  clearErrors,
}) => {
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");

  const { data, isLoading, refetch: handleSearchOrg } = useSearchOrg(keyword);
  let orgList = data?.data.constructionInfoList || [];

  const orgRegistrationData = {
    construction: {
      no: "registration",
      name: "가입 후 등록",
    },
    fileRoot: null,
  };

  orgList = [...orgList, orgRegistrationData];

  const handleClickOrg = (org) => {
    setSelectedOrg(org.construction.name);
  };

  const handleSearch = () => {
    setSearchKeyword(keyword);
    handleSearchOrg();
  };

  const onClickCancel = () => {
    setSelectedOrg(null);
    setOrgSearchModal(false);
  };

  const onClickOk = () => {
    setValue("org_name", selectedOrg);
    clearErrors("org_name");
    setOrgSearchModal(false);
  };

  const onClickEmptyOk = () => {
    setValue("org_name", "가입 후 등록");
    clearErrors("org_name");
    setOrgSearchModal(false);
  };

  return (
    <Modal
      size="xsmall"
      open={orgSearchModal}
      title={
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          회사 검색
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button
          type="primary"
          size="small"
          onClick={onClickOk}
          disabled={!selectedOrg}
        >
          확인
        </Button>,
      ]}
    >
      <OrgSearchInput
        keyword={keyword}
        setKeyword={setKeyword}
        handleSearch={handleSearch}
        setSelectedOrg={setSelectedOrg}
      />

      <div className={s.org_container}>
        {isLoading && <Spin />}
        {orgList?.length !== 1 &&
          orgList?.map((org) => (
            <div
              className={classNames(s.org, {
                [s.selected]: selectedOrg === org.construction.name,
              })}
              key={org.construction.no}
              onClick={() => handleClickOrg(org)}
            >
              {org.fileRoot ? (
                <div className={s.org_logo_container}>
                  <img src={org.fileRoot} alt="org_logo" />
                </div>
              ) : (
                <div className={s.svg_container}>
                  <CirclePlusSVG />
                </div>
              )}
              <Typography
                component="p"
                type={selectedOrg === org.construction.name ? "h5" : "c1"}
                color={COLOR.GRAY.L09}
              >
                {org.construction.name}
              </Typography>
            </div>
          ))}
        {orgList?.length === 1 && (
          <div className={s.empty_container}>
            <Typography component="span" type="c1" color={COLOR.GRAY.L09}>
              <Typography component="span" type="c2" color={COLOR.SUB.BLUE.L05}>
                "{searchKeyword}"
              </Typography>
              가 존재하지 않습니다.
            </Typography>
            <div>
              <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                회원 가입 후, 마이페이지에서
              </Typography>
              <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                회사 등록 및 승인이 완료되어야
              </Typography>
              <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                서비스를 정상적으로 이용할 수 있습니다.
              </Typography>
            </div>
            <Typography component="span" type="c1" color={COLOR.GRAY.L09}>
              회원가입 후, 회사 정보를 등록하시겠습니까?
            </Typography>
            <Button type="primary" size="small" onClick={onClickEmptyOk}>
              확인
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default OrgSearchModal;
