import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constant/QueryKeys";
import { checkPhoneApi } from "../../api/auth";

const useCheckPhone = (userType, phone, code) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CHECK_PHONE],
    queryFn: () => checkPhoneApi(userType, phone, code),
    enabled: false,
    retry: false,
  });
};

export default useCheckPhone;
