import { useEffect, useState } from "react";
import s from "../../../asset/css/materialTablePop.module.css";
import close_icon from "../../../asset/images/cancel-btn.png";
import { materialTableSearchApi } from "../../../api/auth";

const FIRST = "first";
const SECOND = "second";

export default function MaterialTablePop({ setMaterialTablePop }) {
  const [activeSwitch, setActiveSwitch] = useState(FIRST);
  const [keyword, setKeyword] = useState("");
  const [materialTable, setMaterialTable] = useState([]);

  const onClickSwitch = (switchName) => {
    setActiveSwitch(switchName);
  };

  const materialTableSearch = async (searchKeyword) => {
    try {
      const response = await materialTableSearchApi({
        keyword: searchKeyword,
      });
      setMaterialTable(response.data.materialCategoryList);
    } catch (error) {
      console.error(error);
    }
  };

  const onKeyPressSearchInput = (e) => {
    if (e.key === "Enter") materialTableSearch(keyword);
  };

  useEffect(() => {
    materialTableSearch("");
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className={s.background} onClick={() => setMaterialTablePop(false)}>
      <div className={s.container} onClick={(e) => e.stopPropagation()}>
        <div className={s.header}>
          <div className={s.title}>잡자재 기준 안내</div>
          <div className={s.close} onClick={() => setMaterialTablePop(false)}>
            <img src={close_icon} alt="close_icon" />
          </div>
        </div>

        <div className={s.switch_container}>
          <div className={s.switch_box}>
            <div
              className={activeSwitch === FIRST ? s.switch_active : s.switch}
              onClick={() => onClickSwitch(FIRST)}
            >
              사급, 도급 자재 기준
            </div>
            <div
              className={activeSwitch === SECOND ? s.switch_active : s.switch}
              onClick={() => onClickSwitch(SECOND)}
            >
              잡자재 기준
            </div>
          </div>

          <div className={s.search_box}>
            <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={onKeyPressSearchInput}
              placeholder="소분류명을 검색해주세요"
            />
            <button onClick={() => materialTableSearch(keyword)}>검색</button>
          </div>
        </div>

        {activeSwitch === FIRST && (
          <div className={s.desc_container}>
            ○ 사급,도급 정의
            <br />- 사급자재 : 당사가 직접 구매하여 수급사업자(공사업체)에게
            제공하는 자재
            <br />
            단, 설치가 포함된 자재로서 단가계약된 품목은 사급자재로 간주
            (온돌마루,강마루,철재칸막이,자동문 등)
            <br />- 도급자재 : 수급사업자(공사업체)가 구매하는 자재 (하도급공사
            발주 시 자재를 포함하여 발주)
            <br />○ PM은 공사여건(물량, 납기등）및 원가절감 차원에서 유리하다고
            판단되는 경우
            <br />- 구매부서(공사,자재) 합의하에 본 조달기준과 상이하게 편성
            가능 (사급 ↔ 도급)
            <br />○ 사급품목 도급가능 금액 : 1천만원 → 3천만원
            <br />- 하기 조달표에 명기되지 않은 품목 발주 시 3천만원 이상인 경우
            구매부서(공사,자재)와 협의하여 결정
            <br />○ 기타
            <br />- 납품기준 : 포스코이앤씨 "사도급자재 조달기준" 참조
            <br />- 제작관리가 필요하여 제작관리부서 경유가 필요한 품목은 설비로
            발주
            <br />- 손료자재는 도급으로 발주 가능
            <br />- 일부 사급자재의 경우 계약유형이 설비 또는 공사로 발주되며,
            담당 구매부서는 비고 참조
          </div>
        )}

        {activeSwitch === SECOND && (
          <div className={s.desc_container}>
            ○ 천재지변에 따른 긴급 복구, 민원 예방 및 신속 대응 등을 위해
            긴급성을 요하는 물품은 잡자재 구매 가능
            <br />○ 건축/구조물의 본구성품이 되는 자재는 제외
          </div>
        )}

        <div className={s.table_container}>
          <table>
            <tbody>
              <tr className={s.table_title}>
                <th>
                  <p>대분류</p>
                </th>
                <th>
                  <p>중분류</p>
                </th>
                <th>
                  <p>소분류</p>
                </th>
                <th>
                  <p>기준</p>
                </th>
                <th>
                  <p>비고</p>
                </th>
              </tr>
              {materialTable?.map((material) => (
                <MaterialTableTd key={material.no} material={material} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function MaterialTableTd({ material }) {
  return (
    <tr key={material.no} className={s.table_content}>
      <td>{material.largeCategory}</td>
      <td>{material.middleCategory}</td>
      <td>{material.smallCategory}</td>
      <td>{material.supplier}</td>
      <td>{material.comment}</td>
    </tr>
  );
}
