import { Pagination as AntdPagination } from "antd";
import styles from "./pagination.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export default function Pagination({
  current,
  total,
  onChange,
  pageSize,
  ...props
}) {
  return (
    <AntdPagination
      className={cx("table")}
      showSizeChanger={false}
      current={current}
      total={total}
      onChange={onChange}
      pageSize={pageSize}
      responsive
      {...props}
    />
  );
}
