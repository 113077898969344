import { useCallback, useEffect, useState } from "react";
import { ordersModifyHistoryGroupApi } from "../../../api/auth";
import * as S from "../../../asset/css/ordersModifyHistoryTabs.styles";

export default function OrdersModifyHistoryTabs(props) {
  const { ordersGroupNo, index, selectedTab, arrayLength } = props;
  const [ordersModifyHistoryList, setOrdersModifyHistoryList] = useState();

  const fetchOrdersModifyHistoryList = useCallback(async () => {
    try {
      const response = await ordersModifyHistoryGroupApi(ordersGroupNo);
      setOrdersModifyHistoryList(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [ordersGroupNo]);

  useEffect(() => {
    fetchOrdersModifyHistoryList();
  }, [fetchOrdersModifyHistoryList]);

  const isLastIndex = index === arrayLength;

  const renderDiff = (diff) =>
    !isLastIndex &&
    diff !== null &&
    diff !== 0 && (
      <S.Diff diff={diff}>
        ({diff > 0 ? "+" : ""}
        {diff?.toLocaleString("ko")})
      </S.Diff>
    );

  const totalAmountDiff =
    ordersModifyHistoryList?.totalAmount -
    ordersModifyHistoryList?.beforeTotalAmount;

  return (
    <div style={{ position: "relative" }}>
      {selectedTab !== 0 && selectedTab === index && (
        <div className="subject_ok_table">
          {!isLastIndex && (
            <div
              style={{
                position: "absolute",
                top: "-30px",
                right: 0,
                zIndex: 10,
                height: "30px",
                lineHeight: "30px",
              }}
            >
              <span>품목 추가 </span>
              <span style={{ color: "#FF3B30" }}>
                +{ordersModifyHistoryList?.modifyAdd?.toLocaleString("ko")}
              </span>
              <span> 품목 삭제 </span>
              <span style={{ color: "#0061FD" }}>
                -{ordersModifyHistoryList?.modifyDelete?.toLocaleString("ko")}
              </span>
            </div>
          )}

          <table>
            <tbody>
              <tr>
                <th>
                  <p>품목명</p>
                </th>
                <th>
                  <p>규격</p>
                </th>
                <th>
                  <p style={{ lineHeight: "1.3" }}>
                    제조국
                    <br />
                    (브랜드)
                  </p>
                </th>
                <th>
                  <p>단위</p>
                </th>
                <th>
                  <p>수량</p>
                </th>
                <th>
                  <p>단가</p>
                </th>
                <th>
                  <p>금액</p>
                </th>
                <th>
                  <p>비고</p>
                </th>
              </tr>
              {ordersModifyHistoryList?.productInfoList?.map((productInfo) => {
                const curCount = productInfo?.bidProduct?.count;
                const beforeCount = productInfo?.beforeCount;
                const countDiff = curCount - beforeCount;

                const curPrice = productInfo?.price;
                const beforePrice = productInfo?.beforePrice;
                const priceDiff = curPrice - beforePrice;

                const curTotal = curCount * curPrice;
                const totalDiff = curTotal - beforeCount * beforePrice;

                return (
                  <tr
                    className={
                      productInfo.state === "ADD" ? "subject_t_add" : null
                    }
                  >
                    <td>
                      <p className="p_list_standard">
                        {productInfo.bidProduct?.name}
                      </p>
                    </td>
                    <td>
                      <p className="p_list_standard">
                        {productInfo.bidProduct?.standard?.replace(
                          /, /gi,
                          "\n"
                        )}
                      </p>
                    </td>
                    <td>{productInfo.bidProduct?.brand}</td>
                    <td>{productInfo.bidProduct?.unit}</td>
                    <td>
                      <div>{curCount?.toLocaleString("ko")}</div>
                      {renderDiff(countDiff)}
                    </td>
                    <td>
                      <div>{curPrice?.toLocaleString("ko")}</div>
                      {renderDiff(priceDiff)}
                    </td>
                    <td>
                      <div>{curTotal?.toLocaleString("ko")}</div>
                      {renderDiff(totalDiff)}
                    </td>
                    <td>{productInfo.bidProduct?.etc || "-"}</td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan="6">총 합계</td>
                <td>
                  <div>
                    <strong>
                      {ordersModifyHistoryList?.totalAmount.toLocaleString(
                        "ko"
                      )}
                    </strong>
                  </div>
                  <strong>{renderDiff(totalAmountDiff)}</strong>
                </td>
                <td>
                  <input type="text" readOnly />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
