import React, { useState } from "react";
import i_close from "../../../asset/images/w-cancel-icon.png";
import i_download from "../../../asset/images/download_icon.png";
import { useLocation } from "react-router-dom";

import {} from "../../../api/auth";
import Loading from "../../etc/Loading";
import { useMediaQuery } from "react-responsive";

import { isMobile, isIOS } from "react-device-detect";
import { orderCheckApi, orderDetailApi } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function GenerateCheckPop({
  setGeneratePop,
  filePath,
  orNo,
  move2Success,
  orStatus,
  orderDetailFnc,
}) {
  const PageName = "검수 확인서";
  const [generating, setGenerating] = useState(false);

  //검수완료
  const orderCheckFnc = async () => {
    setGenerating(true);
    const response = await orderCheckApi(orNo);
    //
    if (response.status === "success") {
      swal({
        title: "검수완료 되었습니다.",
        icon: "success",
      }).then((value) => {
        if (value) {
          setGeneratePop(false);
          setGenerating(false);
          orderDetailFnc();
        }
      });
    } else {
      swal("다시 시도해주세요.", "", "warning");
    }
    setGeneratePop(false);
    setGenerating(false);
  };

  //세부 데이터 불러오기
  // const detailDataFnc = async () => {
  //   const response = await orderDetailApi(orNo);
  //
  //   if (response.status === 'success') {
  //     setpList(response.data.pList);
  //     setUniqueness(response.data.or_uniqueness);
  //     setOrStatus(response.data.or_status);
  //     setOrCompany(response.data.or_company);
  //     setCNo(response.data.c_no);
  //   } else {
  //   }
  // };

  return (
    <>
      <div className="pop">
        <div className="pop_dim" onClick={() => setGeneratePop(false)}></div>
        <div className="contract_terms_pop">
          <div className="close" onClick={() => setGeneratePop(false)}>
            <img src={i_close} alt="" />
          </div>
          <h3 className="pop_tit">검수확인</h3>
          {/* <div>
            {!isMobile ? (
              <>
                <iframe
                  style={{ width: "100%", height: "500px" }}
                  src={filePath+'#navpanes=0&scrollbar=0'}
                ></iframe>
                <div className="pop_download_container">
                  <span>
                    보안 프로그램의 문제로 인해 미리보기가 표시되지 않는 경우,
                    다운로드하여 {PageName}를 확인해주세요.
                  </span>
                  <div
                    className="pop_download_box"
                    onClick={() => {
                      downloadExcel();
                    }}
                  >
                    <span>다운로드</span>
                    <img src={i_download} alt="" />
                  </div>
                </div>
              </>
            )
            :
            (
              <div>
                <iframe
                  style={{ width: "100%", height: "200px" }}
                  src={filePath}
                ></iframe>
                <div className="pop_download_container">
                  <span>
                    보안 프로그램의 문제로 인해 미리보기가 표시되지 않는 경우,
                    다운로드하여 {PageName}를 확인해주세요.
                  </span>
                  <div
                    className="pop_download_box"
                    onClick={() => {
                      downloadExcel();
                    }}
                  >
                    <span>다운로드</span>
                    <img src={i_download} alt="" />
                  </div>
                </div>
                <span className="contract_terms_info">
                  모바일 환경에선 미리보기가 불가합니다.
                  <br />
                  다운로드 받은 계약서를 보고 계약해주세요!
                </span>
              </div>
            )}
          </div> */}
          <div>검수완료 처리하시겠습니까?</div>
          {orStatus === "TESTED" || orStatus === "END" ? (
            <div className="terms_pop_btn btn_box">
              <div className="agree">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setGeneratePop(false);
                  }}
                >
                  확인
                </a>
              </div>
            </div>
          ) : (
            <div className="terms_pop_btn btn_box">
              <div className="cancel">
                <p onClick={() => setGeneratePop(false)}>취소</p>
              </div>
              <div className="agree">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    // contractClearApi();
                    orderCheckFnc();
                  }}
                >
                  검수완료
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      {generating && <Loading msg={"계약 날인중입니다."} />}
    </>
  );
}
