import { useEffect, useState } from "react";
import Mheader from "../../component/common/Mheader";
import { motion, AnimatePresence } from "framer-motion";

// icon
import store_icon from "../../asset/images/excel_store_icon.png";
import pencil_icon from "../../asset/images/excel_pencil_icon.png";
import memo_icon from "../../asset/images/excel_memo_icon.png";
import call_icon from "../../asset/images/excel_call_icon.png";
import clipboard_icon from "../../asset/images/excel_clipboard_icon.png";
import logo_icon from "../../asset/images/gong_logo.png";
import arrow_down from "../../asset/images/arrow_down_icon.png";

import s from "../../asset/css/requestMethodChoice.module.css";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import Modal from "../../component/ui/modal/Modal";
import Typography from "../../component/ui/typography/Typography";
import Button from "../../component/ui/button/Button";

export default function RequestMethodChoice() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);

  const dispatch = useDispatch();
  const redux_attach_excel = useSelector((state) => state.attachExcel);

  const saveReduxMethod = (method) => {
    dispatch(
      actions.setAttachExcel({
        ...redux_attach_excel,
        method: method,
      })
    );
  };

  const handleItemClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
      setHoverIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const handleItemHover = (index) => {
    setHoverIndex(index);
  };

  const handleItemLeave = () => {
    setHoverIndex(null);
  };

  const swalReverse = () => {
    swal({
      title: "주문서 역발행",
      text: `
        1. 고객사가 주문서 발행 요청 (전화, 문자, 메일 등)
        2. 공급사에서 "주문서 생성" 후 품목, 단가, 수량 등 입력
        3. 주문서 발행 > 고객사 확인 > 배송 진행
      `,
      icon: "info",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
            * 주문서 역발행</br>
              급한 건에 대하여 선제적으로 납품 행위를 먼저 하는 방법으로</br>
              빠른 배송을 원하는 상황에 적합합니다.
          `,
        },
      },
    });
  };

  const [reverseModalOpen, setReverseModalOpen] = useState(false);
  const openReverseModal = () => setReverseModalOpen(true);
  const closeReverseModal = () => setReverseModalOpen(false);

  const methods = [
    {
      idx: 1,
      icon: store_icon,
      title: "업체 지정 주문",
      desc: "구매 품목을 작성 후, 원하는 업체를 지정하여 주문합니다.",
      사전확인사항:
        "업체를 지정하여 주문을 진행하기 때문에 사전에 단가 협의가 필요할 수 있습니다.",
      진행과정:
        "품목 및 수량 입력 > 공급사 선택 > 주문 상세정보 입력 > 주문 완료",
      이런분께추천: `1. 기존에 거래하는 공급사에게 제품을 납품 받고 싶은 경우 \n2. 이미 단가가 협의된 제작품이나 품목을 납품 받는 경우`,
      isGoExcelForm: false,
    },
    {
      idx: 2,
      icon: clipboard_icon,
      title: "단가 계약기반 주문",
      desc: "입찰로 선정된 단가 계약을 기반으로 품목을 선택하여 주문합니다.",
      사전확인사항:
        "단가 계약 입찰 공고를 통해 계약이 완료된 경우에만 진행이 가능합니다.",
      진행과정:
        "단가 계약서 선택 > 품목 및 수량 선택 > 주문 상세정보 입력 > 주문 완료",
      이런분께추천: `1. 정해진 기간동안 동일한 공급사에게 동일한 금액으로 제품을 납품 받고 싶은 경우 \n2. 주기적으로 구매해야하는 물품에 대해 간편하게 주문하고 싶은 경우`,
      isGoExcelForm: true,
    },
    {
      idx: 3,
      icon: logo_icon,
      title: "PB상품 주문",
      desc: "공새로 PB상품을 선택하여 주문할 수 있습니다.",
      사전확인사항:
        "공새로에서 출시한 PB상품을 선택하여 주문이 가능합니다. \n다른 품목 구매가 추가로 필요한 경우, 별도의 주문 건으로 진행해야합니다.",
      진행과정: "PB상품 및 수량 선택 > 주문 상세정보 입력 > 주문 완료",
      이런분께추천: "",
      isGoExcelForm: true,
    },
    {
      idx: 4,
      icon: call_icon,
      title: "주문서 역발행 (전화 주문)",
      desc: "공급사에 전화로 주문하고, 공급사가 주문서를 생성합니다.",
      사전확인사항: "",
      진행과정: `고객사가 주문서 발행 요청 (전화, 문자, 메일 등) > 공급사에서 "주문서 생성" 후 품목, 단가, 수량 등 입력 > 주문서 발행 > 고객사 확인 > 배송 진행`,
      이런분께추천:
        "급한 건에 대하여 선제적으로 납품 행위를 먼저 하는 방법으로 빠른 배송을 원하는 상황에 적합합니다.",
      isGoExcelForm: true,
    },
    {
      idx: 5,
      icon: pencil_icon,
      title: "일회성 입찰 공고",
      desc: "일회성 입찰을 통해 최저가 공급사를 선정합니다.",
      사전확인사항:
        "일회성 주문에 대한 입찰 공고를 작성하는 것이며, 입찰 마감 후 최저가 업체를 선정하여 주문할 수 있습니다.",
      진행과정:
        "품목 및 수량 선택 > 입찰 공고 개시 > 입찰 마감 후, 최저가 선정하여 주문",
      이런분께추천: `1. 기준 이상의 단가를 갖는 주문 건에 대하여 입찰을 통해 원가 절감이 필요한 경우 \n2. 납기의 여유가 있는 주문 건의 경우`,
      isGoExcelForm: false,
    },
    {
      idx: 6,
      icon: memo_icon,
      title: "단가 계약 입찰 공고",
      desc: "입찰을 통해 최저가 공급사와 원하는 기간동안 단가 계약을 체결합니다.",
      사전확인사항:
        "특정 공급사와 지정된 기간동안 품목별 단가를 합의하여 계약 및 주문할 수 있습니다.",
      진행과정:
        "품목 및 수량 선택 > 입찰 공고 개시 > 입찰 마감 후, 계약서 작성 및 단가 계약 주문 진행",
      이런분께추천: `1. 정해진 기간동안 동일한 공급사에게 동일한 금액으로 제품을 납품 받고 싶은 경우\n2. 주기적으로 구매해야하는 물품에 대해 간편하게 주문하고 싶은 경우`,
      isGoExcelForm: false,
    },
  ];

  const navigate = useNavigate();
  const handleNextClick = (event, idx) => {
    // 업체지정주문
    if (idx === 1) {
      saveReduxMethod("업체지정주문");
      navigate("/management/bid/request_excel", {
        state: { choiceOrderMethod: "업체지정주문" },
      });
    }
    // 단가 계약기반 주문
    else if (idx === 2) {
      navigate("/management/bid/request_reorder");
    }
    // PB상품 주문
    else if (idx === 3) {
      navigate("/pb/choice");
    }
    // 주문서 역발행 - swal
    else if (idx === 4) {
      openReverseModal();
    }
    // 일회성 입찰 공고
    else if (idx === 5) {
      saveReduxMethod("일회성입찰공고");
      navigate("/management/bid/request_excel", {
        state: { choiceOrderMethod: "일회성입찰공고" },
      });
    }
    // 단가계약 입찰 공고
    else if (idx === 6) {
      saveReduxMethod("단가계약입찰공고");
      navigate("/management/bid/request_excel", {
        state: { choiceOrderMethod: "단가계약입찰공고" },
      });
    }
  };

  const navigateMapFnc = () => {
    navigate("/look");
  };

  return (
    <AnimatePresence>
      <div className="request_container">
        <Mheader title="주문 방식 선택" />
        <div className="top_box">
          <div className="title" onClick={() => setIsOpen(!isOpen)}>
            주문 및 입찰 방법 선택
          </div>
        </div>
        <div className={s.list_container}>
          {methods.map((elem, idx) => {
            return (
              <>
                {elem.idx === 1 && (
                  <span key={`title-${elem.idx}`} className={s.sub_title}>
                    주문하기
                  </span>
                )}
                {elem.idx === 5 && (
                  <span key={`title-${elem.idx}`} className={s.sub_title}>
                    입찰 공고 작성
                  </span>
                )}
                <div
                  key={elem.idx}
                  className={
                    activeIndex === elem.idx
                      ? s.elem_active_container
                      : s.elem_container
                  }
                  onMouseOver={() => handleItemHover(elem.idx)}
                  onMouseLeave={handleItemLeave}
                  onClick={() => handleItemClick(elem.idx)}
                >
                  <div className={s.elem_top_inner}>
                    <div className={s.elem_contents}>
                      <img src={elem.icon} />
                      <div className={s.content_wrapper}>
                        <span className={s.content_title}>{elem.title}</span>
                        <span className={s.content_desc}>{elem.desc}</span>
                      </div>
                    </div>
                    <div
                      className={s.btn_wrapper}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNextClick(e, elem.idx);
                      }}
                    >
                      {elem.isGoExcelForm ? "주문하기" : "내역 작성하기"}
                    </div>
                  </div>
                  <div
                    className={`${s.elem_hover_container} ${
                      hoverIndex === elem.idx && activeIndex !== elem.idx
                        ? s.hover
                        : s.hide
                    }`}
                  >
                    <span>펼쳐서 설명 보기</span>
                    <img src={arrow_down} />
                  </div>
                  {activeIndex === elem.idx && (
                    <motion.section
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { height: "auto", opacity: 1 },
                        collapsed: { height: 0, opacity: 0 },
                      }}
                      className={s.back_before}
                      transition={{
                        duration: 0.3,
                      }}
                    >
                      <motion.div className={s.detail}>
                        <div className={s.detail_inner}>
                          {elem.사전확인사항 && (
                            <>
                              <span className={s.detail_title}>
                                사전 확인 사항
                              </span>
                              <pre className={s.detail_contents}>
                                {elem?.사전확인사항}
                              </pre>
                            </>
                          )}
                          {elem.진행과정 && (
                            <>
                              <span className={s.detail_title}>진행 과정</span>
                              <pre className={s.detail_contents}>
                                {elem?.진행과정}
                              </pre>
                            </>
                          )}
                          {elem.이런분께추천 && (
                            <>
                              <span className={s.detail_title}>
                                이런분께 추천
                              </span>
                              <pre className={s.detail_contents}>
                                {elem?.이런분께추천
                                  .split("\n")
                                  .map((line, index) => (
                                    <span key={index}>
                                      {line}
                                      <br />
                                      <br />
                                    </span>
                                  ))}
                              </pre>
                            </>
                          )}
                        </div>
                      </motion.div>
                    </motion.section>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>

      <Modal
        size="xsmall"
        type="info"
        open={reverseModalOpen}
        title={
          <Typography component="p" type="h2" color="var(--sub-green-10)">
            공고 내용 확인
          </Typography>
        }
        closeIcon={false}
        onOk={reverseModalOpen}
        onCancel={closeReverseModal}
        footer={[
          <Button type="primary" onClick={closeReverseModal}>
            확인
          </Button>,
        ]}
      >
        <div className={s.reverse_inner}>
          <div className={s.reverse_title}>
            <Typography component="p" type="c2">
              주문서 역발행
            </Typography>
            <Typography component="p" type="c2">
              긴급한 주문 건에 대하여 선제적으로 납품을 요청하는 방법입니다.
            </Typography>
          </div>
          <div className={s.reverse_contents}>
            <Typography component="p" type="c2">
              •{" "}
              <Typography
                component="a"
                type="c2"
                color="var(--sub-blue-05)"
                onClick={navigateMapFnc}
              >
                지도 검색
              </Typography>
              을 통해 공급사 검색 페이지로 이동할 수 있습니다.
            </Typography>
          </div>
          <div classname={s.reverse_alert}>
            <Typography component="p" type="h5" color="var(--sub-green-10)">
              원하는 공급사에 연락하여 주문서 역발행을 요청하세요.
            </Typography>
          </div>
        </div>
      </Modal>
    </AnimatePresence>
  );
}
