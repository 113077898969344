import React, { useCallback, useEffect, useState } from "react";
//library
import { useLocation } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

//component
import Mheader from "../../../component/common/Mheader";
import ContractCancelPop from "../../../component/common/Pop/ContractCancelPop";
import ContractStopPop from "../../../component/common/Pop/ContractStopPop";
import BusinessInfoPop from "../../../component/common/Pop/BusinessInfoPop";
import ContractPauseCancel from "../../../component/common/Pop/ContractPauseCancel";
import ContractReasonPop from "../../../component/common/Pop/ContractReasonPop";
import ContractComparePop from "../../../component/common/Pop/ContractComparePop";

import { aliveContractApi, contractDetailAllApi } from "../../../api/auth";
//css
import "../../../asset/css/contractDetail.css";
import * as S from "../../../asset/css/contractDetail.styles.js";
//etc
//sweet alert
import swal from "sweetalert";
import Loading from "../../../component/etc/Loading";
import { useSelector } from "react-redux";

export default function ContractDetail() {
  const params = useParams();
  const contractNo = params.id;
  const navigate = useNavigate();
  const [conInfo, setConInfo] = useState({});

  // 담기
  const [cList, setClist] = useState([]);
  const [publicConInfo, setPublicConInfo] = useState([]);
  const [pListTest, setpListTest] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [firstClist, setFirstClist] = useState([]);
  // test all 꺼내오기
  const contractDetailAllFnc = async () => {
    const res = await contractDetailAllApi(contractNo);
    if (res.message === "success") {
      setClist(res.data.contractList);
      setPublicConInfo(res.data.contractMap);
      setFirstClist(res.data.contractList[0]);

      setCurrentList(res.data.contractList[res.data.contractMap.ct_count]);
      setpListTest(
        res.data.contractList[res.data.contractMap.ct_count].productList
      );
    } else {
      swal("에러가 발생하였습니다.", "", "error");
    }
  };

  // 계약중지요청 팝업
  const [contractPop, setContractPop] = useState(false);

  //계약취소요청 팝업 - 체결중
  const [cancelPop, setCancelPop] = useState(false);

  //중지요청 취소 팝업
  const [pauseCancelPop, setPauseCancelPop] = useState(false);

  //로딩ui
  const [generating, setGenerating] = useState(false);

  // 사업자정보 보기 팝업
  const [bizPop, setBizPop] = useState(false);

  /** 변경계약 관련 */
  const [contractActive, setContractActive] = useState({
    idx: 0,
    ct_no: contractNo,
    ct_parent: "",
    ct_count: "",
  });
  const [comparePop, setComparePop] = useState(false);

  const [contractReasonPop, setContractReasonPop] = useState(false);

  // 전체 리스트
  const [isClick, setIsClick] = useState(false);

  //계약 타입
  const contractType = (data) => {
    if (data === "C") {
      return "단가계약";
    } else if (data === "S") {
      return "단일건계약";
    }
  };

  const contractType2 = (data) => {
    if (data === "C") {
      return "(" + firstClist.bid_term + "개월)";
    } else if (data === "S") {
      return "";
    }
  };

  //계약 상태
  const contractState = (data, check) => {
    if (data === "START") {
      return "체결대기";
    } else if (data === "CANCEL") {
      return "취소";
    } else if (data === "ALIVE" || data === "DISAGREE") {
      if (check === "Y") {
        return "체결중(변경)";
      } else {
        return "진행중";
      }
    } else if (data === "PAUSE") {
      return "변경전(기간안됨)";
    } else if (data === "HOLD") {
      return "중지요청";
    } else if (data === "DEAD") {
      return "계약중지";
    } else if (data === "END" || data === "GRACE") {
      return "만료";
    } else if (data === "REQ") {
      return "계약대기";
    } else if (data === "COMPLETE") {
      return "진행중";
    }
  };

  //계약 상태에 따른 버튼
  const contractBtnFnc = (data) => {
    // ct_sdate는 first것으로 가져와야하고 contract_file은 현재것 가져와야함
    if (
      data === "ALIVE" ||
      data === "COMPLETE" ||
      firstClist?.orderAvailable === true
    ) {
      return (
        <>
          <li
            onClick={() => {
              window.open(currentList.contract_file);
            }}
            style={{ width: "48.865%", minWidth: "150px" }}
          >
            <p>계약서 확인</p>
          </li>
        </>
      );
    } else if (data === "HOLD") {
      return (
        <>
          <li
            onClick={() => {
              setPauseCancelPop(true);
            }}
            style={{ width: "48.865%" }}
          >
            <p>중지 요청 취소</p>
          </li>
          <li
            onClick={() => {
              window.open(currentList.contract_file);
            }}
          >
            <p>계약서 확인</p>
          </li>
        </>
      );
    } else {
      return (
        <li
          onClick={() => {
            window.open(currentList.contract_file);
          }}
        >
          <p>계약서 확인</p>
        </li>
      );
    }
  };

  //하차역무 포함여부
  const bidDrop = (data) => {
    if (data === "L") {
      return "하차역무 있음 ⭕️";
    } else if (data === "U") {
      return "하차역무 없음 ❌";
    } else if (data === "ALL") {
      return "상하차포함";
    }
  };

  //배송비 포함여부
  const bidFee = (data) => {
    if (data === "Y") {
      return "포함";
    } else if (data === "N") {
      return "미포함";
    }
  };

  //금액
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  /* 
    계약 시작일 계산
    금액 합계 계산  
  */

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  //계약 종료일
  const endDateFnc = (databid, bidTerm) => {
    return formatDate(databid.setMonth(databid.getMonth() + Number(bidTerm)));
  };

  useEffect(() => {
    if (contractNo !== undefined) {
      // contractDetailFnc();
      contractDetailAllFnc();
    }
  }, []);

  // this is gogacksa
  useEffect(() => {
    //처음 마운트되었을 때 실행, conInfo가 들어왔을 때
    if (firstClist.ct_check === "Y") {
      //확인누르면 주문서 발행하는 곳으로 이동하기, 취소하면 그냥 최후 탭
      // swal('동의하지 않은 변경계약이 있습니다','동의 후 주문서를 발행해주세요.','warning');
      swal({
        title: "동의하지 않은 변경계약이 있습니다",
        text: "동의 후 주문서를 발행해주세요.",
        icon: "info",
        buttons: true,
        // dangerMode: false,
      }).then((will) => {
        if (will) {
          setContractReasonPop(true);
        } else {
          // swal("취소하셨습니다.");
        }
      });
    }
  }, [conInfo]);

  useEffect(() => {
    setIsClick(true);
    // }
  }, [contractActive]);

  const _move2Success = () => {
    setContractPop(false);
    setPauseCancelPop(false);
    contractDetailAllFnc();
  };

  const hyphen = (phoneNum) => {
    if (phoneNum != undefined)
      return phoneNum.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    return phoneNum;
  };

  const user = useSelector((state) => state.user);

  const fetchContractList = useCallback(async () => {
    const response = await aliveContractApi(user.site_num, 1);

    if (response.status === "success") {
      const filteredContract = response.data.filter(
        (contractList) => contractList.contract.no === Number(contractNo)
      );

      return filteredContract[0]; // 필터링된 계약을 반환
    }
    return []; // 실패 시 빈 배열 반환
  }, [contractNo, user.site_num]);

  const onClickReorder = async () => {
    const fetchedContract = await fetchContractList();
    navigate("/management/bid/request_reorder2", {
      state: {
        detailInfo: fetchedContract,
      },
    });
  };

  return (
    <div className="contract_ok_wrap">
      <Mheader title="공급계약 내역 확인" />
      <div className="contract_ok_container" style={{ paddingTop: "30px" }}>
        <div className="top_pagenation">
          <h2>공급계약 내역 확인</h2>
          <div className="top_btn_wrap">
            {firstClist.ct_state === "ALIVE" && (
              <ul className="top_btn_box">
                <li onClick={onClickReorder}>단가 계약기반 주문</li>
              </ul>
            )}
            <ul className="top_btn_box">
              {contractBtnFnc(firstClist.ct_state)}
            </ul>
          </div>
        </div>
        <div className="contract_ok_info">
          {firstClist.ct_check === "Y" && (
            <div className="change_contract_caution">
              <p className="caution_txt_orange">
                동의하지 않은 계약건이 있습니다.
              </p>
              <div
                className="change_contract_agree"
                onClick={() => {
                  setContractReasonPop(true);
                }}
              >
                확인하기
              </div>
            </div>
          )}
          {firstClist.ct_state === "PAUSE" && (
            <div className="change_contract_caution">
              <p className="caution_txt_orange">
                계약시작일이 되지 않았습니다(시작일 :{" "}
                {cList[firstClist.ct_count * 1 + 1].ct_sdate})
              </p>
              <div
                className="change_contract_agree"
                onClick={() => {
                  setContractReasonPop(true);
                }}
              >
                확인하기
              </div>
            </div>
          )}

          <div className="change_contract_box">
            <div className="change_contract_tab">
              {/* {countList.map((a,i,all)=>{

                if(countList[0].ct_count*1 >= i){
                return(
                <span
                className={i===contractActive?.idx && "active"} 
                onClick={()=>{
                  setContractActive(a);
                }}
                >{a.idx===0?"최초계약":
                  a.idx===(all.length-1) ? 
                  `${i}차 변경계약(최종)` : 
                  `${i}차 변경계약`}
                </span>
              )}}
              )} */}
              {cList.map((a, i, all) => {
                if (firstClist.ct_count * 1 >= i) {
                  return (
                    <span
                      className={currentList === a && "active"}
                      onClick={() => {
                        setCurrentList(a);
                        setpListTest(a.productList);
                      }}
                    >
                      {i === 0
                        ? "최초계약"
                        : i === all.length - 1
                        ? `${i}차 변경계약(최종)`
                        : `${i}차 변경계약`}
                    </span>
                  );
                }
              })}
            </div>

            {publicConInfo.ct_count !== ("0" || undefined) && (
              <div className="change_contract_compare">
                <div
                  className="change_contract_compare_btn"
                  onClick={() => {
                    setComparePop(!comparePop);
                  }}
                >
                  계약 비교하기
                </div>
              </div>
            )}
          </div>

          <div className="contract_content change_contract_content">
            <div className="content_top">
              <span>계약번호 :&nbsp;</span>
              <span className="public_code">{currentList?.ct_code}</span>
            </div>
            <div className="content_box">
              <div className="content_btm">
                <S.Badge firstClist={firstClist}>
                  {firstClist?.ct_state === "START" && "계약 전"}
                  {firstClist?.ct_state === "ALIVE" && "계약완료"}
                  {firstClist?.ct_state === "CANCEL" && "계약취소"}
                  {firstClist?.ct_state === "HOLD" && "중지요청"}
                  {firstClist?.ct_state === "STOP" && "계약중지"}
                  {firstClist?.ct_state === "GRACE" && "연장가능계약"}
                  {firstClist?.ct_state === "END" && "계약종료"}
                </S.Badge>
                <p className="contractor">
                  <span>
                    {contractType(firstClist.bid_type) +
                      contractType2(firstClist.bid_type)}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="quote_content">
            <h3 className="s_tit">
              <p>상세정보</p>
            </h3>
            <div className="quote_list_box">
              <ul className="quote_list">
                <li>
                  <p>고객사</p>
                  <span>
                    [{firstClist.con_name}] {firstClist.s_name}
                  </span>
                </li>
                <li>
                  <p>현장주소</p>
                  <span>
                    {firstClist.bid_addr1} {firstClist.bid_addr2}
                  </span>
                </li>
                <li>
                  <p>공급사</p>
                  <span>{firstClist.c_name}</span>
                </li>
                {firstClist?.bid_type === "C" ? (
                  <li>
                    <p>계약기간</p>
                    <span>
                      {moment(currentList?.ct_sdate).format("yyyy-MM-DD")}
                      &nbsp;~&nbsp;
                      {moment(currentList?.ct_edate).format("yyyy-MM-DD")}
                    </span>
                  </li>
                ) : (
                  <li>
                    <p>납품기한</p>
                    <span>{currentList.bid_deldate}</span>
                  </li>
                )}
                <li>
                  <p>우선사항</p>
                  <span>{firstClist.bid_priority}</span>
                </li>
              </ul>
              <ul className="quote_list">
                <li>
                  <p>배송비포함여부</p>
                  <span>{bidFee(firstClist.bid_fee)}</span>
                </li>
                <li>
                  <p>하차역무포함여부</p>
                  <span>{bidDrop(firstClist.bid_drop)}</span>
                </li>
                <li>
                  <p>결제방법</p>
                  <span>{firstClist.bid_refund}</span>
                </li>
                {firstClist.bid_type === "C" ? (
                  <li>
                    <p>결제주기</p>
                    <span>
                      납품완료일(세금계산서 발행일)로부터{" "}
                      {firstClist.bid_payday}일
                    </span>
                  </li>
                ) : null}
                <li>
                  <p>계약생성일시</p>
                  <span>{currentList.ct_regdate}</span>
                </li>
              </ul>
            </div>
          </div>
          {currentList?.ct_state !== "START" ||
          currentList?.ct_state !== "CANCEL" ? (
            <div className="contract_manager">
              <div className="manager">
                <h3 className="s_tit">
                  <p>고객사 담당자</p>
                </h3>
                <ul className="manager_info">
                  <li>
                    <p>담당자</p>
                    <span>{firstClist?.s_u_info?.u_name}</span>
                  </li>
                  <li>
                    <p>전화번호</p>
                    <span>
                      {/* {siteInfo?.u_phone} */}
                      {hyphen(firstClist?.s_u_info?.u_phone)}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="manager">
                <h3 className="s_tit">
                  <p>공급사 담당자</p>
                </h3>
                <ul className="manager_info">
                  <li>
                    <p>담당자</p>
                    <span>{firstClist?.c_u_info?.u_name}</span>
                  </li>
                  <li>
                    <p>전화번호</p>
                    <span>{hyphen(firstClist?.c_u_info?.u_phone)}</span>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}

          {firstClist?.bid_type === "C" && (
            <div className="contract_date">
              <h3 className="s_tit">
                <p>계약기간</p>
              </h3>
              <ul className="contract_date_ul">
                <li>
                  <input
                    type="text"
                    value={moment(currentList.ct_sdate).format("yyyy-MM-DD")}
                    disabled
                  />
                </li>
                <li>
                  <input
                    type="text"
                    value={endDateFnc(
                      new Date(currentList.ct_sdate),
                      firstClist.bid_term
                    )}
                    disabled
                  />
                </li>
              </ul>
            </div>
          )}

          <div className="conclude_est">
            <h3 className="s_tit">
              <p>품목 내역</p>
            </h3>
            <div className="conclude_table">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <p>품목명</p>
                    </th>
                    <th>
                      <p>규격</p>
                    </th>
                    <th>
                      <p style={{ lineHeight: "1.3" }}>
                        제조국
                        <br />
                        (브랜드)
                      </p>
                    </th>
                    <th>
                      <p>단위</p>
                    </th>
                    <th>
                      <p>수량</p>
                    </th>
                    <th>
                      <p>단가</p>
                    </th>
                    <th>
                      <p>금액</p>
                    </th>
                    <th>
                      <p>비고</p>
                    </th>
                  </tr>
                  {pListTest.map((data, i) => {
                    if (data.od_price !== "0" || data.od_count !== "0") {
                      return (
                        <tr key={data.bp_name}>
                          <td>{data.bp_name}</td>
                          <td>
                            <p className="p_list_standard limit_max_height">
                              {data.bp_standard.replace(/, /gi, "\n")}
                            </p>
                          </td>
                          <td>{data.bp_brand}</td>
                          <td>{data.bp_unit}</td>
                          <td>{data.od_count}</td>

                          <td>{numberWithCommas(data.od_price)}</td>
                          <td>
                            {numberWithCommas(data.od_price * data.od_count)}
                          </td>
                          <td>
                            <span className="limit_max_height">
                              {data.bp_etc}
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  })}
                  <tr>
                    <td colSpan="6">합계</td>
                    <td colSpan="1">
                      <strong>
                        {numberWithCommas(
                          pListTest
                            .map((data) => data.od_count * data.od_price)
                            .reduce((sum, current) => sum + current, 0)
                        )}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="uniqueness_box">
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>
                  <span>{firstClist.c_name}</span>특이사항
                </p>
              </div>
              <div className="uniqueness_content">
                <p>{firstClist?.o_comment}</p>
              </div>
            </div>
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>
                  <span>계약 관련</span>
                  <span>추가 내용</span>
                </p>
              </div>
              <div className="uniqueness_content contract_additions">
                <p>{currentList.ct_comment}</p>
              </div>
            </div>
          </div>
          <div className="contract_ok_pay">
            <h3 className="s_tit">
              <p>지급방법</p>
            </h3>
            <p>{firstClist.bid_refund}</p>
          </div>
          {firstClist.ct_state !== "START" ? (
            <>
              <div className="look_info payment_account">
                <h3 className="s_tit">
                  <p>지급계좌 정보</p>
                </h3>
                <p
                  onClick={() => {
                    window.open(firstClist.f_root);
                  }}
                >
                  통장사본 보기
                </p>
              </div>
              <div className="look_info tax_bill">
                <h3 className="s_tit">
                  <p>세금계산서 발행 정보</p>
                </h3>
                <p
                  onClick={() => {
                    // setBizPop(true);
                    swal(
                      "본 건은 공새로 위임정산 건으로, 공새로에 문의하시기 바랍니다. (고객센터: 032-205-0479)",
                      "",
                      "info"
                    );
                  }}
                >
                  사업자정보 보기
                </p>
              </div>
            </>
          ) : null}
          {firstClist.ct_state === "ALIVE" ? (
            <p className="caution_txt">
              *고객사 및 공급사의 일방적인 계약중지에 따른 패널티가 발생할 수
              있습니다. 계약취소 및 중단 시 상호간의 협의후 진행해주세요.
              <span
                onClick={() => setContractPop(true)}
                className="contract_pause_request"
              >
                계약 중지 요청
              </span>
            </p>
          ) : null}
          {firstClist.ct_state === "START" ? (
            <p className="caution_txt">
              *고객사 및 공급사의 일방적인 계약중지에 따른 패널티가 발생할 수
              있습니다. 계약취소 및 중단 시 상호간의 협의후 진행해주세요.
              <span
                onClick={() => setCancelPop(true)}
                className="contract_pause_request"
              >
                계약 취소 요청
              </span>
            </p>
          ) : null}
        </div>
      </div>
      {cancelPop && ( // 계약 취소요청 - 체결중일 때만 사용가능
        <ContractCancelPop
          setCancelPop={setCancelPop}
          // contractList={contractData}
          contractNum={contractNo}
        />
      )}
      {contractPop && (
        <ContractStopPop
          setContractPop={setContractPop}
          state="중지"
          move2Success={_move2Success}
          // contractList={contractData}
          contractNum={contractNo}
        />
      )}
      {bizPop && <BusinessInfoPop setBizPop={setBizPop} />}

      {pauseCancelPop && (
        <ContractPauseCancel
          move2Success={_move2Success}
          setPauseCancelPop={setPauseCancelPop}
          contractNum={contractNo}
        />
      )}
      {contractReasonPop && (
        <ContractReasonPop
          move2Success={_move2Success}
          setContractReasonPop={setContractReasonPop}
          currentList={cList[firstClist.ct_count * 1 + 1]} //현재 ct_count 다음 것 선택
          isPause={firstClist.ct_state}
        />
      )}
      {comparePop && (
        <ContractComparePop
          setComparePop={setComparePop}
          compareList={cList}
          currentList={currentList}
          conActive={contractActive}
        />
      )}
      {generating && <Loading />}
    </div>
  );
}
