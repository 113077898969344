import Input from "../../../component/ui/input/Input";
import Typography from "../../../component/ui/typography/Typography";
import s from "./inputTest.module.css";
import ClearSVG from "../../../asset/images/svg/ClearSVG";
import { COLOR } from "../../../constant/Palette";

export default function InputTest() {
  return (
    <div className={s.input_group_container}>
      <Typography component="p" type="h1">
        INPUT SIZE TEST
      </Typography>
      <div>
        <Input size="small" width={120} />
        <Input size="medium" width={140} />
        <Input size="large" width={160} />
      </div>

      <Typography component="p" type="h1">
        INPUT LABEL TEST
      </Typography>
      <div>
        <Input
          size="small"
          width={120}
          id="input_label_small"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
        />
        <Input
          size="medium"
          width={140}
          id="input_label_medium"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
        />
        <Input
          size="large"
          width={160}
          id="input_label_large"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
        />
      </div>

      <Typography component="p" type="h1">
        INPUT ERROR TEST
      </Typography>
      <div>
        <Input
          size="small"
          width={120}
          id="input_error_small"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          status="error"
        />
        <Input
          size="medium"
          width={140}
          id="input_error_medium"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          status="error"
        />
        <Input
          size="large"
          width={160}
          id="input_error_large"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          status="error"
        />
      </div>

      <Typography component="p" type="h1">
        INPUT HELP TEXT TEST
      </Typography>
      <div>
        <Input
          size="small"
          width={120}
          id="input_help_text_small"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          helpText={
            <Typography component="p" type="c5" color={COLOR.GRAY.L05}>
              help text
            </Typography>
          }
        />
        <Input
          size="medium"
          width={140}
          id="input_help_text_medium"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          helpText={
            <Typography component="p" type="c5" color={COLOR.GRAY.L05}>
              help text
            </Typography>
          }
        />
        <Input
          size="large"
          width={160}
          id="input_help_text_large"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          helpText={
            <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
              help text
            </Typography>
          }
        />
      </div>

      <Typography component="p" type="h1">
        INPUT REQUIRED TEST
      </Typography>
      <div>
        <Input
          size="small"
          width={120}
          id="input_required_small"
          label={
            <Typography component="span" type="c3">
              label
            </Typography>
          }
          required={
            <Typography component="span" type="c3" color={COLOR.SUB.RED.L10}>
              *
            </Typography>
          }
        />
        <Input
          size="medium"
          width={140}
          id="input_required_medium"
          label={
            <Typography component="span" type="c3">
              label
            </Typography>
          }
          required={
            <Typography component="span" type="c3" color={COLOR.SUB.RED.L10}>
              (필수)
            </Typography>
          }
        />
        <Input
          size="large"
          width={160}
          id="input_required_large"
          label={
            <Typography component="span" type="c3">
              label
            </Typography>
          }
          required={
            <Typography component="span" type="c3" color={COLOR.SUB.RED.L10}>
              (필수 입력)
            </Typography>
          }
        />
      </div>

      <Typography component="p" type="h1">
        INPUT DISABLED TEST
      </Typography>
      <div>
        <Input
          size="small"
          width={120}
          id="input_disabled_small"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          disabled
        />
        <Input
          size="medium"
          width={140}
          id="input_disabled_medium"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          disabled
        />
        <Input
          size="large"
          width={160}
          id="input_disabled_large"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          disabled
        />
      </div>

      <Typography component="p" type="h1">
        INPUT PREFIX TEST
      </Typography>
      <div>
        <Input
          size="small"
          width={120}
          id="input_prefix_small"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          prefix={<ClearSVG size="16" />}
          onClickPrefix={() => console.log("prefix small clicked!")}
        />
        <Input
          size="medium"
          width={140}
          id="input_prefix_medium"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          prefix={<ClearSVG size="16" />}
          onClickPrefix={() => console.log("prefix medium clicked!")}
        />
        <Input
          size="large"
          width={160}
          id="input_prefix_large"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          prefix={<ClearSVG size="16" />}
          onClickPrefix={() => console.log("prefix large clicked!")}
        />
      </div>

      <Typography component="p" type="h1">
        INPUT SUFFIX TEST
      </Typography>
      <div>
        <Input
          size="small"
          width={120}
          id="input_suffix_small"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          suffix={<ClearSVG size="16" />}
          onClickSuffix={() => console.log("suffix small clicked!")}
        />
        <Input
          size="medium"
          width={140}
          id="input_suffix_medium"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          suffix={<ClearSVG size="16" />}
          onClickSuffix={() => console.log("suffix medium clicked!")}
        />
        <Input
          size="large"
          width={160}
          id="input_suffix_large"
          label={
            <Typography component="p" type="c3">
              label
            </Typography>
          }
          suffix={<ClearSVG size="16" />}
          onClickSuffix={() => console.log("suffix large clicked!")}
        />
      </div>
    </div>
  );
}
