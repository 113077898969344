import Button from "../../../../../../component/ui/button/Button";

const LoginButton = ({ handleLogin }) => {
  return (
    <Button size="large" type="primary" onClick={handleLogin}>
      로그인
    </Button>
  );
};

export default LoginButton;
