import React from "react";
import Input from "../../../../../../component/ui/input/Input";
import SearchSVG from "../../../../../../asset/images/svg/SearchSVG";

const OrgSearchInput = ({
  keyword,
  setKeyword,
  handleSearch,
  setSelectedOrg,
}) => {
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
      setSelectedOrg(null);
    }
  };

  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const onClickSuffix = () => {
    handleSearch();
    setSelectedOrg(null);
  };

  return (
    <Input
      size="large"
      width="100%"
      id="org_search"
      placeholder="고객사명을 검색해주세요."
      value={keyword}
      onChange={onChangeKeyword}
      suffix={<SearchSVG />}
      onClickSuffix={onClickSuffix}
      onKeyDown={onKeyDown}
    />
  );
};

export default OrgSearchInput;
