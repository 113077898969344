import React from "react";
import classNames from "classnames/bind";
import styles from "./input.module.css";
import { Input as AntdInput } from "antd";

const cx = classNames.bind(styles);

const Input = React.forwardRef(
  (
    {
      size = "small",
      width,
      type = "text",
      id = "",
      prefix = null,
      onClickPrefix,
      suffix = null,
      onClickSuffix,
      placeholder = "",
      label = "",
      disabled = false,
      status = "",
      helpText = "",
      onChange,
      ...props
    },
    ref
  ) => {
    const className = cx("common", {
      [size]: true,
    });

    return (
      <div className={cx("container")}>
        {label && <label htmlFor={id}>{label}</label>}
        <div>
          <AntdInput
            ref={ref}
            className={className}
            id={id}
            prefix={
              <span className={cx("icon_container")} onClick={onClickPrefix}>
                {prefix}
              </span>
            }
            suffix={
              <span className={cx("icon_container")} onClick={onClickSuffix}>
                {suffix}
              </span>
            }
            placeholder={placeholder}
            size={size}
            type={type}
            disabled={disabled}
            status={status}
            onChange={onChange}
            style={{ width: width }}
            {...props}
          />
        </div>
        {helpText && <div>{helpText}</div>}
      </div>
    );
  }
);

export default Input;
