import React from "react";
import Button from "../../../../../../component/ui/button/Button";

const CreateButton = ({ state }) => {
  return (
    <Button type="primary" size="medium" htmlType="submit">
      {state?.isEdit ? "수정하기" : "등록하기"}
    </Button>
  );
};

export default CreateButton;
