import React, { useState, useEffect } from "react";
//library
import { useNavigate, useLocation } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/requestSelfChoice.css";

//api
import { estimateClearApi, estimateTimeOverApi } from "../../api/auth";

//redux
import { useSelector } from "react-redux";
//loading
import Loading from "../../component/etc/Loading";

//sweet alert
import swal from "sweetalert";

export default function RequestSelfChoice() {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();

  const { cart, bid_no } = location.state;

  const [completeData, setCompleteData] = useState({
    pList: [],
    bid_comment: "",
    bid_no: bid_no,
  });

  const [generating, setGenerating] = useState(false); //loading

  //견적요청 완료하기
  const completeDataFnc = async () => {
    setGenerating(true);
    const res = await estimateTimeOverApi(bid_no);
    if (res.status === "fail") {
      swal("마감시간이 지났습니다. 다시 투찰해 주세요!", "", "warning");
      navigate("/management/request");

      return false;
    }
    const comeData = completeData.pList;
    const finalData = comeData.map((item) => ({
      bp_bid_no: bid_no,
      bp_p_no: item.p_no,
      bp_name: item.p_name,
      bp_standard: item.p_standard,
      bp_unit: item.p_unit,
      bp_count: item.cartAmount,
      bp_etc: item.bp_etc ? item.bp_etc : "",
    }));

    const response = await estimateClearApi(
      finalData,
      completeData.bid_comment,
      completeData.bid_no,
      userData.site_num
    );

    if (response.status === "success") {
      navigate("/management/bid/request_complete", { replace: true });
    } else {
      swal("다시 시도해주세요", "", "warning");
    }
    setGenerating(false);
  };

  useEffect(() => {
    setCompleteData({ ...completeData, pList: cart });
  }, []);

  return (
    <div className="request_self_choice_wrap">
      <Mheader title="견적 / 계약 / 주문" />
      <div className="request_self_choice_container">
        <div className="top_pagenation">
          <h2>품목 직접 선택</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p>2</p>
            <p>3</p>
            <p className="active">4</p>
            <p>5</p>
          </div>
        </div>
        <div className="self_choice_top">
          <p>
            * 비고란에 추가 내용을 입력하여 정확한 품목을 요청해보세요
            <br />
            <br />* 각 항목의 수량 및 비고사항을 수정 가능합니다.
          </p>
          {/* <div className="btn_modify">품목 수정</div> */}
        </div>
        <div className="subject_table">
          <table>
            <thead>
              <tr>
                <th>
                  <p>품목명</p>
                </th>
                <th>
                  <p>규격</p>
                </th>
                <th>
                  <p>단위</p>
                </th>
                <th>
                  <p>수량</p>
                </th>
                <th>
                  <p>비고</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {completeData.pList.map((data, i) => {
                return (
                  <tr key={data.p_name}>
                    <td>{data.p_name}</td>
                    <td>
                      <textarea
                        style={{
                          width: "100%",
                          border: "none",
                          minHeight: "110px",
                        }}
                        placeholder="규격을 입력하세요"
                        defaultValue={data.p_standard.replace(/, /gi, "\n")}
                        className="no_arrow_input"
                        onChange={(e) => {
                          let tempList = completeData.pList;
                          tempList[i].p_standard = e.target.value;
                          setCompleteData({ ...completeData, pList: tempList });
                        }}
                      />
                    </td>
                    <td>{data.p_unit}</td>
                    <td>
                      <input
                        type="number"
                        min={1}
                        placeholder="1"
                        defaultValue={completeData.pList[i].cartAmount}
                        className="no_arrow_input"
                        onChange={(e) => {
                          let tempList = completeData.pList;
                          tempList[i].cartAmount = e.target.value;
                          setCompleteData({ ...completeData, pList: tempList });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        defaultValue={""}
                        onChange={(e) => {
                          let tempList = completeData.pList;
                          tempList[i].bp_etc = e.target.value;
                          setCompleteData({ ...completeData, pList: tempList });
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="add_write_wrap">
          <p>견적 관련 추가 설명</p>
          <textarea
            placeholder="견적 관련 추가 설명이 있으시다면 작성해주세요."
            spellcheck="false"
            onChange={(e) => {
              setCompleteData({ ...completeData, bid_comment: e.target.value });
            }}
          ></textarea>
        </div>
        <div className="btn_box">
          <div
            className="prev"
            onClick={() => {
              navigate(-1);
            }}
          >
            <p>이전</p>
          </div>
          <div
            className="next"
            onClick={() => {
              completeDataFnc();
              // alreadyPerform();
            }}
          >
            <p>다음</p>
          </div>
        </div>
      </div>
      {generating && <Loading msg={"견적 요청중입니다."} />}
    </div>
  );
}
