import { z } from "zod";

export const updateRankSchema = z
  .object({
    select_rank: z.string().min(1, { message: "직급을 선택해주세요." }),
    input_rank: z.string().optional(),
  })
  .refine(
    (data) => data.select_rank !== "직접 입력" || data.input_rank?.length > 0,
    {
      message: "직급을 입력해주세요.",
      path: ["input_rank"],
    }
  );
