export default function WarningSVG({ size = "small", stroke = "#C97900" }) {
  if (size === "xsmall") size = "16";
  if (size === "small") size = "20";
  if (size === "medium") size = "24";
  if (size === "large") size = "32";
  if (size === "xlarge") size = "40";

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 35C21.9698 35 23.9204 34.612 25.7403 33.8582C27.5601 33.1044 29.2137 31.9995 30.6066 30.6066C31.9995 29.2137 33.1044 27.5601 33.8582 25.7403C34.612 23.9204 35 21.9698 35 20C35 18.0302 34.612 16.0796 33.8582 14.2597C33.1044 12.4399 31.9995 10.7863 30.6066 9.3934C29.2137 8.00052 27.5601 6.89563 25.7403 6.14181C23.9204 5.38799 21.9698 5 20 5C16.0218 5 12.2064 6.58035 9.3934 9.3934C6.58035 12.2064 5 16.0218 5 20C5 23.9782 6.58035 27.7936 9.3934 30.6066C12.2064 33.4196 16.0218 35 20 35Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 12.5V20.625"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 29.375C20.663 29.375 21.2989 29.1116 21.7678 28.6428C22.2366 28.1739 22.5 27.538 22.5 26.875C22.5 26.212 22.2366 25.5761 21.7678 25.1072C21.2989 24.6384 20.663 24.375 20 24.375C19.337 24.375 18.7011 24.6384 18.2322 25.1072C17.7634 25.5761 17.5 26.212 17.5 26.875C17.5 27.538 17.7634 28.1739 18.2322 28.6428C18.7011 29.1116 19.337 29.375 20 29.375Z"
        fill={stroke}
      />
    </svg>
  );
}
