import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signupApi } from "../../api/auth";
import { QUERY_KEYS } from "../../constant/QueryKeys";

const useSignup = (onSuccessCb, onErrorCb) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: signupApi,
    onSuccess: (res) => {
      onSuccessCb(res);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SIGNUP] });
    },
    onError: (error) => {
      onErrorCb(error);
    },
  });
};

export default useSignup;
