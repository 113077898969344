import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
//css
import "../../asset/css/common.css";

//img

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default";

//header 내용
import { sideList, subcSideList } from "../../asset/js/dummy";

//lib
import swal from "sweetalert";

import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { roundToNearestMinutes } from "date-fns";
import { P } from "@antv/g2plot";
import Modal from "../ui/modal/Modal";
import Typography from "../ui/typography/Typography";
import Button from "../ui/button/Button";

export default function Header() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const { signToken } = useSelector((state) => ({
    signToken: state.token,
  }));

  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  const [activeMenu, setActiveMenu] = useState({ id: 0, active: false });
  const [nowUrl, setNowUrl] = useState(pathname);
  const [navList, setNavList] = useState();

  //nav 이벤트
  const activeFunction = (location) => {
    const activeList = sideList.map((item) =>
      item.uri.indexOf(location.split("/")[1]) > 0
        ? { ...item, active: true }
        : { ...item }
    );
    setNavList(activeList);
    const filterList = activeList.filter((item) => item.active);
    if (filterList.length > 0) {
      setActiveMenu(filterList[0]);
    }
    setNowUrl(location);
  };

  const logoutFnc = async () => {
    const willDelete = await swal({
      title: "LOGOUT",
      text: "로그아웃 하시겠습니까?",
      icon: "info",
      buttons: true,
    });

    if (willDelete) {
      await dispatch(actions.setToken(false));
      await dispatch(actions.setUser(userDefault));

      navigate("/login");
    } else {
      swal("취소하셨습니다.");
    }
  };

  useEffect(() => {
    if (pathname === "/") {
      setActiveMenu({ ...activeMenu, id: 0 });
    }
    activeFunction(pathname);
    // setNaviSwitch(false);
  }, [pathname]);

  useEffect(() => {
    setNowUrl(pathname);
  }, []);

  const activeTabFnc = (elem) => {
    const pattern =
      /(estimate|contract|management\/order|management\/pb\/order|invoice|qna|all_history)/i;

    const el = elem.match(pattern);
    const now = nowUrl.match(pattern);

    const filter_elem = el ? el[0] : null;
    const filter_now = now ? now[0] : null;

    if (filter_elem === filter_now) {
      return true;
    } else {
      return false;
    }
  };

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    // deferredPrompt = e;
    setDeferredPrompt(e);
  });

  const appDownloadFnc = async () => {
    if (!deferredPrompt) {
      swal("이미 앱이 설치되어 있거나 앱을 설치할 수 없는 환경입니다");
      return;
    }

    const result = await deferredPrompt.prompt();
    if (result.outcome === "accepted") {
      setDeferredPrompt(null); // 프롬프트 완료 후 초기화
      //swal 끄기,
      closeAppDownloadModal();
    } else {
      swal(
        "설치 실패",
        "바로가기를 설치하지 못했습니다. \n우측상단 설치버튼을 눌러 설치를 완료해주세요.",
        "error"
      );
    }
  };
  const [appDownloadOpen, setAppDownloadOpen] = useState(false);
  const openAppDownloadModal = () => setAppDownloadOpen(true);
  const closeAppDownloadModal = () => setAppDownloadOpen(false);

  const title = !deferredPrompt ? (
    <Typography component="p" type="h4" color="var(--sub-red-10)">
      바탕화면에 바로가기를 추가할 수 없습니다.
    </Typography>
  ) : (
    <Typography component="p" type="h4" color="var(--sub-green-10)">
      바탕화면에 바로가기 앱을 추가하시겠습니까?
    </Typography>
  );

  const footerButtons = !deferredPrompt ? (
    <Button type="danger" onClick={closeAppDownloadModal}>
      닫기
    </Button>
  ) : (
    <>
      <Button type="danger" onClick={closeAppDownloadModal}>
        취소
      </Button>
      <Button type="primary" onClick={appDownloadFnc}>
        확인
      </Button>
    </>
  );

  const content = !deferredPrompt ? (
    <Typography component="p" type="c2">
      이미 앱을 설치하였거나 앱을 설치할 수 없는 환경입니다.
    </Typography>
  ) : (
    <Typography component="p" type="c2">
      확인을 누르면 바로가기 앱이 바탕화면에 설치됩니다.
    </Typography>
  );

  return signToken && !isResponsive ? (
    <>
      <div className="empty_section"></div>
      <div className="side_wrap_first">
        <div className="side_wrap">
          <div
            className="header_left side_top"
            onClick={() => {
              navigate("/");
            }}
          >
            <h1>{/* 로고이미지 */}</h1>
            <p>
              {userData.constructionType !== "SUBCONTRACTOR"
                ? "고객사"
                : "협력사"}
            </p>
          </div>
          <div className="side_inner">
            <div className="side_main_inner">
              {/* {userData.constructionType !== "SUBCONTRACTOR" ? ( */}
              <div className="side_main_inner_button_wrap">
                <button
                  className="estimate_request_btn"
                  onClick={() => {
                    // navigate("/management/request_order");
                    navigate("/management/bid/request_choice");
                  }}
                >
                  {userData.constructionType === "SUBCONTRACTOR" ? (
                    <div className="side_subcontractor_box">
                      <span className="side_subcontractor_pb_badge">PB</span>
                      &nbsp;주문하기
                    </div>
                  ) : (
                    "주문 및 입찰"
                  )}
                </button>
              </div>

              {/* {sideList.map((data, idx)=>{
                  if(data.id === 1){
                    
                  }
                  return(
                    <div className={nowUrl.includes(data.uri) ? "side_elem": "side_elem_off"} key={idx}>
                          <Link 
                              className={nowUrl.includes(data.uri) ? "main_elem": 'main_elem_off'}
                              to={data.uri}
                              onClick={() => {
                                  setActiveMenu({ ...data, active: true });
                                  setNowUrl(data.uri);
                              }}
                          >
                              <span>{data.mainNav}</span>
                          </Link>
                    </div>
                  )
                  })} */}

              {/* userData.constructionType !== "SUBCONTRACTOR" ? 
                  
                  
                  
                  : (
                  <div className="side_elem_off">
                    <Link
                      className="main_elem_off"
                      to={"/management/pb/order/1"}
                    >
                      <OrderIcon />
                      <span>PB상품 주문관리</span>
                    </Link>
                  </div>
                )*/}
              <div className="side_navi_box">
                {userData.constructionType !== "SUBCONTRACTOR" &&
                  sideList.map(
                    ({ id, uri, mainNav, isSubcontractor, Icon }) => {
                      return (
                        <div
                          className={
                            activeTabFnc(uri) ? "side_elem" : "side_elem_off"
                          }
                        >
                          <Link
                            className={
                              activeTabFnc(uri) ? "main_elem" : "main_elem_off"
                            }
                            to={uri}
                            onClick={() => {
                              setActiveMenu({ active: true });
                              setNowUrl(uri);
                            }}
                          >
                            <Icon />
                            <span>{mainNav}</span>
                            {/* {IsNew && (
                          <span className="side_elem_new_box">
                            <IsNew className="side_elem_new" />
                          </span>
                        )} */}
                            {userData.constructionType === "SUBCONTRACTOR" &&
                              isSubcontractor && (
                                <span className="side_elem_subcontractor">
                                  건설사용
                                </span>
                              )}
                          </Link>
                        </div>
                      );
                    }
                  )}

                {userData.constructionType === "SUBCONTRACTOR" &&
                  subcSideList.map(({ id, uri, mainNav, isSubcontractor }) => {
                    return (
                      <div
                        className={
                          activeTabFnc(uri) ? "side_elem" : "side_elem_off"
                        }
                      >
                        <Link
                          className={
                            activeTabFnc(uri) ? "main_elem" : "main_elem_off"
                          }
                          to={uri}
                          onClick={() => {
                            setActiveMenu({ active: true });
                            setNowUrl(uri);
                          }}
                        >
                          <span>{mainNav}</span>
                          {/* {IsNew && (
                          <span className="side_elem_new_box">
                            <IsNew className="side_elem_new" />
                          </span>
                        )} */}
                          {userData.constructionType === "SUBCONTRACTOR" &&
                            isSubcontractor && (
                              <span className="side_elem_subcontractor">
                                건설사용
                              </span>
                            )}
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="side_footer_container">
              <div className="side_line"></div>

              <div className="side_footer_inner">
                <Link to="/look" className="side_footer">
                  <span>인근공급사찾기</span>
                </Link>

                <Link to="/my_page/notice" className="side_footer">
                  <span>공지사항</span>
                </Link>
                <Link
                  to=""
                  className="side_footer"
                  onClick={(e) => {
                    e.preventDefault();
                    swal(
                      "문의",
                      "문의사항은 032-205-0479로 연락주시길 바랍니다.\n 친절히 답변드리겠습니다.",
                      "info"
                    );
                  }}
                >
                  {/* <img src={footerIcon3}/> */}
                  <span>FAQ / 고객문의</span>
                </Link>
                <Link to="" className="side_footer">
                  <span onClick={openAppDownloadModal}>
                    바탕화면에 바로가기 추가
                  </span>
                </Link>
                <div className="side_footer">
                  <span onClick={logoutFnc}>로그아웃</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="xsmall"
        type={!deferredPrompt ? "error" : "info"}
        open={appDownloadOpen}
        title={title}
        closeIcon={false}
        onOk={closeAppDownloadModal}
        onCancel={closeAppDownloadModal}
        footer={footerButtons}
      >
        {content}
      </Modal>
    </>
  ) : null;
}

// export default function SideBar() {
//     const employees = [
//       {id: 1, name: 'Alice', tasks: ['dev', 'test', 'ship']},
//       {id: 2, name: 'Bob', tasks: ['design', 'test']},
//       {id: 3, name: 'Carl', tasks: ['test']},
//     ];

//     return (
//       <div>
//         {employees.map((employee, index) => (
//           <div key={index} style={{marginTop:'100px'}}>
//             <h2>Name: {employee.name}</h2>

//             {employee.tasks.map((task, index) => (
//               <div key={index}>
//                 <h2>Task: {task}</h2>
//               </div>
//             ))}

//             <hr />
//           </div>
//         ))}
//       </div>
//     );
//   }
