import { useState } from "react";
import s from "../../../asset/css/deliveryDonePop.module.css";
import close_icon from "../../../asset/images/cancel-btn.png";
import swal from "sweetalert";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import moment from "moment";
import { deliveryDoneApi } from "../../../api/auth";
import Loading from "../../etc/Loading";

export default function DeliveryDonePop(props) {
  const { orderNo, setDeliveryDonePop, orderDetailFnc } = props;
  const [loading, setLoading] = useState(false);
  const [deliveryDoneTime, setDeliveryDoneTime] = useState("");

  const deliveryDone = async () => {
    if (!deliveryDoneTime) {
      swal("배송 완료 시간을 선택해주세요.", "", "warning");
      return;
    }

    setLoading(true);
    const formatDate = moment(deliveryDoneTime).format("yyyy-MM-DD HH:mm:ss");

    try {
      const response = await deliveryDoneApi(orderNo, formatDate);

      if (response.status === "success") {
        swal({
          title: "배송완료 처리되었습니다.",
          icon: "success",
        }).then((value) => {
          if (value) {
            orderDetailFnc();
            setDeliveryDonePop(false);
          }
        });
      } else {
        swal("배송완료에 실패하였습니다.", "", "warning");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={s.background}>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.title}>배송완료</div>
          <div
            className="bid_status_modal_close"
            onClick={() => setDeliveryDonePop(false)}
          >
            <img src={close_icon} alt="close_icon" />
          </div>
        </div>

        <div className={s.body}>
          <div>배송 완료 시간을 입력해주세요.</div>
        </div>

        <div className={s.date_container}>
          <ReactDatePicker
            selected={deliveryDoneTime}
            onChange={(date) => setDeliveryDoneTime(date)}
            selectsStart
            showTimeSelect
            dateFormat="yyyy-MM-dd h:mm aa"
            locale={ko}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>

        <div className={s.footer}>
          <button onClick={() => setDeliveryDonePop(false)}>취소</button>
          <button onClick={deliveryDone}>확인</button>
        </div>
      </div>
      {loading && <Loading msg={"로딩중 입니다."} />}
    </div>
  );
}
