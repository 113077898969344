import useCreateSiteHandler from "../../../hooks/handler/useCreateSiteHandler";
import { useNavigate } from "react-router-dom";

import Mheader from "../../../component/common/Mheader";
import Title from "./components/elements/title/Title";
import NameInput from "./components/elements/input/NameInput";
import AddrInput from "./components/elements/input/AddrInput";
import AddrDetailInput from "./components/elements/input/AddrDetailInput";
import AddrSearchButton from "./components/elements/button/AddrSearchButton";
import TypeSelect from "./components/elements/select/TypeSelect";
import ScaleInput from "./components/elements/input/ScaleInput";
import ProcessSelect from "./components/elements/select/ProcessSelect";
import PhoneInput from "./components/elements/input/PhoneInput";
import CancelButton from "./components/elements/button/CancelButton";
import CreateButton from "./components/elements/button/CreateButton";
import AddrSearchModal from "./components/modal/addr-search/AddrSearchModal";
import SiteCreateModal from "./components/modal/site-create/SiteCreateModal";
import StartDatePicker from "./components/elements/date-picker/StartDatePicker";
import EndDatePicker from "./components/elements/date-picker/EndDatePicker";

import s from "./createSite.module.css";

const CreateSite = () => {
  const navigate = useNavigate();
  const {
    state,

    handleSubmit,
    control,
    errors,
    setValue,
    clearErrors,

    onClickSubmit,
    handleCreateSite,
    handleUpdateSite,

    addrSearchModal,
    setAddrSearchModal,
    siteCreateModal,
    setSiteCreateModal,
  } = useCreateSiteHandler();

  return (
    <div className={s.page_container}>
      <Mheader title="신규 현장 등록하기" />

      <section className={s.title_section}>
        <Title state={state} />
      </section>

      <form className={s.form_container} onSubmit={handleSubmit(onClickSubmit)}>
        <section className={s.input_section}>
          <NameInput control={control} errors={errors} />

          <div className={s.addr_container}>
            <div className={s.input_with_button_container}>
              <AddrInput control={control} errors={errors} />
              <AddrSearchButton
                setAddrSearchModal={setAddrSearchModal}
                clearErrors={clearErrors}
              />
            </div>
            <AddrDetailInput control={control} errors={errors} />
          </div>

          <TypeSelect control={control} errors={errors} />
          <ScaleInput control={control} errors={errors} />
          <ProcessSelect control={control} errors={errors} />
          <PhoneInput control={control} errors={errors} />
          <StartDatePicker control={control} errors={errors} />
          <EndDatePicker control={control} errors={errors} />
        </section>

        <section className={s.button_container}>
          <CancelButton navigate={navigate} />
          <CreateButton state={state} />
        </section>
      </form>

      {addrSearchModal && (
        <AddrSearchModal
          addrSearchModal={addrSearchModal}
          setAddrSearchModal={setAddrSearchModal}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      )}
      {siteCreateModal && (
        <SiteCreateModal
          siteCreateModal={siteCreateModal}
          setSiteCreateModal={setSiteCreateModal}
          handleCreateSite={handleCreateSite}
          handleUpdateSite={handleUpdateSite}
          state={state}
        />
      )}
    </div>
  );
};

export default CreateSite;
