import { z } from "zod";

export const signupSchema = z
  .object({
    email: z
      .string()
      .min(1, { message: "이메일은 필수 항목입니다." })
      .email({ message: "올바른 이메일을 입력해주세요." }),
    password: z
      .string()
      .min(8)
      .max(15)
      .regex(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/),
    password_check: z
      .string()
      .min(1, { message: "비밀번호가 일치하지 않습니다." }),
    name: z
      .string()
      .min(1, { message: "이름은 필수 항목입니다." })
      .max(50, { message: "50자 이내로 작성해주세요." }),
    org_name: z.string().min(1, { message: "회사명은 필수 항목입니다." }),
    dept: z
      .string()
      .max(50, { message: "50자 이내로 작성해주세요." })
      .optional(),
    select_rank: z.string().optional(),
    input_rank: z
      .string()
      .max(50, { message: "50자 이내로 작성해주세요." })
      .optional(),
    phone: z
      .string()
      .min(1, { message: "휴대폰 번호는 필수 항목입니다." })
      .regex(/^\d{3}-\d{3,4}-\d{4}$|^\d{10,11}$/, {
        message: "올바른 휴대폰 번호를 입력해주세요. (10~11자리 숫자)",
      }),
    code: z.string().min(1, { message: "휴대폰 인증은 필수 항목입니다." }),
  })
  .refine((data) => data.password === data.password_check, {
    message: "비밀번호가 일치하지 않습니다.",
    path: ["password_check"],
  });
