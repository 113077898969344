import classNames from "classnames/bind";
import styles from "./radio.module.css";
import { Radio as AntdRadio } from "antd";

const cx = classNames.bind(styles);

const Radio = ({ children, ...props }) => {
  const className = cx("common");

  return (
    <AntdRadio className={className} {...props}>
      {children}
    </AntdRadio>
  );
};

export default Radio;
