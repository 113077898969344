import s from "./Login.module.css";
import Mheader from "../../../component/common/Mheader";
import Title from "./components/elements/title/Title";
import EmailInput from "./components/elements/input/EmailInput";
import PwdInput from "./components/elements/input/PwdInput";
import MaintainLoginCheckbox from "./components/elements/checkbox/MaintainLoginCheckbox";
import FindAccount from "./components/elements/find-account/FindAccount";
import LoginButton from "./components/elements/button/LoginButton";
import SignupButton from "./components/elements/button/SignupButton";
import LoginFailModal from "./components/modal/LoginFailModal";
import LoginPartnerModal from "./components/modal/LoginPartnerModal";
import useLoginHandler from "../../../hooks/handler/useLoginHandler";

export default function Login() {
  const {
    navigate,
    loginParams,
    setLoginParams,
    loginFailModal,
    setLoginFailModal,
    loginPartnerModal,
    setLoginPartnerModal,
    handleLogin,
    onKeyDown,
  } = useLoginHandler();

  return (
    <div className={s.page_container}>
      <Mheader title="로그인" />

      <section className={s.title_container}>
        <Title />
      </section>

      <section className={s.input_container}>
        <EmailInput
          loginParams={loginParams}
          setLoginParams={setLoginParams}
          onKeyDown={onKeyDown}
        />
        <PwdInput
          loginParams={loginParams}
          setLoginParams={setLoginParams}
          onKeyDown={onKeyDown}
        />
      </section>

      <section className={s.checkbox_container}>
        <MaintainLoginCheckbox />
        <FindAccount navigate={navigate} />
      </section>

      <section className={s.button_container}>
        <LoginButton handleLogin={handleLogin} />
        <SignupButton navigate={navigate} />
      </section>

      {loginFailModal && (
        <LoginFailModal
          loginFailModal={loginFailModal}
          setLoginFailModal={setLoginFailModal}
        />
      )}
      {loginPartnerModal && (
        <LoginPartnerModal
          loginPartnerModal={loginPartnerModal}
          setLoginPartnerModal={setLoginPartnerModal}
        />
      )}
    </div>
  );
}
