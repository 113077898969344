import { React, useState, useEffect } from "react";
//library

//component
import CompanyInfoPop from "./CompanyInfoPop";
//css
import "../../../asset/css/look.css";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import i_cahat from "../../../asset/images/m-chat-icon.png";
import i_location from "../../../asset/images/w-map-pin-icon.png";
import i_search from "../../../asset/images/search-icon.png";
import i_phone from "../../../asset/images/phone-icon.png";
import { companyDetailApi } from "../../../api/auth";
import { useNavigate } from "react-router-dom";

export default function FindDetailPop({
  data,
  c_no,
  setSiteMapList,
  siteMapList,
  i,
}) {
  const navigate = useNavigate();
  const [isOpen2, setIsOpen2] = useState(false);
  const [comDetail, setComDetail] = useState([]);
  const [tagList, setTagList] = useState([]);

  const detailDataFnc = async () => {
    const response = await companyDetailApi(c_no);
    if (response.status === "success") {
      setComDetail(response.data.companyReviewInfo);
      setTagList(response.data.tagList);
    } else {
    }
  };

  const hyphen = (phoneNum) => {
    if (phoneNum != undefined)
      return phoneNum.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    return phoneNum;
  };

  useEffect(() => {
    detailDataFnc();
  }, []);

  return (
    <>
      <div className="look_pop">
        <div
          className="look_close"
          onClick={() => {
            const CurrentData = siteMapList.map((item, index) =>
              index === i && item.flag === false
                ? { ...item, flag: true }
                : { ...item, flag: false }
            );
            setSiteMapList(CurrentData);
          }}
        >
          <img src={i_close} alt="" />
        </div>
        <h3>공급사 상세정보</h3>
        <div className="look_company">
          <div className="look_company_img">
            <img src={comDetail.companyLogo} alt={"공급사 로고 이미지"}></img>
          </div>
          <div className="look_company_info">
            <div className="look_company_type">
              <p>{"공급사 분류:" + comDetail.company?.type}</p>
            </div>
            <div className="look_company_name">
              <p>{comDetail.company?.name}</p>
              <span>총 평점 : {comDetail.totalScore}</span>
            </div>
            <div className="look_company_address">
              <img src={i_location} />
              <div>
                <span>
                  {comDetail.company?.addr1}, {comDetail.company?.addr2}
                </span>
              </div>
            </div>
            <div className="look_company_phone">
              <img src={i_phone} />
              <p>{hyphen(comDetail.company?.phone)}</p>
            </div>
          </div>

          <div
            className="look_company_detail"
            onClick={() => {
              setIsOpen2(true);
            }}
          >
            <p>상세보기</p>
          </div>
          {/* <div
            className="look_company_order"
            onClick={() => {
              navigate("/management/request_bid", {
                state: {
                  companyInfo: { no: c_no, name: comDetail.company?.name },
                  isOrder: true,
                },
              });
            }}
          >
            <p>주문하기</p>
          </div> */}

          {/* http://localhost:3000/management/request_bid */}
        </div>

        <div className="look_hashtag">
          <div className="look_hashtag_inner">
            {tagList.map((a, i) => {
              return (
                <span className="look_hashtag_elem">
                  <span>#</span>
                  {a}
                </span>
              );
            })}
          </div>
        </div>
        {/* <div className='look_subject'>
          <h4>주요 공급품목</h4>
          <div className='look_table'>
            <table>
              <tbody>
                <tr>
                  <th>품목명</th>
                  <th>규격</th>
                  <th>단위</th>
                </tr>
                {cpList
                  ? cpList.map((data, i) => {
                      return (
                        <tr>
                          <td>{data.p_name}</td>
                          <td>{data.p_standard}</td>
                          <td>{data.p_unit}</td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
      {isOpen2 ? (
        <CompanyInfoPop
          setIsOpen2={setIsOpen2}
          comNum={comDetail.company?.no}
        />
      ) : null}
    </>
  );
}
