import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import Button from "../../../../../../component/ui/button/Button";
import { COLOR } from "../../../../../../constant/Palette";

const SiteCreateModal = ({
  siteCreateModal,
  setSiteCreateModal,
  handleCreateSite,
  handleUpdateSite,
  state,
}) => {
  const onClickOk = () => {
    state?.isEdit ? handleUpdateSite() : handleCreateSite();
  };

  const onClickCancel = () => {
    setSiteCreateModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="success"
      open={siteCreateModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.BLUE.L05}>
          현장 {state?.isEdit ? "수정" : "등록"}을 완료하시겠습니까?
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button type="primary" size="small" onClick={onClickOk}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        확인을 누르면 현장 {state?.isEdit ? "수정" : "등록"} 신청이 완료됩니다.
      </Typography>
      <Typography component="p" type="c2">
        입력하신 현장 정보는 승인 후 적용됩니다.
      </Typography>
    </Modal>
  );
};

export default SiteCreateModal;
