import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";

const SiteAttendButton = ({ setSiteAttendModal }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const onClickSiteAttendButton = () => {
    setSiteAttendModal(true);
  };

  return (
    <Button
      type="secondary"
      size="medium"
      style={{ width: isMobile && "46.8rem", minWidth: isMobile && "46.8rem" }}
      onClick={onClickSiteAttendButton}
    >
      현장 참여
    </Button>
  );
};

export default SiteAttendButton;
