import { useMediaQuery } from "react-responsive";
import Button from "../../../../../../component/ui/button/Button";

const MyInfoChangeButton = ({ setMyInfoChangeModal }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });

  const onClickMyInfoChangeButton = () => {
    setMyInfoChangeModal(true);
  };

  return (
    <Button
      type="secondary"
      size="medium"
      style={{
        width: isMobile && "46.8rem",
        minWidth: isMobile && "46.8rem",
      }}
      onClick={onClickMyInfoChangeButton}
    >
      내 정보 변경
    </Button>
  );
};

export default MyInfoChangeButton;
