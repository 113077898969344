import React, { useEffect } from "react";

const PreventRefresh = () => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // 표준에 맞게 메시지를 빈 문자열로 설정
      return ""; // 대부분의 브라우저에서 필요
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return <></>;
};

export default PreventRefresh;
