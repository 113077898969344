import { ReactComponent as EstimateIcon } from "../images/side_estimate_icon.svg";
import { ReactComponent as ContractIcon } from "../images/side_contract_icon.svg";
import { ReactComponent as OrderIcon } from "../images/side_order_icon.svg";
import { ReactComponent as CalculateIcon } from "../images/side_calculate_icon.svg";
import { ReactComponent as QuickIcon } from "../images/side_graph_icon.svg";
import { ReactComponent as ChatIcon } from "../images/side_chat_icon.svg";
import { ReactComponent as NewIcon } from "../images/new_icon.svg";

export const menuList = [
  {
    id: 1,
    mainNav: "인근공급사찾기",
    uri: "/look",
    active: false,
    css_on: false,
  },
  {
    id: 2,
    mainNav: "입찰/계약/주문",
    uri: "/management/request",
    active: false,
    css_on: false,
    subNav: [
      {
        to: "/management/estimate/1",
        title: "입찰관리",
      },
      {
        to: "/management/contract/1",
        title: "계약관리",
      },
      {
        to: "/management/order/1",
        title: "주문관리",
      },
      // {
      //   to: "/management/quick_order",
      //   title: "퀵주문관리",
      // },
    ],
  },
  {
    id: 3,
    mainNav: "정산관리",
    uri: "/invoice",
    active: false,
    css_on: false,
  },
  {
    id: 4,
    mainNav: "질의응답",
    uri: "/qna",
    active: false,
    css_on: false,
  },
  {
    id: 5,
    mainNav: "거래현황",
    uri: "/all_history",
    active: false,
    css_on: false,
  },
  {
    id: 6,
    mainNav: "마이페이지",
    uri: "/my_page/my_info",
    active: false,
    css_on: false,
    subNav: [
      {
        to: "/my_page/my_info",
        title: "내 정보 관리",
      },
      {
        to: "/my_page/notice",
        title: "공지사항",
      },
      {
        to: "/download/app",
        title: "바탕화면에 바로가기 추가",
      },
    ],
  },
];

export const sideList = [
  {
    id: 2,
    mainNav: "입찰관리",
    uri: "/management/estimate/1",
    Icon: EstimateIcon,
    active: false,
    isSubcontractor: true,
    IsNew: false,
    subNav: [
      {
        to: "/qna",
        title: "질의응답",
      },
      {
        to: "/management/estimate",
        title: "진행중",
      },
      {
        to: "/management/estimate",
        title: "선정대기",
      },
      {
        to: "/management/estimate",
        title: "취소",
      },
      {
        to: "/management/estimate",
        title: "선정완료",
      },
    ],
  },
  {
    id: 3,
    mainNav: "계약관리",
    uri: "/management/contract/1",
    Icon: ContractIcon,
    active: false,
    isSubcontractor: true,
    IsNew: false,
    subNav: [
      {
        to: "/management/contract",
        title: "체결대기",
      },
      {
        to: "/management/contract",
        title: "취소 / 대기",
      },
      {
        to: "/management/contract",
        title: "지정기간 계약체결",
      },
      {
        to: "/management/contract",
        title: "지정기간 만료",
      },
    ],
  },
  {
    id: 4,
    mainNav: "주문관리",
    uri: "/management/order/1",
    Icon: OrderIcon,
    isSubcontractor: true,
    active: false,
    IsNew: false,
    subNav: [
      {
        to: "/management/order",
        title: "발행완료",
      },
      {
        to: "/management/order",
        title: "주문확인",
      },
      {
        to: "/management/order",
        title: "배송중",
      },
      {
        to: "/management/order",
        title: "배송완료",
      },
      {
        to: "/management/order",
        title: "검수완료",
      },
      {
        to: "/management/order",
        title: "취소",
      },
    ],
  },
  {
    id: 7,
    mainNav: "정산관리",
    uri: "invoice",
    Icon: CalculateIcon,
    isSubcontractor: true,
    active: false,
    IsNew: false,
    subNav: [
      {
        to: "/calculate/1",
        title: "정산대기",
      },
      {
        to: "/calculate/2",
        title: "정산완료",
      },
    ],
  },
  {
    id: 8,
    mainNav: "질의응답",
    uri: "/qna",
    isSubcontractor: true,
    Icon: ChatIcon,
    active: false,
    IsNew: false,
  },
  {
    id: 9,
    mainNav: "거래현황",
    uri: "/all_history",
    isSubcontractor: true,
    Icon: QuickIcon,
    active: false,
    IsNew: NewIcon,
  },
  // 견적요청

  // 입찰관리
  // - 질의응답

  // 계약관리

  // 주문관리

  // 퀵주문관리

  // 정산관리
  // - 정산대기
  // - 정산완료

  // 인근현장찾기

  // 마이페이지
  // - 내 정보 관리
  // - 공지사항
  // - 유료서비스이용내역
];

export const subcSideList = [
  {
    id: 1,
    mainNav: "PB상품 주문관리",
    uri: "/management/pb/order/1",
    Icon: OrderIcon,
    isSubcontractor: false,
  },
  {
    id: 2,
    mainNav: "입찰관리",
    uri: "/management/estimate/1",
    Icon: EstimateIcon,
    isSubcontractor: true,
  },
  {
    id: 3,
    mainNav: "계약관리",
    uri: "/management/contract/1",
    Icon: ContractIcon,
    isSubcontractor: true,
  },
  {
    id: 4,
    mainNav: "주문관리",
    uri: "/management/order/1",
    Icon: OrderIcon,
    isSubcontractor: true,
  },

  {
    id: 5,
    mainNav: "정산관리",
    uri: "invoice",
    Icon: CalculateIcon,
    isSubcontractor: true,
  },
  {
    id: 6,
    mainNav: "질의응답",
    uri: "/qna",
    Icon: ChatIcon,
    isSubcontractor: true,
  },
  {
    id: 7,
    mainNav: "거래현황",
    uri: "/all_history",
    Icon: QuickIcon,
    isSubcontractor: true,
  },
  // 견적요청

  // 입찰관리
  // - 질의응답

  // 계약관리

  // 주문관리

  // 퀵주문관리

  // 정산관리
  // - 정산대기
  // - 정산완료

  // 인근현장찾기

  // 마이페이지
  // - 내 정보 관리
  // - 공지사항
  // - 유료서비스이용내역
];

// export const sideFooterList =[
//   {
//     id: 1,
//     mainNav: "FAQ / 고객문의",
//     uri: "/look",
//     icon : icon9,
//     active: false,
//     subNav:[]
//   },
//   {
//     id: 1,
//     mainNav: "로그아웃",
//     uri: "/look",
//     icon : icon10,
//     active: false,
//     subNav:[]
//   },
// ]
