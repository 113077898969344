import React, { useState } from 'react';
//library
import { Link, useNavigate } from 'react-router-dom';
//css
import '../../../asset/css/pop.css';
//etc
import i_close from '../../../asset/images/w-cancel-icon.png';

//sweet alert
import swal from 'sweetalert';

export default function ContractPop({
  setContractPop,
  reason,
  setReason,
  detailData,
  detailData2,
  prsCompany,
  index,
}) {
  const navigate = useNavigate();
  const handleSelect = (e) => {
    setReason({ ...reason, re1: e.target.value });
    setReason({ ...reason, re2: e.target.value });
  };
  

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setContractPop(false)}></div>
      <div className='contract_pop'>
        <div className='close' onClick={() => setContractPop(false)}>
          <img src={i_close} alt='' />
        </div>
        <div className='contract_pop_tit'>
          <h3>{detailData2.offerDetailInfoList[index].companyName}와 계약을 진행하시겠습니까?</h3>
          <p>
            <span>해당업체는 최저가 업체가 아닙니다. </span>
            <span>해당업체 선정하신 이유를 알려주세요!</span>
          </p>
        </div>
        <select
          onChange={(e) => {
            setReason({ ...reason, re1: e.target.value });
          }}
        >
          <option value='' selected>
            신청사유 선택
          </option>
          <option value='회사와의 관계'>회사와의 관계</option>
          <option value='진행중인 계약있음'>진행중인 계약있음</option>
          <option value='품질이 좋음'>품질이 좋음</option>
          <option value='기타'>기타</option>
        </select>
        {reason.re1 === '기타' ? (
          <input
            placeholder='선정이유를 입력해주세요'
            onChange={(e) => {
              setReason({ ...reason, re2: e.target.value });
            }}
          />
        ) : null}
        <div className='confirm_btn'>
          <p
            onClick={() => {
              let finalReason = '';
              if(reason.re1 === ''){
                swal('선정사유를 선택해주세요.', '', 'warning');
                return;
              }else if (reason.re1 === '기타') {
                if (!reason.re2 || reason.re2 === '') {
                  swal( "선정사유를 입력해주세요." ,  "" ,  "warning" )
                  return;
                } else {
                  finalReason = reason.re2;
                }
              } else {
                finalReason = reason.re1;
              }

              navigate('/management/estimate/conclude_contract/' +
                  detailData2.offerDetailInfoList[index].offerNo,
                {
                  state: {
                    detailData: detailData,
                    prsCompany: prsCompany,
                    reason: finalReason,
                    index: index,
                    selectCompany:detailData.offerDetailInfoList[index],
                  },
                });
            }}
          >
            확인
          </p>
        </div>
      </div>
    </div>
  );
}
