export default function InfoSVG({ size = "small", stroke = "#669D34" }) {
  if (size === "xsmall") size = "16";
  if (size === "small") size = "20";
  if (size === "medium") size = "24";
  if (size === "large") size = "32";
  if (size === "xlarge") size = "40";

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 35C21.9698 35 23.9204 34.612 25.7403 33.8582C27.5601 33.1044 29.2137 31.9995 30.6066 30.6066C31.9995 29.2137 33.1044 27.5601 33.8582 25.7403C34.612 23.9204 35 21.9698 35 20C35 18.0302 34.612 16.0796 33.8582 14.2597C33.1044 12.4399 31.9995 10.7863 30.6066 9.3934C29.2137 8.00052 27.5601 6.89563 25.7403 6.14181C23.9204 5.38799 21.9698 5 20 5C16.0218 5 12.2064 6.58035 9.3934 9.3934C6.58035 12.2064 5 16.0218 5 20C5 23.9782 6.58035 27.7936 9.3934 30.6066C12.2064 33.4196 16.0218 35 20 35Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 19.375H20V27.5H21.25"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.749 14.1857L21.4561 14.8928L20.749 14.1857C20.4677 14.467 20.0861 14.625 19.6883 14.625C19.2905 14.625 18.909 14.467 18.6277 14.1857C18.3464 13.9044 18.1883 13.5228 18.1883 13.125C18.1883 12.7272 18.3464 12.3456 18.6277 12.0643C18.909 11.783 19.2905 11.625 19.6883 11.625C20.0861 11.625 20.4677 11.783 20.749 12.0643C21.0303 12.3456 21.1883 12.7272 21.1883 13.125C21.1883 13.5228 21.0303 13.9044 20.749 14.1857Z"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
}
