import { Button as AntdButton } from "antd";
import styles from "./button.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export default function Button({
  size = "small",
  width,
  type = "primary",
  children,
  withIcon = false,
  onlyIcon = false,
  icon = null,
  disabled = false,
  onClick,
  ...props
}) {
  const className = cx({
    [size]: true,
    [type]: true,
    with_icon: withIcon,
    only_icon: onlyIcon,
  });

  return (
    <AntdButton
      className={className}
      icon={icon}
      disabled={disabled}
      onClick={onClick}
      style={{ width: width }}
      {...props}
    >
      {children}
    </AntdButton>
  );
}
