import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Select from "../../../../../../component/ui/select/Select";
import Typography from "../../../../../../component/ui/typography/Typography";
import RankInput from "../input/RankInput";
import s from "./rankSelect.module.css";
import { COLOR } from "../../../../../../constant/Palette";

const options = [
  { value: "대표", label: "대표" },
  { value: "이사", label: "이사" },
  { value: "부장", label: "부장" },
  { value: "과장", label: "과장" },
  { value: "대리", label: "대리" },
  { value: "직접 입력", label: "직접 입력" },
];

const RankSelect = ({ control, errors }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="select_rank"
      control={control}
      render={({ field }) => {
        const isUserInput = field.value === "직접 입력";

        return (
          <div className={s.select_container}>
            <Typography
              component="label"
              type="c1"
              color={COLOR.GRAY.L07}
              htmlFor="select_rank"
            >
              직급
            </Typography>

            <Select
              {...field}
              id="select_rank"
              value={field.value || undefined}
              size="medium"
              width={isDesktop ? 380 : "100%"}
              placeholder="직급을 선택해주세요."
              options={options}
            />

            {isUserInput && <RankInput control={control} errors={errors} />}
          </div>
        );
      }}
    />
  );
};

export default RankSelect;
