import s from "../../../asset/css/orderDetailPop.module.css";
import close_icon from "../../../asset/images/cancel-btn.png";

export default function OrderDetailPop({ productList, setOrderDetailPop }) {
  return (
    <div className={s.background} onClick={() => setOrderDetailPop(false)}>
      <div className={s.container} onClick={(e) => e.stopPropagation()}>
        <div className={s.header}>
          <div className={s.title}>상세내역</div>
          <div className={s.close} onClick={() => setOrderDetailPop(false)}>
            <img src={close_icon} alt="close_icon" />
          </div>
        </div>

        <div className={s.table_container}>
          <table className={s.table}>
            <tbody>
              <tr className={s.table_title}>
                <th>
                  <p>품목번호</p>
                </th>
                <th>
                  <p>품목명</p>
                </th>
                <th>
                  <p>수량</p>
                </th>
                <th>
                  <p>단가</p>
                </th>
                <th>
                  <p>합계</p>
                </th>
              </tr>
              {productList?.map((product) => (
                <OrderDetailTableTd
                  key={product.bidProduct.no}
                  product={product}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function OrderDetailTableTd({ product }) {
  return (
    <tr key={product.bidProduct.no} className={s.table_content}>
      <td>{product.bidProduct.no}</td>
      <td>{product.bidProduct.name}</td>
      <td>{product.orderlist.count?.toLocaleString("ko")}</td>
      <td>{product.orderlist.price?.toLocaleString("ko")}</td>
      <td>
        {(product.orderlist.count * product.orderlist.price)?.toLocaleString(
          "ko"
        )}
      </td>
    </tr>
  );
}
