import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../../../../../../redux/action";
import { userDefault } from "../../../../../../redux/reducer/default";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import Button from "../../../../../../component/ui/button/Button";
import { COLOR } from "../../../../../../constant/Palette";

const WithdrawSuccessModal = ({ withdrawSuccessModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickOk = () => {
    dispatch(actions.setToken(false));
    dispatch(actions.setUser(userDefault));
    navigate("/login");
  };

  return (
    <Modal
      size="xsmall"
      type="success"
      open={withdrawSuccessModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.BLUE.L05}>
          회원 탈퇴 완료
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickOk}
      footer={[
        <Button type="primary" size="small" onClick={onClickOk}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        회원 탈퇴가 정상적으로 완료되었습니다.
      </Typography>
    </Modal>
  );
};

export default WithdrawSuccessModal;
