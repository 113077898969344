import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constant/QueryKeys";
import { checkEmailApi } from "../../api/auth";

const useCheckEmail = (email) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CHECK_EMAIL],
    queryFn: () => checkEmailApi(email),
    enabled: false,
    retry: false,
  });
};

export default useCheckEmail;
