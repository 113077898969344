import React from "react";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import Button from "../../../../../../component/ui/button/Button";
import Modal from "../../../../../../component/ui/modal/Modal";
import useAttendSiteApprove from "../../../../../../hooks/useMutation/useAttendSiteApprove";
import useFetchMyPageInfo from "../../../../../../hooks/useQuery/useFetchMyPageInfo";

const SiteAttendApproveModal = ({
  siteAttendApproveModal,
  setSiteAttendApproveModal,
  memberNo,
}) => {
  const { refetch: fetchMyPageInfo } = useFetchMyPageInfo();

  const isAttendApproveSuccessCb = ({ status }) => {
    if (status === "success") {
      setSiteAttendApproveModal(false);
      fetchMyPageInfo();
    }
  };

  const isAttendApproveErrorCb = (error) => {
    console.error("attend approve failed: ", error);
  };

  const { mutate: attendSiteApprove } = useAttendSiteApprove(
    isAttendApproveSuccessCb,
    isAttendApproveErrorCb
  );

  const onClickOk = () => {
    attendSiteApprove({ userNo: memberNo, approve: true });
  };

  const onClickCancel = () => {
    setSiteAttendApproveModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="warning"
      open={siteAttendApproveModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.YELLOW.L10}>
          현장 참여를 승인하시겠습니까?
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button type="primary" size="small" onClick={onClickOk}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        확인을 누르면, 현장 참여를 승인합니다.
      </Typography>
    </Modal>
  );
};

export default SiteAttendApproveModal;
