import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  width: 1400px;
  margin: 0 auto;
  padding: 0px 30px 30px 230px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 5rem 5.5rem 15rem;
  }
`;

// ===========
// PaymentList
// ===========
export const PaymentListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  position: relative;
`;

// ====================
// PaymentList - header
// ====================
export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border-bottom: 1px solid #dddddd;
  background: #f2f5f9;
  @media (max-width: 1080px) {
    padding: 0 1.5rem;
    height: 12rem;
  }
`;

export const Badge = styled.div`
  width: 12%;
  height: 30px;
  border-radius: 5px;
  background: #254574;
  background: ${(props) =>
    (props.invoice.customerInvoice?.state === "REJECT" && "#ff6600") ||
    (props.invoice.customerInvoice?.state === "CANCEL" && "#ff6600") ||
    (props.invoice.customerInvoice?.state === "CANCEL_INVOICE" && "#ff6600")};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
  @media (max-width: 1080px) {
    width: 26rem;
    height: 8rem;
    line-height: 8rem;
    font-size: 3.5rem;
  }
`;

export const InvoiceNo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 20%;
  font-weight: 500;
  font-size: 16px;
  color: #999;
  text-align: center;
  div {
    margin-right: 3px;
  }
  span {
    color: #000000;
  }
  @media (max-width: 1080px) {
    width: 26rem;
    font-size: 3.5rem;
  }
`;

export const PublishedDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 25%;
  font-weight: 500;
  font-size: 16px;
  color: #999;
  text-align: center;
  div {
    margin-right: 3px;
  }
  span {
    color: #000000;
  }
  @media (max-width: 1080px) {
    width: 37rem;
    font-size: 3.5rem;
  }
`;

export const EstimateStep = styled.div`
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  div {
    position: relative;
  }
  div:not(:first-of-type)::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -30px;
    border-bottom: 30px solid #fff;
    border-left: 30px solid transparent;
    z-index: 10;
  }
  div:not(:last-of-type)::before {
    content: "";
    position: absolute;
    right: -30px;
    border-top: 30px solid #fff;
    border-right: 30px solid transparent;
    z-index: 10;
  }
  div:nth-child(1) {
    border-radius: 5px 0 0 5px;
    border-bottom: ${(props) =>
      (props.state === "REQUEST" || props.state === "REJECT") &&
      "30px solid #254574"};
    color: ${(props) =>
      (props.state === "REQUEST" || props.state === "REJECT") && "#fff"};
  }
  div:nth-child(1)::before {
    border-top: ${(props) =>
      (props.state === "REQUEST" || props.state === "REJECT") &&
      "30px solid #254574"};
    color: ${(props) =>
      (props.state === "REQUEST" || props.state === "REJECT") && "#fff"};
  }
  div:nth-child(2) {
    background: ${(props) =>
      (props.state === "ISSUED_INVOICE" ||
        props.state === "REQUEST_INVOICE" ||
        props.state === "CANCEL_INVOICE" ||
        props.state === "PAYMENT") &&
      "#254574"};
    color: ${(props) =>
      (props.state === "ISSUED_INVOICE" ||
        props.state === "REQUEST_INVOICE" ||
        props.state === "CANCEL_INVOICE" ||
        props.state === "PAYMENT") &&
      "#fff"};
  }
  div:nth-child(2)::before {
    border-top: ${(props) =>
      (props.state === "ISSUED_INVOICE" ||
        props.state === "REQUEST_INVOICE" ||
        props.state === "CANCEL_INVOICE" ||
        props.state === "PAYMENT") &&
      "30px solid #254574"};
    color: ${(props) =>
      (props.state === "ISSUED_INVOICE" ||
        props.state === "REQUEST_INVOICE" ||
        props.state === "CANCEL_INVOICE" ||
        props.state === "PAYMENT") &&
      "#fff"};
  }
  div:nth-child(2)::after {
    border-bottom: ${(props) =>
      (props.state === "ISSUED_INVOICE" ||
        props.state === "REQUEST_INVOICE" ||
        props.state === "CANCEL_INVOICE" ||
        props.state === "PAYMENT") &&
      "30px solid #254574"};
    color: ${(props) =>
      (props.state === "ISSUED_INVOICE" ||
        props.state === "REQUEST_INVOICE" ||
        props.state === "CANCEL_INVOICE" ||
        props.state === "PAYMENT") &&
      "#fff"};
  }
  div:nth-child(3) {
    border-radius: 0 5px 5px 0;
    background: ${(props) => props.state === "COMPLETE" && "#254574"};
    color: ${(props) => props.state === "COMPLETE" && "#fff"};
  }
  div:nth-child(3)::after {
    border-bottom: ${(props) =>
      props.state === "COMPLETE" && "30px solid #254574"};
    color: ${(props) => props.state === "COMPLETE" && "#fff"};
  }
`;

export const Step = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  font-size: 16px;
  margin-left: 35px;
  background: #fff;
  color: #333;
  filter: drop-shadow(1.5px 1.5px 1.5px rgba(0, 0, 0, 0.25));
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
    padding: 0 4rem;
  }
`;

export const AlignWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1080px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 5rem;
    div {
      margin-left: 0;
    }
  }
`;

export const InnerAlignWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: baseline;
  }
`;

export const InnerAlignWrap2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: 1080px) {
    flex-direction: column;
    height: 15rem;
    margin-bottom: 5rem;
  }
`;

export const MobileAlignWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const DivideLine = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

export const ToggleIcon = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  @media (max-width: 1080px) {
    width: 6.5rem;
    height: 6.5rem;
  }
`;

export const CompanyCount = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #787878;
  }
  @media (max-width: 1080px) {
    font-size: 4rem;
    margin-bottom: 2rem;
    span {
      font-size: 4rem;
    }
  }
`;

export const OrderCount = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #787878;
  }
  @media (max-width: 1080px) {
    font-size: 4rem;
    margin-bottom: 2rem;
    span {
      font-size: 4rem;
    }
  }
`;

export const TotalAmount = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #787878;
  }
  @media (max-width: 1080px) {
    font-size: 4rem;
    span {
      font-size: 4rem;
    }
  }
`;

export const Switch = styled.div`
  display: flex;
  justify-content: flex-end;
  > .ant-switch-checked {
    background: #f60 !important;
  }
`;

export const CodeGroupWrap = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`;

export const AlignDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1080px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const InvoiceCode = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  width: 150px;
  @media (max-width: 1080px) {
    width: 50rem;
    font-size: 4rem;
    margin-bottom: 3rem;
    span {
      font-size: 4rem;
    }
  }
`;

export const InvoiceCodeCount = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #787878;
  width: 110px;
  @media (max-width: 1080px) {
    width: 30rem;
    font-size: 4rem;
    margin-bottom: 1rem;
    span {
      font-size: 4rem;
    }
  }
`;

export const InvoiceCodePrice = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #787878;
  width: 250px;
  @media (max-width: 1080px) {
    font-size: 4rem;
    span {
      font-size: 4rem;
    }
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    width: 160px;
    height: 40px;
    background: #fff;
    opacity: ${(props) => !props.codeGroup.count && "0.2"};
    cursor: ${(props) => !props.codeGroup.count && "not-allowed"};
    border: 2px solid rgba(0, 40, 99, 0.8);
    box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
    border-radius: 4px;
    color: #002863;
    font-weight: 700;
    font-size: 16px;
    margin-left: 20px;
    transition: 0.2s all;
    &:hover {
      background: #002863;
      background: ${(props) => !props.codeGroup.count && "#fff"};
      color: #fff;
      color: ${(props) => !props.codeGroup.count && "#002863"};
    }
    div:nth-of-type(1) {
      font-size: 13px;
    }
    div:nth-of-type(2) {
      font-size: 11.5px;
    }
  }
  button:nth-of-type(1) {
    opacity: ${(props) =>
      (!props.codeGroup.count || !props.codeGroup.taxInvoiceFile) && "0.2"};
    cursor: ${(props) =>
      (!props.codeGroup.count || !props.codeGroup.taxInvoiceFile) &&
      "not-allowed"};
    &:hover {
      background: ${(props) =>
        (!props.codeGroup.count || !props.codeGroup.taxInvoiceFile) && "#fff"};
      color: ${(props) =>
        (!props.codeGroup.count || !props.codeGroup.taxInvoiceFile) &&
        "#002863"};
    }
  }
  @media (max-width: 1080px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      width: 30%;
      height: 10rem;
      margin-left: 0;
      font-size: 3.5rem;
      div:nth-of-type(1) {
        font-size: 3rem;
      }
      div:nth-of-type(2) {
        font-size: 2rem;
      }
    }
  }
`;

export const MotionContainer = styled(motion.ul)``;

export const ListItem = styled(motion.div)`
  overflow-y: auto;
`;

export const Title = styled(motion.div)`
  border: 1px solid #ddd;
  border-top: none;
  height: 34px;
  line-height: 34px;
  background: #f7f7f7;
  display: flex;
  flex-direction: row;
  div {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    border-right: 1px solid #ddd;
  }
  div:nth-of-type(1) {
    width: 16%;
  }
  div:nth-of-type(2) {
    width: 16%;
  }
  div:nth-of-type(3) {
    width: 15%;
  }
  div:nth-of-type(4) {
    width: 23%;
  }
  div:nth-of-type(5) {
    width: 15%;
  }
  div:nth-of-type(6) {
    width: 15%;
    border-right: none;
  }
  @media (max-width: 1080px) {
    width: 970.02px;
    height: 7rem;
    line-height: 7rem;
    div {
      font-size: 1.8rem;
    }
  }
`;

export const ToggleBtn = styled.button`
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  border: 1px solid #ddd;
  border-top: none;
`;
