import useInviteSiteHandler from "../../../../../../hooks/handler/useInviteSiteHandler";
import Button from "../../../../../../component/ui/button/Button";
import Modal from "../../../../../../component/ui/modal/Modal";
import Segmented from "../../../../../../component/ui/segmented/Segmented";
import UserSearchInput from "./components/elements/input/UserSearchInput";
import s from "./siteInviteModal.module.css";
import Spin from "../../../../../../component/ui/spin/Spin";
import classNames from "classnames";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import Chip from "../../../../../../component/ui/chip/Chip";
import Checkbox from "../../../../../../component/ui/checkbox/Checkbox";
import UserEmailInput from "./components/elements/input/UserEmailInput";
import SiteInviteMailSuccess from "../site-invite-mail-success/SiteInviteMailSuccess";
import SiteInviteMailFail from "../site-invite-mail-fail/SiteInviteMailFail";

const SiteInviteModal = ({ siteInviteModal, setSiteInviteModal }) => {
  const {
    handleSubmit,
    control,
    errors,
    keyword,
    setKeyword,
    searchKeyword,
    isLoading,
    userList,
    handleInviteSite,
    handleClickUser,
    isChecked,
    handleSearch,
    handleInviteMail,
    selectedUserList,
    setSelectedUserList,
    segmentedOptions,
    segmentedValue,
    setSegmentedValue,
    siteInviteMailSuccess,
    setSiteInviteMailSuccess,
    siteInviteMailFail,
    setSiteInviteMailFail,
  } = useInviteSiteHandler();

  const onClickCancel = () => {
    setSiteInviteModal(false);
  };

  const renderFooter = () => {
    const cancelButton = (
      <Button type="danger" size="small" onClick={onClickCancel}>
        취소
      </Button>
    );

    if (segmentedValue === "기존 회원 초대") {
      return [
        cancelButton,
        <Button
          key="invite"
          type="primary"
          size="small"
          onClick={() => handleInviteSite(selectedUserList)}
          disabled={selectedUserList.length === 0}
        >
          초대하기
        </Button>,
      ];
    }
    if (segmentedValue === "미가입 회원 초대") {
      return [
        cancelButton,
        <Button
          key="invite"
          type="primary"
          size="small"
          disabled={errors.email}
          onClick={handleSubmit(handleInviteMail)}
        >
          초대하기
        </Button>,
      ];
    }
  };

  return (
    <Modal
      size="small"
      open={siteInviteModal}
      title={
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          현장 초대
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={renderFooter()}
    >
      <div className={s.segmented_container}>
        <Segmented
          size="small"
          options={segmentedOptions}
          value={segmentedValue}
          onChange={setSegmentedValue}
        />
      </div>
      {segmentedValue === "기존 회원 초대" && (
        <>
          <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
            공새로에 가입하여 회사 정보 승인이 완료된 같은 회사 직원들만 초대할
            수 있습니다.
          </Typography>
          <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
            회원 가입이 되어있지 않다면, 회원 초대를 통해 공새로 가입 링크를
            전달해주세요.
          </Typography>

          <div className={s.input_container}>
            <UserSearchInput
              keyword={keyword}
              setKeyword={setKeyword}
              handleSearch={handleSearch}
              setSelectedUserList={setSelectedUserList}
            />
          </div>

          <div className={s.user_container}>
            {isLoading && <Spin />}
            {userList?.map((user) => (
              <div
                className={classNames(s.user, {
                  [s.selected]: isChecked(user),
                  [s.disabled]: user.state === "PENDING",
                })}
                key={user.no}
                onClick={(e) => {
                  if (e.target.closest(".ant-checkbox-wrapper")) {
                    return;
                  }
                  handleClickUser(user);
                }}
              >
                <Checkbox
                  checked={isChecked(user)}
                  onChange={() => handleClickUser(user)}
                  disabled={user.state === "PENDING"}
                />
                <div className={s.typo_container}>
                  <Typography
                    component="p"
                    type={isChecked(user) ? "h5" : "c1"}
                    color={COLOR.GRAY.L09}
                  >
                    {user.name || "-"}
                  </Typography>
                  <Typography
                    component="p"
                    type={isChecked(user) ? "h5" : "c1"}
                    color={COLOR.GRAY.L09}
                  >
                    {user.rank || "-"}
                  </Typography>
                  <Typography
                    component="p"
                    type={isChecked(user) ? "h5" : "c1"}
                    color={COLOR.GRAY.L09}
                  >
                    {user.id || "-"}
                  </Typography>
                </div>
                {user.state === "PENDING" && (
                  <div className={s.chip_container}>
                    <Chip size="medium" type="default" color="yellow">
                      승인대기
                    </Chip>
                  </div>
                )}
              </div>
            ))}
            {userList?.length === 0 && (
              <div className={s.empty_container}>
                <div>
                  <Typography component="span" type="c1" color={COLOR.GRAY.L09}>
                    <Typography
                      component="span"
                      type="c2"
                      color={COLOR.SUB.BLUE.L05}
                    >
                      "{searchKeyword}"
                    </Typography>
                    가 존재하지 않습니다.
                  </Typography>
                  <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                    상단의 "미가입 회원 초대" 버튼을 클릭하여 초대해주세요.
                  </Typography>
                </div>
                <div>
                  <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                    회원 가입 후, 관리페이지에서 회사 등록 및 승인이 완료되어야
                  </Typography>
                  <Typography component="p" type="c1" color={COLOR.GRAY.L09}>
                    서비스를 정상적으로 이용할 수 있습니다.
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {segmentedValue === "미가입 회원 초대" && (
        <div className={s.invite_mail_container}>
          <Typography component="p" type="c2" color={COLOR.GRAY.L09}>
            초대하고자 하는 사람의 이메일 주소를 입력해주세요.
          </Typography>

          <UserEmailInput control={control} errors={errors} />
        </div>
      )}
      {siteInviteMailSuccess && (
        <SiteInviteMailSuccess
          siteInviteMailSuccess={siteInviteMailSuccess}
          setSiteInviteMailSuccess={setSiteInviteMailSuccess}
        />
      )}
      {siteInviteMailFail && (
        <SiteInviteMailFail
          siteInviteMailFail={siteInviteMailFail}
          setSiteInviteMailFail={setSiteInviteMailFail}
        />
      )}
    </Modal>
  );
};

export default SiteInviteModal;
