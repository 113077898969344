export default function SelectSVG({ size = "small", fill = "#1B1D1F" }) {
  if (size === "xsmall") size = "16";
  if (size === "small") size = "20";
  if (size === "medium") size = "24";
  if (size === "large") size = "32";
  if (size === "xlarge") size = "40";

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9779 7.94217L10.7279 14.1922C10.6699 14.2503 10.6009 14.2964 10.5251 14.3278C10.4492 14.3593 10.3679 14.3755 10.2857 14.3755C10.2036 14.3755 10.1223 14.3593 10.0464 14.3278C9.97051 14.2964 9.90158 14.2503 9.84353 14.1922L3.59353 7.94217C3.47625 7.82489 3.41037 7.66583 3.41037 7.49998C3.41037 7.33413 3.47625 7.17507 3.59353 7.05779C3.71081 6.94052 3.86986 6.87463 4.03572 6.87463C4.20157 6.87463 4.36063 6.94052 4.4779 7.05779L10.2857 12.8664L16.0935 7.05779C16.1516 6.99972 16.2205 6.95366 16.2964 6.92224C16.3723 6.89081 16.4536 6.87463 16.5357 6.87463C16.6178 6.87463 16.6992 6.89081 16.775 6.92224C16.8509 6.95366 16.9198 6.99972 16.9779 7.05779C17.036 7.11586 17.082 7.1848 17.1135 7.26067C17.1449 7.33654 17.1611 7.41786 17.1611 7.49998C17.1611 7.5821 17.1449 7.66342 17.1135 7.73929C17.082 7.81516 17.036 7.8841 16.9779 7.94217Z"
        fill={fill}
      />
    </svg>
  );
}
