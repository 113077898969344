import React from "react";

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="22"
    viewBox="0 0 12 22"
    fill="none"
  >
    <path
      d="M0.899414 20.7988L10.7989 10.8993L0.899414 0.999838"
      stroke="#454C53"
    />
  </svg>
);

export default function OrderBreadCrumb({
  step1,
  step2,
  step3,
  step4,
  isActive,
}) {
  return (
    <div className="navigation">
      {step1 && (
        <span className={isActive === 1 ? "nav_active" : ""}>{step1}</span>
      )}
      {step1 && <ArrowIcon />}
      <span className={isActive === 2 ? "nav_active" : ""}>{step2}</span>
      <ArrowIcon />
      <span className={isActive === 3 ? "nav_active" : ""}>{step3}</span>
      {step4 && <ArrowIcon />}
      {step4 && (
        <span className={isActive === 4 ? "nav_active" : ""}>{step4}</span>
      )}
    </div>
  );
}
