import React, { useState } from "react";
//library
import { useNavigate } from "react-router-dom";
//component
import Mheader from "../../../component/common/Mheader";
//css
import "../../../asset/css/quickOrder.css";

import usePreventBack from "../../main/customHooks/usePreventBack";

export default function QuickOrderComplete() {
  const navigate = useNavigate();

  //뒤로가기 방지
  usePreventBack();

  return (
    <div className="quick_order_complete_wrap" style={{ paddingTop: "70px" }}>
      <Mheader title="업체지정주문" />
      <div className="quick_order_complete_container">
        <div className="quick_bar_container">
          <div className="top_progress_bar" style={{ top: "140px" }}>
            <div className="menus not_allowed">주문방법선택</div>
            <div className="menus not_allowed">공급사 선택</div>
            <div className="menus not_allowed">입력 방법 선택</div>
            <div className="menus not_allowed">
              엑셀입력 or
              <br /> 품목선택
            </div>
            <div className="menus not_allowed">상세정보 입력</div>
            <div className="active_menus color1">완료</div>
          </div>
        </div>

        <div className="quick_order_complete_text">
          <p>업체지정주문이 접수되었습니다.</p>
        </div>
        <div className="btn_box">
          <div className="view_estimate_info next">
            <p
              onClick={() => {
                navigate("/management/order/1");
              }}
            >
              주문관리 바로가기
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
