import { z } from "zod";

export const createSiteSchema = z
  .object({
    name: z.string().min(1, { message: "현장명은 필수 항목입니다." }),
    addr: z.string().min(1),
    addr_detail: z.string().min(1),
    zip: z.string().min(1),
    lat: z.string().min(1),
    lng: z.string().min(1),
    select_type: z.string().min(1, { message: "현장 분류는 필수 항목입니다." }),
    input_type: z.string().optional(),
    scale: z.string().min(1, { message: "현장 규모는 필수 항목입니다." }),
    process: z.string().min(1, { message: "공종 단계는 필수 항목입니다." }),
    phone: z
      .string()
      .min(1, { message: "대표번호는 필수 항목입니다." })
      .regex(/^\d{3}-\d{3,4}-\d{4}$|^\d{10,11}$/, {
        message: "올바른 대표번호를 입력해주세요. (10~11자리 숫자)",
      }),
    s_date: z.coerce.date(),
    e_date: z.coerce.date(),
  })
  .refine(
    (data) => data.select_type !== "직접 입력" || data.input_type?.length > 0,
    {
      message: "현장 분류는 필수 항목입니다.",
      path: ["input_type"],
    }
  );
