import React, { useEffect, useState } from "react";
//library
import { useNavigate } from "react-router-dom";

//css
import "../../asset/css/siteselect.css";
//img
import i_plus from "../../asset/images/w-plus-icon.png";
import { mainBoardInfoApi } from "../../api/auth";

import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default.js";

import i_close from "../../asset/images/w-cancel-icon.png";

//sweet alert
import swal from "sweetalert";

export default function SiteSelect({
  setSelectSwitch,
  siteList,
  selectSwitch,
  isPath,
  canClose, // 현장선택으로 들어왔을 경우에만
}) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  // const siteno = useSelector((state) => state.user.site_num);

  const [isSelected, setIsSelected] = useState(null);
  const navigate = useNavigate();

  const intToRGBA = (hash, alpha = 1, factor = 0.8) => {
    // factor를 사용하여 톤 다운
    const r = Math.floor(((hash & 0xff0000) >> 16) * factor);
    const g = Math.floor(((hash & 0x00ff00) >> 8) * factor);
    const b = Math.floor((hash & 0x0000ff) * factor);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const randomColor = (text, alpha = 1, factor = 0.8) => {
    const hash = hashCode(text);
    return intToRGBA(hash, alpha, factor);
  };

  // 해시 함수
  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };
  const editLogo = (text) => {
    let filterText = text?.substr(0, 2);
    return filterText;
  };

  return selectSwitch ? (
    <div
      className="siteselect_wrap"
      onClick={() => {
        if (canClose) {
          setSelectSwitch(false);
        }
      }}
    >
      <div className="siteselect_container">
        <h2>
          <p>현장 선택</p>
        </h2>

        {canClose && (
          <div className="look_close" onClick={() => setSelectSwitch(false)}>
            <img src={i_close} alt="" />
          </div>
        )}
        <div className="siteselect_bottom">
          <ul className="site_list">
            {siteList.map((data, i) => {
              return (
                <li
                  key={data.site?.name}
                  className={
                    // data.flag ? 'site_select_true' : 'site_select_false'
                    userData.site_num === data.site?.no
                      ? "site_select_true"
                      : "site_select_false"
                  }
                  onClick={() => {
                    let newUserData = {
                      u_no: userData.u_no,
                      u_name: userData.u_name,
                      u_phone: userData?.u_phone,
                      constructionType: userData.constructionType,

                      site_num: data?.site?.no,
                      rep_site: data?.site?.name,
                      rep_con: data?.constructionName,
                    };

                    dispatch(actions.setUser(newUserData));

                    setIsSelected(data);
                    setSelectSwitch(false);
                    if (isPath === "invoice") {
                      navigate("/invoice");
                    } else {
                      navigate("/", {
                        state: { isSelected: isSelected },
                      });
                    }
                    // boardInfoApi();
                  }}
                >
                  {/* <div
                    className="site_img"
                    style={{ background: randomColor(data.addrDetail) }}
                  >
                    {editLogo(data?.addrDetail)}
                  </div> */}
                  <div
                    className="site_img"
                    style={{
                      background: randomColor(data.addrDetail, 0.15, 0.6),
                    }}
                  >
                    <span>{editLogo(data?.addrDetail)}</span>
                  </div>
                  <p className="site_info">
                    {/* &#91;<span className='site_company'>{data.constructionName}</span>&#93;  */}
                    <span className="site_name">
                      현장명 : {data.site?.name}
                    </span>
                    <span className="site_name">
                      공사일 : {data.site?.sdate} ~ {data.site?.edate}
                    </span>
                    <span className="site_name">
                      유&nbsp;&nbsp;&nbsp;&nbsp;형 : {data.site?.type}
                    </span>
                  </p>
                </li>
              );
            })}
          </ul>
          {/* <div className='btn_plus'>
            <img alt='' src={i_plus}></img>
            <p className='btn_plus_pcdn'>현장 추가</p>
          </div> */}
        </div>
      </div>
    </div>
  ) : null;
}
