import Button from "../../../../../../component/ui/button/Button";
import { useNavigate } from "react-router-dom";

const CancelButton = () => {
  const navigate = useNavigate();
  const moveToBackPage = () => {
    navigate(-1);
  };

  return (
    <Button type="secondary" size="medium" onClick={moveToBackPage}>
      취소
    </Button>
  );
};

export default CancelButton;
