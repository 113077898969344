import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constant/QueryKeys";
import { fetchOrgInfoApi } from "../../api/auth";

const useFetchOrgInfo = (inviteCode) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_ORG_INFO],
    queryFn: () => fetchOrgInfoApi(inviteCode),
    enabled: !!inviteCode.code,
  });
};

export default useFetchOrgInfo;
