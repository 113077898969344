import * as S from "../../asset/css/invoiceList.styles";
import arrow_open from "../../asset/images/arrow-open.png";
import arrow_close from "../../asset/images/arrow-close.png";
import {
  eProFormDownloadApi,
  invoiceApproveApi,
  invoiceExcelDownloadApi,
  invoiceRejectApi,
  paymentRequestDownloadApi,
  taxInvoiceDownloadApi,
  taxInvoiceRejectApi,
} from "../../api/auth";
import swal from "sweetalert";
import InvoiceListItem from "./InvoiceListItem";
import InvoiceListItemBtn from "./InvoiceListItemBtn";
import useOpenInvoiceList from "./customHooks/useOpenInvoiceList";
import useInvoiceItemList from "./customHooks/useInvoiceListItem";
import useGenerateLoading from "./customHooks/useGenerateLoading";
import { useMediaQuery } from "react-responsive";
import { useRef } from "react";
import Loading from "../../component/etc/Loading";
import { Switch } from "antd";

export default function InvoiceList(props) {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });

  const { invoice, index, state, fetchInvoiceList, fetchInvoiceCountByState } =
    props;
  const { invoiceListItem, fetchInvoiceListItem } = useInvoiceItemList(invoice);
  const { openInvoiceList, setOpenInvoiceList } = useOpenInvoiceList(index);
  const { generate, setGenerate } = useGenerateLoading();

  const onClickInvoiceList = () => {
    fetchInvoiceListItem(invoice.customerInvoice.no);
    setOpenInvoiceList((prev) => !prev);
  };

  const onClickTaxInvoice = async (invoiceNo, code) => {
    const response = await taxInvoiceDownloadApi(invoiceNo, code);
    if (response.status === "success") {
      window.open(response?.data?.url, "_blank");
    }
  };

  const onClickReject = async (invoiceNo, rejectComment) => {
    await invoiceRejectApi(invoiceNo, rejectComment);
  };

  const onClickTaxReject = async (invoiceNo, rejectComment) => {
    await taxInvoiceRejectApi(invoiceNo, rejectComment);
  };

  const handleSendClick = () => {
    const value = document.querySelector(".swal-content__textarea").value;

    if (!value) {
      swal("취소사유를 입력해주세요.", "", "warning");
    } else {
      onClickReject(invoice.customerInvoice.no, value);
      swal({
        title: "정산이 취소되었습니다.",
        text: `${value}`,
        icon: "success",
      }).then((value) => {
        if (value) fetchInvoiceList("request");
      });
    }
  };

  const handleTaxSendClick = () => {
    const value = document.querySelector(".swal-content__textarea").value;

    if (!value) {
      swal("반려사유를 입력해주세요.", "", "warning");
    } else {
      onClickTaxReject(invoice.customerInvoice.no, value);
      swal({
        title: "세금계산서가 반려되었습니다.",
        text: `${value}`,
        icon: "success",
      }).then((value) => {
        if (value) fetchInvoiceList("process");
      });
    }
  };

  const handleCancelClick = () => {
    swal("입력이 취소되었습니다.", "", "info");
  };

  const reject = () => {
    swal({
      title: "취소사유 입력",
      icon: "info",
      buttons: {
        cancel: {
          text: "취소",
          value: null,
          visible: true,
        },
        confirm: {
          text: "전송",
          value: true,
          visible: true,
        },
      },
      closeOnClickOutside: false,
      content: {
        element: "textarea",
        attributes: {
          placeholder: "사유를 입력해주세요.",
          rows: 4,
          style: "resize:none",
        },
      },
    }).then((value) => {
      if (value) handleSendClick();
      if (!value) handleCancelClick();
    });
  };

  const taxReject = () => {
    swal({
      title: "반려사유 입력",
      icon: "info",
      buttons: {
        cancel: {
          text: "취소",
          value: null,
          visible: true,
        },
        confirm: {
          text: "전송",
          value: true,
          visible: true,
        },
      },
      closeOnClickOutside: false,
      content: {
        element: "textarea",
        attributes: {
          placeholder: "사유를 입력해주세요.",
          rows: 4,
          style: "resize:none",
        },
      },
    }).then((value) => {
      if (value) handleTaxSendClick();
      if (!value) handleCancelClick();
    });
  };

  const onClickApprove = async (invoiceNo) => {
    await invoiceApproveApi(invoiceNo);
  };

  const approve = () => {
    swal({
      title: "세금계산서 발행을 요청합니다.",
      icon: "info",
      buttons: ["취소", "승인"],
      closeOnClickOutside: false,
    }).then((value) => {
      if (value) {
        onClickApprove(invoice.customerInvoice.no);
        swal({
          title: "처리가 완료되었습니다.",
          text: `해당 건이 '세금계산서 요청' 상태로 변경되었습니다.
          담당자 확인 후 세금계산서 발급이 진행됩니다.`,
          icon: "success",
          closeOnClickOutside: false,
        }).then((value) => {
          if (value) {
            fetchInvoiceList("request");
            fetchInvoiceCountByState();
          }
        });
      }
      if (!value) swal("취소되었습니다.", "", "info");
    });
  };

  const totalInvoiceCodeCount = invoiceListItem?.codeGroupList
    ?.map((el) => Number(el.count))
    ?.reduce((acc, cur) => acc + cur, 0);

  const totalInvoiceCodeAmount = invoiceListItem?.codeGroupList
    ?.map((el) => Number(el.totalAmount))
    ?.reduce((acc, cur) => acc + cur, 0);

  const downloadLinkRef = useRef(null);

  const onClickPaymentRequestDownload = async (invoiceNo, code) => {
    setGenerate(true);

    try {
      const response = await paymentRequestDownloadApi(invoiceNo, code);

      const url = URL.createObjectURL(response.data);
      let filename = "filename.xlsx";
      const contentDisposition = response.headers["content-disposition"];

      if (contentDisposition) {
        const match = contentDisposition.match(/fileName="([^"]+)"/);
        if (match && match[1]) {
          filename = decodeURIComponent(match[1]).replace(/\+/g, " ");
        }
      }

      downloadLinkRef.current.href = url;
      downloadLinkRef.current.download = filename;
      downloadLinkRef.current.click();
      setGenerate(false);
    } catch (error) {
      swal("다운로드 오류", "", "error");
      setGenerate(false);
    }
  };

  const onClickTotalExcelDownload = async (invoiceNo) => {
    setGenerate(true);

    try {
      const response = await invoiceExcelDownloadApi(invoiceNo);
      const url = URL.createObjectURL(response.data);
      let filename = "filename.xlsx";
      const contentDisposition = response.headers["content-disposition"];

      if (contentDisposition) {
        const match = contentDisposition.match(/fileName="([^"]+)"/);
        if (match && match[1]) {
          filename = decodeURIComponent(match[1]).replace(/\+/g, " ");
        }
      }

      downloadLinkRef.current.href = url;
      downloadLinkRef.current.download = filename;
      downloadLinkRef.current.click();
      setGenerate(false);
    } catch (error) {
      swal("다운로드 오류", "", "error");
      setGenerate(false);
    }
  };

  const onClickEProForm = async (invoiceNo, code) => {
    setGenerate(true);

    try {
      const response = await eProFormDownloadApi(invoiceNo, code);
      const url = URL.createObjectURL(response.data);
      let filename = "filename.xlsx";
      const contentDisposition = response.headers["content-disposition"];

      if (contentDisposition) {
        const match = contentDisposition.match(/fileName="([^"]+)"/);
        if (match && match[1]) {
          filename = decodeURIComponent(match[1]).replace(/\+/g, " ");
        }
      }

      downloadLinkRef.current.href = url;
      downloadLinkRef.current.download = filename;
      downloadLinkRef.current.click();
      setGenerate(false);
    } catch (error) {
      swal("다운로드 오류", "", "error");
      setGenerate(false);
    }
  };

  return (
    <>
      <S.Wrapper>
        <S.PaymentListWrapper>
          <S.ListHeader>
            <S.Badge invoice={invoice}>
              {invoice.customerInvoice.state === "REQUEST" && "정산요청"}
              {invoice.customerInvoice.state === "REJECT" && "정산반려"}
              {invoice.customerInvoice.state === "CANCEL" && "정산취소"}
              {invoice.customerInvoice.state === "REQUEST_INVOICE" &&
                "세금계산서 요청"}
              {invoice.customerInvoice.state === "ISSUED_INVOICE" &&
                "세금계산서 발행"}
              {invoice.customerInvoice.state === "CANCEL_INVOICE" &&
                "세금계산서 반려"}
              {invoice.customerInvoice.state === "PAYMENT" && "입금완료"}
              {invoice.customerInvoice.state === "COMPLETE" && "정산완료"}
            </S.Badge>
            <S.InvoiceNo>
              {isDesktop && <div>정산 번호 : </div>}
              <span>{invoice.customerInvoice.code}</span>
            </S.InvoiceNo>
            <S.PublishedDate>
              {isDesktop && <div>발행날짜 : </div>}
              <span>
                {invoice.customerInvoice.createdDate
                  .replace("T", " ")
                  .replaceAll("-", ".")
                  .slice(0, 16)}{" "}
              </span>
            </S.PublishedDate>

            {isDesktop && (
              <S.EstimateStep state={invoice.customerInvoice.state}>
                <S.Step>
                  <p>정산요청</p>
                </S.Step>
                <S.Step>
                  <p>세금계산서 발행</p>
                </S.Step>
                <S.Step>
                  <p>정산완료</p>
                </S.Step>
              </S.EstimateStep>
            )}
          </S.ListHeader>

          <S.ListBody>
            <S.AlignWrap>
              <S.InnerAlignWrap>
                <S.MobileAlignWrap>
                  <S.CompanyCount>
                    공급사 : <span>{invoice?.companyCount}개</span>
                  </S.CompanyCount>
                  {isDesktop && <S.DivideLine>|</S.DivideLine>}
                  <S.OrderCount>
                    주문서 개수 : <span>{invoice?.ordersCount}개</span>
                  </S.OrderCount>
                  {isDesktop && <S.DivideLine>|</S.DivideLine>}
                  <S.TotalAmount>
                    합계 금액 :{" "}
                    <span>
                      {invoice?.customerInvoice.totalAmount.toLocaleString(
                        "ko"
                      )}
                      원 (VAT 제외)
                    </span>
                  </S.TotalAmount>
                </S.MobileAlignWrap>
                {isDesktop && (
                  <S.Switch>
                    <Switch
                      checkedChildren="펼쳐보기"
                      unCheckedChildren="접기"
                      defaultChecked={index === 0 ? true : false}
                      onClick={isDesktop && onClickInvoiceList}
                    />
                  </S.Switch>
                )}
              </S.InnerAlignWrap>

              <S.CodeGroupWrap>
                {invoiceListItem?.codeGroupList?.map((codeGroup) => (
                  <S.InnerAlignWrap2 key={codeGroup?.taxCodeName}>
                    <S.AlignDiv>
                      <S.InvoiceCode>{codeGroup?.taxCodeName}</S.InvoiceCode>
                      <S.InvoiceCodeCount>
                        {codeGroup?.count?.toLocaleString("ko") || 0}건
                      </S.InvoiceCodeCount>
                      <S.InvoiceCodePrice>
                        {`${
                          codeGroup?.totalAmount?.toLocaleString("ko") || 0
                        }원 (${(
                          (Number(codeGroup?.totalAmount) /
                            Number(totalInvoiceCodeAmount)) *
                          100
                        )?.toFixed(1)}%)`}
                      </S.InvoiceCodePrice>
                    </S.AlignDiv>
                    <S.BtnWrap codeGroup={codeGroup}>
                      {invoice.customerInvoice.state !== "REQUEST" &&
                      invoice.customerInvoice.state !== "REJECT" ? (
                        <button
                          disabled={
                            !codeGroup?.count || !codeGroup.taxInvoiceFile
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenInvoiceList(true);
                            onClickTaxInvoice(
                              invoice.customerInvoice.no,
                              codeGroup?.taxCodeName
                            );
                          }}
                        >
                          세금계산서 다운
                        </button>
                      ) : (
                        <button style={{ visibility: "hidden" }} />
                      )}
                      <button
                        disabled={!codeGroup?.count}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenInvoiceList(true);
                          onClickEProForm(
                            invoice.customerInvoice.no,
                            codeGroup?.taxCodeName
                          );
                        }}
                      >
                        <div>아이템 상세내역</div>
                        <div>(e-Pro 양식)</div>
                      </button>
                      <button
                        disabled={!codeGroup?.count}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenInvoiceList(true);
                          onClickPaymentRequestDownload(
                            invoice.customerInvoice.no,
                            codeGroup?.taxCodeName
                          );
                        }}
                      >
                        지급요청서 다운
                      </button>
                      <a ref={downloadLinkRef} style={{ display: "none" }} />
                    </S.BtnWrap>
                  </S.InnerAlignWrap2>
                ))}
              </S.CodeGroupWrap>
              <S.InnerAlignWrap>
                <S.AlignDiv>
                  <S.InvoiceCode>합계</S.InvoiceCode>
                  <S.InvoiceCodeCount>
                    {totalInvoiceCodeCount?.toLocaleString("ko") || 0}건
                  </S.InvoiceCodeCount>
                  <S.InvoiceCodePrice>
                    {totalInvoiceCodeAmount?.toLocaleString("ko") || 0}원
                    (100.0%)
                  </S.InvoiceCodePrice>
                </S.AlignDiv>
              </S.InnerAlignWrap>
            </S.AlignWrap>
          </S.ListBody>
        </S.PaymentListWrapper>
        {isMobile && (
          <S.ToggleBtn onClick={onClickInvoiceList}>
            <S.ToggleIcon src={openInvoiceList ? arrow_close : arrow_open} />
          </S.ToggleBtn>
        )}

        {openInvoiceList && (
          <>
            <S.ListItem>
              {invoiceListItem?.ordersDetailList?.map((invoiceItem) => (
                <InvoiceListItem
                  key={invoiceItem.orders.no}
                  invoiceItem={invoiceItem}
                  invoice={invoice}
                  codeGroupList={invoiceListItem?.codeGroupList}
                  fetchInvoiceListItem={fetchInvoiceListItem}
                />
              ))}
            </S.ListItem>

            <InvoiceListItemBtn
              state={state}
              invoice={invoice}
              excelDownload={() =>
                onClickTotalExcelDownload(invoice.customerInvoice.no)
              }
              downloadLinkRef={downloadLinkRef}
              approve={() => approve(invoice.customerInvoice.no)}
              reject={() => reject(invoice.customerInvoice.no)}
              taxReject={() => taxReject(invoice.customerInvoice.no)}
            />
          </>
        )}
      </S.Wrapper>
      {generate && <Loading />}
    </>
  );
}
