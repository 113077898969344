import Input from "../../../../../../../../../component/ui/input/Input";
import SearchSVG from "../../../../../../../../../asset/images/svg/SearchSVG";

const SiteSearchInput = ({
  keyword,
  setKeyword,
  handleSearch,
  setSelectedSite,
}) => {
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
      setSelectedSite(null);
    }
  };

  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const onClickSuffix = () => {
    handleSearch();
    setSelectedSite(null);
  };

  return (
    <Input
      size="large"
      width="100%"
      id="site_search"
      placeholder="현장명을 검색해주세요."
      value={keyword}
      onChange={onChangeKeyword}
      suffix={<SearchSVG />}
      onClickSuffix={onClickSuffix}
      onKeyDown={onKeyDown}
    />
  );
};

export default SiteSearchInput;
