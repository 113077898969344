import React, { useEffect, useState } from "react";
//library
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { motion, AnimatePresence } from "framer-motion";

//component
import Mnavi from "./Mnavi";

//css
import "../../asset/css/common.css";
//etc
import menu_img from "../../asset/images/m-menu-icon.png";
import look_icon from "../../asset/images/look_header_icon.png";
import channel_talk_web_icon from "../../asset/images/channel_talk_web_icon.png";
import x_icon from "../../asset/images/icon_close_x.svg";
// header-icon
import history_icon from "../../asset/images/icon-history.png";
import alarm_icon from "../../asset/images/icon_alarm.png";
import my_page_icon from "../../asset/images/icon_my_page.png";
import select_site_icon from "../../asset/images/icon_select_site.png";

import { menuList } from "../../asset/js/dummy";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default";

import { siteInfoApi2, alarmCountApi, searchListApi } from "../../api/auth";
import ChannelService from "../../component/etc/ChannelService";

//sweet alert
import swal from "sweetalert";
import ChangeSitePop from "./Pop/ChangeSitePop";
import AlarmHeaderPop from "./Pop/AlarmHeaderPop";
import DemoBanner from "./DemoBanner";
import SiteSwitchModal from "../../pages/myPage/my-info/components/modal/site-switch/SiteSwitchModal";

export default function Header() {
  const dispatch = useDispatch();
  const { signToken } = useSelector((state) => ({
    signToken: state.token,
  }));
  const { u_name, rep_site, rep_con, u_phone } = useSelector((state) => ({
    u_name: state.user.u_name,
    rep_site: state.user.rep_site,
    rep_con: state.user.rep_con,
    u_phone: state.user?.u_phone,
  }));
  const navigate = useNavigate();
  const location = useLocation();

  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });
  const [nowUrl, setNowUrl] = useState(location.pathname);
  const [navList, setNavList] = useState(menuList);
  const [naviSwitch, setNaviSwitch] = useState(false);
  const [comeList, setComeList] = useState(menuList);
  const [keyword, setKeyword] = useState("");
  const [headerFocus, setHeaderFocus] = useState(false);

  const [recentKeyword, setRecentKeyword] = useState([]);
  const [lookInfoPopup, setLookInfoPopup] = useState(false);

  //클릭한 메뉴
  const [activeMenu, setActiveMenu] = useState({ id: 0, active: false });

  //로그아웃
  const logoutFnc = () => {
    swal({
      title: "LOGOUT",
      text: "로그아웃 하시겠습니까?",
      icon: "info",
      buttons: true,
      // dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(actions.setToken(false));
        dispatch(actions.setUser(userDefault));
      } else {
        swal("취소하셨습니다.");
      }
    });
  };

  //nav 이벤트
  const activeFunction = (location) => {
    const activeList = menuList.map((item) =>
      item.uri.indexOf(location.split("/")[1]) > 0
        ? { ...item, active: true }
        : { ...item }
    );
    setNavList(activeList);
    const filterList = activeList.filter((item) => item.active);
    if (filterList.length > 0) {
      setActiveMenu(filterList[0]);
    }
    setNowUrl(location.pathname);
  };

  useEffect(() => {
    ChannelService.boot({
      pluginKey: "25e0d610-b1b4-450c-96c5-9d8af0da85f7",
      customLauncherSelector: ".custom_alarm",
      hideChannelButtonOnBoot: true,
    });
  }, []);

  const [isChannelTalkOpen, setIsChannelTalkOpen] = useState(false);

  ChannelService.onShowMessenger(() => setIsChannelTalkOpen(true));
  ChannelService.onHideMessenger(() => setIsChannelTalkOpen(false));

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveMenu({ ...activeMenu, id: 0 });
    }
    activeFunction(location.pathname);
    setNaviSwitch(false);
  }, [location]);

  const [siteList, setSiteList] = useState([]);

  const userData = useSelector((state) => state.user);
  const recentSearchList = useSelector((state) => state.activity.activity_list);

  const [changeSitePop, setChangeSitePop] = useState(false);
  const [alarmPop, setAlarmPop] = useState(false);
  const [countAlarm, setCountAlarm] = useState(0);

  const getSiteInfo = async () => {
    const response = await siteInfoApi2();

    if (response.status === "success") {
      setSiteList(response.data);
    } else if (response.data.length === 1) {
      let newUserData = {
        ...userData,

        con_num: response.data[0].constructionNo,
        rep_con: response.data[0].constructionName,
        site_num: response.data[0].site.no,
        rep_site: response.data[0].site.name,
        isDemo: response.data[0].site.isDemo,
      };

      dispatch(actions.setUser(newUserData));
    }
  };

  const searchFnc = async (headerKey) => {
    // headerKey = 최근 검색어 눌렀을 때 실행
    if (keyword === "" && headerKey === undefined) {
      return swal("검색어를 입력해주세요.", "", "warning");
    }
    //focus 닫기
    setHeaderFocus(false);
    let copy = recentKeyword; //여기서 분기해주기
    if (keyword !== "") copy.unshift(keyword); //push는 뒤에 추가, unshift는 앞에 추가
    setRecentKeyword(copy);

    let total = {
      activity_list: recentKeyword,
    };

    dispatch(actions.setActivity(total));

    let totalKeyword = headerKey ? headerKey : keyword;

    let data = {
      keyword: totalKeyword.replace(/\s/g, ""),
      siteNo: userData.site_num,
    };
    searchListFnc(data);
  };

  const searchListFnc = async (data) => {
    const response = await searchListApi(data);
    if (response.status === "success") {
      navigate("/search_list", {
        state: {
          list: response.data,
          keyword: data.keyword,
        },
      });
    }
  };

  const onKeyPress = (e) => {
    if (e.key == "Enter") {
      searchFnc();
    }
  };

  useEffect(() => {
    if (recentSearchList) {
      const filterArr = recentSearchList.filter((elem, i) => {
        return recentSearchList.indexOf(elem) === i;
      });
      const realRecentSearchList = [...filterArr].slice(0, 5);
      const total = {
        activity_list: realRecentSearchList,
      };
      dispatch(actions.setActivity(total));
      setRecentKeyword(realRecentSearchList);
    }
  }, [headerFocus]); //최근검색어 나올 때마다 잘라주기

  useEffect(() => {
    setHeaderFocus(false);
  }, []);

  useEffect(() => {
    if (userData.site_num && signToken) {
      alarmCountFnc();
    }
  }, [alarmPop]);

  const alarmCountFnc = async () => {
    const response = await alarmCountApi(userData.site_num);
    if (response.status === "success") {
      setCountAlarm(response.data.count);
    } else {
      swal("error", "오류가 발생했습니다.");
    }
  };

  const typeMappingFnc = async () => {
    // constructionType을 갖고있지 않고, customer가 아닐 때!
    // 협력사 회원은 항상 construction Type을 갖고있다.

    //모든 분기를 subcontractor기준으로 해야할 듯? 싶다
    //왜냐하면 ctype을 subcontractor(협력사)들은 모두 갖고 있고
    //문제가 되는 것은 ctype이 없는 애들인데,

    // subcontractor가 아닌 애들을 다 커스터머로 변경
    let newUserData = {
      u_no: userData.u_no,
      u_name: userData.u_name,
      u_phone: userData?.u_phone,
      constructionType: "CUSTOMER",
      site_num: false,
      rep_site: false,
      rep_con: false,
    };

    dispatch(actions.setUser(newUserData));
  };

  // signtoken은 있고, site_num은 없을 때
  // 로그아웃하라는 alert 창을 띄워준다
  // 메인페이지에서는 x
  //
  useEffect(() => {
    if (signToken === false) {
      return;
    }
    if (userData.site_num === false) {
      getSiteInfo();
    }
    // 타입이 없으면 type mapping
    if (
      !userData?.hasOwnProperty("constructionType") ||
      (userData?.constructionType !== "CUSTOMER" &&
        userData?.constructionType !== "SUBCONTRACTOR")
    ) {
      typeMappingFnc();
    }
  }, [navigate]);

  const isDemo = userData?.isDemo;

  const [siteSwitchModal, setSiteSwitchModal] = useState(false);
  const onClickSiteSwitch = () => {
    setSiteSwitchModal(true);
  };

  return signToken ? (
    <>
      <div
        className={
          rep_site === undefined || rep_site === false
            ? // location.pathname === "/"
              "header_wrap2"
            : "header_wrap_else2"
        }
      >
        <div className="header_container">
          {isResponsive ? (
            <>
              <div
                className="m_header_right"
                onClick={() => {
                  setNaviSwitch(true);
                }}
              >
                <img src={menu_img} className="menu_icon" />
              </div>
              <Mnavi
                naviSwitch={naviSwitch}
                setNaviSwitch={setNaviSwitch}
                comeList={comeList}
                setComeList={setComeList}
                userName={u_name}
                selectedSite={rep_site}
                logoutFnc={logoutFnc}
              />
            </>
          ) : (
            <>
              <div className="main_search_container">
                <div className="main_look_container">
                  <img
                    src={look_icon}
                    className="main_search_icon"
                    onClick={() => {
                      navigate("/look");
                      // dispatch(actions.setActivity(activityDefault));
                    }}
                    onMouseOver={() => {
                      setLookInfoPopup(true);
                    }}
                    onMouseLeave={() => {
                      setLookInfoPopup(false);
                    }}
                  />
                  <AnimatePresence>
                    {lookInfoPopup ? (
                      <motion.p
                        className="look_desc_tooltip"
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          scale: [1, 1.1, 1.1, 1.1, 1],
                        }}
                        exit={{ opacity: 0 }}
                      >
                        인근공급사찾기
                      </motion.p>
                    ) : null}
                  </AnimatePresence>
                </div>

                <div className="search_input_container">
                  <input
                    type="text"
                    className="main_search_input"
                    placeholder="작업명 및 품목을 입력해주세요."
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setHeaderFocus(false);
                      } else {
                        setKeyword(e.target.value);
                        setHeaderFocus(true);
                      }
                    }}
                    onKeyPress={onKeyPress}
                    onClick={() => {
                      setHeaderFocus(true);
                    }}
                  />
                  {headerFocus && (
                    <div
                      className="dropdown_menu"
                      onMouseLeave={() => {
                        setHeaderFocus(false);
                      }}
                    >
                      <div className="dropdown_recent">
                        <p className="recent_keyword_title">최근검색어</p>
                        {/* useselector로 가져오기 */}
                        <div className="recent_keyword_container">
                          {recentSearchList?.map((a, i) => {
                            return (
                              <div
                                className="recent_search_list"
                                onClick={() => {
                                  searchFnc(a);
                                  setHeaderFocus(false);
                                }}
                                key={a}
                              >
                                <span>{a}</span>
                                <img
                                  src={x_icon}
                                  className="x_icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const filtering = recentSearchList.filter(
                                      (all) => all !== a
                                    );
                                    //filter
                                    let total = { activity_list: filtering };
                                    dispatch(actions.setActivity(total));
                                  }}
                                  alt="x_icon"
                                />
                              </div>
                            );
                          })}
                          {recentSearchList.length == 0 && (
                            <p className="no_recent_keyword">
                              최근 검색어가 없습니다.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <span
                  className="main_search_btn"
                  onClick={() => {
                    // dispatch 추가필요
                    searchFnc();
                  }}
                >
                  검색
                </span>
              </div>
              <div className="header_right_on">
                <div className="header_info">
                  {userData?.rep_con && <p>[{userData?.rep_con}]</p>}
                  {userData?.rep_site && <p>{userData?.rep_site}</p>}
                </div>
                <div
                  className="header_right_btn"
                  onClick={() => {
                    navigate("/my_page/history");
                  }}
                >
                  <img src={history_icon} className="header_my_page_icon" />
                  <p>히스토리</p>
                </div>
                <div
                  className="header_right_btn"
                  onClick={() => {
                    setChangeSitePop(false);
                    setAlarmPop(!alarmPop);
                  }}
                >
                  <img src={alarm_icon} className="header_my_page_icon" />
                  <p>알림</p>
                  {countAlarm !== 0 && (
                    <span className="count_alarm">{countAlarm}</span>
                  )}
                  {alarmPop && isResponsive === false && (
                    <AlarmHeaderPop
                      setAlarmPop={setAlarmPop}
                      alarmPop={alarmPop}
                    />
                  )}
                </div>
                <div
                  className="header_right_btn"
                  onClick={() => {
                    navigate("/my_page/my_info");
                  }}
                >
                  <img src={my_page_icon} className="header_my_page_icon" />
                  <p title={u_name + "님"}>{u_name}님</p>
                </div>
                {!isDemo && (
                  <div
                    className="header_right_btn"
                    onClick={() => {
                      setAlarmPop(false);
                      onClickSiteSwitch();
                    }}
                  >
                    <img src={select_site_icon} className="header_site_icon" />
                    <p>현장선택</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {isDemo && <DemoBanner />}
        {siteSwitchModal && (
          <SiteSwitchModal
            siteSwitchModal={siteSwitchModal}
            setSiteSwitchModal={setSiteSwitchModal}
          />
        )}
      </div>

      <div
        className={
          (location.pathname === "/qna" && isResponsive) || isChannelTalkOpen
            ? "displayNone"
            : "custom_alarm"
        }
      >
        <img src={channel_talk_web_icon} />
      </div>
    </>
  ) : (
    <div
      className={
        location.pathname === "/" ||
        location.pathname === "/login" ||
        rep_site === false
          ? "header_wrap"
          : "header_wrap_else"
      }
    >
      <div className="header_container">
        <div
          className="header_left"
          onClick={() => {
            navigate("/");
          }}
        >
          <h1>{/* 로고이미지 */}</h1>
          <p>고객사</p>
        </div>
        {isResponsive ? null : (
          <div className="header_right">
            <Link to="/login">로그인</Link>
            <Link to="/signup">회원가입</Link>
          </div>
        )}
      </div>
      <div class="custom_alarm">
        <img src={channel_talk_web_icon} />
      </div>
    </div>
  );
}
