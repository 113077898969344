export default function DownloadSVG({ size = "small", stroke = "#FFFFFF" }) {
  if (size === "xsmall") size = "16";
  if (size === "small") size = "20";
  if (size === "medium") size = "24";
  if (size === "large") size = "32";
  if (size === "xlarge") size = "40";

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 2.5V13.75"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.37512 8.125L10.0001 13.75L15.6251 8.125"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.12512 16.875H16.8751"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
