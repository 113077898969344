import React, { useState, useEffect } from "react";
//library
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import moment from "moment";

//component
import Mheader from "../../../component/common/Mheader";
import ContractStopPop from "../../../component/common/Pop/ContractStopPop";
import ContractCancelPop from "../../../component/common/Pop/ContractCancelPop";
import ContractPauseCancel from "../../../component/common/Pop/ContractPauseCancel";
//css
import * as S from "../../../asset/css/contract.styles.js";
import "../../../asset/css/contract.css";
//etc
import { contractListApi } from "../../../api/auth";

//redux
import { useSelector } from "react-redux";

//sweet alert
import DashBoardTabs from "../../../component/etc/DashBoardTabs";
import { useMediaQuery } from "react-responsive";
import Pagination from "../../../component/ui/pagination/Pagination.js";

registerLocale("ko", ko);

export default function Contract() {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const { page } = useParams();
  const location = useLocation();
  const url = "/management/contract/";
  const fromPage = location?.state?.fromPage;

  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [cancelPop, setCancelPop] = useState(false);
  const [pauseCancelPop, setPauseCancelPop] = useState(false);
  const [maxNum, setMaxNum] = useState(0);
  const [firstData, setFirstData] = useState({
    s_no: 0,
    nowPage: 1,
    searchData: fromPage || "ALL",
    startDate: "2021-01-01",
    endDate: moment().format("yyyy-MM-DD"),
  });
  const [contractNum, setContractNum] = useState(0);
  const [contractList, setContractList] = useState([]);
  const [conStateData, setConStateData] = useState({});
  const [contractPop, setContractPop] = useState(false);
  const [prsContract, setPrsContract] = useState(0);
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);

  //조회
  const lookUpFnc = async () => {
    const response = await contractListApi(
      firstData.s_no,
      currentPage,
      firstData.searchData,
      firstData.startDate,
      firstData.endDate
    );

    if (response.status === "success") {
      setContractList(response.data.cList);
      setMaxNum(response.data.maxNum);
      setConStateData(response.data.state);
    } else {
    }
  };

  //계약 타입
  const contractType = (data) => {
    if (data === "C") {
      return "단가계약";
    } else if (data === "S") {
      return "단일 건 계약";
    }
  };

  //날짜
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    let copy = firstData;
    copy.s_no = userData.site_num;
    copy.lastDate = formatDate(new Date());
    copy.startDate = formatDate(startDate);
    setFirstData(copy);
  }, []);

  useEffect(() => {
    lookUpFnc();
  }, [firstData]);

  useEffect(() => {
    if (firstData.s_no !== 0) {
      lookUpFnc();
    }
  }, [currentPage, firstData.searchData]); //필터랑, 페이지 바뀔 때만 리스트새로받아오기

  const _move2Success = () => {
    lookUpFnc();
  };

  //계약 종료일
  const endDateFnc = (databid, bidTerm) => {
    return formatDate(databid.setMonth(databid.getMonth() + Number(bidTerm)));
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("전체");

  const onChangeSelectValue = (e) => {
    setSelectValue(e.currentTarget.value);
    setCurrentPage(1);
  };

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setFirstData({
      ...firstData,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    });
  };

  useEffect(() => {
    if (selectValue === "최근 1개월") setDate(1, "M");
    if (selectValue === "최근 3개월") setDate(3, "M");
    if (selectValue === "최근 6개월") setDate(6, "M");
    if (selectValue === "최근 1년") setDate(1, "y");
    if (selectValue === "전체") {
      setStartDate(new Date("2021-01-01"));
      setEndDate(new Date(moment().format("yyyy-MM-DD")));
      setFirstData({
        ...firstData,
        startDate: "2021-01-01",
        endDate: moment().format("yyyy-MM-DD"),
      });
    }
  }, [selectValue]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`${url}${page}`);
  };

  return (
    <S.Wrapper>
      <Mheader title="계약관리" />
      <S.DashboardContainer>
        {isDesktop && <DashBoardTabs />}
        <S.DashBoardContent>
          <S.ContentHeader>
            <S.TitleWrap>
              <S.Title>나의 계약관리 현황</S.Title>
              {isDesktop && (
                <>
                  <div>|</div>
                  <S.SelectedDate>
                    {firstData?.startDate} ~ {firstData?.endDate}
                  </S.SelectedDate>
                </>
              )}
            </S.TitleWrap>

            {isDesktop && (
              <S.AlignWrap>
                <S.DateRangeSelect
                  onChange={onChangeSelectValue}
                  value={selectValue}
                >
                  <option>최근 1개월</option>
                  <option>최근 3개월</option>
                  <option>최근 6개월</option>
                  <option>최근 1년</option>
                  <option>전체</option>
                </S.DateRangeSelect>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setFirstData({
                        ...firstData,
                        startDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                      setCurrentPage(1);
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setFirstData({
                        ...firstData,
                        endDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                      setCurrentPage(1);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>
              </S.AlignWrap>
            )}
          </S.ContentHeader>

          <S.ContentBody>
            <S.ContractStateWrap>
              <S.ContractState>계약 전</S.ContractState>
              <S.ContractStartBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    searchData: "START",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {conStateData.start}건
              </S.ContractStartBtn>
            </S.ContractStateWrap>
            <S.ContractStateWrap>
              <S.ContractState>계약완료</S.ContractState>
              <S.ContractAliveBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    searchData: "ALIVE",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {conStateData.alive}건
              </S.ContractAliveBtn>
            </S.ContractStateWrap>

            {isDesktop && <S.DivideLine></S.DivideLine>}

            <S.ContractStateWrap>
              <S.ContractState>전체보기</S.ContractState>
              <S.ContractAllBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    searchData: "ALL",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {Object.values(conStateData).reduce(
                  (acc, cur) => +acc + +cur,
                  0
                )}
                건
              </S.ContractAllBtn>
            </S.ContractStateWrap>
          </S.ContentBody>

          <S.ContentFooter>
            <S.CancelStopNumBtn
              firstData={firstData}
              onClick={() => {
                setFirstData({
                  ...firstData,
                  searchData: "CANCEL",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              계약중지 <span>{conStateData.cancel_stop}</span>건
            </S.CancelStopNumBtn>
            <S.GraceNumBtn
              firstData={firstData}
              onClick={() => {
                setFirstData({
                  ...firstData,
                  searchData: "GRACE",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              연장가능 계약 <span>{conStateData.grace}</span>건
            </S.GraceNumBtn>
            <S.EndNumBtn
              firstData={firstData}
              onClick={() => {
                setFirstData({
                  ...firstData,
                  searchData: "END",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              계약종료 <span>{conStateData.end}</span>건
            </S.EndNumBtn>
          </S.ContentFooter>
        </S.DashBoardContent>

        {isDesktop && (
          <>
            <S.SearchResultWrap>
              <S.SearchResult>
                총 <span>{maxNum}</span>건이 검색되었습니다.
              </S.SearchResult>

              <S.OrderCreateBtnWrapper
                onClick={() => {
                  navigate("/management/bid/request_reorder");
                }}
              >
                <S.OrderCreateBtn>단가 계약기반 주문</S.OrderCreateBtn>
                <S.OrderCreateImg src="/Alarm Play.png" />
              </S.OrderCreateBtnWrapper>
            </S.SearchResultWrap>

            {contractList?.map((el, idx) => (
              <S.ContractListWrapper key={idx}>
                <S.ListHeader>
                  <S.ContractNo>계약번호 : {el.ct_code}</S.ContractNo>
                  <S.SiteName>현장명 : {el.s_name}</S.SiteName>
                  <S.ContractStep el={el}>
                    <S.Step></S.Step>
                    <S.Step>
                      <p>계약 전</p>
                    </S.Step>
                    <S.Step>
                      <p>계약완료</p>
                    </S.Step>
                  </S.ContractStep>
                </S.ListHeader>

                <S.ListBody>
                  <S.ListInfo>
                    <S.Badge el={el}>
                      {el.ct_state === "START" && "계약 전"}
                      {el.ct_state === "ALIVE" && "계약완료"}
                      {el.ct_state === "CANCEL" && "계약취소"}
                      {el.ct_state === "HOLD" && "중지요청"}
                      {el.ct_state === "STOP" && "계약중지"}
                      {el.ct_state === "GRACE" && "연장가능계약"}
                      {el.ct_state === "END" && "계약종료"}
                    </S.Badge>
                    <S.NameWrapper>
                      <S.CompanyName>{el.c_name}</S.CompanyName>
                      <div>|</div>
                      <S.ProductName>{el.product_desc}</S.ProductName>
                    </S.NameWrapper>
                    <S.DetailInfo>
                      <S.BidType>
                        계약형태 : 단가계약 {`(${el.bid_term}개월)`}
                      </S.BidType>
                      <S.BidDate>
                        계약기간 : {el.ct_sdate?.slice(0, 10)} ~{" "}
                        {el.ct_edate?.slice(0, 10)}
                      </S.BidDate>
                      <S.BidCompany>
                        계약금액 : {el.total_amount.toLocaleString("ko")}원
                      </S.BidCompany>
                    </S.DetailInfo>
                  </S.ListInfo>
                  <S.BtnWrapper>
                    <S.ContractContentBtn
                      onClick={() => {
                        navigate(
                          // 원래는 c_no이였음
                          `/management/contract/document/${el.ct_no}`,
                          {
                            state: {
                              contractList: contractList[idx].ct_no,
                              // contractData: el,
                            },
                          }
                        );
                      }}
                    >
                      계약 내역 확인 +
                    </S.ContractContentBtn>
                    {el.ct_state === "HOLD" && (
                      <S.ContractAgreeBtn
                        onClick={() => {
                          setContractNum(el.ct_no);
                          setPauseCancelPop(true);
                        }}
                      >
                        중지 요청 취소
                      </S.ContractAgreeBtn>
                    )}
                  </S.BtnWrapper>
                </S.ListBody>
              </S.ContractListWrapper>
            ))}
            <div className="contract_pagination_container">
              <Pagination
                current={currentPage}
                total={maxNum}
                onChange={handlePageChange}
                pageSize={10}
              />
            </div>
          </>
        )}
      </S.DashboardContainer>

      {pauseCancelPop && (
        <ContractPauseCancel
          move2Success={_move2Success}
          setPauseCancelPop={setPauseCancelPop}
          contractNum={contractNum}
        />
      )}
      {cancelPop && ( //ㄱㅖ약 취소요청 - 체결중일 때만 사용가능
        <ContractCancelPop
          setCancelPop={setCancelPop}
          contractList={contractList[prsContract]}
          contractNum={contractNum}
        />
      )}
      {contractPop && (
        <ContractStopPop
          setContractPop={setContractPop}
          state="중지"
          move2Success={_move2Success}
          contractList={contractList[prsContract]}
          contractNum={contractNum}
        />
      )}

      {isMobile && (
        <div className="contract_wrap">
          <S.AlignWrap>
            <S.DateWrap>
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setFirstData({
                    ...firstData,
                    startDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>

            <S.DateWrap>
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setFirstData({
                    ...firstData,
                    endDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>
          </S.AlignWrap>

          <S.SearchResultWrap>
            <S.SearchResult>
              검색결과 : <span>{maxNum}</span>건
            </S.SearchResult>
            <button
              className="order_create_btn"
              onClick={() => {
                navigate("/management/bid/request_reorder");
              }}
            >
              단가 계약기반 주문
            </button>
          </S.SearchResultWrap>

          <div className="contract_container">
            <div className="contract_list">
              <div className="contract_list_btm">
                {contractList.map((el, i) => {
                  return (
                    <div className="contract_content" key={el.bid_no}>
                      <div className="content_top">
                        <span>계약번호 :&nbsp;</span>
                        <span className="public_code">{el.ct_code}</span>
                      </div>
                      <div className="content_btm">
                        <div className="content_box">
                          <p className="company_img">
                            <img src={el.f_root} />
                          </p>
                          <div className="contract_info">
                            <div className="icon_box">
                              <S.Badge el={el}>
                                {el.ct_state === "START" && "계약 전"}
                                {el.ct_state === "ALIVE" && "계약완료"}
                                {el.ct_state === "CANCEL" && "계약취소"}
                                {el.ct_state === "HOLD" && "중지요청"}
                                {el.ct_state === "STOP" && "계약중지"}
                                {el.ct_state === "GRACE" && "연장가능계약"}
                                {el.ct_state === "END" && "계약종료"}
                              </S.Badge>
                            </div>
                            <p className="contractor">
                              <span>
                                {"[" + el.con_name + "]"}&nbsp;
                                {"/ " + el.c_name}&nbsp;{el.s_name}
                              </span>
                              <span>{contractType(el.bid_type)}</span>
                            </p>
                            <div className="contract_text">
                              {el.bid_type === "C" ? (
                                <p className="company_info">
                                  계약기간 : {el.ct_sdate}&nbsp;~&nbsp;
                                  {endDateFnc(
                                    new Date(el.ct_sdate),
                                    el.bid_term
                                  )}
                                </p>
                              ) : null}
                              {el.ct_state !== "START" && (
                                <p className="company_info">
                                  계약 생성일시 : {el.ct_regdate}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="contract_more">
                          {el.ct_state === "HOLD" && (
                            <p
                              onClick={() => {
                                setContractNum(el.ct_no);
                                setPauseCancelPop(true);
                              }}
                            >
                              중지 요청 취소
                            </p>
                          )}
                          <p
                            onClick={() => {
                              navigate(
                                // 원래는 c_no이였음
                                `/management/contract/document/${el.ct_no}`,
                                {
                                  state: {
                                    contractList: contractList[i].ct_no,
                                    // contractData: el,
                                  },
                                }
                              );
                            }}
                          >
                            계약 내역 확인 +
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="contract_pagination_container">
                <Pagination
                  current={currentPage}
                  total={maxNum}
                  onChange={handlePageChange}
                  pageSize={10}
                />
              </div>
            </div>
          </div>
          {pauseCancelPop && (
            <ContractPauseCancel
              move2Success={_move2Success}
              setPauseCancelPop={setPauseCancelPop}
              contractNum={contractNum}
            />
          )}
          {cancelPop && ( //ㄱㅖ약 취소요청 - 체결중일 때만 사용가능
            <ContractCancelPop
              setCancelPop={setCancelPop}
              contractList={contractList[prsContract]}
              contractNum={contractNum}
            />
          )}
          {contractPop && (
            <ContractStopPop
              setContractPop={setContractPop}
              state="중지"
              move2Success={_move2Success}
              contractList={contractList[prsContract]}
              contractNum={contractNum}
            />
          )}
        </div>
      )}
    </S.Wrapper>
  );
}
