import React from "react";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";
import Button from "../../../../../../component/ui/button/Button";
import s from "./MyInfoChangeModal.module.css";

const MyInfoChangeModal = ({
  myInfoChangeModal,
  setMyInfoChangeModal,
  setRankUpdateModal,
  setPwdUpdateModal,
  setWithdrawModal,
}) => {
  const onClickCancel = () => {
    setMyInfoChangeModal(false);
  };

  const onClickUpdateRank = () => {
    setRankUpdateModal(true);
  };

  const onClickUpdatePwd = () => {
    setPwdUpdateModal(true);
  };

  const onClickWithdraw = () => {
    setWithdrawModal(true);
  };

  return (
    <Modal
      size="xsmall"
      open={myInfoChangeModal}
      title={
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          내정보 변경
        </Typography>
      }
      onCancel={onClickCancel}
      footer={[
        <Typography
          component="span"
          type="c4"
          color={COLOR.GRAY.L07}
          style={{ cursor: "pointer" }}
          onClick={onClickWithdraw}
        >
          회원 탈퇴
        </Typography>,
      ]}
    >
      <div className={s.body_container}>
        <Button
          size="large"
          width="100%"
          type="secondary"
          onClick={onClickUpdateRank}
        >
          직급 변경
        </Button>
        <Button
          size="large"
          width="100%"
          type="secondary"
          onClick={onClickUpdatePwd}
        >
          비밀번호 변경
        </Button>
      </div>
    </Modal>
  );
};

export default MyInfoChangeModal;
