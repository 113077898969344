import useMyPageHandler from "../../../hooks/handler/useMyPageHandler";
import Mheader from "../../../component/common/Mheader";
import Title from "./components/elements/title/Title";
import MyInfo from "./components/elements/info/my/MyInfo";
import OrgInfo from "./components/elements/info/org/OrgInfo";
import SiteInfo from "./components/elements/info/site/SiteInfo";
import MemberInfo from "./components/elements/info/member/MemberInfo";
import SwitchTypeModal from "./components/modal/switch-type/SwitchTypeModal";
import MyInfoChangeModal from "./components/modal/my-info-change/MyInfoChangeModal";
import WithdrawModal from "./components/modal/withdraw/WithdrawModal";
import RankUpdateModal from "./components/modal/rank-update/RankUpdateModal";
import PwdUpdateModal from "./components/modal/pwd-update/PwdUpdateModal";
import SiteAttendModal from "./components/modal/site-attend/SiteAttendModal";
import SiteSwitchModal from "./components/modal/site-switch/SiteSwitchModal";
import SiteInviteModal from "./components/modal/site-invite/SiteInviteModal";
import s from "./myPage.module.css";

const MyPage = () => {
  const {
    isDemo,
    myPageInfo,
    switchTypeModal,
    setSwitchTypeModal,
    myInfoChangeModal,
    setMyInfoChangeModal,
    rankUpdateModal,
    setRankUpdateModal,
    pwdUpdateModal,
    setPwdUpdateModal,
    withdrawModal,
    setWithdrawModal,
    siteAttendModal,
    setSiteAttendModal,
    siteSwitchModal,
    setSiteSwitchModal,
    siteAttendApproveModal,
    setSiteAttendApproveModal,
    siteAttendRejectModal,
    setSiteAttendRejectModal,
    siteInviteModal,
    setSiteInviteModal,
  } = useMyPageHandler();

  return (
    <div className={s.page_container}>
      <Mheader title="마이페이지" />

      <section className={s.title_section}>
        <Title setSwitchTypeModal={setSwitchTypeModal} />
      </section>

      <section className={s.my_info_container}>
        <MyInfo
          my={myPageInfo?.user}
          setMyInfoChangeModal={setMyInfoChangeModal}
          isDemo={isDemo}
        />
      </section>

      <section className={s.org_info_container}>
        <OrgInfo
          org={myPageInfo?.construction}
          logoUrl={myPageInfo?.constructionLogo}
          isDemo={isDemo}
        />
      </section>

      <section className={s.site_info_container}>
        <SiteInfo
          site={myPageInfo?.site}
          setSiteAttendModal={setSiteAttendModal}
          setSiteSwitchModal={setSiteSwitchModal}
          isDemo={isDemo}
        />
      </section>

      <section className={s.member_info_container}>
        <MemberInfo
          memberList={myPageInfo?.memberList}
          siteAttendApproveModal={siteAttendApproveModal}
          setSiteAttendApproveModal={setSiteAttendApproveModal}
          siteAttendRejectModal={siteAttendRejectModal}
          setSiteAttendRejectModal={setSiteAttendRejectModal}
          setSiteInviteModal={setSiteInviteModal}
          isDemo={isDemo}
        />
      </section>

      {switchTypeModal && (
        <SwitchTypeModal
          switchTypeModal={switchTypeModal}
          setSwitchTypeModal={setSwitchTypeModal}
        />
      )}
      {myInfoChangeModal && (
        <MyInfoChangeModal
          myInfoChangeModal={myInfoChangeModal}
          setMyInfoChangeModal={setMyInfoChangeModal}
          setRankUpdateModal={setRankUpdateModal}
          setPwdUpdateModal={setPwdUpdateModal}
          setWithdrawModal={setWithdrawModal}
        />
      )}
      {rankUpdateModal && (
        <RankUpdateModal
          rankUpdateModal={rankUpdateModal}
          setRankUpdateModal={setRankUpdateModal}
        />
      )}
      {pwdUpdateModal && (
        <PwdUpdateModal
          pwdUpdateModal={pwdUpdateModal}
          setPwdUpdateModal={setPwdUpdateModal}
        />
      )}
      {withdrawModal && (
        <WithdrawModal
          withdrawModal={withdrawModal}
          setWithdrawModal={setWithdrawModal}
        />
      )}
      {siteAttendModal && (
        <SiteAttendModal
          siteAttendModal={siteAttendModal}
          setSiteAttendModal={setSiteAttendModal}
        />
      )}
      {siteSwitchModal && (
        <SiteSwitchModal
          siteSwitchModal={siteSwitchModal}
          setSiteSwitchModal={setSiteSwitchModal}
        />
      )}
      {siteInviteModal && (
        <SiteInviteModal
          siteInviteModal={siteInviteModal}
          setSiteInviteModal={setSiteInviteModal}
        />
      )}
    </div>
  );
};

export default MyPage;
