import React, { useEffect, useState } from "react";
//library
import { useNavigate } from "react-router-dom";
//component
//css
import "../../../asset/css/notice.css";
import Mheader from "../../../component/common/Mheader";
import { noticeDataApi } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";
import Pagination from "../../../component/ui/pagination/Pagination";

export default function Notice() {
  const navigate = useNavigate();
  const [maxNum, setMaxNum] = useState(0);
  const [firstData, setFirstData] = useState({
    nowPage: 1,
  });
  const [noticeData, setNoticeData] = useState([]);

  const noticeDataFnc = async () => {
    const response = await noticeDataApi(firstData.nowPage);

    if (response.status === "success") {
      setMaxNum(response.data.maxNum);
      setNoticeData(response.data.bList);
    } else if (response.status === "fail") {
      swal("다시 시도해주세요.", "", "warning");
    }
  };

  useEffect(() => {
    noticeDataFnc();
  }, [firstData]);

  const handlePageChange = (page) => {
    setFirstData((prevState) => ({
      ...prevState,
      nowPage: page,
    }));
  };

  return (
    <div className="notice_wrap">
      <Mheader title="공지사항" />
      <div className="notice_container wd" style={{ paddingTop: "30px" }}>
        <div className="top_pagenation notice_h2">
          <h2>공지사항</h2>
        </div>
        <table className="notice_content">
          {noticeData?.map((data, i) => {
            return (
              <tr
                onClick={() => {
                  navigate("/my_page/notice/" + data.bd_no, {
                    state: {
                      bd_no: data.bd_no,
                    },
                  });
                }}
                key={data.bd_regdate}
              >
                <td className="notice_title">{data.bd_title}</td>
                <td className="notice_info">
                  <p>{data.bd_regdate}</p>
                  <p>공새로</p>
                </td>
              </tr>
            );
          })}
        </table>
        <div className="notice_pagination_container">
          <Pagination
            current={firstData.nowPage}
            total={maxNum}
            onChange={handlePageChange}
            pageSize={10}
          />
        </div>
      </div>
    </div>
  );
}
