import { useDispatch, useSelector } from "react-redux";
import useSwitchType from "../useMutation/useSwitchType";
import { actions } from "../../redux/action";
import { useNavigate } from "react-router-dom";

const useSwitchTypeModalHandler = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSwitchTypeSuccessCb = ({ status, data }) => {
    if (status === "success") {
      const newUser = {
        ...user,
        constructionType: data.constructionType,
        site_num: data.site.no,
        rep_site: data.site.name,
        rep_con: data.constructionName,
        isDemo: data.site.isDemo,
      };
      dispatch(actions.setUser(newUser));
      navigate("/");
    }
  };

  const onSwitchTypeFailCb = (error) => {
    console.error("error switching type :", error);
  };

  const { mutate: switchType } = useSwitchType(
    onSwitchTypeSuccessCb,
    onSwitchTypeFailCb
  );

  const handleSwitchType = (isDemo) => {
    switchType(isDemo);
  };

  return {
    handleSwitchType,
  };
};

export default useSwitchTypeModalHandler;
