import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constant/QueryKeys";
import { searchConstructionApi } from "../../api/auth";

const useSearchOrg = (keyword) => {
  return useQuery({
    queryKey: [QUERY_KEYS.SEARCH_ORG],
    queryFn: () => searchConstructionApi(keyword),
  });
};

export default useSearchOrg;
