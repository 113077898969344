import Modal from "../../../../../component/ui/modal/Modal";
import Typography from "../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../constant/Palette";
import Button from "../../../../../component/ui/button/Button";

const LoginFailModal = ({ loginFailModal, setLoginFailModal }) => {
  const onClickCancel = () => {
    setLoginFailModal(false);
  };

  return (
    <Modal
      size="xsmall"
      type="error"
      open={loginFailModal}
      title={
        <Typography component="p" type="h4" color={COLOR.SUB.RED.L10}>
          로그인 실패
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="primary" size="small" onClick={onClickCancel}>
          확인
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        이메일 혹은 비밀번호가 올바르지 않습니다.
      </Typography>
      <Typography component="p" type="c2">
        다시 시도해주세요.
      </Typography>
    </Modal>
  );
};

export default LoginFailModal;
