import React, {
  useLayoutEffect,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
//library
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
//css
import "../../asset/css/mainRecommend.css";

//icon
import { ReactComponent as ArrowIcon } from "../../asset/images/ic_arrow.svg";

import i_cloud from "../../asset/images/icon_weather_cloud.png";
import i_mix from "../../asset/images/icon_weather_mix.png";
import i_muddy from "../../asset/images/icon_weather_muddy.png";
import i_rainy from "../../asset/images/icon_weather_rainy.png";
import i_shower from "../../asset/images/icon_weather_shower.png";
import i_snow from "../../asset/images/icon_weather_snow.png";
import i_sunny from "../../asset/images/icon_weather_sunny.png";
import i_loud from "../../asset/images/icon_loud.png";
import i_arrow from "../../asset/images/icon_right_arrow.png";

import i_small_rain from "../../asset/images/icon_weather_small_rain.png";
import i_arrow_gray from "../../asset/images/icon_right_arrow_gray.png";
//sweet alert
import swal from "sweetalert";

import moment from "moment";
//etc
import {
  weatherShortApi,
  recommendProductApi,
  fetchGroupBuyingListApi,
} from "../../api/auth";

import { Bar } from "react-chartjs-2";

//redux
import { useDispatch, useSelector } from "react-redux";
import Mheader from "../../component/common/Mheader";

function useWindowSize() {
  const [size, setSize] = useState([1920, 1000]);
  useLayoutEffect(() => {
    function updateSize() {
      // setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function MainRecommend(props) {
  const navigate = useNavigate();
  const [arrowState, setArrowState] = useState(false);

  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  useEffect(() => {
    if (isResponsive) {
      swal({
        title: "알림",
        text: "AI 데이터 추천 페이지는 웹 브라우저를 이용해주세요.",
        icon: "info",
      }).then((value) => {
        if (value) navigate("/");
      });
    }
  }, [isResponsive]);

  const [windowWidth, windowHeight] = useWindowSize();
  const items = [
    {
      title: "한국건설신문",
      content: "철강협회･관세청 ‘철강제품 수입신고 가이드라인’ 신설",
      href: "http://www.conslove.co.kr/news/articleView.html?idxno=76354",
    },
    {
      title: "한국건설신문",
      content: "골재 품질검사제도 권역별 설명회 개최",
      href: "http://www.conslove.co.kr/news/articleView.html?idxno=74745",
    },
    {
      title: "한국건설신문",
      content: "박영순 의원, 화물 운송 ‘안전운임제도 확대 법안’ 발의",
      href: "http://www.conslove.co.kr/news/articleView.html?idxno=73537",
    },
    {
      title: "한국건설신문",
      content:
        "홍기원 의원, 건설현장 사용자재 품질 개선을 위한 「건설기술진흥법」 대표발의",
      href: "http://www.conslove.co.kr/news/articleView.html?idxno=69810",
    },
    {
      title: "한국건설신문",
      content: "포스코건설, 2025년까지 2000억원 규모 녹색제품 구매",
      href: "http://www.conslove.co.kr/news/articleView.html?idxno=69316",
    },
    {
      title: "공새로 뉴스",
      content: "공새로 앱런칭 공지",
      href: "https://customer.gongsaero.com/my_page/notice",
    },
  ];
  const itemSize = items.length;
  const sliderPadding = 40;
  const sliderPaddingStyle = `0 ${sliderPadding}px`;
  const newItemWidth = getNewItemWidth();
  const transitionTime = 500;
  const transitionStyle = `transform ${transitionTime}ms ease 0s`;
  const 양끝에_추가될_데이터수 = 2;
  const [currentIndex, setCurrentIndex] = useState(양끝에_추가될_데이터수);
  const [slideTransition, setTransition] = useState(transitionStyle);
  const [isSwiping, setIsSwiping] = useState(false);
  const [slideY, setslideY] = useState(null);
  const [prevslideY, setPrevslideY] = useState(false);
  let isResizing = useRef(false);

  let slides = setSlides();
  function setSlides() {
    let addedFront = [];
    let addedLast = [];
    var index = 0;
    while (index < 양끝에_추가될_데이터수) {
      addedLast.push(items[index % items.length]);
      addedFront.unshift(items[items.length - 1 - (index % items.length)]);
      index++;
    }
    return [...addedFront, ...items, ...addedLast];
  }

  function getNewItemWidth() {
    let itemWidth = windowWidth * 0.9 - sliderPadding * 2;
    itemWidth = itemWidth > 1100 ? 1100 : itemWidth;
    return itemWidth;
  }

  useEffect(() => {
    isResizing.current = true;
    setIsSwiping(true);
    setTransition("");
    setTimeout(() => {
      isResizing.current = false;
      if (!isResizing.current) setIsSwiping(false);
    }, 1000);
  }, [windowWidth]);

  useInterval(
    () => {
      handleSlide(currentIndex + 1);
    },
    !isSwiping && !prevslideY ? 3000 : null
  );

  const replaceSlide = (index) => {
    setTimeout(() => {
      setTransition("");
      setCurrentIndex(index);
    }, transitionTime);
  };

  const handleSlide = (index) => {
    setCurrentIndex(index);
    if (index - 양끝에_추가될_데이터수 < 0) {
      index += itemSize;
      replaceSlide(index);
    } else if (index - 양끝에_추가될_데이터수 >= itemSize) {
      index -= itemSize;
      replaceSlide(index);
    }
    setTransition(transitionStyle);
  };

  const handleSwipe = (direction) => {
    setIsSwiping(true);
    handleSlide(currentIndex + direction);
  };

  const getItemIndex = (index) => {
    index -= 양끝에_추가될_데이터수;
    if (index < 0) {
      index += itemSize;
    } else if (index >= itemSize) {
      index -= itemSize;
    }
    return index;
  };

  const getclientY = (event) => {
    return event._reactName == "onTouchStart"
      ? event.touches[0].clientY
      : event._reactName == "onTouchMove" || event._reactName == "onTouchEnd"
      ? event.changedTouches[0].clientY
      : event.clientY;
  };

  const handleTouchStart = (e) => {
    setPrevslideY((prevslideY) => getclientY(e));
  };

  const handleTouchMove = (e) => {
    if (prevslideY) {
      setslideY((slideY) => getclientY(e) - prevslideY);
    }
  };

  const handleMouseSwipe = (e) => {
    if (slideY) {
      const currentTouchX = getclientY(e);
      if (prevslideY > currentTouchX + 100) {
        handleSlide(currentIndex + 1);
      } else if (prevslideY < currentTouchX - 100) {
        handleSlide(currentIndex - 1);
      }
      setslideY((slideY) => null);
    }
    setPrevslideY((prevslideY) => null);
  };

  // 날씨 데이터
  const signToken = useSelector((state) => state.token);
  const siteNum = useSelector((state) => state.user.site_num);
  const [weatherData, setWeatherData] = useState([]);
  const [weatherProductData, setWeatherProductData] = useState([]);

  //
  const weatherShortFnc = async () => {
    const response = await weatherShortApi(siteNum);
    if (response.status === "success") {
      setWeatherData(response.data.weatherList);
      setWeatherProductData(response.data.productTagResultList);
    }
  };

  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [recommendList, setRecommendList] = useState([]);
  const [tagId, setTagId] = useState(1);

  const recommendProductFnc = async () => {
    const response = await recommendProductApi(tagId); //처음 공정현황(공통가설) 부르기

    if (response.status === "success") {
      setCategoryList(response.data.categoryTagInfoList);
      setProductList(response.data.productList);
      setRecommendList(response.data.recommedCategoryList); // 3개
    }
  };

  useEffect(() => {
    //
    if (!!siteNum) {
      weatherShortFnc(); //날씨 부르기
      recommendProductFnc(); // 처음 공정현황(공통가설) 부르기
    }
  }, [siteNum]);

  useEffect(() => {
    recommendProductFnc();
  }, [tagId]);

  // useEffect(() => {
  //     function tick() {
  //         savedCallback.current();
  //     }
  //     if (delay !== null) {
  //         let id = setInterval(tick, delay);
  //         return () => clearInterval(id);
  //     }
  // }, [delay]);

  useEffect(() => {
    if (weatherData.length === 0) {
      let timer = setTimeout(() => {
        weatherShortFnc();
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [weatherData]);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  // 차트 데이터

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
        // text:
        //     tagId === 1 ? '공통가설 사용현황' :
        //     tagId === 2 ? '토목공사 사용현황' :
        //     tagId === 3 ? '건축공사' :
        //     tagId === 4 ? '기계/설비공사' :
        //     tagId === 5 ? '전기/소방공사' :
        //     tagId === 6 ? '조경공사' : null
        // ,
      },
    },
  };

  const dataHorBar = {
    labels: [
      recommendList ? recommendList[0]?.scName : "",
      recommendList ? recommendList[1]?.scName : "",
      recommendList ? recommendList[2]?.scName : "",
    ],
    datasets: [
      {
        label: "품목개수",
        backgroundColor: "#E1E5F6",
        borderColor: "#afbbdf",
        borderWidth: 2,
        hoverBackgroundColor: "#E1E5F6",
        // hoverBorderColor: '#002863',
        // data: [ 3026, 2373, 1840]
        data: [
          recommendList ? recommendList[0]?.productCount : 0,
          recommendList ? recommendList[1]?.productCount : 0,
          recommendList ? recommendList[2]?.productCount : 0,
        ],
      },
    ],
  };

  // 자재 공동 구매
  const [groupBuyingList, setGroupBuyingList] = useState();

  const fetchGroupBuyingList = useCallback(async () => {
    const response = await fetchGroupBuyingListApi();
    if (response.status === "success") setGroupBuyingList(response.data);
  }, []);

  useEffect(() => {
    fetchGroupBuyingList();
  }, [fetchGroupBuyingList]);

  return (
    <div className="c_main_wrap">
      <Mheader title="공새로 맞춤추천" />
      <div className="c_main_container">
        {/* <div>송도 b3 현장</div> */}
        <div className="c_dashboard_switch_tab">
          <button
            className="c_main_dashboard"
            onClick={() => {
              navigate("/");
            }}
          >
            통합 대시보드
          </button>
          <button
            className="c_ai_data_recommend"
            onClick={() => {
              navigate("/ai_data_recommend", { replace: true });
            }}
          >
            AI 데이터 추천
          </button>
        </div>

        <div className="c_main_rate">
          <div className="c_main_rate_tab_container">
            <div className="c_main_rate_state">
              <p className="c_main_title">공정현황</p>
              <div className="bubble_container">
                <div className="bubble_left">
                  <h3>타 현장 빅데이터</h3>
                  <span>
                    {tagId === 1
                      ? "공통가설"
                      : tagId === 2
                      ? "토목공사"
                      : tagId === 3
                      ? "건축공사"
                      : tagId === 4
                      ? "기계/설비공사"
                      : tagId === 5
                      ? "전기/소방공사"
                      : tagId === 6
                      ? "조경공사"
                      : null}
                    &nbsp;추천 자재
                  </span>
                </div>
              </div>
            </div>
            <div className="c_main_rate_tabs">
              {/* <span>공통가설</span>
                    <span>토목</span>
                    <span>건축</span>
                    <span>기계/설비</span>
                    <span>전기/소방</span>
                    <span>조경</span> */}
              {categoryList.map((a, i) => {
                return (
                  <span
                    onClick={() => {
                      setTagId(a.id);
                    }}
                    className={a.id === tagId ? "active_btn" : null}
                  >
                    {a.name}
                  </span>
                );
              })}
            </div>
          </div>
          <div className="c_main_rate_container">
            <Bar options={options} data={dataHorBar} width={100} height={13} />
          </div>
          <div className="c_main_rate_image_container">
            {productList.map((a, i) => {
              return (
                <div
                  onClick={() => {
                    //
                    //keyword갖고 이동
                    navigate(
                      "/management/bid/request_excel/detail/" + a.productNo,
                      {
                        state: { getListPop: true, elemNo: a.productNo },
                      }
                    );
                  }}
                >
                  <img src={a.fileRoot} title={a.productName} />
                  <span className="">{a.productName}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="c_main_detail">
          <div className="c_main_left">
            <div className="c_main_rate_state">
              <span
                className="c_main_title"
                onClick={() => {
                  navigate("all_history");
                }}
              >
                이번주 날씨
              </span>
              <div className="bubble_container2">
                <div className="bubble_left2">
                  <h3>계절별 필요 자재 준비 하셨나요?</h3>
                </div>
              </div>
            </div>
            <div className="c_main_weather_container">
              {weatherData.map((all, idx) => {
                return (
                  <div className="c_main_weather_box">
                    <div className="c_main_weather_day">
                      <dd>{moment(all.date).format("MM월 DD일")}</dd>
                      <div className="weather_icon_box">
                        <img
                          src={
                            all.mainWeather === "흐림"
                              ? i_cloud
                              : all.mainWeather === "비/눈"
                              ? i_mix
                              : all.mainWeather === "맑음"
                              ? i_sunny
                              : all.mainWeather === "비"
                              ? i_rainy
                              : all.mainWeather === "눈"
                              ? i_snow
                              : all.mainWeather === "소나기"
                              ? i_shower
                              : all.mainWeather === "구름많음"
                              ? i_muddy
                              : null
                          }
                          className="weather_thumbnail"
                          alt=""
                        />
                      </div>
                      <div className="weather_min_max_box">
                        <span className="weather_min">{all.tmn}°</span>/
                        <span className="weather_max">{all.tmx}°</span>
                      </div>
                      <p className="weather_rainy_box">
                        <img
                          src={i_small_rain}
                          className="weather_rainy"
                          alt=""
                        />
                        <div className="weather_rainy_inner">
                          <span>강수확률 : {all.pop}%</span>
                          {/* <span>{all.pcp} | {all.sno}</span> */}
                        </div>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="c_main_weather_img_container">
              {weatherProductData?.map((a, i) => {
                return (
                  <div
                    className="c_main_weather_img_box"
                    onClick={() => {
                      navigate(
                        "/management/bid/request_excel/detail/" + a.productId,
                        {
                          state: { getListPop: true, elemNo: a.productId },
                        }
                      );
                    }}
                  >
                    <img src={a.fileRoot} title={a.productName} alt="" />
                    <span className="c_main_weather_img_span">
                      {a.productName}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="c_main_right">
            <div className="c_main_rate_state">
              <span className="c_main_title">자재 공동 구매</span>
              <div className="bubble_container2">
                <div className="bubble_left2">
                  <h3>2024년 필요한 자재를 미리 신청하세요!</h3>
                </div>
              </div>
            </div>

            <div className="c_main_group_purchase">
              {groupBuyingList?.map((groupBuyingItem) => (
                <div className="purchase_list">
                  <div className="purchase_bedge">
                    {groupBuyingItem.state === "READY" && "준비중"}
                  </div>
                  <div className="purchase_info">
                    <div className="purchase_title">
                      {groupBuyingItem.title}
                    </div>
                    <div className="purchase_details">
                      <div className="purchase_people">
                        {groupBuyingItem.textBody}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="c_main_news">
          <div className="slider-area">
            <div className="slider">
              <button
                onClick={() => handleSwipe(-1)}
                className={`btn-slide-control btn-prev`}
              >
                <ArrowIcon width="16" height="16" fill="#333" />
              </button>
              <button
                onClick={() => handleSwipe(1)}
                className={`btn-slide-control btn-next`}
              >
                <ArrowIcon width="16" height="16" fill="#333" />
              </button>

              <div
                className="slider-list"
                // style={{ padding: sliderPaddingStyle }}
              >
                <div
                  className="slider-track"
                  onMouseOver={() => setIsSwiping(true)}
                  onMouseOut={() => setIsSwiping(false)}
                  style={{
                    // transform: `translateY(calc(${(-100 / slides.length) * (0.5 + currentIndex)}% + ${slideY || 0}px))`,
                    transform: `translateY(calc(${
                      (-100 / slides.length) * (2 + currentIndex)
                    }% + ${slideY || 0}px))`,
                    transition: slideTransition,
                  }}
                >
                  {slides.map((slide, slideIndex) => {
                    const itemIndex = getItemIndex(slideIndex);
                    return (
                      <div
                        key={slideIndex}
                        className={`slider-item ${
                          currentIndex === slideIndex ? "current-slide" : ""
                        }`}
                        style={{ width: newItemWidth || "auto" }}
                        onMouseDown={handleTouchStart}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onMouseMove={handleTouchMove}
                        onMouseUp={handleMouseSwipe}
                        onTouchEnd={handleMouseSwipe}
                        onMouseLeave={handleMouseSwipe}
                      >
                        <a>
                          {/* <img src={items[itemIndex]} alt={`banner${itemIndex}`} /> */}
                          <span>
                            <div className="slide_contents_box">
                              <img src={i_loud} className="icon_loud" />
                              <p className="slide_title">
                                {items[itemIndex].title} ▶️
                              </p>
                              <p>{items[itemIndex].content}</p>
                            </div>
                            <p
                              onClick={() => {
                                window.open(items[itemIndex].href);
                              }}
                              className="slide_go_link"
                            >
                              바로가기
                            </p>
                          </span>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
