import React, { useState, useEffect } from "react";
//library
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import moment from "moment";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/orderIssuance.css";
//etc
import i_cancel from "../../asset/images/w-cancle-bt.png";
import { contractOrderListApi, orderUserInfoApi } from "../../api/auth";

import { numberWithCommas } from "../../util/Util";
import Loading from "../../component/etc/Loading";

import { useDispatch, useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";
import DaumPostcodeEmbed from "react-daum-postcode";
import OrderBreadCrumb from "../../component/common/OrderBreadCrumb";
import { actions } from "../../redux/action";
import Button from "../../component/ui/button/Button";
import Typography from "../../component/ui/typography/Typography";
import useScrollFixed from "../../hooks/useScrollFixed.js";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

registerLocale("ko", ko);

export default function OrderIssuance() {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [detailInfo, setDetailInfo] = useState(location.state?.detailInfo);
  console.log(detailInfo);
  const now = new Date();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });

  const { isFixed } = useScrollFixed(isDesktop ? 200 : 300);

  //checkbox
  const [allCheck, setAllCheck] = useState(true);
  const [blink, setBlink] = useState(false);

  const [pList, setpList] = useState([]);
  const [addList, setAddList] = useState([]);
  const [deliveryTime, setDeliveryTime] = useState();
  const [alertMsg, setAlertMsg] = useState(false);

  const [ascendData, setAscendDate] = useState({
    contractNo: detailInfo.contract.no, //오더넘버
    code: detailInfo.contract.code, // 계약서코드
    sdate: detailInfo.contract.sdate, // 계약시작일
    edate: detailInfo.contract.edate, // 계약완료일
    deliveryDate: "", //납품기한
    uniqueness: "", //특이사항
    address: detailInfo.site.addr1, //주소
    detail: detailInfo.site.addr2, //상세주소
    recipient: userData.u_name,
    phone: userData.u_phone,

    // or_addr: detailInfo.bid_addr1, //현장주소
    // s_no: userData.site_num,
  });

  const [basicInfo, setBasicInfo] = useState({});
  //loading ui
  const [generating, setGenerating] = useState(false);

  // 날짜 - 시작
  const [startDate, setStartDate] = useState(false);
  //선택된 합계
  const [chooseSum, setChooseSum] = useState(0);

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  //해당 계약의 품목 불러오기
  const orderInfoFnc = async () => {
    const response = await contractOrderListApi(detailInfo.contract.no);
    //

    if (response.status === "success") {
      setBasicInfo(response.data.basic);
      if (response.data.basic.bid_type === "S") {
        const delDate = new Date(response.data.basic.bid_deldate);

        setStartDate(delDate);
        setDeliveryTime(delDate.getHours() + ":" + delDate.getMinutes());
        setAscendDate({
          ...ascendData,
          deliveryDate:
            formatDate(delDate) +
            " " +
            // response.data.basic.bid_deldate.time.hour +
            // ':' +
            // response.data.basic.bid_deldate.time.minute,
            delDate.getHours() +
            ":" +
            delDate.getMinutes(),
        });
      }

      let tempPList = response.data.pList.filter(
        (item) => item.bp_state === "ALIVE" || item.bp_state === "ADD"
      );

      tempPList.map((item) => {
        item.selected = true;
      });

      setpList(tempPList);
    } else {
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    orderInfoFnc();
    setAscendDate({ ...ascendData, or_val_no: detailInfo.contract.no });
  }, []);

  const selectedListFnc = async (selectedList) => {
    let resultList = selectedList?.reduce(
      (total, val) => Number(total) + Number(val.od_price * val.od_count),
      0
    );
    setChooseSum(resultList);
  };

  const getDiffDate = (selectDate) => {
    const date1 = moment(selectDate);
    const date2 = moment();
    let diff = moment(selectDate).fromNow();

    const filterDate2 = date2.add(6, "hours");

    if (date1.isBefore(filterDate2)) {
      return (
        <span className="not_allow">
          최소 working hour 6시간 이후부터 선택 가능합니다.
        </span>
      );
    } else {
      return <span className="allow">약 {diff}</span>;
    }
  };

  const termBidCompleteFnc = async () => {
    setGenerating(true);
    let completeList = pList.filter((item) => item.selected === true);
    completeList = completeList.map((item) => ({
      bidProduct: {
        originBidProductNo: item.bp_no,
        productNo: item.product_no,
        name: item.bp_name,
        standard: item.bp_standard,
        brand: item.bp_brand,
        unit: item.bp_unit,
        count: item.od_count,
        etc: item.bp_etc,
      },
      price: item.od_price,
      unitPrice: item.unitPrice,
    }));

    if (completeList.length < 1) {
      swal("작성오류", "주문할 품목을 선택해주세요.", "info");
    } else if (pList.reduce((total, val) => total * val.od_count, 1) <= 0) {
      swal(
        "작성오류",
        "수량이 잘못 입력된 품목이 있습니다. \n주문한 품목을 살펴보시고, 주문을 원치 않는 품목은 선택해제해주시기 바랍니다.",
        "",
        "warning"
      );
    } else if (ascendData.detail === "") {
      swal("작성오류", "납품 주소를 입력해주세요", "warning");
    } else if (ascendData.recipient === "") {
      swal("작성오류", "수령인을 입력해주세요", "warning");
    } else if (ascendData.phone === "") {
      swal("작성오류", "수령인 연락처를 입력해주세요", "warning");
    } else if (ascendData.deliveryDate === "") {
      swal("작성오류", "납품기한을 입력해주세요", "warning");
    } else if (moment(ascendData.deliveryDate).diff(moment(), "hours") < 6) {
      swal(
        "납품기한을 확인해주세요.",
        "납품기한은 최소 working hour 6시간 이후부터 선택 가능합니다.",
        "warning"
      );
    } else if (
      new Date(ascendData.deliveryDate).getHours() < 7 ||
      new Date(ascendData.deliveryDate).getHours() > 18
    ) {
      swal(
        "납품기한을 확인해주세요.",
        "납품기한은 working hour(07:00 ~ 18:00) 선택 가능합니다.",
        "warning"
      );
    } else if (
      moment(ascendData.deliveryDate).fromNow().includes("전") ||
      ascendData.deliveryDate === "" ||
      ascendData.deliveryDate === "Invalid date"
    ) {
      swal("작성오류", "납품 기한을 확인해주세요.", "info");
    } else {
      const resultData = { ...ascendData, productInfoList: completeList };

      navigate("/management/bid/request_reorder2/request_confirm", {
        state: {
          data: resultData,
          type: "단가계약기반주문",
          selectCompany: detailInfo.company.name,
        },
      });
    }
    setGenerating(false);
  };

  const [postOpen, setPostOpen] = useState(false);

  //주소 확인
  const onCompletePost = (data) => {
    setPostOpen(false);

    let copy = detailInfo;
    copy.addr1 = data.address;
    // setEstimateInfo(copy);
    // setEstimateInfo({ ...estimateInfo, addr1: data.address, addr2: "" });
  };

  const [phone, setPhone] = useState("");

  const handlePhoneNumberChange = (value) => {
    // 기본값으로 빈 문자열을 사용하여 undefined 방지
    let input = value ? value.replace(/\D/g, "") : "";

    // Add hyphens based on the length of the input
    if (input.length > 3 && input.length <= 6) {
      input = `${input.slice(0, 3)}-${input.slice(3)}`;
    } else if (input.length > 6 && input.length <= 10) {
      input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6)}`;
    } else if (input.length > 10) {
      input = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7)}`;
    }

    setAscendDate({
      ...ascendData,
      phone: value || "", // undefined일 경우 빈 문자열로 설정
    });

    setPhone(input);
  };

  // api를 통해 유저휴대폰 번호 받아옴.
  const getUserData = async () => {
    const response = await orderUserInfoApi(userData.site_num);
    if (response.status === "success") {
      // Create a new user data object, preserving existing values and updating u_name and u_phone
      let newUserData = {
        ...userData,
        u_name: response.data.name,
        u_phone: response.data.phone,
      }; // Dispatch the action to update the user data
      dispatch(actions.setUser(newUserData));
      handlePhoneNumberChange(response.data.phone);
    }
  };

  useEffect(() => {
    if (userData.u_phone) {
      handlePhoneNumberChange(userData.u_phone);
    } else {
      getUserData();
    }
  }, []);

  const contractDueFnc = (startDate, endDate) => {
    // 포맷된 날짜
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    // 두 날짜 사이의 개월 수 계산
    const due = moment(endDate).diff(moment(startDate), "months");

    // 결과 문자열 반환
    return `${start} - ${end} (${due}개월)`;
  };

  const prevStepFnc = () => {
    navigate(-1);
  };
  const nextStepFnc = () => {
    userData.constructionType !== "SUBCONTRACTOR"
      ? termBidCompleteFnc()
      : swal(
          "건설사 전용 기능입니다.",
          "이 기능을 사용하기 위해서는 건설사 회원으로 전환이 필요합니다.",
          "warning"
        );
  };

  // const location = useLocation();
  const currentPath = location.pathname; // 현재 경로
  const keyword = "request_confirm"; // 확인할 단어

  // URL에 특정 단어가 포함되어 있는지 확인
  const isConfirmPage = currentPath.includes(keyword);

  return (
    <>
      {!isConfirmPage ? (
        <div className="order_issuance_wrap">
          <Mheader title="상세정보 입력" />
          <div className="request_input_container">
            <div className="top_box">
              <div className="title">상세정보 입력</div>

              <OrderBreadCrumb
                step1="계약서 선택"
                step2="상세정보 입력"
                step3="상세정보 확인"
                step4="주문완료"
                isActive={2}
              />
            </div>
            <div className="top_request_sub_header_wrap">
              <p>
                <span>
                  주문하고자 하는 품목을 선택하고 수량을 입력하여 주문을 진행할
                  수 있습니다.
                </span>
              </p>
            </div>
            <div className="order_issuance_contents">
              <div className="input_info_container">
                <span className="input_info_title">주문/입찰 방식</span>

                <div className="input_info_contents input_info_desc">
                  단가 계약기반 주문
                </div>
              </div>

              <div style={{ marginTop: "40px" }}>
                <span>단가 계약 내역</span>
                <div>
                  <div className="contract_content contract_order_card">
                    <div className="content_top">
                      <div className="content_inner">
                        <div>
                          <span>계약번호 :&nbsp;</span>
                          <span className="public_code">
                            {detailInfo.contract.code}
                          </span>
                        </div>
                        <div>
                          계약기간 :&nbsp;
                          {contractDueFnc(
                            detailInfo.contract.sdate,
                            detailInfo.contract.edate
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="content_box">
                      <div className="content_order_btm">
                        <div className="content_box">
                          <div className="contract_order_info">
                            <div className="icon_box">
                              <div className="contract_icon">단가계약</div>
                            </div>
                            <p className="contractor">
                              <span>
                                [{userData.rep_con}] {userData.rep_site} /{" "}
                                {detailInfo.company.name} |{" "}
                                {detailInfo.productName} 포함{" "}
                                {detailInfo.productCount}건
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="input_info_container">
                <span className="input_info_title">수령인</span>
                <div className="input_info_contents">
                  <input
                    defaultValue={userData.u_name}
                    onChange={(e) =>
                      setAscendDate({
                        ...ascendData,
                        recipient: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">수령인 연락처</span>
                <div className="input_info_contents">
                  <input
                    value={phone}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^\d]/g, "");
                      if (!/^\d{0,11}$/.test(e.target.value)) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }}
                    onChange={(e) => handlePhoneNumberChange(e.target.value)}
                  />
                </div>
              </div>

              {/* 납품기한쓰 */}
              <div className="input_info_container">
                <span className="input_info_title">납품 기한</span>
                <div className="input_info_contents">
                  <DatePicker
                    locale="ko"
                    selected={startDate ? startDate : null}
                    minDate={
                      // 오전 12시(정오) 이후면 다음 날 부터 선택 가능
                      new Date().getHours() >= 12
                        ? new Date(now.getTime() + oneDayInMilliseconds)
                        : // 오늘 날짜라면 오늘이 최소 선택일자
                          new Date()
                    }
                    minTime={
                      formatDate(startDate) === formatDate(new Date())
                        ? new Date().setMinutes(new Date().getMinutes() + 360)
                        : new Date().getHours() >= 12 &&
                          moment(startDate).diff(moment(), "days") < 1
                        ? new Date().setHours(new Date().getHours() - 3, 0, 0)
                        : now.setHours(7, 0, 0)
                    }
                    maxTime={now.setHours(18, 0, 0)}
                    showTimeSelect // 시간 나오게 하기
                    timeFormat="HH:mm" //시간 포맷
                    timeIntervals={30} // 30분 단위로 선택 가능한 box가 나옴
                    timeCaption="time"
                    dateFormat="yyyy/MM/dd HH:mm 까지"
                    isClearable={true}
                    peekNextMonth
                    withPortal
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                      if (date && date?.getHours() === 0) {
                        const plus18hours = date.setHours(date.getHours() + 18);
                        setStartDate(plus18hours);
                      }
                      setStartDate(date);
                      setAscendDate({
                        ...ascendData,
                        deliveryDate: moment(date).format(
                          "yyyy-MM-DD HH:mm:ss"
                        ),
                      });
                      // minValueTime(formatDate(date), deliveryTime);
                    }}
                    // 날짜만 선택하고 닫으면
                    // 시간이 오전 12시로 pick되는 issue 발생
                    // 마지막에 validation으로 formatdate이용해서 체크하는거 추천
                  />
                </div>
              </div>
              {startDate ? (
                <span className="input_bid_time_info">
                  {getDiffDate(startDate)}
                </span>
              ) : (
                <div
                  className="input_budget_info"
                  style={{ marginTop: "5px", flexDirection: "column" }}
                >
                  <span className="request_short_info">
                    * 최소 working hour 6시간 이후부터 선택 가능합니다.
                  </span>
                </div>
              )}
              <div className="input_info_container">
                <span className="input_info_title">납품 주소</span>
                <div className="input_info_contents my_addr_container ">
                  <div className="input_info_btn_box">
                    <input
                      type="text"
                      disabled
                      value={detailInfo.site?.addr1}
                    />
                  </div>
                  {postOpen && (
                    <div className="pop_dim" onClick={() => setPostOpen(false)}>
                      <div className="address_popup_container">
                        <div>
                          <img
                            onClick={() => {
                              setPostOpen(false);
                            }}
                            src={i_cancel}
                            alt=""
                            style={{
                              width: "22px",
                              zIndex: "2",
                              position: "absolute",
                              top: "-18px",
                              left: "10px",
                              transform: "translate(-50%,-50%)",
                            }}
                          />
                        </div>
                        <DaumPostcodeEmbed
                          autoClose
                          onComplete={(e) =>
                            onCompletePost(e, "일회성입찰공고")
                          }
                        />
                      </div>
                    </div>
                  )}
                  <input
                    className="direct_input"
                    placeholder="상세주소를 입력해주세요."
                    onChange={(e) => {
                      setAscendDate({ ...ascendData, detail: e.target.value });
                    }}
                    defaultValue={detailInfo.site?.addr2}
                  />
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">배송비</span>
                <div className="input_info_contents input_info_desc">포함</div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">하차 역무</span>
                <div className="input_info_contents input_info_desc">
                  하차 역무 있음
                </div>
              </div>
              <div className="input_info_container">
                <span className="input_info_title">비용 정산</span>
                <div className="input_info_contents input_info_desc">
                  공새로 위임 정산
                </div>
              </div>
              <div className="issuance_subject">
                <span className="input_info_title">품목 작성</span>
                <div className="subject_box">
                  <div className="subject_btn"></div>
                </div>
                <div className="subject_table">
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <input
                            name="item"
                            id="all"
                            checked={allCheck}
                            type="checkbox"
                            onChange={() => {
                              if (!allCheck) {
                                let tempList = pList;
                                tempList.map((item) => {
                                  if (
                                    item.od_price !== "0" &&
                                    item.od_count !== "0"
                                  ) {
                                    item.selected = true;
                                  }
                                });
                                setpList(tempList);
                                setBlink(!blink);
                                setAllCheck(true);
                              } else {
                                let tempList = pList;
                                tempList.map((item) => {
                                  if (
                                    item.od_price !== "0" &&
                                    item.od_count !== "0"
                                  ) {
                                    item.selected = false;
                                  }
                                });
                                setpList(tempList);
                                setBlink(!blink);
                                setAllCheck(false);
                                setChooseSum(0);
                              }
                            }}
                          />
                          <label htmlFor="all"></label>
                        </th>
                        <th>
                          <p className="standard">품목명</p>
                        </th>
                        <th>
                          <p>규격</p>
                        </th>
                        <th>
                          <p style={{ lineHeight: "1.3" }}>
                            제조국
                            <br />
                            (브랜드)
                          </p>
                        </th>
                        <th>
                          <p>단위</p>
                        </th>
                        <th>
                          <p>수량</p>
                        </th>
                        <th>
                          <p>계약 단가(원)</p>
                        </th>
                        <th>
                          <p>금액 (원)</p>
                        </th>
                        <th>
                          <p>비고</p>
                        </th>
                      </tr>
                      {pList?.map((data, i) => {
                        // if(data?.od_price !== '0' && data?.od_count !== '0')
                        return (
                          <tr
                            className={
                              data.bp_state === "ADD" ? "subject_t_add" : ""
                            }
                          >
                            <td>
                              <input
                                name="item"
                                id={`item_${i}`}
                                type="checkbox"
                                checked={(blink || !blink) && data.selected}
                                onChange={() => {
                                  let tempList = pList;
                                  tempList[i].selected = !tempList[i].selected;

                                  let isAllChecked = true;
                                  tempList.map((item) => {
                                    if (item.selected === false) {
                                      isAllChecked = false;
                                      return;
                                    }
                                  });
                                  if (isAllChecked === true) {
                                    setAllCheck(true);
                                  } else {
                                    setAllCheck(false);
                                  }

                                  // var select = async() =>{

                                  // .
                                  // reduce((total, val)=> Number(total)+Number(val.od_price * val.od_count), 0);
                                  // setChooseSum(selectedList);
                                  // }
                                  // select();

                                  //reduce total = 더 해줄 값
                                  //val은 배열을 돌면서 차례차례 요소를 뽑아줌
                                  //콤마 뒤의 숫자는 초기값

                                  // for( let i in selectedList){
                                  //   let a = 0;
                                  //   let b = a + (selectedList[i].od_price*selectedList[i].od_count);
                                  //
                                  //
                                  // }

                                  setpList(tempList);
                                  setBlink(!blink);

                                  let selectedList = pList.filter(
                                    (item) => item.selected === true
                                  );
                                  selectedListFnc(selectedList);
                                }}
                              />
                              <label htmlFor={`item_${i}`}></label>
                            </td>
                            <td>{data?.bp_name}</td>
                            <td>
                              <p className="p_list_standard">
                                {data.bp_standard.replace(/, /gi, "\n")}
                              </p>
                            </td>
                            <td>{data?.bp_brand}</td>
                            <td>{data?.bp_unit}</td>
                            <td>
                              <input
                                style={{ borderBottom: "1px solid #d2d2d2" }}
                                type="number"
                                min={0}
                                defaultValue={data?.od_count}
                                onChange={(e) => {
                                  let tempList = pList;
                                  tempList[i].od_count = e.target.value;
                                  setpList(tempList);
                                  setBlink(!blink);

                                  let selectedList = pList.filter(
                                    (item) => item.selected === true
                                  );
                                  selectedListFnc(selectedList);
                                }}
                              />
                            </td>
                            <td>
                              {(blink || !blink) &&
                                numberWithCommas(data?.od_price)}
                            </td>
                            <td>
                              {(blink || !blink) &&
                                numberWithCommas(
                                  data?.od_count * data?.od_price
                                )}
                            </td>
                            <td>
                              <input
                                type="text"
                                style={{ borderBottom: "1px solid #d2d2d2" }}
                                defaultValue={data?.bp_etc}
                                onChange={(e) => {
                                  let tempList = pList;
                                  tempList[i].bp_etc = e.target.value;
                                  setpList(tempList);
                                  setBlink(!blink);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                      {/* {addList.map((data, i) => {
                    return (
                      <tr className='subject_t_add'>
                        <td>
                          <img
                            src={i_cancel}
                            alt=''
                            className='t_cancel'
                            onClick={() => {
                              // addProductDel(data.bp_no);
                            }}
                          />
                        </td>
                        <td>{data.bp_name}</td>
                        <td>{data.bp_standard}</td>
                        <td>{data.bp_unit}</td>
                        <td>
                          <input
                            type='number'
                            min={1}
                            defaultValue={data.od_count}
                            onChange={(e) => {
                              const arr = addList.map((item) =>
                                item.bp_no === data.bp_no
                                  ? { ...item, od_count: e.target.value }
                                  : { ...item }
                              );
                              setAddList(arr);
                            }}
                          />
                        </td>
                        <td>{numberWithCommas(data?.od_price)}</td>
                        <td>
                          {numberWithCommas(data?.od_count * data?.od_price)}
                        </td>
                        <td>
                          <input
                            type='text'
                            onChange={(e) => {
                              let tempList = addList;
                              tempList[i].bp_etc = e.target.value;
                              setAddList(addList);
                              setBlink(!blink);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })} */}
                      <tr>
                        <td colSpan="7">합계</td>
                        <td>
                          <strong>
                            {numberWithCommas(
                              pList
                                .map((data) => data.od_count * data.od_price)
                                .reduce((sum, current) => sum + current, 0) +
                                addList
                                  .map((data) => data.od_count * data.od_price)
                                  .reduce((sum, current) => sum + current, 0)
                            )}
                          </strong>
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="7">선택된 합계</td>
                        <td>
                          {chooseSum !== 0 ? (
                            <strong>{numberWithCommas(chooseSum)}</strong>
                          ) : allCheck ? (
                            <strong>
                              {numberWithCommas(
                                pList
                                  .map((data) => data.od_count * data.od_price)
                                  .reduce((sum, current) => sum + current, 0) +
                                  addList
                                    .map(
                                      (data) => data.od_count * data.od_price
                                    )
                                    .reduce((sum, current) => sum + current, 0)
                              )}
                            </strong>
                          ) : (
                            0
                          )}
                          {/* 
                      {allCheck?
                      <strong>
                      {numberWithCommas(
                        pList
                          .map((data) => data.od_count * data.od_price)
                          .reduce((sum, current) => sum + current, 0) +
                          addList
                            .map((data) => data.od_count * data.od_price)
                            .reduce((sum, current) => sum + current, 0)
                      )}
                      </strong>
                      :
                      <strong>
                        {numberWithCommas(chooseSum)}
                      </strong>
                      } */}
                        </td>
                        <td>선택된 품목의 합계금액입니다.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <ul className="request_step4_list">
                  <li>
                    <div className="input_info_container2">
                      <p className="excel_elem_title">비고</p>
                    </div>
                    <div className="textarea_div" spellCheck="false">
                      <textarea
                        placeholder="주문 관련 참고 사항이 있으시면 작성해주시기 바랍니다."
                        onChange={(e) => {
                          setAscendDate({
                            ...ascendData,
                            uniqueness: e.target.value,
                          });
                        }}
                        value={ascendData.uniqueness}
                      ></textarea>
                      <div className="add_text_alert_container">
                        <span className="add_alert_desc">
                          * 300자 이내로 작성해 주세요.
                        </span>
                        <span className="add_alert_typing">
                          {ascendData.uniqueness.length} / 300
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <section
              className={classNames("bid_bottom_button_section", {
                desktop_fixed: isDesktop && isFixed,
                mobile_fixed: isMobile && isFixed,
              })}
            >
              <div className="space_between_btn_group">
                <Button size="medium" type="secondary" onClick={prevStepFnc}>
                  <Typography component="p" type="c1">
                    이전으로
                  </Typography>
                </Button>
                <Button size="medium" type="primary" onClick={nextStepFnc}>
                  <Typography component="p" type="c1" color="var(--white)">
                    입력 완료
                  </Typography>
                </Button>
              </div>
            </section>
          </div>
          {generating && <Loading msg={"로딩 중입니다."} />}
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
}
