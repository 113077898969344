import React, { useState } from "react";

//library
import { useNavigate } from "react-router-dom";

//css
import "../../../asset/css/pop.css";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import { estimateCancelApi } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function ContractCancelPop({ setCancelPop, estimateNum }) {
  const navigate = useNavigate();

  const [cancelData, setCancelData] = useState({
    bid_no: estimateNum,
    bid_cancel: "",
  });

  const estimateCancelFnc = async () => {
    if (cancelData.bid_no === 0) {
      swal("다시 시도해주세요.", "", "warning");
    } else if (cancelData.bid_cancel === "") {
      swal("취소사유를 선택해주세요.", "", "warning");
    } else {
      const response = await estimateCancelApi(
        cancelData.bid_no,
        cancelData.bid_cancel
      );
      if (response.status === "success") {
        swal("공고가 취소되었습니다.", "", "success");
        navigate(0);
        setCancelPop(false);
      } else if (response.status === "fail") {
        swal("다시 시도해주세요.", "", "warning");
      }
    }
  };

  return (
    <div className="pop">
      <div
        className="pop_dim"
        onClick={() => {
          setCancelPop(false);
        }}
      ></div>
      <div className="contract_pop">
        <div
          className="close"
          onClick={() => {
            setCancelPop(false);
          }}
        >
          <img src={i_close} alt="" />
        </div>
        <div className="contract_pop_tit">
          <h3>공고를 취소 하시겠습니까?</h3>
          <p>공고취소 사유를 선택해주세요</p>
        </div>
        <select
          onChange={(e) => {
            setCancelData({ ...cancelData, bid_cancel: e.target.value });
          }}
        >
          <option value="" hidden>
            취소사유 선택
          </option>
          <option value="회사와의 관계">회사와의 관계</option>
          <option value="파기 후 재계약">파기 후 재계약</option>
          <option value="변심">변심</option>
          <option value="기타">기타</option>
        </select>
        <div
          className="confirm_btn"
          onClick={() => {
            setCancelPop(false);
          }}
        >
          <p
            onClick={() => {
              estimateCancelFnc();
            }}
          >
            확인
          </p>
        </div>
      </div>
    </div>
  );
}
