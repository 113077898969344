import React, { useEffect, useRef, useState } from "react";
//library
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";

//component
import Mheader from "../../../component/common/Mheader";
import GenerateCheckPop from "../../../component/common/Pop/generateCheckPop";
import InvoicePreparationPop from "../../../component/common/Pop/InvoicePreparationPop.js";
import Loading from "../../../component/etc/Loading";

//css
import * as S from "../../../asset/css/order.styles.js";
import "../../../asset/css/order.css";
import {
  orderListApi,
  generateCheckApi,
  preInvoiceExcelDownApi,
} from "../../../api/auth";
import search_icon from "../../../asset/images/search-icon.png";
import reset_icon from "../../../asset/images/reset-icon.png";
import excel_icon from "../../../asset/images/excel.png";

//redux
import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";
import DashBoardTabs from "../../../component/etc/DashBoardTabs";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import Pagination from "../../../component/ui/pagination/Pagination.js";

registerLocale("ko", ko);

export default function Order() {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const { page } = useParams();
  const url = "/management/order/";
  const location = useLocation();
  const fromPage = location?.state?.fromPage;
  const locationKeyword = location?.state?.keyword;

  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [maxNum, setMaxNum] = useState(0);
  const [firstData, setFirstData] = useState({
    s_no: userData.site_num,
    nowPage: 1,
    searchData: fromPage || "ALL",
    startDate: "2021-01-01",
    endDate: moment().format("yyyy-MM-DD"),
  });

  const [orderData, setOrderData] = useState([]);
  const [orderStateData, setOrderStateData] = useState({});
  const [generating, setGenerating] = useState(false);
  const [orNo, setOrNo] = useState();
  const [filePath, setFilePath] = useState();
  const [generatePop, setGeneratePop] = useState(false);
  const [detailData, setDetailData] = useState();
  const [companyList, setCompanyList] = useState();
  const [companyAmount, setCompanyAmount] = useState();
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);

  //조회
  const orderDataFnc = async () => {
    const response = await orderListApi(
      firstData.s_no,
      currentPage,
      firstData.searchData,
      firstData.startDate,
      firstData.endDate,
      companyValue,
      keyword !== "" ? keyword : "ALL"
    );
    if (response.status === "success") {
      setMaxNum(response.data.maxNum);
      setOrderData(response.data.oList);
      setOrderStateData(response.data.state);
      setCompanyList(response.data.companyList);
      setCompanyAmount(response.data.companyAmount);
    } else {
      swal("오류", "", "error");
    }
  };

  const _move2Success = () => {
    orderDataFnc();
  };

  //order state
  const orderState = (data) => {
    if (data === "START") {
      return "발행완료";
    } else if (data === "CHECK") {
      return "주문확인";
    } else if (data === "DONE") {
      return "배송완료";
    } else if (data === "TESTED") {
      return "검수완료";
    } else if (data === "CANCEL") {
      return "취소";
    } else if (data === "BEFORE") {
      return "정산대기";
    } else if (data === "END") {
      return "정산완료";
    }
  };

  const generateCheckFnc = async (or_no) => {
    setGenerating(true); //loading ui on
    const response = await generateCheckApi(or_no);

    if (response.status === "success") {
      setOrNo(response.data.or_no);
      setFilePath(response.data.file_path);
      setGenerating(false); //case success : loading ui off
      setGeneratePop(true);
    }
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("기간 전체");
  const [companyValue, setCompanyValue] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [enteredKeyword, setEnteredKeyword] = useState("");

  useEffect(() => {
    // location을 통해 담아온 keyword가 있을 때
    if (locationKeyword) {
      setKeyword(locationKeyword);
      setEnteredKeyword(locationKeyword);
    }
  }, []);

  const onChangeCompanyValue = (e) => {
    setCompanyValue(e.currentTarget.value);
    setCurrentPage(1);
  };

  const onChangeSelectValue = (e) => {
    setSelectValue(e.currentTarget.value);
    setCurrentPage(1);
  };

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setFirstData({
      ...firstData,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    });
    navigate(url + "1");
  };

  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const onKeyPressSearchIcon = (e) => {
    if (e.key === "Enter") {
      setEnteredKeyword(keyword);
      orderDataFnc();
      setCurrentPage(1);
    }
  };

  const onClickReset = () => {
    setStartDate(new Date("2021-01-01"));
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setSelectValue("기간 전체");
    setCompanyValue(0);
    setKeyword("");
    setEnteredKeyword("");
    setFirstData({
      ...firstData,
      searchData: "ALL",
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectValue === "최근 1개월") setDate(1, "M");
    if (selectValue === "최근 3개월") setDate(3, "M");
    if (selectValue === "최근 6개월") setDate(6, "M");
    if (selectValue === "최근 1년") setDate(1, "y");
    if (selectValue === "기간 전체") {
      setStartDate(new Date("2021-01-01"));
      setEndDate(new Date(moment().format("yyyy-MM-DD")));
      setFirstData({
        ...firstData,
        startDate: "2021-01-01",
        endDate: moment().format("yyyy-MM-DD"),
      });
    }
  }, [selectValue, companyValue]);

  useEffect(() => {
    if (firstData.s_no !== 0) {
      orderDataFnc();
    }
  }, [firstData.searchData, currentPage, startDate, endDate]);

  // 예비 정산내역 엑셀 다운로드
  const downloadLinkRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onClickPreInvoiceDownload = async () => {
    setLoading(true);

    try {
      const response = await preInvoiceExcelDownApi(
        firstData.s_no,
        firstData.searchData,
        firstData.startDate,
        firstData.endDate,
        companyValue,
        keyword !== "" ? keyword : "ALL"
      );

      const url = URL.createObjectURL(response.data);
      let filename = "filename.xlsx";
      const contentDisposition = response.headers["content-disposition"];

      if (contentDisposition) {
        const match = contentDisposition.match(/fileName="([^"]+)"/);
        if (match && match[1]) {
          filename = decodeURIComponent(match[1]).replace(/\+/g, " ");
        }
      }

      downloadLinkRef.current.href = url;
      downloadLinkRef.current.download = filename;
      downloadLinkRef.current.click();
    } catch (error) {
      console.error(error);
      swal("다운로드 오류", "", "error");
    } finally {
      setLoading(false);
    }
  };

  // 정산 신청
  const [preparationPop, setPreparationPop] = useState(false);
  const openPreparationPop = () => {
    if (!orderStateData.tested) {
      swal(
        "검수완료 건이 존재하지 않습니다.",
        "검수완료 상태의 주문서 건에 한하여 정산요청이 가능합니다.",
        "error"
      );
      return;
    }
    setPreparationPop(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`${url}${page}`);
  };

  return (
    <S.Wrapper>
      <Mheader title="주문관리" />
      <S.DashboardContainer>
        {isDesktop && <DashBoardTabs />}
        <S.DashBoardContent>
          <S.ContentHeader>
            <S.TitleWrap>
              <S.Title>나의 주문관리 현황</S.Title>
              {isDesktop && (
                <>
                  <div>|</div>
                  <S.SelectedDate>
                    {firstData?.startDate} ~ {firstData?.endDate}
                  </S.SelectedDate>
                </>
              )}
            </S.TitleWrap>

            {isDesktop && (
              <S.AlignContainer>
                <S.AlignWrap>
                  <S.SiteSelect
                    onChange={onChangeCompanyValue}
                    value={companyValue}
                  >
                    {companyList?.map((company) => (
                      <option key={company.companyNo} value={company.companyNo}>
                        {company?.companyName}
                      </option>
                    ))}
                    <option value={0}>공급사 전체</option>
                  </S.SiteSelect>

                  <S.DateRangeSelect
                    onChange={onChangeSelectValue}
                    value={selectValue}
                  >
                    <option>최근 1개월</option>
                    <option>최근 3개월</option>
                    <option>최근 6개월</option>
                    <option>최근 1년</option>
                    <option>기간 전체</option>
                  </S.DateRangeSelect>

                  <S.DateWrap>
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setFirstData({
                          ...firstData,
                          startDate: moment(date).format("yyyy-MM-DD"),
                        });
                        navigate(url + "1");
                        setCurrentPage(1);
                      }}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="yyyy-MM-dd"
                      locale={ko}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </S.DateWrap>

                  <S.DateWrap>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        setFirstData({
                          ...firstData,
                          endDate: moment(date).format("yyyy-MM-DD"),
                        });
                        navigate(url + "1");
                        setCurrentPage(1);
                      }}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="yyyy-MM-dd"
                      locale={ko}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </S.DateWrap>
                </S.AlignWrap>

                <S.SearchWrap>
                  <S.SearchAlignWrap>
                    <S.SearchInput
                      placeholder="품목명, 주문번호, 공급사명을 입력해주세요"
                      onChange={onChangeKeyword}
                      onKeyPress={onKeyPressSearchIcon}
                      value={keyword}
                    />
                    <S.SearchIcon>
                      <img
                        src={search_icon}
                        onClick={() => {
                          setEnteredKeyword(keyword);
                          orderDataFnc();
                          setCurrentPage(1);
                        }}
                        alt="search_icon"
                      />
                    </S.SearchIcon>
                  </S.SearchAlignWrap>
                  <S.ResetIcon onClick={onClickReset}>
                    <img src={reset_icon} alt="reset_icon" />
                  </S.ResetIcon>
                </S.SearchWrap>
              </S.AlignContainer>
            )}
          </S.ContentHeader>

          <S.ContentBody>
            <S.OrderStateWrap>
              <S.OrderState>발행완료</S.OrderState>
              <S.OrderStartBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    searchData: "START",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {orderStateData.start}건
              </S.OrderStartBtn>
            </S.OrderStateWrap>

            <S.OrderStateWrap>
              <S.OrderState>주문확인</S.OrderState>
              <S.OrderCheckBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    searchData: "CHECK",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {orderStateData.check}건
              </S.OrderCheckBtn>
            </S.OrderStateWrap>

            <S.OrderStateWrap>
              <S.OrderState>배송완료</S.OrderState>
              <S.OrderDoneBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    searchData: "DONE",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {orderStateData.done}건
              </S.OrderDoneBtn>
            </S.OrderStateWrap>

            <S.OrderStateWrap style={{ marginTop: isMobile && "3rem" }}>
              <S.OrderState>검수완료</S.OrderState>
              <S.OrderTestedBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    searchData: "TESTED",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {orderStateData.tested}건
              </S.OrderTestedBtn>
              <S.OrderTestedBtn
                onClick={openPreparationPop}
                style={{ marginTop: "10px" }}
              >
                정산요청
              </S.OrderTestedBtn>
            </S.OrderStateWrap>

            <S.OrderStateWrap style={{ marginTop: isMobile && "3rem" }}>
              <S.OrderState>정산</S.OrderState>
              <S.OrderEndBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    searchData: "END",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {orderStateData.end}건
              </S.OrderEndBtn>
            </S.OrderStateWrap>

            {isDesktop && <S.DivideLine></S.DivideLine>}

            <S.OrderStateWrap style={{ marginTop: isMobile && "3rem" }}>
              <S.OrderState>전체보기</S.OrderState>
              <S.OrderAllBtn
                firstData={firstData}
                onClick={() => {
                  setFirstData({
                    ...firstData,
                    searchData: "ALL",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {Object.values(orderStateData)
                  .slice(1)
                  .reduce((acc, cur) => +acc + +cur, 0)}
                건
              </S.OrderAllBtn>
            </S.OrderStateWrap>
          </S.ContentBody>

          <S.ContentFooter>
            <S.CancelNumBtn
              firstData={firstData}
              onClick={() => {
                setFirstData({
                  ...firstData,
                  searchData: "CANCEL",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              취소 <span>{orderStateData.cancel}</span>건
            </S.CancelNumBtn>

            <S.RejectNumBtn
              firstData={firstData}
              onClick={() => {
                setFirstData({
                  ...firstData,
                  searchData: "REJECT",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              반려 <span>{orderStateData.reject}</span>건
            </S.RejectNumBtn>
          </S.ContentFooter>
        </S.DashBoardContent>

        {isDesktop && (
          <>
            <S.SearchResultWrap>
              <S.SearchResult>
                <span>{`"${
                  enteredKeyword !== "" ? enteredKeyword : "전체"
                }" `}</span>
                검색 결과 총 <span>{maxNum}</span>
                건이 검색되었습니다. (미정산 금액 :{" "}
                <span>{companyAmount?.toLocaleString("ko") || 0}</span>원)
              </S.SearchResult>
              <S.ExcelDownBtn
                onClick={onClickPreInvoiceDownload}
                disabled={!maxNum}
              >
                <S.ExcelIcon src={excel_icon} />
                <S.ExcelText>{`주문내역 엑셀 다운 (${maxNum}건)`}</S.ExcelText>
              </S.ExcelDownBtn>
              <a ref={downloadLinkRef} style={{ display: "none" }} />
            </S.SearchResultWrap>

            {orderData?.map((el, idx) => (
              <S.OrderListWrapper key={idx}>
                <S.ListHeader>
                  <S.OrderNo>주문번호 : {el.or_code}</S.OrderNo>
                  <S.SiteName>현장명 : {el.s_name}</S.SiteName>
                  <S.OrderStep el={el}>
                    <S.Step>
                      <p>발행완료</p>
                    </S.Step>
                    <S.Step>
                      <p>주문확인</p>
                    </S.Step>
                    <S.Step>
                      <p>배송완료</p>
                    </S.Step>
                    <S.Step>
                      <p>검수완료</p>
                    </S.Step>
                    <S.Step>
                      <p>정산</p>
                    </S.Step>
                  </S.OrderStep>
                </S.ListHeader>

                <S.ListBody>
                  <S.ListInfo>
                    <S.BadgeWrap>
                      <S.Badge el={el}>
                        {el.or_state === "START" && "발행완료"}
                        {el.or_state === "CHECK" && "주문확인"}
                        {el.or_state === "DONE" && "배송완료"}
                        {el.or_state === "TESTED" && "검수완료"}
                        {el.or_state === "CANCEL" && "주문취소"}
                        {el.or_state === "REJECT" && "반려"}
                        {el.or_state === "END" && "정산"}
                      </S.Badge>
                      {el.bid_type === "A" && (
                        <S.TypeBadge el={el}>주문전용 앱</S.TypeBadge>
                      )}
                      {el.bid_type === "S" && (
                        <S.TypeBadge el={el}>일회성입찰</S.TypeBadge>
                      )}
                      {el.bid_type === "T" && (
                        <S.TypeBadge el={el}>단가계약</S.TypeBadge>
                      )}
                      {el.bid_type === "P" && (
                        <S.TypeBadge el={el}>주문서역발행</S.TypeBadge>
                      )}
                      {el.bid_type === "Q" && (
                        <S.TypeBadge el={el}>업체지정</S.TypeBadge>
                      )}
                      {(el?.bid_type === "B" || el?.bid_type === "D") && (
                        <S.TypeBadge el={el}>PB상품</S.TypeBadge>
                      )}
                      {el.bid_type === "R" && (
                        <S.TypeBadge el={el}>반품</S.TypeBadge>
                      )}
                    </S.BadgeWrap>
                    <S.NameWrapper>
                      <S.CompanyName>{el.c_name}</S.CompanyName>
                      <div>|</div>
                      <S.ProductName>{el.product_desc}</S.ProductName>
                    </S.NameWrapper>
                    <S.DetailInfo>
                      <S.BidType>
                        발행시간 : {el.or_regdate.slice(0, 16)}
                      </S.BidType>
                      <S.TotalAmount>
                        주문금액 : {el.total_amount.toLocaleString("ko")}원
                      </S.TotalAmount>
                      <S.OrderRegdate>
                        배송완료시간 :{" "}
                        {el.or_edate ? el.or_edate.slice(0, 16) : "-"}
                      </S.OrderRegdate>
                    </S.DetailInfo>
                  </S.ListInfo>
                  <S.BtnWrapper>
                    {el.modified && (
                      <S.isModifiedText>*수정 내역 있음</S.isModifiedText>
                    )}
                    <S.OrderContentBtn
                      modified={el.modified}
                      onClick={() => {
                        navigate(`/management/order/document/${el.or_no}`);
                      }}
                    >
                      주문서 상세 내역
                    </S.OrderContentBtn>

                    {el.or_state === "TESTED" && (
                      <S.TestedSheetBtn
                        onClick={() => {
                          navigate(
                            `/management/quick_order/review/${el.or_no}`,
                            {
                              state: {
                                or_c_no: el.or_c_no,
                                or_no: el.or_no,
                                type: "normal",
                              },
                            }
                          );
                        }}
                      >
                        리뷰 작성하기
                      </S.TestedSheetBtn>
                    )}
                  </S.BtnWrapper>
                </S.ListBody>
              </S.OrderListWrapper>
            ))}
            <div className="order_pagination_container">
              <Pagination
                current={currentPage}
                total={maxNum}
                onChange={handlePageChange}
                pageSize={10}
              />
            </div>
          </>
        )}
      </S.DashboardContainer>
      {generatePop && (
        <GenerateCheckPop
          //여기에 담아서 팝업에 보내기
          setGeneratePop={setGeneratePop}
          filePath={filePath}
          orNo={orNo}
          move2Success={_move2Success}
          reload={orderDataFnc}
          detailData={detailData}
        />
      )}
      {preparationPop && (
        <InvoicePreparationPop setPreparationPop={setPreparationPop} />
      )}
      {generating && <Loading msg={"검수확인서 생성중입니다."} />}
      {loading && <Loading />}

      {isMobile && (
        <div className="order_wrap">
          <S.AlignWrap>
            <S.DateWrap>
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setFirstData({
                    ...firstData,
                    startDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>

            <S.DateWrap>
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setFirstData({
                    ...firstData,
                    endDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>
          </S.AlignWrap>

          <div className="order_container">
            <div className="contract_list">
              <select
                className="contract_list_company_select"
                onChange={onChangeCompanyValue}
                value={companyValue}
              >
                {companyList?.map((company) => (
                  <option value={company.companyNo}>
                    {company?.companyName}
                  </option>
                ))}
                <option value={0}>공급사 전체</option>
              </select>

              <div className="contract_list_align_wrap">
                <input
                  className="contract_list_search_input"
                  onChange={onChangeKeyword}
                  placeholder="품목명, 주문번호, 공급사명을 입력해주세요"
                  value={keyword}
                />
                <div className="reset_icon_wrap" onClick={onClickReset}>
                  <img src={reset_icon} alt="reset_icon" />
                </div>
              </div>
              <button
                className="contract_list_search_button"
                onClick={orderDataFnc}
              >
                검색
              </button>
              <button
                className="contract_list_excel_button"
                onClick={onClickPreInvoiceDownload}
              >
                {`주문내역 엑셀 다운 (${maxNum}건)`}
              </button>
              <a ref={downloadLinkRef} style={{ display: "none" }} />

              {firstData.searchData === "TESTED" && (
                <button
                  className="contract_list_excel_button"
                  onClick={openPreparationPop}
                >
                  정산요청
                </button>
              )}

              <div className="contract_list_top">
                <div className="contract_list_count_box">
                  <span>
                    검색결과 :&nbsp;
                    <dd className="contract_list_count">{maxNum}</dd>건
                  </span>
                  <span>
                    미정산 금액 :&nbsp;
                    <dd className="contract_list_count">
                      {companyAmount?.toLocaleString("ko") || 0}
                    </dd>
                    원
                  </span>
                </div>
              </div>
              <div className="contract_list_btm">
                {orderData.map((el, i) => {
                  return (
                    <div className="contract_content" key={el.or_no}>
                      <div
                        className={
                          el.or_state === "CANCEL"
                            ? "content_top"
                            : "content_top content_delivery"
                        }
                      >
                        <div>
                          <span>주문번호 :&nbsp;</span>
                          <span className="public_code">{el.or_code}</span>
                        </div>
                        <div></div>
                        {el.or_state === "CANCEL" ? null : (
                          <div className="progress_bar">
                            <div
                              className={
                                el.or_state === "START"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>발행완료</span>
                            </div>
                            <div
                              className={
                                el.or_state === "CHECK"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>주문확인</span>
                            </div>
                            <div
                              className={
                                el.or_state === "DONE"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>배송완료</span>
                            </div>
                            <div
                              className={
                                el.or_state === "TESTED"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>검수완료</span>
                            </div>
                            <div
                              className={
                                el.or_state === "END"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>정산</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="content_btm">
                        <div className="content_box">
                          <p className="company_img">
                            <img src={el.f_root} alt="" />
                          </p>
                          <div className="contract_info">
                            <S.BadgeWrap>
                              <S.Badge el={el}>
                                {el?.or_state === "START" && "발행완료"}
                                {el?.or_state === "CHECK" && "주문확인"}
                                {el?.or_state === "DONE" && "배송완료"}
                                {el?.or_state === "TESTED" && "검수완료"}
                                {el?.or_state === "CANCEL" && "주문취소"}
                                {el?.or_state === "REJECT" && "반려"}
                                {el?.or_state === "END" && "정산"}
                              </S.Badge>
                              {el?.bid_type === "A" && (
                                <S.TypeBadge el={el}>주문전용 앱</S.TypeBadge>
                              )}
                              {el?.bid_type === "S" && (
                                <S.TypeBadge el={el}>일회성입찰</S.TypeBadge>
                              )}
                              {el?.bid_type === "T" && (
                                <S.TypeBadge el={el}>단가계약</S.TypeBadge>
                              )}
                              {el?.bid_type === "P" && (
                                <S.TypeBadge el={el}>주문서역발행</S.TypeBadge>
                              )}
                              {el?.bid_type === "Q" && (
                                <S.TypeBadge el={el}>업체지정</S.TypeBadge>
                              )}
                              {el?.bid_type === "R" && (
                                <S.TypeBadge el={el}>반품</S.TypeBadge>
                              )}
                              {(el?.bid_type === "B" ||
                                el?.bid_type === "D") && (
                                <S.TypeBadge el={el}>PB상품</S.TypeBadge>
                              )}
                            </S.BadgeWrap>
                            <p className="contractor">
                              <span>{"[" + el.c_name + "]"}</span>
                              <span>{el.product_desc}</span>
                              <span>
                                {el.bid_type === "T"
                                  ? "단가계약 (" + el.bid_term + "개월)"
                                  : "일회성 입찰"}
                              </span>
                            </p>
                            <div className="contract_text">
                              <div>
                                {el.bid_type === "T" ? (
                                  <p>계약기간 : {el.bid_term}개월</p>
                                ) : null}
                                <p>발행일시 : {el.or_regdate}</p>
                              </div>
                              <div>
                                <p>납품기한 : {el.or_edate}</p>
                                <p>
                                  납품주소 :&nbsp;
                                  <span>
                                    {el.or_addr}, {el.or_detail}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="contract_more">
                          <p
                            onClick={() => {
                              navigate(
                                `/management/order/document/${el.or_no}`
                              );
                            }}
                            style={{
                              color: el.modified && "#f60",
                              borderColor: el.modified && "#f60",
                            }}
                          >
                            주문서 상세 내역 {el.modified && "(수정 내역 있음)"}
                          </p>

                          {el.or_state === "TESTED" ? ( //검수완료시에만 리뷰작성하기 보이게하기
                            <p
                              onClick={() => {
                                navigate(
                                  `/management/quick_order/review/${el.or_no}`,
                                  {
                                    state: {
                                      or_c_no: el.or_c_no,
                                      or_no: el.or_no,
                                      type: "normal",
                                    },
                                  }
                                );
                              }}
                            >
                              리뷰 작성하기
                            </p>
                          ) : null}
                          {orderState(el.or_state) === "배송완료" && (
                            <p
                              onClick={() => {
                                setDetailData(orderData[i]);
                                generateCheckFnc(el.or_no);
                              }}
                            >
                              검수 완료
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="order_pagination_container">
                <Pagination
                  current={currentPage}
                  total={maxNum}
                  onChange={handlePageChange}
                  pageSize={10}
                />
              </div>
            </div>
          </div>
          {generatePop && (
            <GenerateCheckPop
              //여기에 담아서 팝업에 보내기
              setGeneratePop={setGeneratePop}
              filePath={filePath}
              orNo={orNo}
              move2Success={_move2Success}
              reload={orderDataFnc}
              detailData={detailData}
            />
          )}
          {preparationPop && (
            <InvoicePreparationPop setPreparationPop={setPreparationPop} />
          )}
          {generating && <Loading msg={"검수확인서 생성중입니다."} />}
          {loading && <Loading />}
        </div>
      )}
    </S.Wrapper>
  );
}
