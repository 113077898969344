import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Input from "../../../../../../../../../component/ui/input/Input";
import Typography from "../../../../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../../../../constant/Palette";
import EyeOpenedSVG from "../../../../../../../../../asset/images/svg/EyeOpenedSVG";
import EyeClosedSVG from "../../../../../../../../../asset/images/svg/EyeClosedSVG";

const NewPwdCheckInput = ({ control, errors }) => {
  const [pwdInVisible, setPwdInVisible] = useState(true);

  const onClickSuffix = () => {
    setPwdInVisible((prev) => !prev);
  };

  return (
    <Controller
      name="new_password_check"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width="100%"
          id="new_password_check"
          type={pwdInVisible ? "password" : "text"}
          placeholder="신규 비밀번호를 다시 한번 입력해주세요."
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                신규 비밀번호 확인{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={
            errors?.new_password_check && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.new_password_check.message}
              </Typography>
            )
          }
          status={errors.new_password_check && "error"}
          suffix={pwdInVisible ? <EyeOpenedSVG /> : <EyeClosedSVG />}
          onClickSuffix={onClickSuffix}
        />
      )}
    />
  );
};

export default NewPwdCheckInput;
