import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constant/QueryKeys";
import { fetchOrgStateApi } from "../../api/auth";

const useFetchOrgState = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_ORG_STATE],
    queryFn: fetchOrgStateApi,
  });
};

export default useFetchOrgState;
