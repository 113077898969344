import Button from "../../../../../../component/ui/button/Button";

const RejectButton = ({ onClickRejectButton }) => {
  return (
    <Button type="danger" size="small" onClick={onClickRejectButton}>
      거절
    </Button>
  );
};

export default RejectButton;
