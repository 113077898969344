import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { invoiceCountApi } from "../../../api/auth";

export default function useInvoiceCount() {
  const userData = useSelector((state) => state.user);
  const [countByState, setCountByState] = useState();

  const fetchInvoiceCountByState = useCallback(async () => {
    try {
      const response = await invoiceCountApi(userData.site_num);
      setCountByState(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [userData.site_num]);

  useEffect(() => {
    if (userData.site_num) fetchInvoiceCountByState();
  }, [fetchInvoiceCountByState, userData.site_num]);

  return { countByState, fetchInvoiceCountByState };
}
