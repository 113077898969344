import styled from "@emotion/styled";

export const Badge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #254574;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  background: ${(props) =>
    (props?.el?.bid?.state === "ALIVE" && "#107C41") ||
    (props?.el?.bid?.state === "CHOOSE" && "#005BAA") ||
    (props?.el?.bid?.state === "END" && "#005BAA") ||
    (props?.el?.bid?.state === "CANCEL" && "#9C9EA1") ||
    (props?.el?.bid?.state === "FAIL" && "#9C9EA1")};
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
`;

export const ReBadge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #254574;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-left: 10px;
  background: #9c9ea1;
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-left: 1rem;
    margin-bottom: 1.5rem;
  }
`;
