import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../../../component/ui/input/Input";
import Typography from "../../../../../../component/ui/typography/Typography";
import { COLOR } from "../../../../../../constant/Palette";

const BizRegInput = ({ control, errors }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });

  return (
    <Controller
      name="biz_reg"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          size="medium"
          width={isDesktop ? 280 : "70rem"}
          id="biz_reg"
          label={
            <>
              <Typography component="span" type="c1" color={COLOR.GRAY.L07}>
                사업자등록증{" "}
              </Typography>
              <Typography component="span" type="c1" color={COLOR.SUB.RED.L10}>
                *
              </Typography>
            </>
          }
          helpText={
            errors.biz_reg && (
              <Typography component="p" type="c5" color={COLOR.SUB.RED.L10}>
                {errors.biz_reg.message}
              </Typography>
            )
          }
          status={errors.biz_reg && "error"}
          readOnly
        />
      )}
    />
  );
};

export default BizRegInput;
