import React, { useState, useEffect } from 'react';
//library
import { useNavigate, useLocation } from 'react-router-dom';
//component
import Mheader from '../../component/common/Mheader';
//css
import '../../asset/css/requestone.css';
//etc
import i_rq3 from '../../asset/images/w-upload-icon.png';
import i_rq4 from '../../asset/images/w-ch-icon.png';

export default function RequestShort() {
  const navigate = useNavigate();
  const location = useLocation();

  const [goal, setGoal] = useState({
    g1: true,
    g2: false,
  });

  const bidNo = location.state.bid_no;
  const bidEndate = location.state.bid_endate;
  

  const nextStepFnc = () => {
    if (goal.g1 && !goal.g2) {
      navigate('/management/estimate/request_short3',{
        state: {
          bid_no: bidNo,
          bid_endate: bidEndate
        }
      })
    } else if (goal.g2 && !goal.g1) {
      navigate('/management/estimate/request_one3',{
        state: {
          bid_no: bidNo,
          bid_endate: bidEndate
        }
      })
    }
  };

  useEffect(() => {}, []);

  return (
    <div className='request_one_wrap'>
      <Mheader title='견적 / 계약 / 주문' />
      <div className='request_one_container'>
        {/* <div className='top_pagenation'>
          <h2>견적 방식 선택</h2>
          <div className='top_pagenation_num'>
            <p>1</p>
            <p>2</p>
            <p className='active'>3</p>
            <p>4</p>
            <p>5</p>
          </div>
        </div> */}
        <ul className='request_list'>
          <li
            className={goal.g1 ? 'select' : ''}
            onClick={() => setGoal({ g1: true, g2: false })}
          >
            <div className='goal_wrap'>
              <div className='request_icon'>
                <img alt='' src={i_rq3} />
              </div>
              <p>견적서 간편 입력</p>
              <div className='checkbox_for_mo'>
                <input
                  type='radio'
                  id='g1'
                  name='지정기간단가계약'
                  checked={goal.g1}
                  onChange={(e) => {}}
                />
                <label
                  className={goal.g1 ? 'check_sub_on' : 'check_sub_off'}
                  htmlFor='g1'
                >
                  <span>견적서 간편 입력</span>
                </label>
              </div>
              <p>
                <span>지정된 엑셀 파일이나 양식을 활용하여</span>
                <span>견적받고자 하는 품목을 일괄로 입력하여</span>
                <span>간편하게 견적요청을 할 수 있습니다.</span>
              </p>
            </div>
          </li>
          <li
            className={goal.g2 ? 'select' : ''}
            onClick={() => setGoal({ g1: false, g2: true })}
          >
            <div className='goal_wrap'>
              <div className='request_icon'>
                <img alt='' src={i_rq4} />
              </div>
              <p>품목 직접 선택</p>
              <div className='checkbox_for_mo'>
                <input
                  type='radio'
                  id='g2'
                  name='지정기간단가계약'
                  checked={goal.g2}
                  onChange={(e) => {}}
                />
                <label
                  className={goal.g2 ? 'check_sub_on' : 'check_sub_off'}
                  htmlFor='g2'
                >
                  <span>품목 직접 선택</span>
                </label>
              </div>
              <p>
                <span>직접 상품을 선택하여 견적요청을 할 수 있습니다.</span>
              </p>
            </div>
          </li>
        </ul>
        <div className='blue_btn' onClick={nextStepFnc}>
          다음
        </div>
      </div>
    </div>
  );
}
