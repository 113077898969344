import { useSelector } from "react-redux";
import Button from "../../../../../../component/ui/button/Button";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import { aliveContractApi } from "../../../../../../api/auth";
import { useCallback, useEffect, useState } from "react";

const ReorderUnitPriceSuccessPop = ({
  navigate,
  contractNo,
  reorderUnitPriceSuccessPop,
  setReorderUnitPriceSuccessPop,
}) => {
  const user = useSelector((state) => state.user);

  const [contract, setContract] = useState("");

  const fetchContractList = useCallback(async () => {
    const response = await aliveContractApi(user.site_num, 1);

    if (response.status === "success") {
      const filteredContract = response.data.filter(
        (contractList) => contractList.contract.no === contractNo
      );

      setContract(...filteredContract);
    }
  }, [contractNo, user.site_num]);

  const onClickReorder = () => {
    navigate("/management/bid/request_reorder2", {
      state: { detailInfo: contract },
    });
  };

  const onClickCancel = () => {
    setReorderUnitPriceSuccessPop(false);
  };

  useEffect(() => {
    fetchContractList();
  }, [fetchContractList]);

  return (
    <Modal
      size="xsmall"
      type="info"
      open={reorderUnitPriceSuccessPop}
      title={
        <Typography component="p" type="h4" color="var(--sub-green-10)">
          단가 계약기반 재주문하기
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={[
        <Button type="danger" size="small" onClick={onClickCancel}>
          취소
        </Button>,
        <Button type="primary" size="small" onClick={onClickReorder}>
          재주문
        </Button>,
      ]}
    >
      <Typography component="p" type="c2">
        단가 계약의 재주문으로 “단가 계약기반 주문" 화면으로
      </Typography>
      <Typography component="p" type="c2">
        이동합니다.
      </Typography>
      <Typography component="p" type="c2">
        주문을 위해 상세 정보를 입력하세요.
      </Typography>
    </Modal>
  );
};

export default ReorderUnitPriceSuccessPop;
