import { useState, useEffect, useCallback } from "react";

const useScrollFixed = (offset) => {
  const [isFixed, setIsFixed] = useState(true);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const triggerPosition = document.documentElement.scrollHeight - offset;

    setIsFixed(scrollPosition < triggerPosition);
  }, [offset]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, offset]);

  return { isFixed };
};

export default useScrollFixed;
