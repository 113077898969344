import Chip from "../../../../../component/ui/chip/Chip";

const stateConfig = {
  START: { color: "green", label: "발행완료" },
  CHECK: { color: "yellow", label: "주문확인" },
  DONE: { color: "blue", label: "배송완료" },
  TESTED: { color: "blue", label: "검수완료" },
  END: { color: "blue", label: "정산" },
  CANCEL: { color: "red", label: "취소" },
  REJECT: { color: "secondary", label: "반려" },
};

export default function StateChip({ state }) {
  const chipConfig = stateConfig[state];

  return chipConfig ? (
    <Chip size="medium" type="default" color={chipConfig.color}>
      {chipConfig.label}
    </Chip>
  ) : null;
}
