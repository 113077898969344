import axios from "axios";
//redux
import { store } from "../redux/store/store";
import { userDefault } from "../redux/reducer/default";
import { actions } from "../redux/action";
import swal from "sweetalert";
import { version } from "../../package.json";

const API_URL = process.env.REACT_APP_API_URL;
export const SERVER = API_URL;

// API request 모듈
const request = async (url, method, headers = {}, jsonData) => {
  const { dispatch } = store;

  const reqData = {
    url: url,
    method: method,
    jsonData: jsonData,
  };

  const getUidFromCookie = () => {
    const cookie = {};
    document.cookie?.split(";")?.forEach((el) => {
      const [key, value] = el?.split("=")?.map((item) => item?.trim());
      cookie[key] = value;
    });
    return cookie["_ga"]?.substring(6);
  };

  const uid = getUidFromCookie();

  // vi여기서 바꾸면됨
  const Address = SERVER + "/" + url;
  try {
    if (method === "get") {
      const { data } = await axios({
        method: method,
        url: Address,
        headers: {
          ...headers,
          uid: uid,
          version: `c-${version}`,
          "content-type": "application/json",
        },
        params: jsonData,
        validateStatus: function (status) {
          //
          if (status === 200) {
            return true;
          } else {
            //200이 아닐 경우
            if (status === 403 || status === 401) {
              //error
              dispatch(actions.setUser(userDefault));
              dispatch(actions.setToken(false));
              swal("로그인 만료", "다시 로그인 후 이용해주세요.", "warning");
              // window.location.replace('/login');
              return false;
            } else if (status === 400) {
              return true;
            }
            if (status !== 200) {
              return false;
            }
          }
        },
      });
      return data;
    } else {
      const { data } = await axios({
        method: method,
        url: Address,
        headers: {
          ...headers,
          uid: uid,
          version: version,
          "content-type": "application/json",
        },
        data: jsonData,
        validateStatus: function (status) {
          //
          if (status === 200) {
            return true;
          } else {
            if (status === 403 || status === 401) {
              //error
              dispatch(actions.setUser(userDefault));
              dispatch(actions.setToken(false));
              swal(
                "로그인 만료",
                "로그인이 만료되었습니다. 다시 로그인 후 이용해주세요.",
                "warning"
              );
              return false;
            } else if (status === 400) {
              return true;
            } else if (status !== 200) {
              // alert("ERROR"+status);
              return false;
            }
          }
        },
      });
      return data;
    }
  } catch (error) {
    await Promise.reject(error);
  }
};
export default request;
