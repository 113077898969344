import React from 'react';
import i_close from '../../../asset/images/w-cancel-icon.png';
import '../../../asset/css/pop.css';
import { contractPauseCancelApi } from '../../../api/auth';

//sweet alert
import swal from 'sweetalert';

export default function ContractStopPop({
  setPauseCancelPop,
  contractNum,
  move2Success,
}) {
  //계약 중지요청 취소
  const contractPauseCancelFnc = async () => {
    const response = await contractPauseCancelApi(contractNum);
    if (response.status === 'success') {
      swal( "계약중지 요청이 취소되었습니다." ,  "" ,  "warning" )
      setPauseCancelPop(false);
      move2Success();
    } else if (response.status === 'fail') {
      swal( "다시 시도해주세요." ,  "" ,  "warning" )
    }
  };

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setPauseCancelPop(false)}></div>
      <div className='contract_pop'>
        <div className='close' onClick={() => setPauseCancelPop(false)}>
          <img src={i_close} alt='' />
        </div>
        <div className='contract_pop_tit'>
          <h3>계약 중지요청을 취소하시겠습니까?</h3>
        </div>
        <div className='confirm_btn' style={{ display: 'flex' }}>
          <p
            style={{ width: '50%', backgroundColor:'#ddd',color:'#000' }}
            onClick={() => {
              setPauseCancelPop(false);
            }}
          >
            취소
          </p>
          <p
            style={{ width: '50%' }}
            onClick={() => {
              contractPauseCancelFnc();
            }}
          >
            확인
          </p>
        </div>
      </div>
    </div>
  );
}
