import classNames from "classnames/bind";
import styles from "./spin.module.css";
import { Spin as AntdSpin } from "antd";

const cx = classNames.bind(styles);

const Spin = ({ fullscreen }) => {
  const className = cx("common");

  return <AntdSpin className={className} fullscreen={fullscreen} />;
};

export default Spin;
