import useWithdrawModalHandler from "../../../../../../hooks/handler/useWithdrawModalHandler";
import Modal from "../../../../../../component/ui/modal/Modal";
import Typography from "../../../../../../component/ui/typography/Typography";
import Button from "../../../../../../component/ui/button/Button";
import PwdInput from "./components/elements/input/PwdInput";
import WithdrawSuccessModal from "../withdraw-success/WithdrawSuccessModal";
import WithdrawFailModal from "../withdraw-fail/WithdrawFailModal";
import s from "./withdrawModal.module.css";
import { COLOR } from "../../../../../../constant/Palette";

const WithdrawModal = ({ withdrawModal, setWithdrawModal }) => {
  const {
    handleSubmit,
    control,
    errors,
    handleWithdraw,
    withdrawSuccessModal,
    withdrawFailModal,
    setWithdrawFailModal,
  } = useWithdrawModalHandler();
  const onClickCancel = () => {
    setWithdrawModal(false);
  };

  return (
    <Modal
      size="xsmall"
      open={withdrawModal}
      title={
        <Typography component="p" type="h4" color={COLOR.GRAY.L09}>
          회원 탈퇴
        </Typography>
      }
      closeIcon={false}
      onCancel={onClickCancel}
      footer={null}
    >
      <form
        className={s.body_container}
        onSubmit={handleSubmit(handleWithdraw)}
      >
        <div>
          <Typography component="p" type="c2" color={COLOR.GRAY.L07}>
            비밀번호 입력 후 확인을 누르면 탈퇴가 완료됩니다.
          </Typography>
          <Typography component="p" type="c2" color={COLOR.GRAY.L07}>
            개인정보는 개인정보안내방침에 따라 처리됩니다.
          </Typography>
        </div>
        <PwdInput control={control} errors={errors} />

        <div className={s.footer_container}>
          <Button type="danger" size="small" onClick={onClickCancel}>
            취소
          </Button>
          <Button type="primary" size="small" htmlType="submit">
            확인
          </Button>
        </div>
      </form>
      {withdrawFailModal && (
        <WithdrawFailModal
          withdrawFailModal={withdrawFailModal}
          setWithdrawFailModal={setWithdrawFailModal}
        />
      )}
      {withdrawSuccessModal && (
        <WithdrawSuccessModal withdrawSuccessModal={withdrawSuccessModal} />
      )}
    </Modal>
  );
};

export default WithdrawModal;
