import React, { useEffect, useState } from 'react';
//etc
import { companyInfoPopApi } from '../../../api/auth';
import i_close from '../../../asset/images/w-cancel-icon.png';
import i_star from '../../../asset/images/w-star-icon.png';
import i_star_empty from '../../../asset/images/m-big-star-icon-n.svg';

export default function CompanyInfoPop({ setIsOpen2, comNum }) {
  const [infoData, setInfoData] = useState([]);

  //업체정보 불러오기
  const companyInfoFnc = async () => {
    const response = await companyInfoPopApi(comNum);
    if (response.status === 'success') {
      setInfoData(response.data);
    }
  };
  let RatingArray = [0, 1, 2, 3, 4];

  useEffect(() => {
    companyInfoFnc();
  }, []);

  return (
    <div className='company_info_pop'>
      <div className='pop_dim' onClick={() => setIsOpen2(false)}></div>
      <div className='company_info_pop_box'>
        <div className='look_close' onClick={() => setIsOpen2(false)}>
          <img src={i_close} alt='' />
        </div>
        <h3>공급사정보</h3>
        <div className='look_company'>
          <div className='look_company_img'>
          <img
            src={infoData.companyLogo}
            alt={'공급사 로고 이미지'}
          ></img>

          </div>
          <div className='look_company_info'>
            <div className='look_company_name'>
              <p>{infoData.company?.name}</p>
              <span>총 평점 : {infoData.totalScore}</span>
            </div>
            <p>공급사분류 : {infoData.company?.type}</p>
            <p>법인사업자 | {infoData.company?.num}</p>
            <p>
              {infoData.company?.addr1}, {infoData.company?.addr2}
            </p>
          </div>
        </div>
        <ul className='look_company_rv'>
          <li>
            <p>납품 일정을 잘 지켰나요?</p>
            <div className='rv_star'>
              <span className='rv_star_img'>
                {RatingArray.map((index) => {
                  return (
                    <img src={index < infoData.deliveryScore ? i_star : i_star_empty} /> //계속 올라가는 index와 고정된 avg값을 비교하여 avg값에 도달할 때까지 꽉찬별을 띄워준다.
                  );
                })}
              </span>
            </div>
          </li>
          <li>
            <p>주문한 상품이 제대로 왔나요?</p>
            <div className='rv_star'>
              <span className='rv_star_img'>
                {RatingArray.map((index) => {
                  return (
                    <img src={index < infoData.productScore ? i_star : i_star_empty} /> //계속 올라가는 index와 고정된 avg값을 비교하여 avg값에 도달할 때까지 꽉찬별을 띄워준다.
                  );
                })}
              </span>
            </div>
          </li>
          <li>
            <p>질의 대응이 빨랐나요?</p>
            <div className='rv_star'>
              <span className='rv_star_img'>
                {RatingArray.map((index) => {
                  return (
                    <img src={index < infoData.qnaScore ? i_star : i_star_empty} /> //계속 올라가는 index와 고정된 avg값을 비교하여 avg값에 도달할 때까지 꽉찬별을 띄워준다.
                  );
                })}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
