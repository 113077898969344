import React, { useState, useEffect } from "react";
//library
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
//sweet alert
import swal from "sweetalert";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/requestManyInput.css";

import question_img from "../../asset/images/question-icon.png";

//etc
import {
  estimateShortApi,
  estimateSelectApi,
  estimateRequestApi,
  getProNameApi,
} from "../../api/auth";

//redux
import { useSelector } from "react-redux";
import { getYear, getMonth } from "date-fns";
import PickDate from "../../component/common/PickDate";
registerLocale("ko", ko);

const _ = require("lodash");

export default function RequestShort2() {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();
  const [selMenu, setSelMenu] = useState([]);
  const [isBlocking, setIsBlocking] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [nowPro, setNowPro] = useState();

  const [estimateInfo, setEstimateInfo] = useState({
    bid_pro_no: "", //진행 공정
    bid_budget: 0, //견적 예산
    bid_endate: "", //투찰 기한
    bid_fee: "Y", //배송비 여부
    bid_drop: "L", //하자 역무 여부
    bid_priority: "최저 단가", //우선 사항
    bid_refund: "공새로 위임 정산", //정산 방법, 임시 고정
    bid_term: "", //계약 기간
    bid_payday: "", //결제 주기
    bid_addr: "", //현장 기본 주소
    bid_addr_detail: "", //현장 기본 주소 ---추가
    bid_s_no: userData.site_num, // 대표현장 번호
    bid_past_no: "", //견적요청 다시하기 구분
  });

  const onPushEstimateInfo = async () => {
    const response = await estimateShortApi(
      estimateInfo.bid_pro_no,
      estimateInfo.bid_budget,
      estimateInfo.bid_endate,
      estimateInfo.bid_fee,
      estimateInfo.bid_drop,
      estimateInfo.bid_priority,
      estimateInfo.bid_refund,
      estimateInfo.bid_payday,
      estimateInfo.bid_term,
      estimateInfo.bid_addr,
      estimateInfo.bid_addr_detail,
      estimateInfo.bid_s_no,
      estimateInfo.bid_past_no
    );
    if (response.status === "success") {
      return response.data.bid_no;
    } else {
      return -1;
    }
  };

  const onNextValidation = async () => {
    if (estimateInfo.bid_budget === "") {
      swal("견적 예산을 입력해주세요.", "", "warning");
    } else if (estimateInfo.bid_endate === "") {
      swal("투찰기한을 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_endate === "not_available") {
      swal("투찰기한을 확인해주세요.", "", "warning");
    } else if (estimateInfo.bid_fee === "") {
      swal("배송비 포함 여부를 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_drop === "") {
      swal("하차역무 포함 여부를 선택해주세요", "", "warning");
    } else if (estimateInfo.bid_priority === "") {
      swal("우선사항을 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_refund === "") {
      swal("비용 정산 방법을 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_term === "" || estimateInfo.bid_term < 1) {
      swal("계약기간을 입력해주세요.", "", "warning");
    } else if (estimateInfo.bid_payday === "") {
      swal("결제주기을 선택해주세요.", "", "warning");
    } else if (estimateInfo.bid_addr_detail === "") {
      swal("상세주소를 입력해주세요.", "", "warning");
    } else {
      const returnedBidNo = await onPushEstimateInfo();

      navigate("/management/estimate/request_method", {
        state: {
          bid_no: returnedBidNo,
          bid_endate: estimateInfo.bid_endate,
        },
      });
    }
  };

  const siteNumSendFnc = async () => {
    const resp = await estimateRequestApi(userData.site_num);
    if (resp.status === "success") {
      setEstimateInfo({ ...estimateInfo, bid_addr: resp.data });
    }
  };

  const selectMenuFnc = async () => {
    const response = await estimateSelectApi();
    if (response.status === "success") {
      setSelMenu(response.data.pList);
    }
  };

  const getProNameFnc = async () => {
    //현재 진행공정 가져오기
    const res = await getProNameApi(userData.site_num);
    if (res.status === "success") {
      setNowPro(res.data);
      setEstimateInfo({
        ...estimateInfo,
        bid_pro_no: res.data.pro_no,
      });
      //
    }
  };

  // 날짜
  const [biddingDate, setbiddingDate] = useState();
  const [biddingTime, setbiddingTime] = useState("");

  const [refundInfoPopup, setRefundInfoPopup] = useState(false);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const minValueTime = (chooseDate, chooseTime) => {
    if (
      formatDate(chooseDate) === formatDate(new Date()) &&
      getTime(10) >= chooseTime
    ) {
      //현재날짜와 비딩날짜가 같고, 선택한 날짜가 현재시간보다 작을 때 --수정예정
      setEstimateInfo({ ...estimateInfo, bid_endate: "not_available" });
      setAlertMsg(true);
    } else setAlertMsg(false);
  };
  const getTime = (min) => {
    //몇시몇분인지 리턴하는 함수
    const offset = (new Date().getTimezoneOffset() - min) * 60000; //isoString을 사용하면 국제시간 기준(+9시간)으로 되기 때문에 미리 빼줌
    const today = new Date(Date.now() - offset);
    let defaultTime = today.toISOString().slice(11, 16); //현재시간의 시와 분 리턴

    return defaultTime;
  };

  useEffect(() => {
    selectMenuFnc();
    setEstimateInfo({ ...estimateInfo, bid_past_no: location.state?.againBid });
  }, []);

  useEffect(() => {
    getProNameFnc(); //현재진행공정 가져오기
  }, [estimateInfo.bid_pro_no]);
  useEffect(() => {
    siteNumSendFnc();
  }, [estimateInfo.bid_addr]);

  //뒤로가기 확인
  // useEffect(() => {
  //   const unblock = history.block((loc, action) => {
  //     if (action === 'POP' && isBlocking) {
  //       return window.confirm('뒤로 가시겠습니까? (데이터가 삭제됩니다.)');
  //     }
  //     return true;
  //   });

  //   return () => unblock();
  // }, [isBlocking]);

  // useEffect(() => {
  //   estimateInfo && setIsBlocking(true);
  // }, [estimateInfo]);

  //직접입력 선택
  const [isSelect, setIsSelect] = useState();
  //금액
  const numberWithCommasThousand = (x) => {
    if (x != "") {
      let tenThousand = x.toString() + "000";
      return tenThousand.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const numberWithCommas = (x) => {
    x = x.replace(/[^0-9]/g, ""); // 입력값이 숫자가 아니면 공백
    x = x.replace(/,/g, ""); // ,값 공백처리
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 정규식을 이용해서 3자리 마다 , 추가
  };
  const [num, setNum] = useState();
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="request_short_wrap">
      <Mheader title="견적 / 계약 / 주문" />
      <div className="request_short_container">
        <div className="top_pagenation">
          <h2>견적 기본 정보 입력</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p className="active">2</p>
            <p>3</p>
            <p>4</p>
            <p>5</p>
          </div>
        </div>

        <ul className="many_input_list">
          <div className="budget_info" style={{ marginTop: "10px" }}>
            <span className="request_short_info">
              * 공종단계는 마이페이지 - 현장정보 - 현장 정보수정에서 변경할 수
              있습니다.
            </span>
          </div>
          <li>
            <p>견적 예산</p>
            <div>
              <input
                type="number"
                placeholder="견적 예산을 입력해주세요."
                className="write_the_budget"
                value={num}
                min={1}
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_budget: e.target.value,
                  });
                }}
              ></input>
              <span className="ten_thousand">천원</span>
            </div>
          </li>
          <div className="budget_info" style={{ marginTop: "10px" }}>
            <span className="request_short_info">
              * 견적예산을 모를 경우 0이라고 입력해주세요.
            </span>
            <span>
              {estimateInfo.bid_budget != 0
                ? numberWithCommasThousand(estimateInfo.bid_budget) + "원"
                : ""}
            </span>
          </div>
          <li>
            <p>투찰 기한</p>
            <div className="bidding_date_box">
              <div className="date_picker_wrap">
                <PickDate
                  estimateInfo={estimateInfo}
                  setEstimateInfo={setEstimateInfo}
                  sort="enDate"
                  selectDate={biddingDate}
                  setSelectDate={setbiddingDate}
                  time={biddingTime}
                  alertMsg={alertMsg}
                  minValueTime={minValueTime}
                  formatDate={formatDate}
                />
                {/* <DatePicker
                  locale='ko'
                  selected={biddingDate}
                  onChange={(date) => {
                    setbiddingDate(date);
                    setEstimateInfo({
                      ...estimateInfo,
                      bid_endate: formatDate(date) + ' ' + biddingTime,
                    });
                    minValueTime(date, biddingTime);
                  }}
                  selectsStart
                  minDate={new Date()}
                  biddingDate={biddingDate}
                  dateFormat='yyyy-MM-dd'
                  className={alertMsg?"select_alert":null}
                /> */}
              </div>
              <input
                type="time"
                className={
                  alertMsg
                    ? "bidding_time_select select_alert"
                    : "bidding_time_select"
                }
                value={biddingTime}
                required
                onChange={(e) => {
                  setbiddingTime(e.target.value);
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_endate: formatDate(biddingDate) + " " + e.target.value,
                  });
                  minValueTime(biddingDate, e.target.value);
                }}
              />
            </div>
            <p
              className="btn_quick"
              onClick={() => {
                navigate("/management/quick_order/search");
              }}
            >
              퀵주문 바로가기
            </p>
          </li>
          {alertMsg ? (
            <span className="bid_time_info">
              투찰 기한은 현재시간에서 10분 이후부터 가능합니다.
            </span>
          ) : null}
          <li className="contract_period_box">
            <p>계약 기간</p>
            <div>
              <input
                type="number"
                min="1"
                max="100"
                placeholder="계약기간을 입력해주세요."
                className="write_the_budget"
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_term: e.target.value,
                  });
                }}
              />
              <span className="ten_thousand">개월</span>
            </div>
          </li>
          <li className="contract_period_box">
            <p>결제주기</p>
            <div>
              <select
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_payday: e.target.value,
                  });
                }}
              >
                <option value="" hidden></option>
                <option value="15">세금계산서(납품완료일)로 부터 15일</option>
                <option value="30">세금계산서(납품완료일)로 부터 30일</option>
                <option value="45">세금계산서(납품완료일)로 부터 45일</option>
                <option value="60">세금계산서(납품완료일)로 부터 60일</option>
              </select>
              {/* <span className="pay_period">세금계산서(납품완료일)로 부터</span> */}
            </div>
          </li>
          <li>
            <p>납품 주소</p>
            <div>
              <input
                type="text"
                value={estimateInfo?.bid_addr}
                readOnly="readonly"
                className="request_disabled"
              />
              <input
                className="direct_input"
                placeholder="자재를 납품받으실 주소를 입력해주세요. ex)1번게이트"
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_addr_detail: e.target.value,
                  });
                }}
              />
            </div>
          </li>
          <li className="sort_line"></li>
          <li>
            <p>배송비 포함 여부</p>
            <div>
              <select
                onChange={(e) => {
                  setEstimateInfo({ ...estimateInfo, bid_fee: e.target.value });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="" hidden></option>
                <option value="Y" selected>
                  포함
                </option>
                <option value="N">포함 안함</option>
              </select>
            </div>
          </li>
          <li>
            <p>하차 역무 포함 여부</p>
            <div>
              <select
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_drop: e.target.value,
                  });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="" hidden></option>
                <option value="U">하차역무 없음 ❌</option>
                <option value="L" selected>
                  하차역무 있음 ⭕️
                </option>
              </select>
            </div>
          </li>
          <li>
            <p>우선사항</p>
            <div>
              <select
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_priority: e.target.value,
                  });
                }}
                readOnly="readonly"
                className="request_disabled"
                disabled
              >
                <option value="" hidden></option>
                <option value="품질 우선">품질 우선</option>
                <option value="최저 단가" selected>
                  최저 단가
                </option>
                <option value="적시 배송">적시 배송</option>
                <option value="우선사항 없음">우선사항 없음</option>
              </select>
            </div>
          </li>
          <li>
            <p>
              비용 정산 방법
              <img
                className="question_img"
                src={question_img}
                onMouseOver={() => {
                  setRefundInfoPopup(!refundInfoPopup);
                }}
                onMouseLeave={() => {
                  setRefundInfoPopup(false);
                }}
              ></img>
              {refundInfoPopup ? (
                <p className="question_box_refund">
                  "공새로 위임정산건"입니다. <br></br>
                  아래 번호로 문의 바랍니다.<br></br>
                  고객센터 (T.032-205-0479)
                </p>
              ) : null}
            </p>
            <div>
              <select
                onChange={(e) => {
                  setEstimateInfo({
                    ...estimateInfo,
                    bid_refund: e.target.value,
                  });
                }}
                readOnly="readonly"
                className="request_delivery_address request_disabled"
                disabled
              >
                <option value="공새로 위임 정산" selected>
                  공새로 위임 정산
                </option>
                {/* <option value="공새로 위임 정산">공새로 위임 정산</option> */}
              </select>
            </div>
          </li>
          {/* <span className="request_short_info2">* 비용 정산 방법은 추가 예정입니다.😅</span> */}

          <li className="estimated_period">
            <p></p>
            <div>
              <p>{/* 예상계약기간 : <span>2021.08.01 ~ 2022.07.31</span> */}</p>
            </div>
          </li>
          <li>
            <p></p>
            <div>
              <div className="btn_box">
                <div
                  className="prev"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  이전
                </div>
                <div className="next" onClick={onNextValidation}>
                  다음
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
