import React, { useState, useEffect } from "react";
//library
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
//component
import SiteSelect from "../../component/etc/SiteSelect";
//css
import "../../asset/css/main.css";
import Site from "../site/Site";
import SiteRecommend from "../main/MainRecommend";
//etc
import { siteInfoApi, siteInfoApi2, mainBoardInfoApi } from "../../api/auth";

//redux
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default.js";

import { isSafari, isMobile } from "react-device-detect";

//sweet alert
import swal from "sweetalert";

export default function Main() {
  const dispatch = useDispatch();

  const signToken = useSelector((state) => state.token);
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const [selectSwitch, setSelectSwitch] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });
  // const [imgRoot, setImgRoot] = useState('');

  //현장 불러오기
  const getSiteInfo = async () => {
    //fired when userData is alive
    const response = await siteInfoApi2();

    if (response.status === "success") {
      setSiteList(response.data);
      // setImgRoot(response.data.f_root);
      if (response.data.length >= 2 && userData.rep_site === false) {
        setSelectSwitch(true);
      } else if (response.data.length === 1) {
        let newUserData = {
          u_no: userData.u_no,
          u_name: userData.u_name,
          constructionType: response.data[0].constructionType,

          site_num: response.data[0].site.no,
          rep_site: response.data[0].site.name,
          rep_con: response.data[0].constructionName,
        };

        dispatch(actions.setUser(newUserData));
      }
    } else {
    }
  };

  useEffect(() => {
    if (userData.site_num === false && signToken !== false) {
      getSiteInfo();
    }
  }, [userData, signToken]);

  return signToken !== false ? (
    selectSwitch ? (
      <>
        <div className="main_wrap">
          <div className="main_container">
            <p>
              공새로에 오신 것을 <br /> 환영합니다.
            </p>
            <p>지금 바로 공새로의 효율적인 발주 업무 경험을 누려보세요.</p>
            <div className="main_link">
              {isResponsive ? (
                <>
                  <Link to="/login">로그인</Link>
                  <p
                    className="join-button"
                    onClick={() => {
                      navigate(`/join_type`, {
                        state: {
                          socialType: "",
                          socialValue: "",
                        },
                      });
                    }}
                  >
                    회원가입
                  </p>
                </>
              ) : (
                <>
                  <Link to="/login">로그인</Link>
                  <p
                    className="join-button"
                    onClick={() => {
                      // if(isSafari && isMobile){
                      //   swal("아이폰 사파리 유저의 경우에는 \n설정 > 사파리 > 팝업차단에서 팝업차단을 해제해주셔야 정상적으로 회원가입을 진행할 수 있습니다.");
                      // }
                      navigate(`/join_type`, {
                        state: {
                          socialType: "",
                          socialValue: "",
                        },
                      });
                    }}
                  >
                    회원가입
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <SiteSelect
          selectSwitch={selectSwitch}
          setSelectSwitch={setSelectSwitch}
          siteList={siteList}
          setSiteList={setSiteList}
          isPath={"main"}
          // root={imgRoot}
        /> */}
      </>
    ) : (
      // <Site boardInfo={boardInfo} />
      <SiteRecommend />
    )
  ) : (
    <div className="main_wrap">
      <div className="main_container">
        {/* <p onClick={()=>{
              swal( "Oops" ,  "Something went wrong!" ,  "warning" )
            }}>1</p>
            <p onClick={()=>{
              swal( "안녕하세요" ,  "Something went wrong!" ,  "error" )
            }}>2</p>
            <p onClick={()=>{
              swal({
                title: "제목",
                text: "축하드립니다!",
                icon: "success",
                button: "확인",
              });
              
            }}>3</p>
            <p onClick={()=>{
              swal({
                title: "하시겠습니까?",
                text: "되돌릴 수 없습니다",
                icon: "info",
                buttons: true,
                // dangerMode: false,
              })
              .then((willDelete) => {
                if (willDelete) {
                  swal("성공", {
                    icon: "success",
                  });
                } else {
                  swal("취소하셨습니다.");
                }
              });
            }}>asdf</p> */}
        <p>
          공새로에 오신 것을 <br /> 환영합니다.
        </p>
        <p>지금 바로 공새로의 효율적인 발주 업무 경험을 누려보세요.</p>
        <div className="main_link">
          {isResponsive ? (
            <>
              <Link to="/login">로그인</Link>
              <a
                onClick={() => {
                  navigate(`/join_type`, {
                    state: {
                      socialType: "",
                      socialValue: "",
                    },
                  });
                }}
              >
                회원가입
              </a>
            </>
          ) : (
            <>
              <Link to="/login">로그인</Link>
              <a
                className="join-button"
                onClick={() => {
                  navigate(`/join_type`, {
                    state: {
                      socialType: "",
                      socialValue: "",
                    },
                  });
                }}
              >
                회원가입
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
