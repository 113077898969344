import React, { useState, useRef, useEffect } from "react";
import "../../asset/css/excel.css";
import circle_minus from "../../asset/images/circle-minus.png";
import swal from "sweetalert";
import { productSuggestApi } from "../../api/auth.js";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

const ExcelComponent = ({
  data,
  setData,
  cols,
  setCols,
  rows,
  setRows,
  addRow,
  notifyRemove,
  emptyAlert,
  setEmptyAlert,
}) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const [focusedCell, setFocusedCell] = useState({ row: 0, col: 0 });

  const [nameList, setNameList] = useState([]);
  const [standardList, setStandardList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  // isAutoSearch : 현재 자동완성목록을 탐색하고 있는 지에 대한 불린값
  // focusIndex : 현재 선택된 자동완성 목록의 인덱스
  // listRef : 자동완성목록 컨테이너 요소
  // focusRef : 현재 선택된 아이템 요소
  const [isAutoSearch, setIsAutoSearch] = useState(false);
  const [focusIndex, setFocusIndex] = useState(-1);
  const listRef = useRef(null);
  const focusRef = useRef(null);
  const scrollRef = useRef(null);
  const inputRefs = useRef([]);

  useEffect(() => {
    // 초기 렌더링 시 첫 번째 input에 포커스를 주기 위해 실행
    inputRefs.current[0]?.focus();
  }, []);

  const placeHolderList = [
    "품목명을 입력해주세요.",
    "규격을 입력해주세요.",
    "단위를 입력해주세요.",
    "0",
    "제조국(브랜드)을 입력해주세요.",
    "특이사항 없음",
  ];

  const handleKeyDown = (event, row, col) => {
    const updatedGridData = [...data];

    switch (event.key) {
      case "Escape":
        allEssentialFnc();
        break;
      case "Tab":
        // col + 1
        event.preventDefault();
        allEssentialFnc();
        setFocusIndex(0);
        productSuggestFnc(updatedGridData[row][0].value, col);
        moveFocus(row, col, 0, 1);
        break;
      case "Enter":
        // col + 1
        // setFocusIndex(() => -1);
        allEssentialFnc();
        productSuggestFnc(updatedGridData[row][0].value, col);
        moveFocus(row, col, 0, 1);
        break;
      case "ArrowLeft":
        // col - 1 but / col 0 x
        setFocusIndex(() => -1);
        productSuggestFnc(updatedGridData[row][0].value, col);
        moveFocus(row, col, 0, -1);
        break;
      case "ArrowRight":
        // col + 1 but / col 4 x
        setFocusIndex(() => -1);
        productSuggestFnc(updatedGridData[row][0].value, col);
        moveFocus(row, col, 0, 1);
        break;
      // 임시 시작------------------
      case "ArrowUp":
        setFocusIndex(() => -1);
        moveFocus(row, col, -1, 0);
        break;
      case "ArrowDown":
        setFocusIndex(() => -1);
        moveFocus(row, col, 1, 0);
        break;
      // 임시 끝 ------------------------
      default:
        break;
    }
  };

  const moveFocus = (row, col, rowOffset, colOffset) => {
    const newRow = row + rowOffset;
    const newCol = col + colOffset;

    if (newRow >= 0 && newRow < rows && newCol >= 0 && newCol < cols) {
      setFocusedCell({ row: newRow, col: newCol });
      allEssentialFnc();

      setTimeout(() => {
        inputRefs.current[newRow * cols + newCol]?.focus();
      }, 0);
    }
  };

  const allEssentialFnc = () => {
    setFocusIndex(-1);
    setNameList([]);
    setStandardList([]);
    setUnitList([]);
  };

  useEffect(() => {
    // 행이 이동될 때마다 초기화시키기
    // 모두 초기화?
    allEssentialFnc();
  }, [focusedCell.row]);

  const deleteRow = (idx) => {
    let copyArray = [...data];
    let clickedIndex = idx;
    notifyRemove("해당");

    if (copyArray.length === 1) {
      return;
    } else {
      copyArray.splice(clickedIndex, 1);

      //셀 한개일 경우 삭제불가
      setData(copyArray);
    }
  };

  // 현재는 수량에만 숫자가 아닌지 체크함 - 추가필요!
  const handleKeyChange = (e, rowIndex, colIndex) => {
    setIsAutoSearch(true);

    const updatedGridData = [...data];

    if (colIndex === 3) {
      let onlyNumValue = e.target.value.replace(/[^0-9]/g, "");

      if (colIndex === 3 && onlyNumValue.length > 7) {
        onlyNumValue = onlyNumValue.slice(0, 7);
      }
      updatedGridData[rowIndex][colIndex].value = onlyNumValue;
    } else {
      updatedGridData[rowIndex][colIndex].value = e.target.value;
    }

    setData(updatedGridData);
    const rowTitle = updatedGridData[rowIndex][0].value;
    productSuggestFnc(rowTitle, colIndex);
  };

  const handleFocusLost = (e) => {
    if (listRef.current && listRef.current.contains(e.relatedTarget)) {
      // listRef.current.contains(e.relatedTarget)를 통해 자동완성 컨테이너 내에서 포커스가 이동한 경우를 확인
      return;
    }
    setIsAutoSearch(false);
  };

  const handleKeyClick = (e, rowIndex, colIndex) => {
    e.preventDefault();
    // 수량, 비고에서는 호출 X
    // 모두 초기화
    allEssentialFnc();
    setIsAutoSearch(true);

    setFocusedCell({ row: rowIndex, col: colIndex });
    const updatedGridData = [...data];
    setData(updatedGridData);
    const rowTitle = updatedGridData[rowIndex][0].value;
    productSuggestFnc(rowTitle, colIndex);
  };

  const productSuggestFnc = async (value, col) => {
    if (value === "") {
      return false;
    }
    // 품목명 규격 단위 제외한 곳에서는 api 미호출
    if (col === 3 || col === 4 || col === 5) {
      return false;
    }
    // 모바일에서는 api 미호출
    if (!isDesktop) {
      return false;
    }
    const response = await productSuggestApi({ name: value });
    if (response.status === "success") {
      setNameList(response.data.nameList);
      setStandardList(response.data.standardList);
      setUnitList(response.data.unitList);
      // preventKeyboardFnc();
    }
  };

  // 배열을 돌면서 빈 곳 체크하는 함수
  const findEmptyCells = () => {
    const emptyCells = [];

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        if (data[i][j]?.value === "") {
          emptyCells.push({ row: i + 1, col: j + 1 });
        }
      }
    }

    return emptyCells;
  };

  const createRowData = (count) => {
    const rowData = [];
    for (let j = 0; j < cols; j++) {
      rowData.push({ value: "" });
    }
    return rowData;
  };

  const handlePaste = (event, rowIndex, cols, colIndex) => {
    // 로직 : 복사해온 값을 가져온다(event 객체를 통해) ->
    // 그 값을 Tab이나 enter로 값을 분리한다 ->
    //분리한 값을 순회하면서 값을 변경한다.
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    const updatedGridData = [...data];

    // 줄 바꿈을 기준으로 행을 분리하고 탭으로 구분된 값을 추출
    const splitRows = pastedText.split("\n");
    const values = splitRows.map((row) => row.split("\t"));

    // 붙여넣은 값이 data의 값을 넘어간다면, addRow를 해야함
    if (data.length < values.length + rowIndex) {
      let count = 1;

      for (let i = 0; i < values.length + rowIndex - data.length; i++) {
        updatedGridData.push(createRowData(count, cols));
      }
      //   원래 data의 row + 붙힌 값의 길이 + 현재 붙힌 곳의 Index - 원래 data 배열의 길이
      setRows(rows + values.length + rowIndex - data.length);
    }
    // 클립보드에 복사된 값을 탭 단위로 나누고, 그 나눈 것을 각 Input에 매핑함
    values.forEach((row, rIdx) => {
      row.forEach((value, cIdx) => {
        // if (Number(colIndex) + Number(cIdx) === 4)
        if (colIndex + cIdx <= 5) {
          // 5칸 넘어가면 그냥 버림
          updatedGridData[rowIndex + rIdx][colIndex + cIdx].value = value;
        }

        if (colIndex + cIdx === 3) {
          // 1. 콤마 제거 (.과 숫자를 제외한)
          const removeValue = value.replace(/[^0-9.]/g, "");

          // 2. 문자열을 숫자로 변환
          const numberValue = Number(removeValue);

          // 3. 숫자가 유효한지 확인 (NaN 또는 Infinity 체크)
          const isValidNumber = !isNaN(numberValue) && isFinite(numberValue);

          // 4. 자리수 제한
          let limitedValue;
          if (colIndex + cIdx === 3) {
            // colIndex가 5일 경우 7자리까지 자르기
            limitedValue = numberValue.toString().slice(0, 7);
          }

          // 5. 유효한 숫자이면 자리수 제한 후 숫자로 변환
          let finalValue =
            isValidNumber && limitedValue ? Number(limitedValue) : 0;

          // 6. 소수점 제거 (버림)
          finalValue = Math.floor(finalValue);

          // 7. 업데이트된 값을 gridData에 설정
          updatedGridData[rowIndex + rIdx][colIndex + cIdx].value = finalValue;
        }
      });
    });

    // 마지막에 setGridData
    setData(updatedGridData);

    event.preventDefault(); // 기본 동작(텍스트 붙여넣기) 방지
  };

  const handleDoubleClick = (e, rowIndex, colIndex, cellValue) => {
    let columnName = "값을 입력해주세요.";
    if (colIndex === 0) columnName = "품목명을 입력해주세요.";
    if (colIndex === 1) columnName = "규격을 입력해주세요.";
    if (colIndex === 2) columnName = "단위를 입력해주세요.";
    if (colIndex === 3) columnName = "수량을 입력해주세요.";
    if (colIndex === 4) columnName = "비고를 입력해주세요.";

    try {
      swal({
        title: columnName,
        icon: "info",
        buttons: {
          cancel: {
            text: "취소",
            value: null,
            visible: true,
          },
          confirm: {
            text: "확인",
            value: true,
            visible: true,
          },
        },
        closeOnClickOutside: false,
        content: {
          element: "textarea",
          attributes: {
            value: cellValue,
            placeholder: "사유를 입력해주세요.",
            rows: 4,
            style: "resize:none",
          },
        },
      }).then((value) => {
        if (value) handlePopOkClick(rowIndex, colIndex);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handlePopOkClick = (rowIndex, colIndex) => {
    const value = document.querySelector(".swal-content__textarea").value;

    if (!value) {
      swal("값을 입력해주세요.", "", "warning");
    } else {
      //  값 바꿔주는 구문 넣어주기
      const updatedGridData = [...data];
      updatedGridData[rowIndex][colIndex].value = value;
      setData(updatedGridData);
    }
  };

  // const handleKeyUp = (e, rowIndex, colIndex) => {
  //   if (KeyEvent[e.key]) KeyEvent[e.key](rowIndex, colIndex);
  // };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [focusIndex]);

  const handleAutoCompleteClick = (elem, row, col) => {
    const updatedGridData = [...data];
    updatedGridData[row][col].value = elem;
    setData(updatedGridData);
    // setIsAutoSearch(false);

    // 모두 초기화
    allEssentialFnc();
  };

  // 각 최종단가 개별
  const eachPartnerElem = (rowIndex) => {
    if (redux_excel[rowIndex][3]?.value) {
      const convertValue = Number(redux_excel[rowIndex][3]?.value);
      const formattedValue = convertValue.toLocaleString("ko");

      return formattedValue;
    } else {
      return 0;
    }
  };

  const redux_excel = useSelector((state) => state.excel);

  return (
    <div className="excel_container">
      <div className="excel_inner">
        <table className="excel_table">
          <tbody>
            <tr className="excel_top_section">
              <td className="excel_number_section sticky_column"></td>
              <td
                onClick={() => {
                  setNameList([]);
                }}
              >
                품목명
              </td>
              <td>규격</td>
              <td>단위</td>
              <td>수량</td>
              <td>
                <p style={{ lineHeight: "1.3" }}>
                  제조국
                  <br />
                  (브랜드)
                </p>
              </td>
              <td>비고</td>
              <td>삭제</td>
            </tr>
            {redux_excel.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className="excel_number_section sticky_column">
                  {rowIndex + 1}
                </td>
                {row.map((cell, colIndex) => (
                  <td
                    key={colIndex}
                    className={
                      emptyAlert &&
                      ((typeof redux_excel[rowIndex][colIndex]?.value ===
                        "string" &&
                        redux_excel[rowIndex][colIndex].value.trim() === "" &&
                        (colIndex === 0 || colIndex === 2 || colIndex === 3)) ||
                        (colIndex === 3 &&
                          (isNaN(Number(redux_excel[rowIndex][3]?.value)) ||
                            redux_excel[rowIndex][3].value < 1 ||
                            redux_excel[rowIndex][3].value > 10000000)))
                        ? "excel_auto_empty_td"
                        : "excel_auto_td"
                    }
                  >
                    <input
                      placeholder={placeHolderList[colIndex]}
                      //   ref는 2차원배열로 관리되지않음
                      ref={(ref) =>
                        (inputRefs.current[rowIndex * cols + colIndex] = ref)
                      }
                      // onDoubleClick={(e) => {
                      //   handleDoubleClick(e, rowIndex, colIndex, cell.value);
                      // }}
                      title={cell.value}
                      value={
                        colIndex === 3
                          ? eachPartnerElem(rowIndex)
                          : redux_excel[rowIndex][colIndex]?.value
                      }
                      // 수량이 숫자가 아닌 경우
                      // 변경할 때마다 셀이 검증 되어야한다.
                      onChange={(e) => {
                        handleKeyChange(e, rowIndex, colIndex);
                      }}
                      onPaste={(e) => {
                        handlePaste(e, rowIndex, cols, colIndex);
                      }}
                      onKeyDown={(e) =>
                        // colIndex === 0 &&
                        {
                          handleKeyDown(e, rowIndex, colIndex);
                        }
                      }
                      onBlur={(e) => {
                        handleFocusLost(e, rowIndex, colIndex);
                      }}
                      // onKeyUp={(e) => handleKeyUp(e, rowIndex, colIndex)}
                      onClick={(e) => {
                        handleKeyClick(e, rowIndex, colIndex);
                      }}
                    />

                    {focusedCell.row === rowIndex &&
                      focusedCell.col === colIndex &&
                      isAutoSearch && (
                        <div
                          ref={listRef}
                          tabIndex="-1"
                          onMouseDown={(e) => {
                            e.preventDefault();
                            // 컨테이너 클릭 시 input의 onBlur가 발생하지 않도록 방지
                          }}
                          className={
                            (colIndex === 0 && nameList.length === 0) ||
                            (colIndex === 1 && standardList.length === 0) ||
                            (colIndex === 2 && unitList.length === 0) ||
                            colIndex === 3 ||
                            colIndex === 4 ||
                            colIndex === 5
                              ? "excel_no_container"
                              : "excel_auto_container"
                          }
                        >
                          {(colIndex === 0
                            ? nameList
                            : colIndex === 1
                            ? standardList
                            : colIndex === 2
                            ? unitList
                            : null
                          )?.map((elem, listIndex) => (
                            <div
                              className={
                                focusIndex - 1 === listIndex
                                  ? "excel_auto_active_elem"
                                  : "excel_auto_elem"
                              }
                              onClick={() => {
                                handleAutoCompleteClick(
                                  elem,
                                  rowIndex,
                                  colIndex
                                );
                              }}
                            >
                              <div
                                ref={
                                  listIndex === focusIndex
                                    ? focusRef
                                    : undefined
                                }
                              >
                                {elem}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                  </td>
                ))}
                <td
                  onClick={(e) => {
                    deleteRow(rowIndex);
                  }}
                  title={`${rowIndex + 1}번째 행 삭제`}
                  className="excel_delete_section"
                >
                  <img src={circle_minus} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <ExcelAutoCompleteComponent /> */}
      </div>
    </div>
  );
};

export default ExcelComponent;
