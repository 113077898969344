import Chip from "../../../component/ui/chip/Chip";
import Typography from "../../../component/ui/typography/Typography";
import s from "./chipTest.module.css";

export default function ChipTest() {
  return (
    <div className={s.chip_group_container}>
      <Typography component="p" type="h1">
        CHIP SIZE TEST
      </Typography>
      <div>
        <Chip size="small" type="default" color="gray">
          SMALL
        </Chip>
        <Chip size="medium" type="default" color="gray">
          MEDIUM
        </Chip>
        <Chip size="large" type="default" color="gray">
          LARGE
        </Chip>
      </div>

      <Typography component="p" type="h1">
        CHIP TYPE TEST
      </Typography>
      <div>
        <Chip size="medium" type="default" color="gray">
          DEFAULT
        </Chip>
        <Chip size="medium" type="border" color="gray">
          BORDER
        </Chip>
      </div>

      <Typography component="p" type="h1">
        CHIP COLOR TEST
      </Typography>
      <div>
        <Chip size="medium" type="default" color="red">
          RED
        </Chip>
        <Chip size="medium" type="default" color="yellow">
          YELLOW
        </Chip>
        <Chip size="medium" type="default" color="green">
          GREEN
        </Chip>
        <Chip size="medium" type="default" color="blue">
          BLUE
        </Chip>
        <Chip size="medium" type="default" color="gray">
          GRAY
        </Chip>
        <Chip size="medium" type="default" color="secondary">
          SECONDARY
        </Chip>
      </div>
      <div>
        <Chip size="medium" type="border" color="red">
          RED
        </Chip>
        <Chip size="medium" type="border" color="yellow">
          YELLOW
        </Chip>
        <Chip size="medium" type="border" color="green">
          GREEN
        </Chip>
        <Chip size="medium" type="border" color="blue">
          BLUE
        </Chip>
        <Chip size="medium" type="border" color="gray">
          GRAY
        </Chip>
        <Chip size="medium" type="border" color="secondary">
          SECONDARY
        </Chip>
      </div>
    </div>
  );
}
