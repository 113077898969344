import * as S from "../../asset/css/invoiceListItemList.styles";
import useInvoiceListItemList from "./customHooks/useInvoiceListItemList";
import swal from "sweetalert";
import { addInvoiceCodeApi } from "../../api/auth";

export default function InvoiceListItemList(props) {
  const { ordersDetailItem, invoice, fetchInvoiceListItem, codeGroupList } =
    props;
  const { setSelectedOption } = useInvoiceListItemList(
    ordersDetailItem,
    fetchInvoiceListItem,
    invoice
  );

  const addInvoiceCode = async (value) => {
    try {
      await addInvoiceCodeApi(value);
    } catch (error) {
      swal({
        icon: "error",
        title: "오류가 발생하였습니다. 다시 시도해주세요.",
      });
    }
  };

  const isNullAlert = () => {
    swal({ icon: "error", title: "정산코드를 입력해주세요. (ex. 잡자재비)" });
  };

  const isDuplicate = (value) => {
    return (
      codeGroupList?.filter((codeGroup) =>
        codeGroup.taxCodeName.includes(value)
      ).length > 0
    );
  };

  const isDuplicateAlert = () => {
    swal({ icon: "error", title: "중복된 정산코드입니다. 다시 입력해주세요." });
  };

  const isSuccessAlert = (value) => {
    swal({
      icon: "success",
      title: `정산코드(${value})가 추가되었습니다.`,
    }).then(() => {
      setSelectedOption(value);
    });
  };

  const handleSelect = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "custom") {
      swal({
        icon: "info",
        title: "정산코드를 입력해주세요. (ex. 잡자재비)",
        content: "input",
      }).then((value) => {
        if (!value) {
          isNullAlert();
          return;
        }
        if (value) {
          if (isDuplicate(value)) {
            isDuplicateAlert();
            return;
          }
          addInvoiceCode(value);
          isSuccessAlert(value);
        }
      });
      return;
    }

    setSelectedOption(selectedValue);
  };

  return (
    <S.Wrapper>
      <S.InvoiceItemList>
        <div>{ordersDetailItem.bidProduct.no}</div>
        <div>{ordersDetailItem.bidProduct.name}</div>
        <div>{ordersDetailItem.bidProduct.count?.toLocaleString("ko")}</div>
        <div>{ordersDetailItem.orderlist.price?.toLocaleString("ko")}</div>
        <div>
          {(
            ordersDetailItem.bidProduct.count * ordersDetailItem.orderlist.price
          ).toLocaleString("ko")}
        </div>
        <div>
          <select
            disabled={invoice.customerInvoice.state !== "REQUEST"}
            onChange={(e) => handleSelect(e)}
            value={ordersDetailItem.taxCode.name}
          >
            {codeGroupList?.map((codeGroup) => (
              <CodeGroupList codeGroup={codeGroup} />
            ))}
            <option value="custom">- 직접 입력 -</option>
          </select>
        </div>
      </S.InvoiceItemList>
    </S.Wrapper>
  );
}

function CodeGroupList({ codeGroup }) {
  return (
    <option key={codeGroup.taxCodeNo} value={codeGroup.taxCodeName}>
      {codeGroup.taxCodeName}
    </option>
  );
}
