import { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchInvoicePreparationListApi,
  requestInvoiceApi,
} from "../../../api/auth";
import s from "../../../asset/css/invoicePreparationPop.module.css";
import close_icon from "../../../asset/images/cancel-btn.png";
import plus_icon from "../../../asset/images/preparation_plus_icon.png";
import equal_icon from "../../../asset/images/preparation_equal_icon.png";
import { Checkbox, Divider, ConfigProvider } from "antd";
import swal from "sweetalert";
import OrderDetailPop from "./OrderDetailPop";

export default function InvoicePreparationPop({ setPreparationPop }) {
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const [preparationList, setPreparationList] = useState();
  const [checkedList, setCheckedList] = useState([]);

  // summary box
  const summaryOrderCount = checkedList?.length || 0;
  const summaryTotalAmount =
    checkedList?.reduce((acc, cur) => acc + cur.orders.totalAmount, 0) || 0;
  const summaryTotalVat =
    checkedList?.reduce((acc, cur) => acc + cur.orders.totalVat, 0) || 0;
  const summaryTotalSum = summaryTotalAmount + summaryTotalVat || 0;

  // checkbox logic
  const checkAll = preparationList?.length === checkedList?.length;
  const checkSome =
    checkedList?.length > 0 && checkedList.length < preparationList?.length;
  const onCheckAll = (e) => {
    setCheckedList(e.target.checked ? preparationList : []);
  };
  const onCheckSome = (order, isChecked) => {
    if (isChecked) {
      setCheckedList((prev) => [...prev, order]);
    } else {
      setCheckedList((prev) =>
        prev.filter((item) => item.orders.no !== order.orders.no)
      );
    }
  };

  const fetchInvoicePreparationList = useCallback(async () => {
    try {
      const response = await fetchInvoicePreparationListApi(userData.site_num);
      setPreparationList(response.data.ordersDetailList);
    } catch (error) {
      console.error(error);
    }
  }, [userData.site_num]);

  const requestInvoice = async () => {
    try {
      const orderNoList = checkedList?.map((order) => order.orders.no);
      await requestInvoiceApi(userData.site_num, orderNoList);
    } catch (error) {
      console.error(error);
    }
  };

  const isNullAlert = () => {
    swal(
      "선택된 주문서가 없습니다",
      "주문서 선택 후 다시 시도해주세요",
      "error"
    );
  };

  const handleCancelClick = () => {
    swal(
      "정산 요청이 취소되었습니다",
      "주문서 선택 후 다시 시도해주세요",
      "error"
    ).then((value) => {
      if (value) setCheckedList([]);
    });
  };

  const handleConfirmClick = () => {
    swal(
      "정산 요청이 완료되었습니다",
      `확인 클릭 시 정산관리 > 정산요청 탭으로 이동합니다.

      누락 또는 잘못된 정산 요청이 있다면
      정산 취소 기능을 이용해 주세요.
      `,
      "success"
    ).then((value) => {
      if (value) navigate("/invoice", { state: { fromPage: "request" } });
    });
  };

  const isConfirmAlert = () => {
    swal({
      title: "아래 정보를 다시 한 번 확인해주세요",
      icon: "info",
      buttons: {
        cancel: {
          text: "취소",
          value: null,
          visible: true,
        },
        confirm: {
          text: "요청",
          value: true,
          visible: true,
        },
      },
      text: `총 주문서 : ${summaryOrderCount}건
      공급가액 : ${summaryTotalAmount?.toLocaleString("ko")}원
      부가세액 : ${summaryTotalSum?.toLocaleString("ko")}원

      합계금액 : ${summaryTotalSum?.toLocaleString("ko")}원
      `,
    }).then((value) => {
      if (value) {
        requestInvoice();
        handleConfirmClick();
      }
      if (!value) handleCancelClick();
    });
  };

  const onClickRequestInvoice = () => {
    if (!checkedList?.length) {
      isNullAlert();
      return;
    }

    isConfirmAlert();
  };

  useEffect(() => {
    if (userData.site_num) fetchInvoicePreparationList();
  }, [fetchInvoicePreparationList, userData.site_num]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className={s.background} onClick={() => setPreparationPop(false)}>
      <div className={s.container} onClick={(e) => e.stopPropagation()}>
        <div className={s.header}>
          <div className={s.title}>정산요청</div>
          <div className={s.close} onClick={() => setPreparationPop(false)}>
            <img src={close_icon} alt="close_icon" />
          </div>
        </div>

        <div className={s.summary}>
          <div className={s.summary_amount}>
            {isDesktop && (
              <>
                <div>
                  <div>총 주문서</div>
                  <div>{summaryOrderCount?.toLocaleString("ko")}건</div>
                </div>

                <Divider type="vertical" className={s.divider} />
              </>
            )}

            <div>
              <div>공급가액</div>
              <div>{summaryTotalAmount?.toLocaleString("ko")}원</div>
            </div>

            <img className={s.summary_icon} src={plus_icon} alt="plus_icon" />

            <div>
              <div>부가세액</div>
              <div>{summaryTotalVat?.toLocaleString("ko")}원</div>
            </div>

            <img className={s.summary_icon} src={equal_icon} alt="equal_icon" />

            <div>
              <div>합계금액</div>
              <div>{summaryTotalSum?.toLocaleString("ko")}원</div>
            </div>
          </div>
          <button
            className={s.invoice_apply_btn}
            onClick={onClickRequestInvoice}
          >
            {isDesktop
              ? "정산요청"
              : `정산요청 (${summaryOrderCount?.toLocaleString("ko")}건)`}
          </button>
        </div>

        <div className={s.table_container}>
          <table className={s.table}>
            <tbody>
              <tr className={s.table_title}>
                <th>
                  <ConfigProvider
                    theme={{
                      components: {
                        Checkbox: {
                          colorPrimary: "#11336B",
                          colorPrimaryBorder: "#11336B",
                          colorPrimaryHover: "#11336B",
                        },
                      },
                    }}
                  >
                    <Checkbox
                      indeterminate={checkSome}
                      onChange={onCheckAll}
                      checked={checkAll}
                      className={s.checkbox}
                    />
                  </ConfigProvider>
                </th>
                <th>
                  <p>주문번호</p>
                </th>
                <th>
                  <p>주문명</p>
                </th>
                <th>
                  <p>공급사명</p>
                </th>
                <th>
                  <p>공급가액</p>
                </th>
                <th>
                  <p>부가세</p>
                </th>
                <th>
                  <p>정산총액</p>
                </th>
                <th>
                  <p>주문일</p>
                </th>
                <th>
                  <p>배송일</p>
                </th>
                <th>
                  <p>검수완료일</p>
                </th>
                <th />
              </tr>
              {preparationList?.map((preparation) => (
                <PreparationTableTd
                  key={preparation.orders.no}
                  preparation={preparation}
                  onCheckSome={onCheckSome}
                  checkedList={checkedList}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function PreparationTableTd({ preparation, onCheckSome, checkedList }) {
  const [orderDetailPop, setOrderDetailPop] = useState(false);

  return (
    <tr key={preparation.orders.no} className={s.table_content}>
      <td>
        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorPrimary: "#11336B",
                colorPrimaryBorder: "#11336B",
                colorPrimaryHover: "#11336B",
              },
            },
          }}
        >
          <Checkbox
            onChange={(e) => onCheckSome(preparation, e.target.checked)}
            checked={checkedList?.some(
              (order) => order.orders.no === preparation.orders.no
            )}
            className={s.checkbox}
          />
        </ConfigProvider>
      </td>
      <td>{preparation.orders.code}</td>
      <td>{preparation.orders.title}</td>
      <td>{preparation.company.name}</td>
      <td>{preparation.orders.totalAmount?.toLocaleString("ko")}</td>
      <td>{preparation.orders.totalVat?.toLocaleString("ko")}</td>
      <td>{preparation.orders.totalVatAmount?.toLocaleString("ko")}</td>
      <td>{preparation.orders.ordersDate?.slice(0, 10)}</td>
      <td>{preparation.orders.edate?.slice(0, 10)}</td>
      <td>{preparation.orders.inspectionDate?.slice(0, 10)}</td>
      <td>
        <button
          className={s.order_detail_btn}
          onClick={() => setOrderDetailPop(true)}
        >
          상세보기
        </button>
      </td>
      {orderDetailPop && (
        <OrderDetailPop
          key={preparation.orders.no}
          productList={preparation.orderlistItemList}
          setOrderDetailPop={setOrderDetailPop}
        />
      )}
    </tr>
  );
}
